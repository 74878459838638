import {makeStyles, Theme} from "@material-ui/core/styles";

export const commonStyle = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
    badQuality: {
        color: 'orange',
        fontWeight: 'bold'
    }
}))

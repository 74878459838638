import React, {useContext} from "react";
import {Button, Grid, Size} from "@material-ui/core";
import {CopyIcon, DeleteIcon, MoveIcon} from "../icon/icons";
import Auth from "../../Auth";
import {ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import MoreActionsButton from "../MoreActionsButton";
import {useTranslation} from "react-i18next";
import {fetchPublicationsOfPeriodical} from "../../../redux/actions/publication.actions";
import {OpenArticleDialogPayload, setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions";
import {useDispatch, useSelector} from "react-redux";
import {DeleteArticleDialogContext} from "../context/DeleteArticleDialogContext";
import DownloadXmlArticle from "../../articles/actions/DownloadArticleXml";
import {ArticleStatus, fixedAttachments} from "../../../types/article";
import {State} from "../../../redux/reducers";
import {ArticleSearchResult, SearchContext} from "../../../types/common-search";
import {GridSize} from "@material-ui/core/Grid";
import PushOnlineArticle from "../../articles/actions/PushOnlineArticle";

type Props = {
    article: ArticleSearchResult
    size?: Size
    xs?: GridSize
    page?: undefined | "organize-bundle"
}

const ArticlesMoreActionsButton = React.memo(({xs, article, size, page}: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {openConfirmationDialog, setArticleBeingDeleted} = useContext(DeleteArticleDialogContext)

    const openArticleDialog = (article: ArticleSearchResult, mode: ArticleDialogMode) => {
        dispatch(setArticleDialogMode({
            mode: mode,
            default: {...article, tags: article.numberOfPages ? {nombreDePages: article.numberOfPages} : undefined},
            source: mode === ArticleDialogMode.MOVE ? article.id : undefined
        } as OpenArticleDialogPayload))
    }

    const displayDuplicateButton = () => {
        if (!article.isBundle) {
            return (<Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}
                          periodicalRestriction={article.periodicalId}>
                <Grid item>
                    <Button
                        className={'lex-left-button'}
                        fullWidth
                        startIcon={<CopyIcon/>}
                        onClick={() => {
                            openArticleDialog(article, ArticleDialogMode.COPY)
                        }}>
                        {t('articles.copy')}
                    </Button>
                </Grid>
            </Auth>)
        }
    }

    return <Grid item xs={xs}>
        <MoreActionsButton size={size}>
            {displayDuplicateButton()}

            {!(page === "organize-bundle") && (!fixedAttachments.includes(article.attached) && [ArticleStatus.IN_PROGRESS, ArticleStatus.READY].includes(article.status)) && !article.nextPublicationOnlineDate &&
            <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}
                  periodicalRestriction={article.periodicalId}>
                <Grid item>
                    <Button
                        className={'lex-left-button'}
                        fullWidth
                        startIcon={<MoveIcon/>}
                        size={"small"} onClick={() => {
                        openArticleDialog(article, ArticleDialogMode.MOVE)
                    }}>
                        {t(article.isBundle ? 'bundles.move' : 'articles.move')}
                    </Button>
                </Grid>
            </Auth>}

            {!(page === "organize-bundle") && (!fixedAttachments.includes(article.attached) && article.publicationOnlineDate === null) && !article.nextPublicationOnlineDate &&
            <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, EDITOR, PRODUCER, MANAGER]}
                  periodicalRestriction={article.periodicalId}>
                <Grid item>
                    <Button
                        className={'lex-left-button'}
                        fullWidth
                        startIcon={<DeleteIcon/>}
                        size={"small"} onClick={() => {
                        setArticleBeingDeleted(article)
                        openConfirmationDialog(true)
                    }}>
                        {t(article.isBundle ? 'bundles.delete' : 'articles.delete')}
                    </Button>
                </Grid>
            </Auth>}

            {/* télécharger xml */}
            <DownloadXmlArticle article={article}/>
        </MoreActionsButton>
    </Grid>
})

export default ArticlesMoreActionsButton

import React, {useEffect} from "react";
import {PlanNode} from "../../../../types/plan-node";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    currentAdminBundlePlanDirtySelector,
    currentAdminBundlePlanFormModeSelector,
    currentAdminBundlePlanSelector
} from "../../../../redux/selectors/admin/bundle/admin-bundle.selectors";
import {saveAdminBundlePlan, setDirty, setPlan} from "../../../../redux/actions/admin/bundle/admin-bundle.actions";
import {Grid, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TreeViewer from "../../../commons/tree/TreeViewer";
import {useHistory} from "react-router-dom";
import useAsyncState from "../../../commons/hooks/useAsyncState";
import {BundlePlanFormMode} from "../../../../types/bundle";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../../../theme";
import RouteLeavingGuard from "../../../RouteLeavingGuard/RouteLeavingGuard";

const removeNulls = (plan: PlanNode[]) => plan.map(node => node.sub === null ? {...node, sub: []} : {
    ...node,
    sub: removeNulls(node.sub)
})
const useStyles = makeStyles((theme) => ({
    tree: {
        backgroundColor: "rgba(0,0,0,0.1)",
        paddingLeft: theme.spacing(1),
        borderRadius: "5px",
        minHeight: "50vh"
    },
    topTitle: {
        fontWeight: 200
    },
    saveRoot: {
        paddingRight: theme.spacing(3),
    }
}));

const BundlePlanStructure = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [nodes, setNodes] = useAsyncState([] as PlanNode[])
    const [isLoaded, setIsLoaded] = useAsyncState(false)

    const bundlePlan = useSelector(currentAdminBundlePlanSelector);
    const mode = useSelector(currentAdminBundlePlanFormModeSelector);

    const customClasses = useStyles(theme);
    const dirty = useSelector(currentAdminBundlePlanDirtySelector)

    // ce n'est certes pas très beau, mais dans le cas présent, c'est la solution la plus simple
    let saving = false

    useEffect(() => {
        if (bundlePlan?.plan && !isLoaded) {
            setNodes(removeNulls(bundlePlan.plan)).then(() => setIsLoaded(true));
        } else if (mode === BundlePlanFormMode.CREATE && !bundlePlan?.plan) {
            setIsLoaded(true).then(() => dispatch(setPlan([])));
        }
    }, [bundlePlan]);

    useEffect(() => {
        if (isLoaded) {
            dispatch(setPlan(nodes));
        }
    }, [nodes])

    const cancel = () => {
        new Promise<void>((resolve) => {
            dispatch(setDirty(true));
            resolve();
        }).then(() => {
            history.push(t(`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}`));
        });
    }

    const save = () => {
        if (bundlePlan) {
            saving = true
            dispatch(saveAdminBundlePlan(bundlePlan));
            if (mode === BundlePlanFormMode.CREATE) {
                history.push(t(`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}`));
            }
        }
    }

    return (
        <>
            <RouteLeavingGuard
                when={dirty}
                shouldBlockNavigation={() => !saving}
                navigate={(location) => {
                    history.push(location);
                }}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={customClasses.topTitle} variant={"h5"}
                                component={"h1"}>{t('admin.bundle.structure.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <hr/>
                </Grid>
                <Grid item xs={12}>
                    <Grid className={customClasses.saveRoot} container item justify={"flex-end"} direction={"row"}
                          spacing={2}>
                        <Grid item>
                            <Button variant="outlined" color="primary" onClick={cancel}>
                                {t('misc.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"} color={"primary"} onClick={save}>
                                {t('misc.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TreeViewer
                        setDirty={setDirty}
                        detachLabel={''}
                        maxDepth={5}
                        preventSiblings={true}
                        edit={true}
                        canAddContainerNodes={true}
                        nodes={nodes}
                        setNodes={setNodes}
                        displayDirection={"row"}
                        treeStyle={{height: '55vh', overflowY: 'auto'}}
                        treeAdderInputLabel={"Titre"}
                        treeAdderButtonLabel={"Ajouter"}
                        customClasses={customClasses}
                        isBundleStructureEdition={true}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default BundlePlanStructure

import {DateTime} from "luxon";

export enum ParameterType {
    BOOLEAN = 'BOOLEAN',
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    BOOLEAN_ARRAY = "BOOLEAN_ARRAY",
    TOKEN_ARRAY = "TOKEN_ARRAY",
    STRING_ARRAY = "STRING_ARRAY",
    DATE_RANGE = "DATE_RANGE",
    SORT = "SORT"
}

type config = {
    type: ParameterType,
    setEmpty?: boolean
    default?: any
}

type paramMap = {
    [key: string]: config
}

export function readQueryParams(params: paramMap, search: string): any {
    const query = new URLSearchParams(search);
    return Object.entries(params)
        .map(([attr, param]) => ReaderMapper[param.type](attr, query, param))
        .reduce((prev, curr) => ({...prev, ...curr}), {});
}

export function buildQueryParams(params: paramMap, query: object): string {
    const v = Object.entries(params)
        .filter(([attr]) => query[attr] !== undefined)
        .flatMap(([attr, param]) => BuilderMapper[param.type](attr, query[attr], param))
        .join('&');
    return `?${v}`;
}

function readBooleanParam(attr: string, params: URLSearchParams, config: { default?: any }): object {
    return params.has(attr) ? {[attr]: params.get(attr) === 'true'} : config.default !== undefined ? {[attr]: config.default} : {};
}

function buildBooleanParam(attr: string, val: any, config: config): string[] {
    return config.default === val ? [] : [`${attr}=${val === true ? 'true' : 'false'}`];
}

export function readNumberParam(attr, params: URLSearchParams, config: config): object {
    return params.has(attr) ? {[attr]: parseInt(params.get(attr)!)} : config.default !== undefined ? {[attr]: config.default} : {};
}

function buildNumberParam(attr: string, val: any, config: config): string[] {
    return config.default === val ? [] : [`${attr}=${val}`];
}

export function readStringParam(attr, params: URLSearchParams): object {
    return params.has(attr) ? {[attr]: params.get(attr)} : {};
}

function buildStringParam(attr: string, val: any): string[] {
    return [`${attr}=${encodeURIComponent(val)}`];
}

export function readBooleanArrayParam(attr, params: URLSearchParams): object {
    return {
        [attr]: params.has(attr) ? params.get(attr)!.split(',').flatMap(v => v === 'true' ? [true] : v === 'false' ? [false] : []) : []
    };
}

function buildBooleanArrayParam(attr: string, val: any): string[] {
    const array = val.flatMap(v => v === true ? ['true'] : v === false ? ['false'] : []);
    return val?.length ? [`${attr}=${array.join(',')}`] : [];
}

export function readTokenArrayParam(attr, params: URLSearchParams, config: config): object {
    const val = params.has(attr) ? params.get(attr) !== '' ? params.get(attr)!.split(',') : [] : config.setEmpty ? undefined : [];
    return val !== undefined ? {
        [attr]: val
    } : {};
}

function buildTokenArrayParam(attr: string, val: any, config: config): string[] {
    return config.setEmpty === true && val === undefined ? [`${attr}=`] : val?.length ? [`${attr}=${val.join(',')}`] : [];
}

export function readStringArrayParam(attr, params: URLSearchParams): object {
    return {
        [attr]: params.has(attr) ? params.get(attr)!.split('|||').map(x => decodeURIComponent(x)) : []
    };
}

function buildStringArrayParam(attr: string, val: any): string[] {
    return val?.length ? [`${attr}=${encodeURIComponent(val.join('|||'))}`] : [];
}

export function readDateRangeParam(attr, params: URLSearchParams): object {
    const start = params.has(`${attr}.start`) ? {start: params.get(`${attr}.start`)} : {};
    const end = params.has(`${attr}.end`) ? {end: params.get(`${attr}.end`)} : {};
    return {[attr]: {...start, ...end}};
}

function buildDateRangeParam(attr: string, val: any): string[] {
    const start = val.start ? [`${attr}.start=${DateTime.fromISO(val.start).toISODate()}`] : [];
    const end = val.end ? [`${attr}.end=${DateTime.fromISO(val.end).toISODate()}`] : [];
    return [...start, ...end];
}

export function readSortParam(attr, params: URLSearchParams, config: config): object {
    const base = config.default?.[0] ?? {};
    const sort = params.has(attr) ? {attr: params.get(attr)} : {};
    const order = params.has(`${attr}.order`) ? {order: params.get(`${attr}.order`)} : {};
    return {[attr]: [{...base, ...sort, ...order}]};
}

function buildSortParam(attr: string, val: any): string[] {
    const sort = val[0]?.attr ? [`${attr}=${val[0].attr}`] : [];
    const order = val[0]?.order ? [`${attr}.order=${val[0].order}`] : [];
    return [...sort, ...order];
}

const ReaderMapper = {
    [ParameterType.BOOLEAN]: readBooleanParam,
    [ParameterType.NUMBER]: readNumberParam,
    [ParameterType.STRING]: readStringParam,
    [ParameterType.BOOLEAN_ARRAY]: readBooleanArrayParam,
    [ParameterType.TOKEN_ARRAY]: readTokenArrayParam,
    [ParameterType.STRING_ARRAY]: readStringArrayParam,
    [ParameterType.DATE_RANGE]: readDateRangeParam,
    [ParameterType.SORT]: readSortParam,
}

const BuilderMapper = {
    [ParameterType.BOOLEAN]: buildBooleanParam,
    [ParameterType.NUMBER]: buildNumberParam,
    [ParameterType.STRING]: buildStringParam,
    [ParameterType.BOOLEAN_ARRAY]: buildBooleanArrayParam,
    [ParameterType.TOKEN_ARRAY]: buildTokenArrayParam,
    [ParameterType.STRING_ARRAY]: buildStringArrayParam,
    [ParameterType.DATE_RANGE]: buildDateRangeParam,
    [ParameterType.SORT]: buildSortParam,
}


import {
  Context,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { State } from "../../redux/reducers";
import { defaultQuery, Query } from "../models/Query";
import { Params, query } from "../utils/query";

type PlaceHolderContextType = [
  Query<any>,
  Dispatch<SetStateAction<Query<any>>>
];

export const PlaceHolderContext = createContext<PlaceHolderContextType>([
  {} as Query<any>,
  () => {},
]);

export type Http_method =
  | "get"
  | "delete"
  | "head"
  | "options"
  | "post"
  | "put"
  | "patch";

type LoadableStateType<T> = [Query<T>, Dispatch<SetStateAction<Query<T>>>];

type UseQueryType<T> = {
  url: string;
  method?: Http_method;
  headers?: { [key: string]: any };
  context?: Context<LoadableStateType<T>> | undefined;
  queryParams?: any;
  mapper?: (arg: any) => T;
  body?: any;
  callback?: (arg: T) => void;
};

/**
 * Hook to fetch data on component mount.
 *
 * This hook is designed to fetch data from a given URL when the component mounts.
 * It uses the Redux state to get the authentication token and sets it in the headers of the request.
 * The fetched data is stored in a local state or a context, depending on whether a context is provided.
 * If a context is provided, the fetched data and the setter function are returned from the hook.
 * If no context is provided, the local state and its setter function are returned instead.
 *
 * @template T The type of the data to be fetched.
 *
 * @param {Object} params The parameters for the hook.
 * @param {string} params.url The URL from which to fetch the data.
 * @param {Context<LoadableStateType<T>>} [params.context] The context in which to store the fetched data.
 * @param {any} [params.body] The body of the request, if applicable.
 * @param {any} [params.queryParams] The query parameters for the request, if applicable.
 * @param {Http_method} [params.method='get'] The HTTP method to use for the request.
 *
 * @returns {[Query<T>, Dispatch<SetStateAction<Query<T>>>]} The fetched data and the setter function.
 */

export const useFetchDataOnMount = <T>({
  url,
  context,
  body,
  queryParams,
  mapper,
  callback = () => {},
  method = "get",
}: UseQueryType<T>): [Query<T>, Dispatch<SetStateAction<Query<T>>>] => {
  const usingContext = context !== undefined;
  const token = useSelector(({ auth }: State) => auth.token);
  const [contextValue, setContext] = useContext(
    usingContext ? context!! : PlaceHolderContext
  );
  const [state, setState] = useState<Query<T>>(defaultQuery);
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const setter = usingContext ? setContext : setState;
  useEffect(() => {
    query({
      url,
      setter,
      method: method!!,
      body,
      queryParams,
      headers,
      mapper,
    }).then(callback);
  }, []);

  return usingContext ? [contextValue, setContext] : [state, setState];
};

/**
 * Custom hook for making API queries and managing the state of the data.
 *
 * @template T - The type of the data returned by the API.
 * @param {Object} options - The options for the hook.
 * @param {React.Context<T> | undefined} options.context - The context to use for managing the state.
 * @returns {[
 *   (args: Omit<UseQueryType<T>, "context">) => Promise<void>,
 *   Query<T>,
 *   Dispatch<SetStateAction<Query<T>>>
 * ]} - An array containing the query function, the current state of the data, and the state setter function.
 */
export const useQuery = <T>({
  context,
}: Pick<UseQueryType<T>, "context">): [
  (args: Omit<UseQueryType<T>, "context"> & Pick<Params<T>, "responseType">) => Promise<void>,
  Query<T>,
  Dispatch<SetStateAction<Query<T>>>
] => {
  const usingContext = context !== undefined;
  const token = useSelector(({ auth }: State) => auth.token);
  const [contextValue, setContext] = useContext(
    usingContext ? context!! : PlaceHolderContext
  );
  const [state, setState] = useState<Query<T>>(defaultQuery);
  const tmpHeaders = {
    Authorization: `Bearer ${token}`,
  };

  const setter = usingContext ? setContext : setState;

  const cb = ({
    url,
    queryParams,
    headers,
    body,
    responseType,
    mapper,
    callback = () => {},
    method = "get",
  }: Omit<UseQueryType<T>, "context"> & Pick<Params<T>, "responseType">) => {
    const finalHeaders = { ...tmpHeaders, ...headers };
    return query({
      url,
      setter,
      method: method!!,
      body,
      queryParams,
      headers: finalHeaders,
      mapper,
      responseType,
    }).then(callback);
  };

  return usingContext ? [cb, contextValue, setContext] : [cb, state, setState];
};

export interface Query<T> {
  data?: T;
  isLoading: boolean;
  error: any;
  statusCode?: number;
}

export const defaultQuery: Query<any> = {
  data: undefined,
  isLoading: false,
  error: undefined,
};
export const setIsLoading =
  <T>(value: boolean) =>
  (loadable: Query<T>) => ({ ...loadable, isLoading: value });
export const setContent =
  <T>(value: T) =>
  (loadable: Query<T>) => ({ ...loadable, data: value });
export const setError =
  <T>(value: any) =>
  (loadable: Query<T>) => ({ ...loadable, error: value });
export const setStatusCode =
  <T>(value?: number) =>
  (loadable: Query<T>): Query<T> => ({ ...loadable, statusCode: value });

import { Checkbox, IconButton, Theme, alpha, createStyles, withStyles } from "@material-ui/core"
import { TreeItem, TreeItemProps } from "@material-ui/lab"
import React, { FC, forwardRef, useContext } from "react"
import { Row } from "../../../components/FlexBox"
import { StatusToUpdateContext, useDirtyTreeContext } from "../../../contexts/organizeTreeBibleContexts"
import { useDragUDFromTree, useDropUDBetween } from "../../../hooks/documentUnitDnDHooks"
import detachUD from "../../../logo/detach_ud.png"
import { DocumentUnitWithPointer, NodeModel, removeDocumentUnitInTree, updateAlert } from "../../../models/TreeModels"
import { treeColor } from "../../../palette"
import { hoverLabelStyle } from "../../../utils/DnD.utils"
import { NodeLabel, UDLabel } from "../Labels"
import boutonAlertSelect from '../../../logo/bouton_alerte_select.png'
import boutonAlertNonSelect from '../../../logo/bouton_alerte_non_select.png'
import externalLink from '../../../logo/external_link.png'
import { DocumentTypeEnum } from "../../../models/Search"

const ExternalLink: FC<{doc: DocumentUnitWithPointer}> = ({doc}) => {

  let url: string | null = null
  if(doc.type === DocumentTypeEnum.LEXPRESS) {
    url = `${process.env.REACT_APP_LEXPRESS_URL}article?query=${doc.documentId}`
  }
  if(doc.type === DocumentTypeEnum.OXYGEN) {
    url = `${process.env.REACT_APP_OXYGEN_URL}detail/${doc.documentId}/preview`
  }

  const paddingX = 12

  return (
    url === null ? <></> : (
      <a target="_blank" rel="noopener noreferrer" href={url} style={{paddingTop: 0, paddingBottom: 0, paddingRight: paddingX, paddingLeft: paddingX, display: 'flex', alignItems: 'center'}}>
        <img src={externalLink} width="20" height="20" alt="icon" />
      </a>
    )
  )
}

const KyrOrganizeNodeDropZone = ({ node, dropZoneIndex }: { node: NodeModel, dropZoneIndex: number }) => {
  const [drop, isOver] = useDropUDBetween(node, dropZoneIndex)

  return (
    <div
      id={`drop-zone-n${dropZoneIndex}`}
      ref={drop}
      style={{ height: 5, ...hoverLabelStyle(isOver) }}
    />
  )
}

interface StyledTreeItemProps extends TreeItemProps {
  borderColor?: string;
}

export const StyledTreeItem = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: ({ borderColor }: StyledTreeItemProps) =>
        `1px dashed ${borderColor || alpha(theme.palette.text.primary, 0.4)}`,
    },
  }))(forwardRef((props: StyledTreeItemProps, ref) => <TreeItem {...props} ref={ref} />));

interface ElementProps {
  doc: DocumentUnitWithPointer
  node: NodeModel
  dropZoneIndex: number
}
const Element = (
  { doc, node, dropZoneIndex }: ElementProps
) => {
  const [drag, isDragging] = useDragUDFromTree(doc)
  const [, setStatusToUpdate] = useContext(StatusToUpdateContext)


  const [tree, setTree] = useDirtyTreeContext()

  const handleRemove = () => {
    if (tree.data?.nodes === undefined) return
    const updatedNodes = removeDocumentUnitInTree(tree.data?.nodes, node.id, doc.documentId)

    setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes } })
    setStatusToUpdate(prevStatus => ({
      ...prevStatus,
      [doc.documentId]: (prevStatus[doc.documentId] || 0) - 1
    }));
  }

  const handleAlert = () => {
    if (tree.data?.nodes === undefined) return
    const updatedNodes = updateAlert(tree.data?.nodes, node.id, doc.documentId)

    setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes } })
  }

  return (
    <>
      <div style={{
        display: isDragging ? 'none' : 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
      >
        <UDLabel innerRef={drag} title={doc.title} documentId={doc.documentId} />
        <Row>
          <ExternalLink doc={doc} />
          <IconButton onClick={handleRemove} style={{paddingTop: 0, paddingBottom: 0}}>
            <img src={detachUD} width="20" height="20" alt="icon" />
          </IconButton>
          <Checkbox 
            icon={<img src={boutonAlertNonSelect} height="20" width="20" />}
            checkedIcon={<img src={boutonAlertSelect} height="20" width="20" />} 
            style={{paddingTop: 0, paddingBottom: 0}} 
            checked={doc.alert} 
            onChange={handleAlert} 
          />
        </Row>
      </div>
      <KyrOrganizeNodeDropZone dropZoneIndex={dropZoneIndex} node={node} />
    </>
  )
}

interface NodeComponentProps {
  offset: number
  node: NodeModel
  color?: string
  uppercase?: boolean
}

const NodeComponent = ({ offset, node, color, uppercase }: NodeComponentProps) => {
  return (
    <Row alignItems="start">
      <StyledTreeItem
        style={{ flexGrow: 1 }}
        borderColor={color}
        nodeId={node.id}
        label={<NodeLabel node={node} color={color} uppercase={uppercase ?? false} />}
      >
        <KyrOrganizeNodeDropZone node={node} dropZoneIndex={-1} />
        {node.documentUnits.map((du, i) => (
          <Element dropZoneIndex={i} doc={du} node={node} />
        ))}
        {node.sub.length > 0 && node.sub.map(n => (
          <NodeComponent offset={offset + 20} node={n} />
        ))}

      </StyledTreeItem>
    </Row>
  )
}

interface NodeListProps {
  nodes: NodeModel[]
  colorful: boolean
}

export const OrganizeNodeList = ({ nodes, colorful }: NodeListProps) => (
  <>
    {
      nodes.map((e, i) => (
        <NodeComponent
          uppercase
          color={colorful ? treeColor[i % treeColor.length] : 'black'}
          offset={0}
          node={e} />
      ))
    }
  </>
)

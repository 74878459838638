import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {Grid, IconButton} from "@material-ui/core";
import {openEditPublicationDialog} from "../../../redux/actions/publication.actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {PublicationSearchResult} from "../../../types/common-search";
import {PublicationStatus} from "../../../types/publication";
import {EditIcon} from "../../../icon";

type Props = {
    publication: PublicationSearchResult
}

const EditPublication = ({publication}: Props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    return (
        <>
            {publication.status === PublicationStatus.IN_PROGRESS && <Grid item>
                <Tooltip
                    title={t('publications.edit_publication') ?? ''}>
                    <IconButton
                        onClick={() => dispatch(openEditPublicationDialog(publication.id))}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </Grid>}
        </>
    );
}

export default EditPublication;

import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import {fetchAdSizes} from "../../../redux/actions/ad.actions";
import AutoCompleteFilter from "../../images/list/AutoCompleteFilter";
import {SearchContext} from "../../../types/common-search";

export default ({formatFilter, selectedAction}) => {
    const {t} = useTranslation();
    const adSizes = useSelector(({ads}: State) => ads.sizes)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAdSizes())
    }, [dispatch]);
    return (
        <AutoCompleteFilter ctx={SearchContext.ADVERTISEMENT} selectedAction={selectedAction} filters={formatFilter}
                            options={
                                adSizes.map(e => ({value: e.key, label: e.label}))}
                            label={t('images.dimensions')}
                            filterName={"formatFilter"}/>
    )
}

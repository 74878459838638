import React, {useState} from 'react'
import {
    Checkbox,
    FormControlLabel,
    Grid,
    GridSize,
    IconButton,
    MenuItem,
    Select as MuiSelect,
    TextField
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {ActionWithPayload} from "../../../redux/actions/utils.actions";
import {Autocomplete} from "@material-ui/lab";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {handleDateChange} from "../../../utils/date-utils";
import {DateTime} from "luxon";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {Controller} from "react-hook-form";
import {ClearIcon} from "../../../icon";
import {DeepPartial} from "react-hook-form/dist/types/utils";
import {OmitResetState, UnpackNestedValue} from "react-hook-form/dist/types/form";
import {FieldValues} from "react-hook-form/dist/types/fields";
import Wysiwyg from "../../articles/create/Wysiwyg";
import Tooltip from "@material-ui/core/Tooltip";

export type UpdateFnType = (action: (_: any) => ActionWithPayload, dispatch: any) => (event: any) => any

export type FormFieldProps = {
    type: "authors" | "images" | "ads" | "articles" | "publications",
    name: string,
    valueSelector: any,
    register: any,
    reset: (values?: UnpackNestedValue<DeepPartial<FieldValues>>, omitResetState?: OmitResetState) => void,
    errors: any,
    setValue: any,
    disabled?: boolean,
    action?: (..._: any) => ActionWithPayload,
    required: boolean,
    validate?: () => boolean,
    renderer?: (props: FormFieldProps, updateFn: UpdateFnType, value, dispatch, t, classes) => JSX.Element
    control: any,
    dontWrap?: boolean,
    getValues: any,
    titleColumn?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8,
    translate: boolean
    searchHandler?: any
    onChange?: any,
    parentJsx?: JSX.Element | JSX.Element[]
}

const selectGroupRecursive = (options: SelectedGroupOption[]): any => {
    return options.map(function mapId({children, ...r}) {
        if (children && children.length > 0) {
            return [<MenuItem value={r.value} key={"opt" + r.value} disabled={true}
                              style={{fontWeight: "bold"}}>{r.label}</MenuItem>, children.map(mapId)]
        } else {

            return <MenuItem value={r.value} key={"opt" + r.value}>{r.label === "" ? r.value : r.label}</MenuItem>
        }

    })

}

const update: UpdateFnType =
    (action: (_: any) => ActionWithPayload, dispatch: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(action(event.target.value))
    }

export const textRenderer = (multiline: boolean) => ({
                                                         type,
                                                         name,
                                                         disabled,
                                                         valueSelector,
                                                         register,
                                                         errors,
                                                         action,
                                                         required
                                                     }: FormFieldProps, update, value, dispatch) =>
    <TextField
        name={name}
        fullWidth={true}
        disabled={disabled}
        multiline={multiline}
        color={"secondary"}
        error={errors[name] !== undefined}
        inputRef={register({required: required, maxLength: 400})}
        value={value ?? ''}
        onChange={update(action, dispatch)}
    />

export const wysiwygRenderer = (withBold?: boolean) => ({
                                                            control,
                                                            type,
                                                            name,
                                                            disabled,
                                                            valueSelector,
                                                            register,
                                                            errors,
                                                            action,
                                                            required
                                                        }: FormFieldProps, update, value, dispatch) => {

    const val = useSelector(valueSelector) as string

    if (val !== undefined)
        return <Wysiwyg key={"wysiwyg"} withBold={withBold} defaultValue={val} onChange={(titleHtml) => {
            if (action)
                dispatch(action(titleHtml ?? ""))
        }
        }
                        name={name} control={control} required={required}/>
}


export const datePickerRenderer = ({
                                       type,
                                       name,
                                       disabled,
                                       valueSelector,
                                       register,
                                       errors,
                                       action,
                                       required
                                   }, update, value, dispatch) => {

    return <>
        <KeyboardDatePicker
            autoOk
            variant="inline"
            InputAdornmentProps={{position: "end"}}
            value={value ?? null}
            onChange={(new_date: DateTime | null | undefined) => {
                if (action) handleDateChange(dispatch(action(new_date?.toISODate())))
            }}
            format="dd/MM/yyyy"
            showTodayButton={true}
            ref={register({required: required})}
            TextFieldComponent={props => <TextField name={name} inputRef={register({required: required})} {...props}
                                                    error={errors[name] !== undefined}/>}
            disabled={disabled}
        />
        {value &&
        <IconButton style={{
            marginLeft: -70
        }}
                    onClick={() => {
                        handleDateChange(dispatch(action(null)));
                    }}>
            <ClearIcon/>
        </IconButton>}
    </>
}


export const flattenAutocomplete = (options: FlattenSelectedOption[]) => ({
                                                                              control,
                                                                              type,
                                                                              name,
                                                                              level,
                                                                              disabled,
                                                                              valueSelector,
                                                                              register,
                                                                              errors,
                                                                              setValue,
                                                                              action,
                                                                              required
                                                                          }, update, _, dispatch, t) => {
    const valSelector = useSelector(valueSelector)
    const value = options.find(x => x.value === valSelector) ?? null
    const [query, setQuery] = useState("")

    const renderOptions = (option: FlattenSelectedOption) => query !== "" ? <span>{option.label} {option.parent ? `(${option.parent})` : ""}</span> : <span
        style={{
            paddingLeft: option.level * 15,
            fontWeight: option.disable ? "bold" : 300
        }}>{option.label}</span>

    return <Controller render={data => {
        return <Autocomplete openText={t('misc.open')}
                             clearText={t('misc.clear')}
                             closeText={t('misc.close')}
                             loadingText={t('misc.loading')}
                             noOptionsText={t('misc.no-options')}
                             fullWidth={true}
                             onChange={(e, newValue: FlattenSelectedOption | null) => {
                                 dispatch(action(newValue?.value))
                                 data.onChange(e)
                                 data.value = newValue?.value
                                 setValue(name, newValue?.value);
                             }}
                             value={value}
                             getOptionLabel={(o: FlattenSelectedOption) => {
                                 return (o.parent ? o.parent + ' > ' : '') + o.label
                             }}
                             onClose={() => setQuery("")}
                             renderOption={renderOptions}
                             renderInput={(params) => (
                                 <TextField
                                     {...params}
                                     value={query}
                                     onChange={(e) => setQuery(e.target.value)}
                                     error={errors[name] !== undefined}
                                     color={"secondary"}
                                     fullWidth={true}
                                     InputProps={{
                                         ...params.InputProps,
                                         endAdornment: (
                                             <React.Fragment>
                                                 {params.InputProps.endAdornment}
                                             </React.Fragment>
                                         ),
                                     }}
                                 />)}
                             options={options}
                             disabled={disabled}
                             getOptionDisabled={(o) => o.disable}/>
    }
    }

                       name={name}
                       control={control}
                       rules={{required: required}}
                       defaultValue={value}
    />
}

export const
    selectGroupRenderer = (options?: SelectedGroupOption[]) => ({
                                                                    control,
                                                                    type,
                                                                    name,
                                                                    level,
                                                                    disabled,
                                                                    valueSelector,
                                                                    register,
                                                                    errors,
                                                                    action,
                                                                    required
                                                                }, update, _, dispatch, t) => {
        let opt = <MenuItem disabled={true}> No options</MenuItem>
        if (options !== undefined && options.length > 0) {
            opt = selectGroupRecursive(options!!)
        }

        const value = useSelector(valueSelector)

        return <>{options && options.length > 0 && <Controller
            render={data => <MuiSelect
                value={value ?? ""}
                name={name}
                fullWidth={true}
                disabled={disabled}
                error={errors[name] !== undefined}
                onChange={e => {
                    update(action, dispatch)(e)
                    data.onChange(e)
                }}>
                {opt}
            </MuiSelect>}
            name={name}
            control={control}
            rules={{required: required}}
            defaultValue={value ?? ''}
        />}
            {options && options.length === 0 && <Controller
                render={_ => <MuiSelect
                    value={null}
                    name={name}
                    disabled={disabled}
                    fullWidth={true}>
                    <MenuItem disabled={true}>{t('misc.no-option')}</MenuItem>
                </MuiSelect>}
                name={name}
                control={control}
                error={errors[name] !== undefined}
                rules={{required: required}}
                defaultValue={null}
            />}
        </>
    }

export const selectRenderer = (options: SelectOption[], resetFields?: string[], customOnChange?: (e) => boolean) => (
    {
        control,
        type,
        name,
        disabled,
        valueSelector,
        register,
        errors,
        action,
        required,
        translate,
        reset
    }, update, _, dispatch, t
) => {
    const value = useSelector(valueSelector)

    return <Controller
        render={data =>
            <MuiSelect
                value={value ?? ''}//data ? data.value ?? '' : []
                name={name}
                fullWidth={true}
                error={errors[name] !== undefined}
                disabled={disabled}
                onChange={e => {
                    if (customOnChange) {
                        const blocking = customOnChange(e);
                        if (blocking) {
                            return;
                        }
                    }
                    data.onChange(e)
                    update(action, dispatch)(e)
                    if (resetFields) {
                        reset(resetFields.reduce((a, b) => (a[b] = null, a), {}))
                    }
                }}>
                {options.map(option =>
                    <MenuItem value={option.value}
                              key={option.keyValue ?? "opt" + option.value}>{translate ? t(option.label) : option.label}</MenuItem>
                )}
            </MuiSelect>
        }
        name={name}
        control={control}
        rules={{required: required}}
        defaultValue={value ?? ''}
    />
}

export const autocompleteSelectRenderer = (options?: SelectOption[]) => (
    {
        control,
        type,
        name,
        disabled,
        valueSelector,
        register,
        errors,
        action,
        required,
        translate,
        searchHandler
    }, update, _, dispatch, t) => {
    const [open, setOpen] = React.useState(false);
    const selectedValue = useSelector(valueSelector) as SelectOption | null
    return (
        <Controller
            render={() => (<Autocomplete
                openText={t('misc.open')}
                clearText={t('misc.clear')}
                closeText={t('misc.close')}
                loadingText={t('misc.loading')}
                noOptionsText={t('misc.no-options')}
                id={name}
                style={{width: "100%"}}
                open={open}
                value={selectedValue ?? null}
                onOpen={() => {
                    setOpen(true);
                    searchHandler();
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(event: any, newValue: SelectOption | null) => {
                    dispatch(action(newValue));
                }}
                getOptionLabel={(option) => '[' + option.value + '] ' + option.label ?? ''}
                options={options ?? []}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        onChange={searchHandler}
                        color={"secondary"}
                        label={t(`misc.search`)}
                        fullWidth={true}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />)}
            name={name}
            control={control}
            rules={{required: required}}
            defaultValue={selectedValue}
        />
    );
}

export const checkboxRenderer = (valueIfTrue: any, valueIfFalse: any) => (
    {
        type,
        name,
        disabled,
        valueSelector,
        register,
        errors,
        action,
        required
    }, update, value, dispatch, t, classes) =>
    <>
        <Grid item xs={4}/>
        <Grid item xs={6}>
            <FormControlLabel
                label={
                    <Typography className={classes.title}>
                        {t(`${type}.form.${name}.label`)}
                        {(errors[name]) && <FormHelperText error={true}>
                            {t(`${type}.form.${name}.err-label`)}
                        </FormHelperText>}
                    </Typography>
                }
                control={<Checkbox
                    checked={value === valueIfTrue}
                    name={name}
                    disabled={disabled}
                    onChange={event => {
                        dispatch(action(event.target.checked ? valueIfTrue : valueIfFalse))
                    }}
                />}
            />
        </Grid>
    </>

export const bundleTagsRenderer = (
    tags: { shortLabel: string, label: string, value: any }[],
    minTags: number,
    maxTags?: number,
    resetFields?: string[]
) => ({
          getValues,
          control,
          type,
          name,
          disabled,
          valueSelector,
          register,
          errors,
          action,
          required,
          reset
      }, update, _, dispatch, t) => {
    const values = useSelector(valueSelector) as any
    return <Autocomplete
        openText={t('misc.open')}
        clearText={t('misc.clear')}
        closeText={t('misc.close')}
        loadingText={t('misc.loading')}
        noOptionsText={t('misc.no-options')}
        fullWidth={true}
        multiple={maxTags === undefined || maxTags > 1}
        disabled={disabled}
        options={tags}
        renderOption={(option) => {
            return <React.Fragment>
                <Tooltip title={option.label}>
                    <span>{option.shortLabel}</span>
                </Tooltip>
            </React.Fragment>
        }}
        getOptionLabel={option => {
            if (typeof option === "string" || typeof option === "number") {
                return tags.find(tag => tag.value === option)?.shortLabel ?? ""
            } else {
                return option.shortLabel
            }
        }}
        getOptionSelected={(option, value) => {
            return option.value === value
        }}
        value={values ?? ""}
        onChange={(e, v) => {
            dispatch(action(maxTags === 1 ? (v ? v.value : undefined) : v.map(it => typeof it === "string" ? it : it.value)))
            if (resetFields) {
                reset(resetFields.reduce((a, b) => (a[b] = null, a), {}))
            }
        }}
        renderInput={(params) => (<Controller
                defaultValue={""}
                as={<TextField
                    name={name}
                    {...params}
                    disabled={disabled}
                    variant="standard"
                />}
                name={name}
                control={control}
                rules={{
                    validate: () => {
                        if (maxTags && maxTags === 1) {
                            if (minTags === 1) {
                                return values !== undefined
                            } else {
                                return true
                            }
                        }
                        return values ? values.length >= minTags : minTags === 0
                    }
                }}
            />
        )}
    />
}

export const tagsRenderer = (
    tags: { label: string, value: any }[],
    minTags: number,
    maxTags?: number,
    resetFields?: string[],
    validValues: string[] = [],
) => ({
          getValues,
          control,
          type,
          name,
          disabled,
          valueSelector,
          register,
          errors,
          action,
          required,
          reset
      }, update, _, dispatch, t) => {
    const values = useSelector(valueSelector) as any
    return <Autocomplete
        openText={t('misc.open')}
        clearText={t('misc.clear')}
        closeText={t('misc.close')}
        loadingText={t('misc.loading')}
        noOptionsText={t('misc.no-options')}
        fullWidth={true}
        multiple={maxTags === undefined || maxTags > 1}
        disabled={disabled}
        options={tags}
        getOptionLabel={option => {
            if (typeof option === "string" || typeof option === "number") {
                return tags.find(tag => tag.value === option)?.label ?? ""
            } else {
                return option.label
            }
        }}
        getOptionSelected={(option, value) => {
            return option.value === value
        }}
        value={values ?? ""}
        onChange={(e, v) => {
            dispatch(action(maxTags === 1 ? (v ? v.value : undefined) : v.map(it => typeof it === "string" ? it : it.value)))
            if (resetFields) {
                reset(resetFields.reduce((a, b) => (a[b] = null, a), {}))
            }
        }}
        renderInput={(params) => (<Controller
                defaultValue={""}
                as={<TextField
                    name={name}
                    {...params}
                    disabled={disabled}
                    variant="standard"
                />}
                name={name}
                control={control}
                rules={{
                    validate: {
                        count: () => {
                            if (maxTags && maxTags === 1) {
                                if (minTags === 1) {
                                    return values !== undefined
                                } else {
                                    return true
                                }
                            }
                            return values ? values.length >= minTags : minTags === 0
                        },
                        overwrite: () => {
                            if (validValues.length > 0) {
                                const bad = values.filter(val => !validValues.includes(val));
                                return bad.length == 0 ? true : `Les revues ne sont pas compatibles : ${bad.join(', ')}`;
                            }
                            return true;
                        },
                    }
                }}
            />
        )}
    />
};

const useStyles = makeStyles(() => ({
    root: {
        margin: '12px 0'
    },
    title: {
        padding: '12px 0'
    }
}));

export type SelectOption = {
    value: any,
    label: string,
    keyValue?: string
}

export type SelectedGroupOption = {
    value: any,
    label: string,
    children?: SelectedGroupOption[]
}
export type FlattenSelectedOption = {
    value: any,
    label: string,
    disable: boolean,
    level: number,
    parent?: string
}

export default function LexFormField(props: FormFieldProps) {
    const {t} = useTranslation()
    const value = useSelector(props.valueSelector) ?? null
    const dispatch = useDispatch()
    const classes = useStyles()

    const wrapInControl = (elem, {
        type,
        errors,
        name,
        dontWrap,
        required,
        titleColumn,
        parentJsx
    }: FormFieldProps, t) => {
        if (dontWrap) {
            return elem
        } else {
            let result: JSX.Element[] = []

            if (parentJsx) {
                result = Array.isArray(parentJsx) ? parentJsx : [parentJsx]
            }
            result = [...result, <Grid container item direction="column">
                <Grid container item direction="row" alignItems="center" justify="space-between">
                    <Grid item xs={titleColumn ? titleColumn : 4}>
                        <Typography className={classes.title}>
                            {t(`${type}.form.${name}.label`)} {required ? ' *' : ''}
                        </Typography>
                    </Grid>

                    <Grid container item xs={titleColumn ? 12 - titleColumn as GridSize : 6} alignItems={"center"}>
                        {elem}
                    </Grid>
                </Grid>

                {(errors[name]) && <Grid item>
                    <FormHelperText error={true}>
                        {errors[name].type === 'overwrite' ? errors[name].message : t(`${type}.form.${name}.err-label`)}
                    </FormHelperText>
                </Grid>}
            </Grid>]
            return result
        }
    }

    return wrapInControl(
        props.renderer
            ? props.renderer(props, update, value, dispatch, t, classes)
            : textRenderer(false)(props, update, value, dispatch),
        props, t)
}

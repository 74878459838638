import React from "react";
import {BundlePlanFormMode, BundlePlanResponse, BundlePlanSearchItem} from "../../../../types/bundle";
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import {Delete, Edit} from "@material-ui/icons";
import {CopyIcon} from "../../../commons/icon/icons";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {setAdminBundlePlanFormMode} from "../../../../redux/actions/admin/bundle/admin-bundle.actions";
import {useDispatch} from "react-redux";

type Props = {
    bundlePlan?: BundlePlanSearchItem | BundlePlanResponse
    displayMode?: "LIST" | "EDIT"
    openConfirmationDialog?: any
    setDeletingId?: any
}

const EditActionCommand = (bundlePlan, displayMode?: string) => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const editBundlePlan = () => {
        dispatch(setAdminBundlePlanFormMode(BundlePlanFormMode.EDIT));
        history.push(t(`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}/${t('routes.admin.bundle.edit')}/${bundlePlan.id}`));
    }

    if (displayMode === "LIST") {
        return (
            <Tooltip
                title={t('misc.edit') ?? ''}>
                <IconButton onClick={editBundlePlan}>
                    <Edit/>
                </IconButton>
            </Tooltip>
        );
    }

    return (<></>);
}

const DuplicateActionCommand = (bundlePlan, displayMode?: string) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const duplicateBundlePlan = () => {
        dispatch(setAdminBundlePlanFormMode(BundlePlanFormMode.DUPLICATE));
        history.push(t(`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}/${t('routes.admin.bundle.duplicate')}/${bundlePlan.id}`));
    }

    if (displayMode === "LIST") {
        return (
            <Tooltip
                title={t('misc.copy') ?? ''}>
                <IconButton onClick={duplicateBundlePlan}>
                    <CopyIcon/>
                </IconButton>
            </Tooltip>
        );
    }

    return (<></>);
}

const DeleteActionCommand = (bundlePlan, openConfirmationDialog, setDeletingId, displayMode?: string) => {
    const {t} = useTranslation();

    const openConfirmationDialogParent = () => {
        openConfirmationDialog(true);
        setDeletingId(bundlePlan.id);
    }

    if (displayMode === "LIST") {
        return (
            <Tooltip
                title={t('admin.bundle.list.delete') ?? ''}>
                <IconButton onClick={openConfirmationDialogParent}>
                    <Delete/>
                </IconButton>
            </Tooltip>
        );
    }

    return (<></>);
}

const BundlePlanActionsList = ({bundlePlan, displayMode = "LIST", openConfirmationDialog, setDeletingId}: Props) => {
    return (
        <Grid container spacing={1} direction={"row"}>
            <Grid item xs={4}>
                {EditActionCommand(bundlePlan, displayMode)}
            </Grid>
            <Grid item xs={4}>
                {DuplicateActionCommand(bundlePlan, displayMode)}
            </Grid>
            <Grid item xs={4}>
                {DeleteActionCommand(bundlePlan, openConfirmationDialog, setDeletingId, displayMode)}
            </Grid>
        </Grid>
    );
}

export default BundlePlanActionsList

import {combineReducers} from "redux";

import autocompleteAuthor from "./autocomplete-authors.reducer";
import autocompleteCountryCity from "./autocomplete-country-city.reducer";
import autocompletePlanNodes from "./autocomplete-plan-nodes.reducer";

export default combineReducers({
    autocompleteAuthor,
    autocompleteCountryCity,
    autocompletePlanNodes
});
import {Observable, of} from "rxjs";
import {StateObservable} from "redux-observable";
import {State} from "../../reducers";
import {EpicDependencies} from "../../store";
import {catchError, concatMap, filter, map} from "rxjs/operators";
import {AxiosStatic} from "axios";
import {fromPromise} from "rxjs/internal-compatibility";
import {
    autoCompleteAuthorsError,
    autoCompleteAuthorsSuccess,
    isAutoCompleteAuthorsAction
} from "../../actions/search/autcocomplete-authors.actions";
import {Action} from "../../actions/utils.actions";
import {AuthorSearchFilters, SearchResults} from "../../../types/common-search";

export function autoCompleteAuthors(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isAutoCompleteAuthorsAction),
        concatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return postAuthorsSearchRequest(axios, payload, token)
        }),
    );
}

function postAuthorsSearchRequest(axios: AxiosStatic, query: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };


    const data = {
        query: query,
        page: 0,
        size: 20
    } as AuthorSearchFilters;

    return fromPromise(axios.post<SearchResults>("/api/author/search", data, {headers: headers}))
        .pipe(
            map((response) => autoCompleteAuthorsSuccess(response.data.result.map(e => {
                return {
                    "key": e.id,
                    "value": `${e.lastName}${e.particle ? ` (${e.particle})` : ''}, ${e.firstName}`
                }
            }))),
            catchError(err =>
                of(autoCompleteAuthorsError(err))
            )
        );
}
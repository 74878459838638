import {
    ArticleSearchFilters,
    Filters,
    ImageSearchFilters,
    PageSize,
    PublicationSearchFilters,
    Sort
} from "../types/common-search";
import {buildQueryParams, ParameterType, readQueryParams} from "./query-param.utils";
import {baseParams} from "./common-utils";
import {EditablePublication, Publication, PublicationStatus} from "../types/publication";
import {BundleResponse} from "../types/bundle";
import {PlanNode} from "../types/plan-node";
import {bundleAttachments, cdfAttachments} from "../types/article";
import {PublicationDialogForm} from "../redux/reducers/publication/new-publication-dialog.reducer";
import { DateTime } from 'luxon'


const params = {
    ...baseParams,
    periodicalIdFilter: {
        type: ParameterType.TOKEN_ARRAY,
        setEmpty: true
    },
    startDateFilter: {
        type: ParameterType.DATE_RANGE
    },
    editionTypeFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    statusFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    imageFilter: {
        type: ParameterType.TOKEN_ARRAY
    }
}

export const readPublicationQueryParams = (search: string): PublicationSearchFilters => {
    return readQueryParams(params, search);
}

export const buildPublicationQueryParams = (filters: PublicationSearchFilters): string => {
    return buildQueryParams(params, filters);
}

export const forcePublicationOptions = (isAdMode: boolean, publication?: Publication, queryId?: string, size?: PageSize, page?: number, sort?: Sort[]): Filters => {
    return !isAdMode ? {
        publicationFilter: publication ? [publication.periodicalId + '_' + publication.year + '_' + publication.number] : undefined,
        attachedFilterBis: [...cdfAttachments, "NONE"],
        query: queryId === "" ? undefined : queryId,
        size: size,
        page: page,
        sort: sort ? sort : [{attr: 'UPDATE', order: 'DESC'}]
    } : {
        collectionFilter: ['ADVERTISEMENT'],
        periodicalFilter: publication?.periodicalId ? [publication.periodicalId] : [],
        endDateFilter: { start: DateTime.now().toFormat('yyyy-MM-dd') },
        enableFilter: [true],
        query: queryId === "" ? undefined : queryId,
        size: size,
        page: page,
        sort: sort ? sort : [{attr: 'UPDATE', order: 'DESC'}]
    }
}

export function mandatoryPublicationFieldsCompleted(publication: EditablePublication | PublicationDialogForm): boolean {
    const year = publication.year as unknown as string
    return !(publication.periodicalId === "" || year === "" ||
        publication.number === "" || publication.startDate === "");

}

export const forceBundleOptions = (isAdMode: boolean, bundle?: BundleResponse, attachedOnly?: boolean, publication?:
    (EditablePublication & { id: number; status: PublicationStatus; plan?: PlanNode[] }) | undefined, queryId?: string, size?: PageSize, page?: number, sort?: Sort[]): Filters => {
    return !isAdMode ? {
        publicationFilter: publication ? [publication.periodicalId + "_" + publication.year + "_" + publication.number] : undefined,
        periodicalFilter: [bundle?.periodical],
        attachedFilterBis: attachedOnly ? [...bundleAttachments] : [...cdfAttachments, ...bundleAttachments],
        query: queryId === "" ? undefined : queryId,
        bundleFilter: attachedOnly ? [bundle?.id] : undefined,
        onlyBundle: [false],
        size: size,
        page: page,
        sort: sort ? sort : [{attr: 'UPDATE', order: 'DESC'}]
    } as ArticleSearchFilters : {
        collectionFilter: ['ADVERTISEMENT'],
        query: queryId === "" ? undefined : queryId,
        sort: sort ? sort : [{attr: 'UPDATE', order: 'DESC'}]
    } as ImageSearchFilters
}

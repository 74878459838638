import {EMPTY, merge, Observable, of} from "rxjs";
import {Action, ActionWithPayload} from "../actions/utils.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {catchError, filter, flatMap, map, mergeMap, switchMap, tap} from "rxjs/operators";
import {EpicDependencies} from "../store";
import {AxiosResponse, AxiosStatic} from "axios";
import {fromPromise} from "rxjs/internal-compatibility";
import {
    deleteAuthorSuccess,
    fetchAuthorError,
    fetchAuthorSuccess,
    isDeleteAuthorAction,
    isDeleteAuthorSuccessAction,
    isFetchAuthorAction,
    isReactivateImageAuthorAction,
    isRemoveMultipleImages,
    isSaveAuthorAction,
    isSaveAuthorSuccessAction,
    isToggleAuthorEnabledAction,
    reactivateImageAuthorSuccess,
    removeMultipleImagesSuccess,
    saveAuthorError,
    saveAuthorSuccess,
    toggleAuthorEnabledSuccess,
} from "../actions/author.actions";

import {AuthorType, EditableAuthor, isSaved, PersistedAuthor} from "../../types/author";
import {isAddressEmpty, isEmailEmpty, isPhoneNumberEmpty} from "../../utils/author-utils";
import {notify, notifyError, notifySuccess} from "../actions/notification.actions";
import i18n from 'i18next';
import {DeleteOrDisabledImage} from "../../types/image";

function _deleteOrUpdate(axios: AxiosStatic, imagesIds: string[], token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post<DeleteOrDisabledImage>(`/api/author/images/delete`, imagesIds, {headers}))
        .pipe(
            map((response) => removeMultipleImagesSuccess(response.data)),
            catchError(err => merge(
                of(notifyError(+err.response.status, {type: 'AUTHOR'}))
            ))
        );
}

export function deleteOrDisableAuthorImages(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isRemoveMultipleImages),
        flatMap(({payload}) => {
            const imagesIds = state$.value.author.current!.images
                .filter((_, idx) => payload.indexes.includes(idx))
                .map(({id}) => id)

            const token = state$.value.auth.token ?? "";
            return _deleteOrUpdate(axios, imagesIds, token)
        }),
    );
}


function _enable(axios: AxiosStatic, imageId: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.put<string>(`/api/author/image/${imageId}/enable`, null, {headers}))
        .pipe(
            map(() => reactivateImageAuthorSuccess(imageId)),
            catchError(err => merge(
                of(notifyError(+err.response.status, {type: 'AUTHOR'}))
            ))
        );
}

export function enableAuthorImage(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isReactivateImageAuthorAction),
        flatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return _enable(axios, payload, token)
        }),
    );
}


function fetch(axios: AxiosStatic, id: string, edit: boolean, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get<PersistedAuthor>(`/api/author/${id}`, {headers}))
        .pipe(
            map((response) => fetchAuthorSuccess({author: response.data, edit})),
            catchError(err => merge(
                of(fetchAuthorError(id)),
                of(notifyError(+err.response.status, {type: 'AUTHOR'}))
            ))
        );
}


export function fetchAuthor(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchAuthorAction),
        flatMap(({payload}) => {
            const {id, edit = false} = payload;
            const token = state$.value.auth.token ?? "";
            return fetch(axios, id, edit, token)
        }),
    );
}


const clean = <T extends EditableAuthor>(author: T): T => {

    const reset = author.type === AuthorType.ORGANISATION ? {
        firstName: '',
        particle: ''
    } : {};

    const images = author.images
        .map(image => ({
            ...image,
            sources: image.sources
        }));

    return {
        ...author,
        ...reset,
        images,
        functions: author.functions.filter(value => value),
        emails: author.emails.filter(isEmailEmpty),
        phoneNumbers: author.phoneNumbers.filter(isPhoneNumberEmpty),
        addresses: author.addresses.filter(isAddressEmpty),
        profiles: author.profiles.filter(({type, value}) => type && value)
    }
}

function create(axios: AxiosStatic, author: EditableAuthor, token: string): Observable<AxiosResponse<PersistedAuthor>> {
    const headers = {'Authorization': `Bearer ${token}`};
    return fromPromise(axios.post<PersistedAuthor>(`/api/author`, author, {headers}));
}

function update(axios: AxiosStatic, author: PersistedAuthor, token: string): Observable<AxiosResponse<PersistedAuthor>> {
    const headers = {'Authorization': `Bearer ${token}`};
    return fromPromise(axios.put<PersistedAuthor>(`/api/author/${author.id}`, author, {headers}));
}

export function refreshAuthorListOnDeleteSuccess(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    return action$.pipe(
        filter(isDeleteAuthorSuccessAction)
    )
}

export function deleteAuthor(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isDeleteAuthorAction),
        mergeMap(action => {
            const token = state$.value.auth.token ?? "";
            const headers = {'Authorization': `Bearer ${token}`};

            return fromPromise(axios.delete(`/api/author/${action.payload.id}`, {headers}))
                .pipe(
                    switchMap(_ => of<ActionWithPayload>(
                        notify(i18n.t('authors.delete_success'), {variant: 'success'}),
					)),
                    catchError((err) => of(
                        notifyError(err.response.status === 400 ? err.response.data.message : err.response.status, {
                            type: 'AUTHOR'
                        })
                    )))
        })
    );
}

export function toggleAuthorEnabled(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies) {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isToggleAuthorEnabledAction),
        flatMap(action => {
            const token = state$.value.auth.token ?? "";
            const headers = {'Authorization': `Bearer ${token}`};

            return fromPromise(axios.put<PersistedAuthor>(
                `/api/author/${action.payload.id}/status?enable=${action.payload.flag}`,
                '',
                {headers}))
                .pipe(
                    mergeMap(_ => merge(of<ActionWithPayload>(
                        toggleAuthorEnabledSuccess(action.payload.flag),
                        notifySuccess(i18n.t(action.payload.flag ? 'authors.enable_success' : 'authors.disable_success')))),
                    ),
                    catchError((err) => of(notifyError(+err.response.status, {type: 'AUTHOR'})))
                )
        })
    )
}

export function saveAuthor(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSaveAuthorAction),
        flatMap(() => {
            const token = state$.value.auth.token ?? "";
            const author = clean(state$.value.author.current!);
            const observable = isSaved(author)
                ? update(axios, author, token)
                : create(axios, author, token);
            return observable.pipe(
                mergeMap((response) => merge(
                    of<ActionWithPayload>(saveAuthorSuccess({author: response.data}),
                        notifySuccess(i18n.t('authors.save_success')))),
                ),
                catchError(err => merge(
                    of(saveAuthorError('')),
                    of(notifyError(+err.response.status, {type: 'AUTHOR'})))
                )
            );
        })
    )
}

export function redirectAfterSaveAuthor(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isSaveAuthorSuccessAction)
    ).subscribe(({payload}) => {
        history.push(`/${i18n.t('routes.author.root')}/${payload.author.id}`)
    });
    return EMPTY;
}

import React, { useState } from "react";
import { TileContext } from "../../contexts/administrateTreeContexts";
import { ChildrenProps } from "../../types";

export const AdministrateTreeProviders = ({ children }: ChildrenProps) => {
  const [tiles, setTitles] = useState<string[]>([])

  return (
    <TileContext.Provider value={[tiles, setTitles]}>
      {children}
    </TileContext.Provider>
  )
}
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Box from "@material-ui/core/Box";
import {useSelector} from "react-redux";
import {State} from "../../redux/reducers";
import {Link as RouterLink} from "react-router-dom";

type Props = {
    currentPage: string,
    query: string,
    countResult: number
}

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(1, 1)
    },
    topTitle: {
        fontWeight: 200,
        marginBottom: theme.spacing(2)
    },
    MuiBadgeRoot: {
        "&:first-child": {
            marginLeft: 15
        },
        marginRight: 15
    }
}));

export default ({currentPage, query, countResult}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const count = useSelector(({quickSearch}: State) =>
        quickSearch.count
    );

    return (
        <Grid item xs={6} className={classes.root}>
            <Box flexDirection="row">
                <Typography variant="h5" component="h1" className={classes.topTitle}>
                    {countResult} {t('quickSearch.result_for')} "{query}" {t('misc.in')} {t(`quickSearch.${currentPage}`)}
                </Typography>
            </Box>
            <Box flexDirection="row">
                <Typography>
                    Voir les résultats pour
                    {currentPage !== 'author' &&
                    <Badge color="secondary"
                           badgeContent={count.find(e => e.type === 'author') ? count.find(e => e.type === 'author')!!.value : 0}
                           className={classes.MuiBadgeRoot}>
                        <Link
                            to={`/${t('routes.author.root')}?query=${query}`}
                            component={RouterLink}>Auteurs</Link>
                    </Badge>
                    }

                    {currentPage !== 'image' &&
                    <Badge color="secondary"
                           badgeContent={count.find(e => e.type === 'image') ? count.find(e => e.type === 'image')!!.value : 0}
                           className={classes.MuiBadgeRoot}>
                        <Link
                            to={`/${t('routes.image.root')}?query=${query}`}
                            component={RouterLink}>Images</Link>
                    </Badge>
                    }

                    {currentPage !== 'ad' &&
                    <Badge color="secondary"
                           badgeContent={count.find(e => e.type === 'ad') ? count.find(e => e.type === 'ad')!!.value : 0}
                           className={classes.MuiBadgeRoot}>
                        <Link
                            to={`/${t('routes.ad.root')}?query=${query}`}
                            component={RouterLink}>Publicités</Link>
                    </Badge>
                    }

                    {currentPage !== 'parution' &&
                    <Badge color="secondary"
                           badgeContent={count.find(e => e.type === 'parution') ? count.find(e => e.type === 'parution')!!.value : 0}
                           className={classes.MuiBadgeRoot}>
                        <Link
                            to={`/${t('routes.publication.root')}?query=${query}`}
                            component={RouterLink}>Parutions</Link>
                    </Badge>
                    }

                    {currentPage !== 'article' &&
                    <Badge color="secondary"
                           badgeContent={count.find(e => e.type === 'article') ? count.find(e => e.type === 'article')!!.value : 0}
                           className={classes.MuiBadgeRoot}>
                        <Link
                            to={`/${t('routes.article.root')}?query=${query}`}
                            component={RouterLink}>Articles</Link>
                    </Badge>
                    }
                </Typography>
            </Box>
        </Grid>
    );
}

import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

type Props = {
    label: string
}

const useStyles = makeStyles(() => ({
    title: {
        fontWeight: 400,
        fontSize: '0.9rem',
        color: 'rgba(0, 0, 0, 0.75)'
    },
}));

export default ({label}: Props) => {
    const classes = useStyles();

    return (
        <Typography className={classes.title}>
            {label}
        </Typography>
    );
}

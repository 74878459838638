import {IconButton, Tooltip} from "@material-ui/core";
import {DeleteIcon, DownloadIcon} from "../../../icon";
import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import {downloadImages} from "../../../utils/common-utils";
import GlobalGroupActions from "../../authors/list/GlobalGroupActions";
import {deleteManyAds, toggleManyAdDisabled} from "../../../redux/actions/ad.actions";
import {shouldDeactivateDisable} from "../../../utils/image.utils";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import Auth from "../../Auth";
import {ADMIN, ADMIN_ADS} from "../../../utils/roles";
import {notify} from "../../../redux/actions/notification.actions";
import {AdSearchResult, SearchContext} from "../../../types/common-search";
import {DisableIcon, EnableIcon} from "../../commons/icon/icons";

type Props = {
    grid?: boolean
}

export default ({grid}: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);

    const [ads, selected, allPageSelected, allSelected, totalCount]: [AdSearchResult[], string[], boolean, boolean, number] =
        useSelector(({search}: State) => {
            const store = search.common[SearchContext.ADVERTISEMENT];
            return [store.searchResults as AdSearchResult[], store.selectedIds, store.allPageSelected, store.allSelected, store.totalCount];
        })

    const handleAdDeletion = () => {
        dispatch(deleteManyAds(selected))
    }

    const handleToggleAdActivated = (flag: boolean) => () => {
        dispatch(toggleManyAdDisabled({ids: selected, flag}))
    }

    const shouldDeleteBeDeactivated = useMemo(
        () => shouldDeactivateDisable(ads.filter(ad => selected.indexOf(ad.id) > -1)),
        [ads, selected]
    )

    const downloadImagesAction = () => {
        const imagesToDownload = ads.filter(it => selected.indexOf(it.id) !== -1);
        if (allSelected || imagesToDownload.length > 20) {
            dispatch(notify(t('misc.limit_download_size_message'), {
                variant: 'error'
            }));
        } else {
            downloadImages(imagesToDownload.map(it => ({id: it.id ?? '', collection: it.collection ?? 'UNKNOWN'})))
        }
    }

    return (<>
            <ConfirmationDialog
                title={t('ads.delete_confirmation', {count: selected.length})}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default', action: () => openConfirmationDialog(false)},
                    {label: t('dialogs.confirm'), action: handleAdDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            {selected.length > 0 &&
            <GlobalGroupActions ctx={SearchContext.ADVERTISEMENT} grid={grid} allSelected={allSelected}
                                totalCount={totalCount}
                                allPageSelected={allPageSelected}
                                selectedCount={selected.length} type={"ads"}>
                <Tooltip title={t('ads.exportAll') ?? ''}>
                        <span>
                            <IconButton color="inherit" disabled={!selected}
                                        onClick={downloadImagesAction}>
                                <DownloadIcon/>
                            </IconButton>
                        </span>
                </Tooltip>

                <Auth requiredRoles={[ADMIN, ADMIN_ADS]}>
                    <Tooltip title={t('ads.enable') ?? ''}>
                        <span>
                        <IconButton color="inherit" disabled={selected.length === 0}
                                    onClick={handleToggleAdActivated(false)}>
                            <EnableIcon/>
                        </IconButton>
                            </span>
                    </Tooltip>
                </Auth>

                <Auth requiredRoles={[ADMIN, ADMIN_ADS]}>
                    <Tooltip title={t('ads.disable') ?? ''}>
                        <span>
                        <IconButton color="inherit" disabled={selected.length === 0}
                                    onClick={handleToggleAdActivated(true)}>
                            <DisableIcon/>
                        </IconButton>
                            </span>
                    </Tooltip>
                </Auth>

                <Auth requiredRoles={[ADMIN, ADMIN_ADS]}>
                    <Tooltip title={t('ads.delete') ?? ''}>
                        <span>
                            <IconButton
                                color="inherit"
                                disabled={selected.length === 0 || shouldDeleteBeDeactivated}
                                onClick={() => openConfirmationDialog(true)}>
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Auth>
            </GlobalGroupActions>
            }
        </>
    )
}

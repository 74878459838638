import React from "react";
import {PublicationSearchFilters, SearchContext} from "../../../types/common-search";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {ClearIcon} from "../../../icon";
import ListItem from "../../commons/ListItem";
import DateRangePicker from "../../commons/DateRangePicker";
import {resetSearchQueryParams, updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {maxDate, minDate} from "../../../utils/common-utils";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import PublicationStatusFilter from "./PublicationStatusFilter";
import EditionTypeFilter from "./EditionTypeFilter";
import PeriodicalAutoComplete from "../../authors/list/PeriodicalAutoComplete";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import {buildPublicationQueryParams} from "../../../utils/publication-utils";

type Props = {
    filters: PublicationSearchFilters
}

const useStyles = makeStyles(() => ({
    paper: {
        borderBottom: "none"
    },
    init: {
        marginBottom: 10
    }
}))

export default function PublicationFilters({filters}: Props) {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const updatePeriodic = (value: string[]) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.PUBLICATION,
            field: 'periodicalIdFilter',
            value
        }));
    }

    return (
        <>
            <Grid container alignItems="center" className={classes.init}>
                <Button onClick={() => dispatch(resetSearchQueryParams(SearchContext.PUBLICATION))}
                        startIcon={<ClearIcon/>}>Réinitialiser</Button>

                {(filters.imageFilter && filters.imageFilter.length > 0) && <><Divider orientation="vertical" flexItem/>
                    <Button variant={"text"} component={RouterLink}
                            to={`/${t('routes.article.root')}?${buildPublicationQueryParams({
                                ...filters,
                                imageFilter: undefined
                            })}`}
                            startIcon={<ClearIcon/>}>{filters.imageFilter.join(", ")}
                    </Button>
                </>}
            </Grid>

            <Paper variant="outlined" square className={classes.paper}>
                <List component="div" disablePadding>
                    <ListItem label={'Revue'}>
                        <PeriodicalAutoComplete selectedOptions={filters.periodicalIdFilter}
                                                selectedAction={updatePeriodic}/>
                    </ListItem>
                    <ListItem label={t('publications.filters.startDate')}>
                        <DateRangePicker
                            start={filters.startDateFilter?.start}
                            end={filters.startDateFilter?.end}
                            onDatesChange={(values) => dispatch(updateSearchQueryParam({
                                ctx: SearchContext.PUBLICATION,
                                field: "startDateFilter",
                                value: values
                            }))}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </ListItem>
                    <PublicationStatusFilter statusFilter={filters.statusFilter}/>
                    <EditionTypeFilter statusFilter={filters.editionTypeFilter}/>
                </List>
            </Paper>
        </>
    )
}

import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import {addSearchQueryParam, removeSearchQueryParam} from "../../../redux/actions/common-search.actions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useDispatch} from "react-redux";
import {ArticleStatus} from "../../../types/article";
import MemoCheckbox from "../../commons/MemoCheckbox";
import {SearchContext} from "../../../types/common-search";
import {Divider} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import {PublicationStatus} from "../../../types/publication";


const useStyles = makeStyles(() => ({
    divider: {
        width: '100%',
        height: 2
    }
}));

export default ({statusFilter}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChange = (value: string) => (event, check) => {
        if (check) {
            dispatch(addSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'statusFilter',
                value: value
            }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'statusFilter',
                value: value
            }))
        }
    }

    return (
        <ListItem label={t('articles.status.name')}>
            <Grid container>
                <Grid container>
                    <FormGroup row>
                        <FormControlLabel
                            control={<MemoCheckbox
                                onChange={handleChange(ArticleStatus.IN_PROGRESS)}
                                checked={statusFilter.some((e) => e === ArticleStatus.IN_PROGRESS)}
                            />}
                            label={t('articles.status.' + ArticleStatus.IN_PROGRESS)}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <FormControlLabel
                            control={<MemoCheckbox
                                onChange={handleChange(ArticleStatus.READY)}
                                checked={statusFilter.some((e) => e === ArticleStatus.READY)}
                            />}
                            label={t('articles.status.' + ArticleStatus.READY)}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <FormControlLabel
                            control={<MemoCheckbox
                                onChange={handleChange(ArticleStatus.PUBLISHING)}
                                checked={statusFilter.some((e) => e === ArticleStatus.PUBLISHING)}
                            />}
                            label={t('articles.status.' + ArticleStatus.PUBLISHING)}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <FormControlLabel
                            control={<MemoCheckbox
                                onChange={handleChange(ArticleStatus.PUBLISHED)}
                                checked={statusFilter.some((e) => e === ArticleStatus.PUBLISHED)}
                            />}
                            label={t('articles.status.' + ArticleStatus.PUBLISHED)}
                        />
                    </FormGroup>
                </Grid>
                <Divider className={classes.divider}/>
                <Grid container>
                    <FormGroup row>
                        <FormControlLabel
                            control={<MemoCheckbox
                                onChange={handleChange(ArticleStatus.PLACARD_GENERATION)}
                                checked={statusFilter.some((e) => e === ArticleStatus.PLACARD_GENERATION)}
                            />}
                            label={t('articles.status.' + ArticleStatus.PLACARD_GENERATION)}
                        />
                    </FormGroup>
                    <FormGroup row>
                        <FormControlLabel
                            control={<MemoCheckbox
                                onChange={handleChange(ArticleStatus.ONLINE)}
                                checked={statusFilter.some((e) => e === ArticleStatus.ONLINE)}
                            />}
                            label={t('articles.pushed_online')}
                        />
                    </FormGroup>

                    <FormGroup row>
                        <FormControlLabel
                            control={<Checkbox
                                onChange={handleChange(ArticleStatus.NOT_ONLINE)}
                                checked={statusFilter.some((e) => e === ArticleStatus.NOT_ONLINE)}
                            />}
                            label={t('articles.status.' + ArticleStatus.NOT_ONLINE)}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </ListItem>
    )
}

import {Action} from "../../actions/utils.actions";
import {AdSearchResult, ImageSearchResult, SearchContext} from "../../../types/common-search";
import {
    iSelectOneElementAction,
    isSearchAction,
    isSearchErrorAction,
    isSearchSuccessAction,
    isSelectAllAction,
    isSelectAllPageAction,
    isUpdateSpecificElementInSearchListAction
} from "../../actions/common-search.actions";

export type SearchStatus = 'NONE' | 'PENDING' | 'LOADED' | 'ERROR'

export type Base = {
    status: SearchStatus,
    totalCount: number,
    allSelected: boolean,
    allPageSelected: boolean,
    selectedIds: string[],
    searchResults: any[]
}

type CommonSearchState = {
    [SearchContext.AUTHOR]: Base
    [SearchContext.IMAGE]: Base & { searchResults: ImageSearchResult[] }
    [SearchContext.ADVERTISEMENT]: Base & { searchResults: AdSearchResult[] }
    [SearchContext.PUBLICATION]: Base
    [SearchContext.ARTICLE]: Base
    [SearchContext.PUBLICATION_ARTICLE]: Base
    [SearchContext.PUBLICATION_AD]: Base
    [SearchContext.BUNDLE_ARTICLE]: Base
    [SearchContext.BUNDLE_AD]: Base
}

export const base: Base = {
    status: 'NONE',
    totalCount: 0,
    allSelected: false,
    allPageSelected: false,
    selectedIds: [],
    searchResults: []
};

const init: CommonSearchState = {
    [SearchContext.AUTHOR]: base,
    [SearchContext.IMAGE]: base,
    [SearchContext.ADVERTISEMENT]: base,
    [SearchContext.PUBLICATION]: base,
    [SearchContext.ARTICLE]: base,
    [SearchContext.PUBLICATION_ARTICLE]: base,
    [SearchContext.PUBLICATION_AD]: base,
    [SearchContext.BUNDLE_ARTICLE]: base,
    [SearchContext.BUNDLE_AD]: base,
};

const reducer = (state = init, action: Action): CommonSearchState => {
    if (isSearchAction(action)) {
        const {ctx} = action.payload
        const former = state[ctx]
        return {
            ...state,
            [ctx]: {
                ...former,
                status: 'PENDING'
            }
        };
    } else if (isUpdateSpecificElementInSearchListAction(action)) {
        const {ctx, id, field, value} = action.payload
        const former = state[ctx]

        const oldValue = state[ctx].searchResults.find(x => x.id === id)

        if (!oldValue) {
            return state
        }

        const updateValue = {
            ...oldValue,
            [field]: value
        }

        return {
            ...state,
            [ctx]: {
                ...former,
                searchResults: former.searchResults.map(x => {
                    if (x.id === id) {
                        return updateValue
                    } else {
                        return x
                    }
                })
            }
        };
    } else if (isSearchSuccessAction(action)) {
        const {ctx, results,} = action.payload
        const former = state[ctx]
        return {
            ...state,
            [ctx]: {
                ...former,
                status: 'LOADED',
                searchResults: results.result,
                totalCount: results.resultCount,
                selectedIds: [],
                allPageSelected: false
            }
        };
    } else if (isSearchErrorAction(action)) {
        return state;
    } else if (isSelectAllAction(action)) {
        const {ctx, all} = action.payload;
        const former = state[ctx];
        const update = all && former.searchResults ? {
            selectedIds: former.searchResults.map(r => r.id),
            allPageSelected: all,
            allSelected: all,
        } : {
            selectedIds: [],
            allPageSelected: false,
            allSelected: false,
        };
        return {
            ...state,
            [ctx]: {
                ...former,
                ...update,
            }
        };
    } else if (isSelectAllPageAction(action)) {
        const {ctx, allPages} = action.payload;
        const former = state[ctx];
        const update = allPages && former.searchResults ? {
            selectedIds: former.searchResults.map(r => r.id),
            allPageSelected: allPages,
        } : {
            selectedIds: [],
            allPageSelected: allPages,
        };
        return {
            ...state,
            [ctx]: {
                ...former,
                ...update,
            }
        }
    } else if (iSelectOneElementAction(action)) {
        const {ctx, id} = action.payload;
        const former = state[ctx];
        const checked = former.selectedIds.includes(id);
        const selectedIds = checked ? former.selectedIds.filter(cur => cur !== id) : [...former.selectedIds, id];
        return {
            ...state,
            [ctx]: {
                ...former,
                selectedIds,
                allPageSelected: checked ? false : former.allPageSelected,
                allSelected: checked ? false : former.allPageSelected
            }
        };
    } else {
        return state;
    }
}

export default reducer

import {AxiosStatic} from "axios";
import {EMPTY, merge, Observable, of} from "rxjs";
import {Action, ActionWithPayload} from "../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, filter, flatMap, map, mergeMap} from "rxjs/operators";
import {notifyError, notifySuccess} from "../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import {
    createBundleError,
    createBundleSuccess,
    fetchBundleError,
    fetchBundlePlansOfPeriodicalError,
    fetchBundlePlansOfPeriodicalSuccess,
    fetchBundleSuccess,
    isCreateBundleAction,
    isCreateBundleSuccessAction,
    isFetchBundleAction,
    isFetchBundlePlansOfPeriodicalAction,
    isOrganizeBundle,
    organizeBundleError,
    organizeBundleSuccess
} from "../actions/bundle.actions";
import {PlanNode} from "../../types/plan-node";
import {BundleType, InitBundleRequest} from "../../types/bundle";
import i18n from "i18next";
import {reloadSearch} from "../actions/common-search.actions";
import {Filters, SearchContext} from "../../types/common-search";
import {ArticleTags} from "../../types/article";


function _createBundle(axios: AxiosStatic,
                       initBundleRequest: InitBundleRequest,
                       context: SearchContext | undefined,
                       forceOptions: Filters | undefined,
                       token: string, nextAction ?: ActionWithPayload
):
    Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.post(`/api/bundle/`, initBundleRequest, {headers}))
        .pipe(
            mergeMap(response => merge(of<Action>(
                reloadSearch({ctx: context ?? SearchContext.ARTICLE, forceOptions}),
                createBundleSuccess({bundle: response.data, action: nextAction}),
                notifySuccess(i18n.t('bundles.save_success')))),
            ),
            catchError(err => merge(
                of(createBundleError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE'}))
            ))
        );
}

function _savePlan(axios: AxiosStatic, {
    bundle,
    bundleId
}: { bundle: PlanNode[], bundleId: string }, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.put(`/api/bundle/${bundleId}/organize`, bundle, {headers}))
        .pipe(
            mergeMap(() => of<Action>(
                organizeBundleSuccess(),
                notifySuccess(i18n.t('bundles.save_success')))),
            catchError(err => merge(
                of(organizeBundleError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE'}))
            ))
        )
}

function _fetchAll(axios: AxiosStatic, periodical: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/bundle_plan/periodical/${periodical}`, {headers}))
        .pipe(
            map((result) => fetchBundlePlansOfPeriodicalSuccess(result.data)),
            catchError(err => merge(
                of(fetchBundlePlansOfPeriodicalError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE'}))
            ))
        )
}

function _fetch(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/bundle/${id}`, {headers}))
        .pipe(
            map((result) => fetchBundleSuccess(result.data)),
            catchError(err => merge(
                of(fetchBundleError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE'}))
            ))
        )
}

export function createBundleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isCreateBundleAction),
        flatMap((action) => {
                const bundlePlanVersionId = state$.value.createArticle.articleForm?.bundlePlanVersionId
                const planNodeId = state$.value.createArticle.articleForm?.planNodeId
                const title = state$.value.createArticle.articleForm?.title
                const periodicalId = state$.value.createArticle.articleForm?.periodicalId
                const publicationId = state$.value.createArticle.articleForm?.publicationId
                const type = state$.value.createArticle.articleForm?.type as BundleType | undefined
                const tags = state$.value.createArticle.articleForm?.tags as ArticleTags | undefined

                if (!title || !planNodeId || !bundlePlanVersionId || !periodicalId || !type) {
                    return EMPTY
                }
                return _createBundle(axios,
                    {
                        title: title,
                        bundlePlanVersionId: bundlePlanVersionId,
                        planNodeId: planNodeId,
                        periodicalId: periodicalId,
                        publicationId: publicationId,
                        type: type!,
                        tags: tags
                    },
                    action.payload.context,
                    action.payload.forceOptions,
                    state$.value.auth.token ?? "", action.payload.action)
            }
        )
    )
}

export function organizeBundleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isOrganizeBundle),
        flatMap((action) => _savePlan(axios,
            action.payload,
            state$.value.auth.token ?? "")
        )
    )
}

export function fetchBundleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchBundleAction),
        flatMap((action) => _fetch(axios,
            action.payload,
            state$.value.auth.token ?? "")
        )
    )
}

export function fetchAllBundlePlansEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchBundlePlansOfPeriodicalAction),
        flatMap((action) => _fetchAll(axios,
            action.payload,
            state$.value.auth.token ?? "")
        )
    )
}

export function actionAfterSaveBundle(action$: Observable<Action>): Observable<Action> {
    return action$.pipe(
        filter(isCreateBundleSuccessAction),
        flatMap((action) => {
            if (action.payload.action) {
                if (action.payload.action.payload === '%%articleId%%') {
                    action.payload.action.payload = action.payload.bundle.id
                }
                return of(action.payload.action as Action)
            }
            return EMPTY
        })
    )
}

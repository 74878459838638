import {Grid, IconButton} from "@material-ui/core";
import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {ArticleSearchResult} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {SetFrontCoverIcon, UnsetFrontCoverIcon} from "../../commons/icon/icons";
import {ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import Auth from "../../Auth";
import {toggleArticleCover} from "../../../redux/actions/article/article.actions";
import {ArticleStatus} from "../../../types/article";


type Props = {
    article: ArticleSearchResult
    size?: "small" | "medium"
}

function AddInCover({article, size}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const handleToggleArticleCover = (article: ArticleSearchResult, flag: boolean) => dispatch(toggleArticleCover({
        article,
        flag
    }))

    if ((article.status === ArticleStatus.IN_PROGRESS || article.status === ArticleStatus.READY)) {
        return (
            <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}
                  periodicalRestriction={article.periodicalId}>
                <Grid item>
                    {!article.isFrontCover &&
                    <Tooltip
                        title={t('misc.set_front_cover') ?? ''}>
                        <IconButton
                            size={size}
                            onClick={() => handleToggleArticleCover(article, true)}>
                            <SetFrontCoverIcon/>
                        </IconButton>
                    </Tooltip>}

                    {/* Annuler en couverture */}
                    {article.isFrontCover && <Tooltip
                        title={t('misc.unset_front_cover') ?? ''}>
                        <IconButton
                            size={size}
                            onClick={() => handleToggleArticleCover(article, false)}>
                            <UnsetFrontCoverIcon/>
                        </IconButton>
                    </Tooltip>}

                </Grid>
            </Auth>
        )
    } else {
        return (<></>)
    }
}

export default AddInCover;

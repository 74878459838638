import React from "react";
import {Grid} from "@material-ui/core";
import {useSelector} from "react-redux";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import {BundleResponse, BundleType} from "../../../types/bundle";
import {State} from "../../../redux/reducers";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {createHtml} from "../../../utils/common-utils";

const typesMap = [
    {
        label: "Analyse et formule",
        value: BundleType.ANALYSIS
    },
    {
        label: "Cas pratique",
        value: BundleType.PRACTICAL_CASE
    },
    {
        label: "Chronique",
        value: BundleType.CHRONICLE
    },
    {
        label: "Dossier",
        value: BundleType.FOLDER
    },
    {
        label: "Panorama",
        value: BundleType.PANORAMA
    },
    {
        label: "Tableau de veille",
        value: BundleType.BOARD
    }]

const metadataContainer = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '5px'
    }
}))

export default function BundleOrganizeMetadata() {
    const publication = useSelector(getCurrentPublication)
    const bundle = useSelector(currentFetchedBundleSelector)
    const {t} = useTranslation();
    const classes = metadataContainer();
    const periodical = useSelector(({periodical}: State) => periodical.periodicals)
        .find(periodical => (periodical.id === publication?.periodicalId || periodical.id === bundle?.periodical))

    const getBundleType = (bundle: BundleResponse) => (typesMap.filter(e => e.value === bundle.type)[0].label ?? undefined)

    return <Grid container item direction={"row"} justify={"space-between"} xs={11} className={classes.root}>
        {bundle && <>
            <Grid item xs={4}>
                <Typography>{t(`bundles.form.id.label`)} : <b>{bundle.id}</b></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>{t(`bundles.form.title.label`)} : <div dangerouslySetInnerHTML={createHtml(bundle.title)}
                                                                   style={{display: 'inline', fontWeight: 'bold'}}/>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>{t(`bundles.form.periodicalId.label`)} : <b>{periodical?.label}</b></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>{t(`bundles.form.publicationId.label`)} : <b>{publication ? publication.periodicalId + '_' + publication.year + '_' + publication.number : ''}</b>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>{t(`bundles.form.type.label`)} : <b>{getBundleType(bundle)}</b></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography>{t(`bundles.form.planNodeId.label`)} : <b>{bundle?.planNode?.name || ''}</b></Typography>
            </Grid>
        </>}
    </Grid>
}

import React, {ChangeEvent} from "react";
import TextField from "@material-ui/core/TextField";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import {State} from "../../../redux/reducers";
import {updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {SearchContext} from "../../../types/common-search";
import {KeyValue} from "../../../redux/reducers/auto-complete/autocomplete-authors.reducer";
import {autoCompletePlanNodes} from "../../../redux/actions/search/autcocomplete-plan-nodes.actions";
import {useTranslation} from "react-i18next";

type Props = {
    label?: string,
    planNodeFilter?: string[]
}

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 20
    }
}))

export default ({label, planNodeFilter}: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation()

    const [open, setOpen] = React.useState(false);
    const [inputText, setInputText] = React.useState("");

    const status = useSelector(({autoCompleteReducer}: State) => autoCompleteReducer.autocompletePlanNodes.status, shallowEqual);

    const [options,] = useSelector(({autoCompleteReducer}: State) =>
        [autoCompleteReducer.autocompletePlanNodes.results, autoCompleteReducer.autocompletePlanNodes.results ? autoCompleteReducer.autocompletePlanNodes.results.filter(x => planNodeFilter?.includes(x.key)) : [{
            key: '',
            value: ''
        }]], shallowEqual);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: KeyValue | KeyValue[] | null) => {
        if (value === null) {
            dispatch(updateSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: `planNodeFilter`, value: []
            }))
        } else if (Array.isArray(value)) {
            dispatch(updateSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: `planNodeFilter`, value: value.map(e => e.key)
            }))
        } else {
            dispatch(updateSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: `planNodeFilter`, value: [value.key]
            }))
        }
    };

    return (
        <FormControl className={classes.select} fullWidth={true}>
            <Autocomplete openText={t('misc.open')}
                          clearText={t('misc.clear')}
                          closeText={t('misc.close')}
                          loadingText={t('misc.loading')}
                          noOptionsText={t('misc.no-options')}
                          color={"secondary"}
                          multiple
                          open={open}
                          onOpen={() => {
                              dispatch(autoCompletePlanNodes(''))
                              setOpen(true);
                          }}
                          onClose={() => {
                              setOpen(false);
                          }}
                          onChange={(event: any, newValue) => handleChange(event, newValue)}
                          options={options}
                          inputValue={inputText}
                          defaultValue={planNodeFilter?.map(x => {
                              return {key: x, value: x} as KeyValue
                          })}
                          getOptionLabel={(e) => e.value}
                          getOptionSelected={(e) => planNodeFilter ? planNodeFilter.includes(e.key) : false}
                          onInputChange={
                              (event: ChangeEvent<{}>, value) => {
                                  setInputText(value);
                                  if (value) {
                                      dispatch(autoCompletePlanNodes(value))
                                  }
                              }
                          }
                          disableCloseOnSelect
                          loading={status === 'PENDING'}
                          renderOption={(option, {selected}) => (
                              <React.Fragment>
                                  <Checkbox
                                      checked={selected}
                                  />
                                  {option.value ? option.value : option.key}
                              </React.Fragment>
                          )}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  color={"secondary"}
                                  label={label}
                                  InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                          <React.Fragment>
                                              {status === 'PENDING' ?
                                                  <CircularProgress color="inherit" size={20}/> : null}
                                              {params.InputProps.endAdornment}
                                          </React.Fragment>
                                      ),
                                  }}
                              />
                          )}
            />
        </FormControl>
    )
};

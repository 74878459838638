import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from 'react-router-dom';

import {
    copyArticle,
    moveArticle,
    moveArticles,
    redirectToEditArticle,
    saveArticle,
    setArticleDialogMode,
    setBundleId,
    setBundlePlanVersionId,
    setNumberOfPages,
    setPeriodicalId,
    setPlanNodeId,
    setPublicationId,
    setTitle,
    setType
} from "../../../redux/actions/article/article-dialog.actions";
import Dialog from '@material-ui/core/Dialog';

import {
    availableArticleTagsSelector,
    availableBundlePlansSelector,
    availablePeriodicalBundlesSelector,
    availablePublicationBundlesSelector,
    availablePublicationsSelector,
    availableTopicsSelector,
    currentBundleIdSelector,
    currentBundlePlanVersionIdSelector,
    currentNombreDePageSelector,
    currentPeriodicalIdSelector,
    currentPlanNodeIdSelector,
    currentPublicationIdSelector,
    currentTitleSelector,
    currentTypeSelector,
    dialogMode,
    isBundleModeSelector,
    openDialog,
    sourceSelector,
} from "../../../redux/selectors/article/article.selectors";

import {attachedOnlySelected, pageSizeSelector, querySelector} from "../../../redux/selectors/forceOptions.selectors"

import {initPeriodicalsLimitedByRoles} from "../../../redux/selectors/periodical.selectors"
import Grid, {GridSize} from "@material-ui/core/Grid";
import LexForm from "../../commons/forms/LexForm";
import {
    bundleTagsRenderer,
    flattenAutocomplete,
    selectRenderer,
    tagsRenderer,
    wysiwygRenderer
} from "../../commons/forms/LexFormField";
import {ArticleDialogForm, ArticleType, simpleType} from "../../../types/article";
import {State} from "../../../redux/reducers";
import {useTranslation} from "react-i18next";
import {Button} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import {SearchContext} from "../../../types/common-search";
import {ADMIN, PRODUCER} from "../../../utils/roles";
import {BundleType} from "../../../types/bundle";
import {fetchBundlePlansOfPeriodical} from "../../../redux/actions/bundle.actions";
import {forceBundleOptions, forcePublicationOptions} from "../../../utils/publication-utils";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import {removeHtmlTags, truncateString} from "../../../utils/article-utils";


export const bundleTypesMap = [
    {
        label: "Analyse et formule",
        value: BundleType.ANALYSIS
    },
    {
        label: "Cas pratique",
        value: BundleType.PRACTICAL_CASE
    },
    {
        label: "Chronique",
        value: BundleType.CHRONICLE
    },
    {
        label: "Dossier",
        value: BundleType.FOLDER
    },
    {
        label: "Panorama",
        value: BundleType.PANORAMA
    },
    {
        label: "Tableau de veille",
        value: BundleType.BOARD
    }];

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(0)
    },
    button: {
        marginTop: theme.spacing(2)
    }
}))

const modeMap = {
    [ArticleDialogMode.CREATE]: saveArticle,
    [ArticleDialogMode.COPY]: copyArticle,
    [ArticleDialogMode.MOVE]: moveArticle,
    [ArticleDialogMode.MOVE_MULTIPLE]: moveArticles
}

const titleMap = (type: string) => ({
    [ArticleDialogMode.CREATE]: `${type}.add`,
    [ArticleDialogMode.COPY]: `${type}.copy`,
    [ArticleDialogMode.MOVE]: `${type}.move`,
    [ArticleDialogMode.MOVE_MULTIPLE]: `${type}.move`
})

type SubmitAction = 'save_and_continue' | 'save_and_close' | 'save_and_create'

export default function ArticleDialog() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [next, setNext] = useState<SubmitAction | null>(null);

    const publications = useSelector(availablePublicationsSelector);
    const topics = useSelector(availableTopicsSelector);
    const periodicalBundles = useSelector(availablePeriodicalBundlesSelector);
    const publicationBundles = useSelector(availablePublicationBundlesSelector);
    const publicationId = useSelector(currentPublicationIdSelector)
    const bundles = (publicationId === null || publicationId === undefined) ? periodicalBundles : publicationBundles

    const open = useSelector(openDialog);
    const isBundleMode = useSelector(isBundleModeSelector);

    const mode = useSelector(dialogMode);
    const bundleId = useSelector(currentBundleIdSelector);
    const bundlePlanNodeId = useSelector(currentBundlePlanVersionIdSelector);
    const source = useSelector(sourceSelector) || "";
    const periodicalId = useSelector(currentPeriodicalIdSelector);
    const type = useSelector(currentTypeSelector);
    const initPeriodicals = useSelector(initPeriodicalsLimitedByRoles)
    const bundlePlans = useSelector(availableBundlePlansSelector)
    const {t} = useTranslation();

    const articleTags = useSelector(availableArticleTagsSelector)

    const location = useLocation()
    let searchContext = SearchContext.ARTICLE;

    let forceOptions;
    const query = useSelector(querySelector)
    const pageSize = useSelector(pageSizeSelector)
    const attachedOnly = useSelector(attachedOnlySelected)
    const publication = useSelector(getCurrentPublication);
    const bundle = useSelector(currentFetchedBundleSelector)

    if (mode === ArticleDialogMode.CREATE) {
        if (location.pathname.startsWith("/" + t('routes.publication.root'))) {
            searchContext = SearchContext.PUBLICATION_ARTICLE
            forceOptions = forcePublicationOptions(false, publication, query, pageSize, 0)
        } else if (location.pathname.startsWith("/" + t('routes.bundle.root'))) {
            searchContext = SearchContext.BUNDLE_ARTICLE
            forceOptions = forceBundleOptions(false, bundle, attachedOnly, publication, query, pageSize, 0)
        }
    }

    const selectedIds = useSelector((state: State) => state.search.common[SearchContext.ARTICLE].selectedIds);
    const roles = useSelector((state: State) => state.auth.roles)
    const titleEditable = (mode !== ArticleDialogMode.MOVE_MULTIPLE && type !== ArticleType.BRIEF);
    const articleType = mode === ArticleDialogMode.COPY || mode === ArticleDialogMode.MOVE || roles.some(x => x.startsWith(ADMIN) || x.startsWith(PRODUCER)) ? Object.values(ArticleType) : simpleType
    const whichPlans = publicationId ? "publication" : "periodical"

    const options = topics[whichPlans] ? topics[whichPlans].map(function mapId({sub, ...r}) {
        return {label: r.name, value: r.id, children: sub.map(mapId), disable: sub.map(mapId).length > 0};
    }) : [];

    const flatten = (obj, level, parent) => {
        const array = Array.isArray(obj) ? obj : [obj];
        return array.reduce((acc, value) => {
            acc.push({...value, level: level, parent: parent});
            if (value.children) {
                acc = acc.concat(flatten(value.children, level + 1, value.label));
                delete value.children;
            }
            return acc;
        }, []);
    }

    const flattenPlanNodeIds = options.length > 0 ? flatten(options, 0, null) : [];


    const bundleOptions = bundles?.map(x => ({
        label: x.title === '' ? x.id : removeHtmlTags(x.title),
        shortLabel: x.title === '' ? x.id : x.id + ' - ' + truncateString(removeHtmlTags(x.title), 50),
        value: x.id
    }))
    const mapPublications = publications?.map(e => ({
        label: `${e.periodicalId}_${e.year}_${e.number}`,
        value: e.id!!
    }))

    const handleClose = () => {
        dispatch(setArticleDialogMode({
            mode: ArticleDialogMode.CLOSE
        }))
    };

    const isDialogReadyFunction = (): boolean => {
        let ready = true;
        if (publicationId && mapPublications === undefined) {
            ready = false
        }

        if (bundleId && bundleOptions === undefined) {
            ready = false
        }

        return ready
    }

    useEffect(() => {
        if (periodicalId && periodicalId !== "" && isBundleMode)
            dispatch(fetchBundlePlansOfPeriodical(periodicalId))
    }, [dispatch, periodicalId])

    if (isDialogReadyFunction()) {
        return (
            <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
                <Grid container className={classes.root}>
                    <Grid container className={classes.root} spacing={1}>
                        <Typography variant="h5"
                                    component="h1">{t(titleMap(isBundleMode ? 'bundles' : 'articles')[mode],
                            {count: mode === ArticleDialogMode.MOVE_MULTIPLE ? selectedIds.length : 1})}
                        </Typography>
                        <LexForm key={'form_article'}
                                 onSubmit={() => {
                                     if (next === 'save_and_continue') {
                                         dispatch(modeMap[mode]({
                                             action: redirectToEditArticle(mode === ArticleDialogMode.MOVE ? source : "%%articleId%%"),
                                             context: searchContext,
                                             forceOptions: forceOptions
                                         }))
                                     } else if (next === 'save_and_close') {
                                         dispatch(modeMap[mode]({
                                             action: setArticleDialogMode({
                                                 mode: ArticleDialogMode.CLOSE
                                             }),
                                             context: searchContext,
                                             forceOptions: forceOptions
                                         }))
                                     } else if (next === 'save_and_create') {
                                         dispatch(modeMap[mode]({
                                             action: setArticleDialogMode({
                                                 mode: ArticleDialogMode.CREATE,
                                                 default: {
                                                     title: "",
                                                     isBundle: isBundleMode,
                                                 tags: undefined} as ArticleDialogForm
                                             }),
                                             context: searchContext,
                                             forceOptions: forceOptions
                                         }))
                                         dispatch(setTitle(undefined))

                                         dispatch(setTitle(""))
                                     }
                                     setNext(null)
                                 }}
                                 type={isBundleMode ? "bundles" : "articles"}
                                 fields={[
                                     {
                                         name: "periodicalId",
                                         selector: currentPeriodicalIdSelector,
                                         action: setPeriodicalId,
                                         renderer: selectRenderer(initPeriodicals.map(e => {
                                             return {
                                                 label: `[${e.id}] ${e.label}`,
                                                 value: e.id
                                             }
                                         }), ['publicationId', 'planNodeId', 'bundleId']),
                                         disabled: mode === ArticleDialogMode.MOVE || mode === ArticleDialogMode.MOVE_MULTIPLE,
                                         required: true,
                                         column: mode !== ArticleDialogMode.MOVE_MULTIPLE ? 4 as GridSize : 6 as GridSize,
                                         shouldNotTranslate: true,
                                         titleColumn: 4
                                     }, {
                                         name: "publicationId",
                                         selector: currentPublicationIdSelector,
                                         action: setPublicationId,
                                         renderer: tagsRenderer(mapPublications??[], 0, 1, ['planNodeId', 'bundleId']),
                                         required: false,
                                         disabled: !periodicalId || periodicalId === "" || !publications,
                                         column: mode !== ArticleDialogMode.MOVE_MULTIPLE ? 4 as GridSize : 6 as GridSize,
                                         titleColumn: 4
                                     },
                                     (isBundleMode) ? (mode !== ArticleDialogMode.MOVE && mode !== ArticleDialogMode.MOVE_MULTIPLE ? {
                                         name: "bundlePlanVersionId",
                                         selector: currentBundlePlanVersionIdSelector,
                                         action: setBundlePlanVersionId,
                                         renderer: tagsRenderer(bundlePlans?.map(bundle => ({
                                             label: bundle.name,
                                             value: bundle.bundlePlanVersionId
                                         })) ?? [], 1, 1),
                                         disabled: !periodicalId || periodicalId === "" || bundles === undefined,
                                         column: 4 as GridSize,
                                         required: true,
                                         shouldNotTranslate: true,
                                         titleColumn: 4
                                     } : undefined) : (mode !== ArticleDialogMode.MOVE_MULTIPLE ? {
                                         name: "bundleId",
                                         selector: currentBundleIdSelector,
                                         action: setBundleId,
                                         renderer: bundleTagsRenderer(bundleOptions??[], 0, 1),
                                         disabled: !publicationId || bundles === null || bundles === undefined,
                                         column: 4 as GridSize,
                                         titleColumn: 4
                                     } : undefined),
                                     mode !== ArticleDialogMode.MOVE_MULTIPLE ? {
                                         name: "type",
                                         selector: currentTypeSelector,
                                         action: setType,
                                         renderer: selectRenderer(!isBundleMode ? articleType.map(x => {
                                                 return {
                                                     label: `enums.articles.type.${x}.main`,
                                                     value: x
                                                 }
                                             }
                                         ) : bundleTypesMap),
                                         disabled: (isBundleMode && bundlePlanNodeId !== null) || mode !== ArticleDialogMode.CREATE,
                                         required: !((isBundleMode && bundlePlanNodeId !== null) || mode !== ArticleDialogMode.CREATE),
                                         column: 4 as GridSize,
                                         titleColumn: 4
                                     } : undefined,
                                     mode !== ArticleDialogMode.MOVE_MULTIPLE && (mode === ArticleDialogMode.CREATE || (flattenPlanNodeIds && flattenPlanNodeIds.length > 0)) ? {
                                         name: "planNodeId",
                                         selector: currentPlanNodeIdSelector,
                                         action: setPlanNodeId,
                                         renderer: flattenAutocomplete(flattenPlanNodeIds),
                                         required: !((bundleId !== null && bundleId !== undefined) || !topics[whichPlans]),
                                         disabled: !periodicalId || periodicalId === "" || (bundleId !== null && bundleId !== undefined) || !topics[whichPlans],
                                         column: 4 as GridSize,
                                         titleColumn: 4
                                     } : undefined,
                                     titleEditable ? {
                                         name: "title",
                                         selector: currentTitleSelector,
                                         action: setTitle,
                                         required: true,
                                         titleColumn: 2,
                                         renderer: wysiwygRenderer(false),
                                         column: 12 as GridSize
                                     } : "title",
                                 mode !== ArticleDialogMode.MOVE_MULTIPLE ? {
                                         name: "blockRenderer",
                                         column: 12 as GridSize,
                                         action: undefined,
                                         selector: null,
                                         required: false,
                                         renderer: <Grid item xs={12}><Typography variant="h5"
                                                                             component="h1">{t('images.tags')}
                                         </Typography></Grid>
                                     } : undefined,
                                     mode !== ArticleDialogMode.MOVE_MULTIPLE ? {
                                         name: "tags",
                                         selector: currentNombreDePageSelector,
                                         action: setNumberOfPages,
                                         required: false,
                                         titleColumn: 4,
                                         renderer: tagsRenderer(articleTags?.nombreDePages ? articleTags.nombreDePages.map(e => {
                                             return {
                                                 label: e,
                                                 value: e,
                                             }
                                         }) : [{label: '', value: ''}], 0, 1),
                                         column: 4 as GridSize
                                     } : undefined
                                 ]}>
                            <Grid container
                                  justify={"flex-end"} className={classes.button} spacing={1}>
                                <Grid item>
                                    <Button variant="outlined"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                handleClose()
                                            }}>
                                        {t('misc.cancel')}
                                    </Button>
                                </Grid>
                                {mode !== ArticleDialogMode.MOVE_MULTIPLE && <Grid item>
                                    <Button variant="contained" color="primary" type={"submit"}
                                            onClick={() => setNext('save_and_continue')}>
                                        {t('articles.save_and_continue')}
                                    </Button>
                                </Grid>}
                                <Grid item>
                                    <Button variant="contained" color="primary" type={"submit"}
                                            onClick={() => setNext('save_and_close')}>
                                        {t('articles.save_and_close')}
                                    </Button>
                                </Grid>
                                {mode === ArticleDialogMode.CREATE && <Grid item>
                                    <Button
                                        disabled={mode !== ArticleDialogMode.CREATE}
                                        variant="contained" color="primary" type={"submit"}
                                        onClick={() => setNext('save_and_create')}>
                                        {t('articles.save_and_create_another')}
                                    </Button>
                                </Grid>}
                            </Grid>
                        </LexForm>
                    </Grid>
                </Grid>
            </Dialog>
        )
    } else {
        return (<></>)
    }
}

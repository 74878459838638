import {createActionWithoutPayload, createActionWithPayload} from "./utils.actions";
import {AdPriority, AdSize, Advertisement} from "../../types/ads";
import {B64Image, ImageColor, ImageFormat, SavedImage} from "../../types/image";
import {AdSearchResult, Filters, SearchContext} from "../../types/common-search";

export const [saveAd, isSaveAdAction] = createActionWithoutPayload('SAVE_AD');
export const [saveAdSuccess, isSaveAdSuccessAction] = createActionWithoutPayload('SAVE_AD_SUCCESS');
export const [saveAdError, isSaveAdErrorAction] = createActionWithoutPayload('SAVE_AD_ERROR');

export const [fetchAdSizes, isFetchAdSizesAction] = createActionWithoutPayload('FETCH_AD_SIZES');
export const [fetchAdSizesSuccess, isFetchAdSizesSuccessAction] = createActionWithPayload<AdSize[]>('FETCH_AD_SIZES_SUCCESS');
export const [fetchAdSizesError, isFetchAdSizesErrorAction] = createActionWithoutPayload('FETCH_AD_SIZES_ERROR');

export const [fetchAdConfig, isFetchAdConfigAction] = createActionWithoutPayload('FETCH_AD_CONFIG');
export const [fetchAdConfigSuccess, isFetchAdConfigSuccessAction] = createActionWithPayload<AdSize[]>('FETCH_AD_CONFIG_SUCCESS');
export const [fetchAdConfigError, isFetchAdConfigErrorAction] = createActionWithoutPayload('FETCH_AD_CONFIG_ERROR');


export const [fetchAd, isFetchAdAction] = createActionWithPayload<string>('FETCH_AD');
export const [fetchAdSuccess, isFetchAdSuccessAction] = createActionWithPayload<Advertisement>('FETCH_AD_SUCCESS');
export const [fetchAdError, isFetchAdErrorAction] = createActionWithoutPayload('FETCH_AD_ERROR');

export const [searchAds, isSearchAdsAction] = createActionWithPayload<any>('SEARCH_ADS'); // todo: type filter images
export const [searchAdsSuccess, isSearchAdsSuccessAction] = createActionWithPayload<AdSearchResult[]>('SEARCH_ADS_SUCCESS');
export const [searchAdsError, isSearchAdsErrorAction] = createActionWithoutPayload('SEARCH_ADS_ERROR');

export const [uploadAdImage, isUploadAdImageAction] = createActionWithPayload<B64Image>('UPLOAD_AD_IMAGE');
export const [uploadAdImageSuccess, isUploadAdImageSuccessAction] = createActionWithPayload<SavedImage>('UPLOAD_AD_IMAGE_SUCCESS');
export const [uploadAdImageError, isUploadAdImageErrorAction] = createActionWithPayload<string>('UPLOAD_AD_IMAGE_ERROR');

export const [replaceAdImage, isReplaceAdImageAction] = createActionWithPayload<{ image: B64Image, id: string }>('REPLACE_AD_IMAGE');
export const [replaceAdImageSuccess, isReplaceAdImageSuccess] = createActionWithPayload<SavedImage>('REPLACE_AD_IMAGE_SUCCESS');
export const [replaceAdImageError, isReplaceAdImageError] = createActionWithPayload<string>('REPLACE_AD_IMAGE_ERROR');

export type DeleteAdPayload = { id: string, ctx?: SearchContext, forceOptions?: Filters }
export const [deleteAd, isDeleteAdAction] = createActionWithPayload<DeleteAdPayload>('DELETE_AD');
export const [deleteAdSuccess, isDeleteAdSuccessAction] = createActionWithoutPayload('DELETE_AD_SUCCESS');
export const [deleteAdError, isDeleteAdErrorAction] = createActionWithoutPayload('DELETE_AD_ERROR');

export const [deleteManyAds, isDeleteManyAdsAction] = createActionWithPayload<string[]>('DELETE_MANY_ADS');
export const [deleteManyAdsSuccess, isDeleteManyAdsSuccessAction] = createActionWithoutPayload('DELETE_MANY_ADS_SUCCESS');
export const [deleteManyAdsError, isDeleteManyAdsErrorAction] = createActionWithoutPayload('DELETE_MANY_ADS_ERROR');

export const [selectAds, isSelectAds] = createActionWithPayload<string[]>('ADS_SET_SELECTION');
export const [addAdSelection, isAddAdSelection] = createActionWithPayload<string[]>('ADS_ADD_SELECTION');
export const [removeAdSelection, isRemoveAdSelection] = createActionWithPayload<string[]>('ADS_REMOVE_SELECTION');
export const [clearAdsSelection, isClearAdsSelection] = createActionWithoutPayload('CLEAR_ADS_SELECTION');

export const [generateNewAd, isGenerateNewAdAction] = createActionWithoutPayload('GENERATE_NEW_AD');

export const [setGridAdSortMode, isSetGridAdSortModeAction] = createActionWithPayload<string>('AD_GRID_SORT_MODE');

// ad edit
export const [setAdName, isSetAdNameAction] = createActionWithPayload<string>('SET_AD_NAME')
export const [setAdDescription, isSetAdDescriptionAction] = createActionWithPayload<string>('SET_AD_DESCRIPTION')
export const [setAdDeadline, isSetAdDeadlineAction] = createActionWithPayload<string>('SET_AD_DEADLINE')
export const [setAdFormat, isSetAdFormatAction] = createActionWithPayload<ImageFormat>('SET_AD_FORMAT')
export const [setAdColor, isSetAdColorAction] = createActionWithPayload<ImageColor>('SET_AD_COLOR')
export const [setAdPeriodicals, isSetAdPeriodicalsAction] = createActionWithPayload<string[]>('SET_AD_PERIODICALS')
export const [setAdPriority, isSetAdPriorityAction] = createActionWithPayload<AdPriority>('SET_AD_PRIORITY')

export const [toggleAdDisabled, isToggleAdDisabledAction] = createActionWithPayload<{ id: string, flag: boolean }>('TOGGLE_AD_DISABLED')
export const [toggleAdDisabledSuccess, isToggleAdDisabledSuccessAction] = createActionWithPayload<boolean>('TOGGLE_AD_DISABLED_SUCCESS')
export const [toggleAdDisabledError, isToggleAdDisabledErrorAction] = createActionWithoutPayload('TOGGLE_AD_DISABLED_ERROR')

export const [toggleManyAdDisabled, isToggleManyAdDisabledAction] = createActionWithPayload<{ ids: string[], flag: boolean }>('TOGGLE_MANY_AD_DISABLED')
export const [toggleManyAdDisabledSuccess, isToggleManyAdDisabledSuccessAction] = createActionWithPayload<boolean>('TOGGLE_MANY_AD_DISABLED_SUCCESS')
export const [toggleManyAdDisabledError, isToggleManyAdDisabledErrorAction] = createActionWithoutPayload('TOGGLE_MANY_AD_DISABLED_ERROR')

import React from "react";
import {useTranslation} from "react-i18next";
import {ArticleSearchResult} from "../../../types/common-search";
import {ArticleStatus} from "../../../types/article";
import {ADMIN, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {EditIcon} from "../../commons/icon/icons";
import Auth from "../../Auth";
import LinkButton from "../../commons/button/LinkButton";
import {Button} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";


type Props = {
    article: ArticleSearchResult,
    size?: 'small' | 'medium',
    className?: any,
    isButton?: boolean
}

function EditArticle({article, size, className, isButton}: Props) {
    const {t} = useTranslation()

    const linkButton = isButton ? <Button
        className={'lex-left-button'}
        fullWidth
        startIcon={<EditIcon/>}
        size={"small"} component={RouterLink}
        to={`/${article.isBundle ? t('routes.bundle.root') : t('routes.article.root')}/${article.id}/${t('routes.article.edit')}`}>
        {t(article.isBundle ? 'bundles.edit' : 'articles.edit')}
    </Button> : <LinkButton size={size}
                            buttonClassName={className}
                            to={`/${article.isBundle ? t('routes.bundle.root') : t('routes.article.root')}/${article.id}/${t('routes.article.edit')}`}
                            title={t(article.isBundle ? 'bundles.edit' : 'articles.edit')}
                            icon={<EditIcon/>}/>


    if ((article.status === ArticleStatus.IN_PROGRESS || article.status === ArticleStatus.READY) && !article.nextPublicationOnlineDate) {
        if (article.isBundle) {
            return (
                <Auth requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER]} periodicalRestriction={article.periodicalId}>
                    {linkButton}
                </Auth>
            )
        } else {
            return (
                <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR]} periodicalRestriction={article.periodicalId}>
                    {linkButton}
                </Auth>
            )
        }
    } else if (article.status === ArticleStatus.PUBLISHED || article.nextPublicationOnlineDate) {
        return (<Auth requiredRoles={[ADMIN]}>
            {linkButton}
        </Auth>)
    } else {
        return (<></>)
    }
}

export default EditArticle;

import React, {useContext, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Grid, IconButton, Paper, Tooltip, Typography} from '@material-ui/core'
import {useDispatch, useSelector} from "react-redux";
import {
    deleteArticle,
    deleteArticles,
    fetchArticle,
    toggleArticleCover
} from "../../../redux/actions/article/article.actions";
import {currentFetchedArticleSelector} from "../../../redux/selectors/article/article.selectors";
import {
    CopyIcon,
    DeleteIcon,
    DownloadIcon,
    MoveIcon,
    SetFrontCoverIcon,
    ShowXmlIcon,
    UnsetFrontCoverIcon
} from "../../commons/icon/icons";
import {useTranslation} from "react-i18next";
import LinkButton from "../../commons/button/LinkButton";
import axios, {AxiosResponse} from "axios";
import {downloadArticle} from "../../../utils/common-utils";
import {notifyError} from "../../../redux/actions/notification.actions";
import {State} from "../../../redux/reducers";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import Auth from "../../Auth";
import {ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {fetchPublicationsOfPeriodical} from "../../../redux/actions/publication.actions";
import {OpenArticleDialogPayload, setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions";
import {DeleteArticleDialogContext} from "../../commons/context/DeleteArticleDialogContext";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import InformationPanel from "../../commons/InformationPanel";
import DateTime from "../../commons/DateTime";
import BapArticle from "../actions/BapArticle";
import RevertBapArticle from "../actions/RevertBapArticle";
import {ElementContext, SearchContext} from "../../../types/common-search";
import ArticleStatusList from "../list/ArticleStatusList";
import {EditIcon} from "../../../icon";
import {ArticleResponse, ArticleStatus, fixedAttachments} from "../../../types/article";
import UnlockPdfArticle from "../actions/UnlockPdfArticle";
import PushOnlineArticle from "../actions/PushOnlineArticle";
import { GeneratePlacardButton } from "../../GeneratePlacardButton";

export default function ArticleViewRightPanel() {
    const {id} = useParams() as { id: string }
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {
        openConfirmationDialog,
        setArticleBeingDeleted,
        articleBeingDeleted,
        confirmationDialogOpen
    } = useContext(DeleteArticleDialogContext)

    const openArticleDialog = (article, mode: ArticleDialogMode) => {
        dispatch(fetchPublicationsOfPeriodical(article.periodicalId))
        dispatch(setArticleDialogMode({
            mode: mode,
            default: article,
            source: mode === ArticleDialogMode.MOVE ? id : undefined
        } as OpenArticleDialogPayload))
    }

    useEffect(() => {
        dispatch(fetchArticle(id))
    }, [dispatch, id])

    const article = useSelector(currentFetchedArticleSelector)

    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')
    const downloadXml = (id: string, isBundle: boolean) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/${isBundle ? "bundle" : "article"}/xml/${id}`, {headers})
            .then((r: AxiosResponse) => downloadArticle(`${id}.xml`, r.data as string))
            .catch((err: any) => {
                dispatch(notifyError(+err.response.status, {type: isBundle ? 'BUNDLE' : 'ARTICLE'}))
            })
    }

    const handleToggleArticleCover = (article: ArticleResponse, flag: boolean) => dispatch(toggleArticleCover({
        article: {id: article.id!!, isBundle: false},
        flag
    }))

    const openXmlInNewTab = (id: string, isBundle: boolean) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/${isBundle ? "bundle" : "article"}/xml/${id}`, {headers})
            .then((r: AxiosResponse) => {
                let blob = new Blob([r.data], {type: 'text/xml'})
                let url = URL.createObjectURL(blob)
                window.open(url)
                URL.revokeObjectURL(url)
            })
            .catch((err: any) => {
                dispatch(notifyError(+err.response.status, {type: isBundle ? 'BUNDLE' : 'ARTICLE'}))
            })
    }

    const handleArticleDeletion = () => {
        dispatch(deleteArticle({...articleBeingDeleted, redirectUrl: t('routes.article.root')}))
    }

    let editButtonAccessRoles = [ADMIN];
    if (article && (article.status === ArticleStatus.IN_PROGRESS || article.status === ArticleStatus.READY)) {
        editButtonAccessRoles = [ADMIN, MANAGER, EDITOR, PRODUCER];
    }

    return <>
        {article && <Paper>
            <ConfirmationDialog
                title={t(articleBeingDeleted.isBundle ? 'bundles.delete_confirmation' : 'articles.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default', action: () => openConfirmationDialog(false)},
                    {label: t('dialogs.confirm'), action: handleArticleDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            {/* Status */}
            <Grid container direction={"column"} style={{padding: 8}} spacing={1}>
                <Grid item>
                    <Typography variant={"h6"}>{t('articles.status.name')}</Typography>
                </Grid>

                <Grid container spacing={1} alignItems={"center"} direction={"row"} style={{marginLeft: 4}}>
                    <ArticleStatusList page="article-view" article={article} justify={"flex-start"}/>
                </Grid>

                {/* Actions */}
                <Auth requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER]}
                      periodicalRestriction={article.periodical.id}>
                    <Grid item>
                        <Typography variant={"h6"}>{t('misc.actions')}</Typography>
                    </Grid>

                    <Grid container spacing={1} alignItems={"center"} direction={"row"} style={{marginLeft: 4}}>
                        <BapArticle articleId={article.id} isValid={article.isValid}
                                    periodicalId={article.periodical.id} articleStatus={article.status} size={"small"}
                                    context={ElementContext.ELEMENT_CONTEXT_ARTICLE}/>

                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}
                              periodicalRestriction={article.periodicalId}>
                            <>
                                {(!article.isFrontCover) &&
                                <Grid item>
                                    <Tooltip
                                        title={t('misc.set_front_cover') ?? ''}>
                                        <IconButton size="small"
                                                    onClick={() => handleToggleArticleCover(article, true)}
                                        >
                                            <SetFrontCoverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>}

                                {article.isFrontCover &&
                                <Grid item>
                                    <Tooltip
                                        title={t('misc.unset_front_cover') ?? ''}>
                                        <IconButton size="small"
                                                    onClick={() => handleToggleArticleCover(article, false)}
                                        >
                                            <UnsetFrontCoverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>}
                            </>
                        </Auth>

                        {article.id && article.lastRequestPlacardProcessStatus !== null && article.lastRequestPlacardProcessStatus !== undefined &&
                        <Grid item>
                            <UnlockPdfArticle documentId={article.id} size="small"/>
                        </Grid>}

                        {article && article.status === ArticleStatus.READY &&
                        <RevertBapArticle article={article} size={"small"}
                                          context={ElementContext.ELEMENT_CONTEXT_ARTICLE}/>}

                        {(!article || (article && (article.status === ArticleStatus.IN_PROGRESS || article.status === ArticleStatus.READY)
                            && (article.lastRequestPlacardProcessStatus === null || article.lastRequestPlacardProcessStatus === undefined)) && article.isValid) &&
                        <GeneratePlacardButton pdfArticle={{article, size: 'small', context: ElementContext.ELEMENT_CONTEXT_ARTICLE}} />}

                        <Auth requiredRoles={editButtonAccessRoles}
                              periodicalRestriction={article.periodical.id}>
                            <Grid item>
                                <LinkButton size={"small"}
                                            to={`/${article.isBundle ? t('routes.bundle.root') : t('routes.article.root')}/${article.id}/${t('routes.article.edit')}`}
                                            title={t(article.isBundle ? 'bundles.edit' : 'articles.edit')}
                                            icon={<EditIcon/>}/>
                            </Grid>
                        </Auth>

                        <Auth requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER]}
                              periodicalRestriction={article.periodical.id}>
                            <Grid item>
                                <Tooltip title={t('misc.download') ?? ""}>
                                    <IconButton size={"small"}
                                                onClick={() => downloadXml(article.id ?? "", article.isBundle)}>
                                        <DownloadIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>

                            <Grid item>
                                <Tooltip title={t('articles.see_xml') ?? ""}>
                                    <IconButton size={"small"}
                                                onClick={() => openXmlInNewTab(article.id ?? "", article.isBundle)}>
                                        <ShowXmlIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Auth>

                        <PushOnlineArticle article={article}/>

                        {!article.isBundle &&
                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, MANAGER, EDITOR, PRODUCER]}
                              periodicalRestriction={article.periodical.id}>
                            <Grid item>
                                <Tooltip title={t('articles.copy') ?? ""}>
                                    <IconButton
                                        size={"small"} onClick={() => {
                                        openArticleDialog({
                                            ...article,
                                            periodicalId: article.periodical.id,
                                            publicationId: article.publication?.id,
                                            planNodeId: article?.planNode?.id
                                        }, ArticleDialogMode.COPY)
                                    }}>
                                        <CopyIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Auth>}

                        {(!fixedAttachments.includes(article.attached) && [ArticleStatus.IN_PROGRESS, ArticleStatus.READY].includes(article.status)) &&
                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, EDITOR, MANAGER]}
                              periodicalRestriction={article.periodicalId}>
                            <Grid item>
                                <Tooltip title={t(article.isBundle ? 'bundles.move' : 'articles.move') ?? ""}>
                                    <IconButton
                                        size={"small"} onClick={() => {
                                        openArticleDialog({
                                            ...article,
                                            periodicalId: article.periodical.id,
                                            publicationId: article.publication?.id
                                        }, ArticleDialogMode.MOVE)
                                    }}>
                                        <MoveIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Auth>}


                        {(!fixedAttachments.includes(article.attached) && article.publicationOnlineDate === null) &&
                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, EDITOR, PRODUCER, MANAGER]}
                              periodicalRestriction={article.periodicalId}>
                            <Grid item>
                                <Tooltip title={t(article.isBundle ? 'bundles.delete' : 'articles.delete') ?? ""}>
                                    <IconButton
                                        size={"small"} onClick={() => {
                                        setArticleBeingDeleted({id: article.id ?? "", isBundle: article.isBundle})
                                        openConfirmationDialog(true)
                                    }}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Auth>}
                    </Grid>
                </Auth>

                {/* Informations */}
                <Grid item>
                    <Typography variant={"h6"}>{t('misc.informations')}</Typography>
                </Grid>

                <Grid container style={{marginLeft: 4}}>
                    <InformationPanel
                        disableMargin={true}
                        width={12}
                        hideDivider={true}
                        rows={[
                            {label: t('misc.id'), show: true, value: article.id},
                            {
                                label: t('articles.status.name'),
                                show: true,
                                value: t(`articles.status.${article.status}`)
                            },
                            {
                                label: t('misc.creation_date'),
                                show: article.creationDate !== "" && article.creationDate !== null,
                                value: <DateTime date={article.creationDate ?? ""}/>
                            },
                            {
                                label: t('misc.creation_by'),
                                show: true,
                                value: article.creationUser
                            },
                            {
                                label: t('misc.last_modification_date'),
                                show: article.modificationDate !== "" && article.modificationDate !== null,
                                value: <DateTime date={article.modificationDate ?? ""}/>
                            },
                            {
                                label: t('misc.last_modification_by'),
                                show: true,
                                value: article.modificationUser
                            },
                            {
                                label: t('misc.front_cover'),
                                show: true,
                                value: t(`misc.boolean.${article.isFrontCover}`)
                            },
                            {
                                label: t('misc.importance'),
                                show: true,
                                value: t(`misc.boolean.${article.isImportant}`)
                            },
                            {
                                label: t('misc.type'),
                                show: true,
                                value: t(`enums.articles.type.${article.type}.main`)
                            },
                            {
                                label: t('misc.plan_node'),
                                show: article.planNode?.name !== null,
                                value: article.planNode?.name
                            },
                            {
                                label: t('articles.character_volume'),
                                show: true,
                                value: t('articles.character_volume_value', {count: article.charVolume})
                            },
                            {
                                label: t('articles.character_da_volume'),
                                show: true,
                                value: t('articles.character_da_volume_value', {count: article.charDaVolume})
                            }
                        ]}
                    />
                </Grid>
            </Grid>
        </Paper>}
    </>
}

import React from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {Link as RouterLink} from "react-router-dom";
import {ShortAuthor} from "../../../types/common-search";
import Link from "@material-ui/core/Link";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

type Props = {
    authors: ShortAuthor[]
}

const useStyles = makeStyles(() => ({
    root: {
        fontStyle: "italic",
        marginTop: 10
    }
}));

export default function AuthorsInTable({authors}: Props) {
    const {t} = useTranslation();
    const classes = useStyles();

    const displayAuthor = (x: ShortAuthor): String => `${x.last_name}${x.particle ? ` (${x.particle})` : ''} ${x.first_name}`
    return (
        <>
            <Grid container className={classes.root}>
                Par&nbsp;{authors.slice(0, 3).map((x, index, array) => {
                return <>
                    <Link
                        to={'/' + t('routes.author.root') + '/' + x.id}
                        component={RouterLink}
                        style={{color: "black", paddingLeft: index === 0 ? 0 : 5}}
                    >{displayAuthor(x)}</Link>
                    {
                        index < array.length - 1 && ","
                    }
                </>
            })
            }
                {authors.length > 3 && <Tooltip title={authors.slice(3).map(x => displayAuthor(x)).join(", ")}><span
                    style={{paddingLeft: 5}}>...</span></Tooltip>}
            </Grid>
        </>
    )
}

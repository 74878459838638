import {createActionWithoutPayload, createActionWithPayload} from "./utils.actions";
import {AuthPayload, User} from "../../types/auth";


export const [authSuccess, isAuthSuccessAction] = createActionWithPayload<AuthPayload>('KEYCLOAK_AUTH_SUCCESS');
export const [authError, isAuthErrorAction] = createActionWithoutPayload('KEYCLOAK_AUTH_ERROR');
export const [userInfo, isUserInfoAction] = createActionWithPayload<User>('KEYCLOAK_USER_INFO');
export const [refreshSuccess, isRefreshSuccessAction] = createActionWithPayload<AuthPayload>('KEYCLOAK_TOKEN_REFRESH_SUCCESS');
export const [refreshError, isRefreshErrorAction] = createActionWithoutPayload('KEYCLOAK_TOKEN_REFRESH_ERROR');
export const [logout, isLogoutAction] = createActionWithoutPayload('KEYCLOAK_LOGOUT');
export const [setKeycloakData, isSetKeycloakDataAction] = createActionWithPayload<{url: string | undefined, realm: string | undefined}>('SET_KEYCLOAK_DATA');

import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import {useDispatch} from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Grid, lighten} from "@material-ui/core";
import theme from "../../../theme";
import {SearchContext} from "../../../types/common-search";
import {selectAll, selectAllPage} from "../../../redux/actions/common-search.actions";

const useStyles = makeStyles(() => ({
    root: {},
    allElement: {
        textTransform: "uppercase",
        cursor: "pointer",
        marginLeft: 5
    },
    verySmall: {
        fontSize: "1.0rem"
    },
    allSelected: {
        paddingTop: 10,
        paddingLeft: 10
    },
    grid: {
        marginTop: theme.spacing(1)
    },
    gridLabel: {
        marginLeft: 0
    },
    actionGroup: {
        background: lighten(theme.palette.secondary.main, 0.85)
    }
}))

type Props = {
    ctx: SearchContext
    type: string
    selectedCount: number
    totalCount: number
    allSelected: boolean
    allPageSelected: boolean
    children: JSX.Element[] | JSX.Element
    grid?: boolean,
    enableSelectedAll?: boolean
}

export default ({
                    type,
                    ctx,
                    selectedCount,
                    totalCount,
                    allSelected,
                    allPageSelected,
                    children,
                    grid,
                    enableSelectedAll = true
                }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const {t} = useTranslation();

    return (<>
            {grid &&
            <Grid container>
                <FormGroup className={classes.grid} row>
                    <FormControlLabel className={classes.gridLabel}
                                      control={<Checkbox
                                          color="primary"
                                          checked={allPageSelected}
                                          onChange={(event, check) => {
                                              dispatch(selectAllPage({ctx, allPages: check}))
                                          }}
                                      />}
                                      label={t('misc.select_all')}
                    />
                </FormGroup>
            </Grid>

            }
            <Grid container className={classes.actionGroup}>
                <Box className={classes.allSelected}>
                    <Typography>{allSelected ? totalCount : selectedCount} {t('enums.selected.' + type)}{!allSelected && "."} {allSelected && t('enums.selected.on_all_page') + "."}
                        {(enableSelectedAll && !allSelected && allPageSelected) &&
                        <Link onClick={() => (dispatch(selectAll({ctx, all: true})))}
                              className={classes.allElement}>{t('misc.selected_all_elements')} ({totalCount} {t('app.sections.' + type)})</Link>
                        }
                        {allSelected && <Link onClick={() => (dispatch(selectAll({ctx, all: false})))}
                                              className={classes.allElement}>{t('misc.remove_selection')}</Link>
                        }
                    </Typography>
                </Box>
                <Box flexDirection="row">
                    {children}
                </Box>
            </Grid>
        </>
    )
}

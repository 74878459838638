import axios, {AxiosResponse} from "axios";
import {notifyError} from "../redux/actions/notification.actions";

export const openPdfOfPublicationInNewTab = (publicationId: number, dispatch: any, token?: string) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    axios.get(`/api/publication/download/${publicationId}/placard`,
        {headers, responseType: "blob"})
        .then((r: AxiosResponse) => {
            let blob = new Blob([r.data], {type: 'application/pdf'})
            let url = URL.createObjectURL(blob)
            window.open(url)
        })
        .catch((err: any) => {
            dispatch(notifyError(+err.response.status, {type: 'PUBLICATION'}))
        })
}

export const openPdfOfArticleInNewTab = (articleId: string, dispatch: any, token?: string) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    axios.get(`/api/article/download/${articleId}/placard`,
        {headers, responseType: "blob"})
        .then((r: AxiosResponse) => {
            let blob = new Blob([r.data], {type: 'application/pdf'})
            let url = URL.createObjectURL(blob)
            window.open(url)
        })
        .catch((err: any) => {
            dispatch(notifyError(+err.response.status, {type: 'ARTICLE'}))
        })
}


export const openPdfPrinterInNewTab = (publicationId: number, dispatch: any, token?: string) => {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    axios.get(`/api/publication/download/${publicationId}/BAT`, {headers, responseType: "blob"})
        .then((r: AxiosResponse) => {
            let blob = new Blob([r.data], {type: 'application/pdf'})
            let url = URL.createObjectURL(blob)
            window.open(url)
        })
        .catch((err: any) => {
            dispatch(notifyError(+err.response.status, {type: 'PUBLICATION'}))
        })
}

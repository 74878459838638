import React, {useRef} from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import {SvgIconProps} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import {isQueryProposal, Proposal, ProposalType} from "../../types/quick-search";
import {selectQuickSearchProposal, typeQuickSearch} from "../../redux/actions/quick-search.actions";
import Grid from "@material-ui/core/Grid";
import AuthorIcon from '@material-ui/icons/Face';
import Typography from "@material-ui/core/Typography";
import {State} from "../../redux/reducers";
import {AdvertisementIcon, ArticleIcon, ImageIcon, PublicationIcon} from "../../icon";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {BundleIcon} from "../commons/icon/icons";
import {cleanHtml} from "../../utils/common-utils";

const useStylesOptions = makeStyles((theme: Theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    }
}));

type ResultOptionProps = {
    icon: React.ReactElement<SvgIconProps>
    main: string
    secondary?: string
}

const ResultOption = ({icon, main, secondary}: ResultOptionProps) => {
    const classes = useStylesOptions()
    return (
        <Grid container alignItems="center">
            <Grid item>
                <div className={classes.icon}>{icon}</div>
            </Grid>
            <Grid item>
                <span>{main}</span>
                {secondary && <Typography variant="body2" color="textSecondary">
                    {secondary}
                </Typography>}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 450,
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
        alignContent: "center"
    },
    textField: {
        '& .MuiInputBase-root': {
            color: "white"
        },
        '& .MuiInput-underline::before': {
            borderBottomColor: "white"
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottomColor: "white"
        }
    }
}));

type RouteMapper = {
    path: string
    proposal: ProposalType.ARTICLE | ProposalType.BUNDLE | ProposalType.AUTHOR | ProposalType.IMAGE | ProposalType.AD
}

const AppBarSearch = () => {
    const {t} = useTranslation();

    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();

    const [value, setValue] = React.useState<Proposal | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const inputRef = useRef<HTMLInputElement>()

    const proposals = useSelector(({quickSearch}: State) => quickSearch.proposals);

    const site = ([
        {path: `/${t('routes.author.root')}`, proposal: ProposalType.AUTHOR},
        {path: `/${t('routes.image.root')}`, proposal: ProposalType.IMAGE},
        {path: `/${t('routes.ad.root')}`, proposal: ProposalType.AD},
        {path: `/${t('routes.article.root')}`, proposal: ProposalType.ARTICLE},
        {path: `/${t('routes.bundle.root')}`, proposal: ProposalType.BUNDLE},
        {path: `/${t('routes.publication.root')}`, proposal: ProposalType.PUBLICATION},
    ] as RouteMapper[])
        .find(({path}) => location.pathname.startsWith(path))
        ?.proposal ?? ProposalType.ARTICLE;

    return (
        <>
            <Autocomplete openText={t('misc.open')}
                          clearText={t('misc.clear')}
                          closeText={t('misc.close')}
                          loadingText={t('misc.loading')}
                          noOptionsText={t('misc.no-options')}
                          fullWidth={true}
                          freeSolo
                          value={value}
                          inputValue={inputValue}
                          size={"small"}
                          options={proposals}
                          className={classes.root}
                          getOptionLabel={(option) => {
                              if (isQueryProposal(option)) {
                                  return `Rechercher : "${option.query}"`
                              } else {
                                  return option.label
                              }
                          }}
                          onChange={(event, newValue) => {
                              setValue(null);
                              setInputValue('');
                              if (typeof newValue === 'string' && newValue.trim().length > 0) {
                                  dispatch(selectQuickSearchProposal({
                                      type: ProposalType.QUERY,
                                      site: site,
                                      query: newValue
                                  }))
                                  inputRef.current?.blur()
                              } else if (typeof newValue !== 'string' && newValue !== null) {
                                  dispatch(selectQuickSearchProposal(newValue))
                                  inputRef.current?.blur()
                              }
                          }}
                          onInputChange={(event, newInputValue) => {
                              if (event.target instanceof HTMLInputElement) {
                                  setInputValue(newInputValue);
                                  dispatch(typeQuickSearch(newInputValue));
                              }
                          }}
                          filterOptions={(options, state) => {
                              const query = state.inputValue.trim()
                              const append: Proposal[] = query.length > 0 ? [
                                  {
                                      type: ProposalType.QUERY, site: ProposalType.AUTHOR, query
                                  },
                                  {
                                      type: ProposalType.QUERY, site: ProposalType.IMAGE, query
                                  },
                                  {
                                      type: ProposalType.QUERY, site: ProposalType.AD, query
                                  },
                                  {
                                      type: ProposalType.QUERY, site: ProposalType.ARTICLE, query
                                  },
                                  {
                                      type: ProposalType.QUERY, site: ProposalType.PUBLICATION, query
                                  }
                              ] : [];
                              return [...options, ...append];
                          }}
                          renderInput={(params) =>
                              <TextField
                                  color={"secondary"}
                                  inputRef={inputRef}
                                  className={classes.input}
                                  ref={params.InputProps.ref}
                                  inputProps={params.inputProps}
                                  placeholder="Rechercher"
                                  classes={{
                                      root: classes.textField
                                  }}
                                  style={{width: "100%"}}
                                  InputProps={{
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <SearchIcon/>
                                          </InputAdornment>
                                      )
                                  }}
                              />
                          }
                          renderOption={(option) => {
                              if (isQueryProposal(option)) {
                                  return (
                                      <ResultOption icon={<SearchIcon/>}
                                                    main={`Rechercher : "${option.query}" dans ${t(`quickSearch.proposal.${option.site}`)}`}/>
                                  )
                              } else if (option.type === ProposalType.AUTHOR) {
                                  return (
                                      <ResultOption icon={<AuthorIcon/>} main={option.id}
                                                    secondary={`${option.label}`}/>
                                  )
                              } else if (option.type === ProposalType.IMAGE) {
                                  return (
                                      <ResultOption icon={<ImageIcon/>} main={option.id} secondary={`${option.label}`}/>
                                  )
                              } else if (option.type === ProposalType.AD) {
                                  return (
                                      <ResultOption icon={<AdvertisementIcon/>} main={option.id}
                                                    secondary={`${option.label}`}/>
                                  )
                              } else if (option.type === ProposalType.PUBLICATION) {
                                  return (
                                      <ResultOption icon={<PublicationIcon/>} main={option.label}
                                                    secondary={option.complete ? option.complete[1] : undefined}/>
                                  )
                              } else if (option.type === ProposalType.ARTICLE) {
                                  return (
                                      <ResultOption icon={<ArticleIcon/>} main={option.id}
                                                    secondary={`${cleanHtml(option.label)}`}/>
                                  )
                              } else if (option.type === ProposalType.BUNDLE) {
                                  return (
                                      <ResultOption icon={<BundleIcon/>} main={option.id}
                                                    secondary={`${cleanHtml(option.label)}`}/>
                                  )
                              }
                          }}
            />
        </>
    )
};

export default AppBarSearch;

import {IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ElementContext, Filters, SearchContext} from "../../../types/common-search";
import {ArticleStatus} from "../../../types/article";
import Tooltip from "@material-ui/core/Tooltip";
import {ADMIN, EDITOR, MANAGER} from "../../../utils/roles";
import {AddBapIcon} from "../../commons/icon/icons";
import {ArticleActionName} from "../../../types/workflow";
import {dispatchArticleAction, dispatchMultipleArticlesAction} from "../../../redux/actions/workflow.actions";
import Auth from "../../Auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import {uuidv4} from "../../../utils/uuid";
import {processInProgress} from "../../../redux/selectors/workflow.selectors";


type Props = {
    articleId?: string,
    articleStatus?: ArticleStatus,
    isValid?: boolean,
    periodicalId?: string,
    ids?: string[],
    context?: SearchContext | ElementContext,
    forceOptions?: Filters,
    size?: 'small' | 'medium',
    buttonClassName?: any,
    color?: 'primary' | 'secondary' | 'inherit'
}

function BapArticle({
                        articleId,
                        articleStatus,
                        periodicalId,
                        ids,
                        context,
                        forceOptions,
                        size,
                        buttonClassName,
                        isValid,
                        color
                    }: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const processInProgressSelector = useSelector(processInProgress)
    const [currentProcess, setCurrentProcess] = useState<string>();

    const displayLoading = (simple: boolean) => {
        if (currentProcess && processInProgressSelector.includes(currentProcess)) {
            return <CircularProgress color="inherit" size={size && size === 'small' ? 10 : 15}
                                     style={size === undefined || size === 'medium' ? {marginTop: 15} : {}}/>
        } else {
            return <IconButton
                color={color ?? "default"}
                size={size}
                className={buttonClassName}
                onClick={() => {
                    if (simple) {
                        bap(articleId!!)
                    } else {
                        baps(ids!!)
                    }
                }}>
                <AddBapIcon/>
            </IconButton>
        }
    }

    const bap = (articleId: string) => {
        const uuid = uuidv4();
        dispatch(dispatchArticleAction({
            id: articleId,
            action: ArticleActionName.BAP_ARTICLE,
            uuid: uuid,
            context,
            forceOptions
        }))
        setCurrentProcess(uuid)
    }

    const baps = (ids: string[]) => {
        const uuid = uuidv4();
        dispatch(dispatchMultipleArticlesAction({ids: ids, action: ArticleActionName.BAP_ARTICLE, uuid: uuid}))
        setCurrentProcess(uuid)
    }

    if (articleId && articleStatus === ArticleStatus.IN_PROGRESS && isValid) {
        return (
            <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}
                  periodicalRestriction={periodicalId}>
                <Tooltip
                    title={t('articles.bap') ?? ''}>
                    {displayLoading(true)}
                </Tooltip>
            </Auth>
        )
    } else if (ids) {
        return (
            <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}>
                <Tooltip
                    title={t('articles.bap') ?? ''}>
                    {displayLoading(false)}
                </Tooltip>
            </Auth>
        )
    } else {
        return (<></>)
    }
}

export default BapArticle;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { TreeContext } from '../contexts/organizeTreeBibleContexts';
import { KyrLeavingPagePopUp } from './KyrPopUp';
// RRD for React Router Dom Location (Location exist in native DOM)
import { Location as RRDLocation } from 'history';


export const KyrReactRouterPrompt = () => {
  const [tree] = useContext(TreeContext)
  const isBlocking = tree.data?.isDirty ?? false
  const [show, setShow] = useState(false);
  const locationRef = useRef<RRDLocation<unknown> | null>(null)
  const history = useHistory()

  const handlePrompt = (location) => {
    const currentPath = history.location.pathname;
    const newPath = location.pathname;
    const organizeTreePath = "/kyrielle/organizeTree/"
    const isChangeTabs = currentPath.startsWith(organizeTreePath) && newPath.startsWith(organizeTreePath);
    if (isChangeTabs) {
      return true;
    }

    locationRef.current = location;
    setShow(true);
    return false;
  };

  useEffect(() => {
    if(!isBlocking && locationRef.current) {
      history.push(locationRef.current.pathname)
    }
    
    locationRef.current = null
  }, [isBlocking])

  return (
    <div>
      <Prompt when={isBlocking} message={handlePrompt} />
      <KyrLeavingPagePopUp showState={[show, setShow]} />
    </div>
  );
}

export default KyrReactRouterPrompt;
import {IconButton} from "@material-ui/core";
import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {ArticleSearchResult, Filters, SearchContext} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {PushOnlineIcon} from "../../commons/icon/icons";
import Auth from "../../Auth";
import {onlineRoles} from "../../../utils/roles";
import {ArticleResponse, ArticleStatus, bundleAttachments} from "../../../types/article";
import {BundleResponse} from "../../../types/bundle";
import {pushArticlesOnlineResponseAction} from "../../../redux/actions/article/article.actions";
import PushOnlineArticleDialog from "./PushOnlineArticleDialog";


type Props = {
    article?: ArticleSearchResult | ArticleResponse,
    bundle?: BundleResponse,
    ids?: string[],
    context?: SearchContext,
    forceOptions?: Filters
}

function PushOnlineArticle({article, bundle, ids, context, forceOptions}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const getIds = () : string[] => {
        if (article && article.id) {
            return [article.id]
        } else if (bundle) {
            return [bundle.id]
        } else if(ids) {
            return ids
        }
        return []
    }

    if ((bundle && bundle.status === ArticleStatus.READY && !bundle.nextPublicationOnlineDate) || (!bundle && !article || (article && article.status === ArticleStatus.READY &&
        !article.nextPublicationOnlineDate && (article.isBundle || article.bundleId === null) && (article.isBundle || !bundleAttachments.includes(article.attached))))) {
        return (
            <Auth requiredRoles={onlineRoles} periodicalRestriction={article?.periodicalId}>
                <PushOnlineArticleDialog ids={getIds()} context={context}
                                         forceOptions={forceOptions}/>
                <Tooltip
                    title={t('articles.push_online') ?? ''}>
                    <IconButton
                        onClick={() => dispatch(pushArticlesOnlineResponseAction(getIds()))}>
                        <PushOnlineIcon/>
                    </IconButton>
                </Tooltip>
            </Auth>
        )
    } else {
        return (<></>)
    }
}

export default PushOnlineArticle;

import {DateTime} from "luxon"
import {PlanNode} from "./plan-node";
import {JouveProcessStatus} from "./workflow";

export enum EditionType {
    STANDARD = 'STANDARD',
    SPECIAL_EDITION = 'SPECIAL_EDITION'
}

export enum PublicationStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    PUBLISHING = 'PUBLISHING',
    PUBLISHED = 'PUBLISHED'
}

export type EditablePublication = {
    title?: string
    periodicalId: string
    year: number
    number: string
    editionType: EditionType
    startDate: string
    endDate?: string
    articleId?: string
    imageId?: string
    text?: string
}

export type Publication = EditablePublication & {
    id: number
    status: PublicationStatus
    plan?: PlanNode[],
    validationDate?: string,
    lastPrinterProcessStatus?: JouveProcessStatus,
    lastAvailablePlacardProcess?: string,
    lastRequestPlacardProcess?: JouveProcessStatus,
    lastAvailablePlacardProcessStatus?: JouveProcessStatus,
    allArticlesReady: boolean,
    closingDate?: DateTime
}

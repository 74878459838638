import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import {addSearchQueryParam, removeSearchQueryParam} from "../../../redux/actions/common-search.actions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch} from "react-redux";
import {PublicationStatus} from "../../../types/publication";
import {SearchContext} from "../../../types/common-search";

const PublicationStatusFilter = ({statusFilter}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleChange = (value: string, checked: boolean) => {
        if (checked) {
            dispatch(addSearchQueryParam({
                ctx: SearchContext.PUBLICATION,
                field: 'statusFilter',
                value: value
            }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.PUBLICATION,
                field: 'statusFilter',
                value: value
            }))
        }
    }

    return (
        <ListItem label={t('publications.filters.status')}>
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange(PublicationStatus.IN_PROGRESS, check)}
                        checked={statusFilter.some((e) => e === PublicationStatus.IN_PROGRESS)}
                    />}
                    label={t('enums.publications.status.' + PublicationStatus.IN_PROGRESS)}
                />
            </FormGroup>
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange(PublicationStatus.PUBLISHING, check)}
                        checked={statusFilter.some((e) => e === PublicationStatus.PUBLISHING)}
                    />}
                    label={t('enums.publications.status.' + PublicationStatus.PUBLISHING)}
                />
            </FormGroup>
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange(PublicationStatus.PUBLISHED, check)}
                        checked={statusFilter.some((e) => e === PublicationStatus.PUBLISHED)}
                    />}
                    label={t('enums.publications.status.' + PublicationStatus.PUBLISHED)}
                />
            </FormGroup>
        </ListItem>
    )
}

export default PublicationStatusFilter

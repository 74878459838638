import React from 'react';
import { Typography, TypographyProps } from "@material-ui/core";

export const KyrSubTitle = (props: TypographyProps) => {
    const { children, style, ...otherProps } = props

    return (
    <Typography style={{...style, fontWeight: 500}} {...otherProps}>
        {children}
    </Typography>
    )
}
import {Action} from "../../actions/utils.actions";
import {
    isDeleteArticleAction,
    isDeleteArticleErrorAction,
    isDeleteArticlesAction,
    isDeleteArticlesErrorAction,
    isDeleteArticlesSuccessAction,
    isDeleteArticleSuccessAction,
    isFetchArticleSuccessAction, isPushArticlesOnlineResponseAction,
    isToggleArticleCoverAction,
    isToggleArticleErrorAction,
    isToggleArticleSuccessAction
} from "../../actions/article/article.actions";
import {
    isMoveArticleAction,
    isMoveArticleErrorAction,
    isMoveArticlesAction,
    isMoveArticlesErrorAction,
    isMoveArticlesSuccessAction,
    isMoveArticleSuccessAction
} from "../../actions/article/article-dialog.actions";
import {ArticleResponse} from "../../../types/article";

export type ArticleState = {
    isDoingRequest: boolean
    currentFetchedArticle: ArticleResponse | undefined
    articleIdsToPushOnline: string[] | undefined
}

const init: ArticleState = {
    isDoingRequest: false,
    currentFetchedArticle: undefined,
    articleIdsToPushOnline: undefined
}

export default function ArticleReducer(state = init, action: Action): ArticleState {
    if (isFetchArticleSuccessAction(action)) {
        return {
            ...state,
            currentFetchedArticle: action.payload
        }
    } else if (isPushArticlesOnlineResponseAction(action)) {
        return {...state, articleIdsToPushOnline: action.payload}
    } else if (isDeleteArticlesAction(action) || isDeleteArticleAction(action)
        || isMoveArticleAction(action) || isMoveArticlesAction(action)
        || isToggleArticleCoverAction(action)
    ) {
        return {...state, isDoingRequest: true}
    } else if (
        isDeleteArticlesSuccessAction(action) || isDeleteArticleSuccessAction(action)
        || isMoveArticleSuccessAction(action) || isMoveArticlesSuccessAction(action)
    ) {
        return {...state, isDoingRequest: false}
    } else if (isToggleArticleSuccessAction(action)) {
        return {
            ...state,
            currentFetchedArticle: state.currentFetchedArticle ? {
                ...state.currentFetchedArticle,
                isFrontCover: action.payload
            } : undefined, isDoingRequest: false
        }
    } else if (
        isDeleteArticlesErrorAction(action) || isDeleteArticleErrorAction(action)
        || isMoveArticleErrorAction(action) || isMoveArticlesErrorAction(action)
        || isToggleArticleErrorAction(action)
    ) {
        return {...state, isDoingRequest: false}
    } else {
        return state
    }
}

import {createSelector} from "reselect";
import {State} from "../../reducers";
import {ArticleDialogMode} from "../../reducers/article/article-dialog.reducer";

export const articleDialogSelector = ({article}: State) => article.dialog;

export const currentArticleSelector = createSelector(articleDialogSelector, dialog => dialog.articleForm)
export const articleImportStatusSelector = createSelector(articleDialogSelector, dialog => dialog.articleImportStatus)
export const availablePublicationsSelector = createSelector(articleDialogSelector, dialog => dialog.publications)
export const availableTopicsSelector = createSelector(articleDialogSelector, dialog => dialog.topics)
export const availableBundlePlansSelector = createSelector(articleDialogSelector, dialog => dialog.bundlePlanVersion)
export const availableArticleTagsSelector = createSelector(articleDialogSelector, dialog => dialog.articleTags)
export const availablePeriodicalBundlesSelector = createSelector(articleDialogSelector, dialog => dialog.periodicalBundles)
export const availablePublicationBundlesSelector = createSelector(articleDialogSelector, dialog => dialog.publicationBundles)
export const openDialog = createSelector(articleDialogSelector, dialog => dialog.mode !== ArticleDialogMode.CLOSE)
export const dialogMode = createSelector(articleDialogSelector, dialog => dialog.mode)
export const isBundleModeSelector = createSelector(articleDialogSelector, dialog => dialog.articleForm?.isBundle || false)
export const sourceSelector = createSelector(articleDialogSelector, dialog => dialog.sourceId)

export const currentTitleSelector = createSelector(currentArticleSelector, article => article?.title)
export const currentTypeSelector = createSelector(currentArticleSelector, article => article?.type)
export const currentPeriodicalIdSelector = createSelector(currentArticleSelector, article => article?.periodicalId)
export const currentBundleIdSelector = createSelector(currentArticleSelector, article => article?.bundleId)
export const currentBundlePlanVersionIdSelector = createSelector(currentArticleSelector, article => article?.bundlePlanVersionId)
export const currentPlanNodeIdSelector = createSelector(currentArticleSelector, article => article?.planNodeId)
export const currentPublicationIdSelector = createSelector(currentArticleSelector, article => article?.publicationId)
export const currentNombreDePageSelector = createSelector(currentArticleSelector, article => article?.tags?.nombreDePages)

export const isDoingRequestSelector = createSelector(({article}: State) => article.infos, article => article.isDoingRequest)
export const articleIdsToPushOnlineSelector = createSelector(({article}: State) => article.infos, article => article.articleIdsToPushOnline)
export const currentFetchedArticleSelector = createSelector((state: State) => state.article, (articleState) => articleState.infos.currentFetchedArticle)

import {ImageColor, ImageFormat, ImageFraming, ImageQuality, MetadataImage} from "./image";
import {DateTime} from "luxon";

export enum AdPriority {
    PRIORITY = "PRIORITY",
    NON_PRIORITY = "NON-PRIORITY"
}

export type AdSize = { label: string, key: string }
export type Advertisement = {
    id: string
    name: string
    used: boolean
    disabled: boolean
    comments: string
    priority: boolean
    periodicals: string[]

    collection?: string
    uploadDate?: string
    modificationDate?: string
    deadline?: string
    framing?: ImageFraming
    color?: ImageColor
    format?: ImageFormat
    formatLabel?: string
    meta?: MetadataImage
    quality?: ImageQuality,
    characteristics?: string[],
    lastPublicationsModified?: LinkAd[],
    version: number
}

export type LinkAd = {
    title?: string,
    modificationDate: DateTime,
    publicationId: number,
    publicationName: string
}

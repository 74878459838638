import { useContext } from "react";
import { useSelector } from "react-redux";
import { State } from "../../redux/reducers";
import { TreeContext } from "../contexts/organizeTreeBibleContexts";
import { TreeModel } from "../models/TreeModels";
import {
  addNodeIdIntoDocumentUnit,
} from "../pages/organizeTree/tree/tree.utils";
import { query } from "../utils/query";

export enum SaveType {
  ADMIN,
  ORGANIZE,
}

export const useHandleSaveTree = () => {
  const [tree, setTree] = useContext(TreeContext);
  const token = useSelector(({ auth }: State) => auth.token);

  // Set up the headers for the fetch request
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return async (saveType: SaveType = SaveType.ORGANIZE) => {
    const url =
      saveType === SaveType.ORGANIZE
        ? `/api/kyrielle/tree/${tree.data?.id}/save`
        : `/api/kyrielle/tree/${tree.data?.id}/admin/save`;

    await query<TreeModel>({
      url,
      method: "post",
      headers,
      setter: setTree,
      body: tree.data,
      mapper: addNodeIdIntoDocumentUnit,
    });
  };
};

export const useGetTree = () => {
  const [tree, setTree] = useContext(TreeContext);
  const token = useSelector(({ auth }: State) => auth.token);

  // Set up the headers for the fetch request
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return async (id: string): Promise<TreeModel> => {
    const data = (await query<TreeModel>({
      url: `/api/kyrielle/tree/${id}`,
      method: "get",
      headers,
      setter: setTree,
      body: tree.data,
      mapper: addNodeIdIntoDocumentUnit,
    })) as TreeModel;

    return data;
  };
};
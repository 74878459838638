import {EMPTY, merge, Observable, of} from "rxjs";
import {StateObservable} from "redux-observable";
import {State} from "../../reducers";
import {EpicDependencies} from "../../store";
import {catchError, concatMap, filter, map, switchMap} from "rxjs/operators";
import {AxiosStatic} from "axios";
import {fromPromise} from "rxjs/internal-compatibility";
import {notifyError} from "../../actions/notification.actions";
import {autoCompleteError, autoCompleteSuccess, isAutoCompleteAction} from "../../actions/search/search-author.actions";
import {
    isAddSearchQueryParamAction,
    isInitDefaultPeriodicalQueryParamAction,
    isInitDefaultQueryParamAction,
    isReloadSearchAction,
    isRemoveSearchQueryParamAction,
    isResetSearchQueryParamsAction,
    isSearchAction,
    isUpdateSearchQueryParamAction,
    search,
    searchError,
    searchSuccess
} from "../../actions/common-search.actions";
import {
    isLoadAutoCompletePublicationAction,
    loadAutoCompletePublicationError,
    loadAutoCompletePublicationSuccess
} from "../../actions/article/article-filters.actions"
import {i18n} from "i18next";
import {buildPublicationQueryParams, readPublicationQueryParams} from "../../../utils/publication-utils";
import {Action} from "../../actions/utils.actions";
import {
    ArticleSearchFilters,
    AutoComplete,
    Filters,
    PublicationSearchFilters,
    SearchContext,
    SearchResults
} from "../../../types/common-search";
import {
    buildArticleQueryParams,
    readArticleQueryParams,
    readArticleQueryParamsWithForceOptions
} from "../../../utils/article-utils";
import {buildAuthorQueryParams, readAuthorQueryParams} from "../../../utils/author-utils";
import {buildImageQueryParams, readImageQueryParams} from "../../../utils/image.utils";
import {EntityProposal} from "../../../types/quick-search";

export function autoComplete(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isAutoCompleteAction),
        concatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return autoCompleteRequest(axios, payload, token)
        }),
    );
}

export function autoCompletePublications(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isLoadAutoCompletePublicationAction),
        concatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return postSearchPublicationRequest(axios, payload, token)
        }),
    );
}

function postSearchPublicationRequest(axios: AxiosStatic, data: PublicationSearchFilters, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const uri = "/api/search/autocomplete/publication";

    return fromPromise(axios.post<EntityProposal[]>(uri, data, {headers: headers}))
        .pipe(
            map((response) => loadAutoCompletePublicationSuccess(response.data)),
            catchError(err => merge(
                of(loadAutoCompletePublicationError(err.response)),
                of(notifyError(+err.response.status, {type: SearchContext.PUBLICATION_ARTICLE}))
            ))
        );
}


function autoCompleteRequest(axios: AxiosStatic, data: AutoComplete, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    let url = `/api/author/search/autocomplete/` + data.attribute;
    if (data.query && data.query !== "") {
        url = url + "?text=" + data.query;
    }
    return fromPromise(axios.get<string[]>(url, {headers: headers}))
        .pipe(
            map((response) => autoCompleteSuccess({attribute: data.attribute, results: response.data})),
            catchError(err => merge(
                of(autoCompleteError(data.attribute)),
                of(notifyError(+err.response.status, {type: 'Auteur'}))
            ))
        );
}

export function fetchSearchResultPageOnSearchChange(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSearchAction),
        concatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            const {ctx, filters} = payload;
            return postSearchRequest(axios, ctx, filters, token)
        }),
    );
}

function postSearchRequest(axios: AxiosStatic, ctx: SearchContext, data: Filters, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    const uri = endpoints[ctx];
    return fromPromise(axios.post<SearchResults>(uri, data, {headers: headers}))
        .pipe(
            map((response) => searchSuccess({ctx, results: response.data})),
            catchError(err => merge(
                of(searchError(ctx)),
                of(notifyError(+err.response.status, {type: ctx}))
            ))
        );
}

export function updateLocationOnQueryParamChange(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isUpdateSearchQueryParamAction)
    ).subscribe(({payload}) => {
        const {ctx, field, value} = payload;
        const former = mapper[ctx].queryReader(history.location.search);
        const filters = {
            ...former,
            [field]: value,
            'page' : field !== 'page' ? undefined : value
        }
        const query = mapper[ctx].queryBuilder(filters);
        const url = buildUrl(i18n, ctx, query);
        console.log(url);
        history.replace(url);
    });
    return EMPTY
}

export function resetQueryParam(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isResetSearchQueryParamsAction)
    ).subscribe((action) => {
        const ctx = action.payload;
        const former = mapper[ctx].queryReader(history.location.search) as { query?: string, grid?: boolean };
        const filters = {
            query: former.query,
            grid: former.grid,
        }
        const query = mapper[ctx].queryBuilder(filters);
        const url = buildUrl(i18n, ctx, query);
        history.replace(url);
    });
    return EMPTY
}

export function updateLocationOnAddElementQueryParamChange(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isAddSearchQueryParamAction)
    ).subscribe(({payload}) => {
        const {ctx, field, value} = payload;
        const former = mapper[ctx].queryReader(history.location.search);
        const filters = {
            ...former,
            [field]: Array.isArray(former[field]) ? [...former[field], value] : value
        }
        const query = mapper[ctx].queryBuilder(filters);
        const url = buildUrl(i18n, ctx, query);
        history.replace(url);
    });
    return EMPTY
}

export function updateLocationOnRemoveElementQueryParamChange(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isRemoveSearchQueryParamAction)
    ).subscribe(({payload}) => {
        const {ctx, field, value} = payload;
        const former = mapper[ctx].queryReader(history.location.search);
        const filters = {
            ...former,
            [field]: Array.isArray(former[field]) ? former[field].filter(cur => cur !== value) : undefined
        }
        const query = mapper[ctx].queryBuilder(filters);
        const url = buildUrl(i18n, ctx, query);
        history.replace(url);
    });
    return EMPTY
}

export function initLocationWithPeriodical(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isInitDefaultPeriodicalQueryParamAction)
    ).subscribe(({payload: ctx}) => {
        const former = mapper[ctx].queryReader(history.location.search);
        if (former.hasOwnProperty('imageFilter') && former['imageFilter'] && former['imageFilter'].length > 0) {
            return EMPTY
        }
        const attr = ctx === SearchContext.ARTICLE ? 'periodicalFilter' : 'periodicalIdFilter';
        const fav = sessionStorage.getItem('fav')?.split(',') ?? [];
        const statut_fav = sessionStorage.getItem('statut_fav');

        const f = former as ArticleSearchFilters
        let statusFilter = f.statusFilter
        if(statut_fav === 'true' && history.location.pathname.startsWith("/article")) {
            if (f.statusFilter && !f.statusFilter.includes('NOT_ONLINE')) {
                statusFilter = [...f.statusFilter, 'NOT_ONLINE']
            }
        }
        const filters = {
            ...former,
            [attr]: fav,
            'statusFilter' : statusFilter
        }
        const query = mapper[ctx].queryBuilder(filters);
        const url = buildUrl(i18n, ctx, query);
        history.replace(url);
    })
    return EMPTY
}

export function initLocationWithCollection(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isInitDefaultQueryParamAction)
    ).subscribe(({payload}) => {
        const {ctx, field, value} = payload;
        const former = mapper[ctx].queryReader(history.location.search);
        const filters = {
            ...former,
            [field]: value
        }
        const query = mapper[ctx].queryBuilder(filters);
        const url = buildUrl(i18n, ctx, query);
        history.replace(url);
    })
    return EMPTY
}

export function reloadSearch(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    return action$.pipe(
        filter(isReloadSearchAction),
        switchMap((action) => {
            const {ctx, forceOptions} = action.payload;
            const path = history.location.pathname;
            const base = mapper[ctx].baseFrontUri(i18n);
            return path.startsWith(base) ? of(search({
                ctx,
                filters: mapper[ctx].queryReader(history.location.search, forceOptions)
            })) : EMPTY;
        })
    );
}

const endpoints = {
    [SearchContext.AUTHOR]: '/api/author/search',
    [SearchContext.IMAGE]: '/api/image/search',
    [SearchContext.ADVERTISEMENT]: '/api/image/search',
    [SearchContext.PUBLICATION]: '/api/publication/search',
    [SearchContext.ARTICLE]: '/api/article/search',
    [SearchContext.PUBLICATION_ARTICLE]: '/api/article/search',
    [SearchContext.PUBLICATION_AD]: '/api/image/search',
    [SearchContext.BUNDLE_ARTICLE]: '/api/article/search',
    [SearchContext.BUNDLE_AD]: '/api/image/search',
}

type Mapper = {
    baseFrontUri: (i18n: i18n) => string
    queryReader: (query: string, options?: any) => Filters
    queryBuilder: (filter: any) => string
}

const mapper: {
    [key: string]: Mapper
} = {
    [SearchContext.AUTHOR]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.author.root`)}`,
        queryReader: readAuthorQueryParams,
        queryBuilder: buildAuthorQueryParams,
    },
    [SearchContext.IMAGE]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.image.root`)}`,
        queryReader: readImageQueryParams,
        queryBuilder: buildImageQueryParams,
    },
    [SearchContext.ADVERTISEMENT]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.ad.root`)}`,
        queryReader: readImageQueryParams,
        queryBuilder: buildImageQueryParams,
    },
    [SearchContext.PUBLICATION]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.publication.root`)}`,
        queryReader: readPublicationQueryParams,
        queryBuilder: buildPublicationQueryParams,
    },
    [SearchContext.PUBLICATION_ARTICLE]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.publication.root`)}`,
        queryReader: readArticleQueryParamsWithForceOptions,
        queryBuilder: buildArticleQueryParams,
    },
    [SearchContext.PUBLICATION_AD]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.publication.root`)}`,
        queryReader: readImageQueryParams,
        queryBuilder: buildImageQueryParams,
    },
    [SearchContext.BUNDLE_ARTICLE]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.bundle.root`)}`,
        queryReader: readArticleQueryParamsWithForceOptions,
        queryBuilder: buildArticleQueryParams,
    },
    [SearchContext.BUNDLE_AD]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.bundle.root`)}`,
        queryReader: readImageQueryParams,
        queryBuilder: buildImageQueryParams,
    },
    [SearchContext.ARTICLE]: {
        baseFrontUri: (i18n: i18n) => `/${i18n.t(`routes.article.root`)}`,
        queryReader: readArticleQueryParams,
        queryBuilder: buildArticleQueryParams,
    }
}

function buildUrl(i18n: i18n, ctx: SearchContext, query: string): string {
    return mapper[ctx].baseFrontUri(i18n) + query
}

import {
    isDispatchArticleAction,
    isDispatchArticleResponseAction,
    isDispatchMultipleArticlesAction,
    isDispatchMultipleArticlesResponseAction,
    isDispatchMultiplePublicationsAction,
    isDispatchMultiplePublicationsResponseAction,
    isDispatchPublicationAction,
    isDispatchPublicationResponseAction
} from "../actions/workflow.actions";
import {Action} from "../actions/utils.actions";

type WorkflowState = {
    processInProgress: string[]
}

const init: WorkflowState = {
    processInProgress: []
};

const reducer = (state = init, action: Action): WorkflowState => {
    if (isDispatchMultiplePublicationsResponseAction(action)) {
        return {processInProgress: state.processInProgress.filter(x => x !== action.payload)}
    } else if (isDispatchMultipleArticlesResponseAction(action)) {
        return {processInProgress: state.processInProgress.filter(x => x !== action.payload.uuid)}
    } else if (isDispatchMultipleArticlesAction(action)) {
        return {processInProgress: [...state.processInProgress, action.payload.uuid]}
    } else if (isDispatchMultiplePublicationsAction(action)) {
        return {processInProgress: [...state.processInProgress, action.payload.uuid]}
    } else if (isDispatchArticleResponseAction(action)) {
        return {processInProgress: state.processInProgress.filter(x => x !== action.payload.uuid)}
    } else if (isDispatchArticleAction(action)) {
        return {processInProgress: [...state.processInProgress, action.payload.uuid]}
    } else if (isDispatchPublicationResponseAction(action)) {
        return {processInProgress: state.processInProgress.filter(x => x !== action.payload.uuid)}
    } else if (isDispatchPublicationAction(action)) {
        return {processInProgress: [...state.processInProgress, action.payload.uuid]}
    } else {
        return state;
    }
}

export default reducer

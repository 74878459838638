import {makeStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import {setArticleDialogMode} from "../../redux/actions/article/article-dialog.actions";
import {ArticleDialogMode} from "../../redux/reducers/article/article-dialog.reducer";
import Auth from "../Auth";
import {ADMIN, EDITOR, MANAGER} from "../../utils/roles";
import ImportArticle from "../import-article/ImportArticle";
import StyledFab from "../commons/StyledFab";

const fixedActionButtonListStyle = makeStyles(() => ({
    groupButton: {
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        bottom: "10em",
        right: "-0.5em",
        padding: "0.5em 0"
    }
}));

export default () => {
    const classes = fixedActionButtonListStyle();

    const dispatch = useDispatch();
    const location = useLocation();
    const {t} = useTranslation();

    const displayCDFButton = (cur: string) => {
        return ![
            `/${t('routes.author.root')}`,
            `/${t('routes.image.root')}`,
            `/${t('routes.ad.root')}`
        ].some(path => cur.startsWith(path));
    }

    const openCreateArticleDialog = () => {
        dispatch(setArticleDialogMode({
            mode: ArticleDialogMode.CREATE
        }))
    }

    return (
        <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}>
            <div className={classes.groupButton}>
                {displayCDFButton(location.pathname) && <ImportArticle fab/>}
                <StyledFab size="small" aria-label="add"
                           onClick={openCreateArticleDialog}>
                    <Tooltip title="Créer un nouvel article">
                        <AddIcon/>
                    </Tooltip>
                </StyledFab>
            </div>
        </Auth>
    )
}

import './wdyr'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import store, { history } from "./redux/store";
import { Provider } from "react-redux";
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import i18next from './i18n';
import { I18nextProvider } from "react-i18next";
import { Router } from "react-router";
import { SnackbarProvider } from "notistack";
import DeleteArticleDialogProvider from "./components/commons/context/DeleteArticleDialogContext";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={theme}>
            <DeleteArticleDialogProvider>
                <Provider store={store()}>
                    <SnackbarProvider>
                        <Router history={history}>
                            <I18nextProvider i18n={i18next}>
                                <App />
                            </I18nextProvider>
                        </Router>
                    </SnackbarProvider>
                </Provider>
            </DeleteArticleDialogProvider>
        </ThemeProvider>
    </DndProvider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {useEffect, useState} from "react";
import {Grid, RadioGroup, Table} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import {Search} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {PlanNode} from "../../../types/plan-node";
import {removeChildren} from "../../commons/tree/TreeViewer";
import {useDispatch, useSelector} from "react-redux";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import {State} from "../../../redux/reducers";
import {AdSearchResult, ArticleSearchResult, SearchContext} from "../../../types/common-search";
import {search} from "../../../redux/actions/common-search.actions";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import TablePagination from "../../authors/list/TablePagination";
import {planHasId} from "../../../utils/plan-utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MemoCheckbox from "../../commons/MemoCheckbox";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import {deleteArticle} from "../../../redux/actions/article/article.actions";
import {isDoingRequestSelector} from "../../../redux/selectors/article/article.selectors";
import DeleteArticleDialogProvider from "../../commons/context/DeleteArticleDialogContext";
import OrganizeRowTable from "../../publications/organize/OrganizeRowTable";
import {forceBundleOptions} from "../../../utils/publication-utils";
import {
    setAttachedOnlyForceOptions,
    setQueryForceOptions,
    setSizePageForceOptions
} from "../../../redux/actions/search/force-search-options.actions";
import {
    attachedOnlySelected,
    pageSizeSelector,
    querySelector
} from "../../../redux/selectors/forceOptions.selectors";

type Props = {
    nodes: PlanNode[]
    setNodes: any
    page: number
    setPage: any
}

function BundleOrganizeTableComponent({nodes, setNodes, page, setPage}: Props) {
    const dispatch = useDispatch()
    const {id} = useParams() as { id: string }
    const {t} = useTranslation()
    const bundle = useSelector(currentFetchedBundleSelector)
    const publication = useSelector(getCurrentPublication)
    const [isAdMode, setAdMode] = useState(false);
    const [isInit, setInit] = useState(false);
    const isDoingRequest = useSelector(isDoingRequestSelector)

    useEffect(() => {
        new Promise<void>((resolve) => {
            dispatch(setSizePageForceOptions(50));
            dispatch(setQueryForceOptions(undefined));
            resolve();
        }).then(() => setInit(true));
    }, [])

    const handleUnlink = (id: string) => () => {
        setNodes(removeChildren(nodes, [id]))
    }

    const handleChangeMode = (newMode: string) => {
        setAdMode(newMode === 'ad');
        setPage(0);
        dispatch(setQueryForceOptions(undefined))
    }

    const [articles, ads, totalArticles, totalAds]: [ArticleSearchResult[], AdSearchResult[], number, number] = useSelector(({search}: State) =>
        [search.common[SearchContext.BUNDLE_ARTICLE].searchResults,
            search.common[SearchContext.BUNDLE_AD].searchResults,
            search.common[SearchContext.BUNDLE_ARTICLE].totalCount,
            search.common[SearchContext.BUNDLE_AD].totalCount]);

    const queryId = useSelector(querySelector)
    const size = useSelector(pageSizeSelector)
    const attachedOnly = useSelector(attachedOnlySelected)

    useEffect(() => {
        if (!id || id === 'undefined' || isDoingRequest || !isInit) {
        } else {
            if (isAdMode)
                dispatch(search({
                    ctx: SearchContext.BUNDLE_AD,
                    filters: forceBundleOptions(isAdMode, bundle, attachedOnly, publication, queryId, size, page)
                }))

            if (!isAdMode && bundle)
                dispatch(search({
                    ctx: SearchContext.BUNDLE_ARTICLE,
                    filters: forceBundleOptions(isAdMode, bundle, attachedOnly, publication, queryId, size, page)
                }))
        }
    }, [id, bundle?.id, isAdMode, queryId, page, size, publication?.id, attachedOnly, isDoingRequest, isInit])


    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);
    const [articleBeingDeleted] = useState({id: "", isBundle: false})

    const handleArticleDeletion = () => {
        new Promise<void>((resolve) => {
            dispatch(deleteArticle(articleBeingDeleted))
            resolve();
        }).then(() => openConfirmationDialog(false))
    }

    const result: (AdSearchResult | ArticleSearchResult)[] = isAdMode ? ads : articles

    return <>
        <ConfirmationDialog
            title={t(articleBeingDeleted.isBundle ? 'bundles.delete_confirmation' : 'articles.delete_confirmation')}
            description={''}
            actions={[
                {label: t('dialogs.cancel'), color: 'default', action: () => openConfirmationDialog(false)},
                {label: t('dialogs.confirm'), action: handleArticleDeletion, variant: 'contained'}
            ]}
            isOpened={confirmationDialogOpen}
            onClose={() => openConfirmationDialog(false)}
        />

        <TableContainer component={Paper} style={{padding: 8, width: "calc(100% - 32px)"}}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell variant={"head"} colSpan={isAdMode ? 2 : 4}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <Search/>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField
                                        label="Rechercher"
                                        fullWidth={true}
                                        value={queryId}
                                        onChange={(v) => {
                                            dispatch(setQueryForceOptions(v.target.value))
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </TableCell>

                        <TableCell colSpan={3}>
                            <Grid container justify={"flex-end"}>
                                <RadioGroup
                                    row
                                    value={isAdMode ? 'ad' : 'article'}
                                    onChange={v => handleChangeMode(v.target.value)}>
                                    <FormControlLabel
                                        control={<MemoCheckbox checked={attachedOnly}
                                                               onChange={v => {
                                                                   dispatch(setAttachedOnlyForceOptions(v.target.checked))
                                                               }}/>}
                                        label={t('bundles.attached')}/>
                                </RadioGroup>
                            </Grid>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>{t('misc.id')}</TableCell>
                        <TableCell>{t('misc.designation')}</TableCell>
                        <TableCell>{t('articles.modified')}</TableCell>
                        <TableCell align={"center"}>{t('articles.status.name')}</TableCell>
                        <TableCell align={"center"}>{t('misc.actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {(!result || result?.length === 0) &&
                    <TableRow><TableCell colSpan={8} align={"center"}>{t('table.no_result')}</TableCell></TableRow>}

                    {result && result.map((it: AdSearchResult | ArticleSearchResult) => <OrganizeRowTable
                        page={'bundle'}
                        unlink={handleUnlink(it.id)}
                        isLinked={planHasId(nodes, it.id)}
                        infos={it}
                        isAdMode={isAdMode}
                        key={it.id}
                        attachedOnly={attachedOnly}
                        bundle={bundle}
                        publication={publication}/>
                    )}
                </TableBody>
            </Table>

            {!isAdMode && <TablePagination page={page} size={size}
                                           totalCount={totalArticles}
                                           updateChangePage={(p) => {
                                               setPage(p)
                                           }}
                                           updateChangeRowPerPage={(s) => {
                                               setPage(0)
                                               dispatch(setSizePageForceOptions(s))
                                           }}/>}

            {isAdMode && <TablePagination page={page} size={size}
                                          totalCount={totalAds}
                                          updateChangePage={(p) => {
                                              setPage(p)
                                          }}
                                          updateChangeRowPerPage={(s) => {
                                              setPage(0)
                                              dispatch(setSizePageForceOptions(s))
                                          }}/>}
        </TableContainer>
    </>
}

const BundleOrganizeTable = (props: Props) =>
    <DeleteArticleDialogProvider><BundleOrganizeTableComponent {...props}/></DeleteArticleDialogProvider>
export default BundleOrganizeTable

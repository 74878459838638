import {makeStyles} from "@material-ui/core/styles";
import {lighten} from "@material-ui/core";

export const tableStyle = makeStyles((theme) => ({
    tableRowHover: {
        cursor: 'pointer'
    },
    row: {
        height: '89px'
    },
    image: {
        maxWidth: 65,
        paddingTop: 5
    },
    actionGroup: {
        background: lighten(theme.palette.secondary.main, 0.85)
    },
    iconButton: {
        padding: 5
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    cell: {
        padding: 2,
        fontSize: theme.typography.body1.fontSize,
    },
    actionCell: {
        padding: 2,
        fontSize: theme.typography.body1.fontSize,
        minWidth: 255
    },
    root: {
        marginTop: 33,
        "& th:nth-child(2)": {
            width: 80,
            paddingBottom: 0
        },
        "& th:nth-child(7)": {
            width: 250
        },
        "& th:nth-child(8)": {
            width: 135
        },
        "& th:nth-child(9)": {
            width: 275
        }
    }
}))

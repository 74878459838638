import {AxiosStatic} from "axios";
import {EMPTY, iif, merge, Observable, of} from "rxjs";
import {Action} from "../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, filter, flatMap, map, mergeMap} from "rxjs/operators";
import {notifyError, notifySuccess} from "../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import i18n from "i18next";
import {Advertisement} from "../../types/ads";
import {
    deleteAdError,
    DeleteAdPayload,
    deleteAdSuccess,
    deleteManyAdsError,
    deleteManyAdsSuccess,
    fetchAdConfigError,
    fetchAdConfigSuccess,
    fetchAdError,
    fetchAdSizesError,
    fetchAdSizesSuccess,
    fetchAdSuccess,
    isDeleteAdAction,
    isDeleteManyAdsAction,
    isFetchAdAction,
    isFetchAdConfigAction,
    isFetchAdSizesAction,
    isSaveAdAction,
    isSaveAdSuccessAction,
    isToggleAdDisabledAction,
    isToggleManyAdDisabledAction,
    saveAdError,
    saveAdSuccess,
    toggleAdDisabledError,
    toggleAdDisabledSuccess,
    toggleManyAdDisabledError,
    toggleManyAdDisabledSuccess
} from "../actions/ad.actions";

import {reloadSearch} from "../actions/common-search.actions"
import {SearchContext} from "../../types/common-search";

function _saveAd(axios: AxiosStatic, ad: Advertisement, id: string | undefined, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.put(`/api/advertisement/${id}`, {
        ...ad,
        deadline: ad.deadline?.split('T')[0]
    }, {headers}))
        .pipe(
            mergeMap(_ => merge(of<Action>(
                saveAdSuccess(),
                notifySuccess(i18n.t('ads.save_success')))),
            ),
            catchError(err => merge(
                of(saveAdError()),
                of(notifyError(+err.response.status, {type: 'ADVERTISEMENT'}))
            ))
        );
}

function _fetchAdSizes(axios: AxiosStatic, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/nomenclature/advertisement/sizes`, {headers}))
        .pipe(
            map((result) => fetchAdSizesSuccess(result.data)),
            catchError(err => merge(
                of(fetchAdSizesError()),
                of(notifyError(+err.response.status, {type: 'ADVERTISEMENT'}))
            ))
        )
}

function _fetchAdConfig(axios: AxiosStatic, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/nomenclature/advertisement/config`, {headers}))
        .pipe(
            map((result) => fetchAdConfigSuccess(result.data)),
            catchError(err => merge(
                of(fetchAdConfigError()),
                of(notifyError(+err.response.status, {type: 'ADVERTISEMENT'}))
            ))
        )
}

function _fetch(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/advertisement/${id}`, {headers}))
        .pipe(
            map((result) => fetchAdSuccess(result.data)),
            catchError(err => merge(
                of(fetchAdError()),
                of(notifyError(+err.response.status, {type: 'ADVERTISEMENT'}))
            ))
        )
}

function _delete(axios: AxiosStatic, payload: DeleteAdPayload, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.delete(`/api/advertisement/${payload.id}`, {headers}))
        .pipe(
            mergeMap(_ => merge(of<Action>(
                deleteAdSuccess(),
                notifySuccess(i18n.t('ads.delete_success')),
                ),
                iif(() => !!payload.ctx, of(reloadSearch({ctx: payload.ctx!, forceOptions: payload.forceOptions})))
                ),
            ),
            catchError(err => merge(
                of(deleteAdError()),
                of(notifyError(+err.response.status, {type: 'ADVERTISEMENT'}))
            ))
        );
}

const _toggleManyAdDisabled = (axios: AxiosStatic, ids: string[], flag: boolean, token: string): Observable<Action> => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    return fromPromise(axios.put(`/api/advertisement/status/many`, {ids: ids, disabled: flag}, {headers}))
        .pipe(
            mergeMap(() => merge(of<Action>(
                toggleManyAdDisabledSuccess(flag),
                reloadSearch({ctx: SearchContext.ADVERTISEMENT, forceOptions: {collectionFilter: ["ADVERTISEMENT"]}}),
                notifySuccess(i18n.t(flag ? 'ads.toggle_disable' : 'ads.toggle_enable', {count: ids.length})))
                ),
            ),
            catchError(err => merge(
                of(toggleManyAdDisabledError()),
                of(notifyError(+err.response.status, {type: 'ADVERTISEMENT'}))
            ))
        )
}

const _toggleAdDisabled = (axios: AxiosStatic, id: string, flag: boolean, token: string): Observable<Action> => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    return fromPromise(axios.put(`/api/advertisement/${id}/status`, {disabled: flag}, {headers}))
        .pipe(
            mergeMap(() => of(
                toggleAdDisabledSuccess(flag),
                notifySuccess(i18n.t(flag ? 'ads.toggle_disable' : 'ads.toggle_enable')),
                reloadSearch({ctx: SearchContext.ADVERTISEMENT, forceOptions: {collectionFilter: ["ADVERTISEMENT"]}})),
            ),
            catchError(err => merge(
                of(toggleAdDisabledError()),
                of(notifyError(+err.response.status, {type: 'ADVERTISEMENT'}))
            ))
        )
}

function _deleteMany(axios: AxiosStatic, ids: string[], token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/advertisement/delete`, ids, {headers}))
        .pipe(
            mergeMap(_ => merge(of<Action>(
                deleteManyAdsSuccess(),
                reloadSearch({ctx: SearchContext.ADVERTISEMENT, forceOptions: {collectionFilter: ["ADVERTISEMENT"]}}),
                notifySuccess(i18n.t('ads.delete_success', {count: ids.length})))),
            ),
            catchError(err => merge(
                of(deleteManyAdsError()),
                of(notifyError(+err.response.status, {type: 'ADVERTISEMENT'}))
            ))
        );
}

export function redirectOnSaveAds(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history} = dependencies;

    action$.pipe(
        filter(isSaveAdSuccessAction)
    ).subscribe(() => {
        history.push({
            pathname: `/${i18n.t('routes.ad.root')}`
        })
    })
    return EMPTY
}

export function deleteManyAdsEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isDeleteManyAdsAction),
        flatMap((action) => {
            const token = state$.value.auth.token ?? "";
            return _deleteMany(axios, action.payload, token)
        })
    )
}

export function toggleManyAdDisabledEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isToggleManyAdDisabledAction),
        flatMap((action) => _toggleManyAdDisabled(axios,
            action.payload.ids,
            action.payload.flag,
            state$.value.auth.token ?? "")
        )
    )
}

export function toggleAdDisabledEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isToggleAdDisabledAction),
        flatMap((action) => _toggleAdDisabled(axios,
            action.payload.id,
            action.payload.flag,
            state$.value.auth.token ?? "")
        )
    )
}

export function fetchAdSizesEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchAdSizesAction),
        flatMap(() => _fetchAdSizes(axios, state$.value.auth.token ?? ""))
    )
}

export function fetchAdConfigEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchAdConfigAction),
        flatMap(() => _fetchAdConfig(axios, state$.value.auth.token ?? ""))
    )
}

export function fetchAdEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchAdAction),
        flatMap((action) => _fetch(axios,
            action.payload,
            state$.value.auth.token ?? "")
        )
    )
}

export function saveAdEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isSaveAdAction),
        flatMap(() => {
                const currentUploadedAdImageId = state$.value.ads.currentUploadedImage?.id
                const currentAdImageId = state$.value.ads.form.currentAd.id
                const adImageId = currentUploadedAdImageId ? currentUploadedAdImageId : currentAdImageId

                return _saveAd(axios,
                    state$.value.ads.form.currentAd,
                    adImageId,
                    state$.value.auth.token ?? "")
            }
        )
    )
}

export function deleteAdEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isDeleteAdAction),
        flatMap((action) => {
            const token = state$.value.auth.token ?? "";
            return _delete(axios, action.payload, token)
        })
    )
}

import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import React from "react";
import { palette } from "../palette";

const useStyles = makeStyles({
  root: {
    color: palette.borderColor,
    '&$checked': {
      color: palette.primary,
    },
  },
  checked: {},
});

export const KyrRadio = (props: RadioProps) => {
  const classes = useStyles();
  return <Radio classes={{ root: classes.root, checked: classes.checked }} {...props} />;
}

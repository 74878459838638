import {createSelector} from "reselect";
import {State} from "../reducers";
import {Periodical} from "../../types/periodical";
import {ADMIN, EDITOR, MANAGER, PRODUCER} from "../../utils/roles";

export const currentPeriodicalsSelector = createSelector((state: State) => state.periodical, state => state.periodicals as Periodical[]);


const initPeriodicals = (state: State) => state.periodical.periodicals
const roles = (state: State) => state.auth.roles

export const initPeriodicalsLimitedByRoles = createSelector(
    initPeriodicals,
    roles,
    (initPeriodicals, roles) => {
        if (roles.includes(ADMIN) || roles.includes(PRODUCER) || roles.includes(EDITOR) || roles.includes(MANAGER)) {
            return initPeriodicals
        }
        return initPeriodicals.filter(x => roles.some(r => r.endsWith("_" + x.id)))
    }
)

import {AxiosResponse, AxiosStatic} from "axios";
import {EMPTY, merge, Observable, of} from "rxjs";
import {Action, ActionWithPayload} from "../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, concatMap, filter, map, mergeMap} from "rxjs/operators";
import {closeSnackbar, notify, notifyError, notifySuccess, notifyWarning} from "../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import i18n from "i18next";
import {
    ArticleDestinationRequest,
    ArticleDialogForm,
    ArticleOnlineRequest,
    ArticleResponse,
    ArticlesDestinationRequest,
    ArticleType
} from "../../types/article";

import {redirect} from "../actions/router.actions"
import {
    generateNewArticle,
    isCopyArticleAction,
    isFetchAvailableTagsAction,
    isMoveArticleAction,
    isMoveArticlesAction,
    isRedirectToEditArticleAction,
    isSaveArticleAction,
    isSaveArticleSuccessAction,
    isSetArticleDialogModeAction,
    isSetBundleId,
    isSetPeriodicalIdAction as isSetPeriodicalIdActionArticle,
    isSetPublicationIdAction,
    moveArticleError,
    moveArticlesError,
    moveArticlesSuccess,
    moveArticleSuccess,
    redirectToEditArticle as redirectToEditArticleAction,
    saveArticleError,
    saveArticleSuccess,
    setArticleDialogMode,
    setAvailableNodesResponse,
    setAvailablePeriodicalBundlesResponse,
    setAvailablePublicationBundlesResponse,
    setAvailablePublicationsResponse,
    setAvailableTags,
    setPlanNodeId,
    setTitle
} from "../actions/article/article-dialog.actions"

import {
    deleteArticleError,
    deleteArticlesError,
    deleteArticlesSuccess,
    deleteArticleSuccess,
    fetchArticle,
    fetchArticleError,
    fetchArticleSuccess,
    isDeleteArticleAction,
    isDeleteArticlesAction,
    isFetchArticleAction,
    isPushArticlesOnlineAction,
    isToggleArticleCoverAction,
    isToggleArticleSuccessAction,
    isUploadArticleAction,
    pushArticlesOnlineResponseAction,
    toggleArticleError,
    toggleArticleSuccess,
    uploadArticleTerminated
} from "../actions/article/article.actions"

import {reloadSearch} from "../actions/common-search.actions";
import {ArticleSearchResult, Filters, SearchContext} from "../../types/common-search";
import {isFetchPublicationsOfPeriodicalAction} from "../actions/publication.actions";
import {createBundle, fetchBundle, fetchBundleSuccess} from "../actions/bundle.actions"
import {ArticleDialogMode} from "../reducers/article/article-dialog.reducer";
import ImportReportNotification from "../../components/commons/ImportReportNotification";
import React from "react";
import {DateTime} from "luxon";
import {History} from "history";
import {SnackbarKey} from "notistack";

function _fetchArticle(axios: AxiosStatic, id: string, token: string, isBundle: boolean): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/${isBundle ? 'bundle' : 'article'}/${id}`, {headers}))
        .pipe(
            map((response: AxiosResponse<ArticleResponse>) => fetchArticleSuccess(response.data)),
            catchError(err =>
                of(fetchArticleError(),
                    notifyError(+err.response.status, {type: 'ARTICLE'}))
            )
        )
}

function _fetchPlanNodes(axios: AxiosStatic, prefix: "periodical" | "publication", id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/publication_plan/${prefix}/${id}`, {headers}))
        .pipe(
            map((response) => setAvailableNodesResponse({plan: response.data.plan, type: prefix})),
            catchError(_ =>
                of(setAvailableNodesResponse({plan: [], type: prefix})),
            )
        )
}

function _fetchPublicationBundles(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/bundle/publication/${id}`, {headers}))
        .pipe(
            map((response) => setAvailablePublicationBundlesResponse(response.data)),
            catchError(_ =>
                of(setAvailablePublicationBundlesResponse([])),
            )
        )
}

function _fetchPeriodicalBundles(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/bundle/periodical/${id}`, {headers}))
        .pipe(
            map((response) => setAvailablePeriodicalBundlesResponse(response.data)),
            catchError(_ =>
                of(setAvailablePeriodicalBundlesResponse([])),
            )
        )
}

function _fetchPublications(axios: AxiosStatic, periodicalId: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/publication/periodical/${periodicalId}`, {headers})).pipe(
        map((response) => setAvailablePublicationsResponse(response.data)),
        catchError(_ =>
            of(setAvailablePublicationsResponse([]))
        )
    )
}


export function fetchArticleTagsEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isFetchAvailableTagsAction),
        concatMap((action) => {
                return _fetchArticleTags(axios, state$.value.auth.token ?? "")
            }
        )
    )
}

function _fetchArticleTags(axios: AxiosStatic, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/article/tags`, {headers})).pipe(
        map((response) => setAvailableTags(response.data))
    )
}

function _pushArticleOnline(axios: AxiosStatic, history: History, token: string, articleIds: string[], delay?: DateTime, context?: SearchContext, forceOptions?: Filters): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/article/publish-online`, {
        articleIds: articleIds,
        delay: delay?.toISO({includeOffset: false})
    } as ArticleOnlineRequest, {headers})).pipe(
        mergeMap((response) => {
            const actions: ActionWithPayload[] = [];
            if (context) {
                actions.push(reloadSearch({ctx: context, forceOptions: forceOptions}))
            } else {
                if (articleIds.length == 1)
                    if (history.location.pathname.startsWith("/article")) {
                        actions.push(fetchArticle(articleIds[0]))
                    } else if (history.location.pathname.startsWith("/lot")) {
                        actions.push(fetchBundle(articleIds[0]))
                    }

            }
            actions.push(pushArticlesOnlineResponseAction(undefined))

            actions.push(response.status === 202 ?
                notifyWarning("PARTIAL_ERROR", {type: 'ARTICLE'})
                : notify(i18n.t(`workflows.success.ONLINE_ARTICLE_REDUCER`, {count: articleIds.length}), {variant: 'success'}))
            return merge(of(...actions))
        }),
        catchError(err =>
            merge(of(pushArticlesOnlineResponseAction(articleIds), notifyError(((err.response.status === 400 || err.response.status === 403)) ? err.response.data.message : err.response.status, {
                type: 'ARTICLE', periodicalId: err?.response?.data?.periodicalId
            })))
        )
    )
}

function _saveArticle(axios: AxiosStatic, article: ArticleDialogForm, token: string, nextAction?: ActionWithPayload, context?: SearchContext, forceOptions?: Filters): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    if (article.periodicalId === undefined || article.title === undefined || article.type === undefined || article.planNodeId === undefined) {
        return EMPTY
    }

    return fromPromise(axios.post(`/api/article`, article, {headers}))
        .pipe(
            mergeMap(response => {
                    if (context) {
                        return merge(of<Action>(
                            reloadSearch({ctx: context, forceOptions: forceOptions}),
                            saveArticleSuccess({article: response.data, action: nextAction}),
                            setTitle(""),
                            notifySuccess(i18n.t('articles.save_success'))))
                    } else {
                        return merge(of<Action>(
                            saveArticleSuccess({article: response.data, action: nextAction}),
                            setTitle(""),
                            notifySuccess(i18n.t('articles.save_success'))))
                    }
                },
            ),
            catchError(err => merge(
                of(saveArticleError()),
                of(notifyError(+err.response.status, {type: 'ARTICLE'}))
            ))
        );
}

function _deleteArticle(axios: AxiosStatic, articleId: string, isBundle: boolean, token: string, redirectUrl?: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const successAction = [
        reloadSearch({ctx: SearchContext.ARTICLE}),
        deleteArticleSuccess(),
        notifySuccess(i18n.t(isBundle ? 'bundles.delete_success' : 'articles.delete_success'))
    ]

    if (redirectUrl !== undefined) {
        successAction.push(redirect(redirectUrl))
    }
    return fromPromise(axios.delete(`/api/article/${articleId}`, {headers}))
        .pipe(
            mergeMap(_ => merge(
                of<Action>(...successAction)
                ),
            ),
            catchError(err =>
                of(deleteArticleError(),
                    notifyError(err.response.status === 409 ? err.response.data.message : err.response.status, {
                        type: 'ARTICLE', count: err.response.data?.names?.length,
                        names: err.response.data?.names
                    }))
            )
        )
}

function _deleteArticles(axios: AxiosStatic, articleIds: string[], token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.post(`/api/article/delete`, articleIds, {headers}))
        .pipe(
            mergeMap(_ => merge(of(
                reloadSearch({ctx: SearchContext.ARTICLE}),
                deleteArticlesSuccess(),
                notifySuccess(i18n.t('articles.delete_many_success', {count: articleIds.length})))),
            ),
            catchError(err =>
                of(
                    deleteArticlesError(),
                    notifyError(err.response.status === 409 ? err.response.data.message : err.response.status, {
                        type: 'ARTICLE',
                        count: err.response.data.data?.articles?.lenght
                    })))
        )
}

function _copyArticle(axios: AxiosStatic, request: ArticleDestinationRequest, token: string, nextAction?: ActionWithPayload): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.put(`/api/article/${request.id}/copy`, request, {headers}))
        .pipe(
            mergeMap(response => merge(of<Action>(
                reloadSearch({ctx: SearchContext.ARTICLE}),
                (nextAction ? {
                    ...nextAction,
                    payload: nextAction.payload === '%%articleId%%' ? response.data.id : nextAction.payload
                } : () => {
                }) as any,
                notifySuccess(i18n.t('articles.copy_success')))),
            ),
            catchError(err =>
                of(notifyError(err.response.status === 409 ? err.response.data.message : err.response.status, {type: 'ARTICLE'})))
        )
}

function _moveArticle(axios: AxiosStatic, request: ArticleDestinationRequest, token: string, nextAction?: ActionWithPayload): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.put(`/api/article/${request.id}/move`, request, {headers}))
        .pipe(
            mergeMap(response => merge(of<Action>(
                reloadSearch({ctx: SearchContext.ARTICLE}),
                (nextAction ? {
                    ...nextAction,
                    payload: nextAction.payload === '%%articleId%%' ? response.data.id : nextAction.payload
                } : () => {
                }) as any,
                moveArticleSuccess(),
                notifySuccess(i18n.t(request.isBundle ? 'bundles.move_success' : 'articles.move_success')))),
            ),
            catchError(err =>
                of(
                    moveArticleError(),
                    notifyError(err.response.status === 409 ? err.response.data.message : err.response.status,
                        {type: request.isBundle ? 'BUNDLE' : 'ARTICLE'})
                ))
        )
}

function _moveArticles(axios: AxiosStatic, request: ArticlesDestinationRequest, token: string, nextAction?: ActionWithPayload): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.post(`/api/article/move`, request, {headers}))
        .pipe(
            mergeMap(_ => merge(of<Action>(
                reloadSearch({ctx: SearchContext.ARTICLE}),
                (nextAction ? nextAction : () => {
                }) as any,
                moveArticlesSuccess(),
                notifySuccess(i18n.t('articles.move_multiple_success', {count: request.ids.length})))),
            ),
            catchError(err =>
                of(
                    moveArticlesError(),
                    notifyError(err.response.status === 409 ? err.response.data.message : err.response.status, {type: 'ARTICLE'})
                ))
        )
}

export type PartialArticleSearchResultToggleCover = Pick<ArticleSearchResult, "id" | "isBundle">

function _toggleArticleCover(axios: AxiosStatic, article: PartialArticleSearchResultToggleCover, flag: boolean, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const type = article.isBundle ? "bundles" : "articles"
    return fromPromise(axios.put(`/api/article/${article.id}/toggle-cover?flag=${flag}`, {}, {headers}))
        .pipe(
            mergeMap(_ => merge(of<Action>(
                reloadSearch({ctx: SearchContext.ARTICLE}),
                toggleArticleSuccess(flag),
                notifySuccess(i18n.t(flag ? `${type}.set_cover_success` : `${type}.unset_cover_success`)))),
            ),
            catchError(err => of(
                toggleArticleError(),
                notifyError(+err.response.status, {type: 'ARTICLE'}))
            )
        )
}

export function updateBundleOnCoverToggle(action$: Observable<Action>, state$: StateObservable<State>,): Observable<Action> {
    return action$.pipe(
        filter(isToggleArticleSuccessAction),
        concatMap((action) => {
            if (state$.value.bundle.organizeBundle.current) {
                return of(fetchBundleSuccess({
                    ...state$.value.bundle.organizeBundle.current!!,
                    isFrontCover: action.payload
                }))
            }
            return EMPTY
        })
    )
}

function _uploadArticle(axios: AxiosStatic, {
    files,
    notificationKey
}: { files: FileList, notificationKey: SnackbarKey }, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    const data = new FormData()
    data.append("file", files[0])

    return fromPromise(axios.post(`/api/article/import`, data, {headers}))
        .pipe(
            mergeMap((response: any) => merge(of<Action>(
                notify(
                    "",
                    {
                        persist: true,
                        content: (key) => (
                            <ImportReportNotification id={key} importResult={response.data}/>
                        )
                    }
                ),
                reloadSearch({ctx: SearchContext.ARTICLE}),
                redirectToEditArticleAction(response.data.finalId),
                closeSnackbar(notificationKey),
                uploadArticleTerminated(true)
            ))),
            catchError(err => {
                    return of(
                        err.response.data.hasOwnProperty("result") ?
                            notify("",
                                {
                                    persist: true,
                                    content: (key) => (
                                        <ImportReportNotification id={key} importResult={err.response.data}/>
                                    )
                                }, true) : notifyError(+err.response.status, {}),
                        closeSnackbar(notificationKey),
                        uploadArticleTerminated(false)
                    )
                }
            )
        )
}

export function saveArticleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isSaveArticleAction),
        concatMap((action) => {
                const currentArticle = state$.value.article.dialog.articleForm

                if (currentArticle === undefined || !currentArticle.periodicalId
                    || (currentArticle.type !== ArticleType.BRIEF && !currentArticle.title)
                    || !currentArticle.planNodeId || !currentArticle.type) {
                    return EMPTY
                }

                if (currentArticle.isBundle) {
                    return of(createBundle({
                        action: action.payload.action,
                        context: action.payload.context,
                        forceOptions: action.payload.forceOptions
                    }))
                } else {
                    return _saveArticle(axios,
                        currentArticle!,
                        state$.value.auth.token ?? "", action.payload.action, action.payload.context, action.payload.forceOptions)
                }
            }
        )
    )
}

export function pushArticlesOnline(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, history} = dependencies;

    return action$.pipe(
        filter(isPushArticlesOnlineAction),
        mergeMap((action) => {
            const {ids, delay, context, forceOptions} = action.payload
            return _pushArticleOnline(axios, history, state$.value.auth.token ?? "", ids, delay, context, forceOptions)
        })
    )
}


export function actionAfterSaveArticle(action$: Observable<Action>): Observable<Action> {
    return action$.pipe(
        filter(isSaveArticleSuccessAction),
        concatMap((action) => {
            if (action.payload.action) {
                if (action.payload.action.payload === '%%articleId%%') {
                    action.payload.action.payload = action.payload.article.id
                }
                return of(action.payload.action as Action)
            }
            return EMPTY
        })
    )
}

export function redirectToEditArticle(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isRedirectToEditArticleAction),
    ).subscribe((action) => {
            if (state$.value.createArticle && state$.value.createArticle.articleForm && state$.value.createArticle.articleForm.isBundle) {
                history.push(`/${i18n.t('routes.bundle.root')}/${action.payload}/${i18n.t('routes.article.edit')}`)
            } else {
                history.push(`/${i18n.t('routes.article.root')}/${action.payload}/${i18n.t('routes.article.edit')}`)
            }
        }
    )

    return action$.pipe(
        filter(isRedirectToEditArticleAction),
        map(_ =>
            setArticleDialogMode({
                mode: ArticleDialogMode.CLOSE
            })
        )
    )
}

const periodicalFilter = (action,): action is ActionWithPayload => isSetPeriodicalIdActionArticle(action)
    || isFetchPublicationsOfPeriodicalAction(action)

const periodicalDefaultFilter = (action,): action is ActionWithPayload => isSetArticleDialogModeAction(action) && action.payload.mode !== ArticleDialogMode.CLOSE
    && action.payload?.default !== undefined && action.payload?.default.periodicalId !== undefined

export function loadDatasOnDefaultPeriodicalSelection(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(periodicalDefaultFilter),
        concatMap((action) =>
            merge(
                _fetchPublications(axios, action.payload.default.periodicalId, state$.value.auth.token ?? ""),
                _fetchPlanNodes(axios, 'periodical', action.payload.default.periodicalId, state$.value.auth.token ?? ""),
                _fetchPeriodicalBundles(axios, action.payload.default.periodicalId, state$.value.auth.token ?? "")
            )
        )
    )
}

export function loadPublicationsOnPeriodicalSelection(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(periodicalFilter),
        concatMap((action) =>
            _fetchPublications(axios, action.payload, state$.value.auth.token ?? "")
        )
    )
}

export function loadPlansOnPeriodicalSelection(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(periodicalFilter),
        concatMap((action) =>
            _fetchPlanNodes(axios, 'periodical', action.payload, state$.value.auth.token ?? "")
        )
    )
}

export function loadBundlesOnPeriodicalSelection(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(periodicalFilter),
        concatMap((action) => _fetchPeriodicalBundles(axios, action.payload, state$.value.auth.token ?? "")
        )
    )
}

const loadContainerOnBundleChangeFilter = (action) => isSetBundleId(action) || (
    isSetArticleDialogModeAction(action) && action.payload.mode === ArticleDialogMode.CREATE && action.payload?.default?.bundleId !== undefined
)

export function loadContainerOnBundleChange(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(loadContainerOnBundleChangeFilter),
        filter(({payload}) => payload !== undefined),
        filter(({payload}) => (payload?.default?.bundleId !== undefined && payload?.default?.bundleId !== null) || typeof payload === "string"),
        concatMap((action) => {
            const headers = {
                'Authorization': `Bearer ${state$.value.auth.token ?? ""}`
            };

            return fromPromise(axios.get(`/api/bundle/${action.payload?.default?.bundleId ?? action.payload}`, {headers}))
                .pipe(
                    mergeMap(response => {
                        return merge(of<Action>(
                            setAvailableNodesResponse({
                                plan: [{
                                    id: response.data.planNode?.id || '',
                                    name: response.data.planNode?.name || '',
                                    type: "CONTAINER",
                                    sub: []
                                }], type: "bundle"
                            }),
                            setPlanNodeId(response.data.planNode?.id || '')
                        ))
                    })
                )
        })
    )
}

export function loadBundlesWhenPrefilled(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSetArticleDialogModeAction),
        filter(({payload}) => payload.mode !== ArticleDialogMode.CLOSE
            && payload.default?.publicationId !== undefined
            && payload.default?.publicationId !== null),
        concatMap((action: any) => _fetchPublicationBundles(axios, "" + action.payload.default.publicationId, state$.value.auth.token ?? ""))
    )
}

export function loadBundlesOnPublicationSelection(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSetPublicationIdAction),
        concatMap((action) => {
                if (action.payload)
                    return _fetchPublicationBundles(axios, action.payload.toString(), state$.value.auth.token ?? "")
                else
                    return _fetchPeriodicalBundles(axios, state$.value.article.dialog.articleForm!.periodicalId!, state$.value.auth.token!)
            }
        )
    )
}

const loadPlansOnPublicationSelectionFilter = (action) => isSetPublicationIdAction(action) || (
    isSetArticleDialogModeAction(action)
    && action.payload?.default?.publicationId !== undefined
    && action.payload?.default?.publicationId !== null
)

export function loadPlansOnPublicationSelection(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(loadPlansOnPublicationSelectionFilter),
        concatMap((action) => {
                if (action.payload)
                    return _fetchPlanNodes(axios, 'publication', action.payload?.default?.publicationId ?? action.payload, state$.value.auth.token ?? "")
                else
                    return _fetchPlanNodes(axios, 'periodical', state$.value.article.dialog.articleForm!.periodicalId!, state$.value.auth.token!)
            }
        )
    )
}

export function toggleArticleCoverEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isToggleArticleCoverAction),
        concatMap((action) => _toggleArticleCover(axios, action.payload.article, action.payload.flag, state$.value.auth.token ?? ""))
    )
}

export function deleteArticleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isDeleteArticleAction),
        concatMap((action) => _deleteArticle(axios, action.payload.id, action.payload.isBundle, state$.value.auth.token ?? "", action.payload.redirectUrl))
    )
}

export function deleteArticlesEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isDeleteArticlesAction),
        concatMap((action) => _deleteArticles(axios, action.payload, state$.value.auth.token ?? ""))
    )
}

export function copyArticleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isCopyArticleAction),
        filter(() => state$.value.createArticle.articleForm?.id !== undefined),
        concatMap((action) => _copyArticle(
            axios,
            {
                id: state$.value.createArticle.articleForm!.id!,
                bundleId: state$.value.createArticle.articleForm!.bundleId,
                periodicalId: state$.value.createArticle.articleForm!.periodicalId!,
                planNodeId: state$.value.createArticle.articleForm!.planNodeId,
                publicationId: state$.value.createArticle.publications?.find(x => x.id === state$.value.createArticle.articleForm!.publicationId) ?
                    state$.value.createArticle.articleForm!.publicationId : undefined,
                title: state$.value.createArticle.articleForm!.title,
                isBundle: state$.value.createArticle.articleForm!.isBundle,
                tags: state$.value.createArticle.articleForm!.tags
            },
            state$.value.auth.token ?? "",
            action.payload.action)
        )
    )
}

export function moveArticleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isMoveArticleAction),
        filter(() => state$.value.createArticle.articleForm !== undefined),
        filter(() => state$.value.createArticle.articleForm!.id !== undefined),
        concatMap((action) => _moveArticle(
            axios,
            {
                id: state$.value.createArticle.articleForm!.id!,
                bundleId: state$.value.createArticle.articleForm!.bundleId,
                periodicalId: state$.value.createArticle.articleForm!.periodicalId!,
                planNodeId: state$.value.createArticle.articleForm!.planNodeId,
                publicationId: state$.value.createArticle.articleForm!.publicationId,
                title: state$.value.createArticle.articleForm!.title,
                isBundle: state$.value.createArticle.articleForm!.isBundle,
                tags: state$.value.createArticle.articleForm!.tags
            },
            state$.value.auth.token ?? "",
            action.payload.action)
        )
    )
}

export function moveArticlesEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isMoveArticlesAction),
        filter(() => state$.value.createArticle.articleForm !== undefined),
        filter(() => state$.value.search.common[SearchContext.ARTICLE].selectedIds.length > 0),
        concatMap((action) => _moveArticles(
            axios,
            {
                ids: state$.value.search.common[SearchContext.ARTICLE].selectedIds,
                periodicalId: state$.value.createArticle.articleForm!.periodicalId!,
                publicationId: state$.value.createArticle.articleForm!.publicationId,
            },
            state$.value.auth.token ?? "",
            action.payload.action)
        )
    )
}

export function fetchArticleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchArticleAction),
        concatMap((action) => _fetchArticle(axios,
            action.payload,
            state$.value.auth.token ?? "", false)
        )
    )
}


const isCreateArticleForm = (action) => isSetArticleDialogModeAction(action) && action.payload.mode === ArticleDialogMode.CREATE

export function generateNewArticleFormEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isCreateArticleForm),
        concatMap((action) => {
            const fav = sessionStorage.getItem('fav')?.split(',') ?? [];
            const periodicalId = (action.payload?.default?.periodicalId ?? state$.value.createArticle?.articleForm?.periodicalId)
                || (fav.length === 1 ? fav[0] : undefined)
            if (periodicalId) {
                return merge(
                    _fetchPublications(axios, periodicalId, state$.value.auth.token ?? ""),
                    _fetchPlanNodes(axios, 'periodical', periodicalId, state$.value.auth.token ?? ""),
                    _fetchPeriodicalBundles(axios, periodicalId, state$.value.auth.token ?? ""),
                    of(generateNewArticle({
                        periodicalId: periodicalId
                    }))
                )
            }
            return EMPTY
        })
    )
}

export function uploadArticleEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isUploadArticleAction),
        concatMap((action) => _uploadArticle(axios, action.payload, state$.value.auth.token ?? ""))
    )
}

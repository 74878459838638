import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  is2XXStatusCode,
  is4XXStatusCode,
  is5XXStatusCode,
} from "../utils/query";
import { Query, setError, setStatusCode } from "../models/Query";
import {
  notifyError,
  notifySuccess,
} from "../../redux/actions/notification.actions";
import { Dispatch, SetStateAction } from "react";

export type HandleQueryType<T> = {
  query: Query<T>;
  setQuery: Dispatch<SetStateAction<Query<T>>>;
  succesMessage?: string;
  errorMessage: string;
  successCallBack?: (query: Query<T>) => void
};

export const useHandleQuery = <T>({
  errorMessage,
  query,
  setQuery,
  succesMessage,
  successCallBack = () => {},
}: HandleQueryType<T>) => {
  const dispatch = useDispatch();
  const { statusCode } = query;
  const { t } = useTranslation();

  if (is5XXStatusCode(statusCode) || is4XXStatusCode(statusCode)) {
    setQuery(setError(undefined));
    setQuery(setStatusCode(undefined));
    dispatch(notifyError(errorMessage, {}));
  }

  if (is2XXStatusCode(statusCode)) {
    successCallBack(query);
    setQuery(setStatusCode(undefined));
    if (!succesMessage) return;
    dispatch(notifySuccess(t(succesMessage)));
  }
};

import {EMPTY, Observable} from "rxjs";
import {Action} from "../actions/utils.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import {filter} from "rxjs/operators";
import {isPushBackAction, isRedirectAction} from "../actions/router.actions";


export function pushBackRoute(action$: Observable<Action>, state$: StateObservable<State>, {history}: EpicDependencies): Observable<Action> {
    action$.pipe(
        filter(isPushBackAction)
    ).subscribe(() => {
        history.goBack();
    });
    return EMPTY;
}

export function redirectEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history} = dependencies;
    action$.pipe(
        filter(isRedirectAction)
    ).subscribe((action) => {
        history.push({
            pathname: `/${action.payload}`
        })
    });
    return EMPTY
}
import {Action} from "../../../actions/utils.actions";
import {ArticleOnlinePublicationTask} from "../../../../types/article";
import {
    isFetchOnlinePublicationsAction,
    isFetchOnlinePublicationsResponseAction
} from "../../../actions/admin/online/online-publications.actions";

export type AdminOnlinePublicationsState = {
    articles?: ArticleOnlinePublicationTask[]
}

const init: AdminOnlinePublicationsState = {}

export default function AdminPublicationReducer(state = init, action: Action): AdminOnlinePublicationsState {
    if (isFetchOnlinePublicationsAction(action)) {
        return {
            ...state,
            articles: undefined
        }
    } else if (isFetchOnlinePublicationsResponseAction(action)) {
        return {
            ...state,
            articles: action.payload ? action.payload.sort(function (a, b) {
                return new Date(a.executionDate).getTime() - new Date(b.executionDate).getTime()
            }) : undefined
        }
    }
    return state;
}

import React from "react";
import {viewPageStyle} from "../../../styles/view-page.style";
import {useParams} from "react-router-dom";
import MagicIframe from "../../commons/MagicIframe";
import ArticleViewRightPanel from "./ArticleViewRightPanel";
import {useSelector} from "react-redux";
import {currentFetchedArticleSelector} from "../../../redux/selectors/article/article.selectors";
import {Typography} from "@material-ui/core";
import {createHtml} from "../../../utils/common-utils";
import ArticleViewBreadcrumbs from "../commons/ArticleViewBreadcrumbs";

export function ArticleView() {
    const classes = viewPageStyle()
    const {id} = useParams() as { id: string }
    const url = `/api/article/${id}/preview`
    let search = window.location.search
    let params = new URLSearchParams(search)
    let mode = params.get('mode')

    const article = useSelector(currentFetchedArticleSelector)
    const fullscreen = mode === "fullscreen"

    return (
        <div className={classes.viewRoot}
             style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
            <div>
                <Typography variant={"h4"}>
                    <span dangerouslySetInnerHTML={createHtml(article?.title ?? '')}/>
                </Typography>

                {article && <ArticleViewBreadcrumbs article={article}/>}
            </div>

            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{
                    margin: '20px auto',
                    width: fullscreen ? '100%' : '70%'
                }}>
                    <MagicIframe url={url}/>
                </div>

                {!fullscreen && <div style={{
                    width: '25%', margin: '20px auto',
                }}>
                    <ArticleViewRightPanel/>
                </div>}
            </div>
        </div>
    )
}

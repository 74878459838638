import {Action} from "../../../actions/utils.actions";
import {isSaveAdminPublicationPlan, isSetDirty} from "../../../actions/admin/publication/admin-publication.actions";

export type AdminPublicationState = {
    dirty: boolean
}

const init: AdminPublicationState = {
    dirty: false
}

export default function AdminPublicationReducer(state = init, action: Action): AdminPublicationState {
    if (isSaveAdminPublicationPlan(action)) {
        return {
            ...state,
            dirty: false
        }
    } else if (isSetDirty(action)) {
        return {
            ...state,
            dirty: action.payload
        }
    }

    return state;
}

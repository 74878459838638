import React from 'react'
import Button, { ButtonProps } from "@material-ui/core/Button";
import { palette } from '../palette';
import { IconButton, IconButtonProps, alpha } from '@material-ui/core';

const KyrPrimaryButton = (props: ButtonProps) => {
  const { children, style, ...otherProps } = props
  return (
    <Button style={{
      textTransform: 'none',
      color: 'white',
      backgroundColor: props.disabled ? `${palette.primary}55` : palette.primary,
      borderColor: props.disabled ? `${palette.primary}55` : palette.primary,
      border: 'solid',
      borderWidth: 1,
      ...style
    }}
      variant="outlined"
      {...otherProps}
    >
      {children}
    </Button>
  )
}

const KyrSecondaryButton = (props: ButtonProps) => {
  const { children, style, ...otherProps } = props
  return (
    <Button style={{
      textTransform: 'none',
      color: props.disabled ? `${palette.primary}55` : palette.primary,
      backgroundColor: 'white',
      borderColor: props.disabled ? `${palette.primary}55` : palette.primary,
      border: 'solid',
      borderWidth: 1,
      ...style
    }}
      variant="outlined"
      {...otherProps}
    >
      {children}
    </Button>
  )
}

const KyrTextButton = (props: ButtonProps) => {
  const { children, style, ...otherProps } = props
  return (
    <Button style={{
      textTransform: 'none',
      color: props.disabled ? alpha(palette.primary, 0.3)  : palette.primary,
      backgroundColor: 'white',
      fontSize: 15,
      ...style
    }}
      {...otherProps}
    >
      {children}
    </Button>
  )
}

export const KyrButton = (props: ButtonProps & { kyrType: 'primary' | 'secondary' | 'text' }) => {
  const { kyrType, ...otherProps } = props

  if (kyrType === 'primary') return <KyrPrimaryButton {...otherProps} />
  else if (kyrType === 'secondary') return <KyrSecondaryButton {...otherProps} />
  else return <KyrTextButton {...otherProps} />
}

export const KyrIconButton = (props: IconButtonProps) => {
  const {style, ...otherProps} = props
  return (
    <IconButton {...otherProps} style={{ color: palette.primary, ...style }} />
  )
}
import {createSelector} from "reselect";
import {State} from "../reducers";

export const imageFormSelector = createSelector(({image}: State) => image, image => image.form)
export const isCurrentImageDirtySelector = createSelector(imageFormSelector, form => form.isDirty)
export const currentImageSelector = createSelector(imageFormSelector, form => form.currentIllustration)
export const currentImageIdSelector = createSelector(currentImageSelector, image => image?.id)
export const currentImageNameSelector = createSelector(currentImageSelector, image => image?.name)
export const currentImageDescriptionSelector = createSelector(currentImageSelector, image => image?.comments)
export const currentImageFormatSelector = createSelector(currentImageSelector, image => image?.format)
export const currentImageColorSelector = createSelector(currentImageSelector, image => image?.color)
export const currentImageQualitySelector = createSelector(currentImageSelector, image => image?.quality)
export const currentImageCreditsSelector = createSelector(currentImageSelector, image => image?.credits)
export const currentImageSourcesSelector = createSelector(currentImageSelector, image => image?.sources)
export const currentImageTagsSelector = createSelector(currentImageSelector, image => image?.tags)

export const currentUploadedImageSelector = createSelector(({image}: State) => image, image => image.currentUploadedImage)
export const uploadingImage = createSelector(({image}: State) => image, image => image.uploading)
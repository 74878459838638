import React, {PropsWithChildren, useCallback} from "react";
import {useSelector} from "react-redux";
import {State} from "../redux/reducers";

type Props = {
    otherwise?: JSX.Element[] | JSX.Element,
    periodicalRestriction?: string,
    requiredRoles?: string[]
}

export const isAuth = (roles: string[], requiredRoles?: string[], periodicalRestriction?: string): boolean => {
    if(requiredRoles?.includes("KYRIELLE") && roles.includes("KYRIELLE")) return true
    
    if (roles.includes("ADMIN") && (!requiredRoles?.includes("KYRIELLE") || requiredRoles?.includes("ADMIN"))) {
        return true
    }

    if (periodicalRestriction) {
        return !requiredRoles || requiredRoles.some(r => roles.includes(r + "_" + periodicalRestriction) || roles.includes(r))
    } else {
        return !requiredRoles || requiredRoles.some(gr => roles.some(r => r.startsWith("ADMIN_") ? r === gr : r.startsWith(gr)))
    }
}

const Auth = ({children, requiredRoles, otherwise, periodicalRestriction}: PropsWithChildren<Props>) => {
    const [authenticated, roles]: [boolean, string[] | undefined] = useSelector(({auth}: State) => [auth?.authenticated && auth?.user !== undefined, auth?.roles]);
    const condition = useCallback((): boolean => {
        if (!authenticated) {
            return false
        }
        return isAuth(roles, requiredRoles, periodicalRestriction)
    }, [authenticated, periodicalRestriction, requiredRoles, roles])

    return condition() ? (<>{children}</>) : (<>{otherwise}</>);
}

export default Auth;

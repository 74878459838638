import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {deleteIllustration, fetchImage, toggleIllustrationDisabled} from "../../../redux/actions/image.actions";
import LexImage from "../../commons/LexImage";
import {State} from "../../../redux/reducers";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Auth from "../../Auth";
import Hamburtton from "../../commons/Hamburtton";
import Loader from "../../commons/Loader";
import ImageBreadcrumbs from "../commons/ImageBreadcrumbs";
import TitleTypo from "../../commons/TitleTypo";
import {ImageQuality} from "../../../types/image";
import ImageRightPanel from "../edit/ImageRightPanel";
import {ADMIN, ADMIN_IMAGES} from "../../../utils/roles";
import {IconButton, Tooltip} from "@material-ui/core";
import {CopyIcon} from "../../../icon";
import Toggler from "../../commons/Toggler";
import {notify} from "../../../redux/actions/notification.actions";
import {downloadIllustrationImages} from "../../../utils/common-utils";
import {viewPageStyle} from "../../../styles/view-page.style";
import {commonStyle} from "../../../styles/common.style";

export function ImageView() {
    const {id} = useParams() as { id: string }
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const commonCss = commonStyle()
    const classes = viewPageStyle()

    useEffect(() => {
        dispatch(fetchImage(id))
    }, [dispatch, id])

    const [dialogOpen, openDialog] = useState(false);
    const closeDialog = () => openDialog(false)

    const onImageDeletion = (id: string) => () => {
        dispatch(deleteIllustration({id, doRedirect: true}))
    }

    const image = useSelector((state: State) => state.image.form.currentIllustration)

    const handleCopy = () => {
        navigator.clipboard.writeText(image.name);
        dispatch(notify("Copié", {
            variant: 'success'
        }));
    };

    if (image) {
        return (
            <div className={classes.viewRoot}>
                <ConfirmationDialog
                    title={t('images.delete_confirmation')}
                    description={''}
                    actions={[
                        {label: t('dialogs.cancel'), color: 'default'},
                        {label: t('dialogs.confirm'), action: onImageDeletion(image.id), variant: 'contained'}
                    ]}
                    isOpened={dialogOpen}
                    onClose={closeDialog}
                />

                <Grid container className={classes.header} spacing={2}>
                    <Grid item xs={9}>
                        <Typography variant="h4">
                            {image.id} {image.name}
                            <IconButton onClick={handleCopy}>
                                <Tooltip title={t('misc.copy_to_word') || ""}>
                                    <CopyIcon/>
                                </Tooltip>
                            </IconButton>
                        </Typography>
                        <ImageBreadcrumbs/>
                    </Grid>

                    <Grid item xs={3}>
                        <span>
                            {t("images.disabled")}
                            <Toggler
                                id={image.id}
                                enabled={!image.disabled}
                                requiredRoles={[ADMIN, ADMIN_IMAGES]}
                                action={toggleIllustrationDisabled}
                                style='noMargin'
                                reverse={true}
                            />
                            {t("images.enabled")}
                        </span>

                        <Auth requiredRoles={[ADMIN, ADMIN_IMAGES]}>
                            <Grid container className={classes.delete}>
                                <Hamburtton
                                    buttonAction={{
                                        label: t('images.edit'),
                                        route: `/${t('routes.image.root')}/${image.id}/${t('routes.image.edit')}`
                                    }}
                                    actions={[
                                        {label: t("misc.export"), action: () => downloadIllustrationImages([image.id])},
                                        {label: t("misc.delete"), action: () => openDialog(true), display: image.enable}
                                    ]}
                                />
                            </Grid>
                        </Auth>
                    </Grid>
                </Grid>

                <Grid container spacing={8}>
                    <Grid item xs={9}>

                        <LexImage image={{...image, collection: 'ILLUSTRATION'}} maxHeight={"calc(100vh - 124px)"}
                                  maxWidth={"100%"} disabled={image.disabled} size={"MEDIUM"}/>

                        <Grid container className={classes.formRoot}>
                            {
                                [
                                    ["misc.id", image.id, true],
                                    ["misc.name", image.name, true],
                                    ["images.size", t(`enums.images.format.${image?.format}.main`), true],
                                    ["images.color", t(`enums.images.color.${image?.color}.main`), true],
                                    ["images.tags", image.tags.join(', '), true],
                                    ["images.quality", <span
                                        className={commonCss.badQuality}>{t(`enums.images.quality.${image.quality}.main`)}</span>,
                                        image.quality === ImageQuality.BAD],
                                    ["images.credits", image.credits, true],
                                    ["images.sources", image.sources, true],
                                    ["images.description", image.comments, image.comments !== ''],
                                ].map(v => <>
                                        {v[2] && <>
                                            <Grid item xs={2}>
                                                <TitleTypo label={t("" + v[0])}/>
                                            </Grid>
                                            <Grid item xs={10} component={"pre"}>
                                                {v[1]}
                                            </Grid>
                                        </>}
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={3}>
                        <ImageRightPanel image={image}/>
                    </Grid>
                </Grid>
            </div>)
    } else {
        return (<Loader/>)
    }
}

import {PlanNode} from "./plan-node";
import {ArticleStatus, ArticleTags} from "./article";
import {JouveProcessStatus} from "./workflow";

export type Bundle = {
    id?: string
    title?: string
    bundleId?: string
    planNodeId?: string
    periodicalId: string
    publicationId?: number
    modificationDate?: string
    type: BundleType
}

export enum BundleType {
    ANALYSIS = "ANALYSIS",
    PRACTICAL_CASE = "PRACTICAL_CASE",
    CHRONICLE = "CHRONICLE",
    FOLDER = "FOLDER",
    PANORAMA = "PANORAMA",
    BOARD = "BOARD",
    UNKNOWN = "UNKNOWN"
}

export enum BundlePlanFormMode {
    CREATE = "create",
    DUPLICATE = "duplicate",
    EDIT = "edit"
}

export type CustomBundlePlan = {
    id: string
    title: string
    periodicalId: string
    type: BundleType
    plan: PlanNode[]
}

export type BundleResponse = {
    id: string
    title: string
    type: BundleType
    status: ArticleStatus
    revisionId?: string
    plan: PlanNode[]
    periodical: string // periodicalId
    publication: number // publicationId
    planNode?: PlanNode
    creationDate?: string
    creationUser?: string
    modificationDate?: string
    modificationUser?: string
    lastRequestPlacardProcess?: string
    lastRequestPlacardProcessStatus?: JouveProcessStatus
    lastAvailablePlacardProcessStatus?: JouveProcessStatus
    lastAvailablePlacardProcess?: string
    publicationOnlineDate?: string
    bundlePlanVersionId: string
    isValid: boolean
    isImportant: boolean
    isFrontCover: boolean
    attached: "NONE" | "CDF" | "CDF_FIXE"
    charVolume: number
    nextPublicationOnlineUsername?: string
    nextPublicationOnlineDate?: string
}

export type SmallBundleResponse = {
    id: string
    title: string
}

export type BundlePlanResponse = {
    bundlePlanId: string
    bundlePlanVersionId: string
    periodicalId: string
    plan: PlanNode[]
    creationDate: string
    creationUserId: string
    enable: boolean
    type: BundleType
    name: string
}

export type BundlePlanSearchContext = {
    periodicalId: string[]
    page?: number
    size?: number
}

export type BundlePlanSearchItem = {
    id: string
    periodicalId: string
    type: BundleType
    label: string
}

export type BundlePlanSearchResponse = {
    page: number
    resultCount: number
    result: BundlePlanSearchItem[]
}

export type InitBundleRequest = {
    title: string
    bundlePlanVersionId: string
    planNodeId: string
    periodicalId: string
    publicationId?: number
    type: BundleType,
    tags?: ArticleTags
}

import {Action} from "../../actions/utils.actions";
import {PageSize} from "../../../types/common-search";
import {
    isSetAttachedOnlyForceOptionsAction,
    isSetQueryForceOptionsAction,
    isSetSizePageForceOptionsAction
} from "../../actions/search/force-search-options.actions";

export type ForceOptions = { attachedOnly: boolean, query?: string, pageSize: PageSize }

const init: ForceOptions = {
    attachedOnly: true,
    pageSize: 20
}

const reducer = (state = init, action: Action): ForceOptions => {
    if (isSetAttachedOnlyForceOptionsAction(action)) {
        return {
            ...state,
            attachedOnly: action.payload
        };
    } else if (isSetQueryForceOptionsAction(action)) {
        return {
            ...state,
            query: action.payload
        };
    } else if (isSetSizePageForceOptionsAction(action)) {
        return {
            ...state,
            pageSize: action.payload || 20
        };
    } else {
        return state;
    }
}

export default reducer

import {Action} from "../../actions/utils.actions";
import {
    isAutoCompleteAuthorsAction,
    isAutoCompleteAuthorsSuccesAction,
    isAutoCompleteErrorAuthorsAction
} from "../../actions/search/autcocomplete-authors.actions";
import {autoCompleteStatus} from "../../../types/common-search";

export type KeyValue = {
    key: string,
    value: string
}

export type AutoCompleteAuthorsState = {
    results: KeyValue[],
    status: autoCompleteStatus
}

const initAutoComplete: AutoCompleteAuthorsState = {
    results: [],
    status: "NONE"
}

const reducer = (state = initAutoComplete, action: Action): AutoCompleteAuthorsState => {
    if (isAutoCompleteAuthorsAction(action)) {
        return {
            status: 'PENDING',
            results: []
        }
    } else if (isAutoCompleteErrorAuthorsAction(action)) {
        return {
            status: 'ERROR',
            results: []
        }
    } else if (isAutoCompleteAuthorsSuccesAction(action)) {
        return {
            status: 'LOADED',
            results: action.payload
        }
    } else {
        return state;
    }
}

export default reducer

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAdminBundlePlans,
    setAdminBundlePlanFormMode,
    setAdminBundlePlansSearchContext
} from "../../../../redux/actions/admin/bundle/admin-bundle.actions";
import Container from "@material-ui/core/Container";
import {commonStyle} from "../../../../styles/common.style";
import {ADMIN} from "../../../../utils/roles";
import PageHeader from "../../../commons/PageHeader";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Auth from "../../../Auth";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import BundlePlanListFilter from "./BundlePlanListFilters";
import BundlePlanListTable from "./BundlePlanListTable";
import TablePagination from "../../../authors/list/TablePagination";
import {
    currentAdminBundlePlansSearchContextSelector,
    currentAdminBundlePlansSearchResultSelector
} from "../../../../redux/selectors/admin/bundle/admin-bundle.selectors";
import {BundlePlanFormMode} from "../../../../types/bundle";

const BundlePlanListRoot = () => {
    const classes = commonStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const searchContext = useSelector(currentAdminBundlePlansSearchContextSelector);
    const searchResult = useSelector(currentAdminBundlePlansSearchResultSelector);

    useEffect(() => {
            dispatch(fetchAdminBundlePlans(searchContext));
        },
        [searchContext]);

    const updateChangePage = (value: number) => {
        dispatch(setAdminBundlePlansSearchContext({...searchContext, page: value}));
    }

    const updateChangeRowPerPage = (value: number) => {
        dispatch(setAdminBundlePlansSearchContext({...searchContext, size: value}));
    }

    const createBundlePlan = () => {
        dispatch(setAdminBundlePlanFormMode(BundlePlanFormMode.CREATE));
        history.push(`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}/${t('routes.admin.bundle.create')}`);
    }

    return (
        <Container maxWidth={"xl"} className={classes.root}>
            <PageHeader
                label={t('admin.bundle.list.title')}
                requiredRoles={[ADMIN]}
                rightPanelChildren={
                    <Grid container spacing={1}>
                        <Auth requiredRoles={[ADMIN]}>
                            <Grid item>
                                <Button variant={"outlined"} color={"primary"}
                                        onClick={createBundlePlan}>
                                    {t('admin.bundle.list.add')}
                                </Button>
                            </Grid>
                        </Auth>
                    </Grid>
                }
            />

            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <BundlePlanListFilter
                        bundlePlanSearchContext={searchContext}
                    />
                </Grid>
                <Grid item xs={9}>
                    <BundlePlanListTable/>
                    <TablePagination page={searchContext.page || 0}
                                     size={searchContext.size || 0}
                                     totalCount={searchResult?.resultCount || 0}
                                     updateChangePage={updateChangePage}
                                     updateChangeRowPerPage={updateChangeRowPerPage}/>
                </Grid>
            </Grid>
        </Container>
    );
}

export default BundlePlanListRoot

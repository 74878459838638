export enum ArticleActionName {
    BAP_ARTICLE = "BAP_ARTICLE",
    GENERATE_ARTICLE_PLACARD = "GENERATE_ARTICLE_PLACARD",
    UPDATE_ARTICLE = "UPDATE_ARTICLE",
    UNLOCK_ARTICLE_PLACARD = "UNLOCK_ARTICLE_PLACARD"
}

export enum JouveProcessStatus {
    GEN_REQUEST_PACKAGE = "GEN_REQUEST_PACKAGE",
    PENDING = "PENDING",
    GEN_RESULT_PACKAGE = "GEN_RESULT_PACKAGE",
    FINISHED = "FINISHED",
    ERROR = "ERROR",
    CANCEL = "CANCEL",
    OUT_OF_DATE = "OUT_OF_DATE"
}

export enum PublicationActionName {
    UNLOCK_PUBLICATION_PLACARD = "UNLOCK_PUBLICATION_PLACARD",
    GENERATE_PUBLICATION_PLACARD = "GENERATE_PUBLICATION_PLACARD",
    GENERATE_PUBLICATION_PRINTER_PDF = "GENERATE_PUBLICATION_PRINTER_PDF",
    INVALIDATE_PUBLICATION_PRINTER_PDF = "INVALIDATE_PUBLICATION_PRINTER_PDF",
    BAP_ARTICLES_OF_PUBLICATION = "BAP_ARTICLES_OF_PUBLICATION",
    BAT_PUBLICATION = "BAT_PUBLICATION",
    CLOSE_PUBLICATION = "CLOSE_PUBLICATION"
}

import {AuthorImage} from "../types/image";
import {TFunction} from "i18next";
import {AdSearchResult, CommonImageResult, ImageSearchFilters} from "../types/common-search";
import {baseParams} from "./common-utils";
import {buildQueryParams, ParameterType, readQueryParams} from "./query-param.utils";

//https://docs.google.com/document/d/1hRvbzVVWlpCSh3adNZg9OvA06sRQwvDo91B-JQbzSZ4/edit#
//Le bouton de suppression n’est actif que si la publicité n’est utilisée dans aucun lot ni aucun chemin de fer. Sinon, le bouton est désactivé.
// /!\ il semblerait qu'il y a un clash entre les specs pub et images sur la désactivation de la supression
const _shouldDeactivateDelete = (image: CommonImageResult) => image.publicationPeriodicals.length > 0
export const shouldDeactivateDelete = (image: CommonImageResult | CommonImageResult[]) =>
    Array.isArray(image) ? image.some(_shouldDeactivateDelete) : _shouldDeactivateDelete(image)

//https://docs.google.com/document/d/1hRvbzVVWlpCSh3adNZg9OvA06sRQwvDo91B-JQbzSZ4/edit#
//si la publicité est active ET qu’elle n’est utilisée dans aucun lot ni aucun chemin de fer, un bouton icône “Désactiver”, libellé “Désactiver”.
//si la publicité est désactivée, un bouton icône “Activer”, libellé “Activer”.
const _shouldDeactivateDisable = (ad: AdSearchResult) => ad.publicationPeriodicals.length > 0
export const shouldDeactivateDisable = (ad: AdSearchResult | AdSearchResult[]) =>
    Array.isArray(ad) ? ad.some(_shouldDeactivateDisable) : _shouldDeactivateDisable(ad)

export const generateImageAuthorName = (authorId: string | undefined, image: AuthorImage, t: TFunction) => {
    const {id, format, color, framing} = image;
    const tFormat = t(`enums.images.format.${format}.slug`);
    const tColor = t(`enums.images.color.${color}.slug`);
    const tFraming = t(`enums.images.framing.${framing}.slug`);
    return authorId
        ? `${authorId}_${tFormat}_${tColor}_${tFraming}_${id}.${image.meta.contentType.toLowerCase()}`
        : undefined;
}

const params = {
    ...baseParams,
    query: {
        type: ParameterType.STRING
    },
    grid: {
        type: ParameterType.BOOLEAN,
        default: false
    },
    publicationPeriodicalFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    periodicalFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    priorityFilter: {
        type: ParameterType.BOOLEAN_ARRAY
    },
    formatFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    collectionFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    imageFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    mimeTypeFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    characteristicFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    modificationDateFilter: {
        type: ParameterType.DATE_RANGE
    },
    enableFilter: {
        type: ParameterType.BOOLEAN_ARRAY
    },
    endDateFilter: {
        type: ParameterType.DATE_RANGE
    }
}

export const readImageQueryParams = (search: string, options?: ImageSearchFilters): ImageSearchFilters => {
    return {
        ...readQueryParams(params, search),
        ...options
    };
}

export const buildImageQueryParams = (filters: ImageSearchFilters): string => {
    return buildQueryParams(params, filters);
}

export const getCollectionName = (specificName: string): string[] => {
    switch (specificName) {
        case 'ad':
            return ["ADVERTISEMENT"]
        case 'image':
            return ["ILLUSTRATION", "AUTHOR"]
        default:
            return []
    }
}

// converti une valeur en kilo-octets vers mega-octets si la taille est >1 Mo
export const koToMo = (fileSize: number) => fileSize > 1000 * 1000 ? `${(fileSize / (1000 * 1000)).toFixed(1)} mo`
    : `${(fileSize / 1000).toFixed(1)} ko`

const _getSortFirst = (queryParams: ImageSearchFilters) => (queryParams.sort?.length ?? 0) > 0 ? (queryParams.sort?.[0] ?? null) : null
export const getSortFirstAttr = (queryParams: ImageSearchFilters) => _getSortFirst(queryParams)?.attr ?? 'ID'
export const getSortFirstOrder = (queryParams: ImageSearchFilters) => _getSortFirst(queryParams)?.order ?? 'ASC'

import { Button, Menu, MenuItem, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import React, { CSSProperties, Children, FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import { logout } from "../../redux/actions/auth.actions";
import { useQuery } from "../hooks/useFetchDataOnMount";
import { useHandleQuery } from "../hooks/useHandleQuery";
import kyrielleLogo from '../logo/kyrielle.png';
import ouvrageLogo from '../logo/ouvrages.png';
import { palette } from "../palette";
import { ChildrenProps } from "../types";
import { download } from "../utils/download";
import { Column, Row } from "./FlexBox";

const OuvrageItem: FC<{ tooltipTitle: string, handler: () => void, disabled: boolean }> = ({ tooltipTitle, handler, disabled, children }) => {

  return (
    <Tooltip title={tooltipTitle}>
      <Button
        style={{ textTransform: 'none', fontWeight: 'unset', width: '100%', padding: 0 }}
        onClick={handler}
        disabled={disabled}
      >
        <Row grow={1} colGap={20} justifyContent="space-between">
          {children}
        </Row>
      </Button>
    </Tooltip>
  )
}

const LEXPRESS_URL = process.env.REACT_APP_LEXPRESS_URL
const OXYGEN_URL = process.env.REACT_APP_OXYGEN_URL
const LINK_FONT_WEIGHT = 500

const KyrTopBarLayout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)

  const paddingX = 24
  const containerStyle: CSSProperties = {
    backgroundColor: palette.headerColor,
    maxHeight: '64px',
    minHeight:'64px',
    paddingRight: `${paddingX}px`,
    paddingLeft: `${paddingX}px`
  }

  const kyrielle = childrenList[0]
  const ouvrage = childrenList[1]
  const lexpressLink = childrenList[2]
  const oxygenLink = childrenList[3]
  const logout = childrenList[4]

  return (
    <Row alignItems="center" style={containerStyle}>
      <Row grow={1} height="100%" alignItems="center" colGap={20}> {/* Partie image */}
        {kyrielle}
        {ouvrage}
      </Row>
      <Row grow={1} colGap={20} justifyContent="flex-end" alignItems="center"> {/* Partie liens */}
        {lexpressLink}
        {oxygenLink}
        {logout}
      </Row>
    </Row>
  )
}

const KyrLink = ({ type }: { type: 'lexpress' | 'oxygen' }) => {
  if (type === 'lexpress') return (
    <Link style={{ color: palette.lexpressLink, fontWeight: LINK_FONT_WEIGHT }} href={LEXPRESS_URL}>Lex-Press</Link>
  )

  return (
    <Link style={{ color: palette.oxygenLink, fontWeight: LINK_FONT_WEIGHT }} href={OXYGEN_URL}>Oxygen</Link>
  )
}

const LexPressLink = () => (
  <KyrLink type="lexpress" />
)

const OxygenLink = () => (
  <KyrLink type="oxygen" />
)

const Logout = () => {
  const dispatch = useDispatch()

  const handleClick = () => dispatch(logout())

  return (
    <IconButton style={{ color: palette.primary }} onClick={handleClick}>
      <ExitToAppIcon />
    </IconButton>
  )
}

const ExportOuvrages = () => {
  const { t } = useTranslation()
  const [query, exportResult, setExportResult] = useQuery<any>({})

  useHandleQuery({
    errorMessage: 'KYRIELLE_EXPORT',
    query: exportResult,
    setQuery: setExportResult,
    successCallBack: (arg) => download(
      arg.data,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', t('kyrielle.export.fileName')
    )
  })

  const handleDownLoad = async () => {
    await query({
      url: '/api/kyrielle/document/export',
      method: 'get',
      responseType: 'arraybuffer',
    })
  }

  return (
    <OuvrageItem tooltipTitle={t('kyrielle.export.tooltip') ?? ''} handler={handleDownLoad} disabled={exportResult.isLoading}>
      <Typography>Télécharger le référentiel</Typography>
      <GetAppIcon style={{ color: '#1AAE9F' }}  />
    </OuvrageItem>
  )
}


const ImportOuvrages = () => {
  const { t } = useTranslation()
  const [query, importResult, setImportResult] = useQuery<undefined>({})
  const fileInputRef = useRef<HTMLInputElement>(null);


  const handleSelectFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  useHandleQuery({
    succesMessage: 'kyrielle.import.success',
    errorMessage: 'KYRIELLE_IMPORT',
    query: importResult,
    setQuery: setImportResult,
  })

  const handleUpload = async (file: any) => {
    const formData = new FormData()
    formData.append('file', file[0])
    await query({
      url: '/api/kyrielle/document/import',
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  }

  const clearFile = (e: any) => {
    e.target.value = null
  }

  return (
    <>
      <OuvrageItem disabled={importResult.isLoading} handler={handleSelectFile} tooltipTitle={t('kyrielle.export.tooltip') ?? ''}>
        <Typography>Charger le référentiel</Typography>
      <PublishIcon style={{ color: '#D3455B' }} />
      </OuvrageItem>
      <input
        ref={fileInputRef}
        hidden
        type="file"
        onChange={(e) => handleUpload(e.target.files)}
        onClick={(e) => clearFile(e)}
      />
    </>
  )
}

const OuvrageMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const imageSize = "20.5px"
  return (
    <>
      <Button style={{ backgroundColor: palette.headerColor, padding: 0 }}>
        <Column center onClick={handleClick} rowGap={3}>
          <img src={ouvrageLogo} height={imageSize} width={imageSize} />
          <Typography style={{color: 'white', fontSize: 9}} variant="button">Ouvrages</Typography>
        </Column>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} >
          <ExportOuvrages />
        </MenuItem>
        <MenuItem onClick={handleClose} >
          <ImportOuvrages />
        </MenuItem>
      </Menu>
    </>

  )
}

export const KyrTopBar = () => {
  return (
    <KyrTopBarLayout>
      <RouterLink to="/kyrielle/organizeTree">
        <img src={kyrielleLogo} style={{maxWidth: '120px'}}/>
      </RouterLink>
      <OuvrageMenu />
      <LexPressLink />
      <OxygenLink />
      <Logout />
    </KyrTopBarLayout>
  )
}
import {Grid, IconButton} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {PublicationSearchResult} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {ShowBluePdfIcon} from "../../commons/icon/icons";
import {JouveProcessStatus} from "../../../types/workflow";
import Auth from "../../Auth";
import {ADMIN, EDITOR, MANAGER, PRODUCER, VIEWER} from "../../../utils/roles";
import {State} from "../../../redux/reducers";
import {openPdfPrinterInNewTab} from "../../../utils/workflows-utils";


type Props = {
    publication: PublicationSearchResult
}

function PdfPrinterStatusAndDownload({publication}: Props) {
    const {t} = useTranslation()
    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')
    const dispatch = useDispatch()

    if (publication.lastPrinterPdfProcessStatus === JouveProcessStatus.FINISHED) {
        return (
            <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR, VIEWER]}><Grid item><Tooltip
                title={t('workflows.see_printer_pdf') ?? ""}>
                <IconButton size={"small"} style={{paddingTop: 0}}
                            onClick={() => openPdfPrinterInNewTab(publication.id, dispatch, token)}><ShowBluePdfIcon/></IconButton>
            </Tooltip></Grid></Auth>
        )
    } else {
        return (<></>)
    }

}

export default PdfPrinterStatusAndDownload;
import {createActionWithoutPayload, createActionWithPayload} from "../../utils.actions";
import {
    BundlePlanFormMode,
    BundlePlanResponse,
    BundlePlanSearchContext,
    BundlePlanSearchResponse,
    BundleType,
} from "../../../../types/bundle";
import {PlanNode} from "../../../../types/plan-node";

// LIST
export const [fetchAdminBundlePlans, isFetchAdminBundlePlans] = createActionWithPayload<BundlePlanSearchContext>('ADMIN_FETCH_BUNDLE_PLANS')
export const [fetchAdminBundlePlansSuccess, isFetchAdminBundlePlansSuccess] = createActionWithPayload<BundlePlanSearchResponse>('ADMIN_FETCH_BUNDLE_PLANS_SUCCESS')
export const [fetchAdminBundlePlansError, isFetchAdminBundlePlansError] = createActionWithoutPayload('ADMIN_FETCH_BUNDLE_PLANS_ERROR')

// SEARCH CONTEXT
export const [setAdminBundlePlansSearchContext, isSetAdminBundlePlansSearchContext] = createActionWithPayload<BundlePlanSearchContext>('ADMIN_SET_BUNDLE_PLANS_SEARCH_CONTEXT')

// EDIT
export const [fetchAdminBundlePlan, isFetchAdminBundlePlan] = createActionWithPayload<string>('ADMIN_FETCH_BUNDLE_PLAN')
export const [fetchAdminBundlePlanSuccess, isFetchAdminBundlePlanSuccess] = createActionWithPayload<BundlePlanResponse>('ADMIN_FETCH_BUNDLE_PLAN_SUCCESS')
export const [fetchAdminBundlePlanError, isFetchAdminBundlePlanError] = createActionWithoutPayload('ADMIN_FETCH_BUNDLE_PLAN_ERROR')

export const [resetAdminBundlePlan, isResetAdminBundlePlan] = createActionWithoutPayload('ADMIN_RESET_BUNDLE_PLAN')
export const [setAdminBundlePlanFormMode, isSetAdminBundlePlanFormMode] = createActionWithPayload<BundlePlanFormMode>('ADMIN_SET_BUNDLE_PLAN_FORM_MODE')

// FORM
export const [setTitle, isSetTitle] = createActionWithPayload<string>('ADMIN_SET_BUNDLE_PLAN_TITLE')
export const [setType, isSetType] = createActionWithPayload<BundleType>('ADMIN_SET_BUNDLE_PLAN_TYPE')
export const [setPeriodicalId, isSetPeriodicalId] = createActionWithPayload<string>('ADMIN_SET_BUNDLE_PLAN_PERIODICAL_ID')
export const [setPlan, isSetPlan] = createActionWithPayload<PlanNode[]>('ADMIN_SET_BUNDLE_PLAN_PLAN')
export const [setDirty, isSetDirty] = createActionWithPayload<boolean>('ADMIN_SET_BUNDLE_PLAN_DIRTY')

export const [saveAdminBundlePlan, isSaveAdminBundlePlan] = createActionWithPayload<BundlePlanResponse>('ADMIN_SAVE_BUNDLE_PLAN')
export const [saveAdminBundlePlanSuccess, isSaveAdminBundlePlanSuccess] = createActionWithPayload<BundlePlanResponse>('ADMIN_SAVE_BUNDLE_PLAN_SUCCESS')
export const [saveAdminBundlePlanError, isSaveAdminBundlePlanError] = createActionWithoutPayload('ADMIN_SAVE_BUNDLE_PLAN_ERROR')

export const [deleteAdminBundlePlan, isDeleteAdminBundlePlan] = createActionWithPayload<string>('ADMIN_DELETE_BUNDLE_PLAN')
export const [deleteAdminBundlePlanSuccess, isDeleteAdminBundlePlanSuccess] = createActionWithPayload<string>('ADMIN_DELETE_BUNDLE_PLAN_SUCCESS')
export const [deleteAdminBundlePlanError, isDeleteAdminBundlePlanError] = createActionWithoutPayload('ADMIN_DELETE_BUNDLE_PLAN_ERROR')
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import AdsImageGrid from "./list/AdsGrid";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../redux/reducers";
import AdsImageTable from "./list/AdsTable";
import {useTranslation} from "react-i18next";
import AdsFilters from "./list/AdsFilters";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PageHeader from "../commons/PageHeader";
import {search, updateSearchQueryParam} from "../../redux/actions/common-search.actions";
import {getCollectionName, readImageQueryParams} from "../../utils/image.utils";
import {ADMIN, ADMIN_ADS} from "../../utils/roles";
import TablePagination from "../authors/list/TablePagination";
import QuickResults from "../commons/QuickResults";
import {commonStyle} from "../../styles/common.style";
import {SearchContext} from "../../types/common-search";

export default () => {
    const {t} = useTranslation()
    const common = commonStyle();
    const dispatch = useDispatch()

    const searchLocation = useLocation().search;
    const isLocationGrid = searchLocation.includes("&grid") || searchLocation.includes("?grid")
    const filters = readImageQueryParams(searchLocation);
    const query = filters.query;

    const updateChangePage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ADVERTISEMENT,
            field: 'page',
            value
        }));
    }

    const updateChangeRowPerPage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ADVERTISEMENT,
            field: 'size',
            value
        }));
    }

    useEffect(() => {
        const queryFilters = readImageQueryParams(searchLocation);
        const filters = {
            ...queryFilters,
            collectionFilter: getCollectionName("ad")
        }
        dispatch(search({
            ctx: SearchContext.ADVERTISEMENT,
            filters
        }))
    }, [dispatch, searchLocation])

    const totalCount = useSelector(({search}: State) =>
        search.common[SearchContext.ADVERTISEMENT].totalCount)

    return <>
        <Container maxWidth={"xl"} className={common.root}>
            <PageHeader
                label={query ? "" : "Liste des publicités"}
                hamburttonProps={{
                    buttonAction: {
                        label: t('ads.new_ad'),
                        route: `/${t('routes.ad.root')}/${t('routes.ad.create')}`
                    }
                }}
                requiredRoles={[ADMIN, ADMIN_ADS]}
            >
                {query ? <QuickResults currentPage={"ad"} countResult={totalCount}
                                       query={query!!}/> : <></>}
            </PageHeader>

            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <AdsFilters filters={filters}/>
                </Grid>

                <Grid item xs={9}>
                    <Grid item xs={12}>
                        <Grid item>
                            {isLocationGrid ? <AdsImageGrid/> : <AdsImageTable filters={filters}/>}
                            <TablePagination page={filters.page || 0}
                                             size={filters.size || 0}
                                             totalCount={totalCount}
                                             updateChangePage={updateChangePage}
                                             updateChangeRowPerPage={updateChangeRowPerPage}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </>
}

import React, {forwardRef, useCallback, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {SnackbarContent, useSnackbar} from 'notistack';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '344px !important',
        },
    },
    cardSuccess: {
        backgroundColor: 'rgb(67, 160, 71)',
        width: '100%',
    },
    cardWarning: {
        backgroundColor: 'rgb(255, 152, 0)',
        width: '100%',
    },
    cardError: {
        width: '100%',
        backgroundColor: "rgb(211, 47, 47)"
    },
    typography: {
        color: "#fff"
    },
    actionRoot: {
        padding: '8px 8px 8px 16px',
        justifyContent: 'space-between',
    },
    icons: {
        marginLeft: 'auto',
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        padding: 16,
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: 'none',
    },
    item: {
        fontSize: '.8rem'
    }
}));

type RuleValidationResultState = "SUCCESS" | "WARNING" | "ERROR"

type RuleValidationResult = {
    resultState: RuleValidationResultState
    reason: string
    data: string
}

type ImportResult = {
    finalId: string
    result: RuleValidationResult[]
}

const containsWarning = (result: ImportResult) => result.result.some(it => it.resultState == "WARNING")
const containsErrors = (result: ImportResult) => result.result.some(it => it.resultState == "ERROR")

const ImportReportNotification = forwardRef<HTMLDivElement, { id: string | number, importResult: ImportResult }>(
    ({
         id,
         importResult
     }, ref) => {
        const {t} = useTranslation()
        const classes = useStyles();
        const {closeSnackbar} = useSnackbar();

        const [hasError,] = useState(containsErrors(importResult))
        const [hasWarning,] = useState(containsWarning(importResult))

        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);

        return (<>
            <SnackbarContent ref={ref} className={classes.root}>
                <Card className={hasError ? classes.cardError : hasWarning ? classes.cardWarning : classes.cardSuccess}>
                    <CardActions classes={{root: classes.actionRoot}}>
                        <Typography className={classes.typography}>
                            {hasError && t(`article-import.error`)}
                            {!hasError && hasWarning && t(`article-import.warning`)}
                            {!hasError && !hasWarning && t(`article-import.success`)}
                        </Typography>
                        <div className={classes.icons}>
                            <IconButton className={classes.expand} onClick={handleDismiss}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </CardActions>
                    {(hasError || hasWarning) && <Paper className={classes.collapse}>
                        <Grid container direction="column">
                            {importResult.result
                                .filter(it => it.resultState !== "SUCCESS")
                                .map(it =>
                                    <Grid item>
                                        <Typography className={classes.item}>{it.reason}</Typography>
                                    </Grid>)}
                        </Grid>
                    </Paper>}
                </Card>
            </SnackbarContent>
        </>);
    });

export default ImportReportNotification;

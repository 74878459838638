import {ActionWithPayload, createActionWithoutPayload, createActionWithPayload} from "../utils.actions";
import {Article, ArticleDialogForm, AvailableArticleTags, ArticleType} from "../../../types/article";
import {Publication} from "../../../types/publication";
import {PlanNode} from "../../../types/plan-node";
import {SmallBundleResponse} from "../../../types/bundle";
import {ArticleDialogMode} from "../../reducers/article/article-dialog.reducer";
import {Filters, SearchContext} from "../../../types/common-search";


export type OpenArticleDialogPayload = {
    mode: ArticleDialogMode.CLOSE
    source?: undefined
    default?: undefined
} | {
    mode: ArticleDialogMode.CREATE
    source?: undefined
    default?: ArticleDialogForm
} | {
    mode: ArticleDialogMode.MOVE | ArticleDialogMode.COPY | ArticleDialogMode.MOVE_MULTIPLE
    source: string
    default?: ArticleDialogForm
}


export const [setArticleDialogMode, isSetArticleDialogModeAction] = createActionWithPayload<OpenArticleDialogPayload>('SET_ARTICLE_DIALOG_MODE')
export const [generateNewArticle, isGenerateNewArticleAction] = createActionWithPayload<{ periodicalId?: string }>('GENERATE_NEW_ARTICLE');

export const [saveArticle, isSaveArticleAction] = createActionWithPayload<{ action?: ActionWithPayload, context: SearchContext, forceOptions: Filters }>('SAVE_ARTICLE');
export const [saveArticleSuccess, isSaveArticleSuccessAction] = createActionWithPayload<{ action?: ActionWithPayload, article: Article }>('SAVE_ARTICLE_SUCCESS');
export const [saveArticleError, isSaveArticleErrorAction] = createActionWithoutPayload('SAVE_ARTICLE_ERROR');

export const [copyArticle, isCopyArticleAction] = createActionWithPayload<{ action?: ActionWithPayload }>('COPY_ARTICLE');
export const [copyArticleSuccess, isCopyArticleSuccessAction] = createActionWithPayload<{ action?: ActionWithPayload, article: Article }>('COPY_ARTICLE_SUCCESS');
export const [copyArticleError, isCopyArticleErrorAction] = createActionWithoutPayload('COPY_ARTICLE_ERROR');

export const [moveArticle, isMoveArticleAction] = createActionWithPayload<{ action?: ActionWithPayload }>('MOVE_ARTICLE');
export const [moveArticleSuccess, isMoveArticleSuccessAction] = createActionWithoutPayload('MOVE_ARTICLE_SUCCESS');
export const [moveArticleError, isMoveArticleErrorAction] = createActionWithoutPayload('MOVE_ARTICLE_ERROR');

export const [moveArticles, isMoveArticlesAction] = createActionWithPayload<{ action?: ActionWithPayload }>('MOVE_ARTICLES');
export const [moveArticlesSuccess, isMoveArticlesSuccessAction] = createActionWithoutPayload('MOVE_ARTICLES_SUCCESS');
export const [moveArticlesError, isMoveArticlesErrorAction] = createActionWithoutPayload('MOVE_ARTICLES_ERROR');

// Form
export const [setType, isSetTypeAction] = createActionWithPayload<ArticleType>('SET_ARTICLE_TYPE')
export const [setBundleId, isSetBundleId] = createActionWithPayload<string>('SET_ARTICLE_BUNDLE')
export const [setBundlePlanVersionId, isSetBundlePlanVersionId] = createActionWithPayload<string>('SET_BUNDLE_PLAN_VERSION_ID')
export const [setPlanNodeId, isSetPlanNodeId] = createActionWithPayload<string>('SET_ARTICLE_PLAN_NODE')
export const [setPeriodicalId, isSetPeriodicalIdAction] = createActionWithPayload<string>('SET_ARTICLE_PERIODICAL')
export const [setPublicationId, isSetPublicationIdAction] = createActionWithPayload<number>('SET_ARTICLE_PUBLICATION')
export const [setTitle, isSetTitle] = createActionWithPayload<string | undefined>('SET_ARTICLE_TITLE')
export const [setNumberOfPages, isSetNumberOfPages] = createActionWithPayload<string | undefined>('SET_NUMBER_OF_PAGES')


// Select List Options
export const [setAvailablePublications, isSetAvailablePublicationsAction] = createActionWithPayload<string>('SET_ARTICLE_PUBLICATIONS')
export const [setAvailablePublicationsResponse, isSetAvailablePublicationsResponseAction] = createActionWithPayload<Publication[]>('SET_ARTICLE_PUBLICATIONS')
export const [setAvailableNodes, isSetAvailableNodesAction] = createActionWithPayload<string>('SET_ARTICLE_NODES_RESPONSE')
export const [fetchAvailableTags, isFetchAvailableTagsAction] = createActionWithoutPayload('FETCH_ARTICLE_TAGS_RESPONSE')
export const [setAvailableTags, isSetAvailableTagsAction] = createActionWithPayload<AvailableArticleTags>('SET_ARTICLE_TAGS_RESPONSE')
export const [setAvailableNodesResponse, isSetAvailableNodesResponseAction] = createActionWithPayload<{ plan: PlanNode[], type: "periodical" | "publication" | "bundle" }>('SET_ARTICLE_NODES_RESPONSE')
export const [setAvailablePeriodicalBundlesResponse, isSetAvailablePeriodicalBundlesResponseAction] = createActionWithPayload<SmallBundleResponse[]>('SET_ARTICLE_PERIODICAL_BUNDLES_RESPONSE')
export const [setAvailablePublicationBundlesResponse, isSetAvailablePublicationBundlesResponseAction] = createActionWithPayload<SmallBundleResponse[]>('SET_ARTICLE_PUBLICATION_BUNDLES_RESPONSE')

export const [redirectToEditArticle, isRedirectToEditArticleAction] = createActionWithPayload<string>('REDIRECT_TO_EDIT_ARTICLE')

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Container, Grid} from "@material-ui/core";
import PublicationOrganizeTable from "./PublicationOrganizeTable";
import PublicationOrganizeTree from "./PublicationOrganizeTree";
import PublicationOrganizeHeader from "./PublicationOrganizeHeader";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {commonStyle} from "../../../styles/common.style";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchPublication,
    resetPublication,
    savePublicationPlan,
    setDirtyAction
} from "../../../redux/actions/publication.actions";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {flatMapNodesFiltered, PlanNode} from "../../../types/plan-node";
import PublicationOrganizeMetadata from "./PublicationOrganizeMetadata";
import useAsyncState from "../../commons/hooks/useAsyncState";
import {PublicationStatus} from "../../../types/publication";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {ADMIN, EDITOR, MANAGER} from "../../../utils/roles";
import {isAuth} from "../../Auth";
import {State} from "../../../redux/reducers";
import {pageSizeSelector, querySelector} from "../../../redux/selectors/forceOptions.selectors";
import Typography from "@material-ui/core/Typography";
import PublicationsBreadcrumbs from "../commons/PublicationsBreadcrumbs";
import {forcePublicationOptions} from "../../../utils/publication-utils";
import axios, {AxiosResponse} from "axios";
import _ from "lodash";

const customStyles = makeStyles((theme) => ({
    topTitle: {
        fontWeight: 200,
        margin: theme.spacing(2, 0)
    },
    structureHeader: {
        paddingLeft: theme.spacing(3)
    }
}));

export default function PublicationOrganizeRoot() {
    const theme = useTheme()
    const classes = commonStyle(theme)
    const customClasses = customStyles(theme)
    const {t} = useTranslation()
    const {id} = useParams() as { id: string }
    const dispatch = useDispatch()
    const [nodes, _setNodes] = useAsyncState([] as PlanNode[])
    const [isAdMode, setIsAdMode] = useState(false)
    const publication = useSelector(getCurrentPublication)
    const history = useHistory();
    const [characterCount, setCharacterCount] = useState(0)
    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')

    const [page, setPage] = useState(0);
    const queryId = useSelector(querySelector)
    const size = useSelector(pageSizeSelector)

    const dirty = useSelector(({publication}: State) => publication.organize.isDirty)
    const setNodes = useCallback(_setNodes, [])

    const nodeIds = useMemo(() =>
            flatMapNodesFiltered(nodes, (node: PlanNode) => node.type === "ARTICLE" && !!node.id)
                .map(node => node.id),
        [nodes])

    const setDirty = (dirty: boolean) => {
        dispatch(setDirtyAction(dirty))
    }

    const getCharacterVolume = useMemo(() => _.debounce(nodeIds =>
        axios.post("/api/article/character-volume", nodeIds, {headers: {'Authorization': `Bearer ${token}`}})
            .then((response: AxiosResponse<number>) => setCharacterCount(response.data)), 200), [])

    useEffect(() => {
        if (nodeIds.length > 0) {
            getCharacterVolume(nodeIds)
        }
    }, [nodeIds])

    useEffect(() => {
        dispatch(fetchPublication(id))
    }, [id, dispatch])
    const roles = useSelector((state: State) => state.auth.roles)

    const editorRoles = [EDITOR, MANAGER, ADMIN]

    const isPublicationPlanReadOnly = !isAuth(roles, editorRoles, publication?.periodicalId) || (publication?.status !== undefined && [PublicationStatus.PUBLISHED, PublicationStatus.PUBLISHING].indexOf(publication.status) !== -1)

    history.listen(() => {
        dispatch(resetPublication());
    });

    return <Container maxWidth={"xl"} className={classes.root}>
        <Grid container item xs={12}>
            <PublicationOrganizeHeader queryId={queryId} page={page} size={size}/>
        </Grid>

        <Grid container item direction={"row"}>
            <Grid container item xs={8} spacing={2}>
                <PublicationsBreadcrumbs/>

                <PublicationOrganizeMetadata/>
            </Grid>

            <Grid container direction={"row"} alignItems={"flex-start"}>
                <Grid container xs={8}>
                    <Grid item>
                        <Typography variant="h5" component="h1"
                                    className={customClasses.topTitle}>{t(`publications.articles_and_ads_table_title`)}</Typography>
                    </Grid>
                    <Grid container item>
                        <PublicationOrganizeTable nodes={nodes} setNodes={setNodes} page={page}
                                                  setIsAdMode={setIsAdMode} isAdMode={isAdMode}
                                                  setPage={setPage}/>
                    </Grid>
                </Grid>

                <Grid container item xs={4} justify={"flex-start"} alignContent={"flex-start"}
                      style={{position: "sticky", top: "80px"}}>
                    <Grid container xs={12} className={customClasses.structureHeader}>
                        <Grid item xs={6}>
                            <Typography variant="h5" component="h1"
                                        className={customClasses.topTitle}>{t(`publications.structure_title`)}</Typography>
                        </Grid>

                        {!isPublicationPlanReadOnly &&
                        <Grid container xs={6} justify={"flex-end"} alignContent={"center"} direction={"row"}>
                            <Grid item>
                                <Button variant={"contained"} color={"primary"} onClick={() => {
                                    dispatch(savePublicationPlan({
                                        plan: nodes,
                                        publicationId: id,
                                        filters: forcePublicationOptions(isAdMode, publication, queryId, size, page)
                                    }));
                                }}>
                                    {t('publications.save_plan')}
                                </Button>
                            </Grid>
                        </Grid>}

                        <Grid item xs={6}>
                            <Typography>{t('articles.character_volume_value_and_label', {count: characterCount})}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                        <PublicationOrganizeTree
                            nodes={nodes}
                            setNodes={setNodes}
                            dirty={dirty}
                            setDirty={setDirty}
                            isPublicationPlanReadOnly={isPublicationPlanReadOnly}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>
}

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { TreeView } from "@material-ui/lab"
import React, { Children, FC, useState } from "react"
import { useParams } from "react-router-dom"
import { Column } from "../../components/FlexBox"
import { TreeContext } from "../../contexts/organizeTreeBibleContexts"
import { useFetchDataOnMount } from "../../hooks/useFetchDataOnMount"
import { TreeModel, TreeType } from "../../models/TreeModels"
import { ChildrenProps, SetState } from "../../types"
import { addNodeIdIntoDocumentUnit } from "../organizeTree/tree/tree.utils"
import { boxStyle } from "./admistrateTree.common"
import { AdminNodeList } from "./AdminNodeList"
import { KyrSubTitle } from "../../components/KyrSubTitle"
import { KyrHandleQuery } from "../../components/KyrHandleQuery"
import { useTranslation } from "react-i18next"
const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)
  return (
    <Column rowGap={20} style={{ flexGrow: 1, overflow: 'auto' }}>
      {childrenList[0]}
      <Column style={{ ...boxStyle, flexGrow: 1, padding: 20, overflow: 'auto' }}>
        {childrenList[1]}
      </Column>
    </Column>
  )
}

type ContentType = {
  id: number;
  expandedState: [string[], SetState<string[]>]
}
const Content: FC<ContentType> = ({ id, expandedState: [expanded, setExpanded] }) => {
  const [treeState, setTreeState] = useFetchDataOnMount<TreeModel>({
    url: `/api/kyrielle/tree/${id}`,
    method: 'get',
    context: TreeContext,
    mapper: addNodeIdIntoDocumentUnit,
  })


  return (
    <KyrHandleQuery errorMessage="KYRIELLE_TREE_LOAD" query={treeState} setQuery={setTreeState}>
      <TreeView
        expanded={expanded}
        disableSelection
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<KeyboardArrowRightIcon />}
        onNodeToggle={(_, nodeIds) => setExpanded(nodeIds)}
      >
        <AdminNodeList
          colorful={treeState?.data?.type === TreeType.ACTES_CLAUSES}
          nodes={treeState?.data?.nodes ?? []}
        />
      </TreeView>
    </KyrHandleQuery>
  )
}

export const TreeStructure = () => {
  const { id } = useParams<{ id: string }>()
  const [expanded, setExpanded] = useState<string[]>([])
  const {t} = useTranslation()

  return (
    <Layout>
      <KyrSubTitle>{t('kyrielle.administrate.cdfStructureTitle')}</KyrSubTitle>
      <Content id={Number(id)} expandedState={[expanded, setExpanded]}/>
    </Layout>
  )
}
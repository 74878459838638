import {AxiosStatic} from "axios";
import {merge, Observable, of} from "rxjs";
import {Action} from "../../../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, concatMap, delay, filter, map, mergeMap} from "rxjs/operators";
import {StateObservable} from "redux-observable";
import {State} from "../../../reducers";
import {EpicDependencies} from "../../../store";
import {notifyError, notifySuccess} from "../../../actions/notification.actions";
import {i18n} from "i18next";
import {
    fetchOnlinePublications,
    fetchOnlinePublicationsResponse,
    isDeleteOnlinePublicationsAction,
    isFetchOnlinePublicationsAction,
    isPushArticlesOnlineNowAction
} from "../../../actions/admin/online/online-publications.actions";
import {ArticleOnlinePublicationTask} from "../../../../types/article"

function _fetchOnlinePublications(axios: AxiosStatic, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/admin/publish-online`, {headers}))
        .pipe(
            map((response) =>
                fetchOnlinePublicationsResponse(response.data)
            ),
            catchError(err => merge(
                of(fetchOnlinePublicationsResponse([])),
                of(notifyError(+err.response.status, {type: 'ARTICLE'}))
                )
            )
        )
}


function _deleteOnlinePublications(axios: AxiosStatic, i18n: i18n, token: string, task: ArticleOnlinePublicationTask): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    const path = !!task.name ? `/api/publication/schedule/${task.id}` : `/api/admin/publish-online/${task.id}`
    return fromPromise(axios.delete(path, {headers}))
        .pipe(
            concatMap((response) => merge(of(fetchOnlinePublications()), of(notifySuccess(i18n.t("workflows.success.REMOVE_ONLINE_PUBLICATION_SUCCESS"))))),
            catchError(err => merge(
                of(notifyError(+err.response.status, {type: !!task.name ? 'PUBLICATION' : 'ARTICLE'}))
                )
            )
        )
}

function _pushOnlinePublicationsNow(axios: AxiosStatic, i18n: i18n, token: string, task: ArticleOnlinePublicationTask): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    }
    if (!task.name) {
        return fromPromise(axios.put(`/api/admin/publish-online/${task.id}`, {
            articleIds: [task.id]
        }, {headers}))
            .pipe(
                concatMap((response) => merge(of(fetchOnlinePublicationsResponse(undefined)), of(fetchOnlinePublications()).pipe(delay(2000)), of(notifySuccess(i18n.t("workflows.success.ONLINE_PUBLICATION_UPDATED"))))),
                catchError(err => merge(
                    of(notifyError(+err.response.status, {type: 'ARTICLE'}))
                    )
                )
            )
    } else {
        return fromPromise(axios.put(`/api/admin/publication/${task.id}/publish`, {headers}))
            .pipe(
                concatMap(() => merge(of(fetchOnlinePublications()), of(notifySuccess(i18n.t("workflows.success.ONLINE_PUBLICATION_UPDATED"))))),
                catchError(err => merge(
                    of(notifyError(+err.response.status, {type: 'PUBLICATION'}))
                    )
                )
            )
    }
}

export function loadOnlinePublications(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isFetchOnlinePublicationsAction),
        concatMap((action) =>
            _fetchOnlinePublications(axios, state$.value.auth.token ?? "")
        )
    )
}

export function deleteOnlinePublication(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;

    return action$.pipe(
        filter(isDeleteOnlinePublicationsAction),
        concatMap((action) =>
            _deleteOnlinePublications(axios, i18n, state$.value.auth.token ?? "", action.payload)
        )
    )
}

export function pushArticlesOnlineNow(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;

    return action$.pipe(
        filter(isPushArticlesOnlineNowAction),
        mergeMap((action) => {
            return _pushOnlinePublicationsNow(axios, i18n, state$.value.auth.token ?? "", action.payload)
        })
    )
}

import React from "react";
import { DateTime as DateTimeLuxon, DateTimeFormatOptions, LocaleOptions } from "luxon";

type Props = {
    date: string,
    format?: DateTimeFormatOptions,
    locale?: string
    onlyDate?: boolean
}

export default ({ date, format, locale, onlyDate }: Props) => {

    const output = DateTimeLuxon.fromISO(date)
    .setZone("europe/paris")
    .setLocale(locale ?? "fr-FR")
    .toLocaleString((format ? format : DateTimeLuxon.DATETIME_MED) as LocaleOptions & DateTimeFormatOptions)

    return (
        <>
            {onlyDate ? output.split(',')[0] : output}
        </>
    );
}
import React, {useEffect, useMemo, useState} from "react";
import {Checkbox, FormControlLabel, Grid, TextField} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export const bundleStructureTitleRegex = /<types:renvoi.*(refid="([^"]*)").*>(.*)<\/types:renvoi>/
const editRegex = /(<types:renvoi.*>)(.*)(<\/types:renvoi>)/
const refidEditRegex = /(<types:renvoi.*)(refid=".*")(.*>.*<\/types:renvoi>)/

export const _NO_MATCH = 0
export const _REFID = 1
export const _REFID_VALUE = 2
export const _TEXT_CONTENT = 3

const urlRegexList = [
    /AMF,\s*[Rr](é|è)gl\.(\s|&nbsp;)*[Gg]én\.,\s*art\.(\s|&nbsp;)*(\d{1,3}-(\d)+)\s(\w{3,10})/,
    /AMF,\s*[Rr](é|è)gl\.(\s|&nbsp;)*[Gg]én\.,\s*art\.(\s|&nbsp;)*((\d{1,3}-(\d+)-(\w))|(\d{1,3}-(\d+)))/,
    /https?:\/\/([\w+?\.\w+]+)([a-zA-Z0-9\\\^\$\.\(\)\?\*\+~!@#%_=\/:',-]*)?(\w|\d|\\|\/)/,
    /(www\.[\w+?\.\w+]+)([a-zA-Z0-9\\\^\$\.\(\)\?\*\+~!@#%_=\/:',-]*)?(\w|\d|\\|\/)/,
    /L(\.|oi)(?:\s|&nbsp;)+du\s+(29)(?:\s|&nbsp;)+(juill(\.|et))\s+(1881)/,
    /L(\.|oi)(?:\s|&nbsp;)+du\s+(1)er(?:\s|&nbsp;)+(juill(\.|et))\s+(1901)/,
    /D(|écr|écret)\s+du\s+(16)(?:\s|&nbsp;)+(août)\s+(1901)/,
    /L(\.|oi)\s+du\s+(9)(?:\s|&nbsp;)+(déc(\.|embre))\s+(1905)/,
    /(lext\.so\/img\d{13}-\d{3}\.pdf)/
]

export const validateLexUrl = url => urlRegexList.some(regex => regex.test(url))

export default function BundleStructureTitleRenvoiEditField({
                                                                fieldValue, setFieldValue, setRenvoiOk,
                                                                setNameOk
                                                            }) {
    const {t} = useTranslation()
    const [invalidUrl, setInvalidUrl] = useState(false)
    const matched = useMemo(() => {
        const matched = fieldValue.match(bundleStructureTitleRegex)
        return matched ?? [fieldValue]
    }, [fieldValue])

    const [innerFieldValue, setInnerFieldValue] = useState("")

    useEffect(() => {
        if (matched.length > 1) {
            const urlOk = validateLexUrl(innerFieldValue)
            setRenvoiOk(urlOk)
            setInvalidUrl(!urlOk)
        } else {
            setRenvoiOk(true)
            setInvalidUrl(false)
        }
    }, [matched, innerFieldValue])

    return <>
        <Grid item xs={12} direction="column">
            <TextField
                label={"Titre"}
                required
                fullWidth={true}
                value={(matched.length > 1 ? matched[_TEXT_CONTENT] : matched[_NO_MATCH]) ?? ""}
                autoFocus
                onChange={e => {
                    if (matched.length > 1) {
                        setFieldValue(fieldValue.replace(editRegex, `$1${e.target.value}$3`))
                    } else {
                        setFieldValue(e.target.value)
                    }

                    setNameOk(e.target.value.length > 0)
                }}
            />

            <FormControlLabel control={
                <Checkbox checked={matched.length > 1}
                          onClick={(v) => {
                              if (matched.length > 1) {
                                  setFieldValue(matched[_TEXT_CONTENT])
                              } else if (matched.length === 1) {
                                  setFieldValue(`<types:renvoi type="url" refid="">${matched[_NO_MATCH]}</types:renvoi>`)
                              }
                          }}/>
            } label={"Avec renvoi"}/>

            {matched.length > 1 && <TextField
                error={invalidUrl}
                value={matched[_REFID_VALUE]}
                onChange={e => {
                    setFieldValue(fieldValue.replace(refidEditRegex, `$1refid="${e.target.value}"$3`))
                    setInnerFieldValue(e.target.value)
                }}
                fullWidth label={t('admin.bundle.url')}/>}
        </Grid>
    </>
}

import {Fab, withStyles} from "@material-ui/core";

const StyledFab = withStyles((theme) => ({
    root: {
        margin: "0.5em 0",
        borderRadius: "25%",
        height: 35,
        width: 38,
        backgroundColor: "white",
        color: theme.palette.primary.main,
        border: "2px solid",
        borderColor: theme.palette.primary.main,
        "& .MuiFab-root:hover": {
            backgroundColor: theme.palette.primary.light
        }
    }
}))(Fab)

export default StyledFab
import MenuBook from "@material-ui/icons/MenuBook";
// import Dashboard from '@material-ui/icons/Dashboard';
import Newspaper from "mdi-material-ui/Newspaper";
import Details from "mdi-material-ui/Newspaper";
import AccountCircleOutline from "mdi-material-ui/AccountCircleOutline";
import BullhornOutline from "mdi-material-ui/BullhornOutline";
import ImageOutline from "mdi-material-ui/ImageOutline";
import LinkVariant from "mdi-material-ui/LinkVariant";
import FileEditOutline from "mdi-material-ui/FileEditOutline";
import FolderEditOutline from "mdi-material-ui/FolderEdit";
import FolderMove from "mdi-material-ui/FolderMove";
import ContentCopy from "mdi-material-ui/ContentCopy";
import Eye from "mdi-material-ui/Eye";
import FilePdfOutline from "mdi-material-ui/FilePdfOutline";
import CheckBold from "mdi-material-ui/CheckBold";
import FileCodeOutline from "mdi-material-ui/FileCodeOutline";
import ShipWheel from "mdi-material-ui/ShipWheel";
import Account from "mdi-material-ui/Account";
import Plus from "mdi-material-ui/Plus";
import Delete from "mdi-material-ui/DeleteOutline";
import Star from "mdi-material-ui/Star";
import StarLight from "mdi-material-ui/light/Star";
import PencilOutline from 'mdi-material-ui/PencilOutline';
import Download from 'mdi-material-ui/Download';
import MagnifyPlusOutline from "mdi-material-ui/MagnifyPlusOutline";
import NoImage from "mdi-material-ui/ImageOff";
import Export from "mdi-material-ui/ExportVariant";
import DotsHorizontal from "mdi-material-ui/DotsHorizontal"
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Clear from "@material-ui/icons/Clear";
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Reply from '@material-ui/icons/Reply';
/*
 * check for more https://materialdesignicons.com/
 */

export const ClearIcon = Clear;
export const ExpandMoreIcon = ExpandMore;
export const ExpandLessIcon = ExpandLess;
export const ReplyIcon = Reply;

export const UserIcon = Account;
export const NoImageIcon = NoImage;

export const ArticleIcon = Newspaper;
export const PublicationIcon = MenuBook;
export const ImportIcon = ArrowDownwardIcon;
export const PeriodicalIcon = MenuBook;

export const Zoom = MagnifyPlusOutline;
export const PlusIcon = Plus;
export const DeleteIcon = Delete;
export const FavIcon = Star;
export const FavIconLight = StarLight;
export const EditIcon = PencilOutline;
export const DownloadIcon = Download;

export const AdminIcon = ShipWheel;
export const AuthorIcon = AccountCircleOutline;
export const ImageIcon = ImageOutline;
export const AdvertisementIcon = BullhornOutline;

export const LinkedIcon = LinkVariant;
export const XmlIcon = FileCodeOutline;
export const PdfIcon = FilePdfOutline;
export const ViewIcon = Eye;
export const MoreIcon = DotsHorizontal;

export const EditArticleIcon = FileEditOutline;
export const EditBundleIcon = FolderEditOutline;

export const PlacardIcon = FilePdfOutline;
export const BapIcon = CheckBold;
export const SendIcon = FolderMove;
export const CopyIcon = ContentCopy;
export const ExportIcon = Export;
export const DetailsIcon = Details;

export const ViewGridIcon = ViewComfyIcon;

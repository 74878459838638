import {IconButton} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import {ShowPdfIcon, ShowObsoletePdfIcon} from "../../commons/icon/icons";
import Auth from "../../Auth";
import {ADMIN, EDITOR, MANAGER, PRODUCER, VIEWER} from "../../../utils/roles";
import {State} from "../../../redux/reducers";
import {openPdfOfArticleInNewTab, openPdfOfPublicationInNewTab} from "../../../utils/workflows-utils";
import {JouveProcessStatus} from "../../../types/workflow";


type Props = {
    id: string | number,
    lastAvailablePlacardProcessStatus?: JouveProcessStatus,
    type: "publication" | "article",
    size?: 'small' | 'medium'
}

function PlacardStatusAndDownload({id, lastAvailablePlacardProcessStatus, type, size}: Props) {
    const {t} = useTranslation()
    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')
    const dispatch = useDispatch()

    if (lastAvailablePlacardProcessStatus) {
        return (
            <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR, VIEWER]}>
                <Tooltip title={t('workflows.see_placard') ?? ""}>
                    <IconButton size={size || "small"} onClick={() => {
                        if (type === "publication")
                            openPdfOfPublicationInNewTab(id as number, dispatch, token)
                        else
                            openPdfOfArticleInNewTab(id as string, dispatch, token)
                    }
                    }>{lastAvailablePlacardProcessStatus === JouveProcessStatus.OUT_OF_DATE ? <ShowObsoletePdfIcon/> :
                        <ShowPdfIcon/>}</IconButton>
                </Tooltip></Auth>
        )
    } else {
        return (<></>)
    }
}

export default PlacardStatusAndDownload;

import React, {useEffect, useState} from "react";
import TreeViewer from "../../commons/tree/TreeViewer";
import {Container} from "@material-ui/core";
import {useSelector} from "react-redux";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {PlanNode} from "../../../types/plan-node";
import {useTranslation} from "react-i18next";
import RouteLeavingGuard from "../../RouteLeavingGuard/RouteLeavingGuard";
import {useHistory, useLocation} from "react-router-dom";
import {savePublicationPlan} from "../../../redux/actions/publication.actions";
import {setDirty} from "../../../redux/actions/admin/bundle/admin-custom-bundle.actions";

const removeNulls = (plan: PlanNode[]) => plan.map(node => node.sub === null ? {...node, sub: []} : {
    ...node,
    sub: removeNulls(node.sub)
})

type Props = {
    nodes: PlanNode[]
    setNodes: any
    dirty: boolean
    setDirty: any
    isPublicationPlanReadOnly: boolean
}

export default function PublicationOrganizeTree({nodes, setNodes, dirty, setDirty, isPublicationPlanReadOnly}: Props) {
    const {t} = useTranslation()
    const history = useHistory()
    const location = useLocation()
    const publication = useSelector(getCurrentPublication)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (publication?.plan) {
            setNodes(removeNulls(publication.plan)).then(() => setLoaded(true));
        }
    }, [publication?.id, publication?.plan, setNodes])

    const handleUnload = (ev) => {
        if (dirty && location.pathname.indexOf("edit") !== -1) {
            ev.preventDefault();
            return ev.returnValue = 'Voulez-vous enregistrer les modifications apportées au chemin de fer avant de quitter cette page ?';
        }
    }
    window.addEventListener("beforeunload", handleUnload);

    return <Container style={{marginTop: '1%', maxHeight: '85vh', overflowY: 'auto'}}>
        {!isPublicationPlanReadOnly && <RouteLeavingGuard
            when={dirty}
            shouldBlockNavigation={() => true}
            navigate={(location) => history.push(location)}
            title={t(`publications.confirm_dialog_title`)}
            actionOnOk={(!isPublicationPlanReadOnly && publication?.id) ? savePublicationPlan({
                plan: nodes,
                publicationId: publication!!.id.toString()
            }) : undefined}
            navigateOnCancel={true}
        />}
        {loaded && <TreeViewer
            setDirty={setDirty}
            readonly={isPublicationPlanReadOnly}
            detachLabel={t('bundles.unlink')}
            maxDepth={3}
            preventSiblings={false}
            edit={false}
            canAddContainerNodes={false}
            nodes={nodes}
            setNodes={setNodes}
            isBundleStructureEdition={false}
        />
        }
    </Container>
}

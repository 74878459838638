import { useState } from "react"

/**
 * Custom hook that limits the length of a text input value.
 *
 * @param maxLength - The maximum length allowed for the text input value.
 * @param defaultValue - The default value for the text input.
 * @returns A tuple containing the current value and a setter function to update the value.
 */
export const useTextMaxLength = (maxLength: number, defaultValue = ''): [string, (arg: string) => void] => {
  const [state, setState] = useState(defaultValue)
  const setter = (value: string) => {
    if (value.length > maxLength) return
    setState(value)
  }

  return [state, setter]
}
import React from "react";
import Avatar from '@material-ui/core/Avatar';
import {makeStyles} from '@material-ui/core/styles';
import {PlusIcon} from "../../../../icon";
import Dropzone from "react-dropzone";
import {addImageAuthor, addImageAuthorError} from "../../../../redux/actions/author.actions";
import {useDispatch, useSelector} from "react-redux";
import {B64Image} from "../../../../types/image";
import {State} from "../../../../redux/reducers";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {},
    image: {
        width: "138px",
        height: "138px",
        cursor: "pointer",
        border: "rgba(0, 0, 0, .325) 1px solid",
        backgroundColor: "transparent",
        color: theme.palette.text.secondary,
        '&:hover, &:focus, &:active, &:visited, &:link': {
            backgroundColor: theme.palette.secondary.light,
            border: "rgba(0, 0, 0, .325) 1px solid"
        }
    }
}));


function displayImageStatus(uploading: boolean) {
    if (uploading) {
        return (<CircularProgress/>)
    } else {
        return (<PlusIcon fontSize={"large"}/>
        )
    }
}

const AddImageAuthor = () => {
    const {t} = useTranslation();

    const classes = useStyles();
    const dispatch = useDispatch();

    function getBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            dispatch(addImageAuthor({data: reader.result} as B64Image))
        };
        reader.onerror = function () {
            dispatch(addImageAuthorError("TODO"))
        };
    }

    const uploading = useSelector(({author}: State) => author.uploading);

    return (
        <Grid item>
            <Dropzone maxSize={10000000} accept={["image/jpeg", "image/png"]}
                      onDropRejected={
                          () => {
                              dispatch(addImageAuthorError("TODO"))
                          }
                      }
                      onDropAccepted={
                          files => {
                              files.forEach(file => getBase64(file))
                          }
                      }>
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Tooltip title={t('images.addMany') ?? ''}>
                                <Avatar variant="square" className={classes.image}>
                                    {
                                        displayImageStatus(uploading)
                                    }
                                </Avatar>
                            </Tooltip>
                        </div>
                    </section>
                )}
            </Dropzone>
        </Grid>
    )
}

export default AddImageAuthor;

import React from "react";
import {Popover} from "@material-ui/core";
import Grid from "@material-ui/core/Grid"
import BlockIcon from '@material-ui/icons/Block';

type Props = {
    anchorEl: EventTarget & HTMLLIElement | null
    setAnchorEl: any
    dragInfo: string
}

export default function TreePopover({anchorEl, setAnchorEl, dragInfo}: Props) {
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'tree-popover' : undefined;
    const [type,] = dragInfo !== "" ? dragInfo.split("|>") : ["", ""]

    return <>
        {type === "invalid" && <Popover
            disableRestoreFocus
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{pointerEvents: 'none'}}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Grid container item direction={"row"} justify={"center"} alignItems={"center"} style={{padding: 5}}>
                <BlockIcon/>
            </Grid>
        </Popover>}
    </>
}

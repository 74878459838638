import {Action} from "../../actions/utils.actions";
import {
    isLoadAutoCompletePublicationAction,
    isLoadAutoCompletePublicationErrorAction,
    isLoadAutoCompletePublicationSuccesAction
} from "../../actions/article/article-filters.actions";
import {EntityProposal} from "../../../types/quick-search";

export type ArticleFiltersState = {
    availablePublications?: EntityProposal[]
}

const init: ArticleFiltersState = {
    availablePublications: []
}

export default function ArticleFiltersReducer(state = init, action: Action): ArticleFiltersState {
    if (isLoadAutoCompletePublicationErrorAction(action)) {
        return {...state, availablePublications: []}
    } else if (isLoadAutoCompletePublicationAction(action)) {
        return {...state, availablePublications: undefined}
    } else if (isLoadAutoCompletePublicationSuccesAction(action)) {
        return {...state, availablePublications: action.payload}
    } else {
        return state
    }
}

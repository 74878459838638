import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";


export default () => {
    return (<div style={{width: '100%'}}>
        <Box display="flex" justifyContent="center" m={1} p={1}>
            <CircularProgress color="secondary"/>
        </Box>
    </div>)
}
import React, {useCallback, useEffect} from "react";
import {IconButton, Table, TableCell, TableContainer, TableRow, Tooltip} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {onlinePublications} from "../../../redux/selectors/admin/online/admin-online.selectors";
import {
    deleteOnlinePublications,
    fetchOnlinePublications,
    pushArticlesOnlineNowAction
} from "../../../redux/actions/admin/online/online-publications.actions";
import CountDownDateTime from "../../commons/CountDownDateTime";
import {DownloadIcon, PushOnlineIcon} from "../../commons/icon/icons";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";
import {State} from "../../../redux/reducers";
import {notifyError} from "../../../redux/actions/notification.actions";
import Loader from "../../commons/Loader";

export default function PushOnlineArticleTable() {
    const {t} = useTranslation()
    const articles = useSelector(onlinePublications)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchOnlinePublications())
    }, [dispatch])

    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')

    const download = useCallback((articleId: string) => () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/admin/publish-online/${articleId}/download`, {headers: headers, responseType: 'blob'})
            .then(result => {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${articleId}-export.zip`); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
            dispatch(notifyError(+error.response.status, {type: 'ARTICLE'}))
        })
    }, [token])

    return (
        <TableContainer>
            <Table>
                <TableRow>
                    <TableCell width={"100px"}>{t('push-online-admin-page.table.id')}</TableCell>
                    <TableCell>{t('push-online-admin-page.table.name')}</TableCell>
                    <TableCell align={"center"}
                               width={"150px"}>{t('push-online-admin-page.table.remaining-time')}</TableCell>
                    <TableCell align={"center"} width={"150px"}>{t('push-online-admin-page.table.actions')}</TableCell>
                </TableRow>
                {
                    (articles !== undefined && articles.length > 0) ? articles.map(a =>
                        <TableRow>
                            <TableCell>{a.name || a.id}</TableCell>
                            <TableCell>{a.title}</TableCell>
                            <TableCell align={"center"}><CountDownDateTime date={a.executionDate}/> </TableCell>
                            <TableCell align={"center"}>
                                <Tooltip
                                    title={t('push-online.push-now') ?? ''}><IconButton
                                    color="inherit"
                                    onClick={() => dispatch(pushArticlesOnlineNowAction(a))}>
                                    <PushOnlineIcon/>
                                </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={t('push-online.cancel-delivery') ?? ''}><IconButton
                                    color="inherit"
                                    onClick={() => dispatch(deleteOnlinePublications(a))}>
                                        <CancelIcon />
                                    </IconButton>
                                </Tooltip>
                                {
                                    !a.name &&
                                    <Tooltip
                                        title={t('misc.download') ?? ''}><IconButton
                                            color="inherit"
                                            onClick={download(a.id)}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Tooltip>
                                }

                            </TableCell>
                        </TableRow>
                    ) : <TableRow>
                        <TableCell colSpan={4} align={"center"}>
                            {articles === undefined ? <Loader/> : t('table.no_result')}
                        </TableCell>
                    </TableRow>

                }
            </Table>
        </TableContainer>
    )
}

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme} from "@material-ui/core";
import React, {useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, useLocation} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {DeleteIcon, DownloadIcon, EditIcon, ViewIcon} from "../../../icon";
import Auth from "../../Auth";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import LexImage from "../../commons/LexImage";
import DateTime from "../../commons/DateTime";
import AdsListActions from "./AdsListActions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {AdSearchResult, ImageSearchFilters, SearchContext} from "../../../types/common-search";
import {selectAllPage, selectOneElement, updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {ADMIN, ADMIN_ADS} from "../../../utils/roles";
import {deleteAd, toggleAdDisabled} from "../../../redux/actions/ad.actions";
import {readImageQueryParams, shouldDeactivateDelete, shouldDeactivateDisable} from "../../../utils/image.utils";
import Date from "../../commons/Date";
import {downloadImages} from "../../../utils/common-utils";
import {makeSortHandler, sortDirection} from "../../../utils/table-utils";
import {tableStyle} from "../../../styles/table.style";
import GridListSwitcher from "../../commons/GridListSwitcher";
import {DisableIcon, EnableIcon} from "../../commons/icon/icons";

type LexAdsListProps = {
    filters: ImageSearchFilters
}

const mapper = {
    UPDATE: {default: "DESC", DESC: "ASC", ASC: ""},
    DEADLINE: {default: "ASC", DESC: "", ASC: "DESC"},
    ID: {default: "ASC", DESC: "", ASC: "DESC"},
    NAME: {default: "ASC", DESC: "", ASC: "DESC"}
}

export default ({filters}: LexAdsListProps) => {
    const theme = useTheme()
    const classes = tableStyle(theme)
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const searchLocation = useLocation().search;
    const isLocationGrid = searchLocation.includes("&grid") || searchLocation.includes("?grid")

    const ads = useSelector(({search}: State) => search.common[SearchContext.ADVERTISEMENT].searchResults)

    const [selectedIds, allPageSelected, loaded]: [string[], boolean, boolean] = useSelector((state: State) => {
        const store = state.search.common[SearchContext.ADVERTISEMENT];
        return [store.selectedIds, store.allPageSelected, store.status === 'LOADED'];
    });

    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);
    const [adBeingDeleted, changeAdBeingDeletedId] = useState("");
    const handleAdDeletionConfirmation = (adId: string) => () => {
        changeAdBeingDeletedId(adId)
        openConfirmationDialog(true)
    }

    const queryParams = {...readImageQueryParams(searchLocation), collectionFilter: ["ADVERTISEMENT"]}

    const handleAdDeletion = () => {
        dispatch(deleteAd({id: adBeingDeleted, ctx: SearchContext.ADVERTISEMENT, forceOptions: queryParams}))
    }

    const handleChangeMode = () => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ADVERTISEMENT,
            field: "grid",
            value: !isLocationGrid
        }))
    };

    const enableIcon = (flag: boolean) => {
        return flag ? <EnableIcon/> : <DisableIcon/>
    }

    const switchEnableAds = (id: string, flag: boolean) => () => {
        dispatch(toggleAdDisabled({id, flag}));
    }

    const downloadImageAction = (image: AdSearchResult) => () => {
        downloadImages([image].map(it => ({id: it?.id ?? '', collection: it?.collection ?? 'ADVERTISEMENT'})))
    }

    const sortHandler = makeSortHandler(SearchContext.ADVERTISEMENT, mapper, filters, dispatch, updateSearchQueryParam)

    return (<>
            <ConfirmationDialog
                title={t('ads.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default', action: () => openConfirmationDialog(false)},
                    {label: t('dialogs.confirm'), action: handleAdDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            <TableContainer>
                <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                    <span>
                        <GridListSwitcher handleChangeMode={handleChangeMode} isLocationGrid={isLocationGrid}/>
                    </span>
                </div>

                <Table aria-label={"Liste des publicités"}><TableHead>
                    <TableRow>
                        <TableCell padding="checkbox" className={classes.cell}>
                            <Checkbox
                                checked={allPageSelected}
                                onChange={(event, check) => {
                                    dispatch(selectAllPage({ctx: SearchContext.ADVERTISEMENT, allPages: check}))
                                }}
                            />
                        </TableCell>
                        <TableCell className={classes.cell}>Publicité</TableCell>
                        <TableCell className={classes.cell}>
                            <TableSortLabel
                                active={sortDirection(filters, "ID") !== undefined}
                                direction={sortDirection(filters, "ID")}
                                onClick={sortHandler("ID")}>
                                {t('misc.id')}
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <TableSortLabel
                                active={sortDirection(filters, "NAME") !== undefined}
                                direction={sortDirection(filters, "NAME")}
                                onClick={sortHandler("NAME")}>
                                Nom
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.cell}>
                            {t('images.dimensions')}
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <TableSortLabel
                                active={sortDirection(filters, "DEADLINE") !== undefined}
                                direction={sortDirection(filters, "DEADLINE")}
                                onClick={sortHandler("DEADLINE")}>
                                Date limite
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.cell}>
                            <TableSortLabel
                                active={sortDirection(filters, "UPDATE") !== undefined}
                                direction={sortDirection(filters, "UPDATE")}
                                onClick={sortHandler("UPDATE")}>
                                Modifiée le
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={classes.cell}>
                            {t('images.state')}
                        </TableCell>
                        <TableCell align={"center"} className={classes.cell}>{t('misc.actions')}</TableCell>
                        {/*    */}
                    </TableRow>
                </TableHead>

                    <TableBody>
                        {(selectedIds && selectedIds.length > 0) &&
                        <TableRow key={"selectedRow"} className={classes.actionGroup}>
                            <TableCell colSpan={9} className={classes.cell}>
                                <AdsListActions/>
                            </TableCell>
                        </TableRow>}

                        {ads.length > 0 && ads.map(ad =>
                            <TableRow key={ad.id} className={`${classes.row}`} hover={true}>
                                <TableCell padding="checkbox" className={classes.cell}>
                                    <Checkbox
                                        checked={selectedIds.indexOf(ad.id ?? '') > -1}
                                        onChange={() => dispatch(selectOneElement({
                                            ctx: SearchContext.ADVERTISEMENT,
                                            id: ad.id
                                        }))}
                                    />
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <LexImage image={ad} maxWidth={65} disabled={!ad.enable} size={"THUMBNAIL"}/>
                                </TableCell>
                                <TableCell className={classes.cell}>{ad.id}</TableCell>
                                <TableCell className={classes.cell}>{ad.name}</TableCell>
                                <TableCell
                                    className={classes.cell}>{ad.formatLabel !== '???' && ad.formatLabel + ' mm'}</TableCell>
                                <TableCell className={classes.cell}>
                                    {ad.deadline && <Date date={ad.deadline}/>}
                                    {!ad.deadline && <span>{t('misc.not_provided')}</span>}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    <DateTime date={ad.modificationDate ?? ''}/>
                                </TableCell>
                                <TableCell className={classes.cell}>{enableIcon(ad.enable)}</TableCell>
                                <TableCell align={"center"} className={classes.cell}>
                                    <Link
                                        to={`/${t('routes.ad.root')}/${ad.id}`}
                                        component={RouterLink}>
                                        <Tooltip title={`${t('ads.see')}` || ""}>
                                            <IconButton className={classes.iconButton}>
                                                <ViewIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Link>

                                    <Tooltip title={t('ads.export') || ""}>
                                        <IconButton onClick={downloadImageAction(ad)}>
                                            <DownloadIcon color="inherit"/>
                                        </IconButton>
                                    </Tooltip>

                                    <Auth requiredRoles={[ADMIN, ADMIN_ADS]}>
                                        <Link
                                            to={`/${t('routes.ad.root')}/${ad.id}/edit`}
                                            component={RouterLink}>
                                            <Tooltip title={t('misc.edit') || ""}>
                                                <IconButton className={classes.iconButton}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Link>

                                        <Tooltip title={(ad.enable ? t('ads.disable') : t('ads.enable')) || ""}>
                                            <IconButton
                                                disabled={ad.enable ? shouldDeactivateDisable(ad) : false}
                                                className={classes.iconButton}
                                                onClick={switchEnableAds(ad.id, ad.enable)}>
                                                {enableIcon(!ad.enable)}
                                            </IconButton>
                                        </Tooltip>

                                        <IconButton className={classes.iconButton}
                                                    disabled={shouldDeactivateDelete(ad)}
                                                    onClick={handleAdDeletionConfirmation(ad.id ?? '')}>
                                            <Tooltip title={t('misc.delete') || ""}>
                                                <DeleteIcon/>
                                            </Tooltip>
                                        </IconButton>
                                    </Auth>
                                </TableCell>
                            </TableRow>
                        )}

                        {(ads.length === 0 && loaded) && (
                            <TableRow>
                                <TableCell colSpan={9} align={"center"}>
                                    {t('table.no_result')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {shallowEqual, useSelector} from "react-redux";
import {State} from "../../../../redux/reducers";
import ImageAuthorForm from "./ImagesFormItemForm";
import {generateImageAuthorName} from "../../../../utils/image.utils";
import {isSaved} from "../../../../types/author";

const useStyles = makeStyles((theme) => ({
    root: {},
    notActive: {
        display: "none"
    },

    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: 2
    },

    media: {
        width: "100%"
    }
}));

type Props = {
    index: number
    close: () => void
    register: any
    errors: any
    control: any
}

const ImagesFormItemPanel = ({index, close, register, errors, control}: Props) => {

    const {t} = useTranslation();
    const classes = useStyles();

    const authorId = useSelector(({author}: State) => {
        return author.current && isSaved(author.current)
            ? author.current.id
            : undefined
    });
    const image = useSelector(({author}: State) => author.current!.images[index], shallowEqual);
    const {id} = image;

    const name = generateImageAuthorName(authorId, image, t);


    return (
        <>
            <Grid container justify={"flex-end"}>
                <IconButton aria-label={t('misc.select')} size={"small"} onClick={close}>
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </Grid>
            <Grid container spacing={5}>
                <Grid item xs={5}>
                    <img alt={name}
                         className={classes.media}
                         src={"/api/image/" + id + "?size=MEDIUM"}
                         title={name}
                    />
                </Grid>
                <Grid item xs={7}>
                    <ImageAuthorForm index={index}
                                     register={register}
                                     errors={errors} control={control}/>
                </Grid>
            </Grid>
        </>
    );
}

export default ImagesFormItemPanel;

import {IconButton} from "@material-ui/core";
import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import {PushOnlineIcon} from "../../commons/icon/icons";
import Auth from "../../Auth";
import {onlineRoles} from "../../../utils/roles";
import {State} from "../../../redux/reducers";
import {ArticleSearchResult, SearchContext} from "../../../types/common-search";
import {ArticleStatus, bundleAttachments} from "../../../types/article";
import {
    pushArticlesOnlineResponseAction
} from "../../../redux/actions/article/article.actions";
import PushOnlineArticleDialog from "./PushOnlineArticleDialog";

const isPushable = article => ((article && article.status === ArticleStatus.READY &&
    (article.isBundle || article.bundleId === null) && (article.isBundle || !bundleAttachments.includes(article.attached)) && !article.nextPublicationOnlineDate))

function PushOnlineMultipleArticles() {
    const {t} = useTranslation()

    const dispatch = useDispatch()

    const [selectedIds, searchResults]: [string[], ArticleSearchResult[]] = useSelector((state: State) => {
        const store = state.search.common[SearchContext.ARTICLE]
        return [store.selectedIds, store.searchResults as ArticleSearchResult[]]
    });

    const shouldDisableButton = useMemo(() => searchResults
        .filter(result => selectedIds.indexOf(result.id) !== -1)
        .some(result => !isPushable(result)), [searchResults, selectedIds])

    return <Auth requiredRoles={onlineRoles}>
        <PushOnlineArticleDialog ids={selectedIds} context={SearchContext.ARTICLE}/>
        <Tooltip
            title={t(shouldDisableButton ? 'articles.cant_push_online' : 'articles.push_online', {count: selectedIds.length}) ?? 'no tooltip available'}>
            <span><IconButton
                disabled={shouldDisableButton}
                color="inherit"
                onClick={() => dispatch(pushArticlesOnlineResponseAction(selectedIds))}>
                <PushOnlineIcon/>
            </IconButton></span>
        </Tooltip>
    </Auth>
}

export default PushOnlineMultipleArticles;

import React from 'react'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {currentAdSelector, currentUploadedAdSelector} from "../../../redux/selectors/ads.selectors";
import {commonStyle} from "../../../styles/common.style";

type Props = {
    form?: boolean
}

export default ({form}: Props) => {
    const {t} = useTranslation();
    const classes = commonStyle();

    const uploadedAd = useSelector(currentUploadedAdSelector)
    const currentAd = useSelector(currentAdSelector)
    const ad = uploadedAd ? uploadedAd : currentAd

    const action = ad.id ? 'misc.edit' : 'ads.new_ad'

    return (
        <Breadcrumbs aria-label="breadcrumb" separator="›" className={classes.root}>
            <Link color="inherit" to={'/' + t('routes.ad.root')} component={RouterLink}>
                {t('app.sections.ads')}
            </Link>

            {ad.id !== '' && <Link color="inherit"
                                   to={'/' + t('routes.ad.root') + '/' + ad.id}
                                   component={RouterLink}>
                {ad.id} {ad['name'] ?? ''}
            </Link>}

            {form && <Typography color="textPrimary">{t(action)}</Typography>}
        </Breadcrumbs>)
}

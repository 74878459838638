import React, { useState } from "react"
import { DocumentUnitContext, 
  SearchContext, 
  StatusToUpdateContext, 
  TreeListContext, 
  TreeTypeAndId } from "../../contexts/organizeTreeBibleContexts"
import { Query, defaultQuery } from "../../models/Query"
import { Pageable } from "../../models/Pageable"
import { DocumentUnit } from "../../models/DocumentUnit"
import { Search, defaultSearch } from "../../models/Search"
import { ChildrenProps } from "../../types"

export const OrganizeTreeBibleProviders = ({ children }: ChildrenProps) => {
  const [searchState, setSearchState] = useState<Search>(defaultSearch)
  const [treeListState, setTreeListState] = useState<Query<TreeTypeAndId[]>>(defaultQuery)
  const [
    documentUnitState,
    setDocumentUnitState
  ] = useState<Query<Pageable<DocumentUnit>>>(defaultQuery)

  const [statusToUpdateState, setStatusToUpdateState] = useState<Record<string, number>>({})


  return (
    <DocumentUnitContext.Provider value={[documentUnitState, setDocumentUnitState]}>
      <SearchContext.Provider value={[searchState, setSearchState]}>
        <TreeListContext.Provider value={[treeListState, setTreeListState]}>
          <StatusToUpdateContext.Provider value = {[statusToUpdateState, setStatusToUpdateState]}>
            {children}
          </StatusToUpdateContext.Provider>
        </TreeListContext.Provider>
      </SearchContext.Provider>
    </DocumentUnitContext.Provider>
  )
}
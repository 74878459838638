import React from "react";
import {useDispatch} from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {SearchContext} from "../../../types/common-search";

type Props = {
    ctx: SearchContext
    values?: boolean[]
    field: string,
    trueLabel: string,
    falseLabel: string,
    addSearchQueryAction: any,
    removeSearchQueryAction: any
}

export default ({ctx, values, trueLabel, falseLabel, addSearchQueryAction, removeSearchQueryAction, field}: Props) => {
    const dispatch = useDispatch();

    const handleChange = (value: boolean, checked: boolean) => {
        if (checked) {
            dispatch(addSearchQueryAction({ctx, field: field, value: value}))
        } else {
            dispatch(removeSearchQueryAction({ctx, field: field, value: value}))
        }
    };

    return (
        <>
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange(true, check)}
                        checked={(values && values.some((e) => e))}
                    />}
                    label={trueLabel}
                />
            </FormGroup>

            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange(false, check)}
                        checked={(values && values.some((e) => !e))}
                    />}
                    label={falseLabel}
                />
            </FormGroup>
        </>
    )
};

import {createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: 'rgba(87, 87, 87, .2)',
            main: '#E71737',
            dark: '#8a261b',
            contrastText: '#FFF',
        },
        secondary: {
            light: '#575757',
            main: '#ffc800',
            dark: '#202020',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(', '),
        fontSize: 12,
        fontWeightRegular: 300,
    },
});

export default theme;

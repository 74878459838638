import {combineReducers} from "redux";
import auth from "./auth";
import notification from "./notification.reducer";
import author from "./author.reducer";
import image from "./image.reducer"
import search from "./search/index";
import autoCompleteReducer from "./auto-complete/index"
import ads from './ad.reducer'
import article from './article'
import createArticle from './article/article-dialog.reducer'
import publication from './publication'
import bundle from './bundle'
import periodical from './periodical.reducer'
import fonto from './fonto.reducer'
import {quickSearch} from "./quick-search.reducer";
import adminPeriodical from "./admin/periodical/admin-periodical.reducer";
import adminPublication from "./admin/publication/admin-publication.reducer";
import adminBundle from "./admin/bundle/admin-bundle.reducer";
import adminCustomBundle from "./admin/bundle/admin-custom-bundle.reducer";
import workflow from "./workflow.reducer"
import adminExport from "./admin/export/admin-export.reducer"
import adminOnlinePublications from "./admin/online/admin-online-publications.reducer"

const root = combineReducers({
    auth,
    notification,
    author,
    image,
    ads,
    fonto,
    workflow,
    article,
    createArticle,
    publication,
    bundle,
    quickSearch,
    search,
    autoCompleteReducer,
    periodical,
    // Admin
    adminPeriodical,
    adminPublication,
    adminExport,
    adminBundle,
    adminCustomBundle,
    adminOnlinePublications
});

export type State = ReturnType<typeof root>
export default root;

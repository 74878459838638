import {Grid, IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {PublicationSearchResult, SearchContext} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {GeneratePdfIcon} from "../../commons/icon/icons";
import {PublicationActionName} from "../../../types/workflow";
import {dispatchPublicationAction} from "../../../redux/actions/workflow.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {uuidv4} from "../../../utils/uuid";
import {processInProgress} from "../../../redux/selectors/workflow.selectors";
import {PublicationStatus} from "../../../types/publication";
import { PdfPublicationProps } from "../../GeneratePlacardButton";


function PdfPublication({publication}: PdfPublicationProps) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const processInProgressSelector = useSelector(processInProgress)
    const [currentProcess, setCurrentProcess] = useState<string>();

    const displayLoading = () => {
        if (currentProcess && processInProgressSelector.includes(currentProcess)) {
            return <CircularProgress color="inherit" size={15} style={{marginTop: 15}}/>
        } else {
            return <IconButton
                onClick={() => generatePlacard(publication.id)}>
                <GeneratePdfIcon/>
            </IconButton>
        }
    }

    const generatePlacard = (publicationId: number) => {
        const uuid = uuidv4();

        dispatch(dispatchPublicationAction({
            id: publicationId,
            action: PublicationActionName.GENERATE_PUBLICATION_PLACARD,
            uuid,
            context: SearchContext.PUBLICATION
        }))
        setCurrentProcess(uuid)

    }
    if (publication.status === PublicationStatus.IN_PROGRESS && publication.lastRequestPlacardProcessStatus === null) {
        return (
            <Grid item>
                <Tooltip
                    title={t('workflows.generate_placard') ?? ''}>
                    {displayLoading()}
                </Tooltip>
            </Grid>
        )
    } else {
        return (<></>)
    }

}

export default PdfPublication;

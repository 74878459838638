import {IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ElementContext, Filters, SearchContext} from "../../../types/common-search";
import {ArticleStatus} from "../../../types/article";
import Tooltip from "@material-ui/core/Tooltip";
import {ADMIN, EDITOR, MANAGER} from "../../../utils/roles";
import {RemoveBapIcon} from "../../commons/icon/icons";
import {ArticleActionName} from "../../../types/workflow";
import {dispatchArticleAction} from "../../../redux/actions/workflow.actions";
import Auth from "../../Auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import {uuidv4} from "../../../utils/uuid";
import {processInProgress} from "../../../redux/selectors/workflow.selectors";
import {BundleResponse} from "../../../types/bundle";


type Props = {
    bundle: BundleResponse,
    context?: SearchContext | ElementContext,
    forceOptions?: Filters,
    size?: 'small' | 'medium',
    buttonClassName?: any
}

function RevertBapBundle({bundle, context, forceOptions, size, buttonClassName}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const processInProgressSelector = useSelector(processInProgress)
    const [currentProcess, setCurrentProcess] = useState<string>();

    const displayLoading = () => {
        if (currentProcess && processInProgressSelector.includes(currentProcess)) {
            return <CircularProgress color="inherit" size={size && size == 'small' ? 10 : 15}
                                     style={size === undefined || size === 'medium' ? {marginTop: 15} : {}}/>
        } else {
            return <IconButton size={size}
                               className={buttonClassName}
                               onClick={() => revert_bap()}>
                <RemoveBapIcon/>
            </IconButton>
        }
    }

    const revert_bap = () => {
        const uuid = uuidv4();
        dispatch(dispatchArticleAction({
            id: bundle.id!!,
            action: ArticleActionName.UPDATE_ARTICLE,
            uuid,
            context,
            forceOptions
        }))
        setCurrentProcess(uuid)
    }

    if (bundle && bundle.status === ArticleStatus.READY) {
        return (
            <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}
                  periodicalRestriction={bundle.periodical}>
                <Tooltip
                    title={t('bundles.remove_bap') ?? ''}>
                    {displayLoading()}
                </Tooltip>
            </Auth>

        )
    } else {
        return (<></>)
    }
}

export default RevertBapBundle;

import React from "react";
import ListItem from "../../commons/ListItem";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";

const AutoCompleteFilter = ({ctx, options, filters, selectedAction, filterName, label}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value) => {
        if (value === null) {
            dispatch(selectedAction({ctx, field: filterName, value: []}))
        } else if (Array.isArray(value)) {
            dispatch(selectedAction({ctx, field: filterName, value: value.map(e => e.value)}))
        } else {
            dispatch(selectedAction({ctx, field: filterName, value: [value.value]}))
        }
    };

    const useStyles = makeStyles(() => ({
        select: {
            marginTop: 20
        }
    }))

    const classes = useStyles();


    return (
        <ListItem label={label}>
            <FormControl className={classes.select} fullWidth={true}>
                <Autocomplete
                    openText={t('misc.open')}
                    clearText={t('misc.clear')}
                    closeText={t('misc.close')}
                    loadingText={t('misc.loading')}
                    noOptionsText={t('misc.no-options')}
                    multiple color={"secondary"}
                    options={options ?? [{label: "", value: ""}]}
                    disableCloseOnSelect
                    value={options.filter(x => filters?.includes(x.value))}
                    onChange={(event: any, newValue) => handleChange(event, newValue)}
                    getOptionLabel={(option) => option.label}
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <Checkbox
                                checked={selected}
                            />
                            {option.label}
                        </React.Fragment>
                    )}
                    renderInput={(params) => (
                        <TextField {...params} color={"secondary"} variant="outlined" label={label}/>
                    )}
                />
            </FormControl>
        </ListItem>
    )
}

export default AutoCompleteFilter

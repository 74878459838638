import {combineEpics} from 'redux-observable';
import {
    deleteOnlinePublication,
    loadOnlinePublications,
    pushArticlesOnlineNow
} from "./admin/online/admin-online-publications.epics";
import {
    deleteAuthor,
    deleteOrDisableAuthorImages,
    enableAuthorImage,
    fetchAuthor,
    redirectAfterSaveAuthor,
    refreshAuthorListOnDeleteSuccess,
    saveAuthor,
    toggleAuthorEnabled
} from "./author.epics";
import {
    actionAfterSaveArticle,
    copyArticleEpic,
    deleteArticleEpic,
    deleteArticlesEpic,
    fetchArticleEpic,
    generateNewArticleFormEpic,
    loadBundlesOnPeriodicalSelection,
    loadBundlesOnPublicationSelection,
    loadBundlesWhenPrefilled,
    loadContainerOnBundleChange,
    loadDatasOnDefaultPeriodicalSelection,
    loadPlansOnPeriodicalSelection,
    loadPlansOnPublicationSelection,
    loadPublicationsOnPeriodicalSelection,
    moveArticleEpic,
    moveArticlesEpic,
    redirectToEditArticle,
    saveArticleEpic,
    toggleArticleCoverEpic,
    updateBundleOnCoverToggle,
    uploadArticleEpic,
    fetchArticleTagsEpic,
    pushArticlesOnline
} from "./articles.epics";
import {
    autoComplete,
    autoCompletePublications,
    fetchSearchResultPageOnSearchChange,
    initLocationWithCollection,
    initLocationWithPeriodical,
    reloadSearch,
    resetQueryParam,
    updateLocationOnAddElementQueryParamChange,
    updateLocationOnQueryParamChange,
    updateLocationOnRemoveElementQueryParamChange
} from "./search/common-search.epics";
import {autoCompleteAuthors} from "./search/autocomplete-authors.epics"
import {autoCompletePlanNodes} from "./search/autocomplete-plan-nodes.epics"
import {initFontoSession} from "./fonto.epics";
import {
    deleteIllustrationEpic,
    deleteManyIllustrationEpic,
    fetchImageEpic,
    redirectOnSaveImage,
    replaceAdImageEpic,
    replaceIllustrationEpic,
    saveIllustrationEpic,
    searchImagesEpic,
    toggleIllustrationDisabledEpic,
    uploadAdImageEpic,
    uploadAuthorImageEpic,
    uploadImageEpic
} from "./image.epics"
import {fetchPeriodicalEpic} from "./periodical.epics";
import {
    fetchCountQuickSearch,
    fetchQuickSearchProposalsOnType,
    redirectOnQuickSearchProposalSelected
} from "./quick-search.epics";
import {pushBackRoute, redirectEpic} from "./router.epics";
import {
    deleteAdEpic,
    deleteManyAdsEpic,
    fetchAdConfigEpic,
    fetchAdEpic,
    fetchAdSizesEpic,
    redirectOnSaveAds,
    saveAdEpic,
    toggleAdDisabledEpic,
    toggleManyAdDisabledEpic
} from "./ad.epics";
import {
    fetchPublicationEpic,
    fetchPublicationPlanEpic,
    savePublicationPlanEpic,
    uploadPublicationsEpic
} from "./publication.epics";
import {
    actionAfterSaveBundle,
    createBundleEpic,
    fetchAllBundlePlansEpic,
    fetchBundleEpic,
    organizeBundleEpic
} from "./bundle.epics";
import {
    createPublicationEpic,
    editPublicationEpic,
    generateNewPublicationFormEpic,
    loadEditPublicationFormEpic
} from "./publication/new-publication.epics";
import {loadAdminPlanForPeriodical, saveAdminPlanForPeriodical} from "./admin/periodical/admin-periodical.epics";

import {
    downloadExcelFile,
    exportPublishedElements,
    exportReferences,
    exportSpecificAuthors
} from "./admin/export/admin-export.epics";

import {
    dispacthArticleWorkflowAction,
    dispacthMultipleArticlesWorkflowAction,
    dispacthMultiplePublicationsWorkflowAction,
    dispacthPublicationWorkflowAction, redirectOnClosePublicationAction,
    refreshArticleOnWorkflowSuccess,
    refreshPublicationOnWorkflowSuccess,
    redirectOnPlacardRequestOnFontoPage
} from "./workflow.epics"
import {
    deleteAdminBundlePlan,
    loadAdminBundlePlan,
    loadAdminBundlePlans,
    saveAdminBundlePlan
} from "./admin/bundle/admin-bundle.epics";
import {loadAdminCustomBundlePlan, saveAdminCustomBundlePlan} from "./admin/bundle/admin-custom-bundle.epics";
import {uploadReferentialEpic} from './admin/referential/referential.epics';
import {saveAdminPlanForPublication} from "./admin/publication/admin-publication.epics";

export default combineEpics(
    //router
    redirectOnQuickSearchProposalSelected,
    pushBackRoute,
    redirectEpic,

    //workflow
    dispacthArticleWorkflowAction,
    dispacthPublicationWorkflowAction,
    dispacthMultiplePublicationsWorkflowAction,
    dispacthMultipleArticlesWorkflowAction,
    refreshArticleOnWorkflowSuccess,
    refreshPublicationOnWorkflowSuccess,
    redirectOnPlacardRequestOnFontoPage,

    //search
    updateLocationOnQueryParamChange,
    updateLocationOnAddElementQueryParamChange,
    updateLocationOnRemoveElementQueryParamChange,
    resetQueryParam,
    autoComplete,
    fetchQuickSearchProposalsOnType,
    fetchCountQuickSearch,
    fetchSearchResultPageOnSearchChange,
    initLocationWithPeriodical,
    initLocationWithCollection,
    reloadSearch,
    autoCompletePublications,

    //autocomplete authors
    autoCompleteAuthors,
    //autocomplete plan nodes
    autoCompletePlanNodes,

    //author
    fetchAuthor,
    saveAuthor,
    deleteAuthor,
    uploadAuthorImageEpic,
    toggleAuthorEnabled,
    refreshAuthorListOnDeleteSuccess,
    redirectAfterSaveAuthor,
    deleteOrDisableAuthorImages,
    enableAuthorImage,

    //ads
    fetchAdEpic,
    fetchAdSizesEpic,
    fetchAdConfigEpic,
    saveAdEpic,
    deleteAdEpic,
    uploadAdImageEpic,
    replaceAdImageEpic,
    toggleManyAdDisabledEpic,
    toggleAdDisabledEpic,
    deleteManyAdsEpic,
    fetchPeriodicalEpic,
    redirectOnSaveAds,

    //articles
    updateBundleOnCoverToggle,
    saveArticleEpic,
    redirectToEditArticle,
    actionAfterSaveArticle,
    loadBundlesOnPeriodicalSelection,
    loadBundlesOnPublicationSelection,
    loadBundlesWhenPrefilled,
    toggleArticleCoverEpic,
    loadContainerOnBundleChange,
    deleteArticleEpic,
    deleteArticlesEpic,
    copyArticleEpic,
    moveArticleEpic,
    moveArticlesEpic,
    fetchArticleEpic,
    loadDatasOnDefaultPeriodicalSelection,
    generateNewArticleFormEpic,
    uploadArticleEpic,
    fetchArticleTagsEpic,
    pushArticlesOnline,

    //image
    uploadImageEpic,
    searchImagesEpic,
    fetchImageEpic,

    //illustration
    replaceIllustrationEpic,
    saveIllustrationEpic,
    deleteIllustrationEpic,
    deleteManyIllustrationEpic,
    toggleIllustrationDisabledEpic,
    redirectOnSaveImage,


    //publication
    generateNewPublicationFormEpic,
    createPublicationEpic,
    fetchPublicationEpic,
    fetchPublicationPlanEpic,
    savePublicationPlanEpic,
    loadPublicationsOnPeriodicalSelection,
    loadEditPublicationFormEpic,
    editPublicationEpic,
    uploadPublicationsEpic,
    redirectOnClosePublicationAction,

    //publication plan
    loadPlansOnPeriodicalSelection,
    loadPlansOnPublicationSelection,

    //fonto
    initFontoSession,

    //bundle
    fetchAllBundlePlansEpic,
    fetchBundleEpic,
    organizeBundleEpic,
    createBundleEpic,
    actionAfterSaveBundle,

    // Admin
    loadAdminPlanForPeriodical,
    loadAdminBundlePlans,
    loadAdminBundlePlan,
    saveAdminBundlePlan,
    deleteAdminBundlePlan,
    saveAdminPlanForPeriodical,
    saveAdminPlanForPublication,
    saveAdminCustomBundlePlan,
    loadAdminCustomBundlePlan,
    uploadReferentialEpic,
    exportSpecificAuthors,
    exportReferences,
    downloadExcelFile,
    exportPublishedElements,
    loadOnlinePublications,
    deleteOnlinePublication,
    pushArticlesOnlineNow
);

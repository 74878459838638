import React from "react";
import {ClearIcon} from "../../../../icon";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import PeriodicalAutoComplete from "../../../authors/list/PeriodicalAutoComplete";
import ListItem from "../../../commons/ListItem";
import {BundlePlanSearchContext} from "../../../../types/bundle";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setAdminBundlePlansSearchContext} from "../../../../redux/actions/admin/bundle/admin-bundle.actions";

type Props = {
    bundlePlanSearchContext: BundlePlanSearchContext
}

const useStyles = makeStyles(() => ({
    paper: {
        borderBottom: "none"
    }
}))

const BundlePlanListFilter = ({bundlePlanSearchContext}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const updatePeriodical = (value: string[]) => {
        dispatch(setAdminBundlePlansSearchContext({...bundlePlanSearchContext, periodicalId: value}));
    }

    const emptySearchContext = {
        ...bundlePlanSearchContext,
        periodicalId: []
    };

    const resetSearchContext = () => dispatch(setAdminBundlePlansSearchContext((emptySearchContext as BundlePlanSearchContext)));

    return (
        <>
            <Button onClick={resetSearchContext}
                    startIcon={<ClearIcon/>}>Réinitialiser</Button>

            <Paper variant="outlined" square className={classes.paper}>
                <List component="div" disablePadding>
                    <ListItem label={t('misc.entities.periodical')}>
                        <PeriodicalAutoComplete selectedOptions={bundlePlanSearchContext.periodicalId}
                                                selectedAction={updatePeriodical}
                                                label={t('misc.entities.periodical')}/>
                    </ListItem>
                </List>
            </Paper>
        </>
    );
}

export default BundlePlanListFilter

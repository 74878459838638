import React, { useState } from "react"
import { ChildrenProps } from "../types"
import { KyrTopBar } from "../components/KyrTopBar"
import { TreeContext } from "../contexts/organizeTreeBibleContexts"
import { Query, defaultQuery } from "../models/Query"
import { TreeModel } from "../models/TreeModels"
import KyrReactRouterPrompt from "../components/KyrReactRouterPrompt"
import './global.css'
import { useSetFaviconAndTabeLabel, useSetRootHeightTo100vh } from "../hooks/UIhooks"

export const KyrLayout = ({ children }: ChildrenProps) => {
  useSetFaviconAndTabeLabel()

  const [treeState, setTreeState] = useState<Query<TreeModel>>(defaultQuery)

  return (
    <TreeContext.Provider value={[treeState, setTreeState]}>
      <KyrReactRouterPrompt />
      <KyrTopBar />
      {children}
    </TreeContext.Provider>
  )
}

import {createActionWithoutPayload, createActionWithPayload} from "../../utils.actions";
import {PlanNode, PlanNodeCollection} from "../../../../types/plan-node";
import {Periodical} from "../../../../types/periodical";

export const [setAdminPeriodical, isSetAdminPeriodicalAction] = createActionWithPayload<Periodical>('ADMIN_SET_PERIODICAL')
export const [resetAdminPeriodical, isResetAdminPeriodicalAction] = createActionWithoutPayload('ADMIN_RESET_PERIODICAL')
export const [setAdminPeriodicalPlanCollection, isSetAdminPeriodicalPlanCollectionAction] = createActionWithPayload<PlanNodeCollection>('ADMIN_SET_PERIODICAL_PLAN')
export const [setAdminPeriodicalPlan, isSetAdminPeriodicalPlanAction] = createActionWithPayload<PlanNode[]>('ADMIN_SET_PERIODICAL_PLAN_COLLECTION')
export const [setDirty, isSetDirty] = createActionWithPayload<boolean>('ADMIN_SET_PERIODICAL_DIRTY')

export const [saveAdminPeriodicalPlan, isSaveAdminPeriodicalPlan] = createActionWithPayload<PlanNodeCollection>('ADMIN_SAVE_PERIODICAL_PLAN')

import {AxiosStatic} from "axios";
import {EMPTY, merge, Observable, of} from "rxjs";
import {Action, ActionWithPayload} from "../../../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, concatMap, filter, map, mergeMap} from "rxjs/operators";
import {
    exportAuthorsError,
    exportAuthorsSucces,
    exportPublishedElementsError,
    exportPublishedElementsSucces,
    exportReferencesError,
    exportReferencesSucces,
    isExportAuthors,
    isExportAuthorsSuccess,
    isExportPublishedElements,
    isExportReferences,
    isExportReferencesSuccess
} from "../../../actions/admin/export/admin-export.actions";
import {StateObservable} from "redux-observable";
import {State} from "../../../reducers";
import {EpicDependencies} from "../../../store";
import {notify, notifyError} from "../../../actions/notification.actions";
import {i18n} from "i18next";

function _exportAuthors(axios: AxiosStatic, token: string, periodicalId: string, startDate: string, endDate: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/admin/export/authors`, {periodicalId, startDate, endDate}, {
        headers,
        responseType: 'blob'
    }))
        .pipe(
            map((response) => {
                    const fileName = `authors_${periodicalId}_${new Date(startDate!!).toLocaleDateString()}_${new Date(endDate!!).toLocaleDateString()}.xlsx`
                    return exportAuthorsSucces({blob: response.data, filename: fileName})
                }
            ),
            catchError(err => merge(
                of(exportAuthorsError(err.response.message)),
                of(notifyError(+err.response.status, {type: 'EXPORT'}))
                )
            )
        )
}

function _exportReferences(axios: AxiosStatic, token: string, periodicalId: string, startDate: string, endDate: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/admin/export/references`, {periodicalId, startDate, endDate}, {
        headers,
        responseType: 'blob'
    }))
        .pipe(
            map((response) => {
                    const fileName = `references_${periodicalId}_${new Date(startDate!!).toLocaleDateString()}_${new Date(endDate!!).toLocaleDateString()}.csv`
                    return exportReferencesSucces({blob: response.data, filename: fileName})
                }
            ),
            catchError(err => merge(
                of(exportReferencesError(err.response.message)),
                of(notifyError(+err.response.status, {type: 'EXPORT'}))
                )
            )
        )
}

function _exportPublishedElements(axios: AxiosStatic, i18n: i18n, token: string, periodicalId: string, startDate: string, endDate: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/admin/export/publication`, {periodicalId, startDate, endDate}, {
        headers,
        responseType: 'blob'
    }))
        .pipe(
            mergeMap(_ => merge(of(
                exportPublishedElementsSucces(),
                notify(i18n.t('admin.export.published_elements_success'), {variant: 'success'})
            ))),
            catchError(err => merge(
                of(exportPublishedElementsError(err.response.message)),
                of(notifyError(+err.response.status, {type: 'EXPORT'}))
                )
            )
        )
}

const downloadFileSuccess = (action,): action is ActionWithPayload => isExportAuthorsSuccess(action)
    || isExportReferencesSuccess(action)

export function downloadExcelFile(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    action$.pipe(
        filter(downloadFileSuccess)
    ).subscribe(({payload}) => {
        const downloadLink = window.document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([payload.blob], {type: "application/octet-stream"}));
        downloadLink.download = payload.filename;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    })
    return EMPTY
}

export function exportPublishedElements(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;

    return action$.pipe(
        filter(isExportPublishedElements),
        concatMap((action) => {
            return _exportPublishedElements(axios, i18n, state$.value.auth.token ?? "",
                action.payload.periodicalId,
                action.payload.startDate,
                action.payload.endDate)
        })
    )
}

export function exportSpecificAuthors(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isExportAuthors),
        concatMap((action) => {
            return _exportAuthors(axios, state$.value.auth.token ?? "",
                action.payload.periodicalId,
                action.payload.startDate,
                action.payload.endDate)
        })
    )
}

export function exportReferences(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isExportReferences),
        concatMap((action) => {
            return _exportReferences(axios, state$.value.auth.token ?? "",
                action.payload.periodicalId,
                action.payload.startDate,
                action.payload.endDate)
        })
    )
}

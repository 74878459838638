import React from "react";
import { AuthorPhoneNumber } from "../../../types/author";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import FormLabel from "@material-ui/core/FormLabel";
import { FavIcon } from "../../../icon";
import PhoneNumber from "../commons/PhoneNumber";

type Props = {
    phoneNumbers: AuthorPhoneNumber[]
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: 30
        },
        divider: {
            marginTop: 30,
            height: 1,
            marginBottom: 20
        },
        favorite: {
            color: "#ffbe00"
        },
        flexCenter: {
            display: 'flex',
            alignItems: 'center'
        },
        fav: {
            maxWidth: '18px',
            marginRight: theme.spacing(1),
        }
    }),
);


export default ({ phoneNumbers }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={2}>
                <FormLabel>
                    {t('authors.phoneNumbers')}
                </FormLabel>
            </Grid>
            <Grid item xs={10}>
                {phoneNumbers.map((phone, index) => (
                    <Grid container spacing={2} key={index}>
                        <Grid item xs={1} className={`${classes.fav} ${classes.flexCenter}`}>
                            {phone.fav && (<FavIcon fontSize={"small"} className={classes.favorite} />)}
                        </Grid>
                        <Grid item xs={11} className={classes.flexCenter}>
                            <PhoneNumber phone={phone} />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

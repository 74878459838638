import React from 'react';
import { Redirect, Route, Switch, useLocation, } from "react-router-dom";

import Access from "../components/Access";
import { useTranslation } from "react-i18next";
import EditAuthorView from "../components/authors/edit/AuthorForm";
import AuthorView from "../components/authors/show/ShowAuthorView";
import ListAuthorView from "../components/authors/list/ListAuthorView";
import ImagesRoot from "../components/images/ImagesRoot";
import ImageForm from "../components/images/edit/ImageForm";
import AdsRoot from "../components/ads/AdsRoot";
import AdsForm from "../components/ads/edit/AdsForm";
import { ImageView } from "../components/images/show/ImageView";
import { AdsView } from "../components/ads/show/AdsView";
import Auth from "../components/Auth";
import { ADMIN, ADMIN_ADS, ADMIN_IMAGES } from "../utils/roles";
import PublicationsRoot from "../components/publications/PublicationsRoot";
import ArticlesRoot from "../components/articles/ArticlesRoot";
import Fonto from "../components/fonto/Fonto";
import { ArticleView } from "../components/articles/show/ArticleView";
import PublicationOrganizeRoot from "../components/publications/organize/PublicationOrganizeRoot";
import BundleOrganizeRoot from "../components/bundle/organize/BundleOrganizeRoot";
import { BundleView } from "../components/bundle/show/BundleView";
import FixedActionButtonList from "../components/appbar/FixedActionButtonList";
import PeriodicalOrganizeRoot from "../components/admin/periodical/organize/PeriodicalOrganizeRoot";
import BundlePlanListRoot from "../components/admin/bundle-plan/list/BundlePlanListRoot";
import BundlePlanEditRoot from "../components/admin/bundle-plan/edit/BundlePlanEditRoot";
import ReferentialAdminRoot from "../components/admin/referential/ReferentialAdminRoot";
import GlobalExportRoot from "../components/admin/export/GlobalExportRoot";
import BundleEditRoot from "../components/admin/bundle/edit/BundleEditRoot";
import PublicationPlanAdministrateRoot
  from "../components/admin/publication/administrate/PublicationPlanAdministrateRoot";
import PushOnlineConfigurationAdminRoot
  from "../components/admin/push-online-configuration/PushOnlineConfigurationAdminRoot";
import { OrganizeTree } from "../kyrielle/pages/organizeTree/OrganizeTree";
import { AdministrateTree } from '../kyrielle/pages/administrateTree/AdministrateTree';
import { KyrLayout } from '../kyrielle/global/KyrLayout';
import { OrganizeTreeBibleProviders } from '../kyrielle/pages/organizeTree/OrganizeTreeProvider';
import { AdministrateTreeProviders } from '../kyrielle/pages/administrateTree/AdministrateTreeProviders';

const Root = () => {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={`/${t('routes.article.root')}`} />
      </Route>

      <Route exact path={`/${t('routes.access')}`}>
        <Access />
      </Route>

      {/* ADMIN */}
      <Route exact
        path={`/${t("routes.admin.root")}/${t('routes.admin.periodical.root')}/${t('routes.admin.periodical.organize')}`}>
        <PeriodicalOrganizeRoot />
      </Route>
      <Route exact path={`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}`}>
        <BundlePlanListRoot />
      </Route>
      <Route exact
        path={`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}/${t('routes.admin.bundle.edit')}/:id`}>
        <BundlePlanEditRoot />
      </Route>
      <Route exact
        path={`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}/${t('routes.admin.bundle.duplicate')}/:id`}>
        <BundlePlanEditRoot />
      </Route>
      <Route exact
        path={`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}/${t('routes.admin.bundle.create')}`}>
        <BundlePlanEditRoot />
      </Route>
      <Route exact
        path={`/${t("routes.admin.root")}/${t('routes.admin.bundle.custom')}/${t('routes.admin.bundle.edit')}/:id`}>
        <BundleEditRoot />
      </Route>
      <Route exact path={`/${t("routes.admin.root")}/${t("routes.admin.referential.root")}`}>
        <ReferentialAdminRoot />
      </Route>
      <Route exact path={`/${t("routes.admin.root")}/${t('routes.admin.export.root')}`}>
        <GlobalExportRoot />
      </Route>
      <Route exact path={`/${t('routes.admin.root')}/` +
        `${t('routes.admin.administrate_publication_plan.root')}/` +
        `${t('routes.admin.administrate_publication_plan.administrate')}/` +
        `:id`}>
        <PublicationPlanAdministrateRoot />
      </Route>

      <Route exact path={`/${t('routes.admin.root')}/${t('routes.admin.administrate-push-online')}`}>
        <PushOnlineConfigurationAdminRoot />
      </Route>

      {/* AUTHORS */}
      <Route exact path={`/${t('routes.author.root')}`}>
        <ListAuthorView />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.author.root')}/${t('routes.author.create')}`}>
        <EditAuthorView />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.author.root')}/:id`}>
        <AuthorView />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.author.root')}/:id/${t('routes.author.edit')}`}>
        <EditAuthorView />
        <FixedActionButtonList />
      </Route>

      {/* IMAGES */}
      <Route exact path={`/${t('routes.image.root')}`}>
        <ImagesRoot />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.image.root')}/${t('routes.image.create')}`}>
        <Auth requiredRoles={[ADMIN, ADMIN_IMAGES]} otherwise={<Redirect to={`/${t('routes.image.root')}`} />}>
          <ImageForm />
          <FixedActionButtonList />
        </Auth>
      </Route>
      <Route exact path={`/${t('routes.image.root')}/:id`}>
        <ImageView />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.image.root')}/:id/${t('routes.image.edit')}`}>
        <Auth requiredRoles={[ADMIN, ADMIN_IMAGES]} otherwise={<ImageView />}>
          <ImageForm />
          <FixedActionButtonList />
        </Auth>
      </Route>

      {/* ADS */}
      <Route exact path={`/${t('routes.ad.root')}`}>
        <AdsRoot />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.ad.root')}/${t('routes.ad.create')}`}>
        <Auth requiredRoles={[ADMIN, ADMIN_ADS]} otherwise={<Redirect to={`/${t('routes.ad.root')}`} />}>
          <AdsForm />
          <FixedActionButtonList />
        </Auth>
      </Route>
      <Route exact path={`/${t('routes.ad.root')}/:id`}>
        <AdsView />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.ad.root')}/:id/${t('routes.ad.edit')}`}>
        <Auth requiredRoles={[ADMIN, ADMIN_ADS]} otherwise={<AdsView />}>
          <AdsForm />
          <FixedActionButtonList />
        </Auth>
      </Route>

      {/* PUBLICATIONS */}
      <Route exact path={`/${t('routes.publication.root')}`}>
        <PublicationsRoot />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.publication.root')}/:id/${t('routes.publication.organize')}`}>
        <PublicationOrganizeRoot />
        <FixedActionButtonList />
      </Route>

      {/* ARTICLES */}
      <Route exact path={`/${t('routes.article.root')}`}>
        <ArticlesRoot />
        <FixedActionButtonList />
      </Route>
      <Route exact path={`/${t('routes.article.root')}/:id`}>
        <ArticleView />
        <FixedActionButtonList />
      </Route>

      <Route exact path={`/${t('routes.article.root')}/:id/${t('routes.article.edit')}`}>
        <Fonto isBundle={false} />
      </Route>

      {/* BUNDLES */}
      <Route exact path={`/${t('routes.bundle.root')}/:id/${t('routes.bundle.organize')}`}>
        <BundleOrganizeRoot />
      </Route>
      <Route exact path={`/${t('routes.bundle.root')}/:id`}>
        <BundleView />
      </Route>
      <Route exact path={`/${t('routes.bundle.root')}/:id/${t('routes.article.edit')}`}>
        <Fonto isBundle={true} />
      </Route>

      {/* KYRIELLE */}
      <Route exact path="/kyrielle/organizeTree/:id?">
        <KyrLayout>
          <OrganizeTreeBibleProviders>
            <OrganizeTree />
          </OrganizeTreeBibleProviders>
        </KyrLayout>
      </Route>
      <Route exact path="/kyrielle/administrateTree/:id">
        <KyrLayout>
          <AdministrateTreeProviders>
            <AdministrateTree />
          </AdministrateTreeProviders>
        </KyrLayout>
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default Root;


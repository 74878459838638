import React from 'react';
import {SvgIconProps} from "@material-ui/core";
import {ReactComponent as EnableSvg} from './svg/action_gris_activer.svg';
import {ReactComponent as SetFrontCover} from './svg/action_gris_ajouter-couv.svg';
import {ReactComponent as UnsetFrontCover} from './svg/action_gris_retirer-couv.svg';
import {ReactComponent as CopySvg} from './svg/action_gris_copier.svg';
import {ReactComponent as CopyToWordSvg} from './svg/action_gris_copier-word.svg';
import {ReactComponent as MoveSvg} from './svg/action_gris_deplacer.svg';
import {ReactComponent as DisableSvg} from './svg/action_gris_desactiver.svg';
import {ReactComponent as EditSvg} from './svg/action_gris_editer.svg';
import {ReactComponent as UnlockSvg} from './svg/action_gris_deverrouiller-placard.svg';
import {ReactComponent as GeneratePdfSvg} from './svg/action_gris_generer-placard.svg';
import {ReactComponent as BundleSvg} from './svg/action_gris_lot.svg';
import {ReactComponent as AddBapSvg} from './svg/action_gris_mettre-en-bap.svg';
import {ReactComponent as OrganizeBundleSvg} from './svg/action_gris_organiser-lot.svg';
import {ReactComponent as PushOnlineSvg} from './svg/action_gris_pousser-en-ligne.svg';
import {ReactComponent as RemoveBapSvg} from './svg/action_gris_retirer-bap.svg';
import {ReactComponent as DeleteSvg} from './svg/action_gris_supprimer.svg';
import {ReactComponent as DownloadSvg} from './svg/action_gris_telecharger.svg';
import {ReactComponent as ShowMoreSvg} from './svg/action_gris_voir-plus.svg';
import {ReactComponent as ShowXmlSvg} from './svg/action_gris_voir_xml.svg';
import {ReactComponent as AttachedPlan} from './svg/statut_gris_lie-cdf.svg';
import {ReactComponent as AttachedBundle} from './svg/statut_gris_lie-lot.svg';
import {ReactComponent as FrontCover} from './svg/statut_vert_couv.svg';
import {ReactComponent as DetachedPlan} from './svg/CdF_gris_retirer-structure.svg';

// todo
import {ReactComponent as AddToPlanSvg} from './svg/CdF_gris_ajouter-structure.svg';
// import {ReactComponent as RemoveContainerSvg} from './svg/CdF_gris_retirer-structure.svg';
import {ReactComponent as RequireBatSvg} from './svg/CdF_orange_demander-bat.svg';
import {ReactComponent as InvalidateBatSvg} from './svg/CdF_rouge_invalider-bat.svg';
import {ReactComponent as ConfirmBatSvg} from './svg/CdF_vert_valider-bat.svg';
import {ReactComponent as OnlineTemp} from './svg/online_temp.svg';
import {ReactComponent as ComplexSvg} from './svg/statut_gris_complexe.svg';
// import {ReactComponent as PublicationLinkSvg} from './svg/statut_gris_lie-cdf.svg';
// import {ReactComponent as BundleLinkSvg} from './svg/statut_gris_lie-lot.svg';
import {ReactComponent as PublishedSvg} from './svg/statut_orange_en-publication.svg';
import {ReactComponent as ScheduledSvg} from './svg/planification_parution.svg'
import {ReactComponent as GeneratingPdfSvg} from './svg/statut_orange_placard-en-generation.svg';
// import {ReactComponent as ArchivedSvg} from './svg/statut_rouge_archive.svg';
import {ReactComponent as InvalidDoc} from './svg/statut_rouge_doc-invalide.svg';
// import {ReactComponent as FirstPageSvg} from './svg/statut_vert_couv.svg';
import {ReactComponent as ReadyStatusSvg} from './svg/statut_vert_en-bap.svg';
import {ReactComponent as OnlineSvg} from './svg/statut_vert_mis-en-ligne.svg';
import {ReactComponent as ShowPdfSvg} from './svg/statut_vert_voir-placard.svg';
// same than existing
//import {ReactComponent as DisableSvg} from './svg/statut_rouge_desactive.svg';
//import {ReactComponent as Svg} from './svg/statut_vert_doc-en-prepa_jamais-affiche.svg';
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as HelpSvg} from './svg/icon-help.svg';


export function AddToPlanIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={AddToPlanSvg} htmlColor={"grey"}/>
    );
}

export function HelpIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={HelpSvg} htmlColor={"white"}/>
    );
}

export function FrontCoverIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={FrontCover} htmlColor={"green"}/>
    );
}

export function OnlineTempIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} viewBox="0 0 19 19" {...props} component={OnlineTemp} htmlColor={"orange"}/>
    );
}

export function AttachedBundleIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={AttachedBundle}/>
    );
}

export function DetachedPlanIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={DetachedPlan}/>
    );
}

export function AttachedPlanIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={AttachedPlan}/>
    );
}

export function EnableIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={EnableSvg}/>
    );
}

export function SetFrontCoverIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={SetFrontCover}/>
    );
}

export function CopyIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={CopySvg}/>
    );
}

export function CopyToWordIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={CopyToWordSvg}/>
    );
}

export function MoveIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={MoveSvg}/>
    );
}

export function DisableIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={DisableSvg}/>
    );
}

export function EditIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={EditSvg}/>
    );
}

export function UnlockIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={UnlockSvg}/>
    );
}

export function GreenUnlockIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={UnlockSvg} htmlColor={"green"}/>
    );
}

export function InvalidDocIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={InvalidDoc} htmlColor={"red"}/>
    );
}

export function LockIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={PublishedSvg} htmlColor={"orange"}/>
    );
}

export function ScheduledIcon(props: SvgIconProps) {
	return (
		<SvgIcon className="lex-icon" {...props} viewBox="0 0 19 19" component={ScheduledSvg} htmlColor="orange"/>
	)
}

export function ArchiveIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={PublishedSvg} htmlColor={"red"}/>
    );
}

export function GeneratePdfIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={GeneratePdfSvg}/>
    );
}

export function GeneratingPdfIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={GeneratingPdfSvg} htmlColor={"orange"}/>
    );
}

export function ShowPdfIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={ShowPdfSvg} htmlColor={"green"}/>
    );
}

export function ShowObsoletePdfIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={ShowPdfSvg} htmlColor={"Gray"}/>
    );
}

export function ShowBluePdfIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={ShowPdfSvg} htmlColor={"blue"}/>
    );
}

export function BundleIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={BundleSvg}/>
    );
}

export function AddBapIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={AddBapSvg}/>
    );
}

export function ReadyStatusIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={ReadyStatusSvg} htmlColor={"green"}/>
    );
}

export function OrganizeBundleIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={OrganizeBundleSvg}/>
    );
}

export function PushOnlineIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={PushOnlineSvg}/>
    );
}

export function OnlineIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={OnlineSvg}/>
    );
}

export function RemoveBapIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={RemoveBapSvg}/>
    );
}

export function UnsetFrontCoverIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={UnsetFrontCover}/>
    );
}

export function DeleteIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={DeleteSvg}/>
    );
}

export function DownloadIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={DownloadSvg}/>
    );
}

export function ShowMoreIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={ShowMoreSvg} htmlColor={"grey"}/>
    );
}

export function RequireBatIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} style={{'width': '40px'}} component={RequireBatSvg}
                 htmlColor={"orange"}/>
    );
}

export function ConfirmBatIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} style={{'width': '40px'}} component={ConfirmBatSvg}
                 htmlColor={"green"}/>
    );
}

export function ComplexeIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={ComplexSvg}/>
    );
}

export function InvalidateBatIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} style={{'width': '40px'}} component={InvalidateBatSvg}
                 htmlColor={"red"}/>
    );
}

export function ShowXmlIcon(props: SvgIconProps) {
    return (
        <SvgIcon className={"lex-icon"} {...props} component={ShowXmlSvg} htmlColor={"grey"}/>
    );
}

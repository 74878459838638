import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import ImageAuthor from "../commons/ImageAuthor";
import PlusImageAuthor from "../edit/image/AddImageAuthor";
import { State } from "../../../redux/reducers";
import { DeleteIcon, DownloadIcon } from "../../../icon";
import ListItem from "../../commons/ListItem";
import List from "@material-ui/core/List";
import { selectImages } from "../../../redux/actions/author.actions"
import Checkbox from "@material-ui/core/Checkbox";
import { downloadAuthorImages } from "../../../utils/common-utils";
import { EditableAuthor, PersistedAuthor } from "../../../types/author";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 0',
        borderBottom: 'none'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    labelSelectCount: {
        padding: '12px 0'
    },
    content: {
        marginTop: theme.spacing(3)
    }
}));

type Props = {
    edit?: (id: string) => () => void
    setFav?: (idx: number) => () => void
    remove?: (idx: number) => () => void
    removeAll?: () => void,
    displayDisableImages?: boolean
}

const ImagesAuthorPanel: React.FunctionComponent<Props> = ({
    displayDisableImages,
    children,
    edit,
    setFav,
    remove,
    removeAll
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [author, selected]: [EditableAuthor | PersistedAuthor, number[]] = useSelector(({ author }: State) => [author.current!, author.form.selectedImages])


    const ids = useSelector(({ author }: State) => author.current?.images?.map(({ id }) => id) ?? [], shallowEqual);

    const downLoadAll = () => {
        const idsToDownload = ids.filter((_, idx) => selected.includes(idx))
        downloadAuthorImages(idsToDownload);
    }

    return (
        <Paper variant="outlined" square className={classes.root}>
            {author &&
                <List component="div" disablePadding>
                    <ListItem label={t('app.sections.images')} bold={true}>
                        <div className={classes.content}>
                            <label>
                                <Tooltip title={t('authors.editPanel.images.selectAll') ?? ''}>
                                    <Checkbox
                                        checked={selected?.length === author?.images?.length && !(selected?.length === 0)}
                                        onChange={() => {
                                            selected?.length === author?.images.length ? dispatch(selectImages([])) :
                                                dispatch(selectImages(Array.from(Array(author.images.length | 0).keys())))
                                        }} />
                                </Tooltip>

                                <span className={classes.labelSelectCount}>
                                    {t('images.selectWithCount', { count: selected.length })}
                                </span>
                            </label>

                            <Tooltip title={t('images.exportAll') ?? ''}>
                                <span>
                                    <IconButton color="inherit" onClick={downLoadAll} disabled={selected.length === 0}>
                                        <DownloadIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {remove && <Tooltip title={t('authors.editPanel.images.delete') ?? ''}>
                                <span>
                                    <IconButton color="inherit" onClick={removeAll} disabled={selected.length === 0}>
                                        <DeleteIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>}

                            <Grid container spacing={1}>
                                {ids.map((id, idx) => <ImageAuthor
                                    key={idx}
                                    index={idx}
                                    displayDisableImage={displayDisableImages}
                                    selected={selected.includes(idx)}
                                    select={() => {
                                        const arr = selected.includes(idx)
                                            ? selected.filter(i => i !== idx)
                                            : [...selected, idx];
                                        dispatch(selectImages(arr));
                                    }}
                                    edit={edit ? edit(id) : undefined}
                                    setFav={setFav ? setFav(idx) : undefined}
                                    remove={remove ? remove(idx) : undefined}
                                />)}
                                {edit && <PlusImageAuthor />}
                            </Grid>
                            {children}
                        </div>
                    </ListItem>
                </List>}
        </Paper>
    )
}

export default ImagesAuthorPanel;

import React, { FC, ReactElement } from "react";
import { HandleQueryType, useHandleQuery } from "../hooks/useHandleQuery";
import { KyrLoader } from "./Loader";



export const KyrHandleQuery: FC<HandleQueryType<any>> = ({
  query,
  setQuery,
  succesMessage,
  errorMessage,
  children,
  successCallBack = () => {}
}) => {
  useHandleQuery({ query, errorMessage, setQuery, succesMessage, successCallBack })

  if (query.isLoading || query.data === undefined) {
    return (
      <KyrLoader />
    )
  }

  return children as ReactElement<any, any> | null
};

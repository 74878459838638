import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import {setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions"
import {dispatchPublicationAction} from "../../../redux/actions/workflow.actions"
import {State} from "../../../redux/reducers"
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer"
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors"
import {PageSize, SearchContext} from "../../../types/common-search"
import {PublicationStatus} from "../../../types/publication"
import {JouveProcessStatus, PublicationActionName} from "../../../types/workflow"
import {forcePublicationOptions} from "../../../utils/publication-utils"
import {ADMIN, EDITOR, MANAGER, PRODUCER, VIEWER} from "../../../utils/roles"
import {uuidv4} from "../../../utils/uuid"
import {openPdfOfPublicationInNewTab, openPdfPrinterInNewTab} from "../../../utils/workflows-utils"
import {isAuth} from "../../Auth"
import PageHeader from "../../commons/PageHeader"
import ClosePublicationDialog from "./ClosePublicationDialog"
import { disabledPeriodical, periodicalAuthorizeToClosePublication } from "../../../constantes"

type Props = {
    page: number
    size: PageSize
    queryId: string
}


export default function PublicationOrganizeHeader({page, size, queryId}: Props) {
    const {t} = useTranslation()
	const dispatch = useDispatch()

    const history = useHistory()
    const publication = useSelector(getCurrentPublication)
    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')
    const roles = useSelector((state: State) => state.auth.roles)

	const [showDialog, setShowDialog] = useState(false)

    const bapAll = (publicationId: number) => {
        const uuid = uuidv4();
        dispatch(dispatchPublicationAction({
            id: publicationId,
            action: PublicationActionName.BAP_ARTICLES_OF_PUBLICATION,
            uuid,
            context: SearchContext.PUBLICATION_ARTICLE,
            forceOptions: forcePublicationOptions(false, publication, queryId, size, page)
        }))
    }

    const placard = () => {
        if (publication) {
            const uuid = uuidv4();
            dispatch(dispatchPublicationAction({
                id: publication.id,
                action: PublicationActionName.GENERATE_PUBLICATION_PLACARD,
                uuid,
            }))
        }
    }
    const unlockPdf = () => {
        if (publication) {
            const uuid = uuidv4();

            dispatch(dispatchPublicationAction({
                id: publication.id,
                action: PublicationActionName.UNLOCK_PUBLICATION_PLACARD,
                uuid
            }))
        }

    }

    const redirectToCdfAdmin = () => publication ?
        history.push(
            `/${t('routes.admin.root')}/` +
            `${t('routes.admin.administrate_publication_plan.root')}/` +
            `${t('routes.admin.administrate_publication_plan.administrate')}/` +
            `${publication.id}`)
        : null

    return <>
        {publication && <PageHeader
            label={publication ? `${t('publications.edit_plan_title')} : ${publication.periodicalId}_${publication.year}_${publication.number}` : ''}
            hamburttonProps={{
                buttonAction: {
                    label: 'Tout mettre en BAP',
                    action: () => bapAll(publication.id),
                    display: isAuth(roles, [ADMIN, MANAGER, EDITOR], publication.periodicalId)
                },
                actions: [
                    {
                        label: 'Créer un nouvel article',
                        action: () => {
                            dispatch(setArticleDialogMode({
                                mode: ArticleDialogMode.CREATE,
                                default: {
                                    periodicalId: publication.periodicalId,
                                    publicationId: publication.id,
                                    type: undefined,
                                    isBundle: false
                                }
                            }))
                        },
                        display: isAuth(roles, [ADMIN, MANAGER, EDITOR, PRODUCER], publication.periodicalId)
                    },
                    {
                        label: 'Ajouter un lot',
                        action: () => {
                            dispatch(setArticleDialogMode({
                                mode: ArticleDialogMode.CREATE,
                                default: {
                                    periodicalId: publication.periodicalId,
                                    publicationId: publication.id,
                                    type: undefined,
                                    isBundle: true
                                }
                            }))
                        },
                        display: isAuth(roles, [ADMIN, MANAGER, EDITOR], publication.periodicalId)
                    },
                    {
                        label: t('workflows.see_printer_pdf'),
                        action: () => openPdfPrinterInNewTab(publication.id, dispatch, token),
                        display: publication.lastPrinterProcessStatus === JouveProcessStatus.FINISHED
                            && isAuth(roles, [ADMIN, MANAGER, EDITOR, PRODUCER], publication.periodicalId)

                    },
                    {
                        label: t('workflows.see_placard'),
                        action: () => openPdfOfPublicationInNewTab(publication.id, dispatch, token),
                        display: publication.lastAvailablePlacardProcessStatus === JouveProcessStatus.FINISHED ||
                            publication.lastAvailablePlacardProcessStatus === JouveProcessStatus.OUT_OF_DATE
                    },
                    {
                        label: t('workflows.unlock_placard'),
                        action: () => unlockPdf(),
                        display: publication.lastRequestPlacardProcess !== null && isAuth(roles, [ADMIN])
                    },
                    {
                        label: t('workflows.generate_placard'),
                        action: placard,
                        display: !disabledPeriodical.includes(publication.periodicalId) &&  publication.status === PublicationStatus.IN_PROGRESS &&
                            (publication.lastRequestPlacardProcess === null || publication.lastRequestPlacardProcess === undefined)
                            && isAuth(roles, [ADMIN, MANAGER, EDITOR, PRODUCER], publication.periodicalId)
                    },
                    {
                        label: t('publications.administrate'),
                        action: redirectToCdfAdmin,
                        display: isAuth(roles, [ADMIN], publication.periodicalId)
                    },
					... !publication?.closingDate ? [
						{
							label: 'Clôturer la parution',
							action: () => setShowDialog(true),
							display: periodicalAuthorizeToClosePublication.includes(publication.periodicalId) && isAuth(roles, [ADMIN, PRODUCER, MANAGER], publication.periodicalId) && publication.status === PublicationStatus.IN_PROGRESS && !publication.lastRequestPlacardProcess
						}
					] : []
                ]
            }}
            requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER, VIEWER]}
        />}

		<ClosePublicationDialog
			publication={publication!}
			isOpen={showDialog}
			close={() => setShowDialog(false)}
		/>
	</>
}

import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ArticleSearchResult} from "../../../types/common-search";
import {ADMIN, EDITOR, MANAGER} from "../../../utils/roles";
import {OrganizeBundleIcon} from "../../commons/icon/icons";
import Auth from "../../Auth";
import LinkButton from "../../commons/button/LinkButton";
import {Link as RouterLink} from "react-router-dom";
import {Button} from "@material-ui/core";
import {BundleResponse} from "../../../types/bundle";

type PickBundle = Pick<BundleResponse, "periodical" | "id">
type PickArticle = Pick<ArticleSearchResult, "periodicalId" | "id">

type Props = {
    document: PickBundle | PickArticle,
    size?: 'small' | 'medium',
    className?: any,
    isButton?: boolean
}

function OrganizeBundle({document, size, className, isButton}: Props) {
    const {t} = useTranslation()

    const periodicalId = useMemo(() =>
            document.hasOwnProperty("periodical")
                ? (document as PickBundle).periodical
                : (document as PickArticle).periodicalId,
        [document])

    return (
        <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]} periodicalRestriction={periodicalId}>
            {isButton ? <Button
                className={'lex-left-button'}
                fullWidth
                startIcon={<OrganizeBundleIcon/>}
                size={"small"} component={RouterLink}
                to={`/${t('routes.bundle.root')}/${document.id}/${t('routes.bundle.organize')}`}>
                {t('bundles.edit_bundle_title')}
            </Button> : <LinkButton size={size}
                                    buttonClassName={className}
                                    to={`/${t('routes.bundle.root')}/${document.id}/${t('routes.bundle.organize')}`}
                                    title={t('bundles.edit_bundle_title')}
                                    icon={<OrganizeBundleIcon/>}/>
            }
        </Auth>
    )
}

export default OrganizeBundle;

import React, {useEffect} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {commonStyle} from "../../../../styles/common.style";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import PageHeader from "../../../commons/PageHeader";
import {ADMIN} from "../../../../utils/roles";
import Container from "@material-ui/core/Container";
import {Grid} from "@material-ui/core";
import BundlePlanInfos from "./BundlePlanInfos";
import BundlePlanStructure from "./BundlePlanStructure";
import {
    fetchAdminBundlePlan,
    resetAdminBundlePlan,
    setAdminBundlePlanFormMode
} from "../../../../redux/actions/admin/bundle/admin-bundle.actions";
import {currentAdminBundlePlanFormModeSelector} from "../../../../redux/selectors/admin/bundle/admin-bundle.selectors";
import {BundlePlanFormMode} from "../../../../types/bundle";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: "3%"
    }
}));

const BundlePlanEditRoot = () => {
    const classes = commonStyle()
    const customClasses = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const {t} = useTranslation()
    const {id} = useParams() as { id: string }
    const location = useLocation()

    useEffect(() => {
        if (id) {
            dispatch(fetchAdminBundlePlan(id))
        }
    }, [id, dispatch]);

    // In case of reload, form mode is not set
    const mode = useSelector(currentAdminBundlePlanFormModeSelector);
    if (mode === undefined) {
        const isEditMode = location.pathname.includes('/edit/');
        const isDuplicateMode = location.pathname.includes('/duplicate/');
        if (isEditMode) {
            dispatch(setAdminBundlePlanFormMode(BundlePlanFormMode.EDIT))
        } else if (isDuplicateMode) {
            dispatch(setAdminBundlePlanFormMode(BundlePlanFormMode.DUPLICATE))
        } else {
            dispatch(setAdminBundlePlanFormMode(BundlePlanFormMode.CREATE))
        }
    }

    useEffect(() => {
        const unListener = history.listen(() => {
            resetBundlePlan();
            unListener();
        })
    }, [])

    const resetBundlePlan = () => {
        dispatch(resetAdminBundlePlan());
    }

    if (mode === BundlePlanFormMode.CREATE) {
        resetBundlePlan();
    }

    return (
        <Container maxWidth={"xl"} className={classes.root}>
            <PageHeader
                label={t(mode === BundlePlanFormMode.EDIT ? 'admin.bundle.edit.title' : 'admin.bundle.create.title')}
                requiredRoles={[ADMIN]}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} className={customClasses.root}>
                    {mode && <BundlePlanInfos mode={mode}/>}
                </Grid>

                <Grid item xs={12}>
                    <BundlePlanStructure/>
                </Grid>
            </Grid>

        </Container>
    );
}

export default BundlePlanEditRoot

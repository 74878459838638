import React, {useLayoutEffect, useRef, useState} from "react";
import {CircularProgress} from "@material-ui/core";

type Props = {
    url: string
}

export default function MagicIframe({url}: Props) {
    const iframe = useRef<HTMLIFrameElement>(null);
    const [loading, loaded] = useState(true);
    const [height, setHeight] = useState(0);

    useLayoutEffect(() => {
        const update = () => {
            loaded(false);
            const h = iframe.current?.contentWindow?.document.body.scrollHeight ?? 500;
            setHeight(h + 40);
        }
        if (iframe.current) {
            iframe.current.onload = update;
        }
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
    }, [iframe]);

    return (
        <>
            {loading && <div style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '60px auto'
            }}>
                <CircularProgress/>
            </div>}
            <div style={{height: `${height}px`, visibility: loading ? 'hidden' : 'visible'}}>
                <iframe ref={iframe} src={url} width="100%" height="100%"/>
            </div>
        </>
    )

}

import {Proposal} from "../../types/quick-search";
import {Action} from "../actions/utils.actions";
import {
    isCountQuickSearchSuccesAction,
    isFetchQuickSearchProposalsSuccessAction,
    isSelectQuickSearchProposalAction
} from "../actions/quick-search.actions";

type QuickResultCount = {
    type: string,
    value: number
}

type QuickSearchState = {
    proposals: Proposal[]
    loading: boolean
    count: QuickResultCount[]
}

const init: QuickSearchState = {
    proposals: [],
    loading: false,
    count: []
}

export const quickSearch = (state = init, action: Action): QuickSearchState => {
    if (isFetchQuickSearchProposalsSuccessAction(action)) {
        const proposals = action.payload;
        return {...state, proposals};
    } else if (isSelectQuickSearchProposalAction(action)) {
        return {...state, proposals: []};
    } else if (isCountQuickSearchSuccesAction(action)) {
        return {...state, count: [...state.count, {type: action.payload.type, value: action.payload.count}]};
    } else {
        return state;
    }
};
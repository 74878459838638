import {createSelector} from "reselect";
import {State} from "../reducers";
import {createActionWithPayload} from "../actions/utils.actions";

const OrganizeState = (state: State) => state.bundle.organizeBundle;
export const currentFetchedBundleSelector = createSelector(OrganizeState, state => state.current)
export const currentFetchedBundleTitleSelector = createSelector(OrganizeState, state => state.current?.title)
export const currentFetchedBundlePeriodicalIdSelector = createSelector(OrganizeState, state => state.current?.periodical)
export const currentFetchedBundlePlanSelector = createSelector(OrganizeState, state => state.current?.plan)
export const currentFetchedBundlePublicationIdSelector = createSelector(OrganizeState, state => state.current?.publication)
export const currentFetchedBundleTypeSelector = createSelector(OrganizeState, state => state.current?.type)

export const [isRedirectToEditBundleAction] = createActionWithPayload<string>('REDIRECT_TO_EDIT_BUNDLE')

import React from "react";
import {viewPageStyle} from "../../../styles/view-page.style";
import {useParams} from "react-router-dom";
import MagicIframe from "../../commons/MagicIframe";
import {Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import BundleViewRightPanel from "./BundleViewRightPanel";
import {createHtml} from "../../../utils/common-utils";


export function BundleView() {
    const classes = viewPageStyle()
    const {id} = useParams() as { id: string }
    const url = `/api/article/${id}/preview`

    let search = window.location.search
    let params = new URLSearchParams(search)
    let mode = params.get('mode')

    const bundle = useSelector(currentFetchedBundleSelector);
    const fullscreen = mode === "fullscreen";

    return (
        <div className={classes.viewRoot}
             style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
            <div>
                <Typography variant={"h4"}>
                    <span dangerouslySetInnerHTML={createHtml(bundle?.title ?? '')}/>
                </Typography>
            </div>

            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{
                    margin: '20px auto',
                    width: fullscreen ? '100%' : '70%'
                }}>
                    <MagicIframe url={url}/>
                </div>

                {!fullscreen && <div style={{
                    width: '25%', margin: '20px auto',
                }}>
                    <BundleViewRightPanel/>
                </div>}
            </div>
        </div>
    )
}

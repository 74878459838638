import {Observable, of} from "rxjs";
import {StateObservable} from "redux-observable";
import {State} from "../../reducers";
import {EpicDependencies} from "../../store";
import {catchError, concatMap, filter, map} from "rxjs/operators";
import {AxiosStatic} from "axios";
import {fromPromise} from "rxjs/internal-compatibility";
import {
    autoCompletePlanNodesError,
    autoCompletePlanNodesSuccess,
    isAutoCompletePlanNodesAction
} from "../../actions/search/autcocomplete-plan-nodes.actions";
import {Action} from "../../actions/utils.actions";
import {PlanNodeFilters, SearchResults} from "../../../types/common-search";

export function autoCompletePlanNodes(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isAutoCompletePlanNodesAction),
        concatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return postPlanNodesSearchRequest(axios, payload, token)
        }),
    );
}

function postPlanNodesSearchRequest(axios: AxiosStatic, query: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };


    const data = {
        query: query,
        page: 0,
        size: 20
    } as PlanNodeFilters;

    return fromPromise(axios.post<SearchResults>("/api/publication_plan/search", data, {headers: headers}))
        .pipe(
            map((response) => autoCompletePlanNodesSuccess(response.data.result.map(e => {
                return {
                    "key": e.id,
                    "value": e.parentName ? `[${e.periodicalId}] ${e.parentName} (${e.name})` : `[${e.periodicalId}] ${e.name}`
                }
            }))),
            catchError(err =>
                of(autoCompletePlanNodesError(err))
            )
        );
}
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ImagesAuthorPanel from "../../commons/ImagesAuthorPanel";
import Divider from "@material-ui/core/Divider";
import {
    currentEditImagePanel,
    Field,
    removeMultipleImages,
    selectImages,
    setFavField
} from "../../../../redux/actions/author.actions";
import {makeStyles} from "@material-ui/core/styles";
import ImageForm from "./ImagesFormItemPanel";
import {Grow} from "@material-ui/core";
import {State} from "../../../../redux/reducers";
import ConfirmationDialog from "../../../commons/ConfirmationDialog";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";

type Props = {
    register: any
    errors: any
    control: any
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'block',
        width: '100%'
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        height: 2
    }
}));

const ImagesForm = ({register, errors, control}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const current = useSelector(({author}: State) => {
        const idx = author.current?.images?.findIndex((image) => image.id === author.form.currentEditingImage) ?? -1;
        return idx >= 0 ? idx : null;
    });

    const selectedImages = useSelector(({author}: State) => author.form.selectedImages)

    const [dialogOpen, openDialog] = useState(false);
    const closeDialog = () => openDialog(false)

    const edit = (index: string) => () => {
        dispatch(currentEditImagePanel(index))
    }
    const close = () => {
        dispatch(currentEditImagePanel(null))
    }
    const setFav = (index: number) => () => {
        dispatch(setFavField({field: Field.IMAGE, index}))
    };

    const askForRemoval = () => openDialog(true)
    const removeOne = (idx: number) => () => {
        dispatch(selectImages([idx]))
        openDialog(true)
    }
    const removeAll = () => {
        dispatch(removeMultipleImages({indexes: selectedImages}))
    }

    const [displayDisableImages, setDisplayDisableImages] = useState(false)

    return (
        <ImagesAuthorPanel edit={edit}
                           setFav={setFav}
                           remove={removeOne}
                           removeAll={askForRemoval} displayDisableImages={displayDisableImages}>
            <ConfirmationDialog title={t("authors.delete_images_confirmation", {count: selectedImages.length})}
                                description={''}
                                actions={[
                                    {label: t('dialogs.cancel'), color: 'default'},
                                    {label: t('dialogs.confirm'), action: removeAll}
                                ]}
                                isOpened={dialogOpen}
                                onClose={closeDialog}/>
            <Grow in={current !== null}>
                <div className={classes.root}>
                    <Divider className={classes.divider}/>
                    {current !== null && <ImageForm index={current}
                                                    close={close}
                                                    register={register}
                                                    control={control}
                                                    errors={errors}/>}
                </div>
            </Grow>

            <Button
                onClick={() => setDisplayDisableImages(!displayDisableImages)}>{!displayDisableImages ? 'Afficher les images désactivées' : 'Masquer les images désactivées'}</Button>

        </ImagesAuthorPanel>);
}

export default ImagesForm;

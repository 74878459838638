import Grid from "@material-ui/core/Grid"
import {DateTimePicker} from "@material-ui/pickers"
import {DateTime, Duration} from "luxon"
import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router-dom"
import i18n from "../../../i18n"
import {notifyError, notifySuccess} from "../../../redux/actions/notification.actions"
import {fetchPublication} from "../../../redux/actions/publication.actions"
import {closePublicationEpic} from "../../../redux/epics/publication.epics"
import {State} from "../../../redux/reducers"
import {Filters, SearchContext} from "../../../types/common-search"
import {Publication} from "../../../types/publication"
import ConfirmationDialog from "../../commons/ConfirmationDialog"
import {of} from "rxjs";

interface Props {
    publication: Publication
    context?: SearchContext
    forceOptions?: Filters
    isOpen: boolean
    close: () => void
}

export default function ClosePublicationDialog({publication, isOpen = false, close, context, forceOptions}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {id} = useParams() as { id: string }
    const token = useSelector(({auth}: State) => auth.token)

    async function closePublication(date?: DateTime) {
        try {
            await closePublicationEpic(publication?.id, token ?? '', date)
            dispatch(notifySuccess(i18n.t('publications.close_publication_success')))
            dispatch(fetchPublication(id))
            setDisplayDateTimePicker(false)
        } catch ({response}) {
            dispatch(notifyError(response.status === 400 &&
            response?.data?.message === 'PUBLICATION_CAN_T_BE_CLOSED' ?
                response.data.message : response.status, {type: "PUBLICATION"}))
        }
        close()
    }

    const [displayDateTimePicker, setDisplayDateTimePicker] = useState(false)
    const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now().plus(Duration.fromObject({minutes: 1})))

    const handleDateChange = value => setSelectedDate(value)

    return <ConfirmationDialog
        title={t('workflows.online_confirmation')}
        description={(displayDateTimePicker
            ? <Grid container direction="row"
                    justifyContent={"center"}>
                <DateTimePicker
                    inputVariant="outlined"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
            </Grid>
            : <Grid container>{t("publications.close_publication")}</Grid>)}
        actions={[
            {
                label: t('push-online.now'), action: () => {
                    closePublication()
                }, variant: 'contained'
            },
            {
                label: t('push-online.push-later'), action: () => {
                    if (displayDateTimePicker) {
                        closePublication(selectedDate)
                    }
                    setDisplayDateTimePicker(true)
                }, variant: 'contained', close: false
            },
            {label: t('dialogs.cancel'), color: 'default', action: close}
        ]}
        isOpened={isOpen}
        onClose={close}
    />
}

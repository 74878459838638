import React, {useEffect, useMemo, useState} from "react";
import {useLocation} from "react-router-dom";
import LexImageGrid from "./list/ImageGrid";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../redux/reducers";
import LexImageTable from "./list/ImageTable";
import {useTranslation} from "react-i18next";
import ImageFilters from "./list/ImageFilters";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import PageHeader from "../commons/PageHeader";
import {initDefaultQueryParam, search, updateSearchQueryParam} from "../../redux/actions/common-search.actions";
import {getCollectionName, readImageQueryParams} from "../../utils/image.utils";
import {ADMIN, ADMIN_IMAGES} from "../../utils/roles";
import TablePagination from "../authors/list/TablePagination";
import QuickResults from "../commons/QuickResults";
import {commonStyle} from "../../styles/common.style";
import {ImageSearchResult, SearchContext} from "../../types/common-search";

const ImagesRoot = () => {
    const {t} = useTranslation()
    const classes = commonStyle()
    const dispatch = useDispatch()
    const [isInit, setInit] = useState(false)

    const searchLocation = useLocation().search;
    const isLocationGrid = searchLocation.includes("&grid") || searchLocation.includes("?grid")
    const filters = useMemo(() => readImageQueryParams(searchLocation), [searchLocation])

    useEffect(() => {
        new Promise<void>((resolve) => {
            dispatch(initDefaultQueryParam({
                ctx: SearchContext.IMAGE,
                field: 'collectionFilter',
                value: ['ILLUSTRATION']
            }))
            resolve();
        }).then(() => setInit(true));
    }, [dispatch]);

    const [images, totalCount]: [ImageSearchResult[], number] = useSelector(({search}: State) => {
        const store = search.common[SearchContext.IMAGE]
        return [store.searchResults, store.totalCount];
    });

    const updateChangePage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.IMAGE,
            field: 'page',
            value
        }));
    }

    const updateChangeRowPerPage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.IMAGE,
            field: 'size',
            value
        }));
    }

    useEffect(() => {
        if (!isInit) {
            return;
        }

        const queryFilters = readImageQueryParams(searchLocation);
        const filters = {
            ...queryFilters,
            collectionFilter: (queryFilters.collectionFilter && queryFilters.collectionFilter.length > 0) ? queryFilters.collectionFilter : getCollectionName("image")
        }
        dispatch(search({
            ctx: SearchContext.IMAGE,
            filters
        }))
    }, [dispatch, searchLocation, isInit])

    return (
        <>
            <Container maxWidth={"xl"} className={classes.root}>
                <PageHeader
                    label={filters.query ? "" : "Liste des images"}
                    hamburttonProps={{
                        buttonAction: {
                            label: t('images.add'),
                            route: '/' + t('routes.image.root') + '/' + t('routes.image.create')
                        }
                    }}
                    requiredRoles={[ADMIN, ADMIN_IMAGES]}
                >
                    {filters.query ?
                        <QuickResults currentPage={"image"} countResult={totalCount}
                                      query={filters.query!!}/> : <></>}
                </PageHeader>

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <ImageFilters
                            filters={readImageQueryParams(searchLocation)}/>
                    </Grid>

                    <Grid item xs={9}>
                        <Grid item xs={12}>
                            {isLocationGrid ?
                                <LexImageGrid/> : <LexImageTable
                                    images={images ?? []}
                                    filters={filters}
                                />
                            }
                            <TablePagination page={filters.page || 0}
                                             size={filters.size || 0}
                                             totalCount={totalCount}
                                             updateChangePage={updateChangePage}
                                             updateChangeRowPerPage={updateChangeRowPerPage}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

export default ImagesRoot

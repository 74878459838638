import React from "react";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {State} from "../../redux/reducers";
import {Grid, MenuItem, Select as MuiSelect} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";


type Props = {
    selectedOption?: string
    selectedAction: (periodical: string | undefined) => void,
    label?: string,
    error?: boolean
}

const useStyles = makeStyles(() => ({
    select: {
        minWidth: 200
    },
    label: {
        marginTop: 10
    }
}))

export default ({selectedOption, selectedAction, error, label}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const roles = useSelector(({auth}: State) => auth?.roles ?? []);

    const initPeriodicals = useSelector(({periodical}: State) => periodical.periodicals).filter(x => {
        if (roles.some(x => x.startsWith("ADMIN"))) {
            return true
        } else {
            return roles.some(r => r.endsWith(x.id))
        }
    })


    return (<>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="subtitle2" className={classes.label}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item>

                    <MuiSelect
                        value={selectedOption ?? null}//data ? data.value ?? '' : []
                        fullWidth={true}
                        className={classes.select}
                        name={t('admin.form.periodicalId')}
                        error={error !== undefined}
                        onChange={(e) => {
                            selectedAction(e?.target?.value as string | undefined)
                        }}>
                        {initPeriodicals.map(option =>
                            <MenuItem value={option.id}
                                      key={option.label}>[{option.id}] {option.label}</MenuItem>
                        )}
                    </MuiSelect>
                    {error && <FormHelperText error={true}>
                        La revue est obligatoire </FormHelperText>}
                </Grid>
            </Grid>
        </>
    )
};

import { Box, BoxProps } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { forwardRef } from "react";


export type FlexBoxType = BoxProps & {
    grow?: number,
    rowGap?: number | string,
    colGap?: number | string,
    center?: boolean,
}
export const FlexBox = forwardRef((props: FlexBoxType, ref) => {
    const {
        style,
        grow,
        rowGap,
        colGap,
        center,
        ...otherProps
    } = props

    let customStyle: CSSProperties = {
        ...style,
    }

    if (center) {
        customStyle = { ...customStyle, justifyContent: 'center', alignItems: 'center' }
    }

    return (
        <Box
            {...otherProps}
            //@ts-ignore la ref est juste mal typé (TS) mais ok. 
            // Il faudrait trouvé le bon type pour ne plus avoir l'erreur et retiré le ignore
            // Mais pas prioritaire
            ref={ref}
            style={{
                display: 'flex',
                rowGap,
                columnGap: colGap,
                flexGrow: grow,
                ...customStyle
            }} />
    )
})

export const Column = forwardRef((props: FlexBoxType, ref) => {
    const { style, ...otherProps } = props

    return (
        <FlexBox {...otherProps} ref={ref} style={{ display: 'flex', flexDirection: 'column', ...style }} />
    )
})

export const Row = forwardRef((props: FlexBoxType, ref) => {
    const { style, ...otherProps } = props

    return (
        <FlexBox {...otherProps} ref={ref} style={{ display: 'flex', flexDirection: 'row', ...style }} />
    )
}) 
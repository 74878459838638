import {PlanNode} from "../types/plan-node";

export const planHasId = (nodes: PlanNode[], id: string): boolean => {
    for (let node of nodes) {
        const n = node.id === id ? node : planHasId(node.sub, id)
        if (n) return true
    }

    return false
}

import React from "react";
import Grid from "@material-ui/core/Grid";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import TitleTypo from "../../commons/TitleTypo";

type Props = {
    functions: string[]
}
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        list: {
            marginTop: 0,
            paddingLeft: theme.spacing(2)
        }
    }),
);


export default ({functions}: Props) => {
    const classes = useStyles();
    const createHtml = (title: string) => {
        return {__html: title};
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={2}>
                <TitleTypo label={"Fonction(s)"}/>
            </Grid>
            <Grid item xs={10}>
                {functions.length > 0 && (<ul className={classes.list}>
                    {functions.map((fun, index) => (
                        <li key={index} dangerouslySetInnerHTML={createHtml(fun)}></li>
                    ))}
                </ul>)}
            </Grid>
        </Grid>
    )
}

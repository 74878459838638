import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    GridList,
    GridListTile,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip,
    useTheme
} from "@material-ui/core";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import {DeleteIcon, EditIcon, ExportIcon, Zoom} from "../../../icon";
import {useDispatch, useSelector} from "react-redux";
import DateTime from "../../commons/DateTime";
import {State} from "../../../redux/reducers";
import {SearchContext} from "../../../types/common-search";
import {selectOneElement, updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {Link as RouterLink, useHistory, useLocation} from "react-router-dom";
import {deleteAd, toggleAdDisabled} from "../../../redux/actions/ad.actions";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import {downloadAdvertisementImages} from "../../../utils/common-utils";
import Collapse from "@material-ui/core/Collapse";
import {
    getSortFirstAttr,
    getSortFirstOrder,
    readImageQueryParams,
    shouldDeactivateDelete,
    shouldDeactivateDisable
} from "../../../utils/image.utils";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import AdsListActions from "./AdsListActions";
import {gridStyle} from "../../../styles/grid.style";
import GridListSwitcher from "../../commons/GridListSwitcher";
import {DisableIcon, EnableIcon} from "../../commons/icon/icons";

const sortValues = [
    "ID",
    "NAME",
    "DEADLINE",
    "UPDATE"
]

export default function AdsGrid() {
    const theme = useTheme()
    const classes = gridStyle(theme)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const selectedIds = useSelector((state: State) => state.search.common[SearchContext.ADVERTISEMENT].selectedIds)
    const loaded = useSelector((state: State) => state.search.common[SearchContext.ADVERTISEMENT].status === "LOADED")

    const ads = useSelector(({search}: State) => search.common[SearchContext.ADVERTISEMENT].searchResults)

    const [isShown, shown] = useState(ads.map((_) => false))
    if (isShown.length !== ads.length) {
        shown(ads.map(() => false))
    }

    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);

    const [adBeingDeleted, changeAdBeingDeletedId] = useState("");
    const handleAdDeletionConfirmation = (imageId: string) => () => {
        changeAdBeingDeletedId(imageId)
        openConfirmationDialog(true)
    }

    const searchLocation = useLocation().search;
    const isLocationGrid = searchLocation.includes("&grid") || searchLocation.includes("?grid")
    const queryParams = readImageQueryParams(searchLocation)
    const sortMode = getSortFirstOrder(queryParams)
    const attr = getSortFirstAttr(queryParams)

    const enableIcon = (flag: boolean) => {
        return flag ? <EnableIcon/> : <DisableIcon/>
    }

    const switchEnableAds = (id: string, flag: boolean) => () => {
        dispatch(toggleAdDisabled({id, flag}));
    }

    const sort = (attribute: string, order: ("ASC" | "DESC" | undefined) | string) => dispatch(updateSearchQueryParam({
        ctx: SearchContext.ADVERTISEMENT,
        field: "sort",
        value: [{attr: attribute, order: order}]
    }))

    const handleChangeMode = () => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ADVERTISEMENT,
            field: "grid",
            value: !isLocationGrid
        }))
    };

    const handleAdDeletion = () => {
        dispatch(deleteAd({
            id: adBeingDeleted, ctx: SearchContext.ADVERTISEMENT, forceOptions: queryParams
        }))
    }

    return (
        <>
            <ConfirmationDialog
                title={t('images.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default', action: handleAdDeletion},
                    {label: t('dialogs.confirm'), action: handleAdDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            <Grid container item xs={12} justify={"space-between"}>
                <Grid container item xs={5} spacing={1}>
                    <Grid item xs={8}>
                        <InputLabel>{t('misc.sort_by')}</InputLabel>
                        <Select
                            fullWidth={true}
                            onChange={(e) => {
                                const i = e.target.value as number
                                sort(sortValues[i], sortMode)
                            }}>
                            <MenuItem value={0}>{t('misc.id')}</MenuItem>
                            <MenuItem value={1}>{t('misc.name')}</MenuItem>
                            <MenuItem value={2}>{t('misc.deadline')}</MenuItem>
                            <MenuItem value={3}>{t('misc.modification_date')}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid container item xs={4}>
                        <InputLabel>{t('misc.sort_order')}</InputLabel>
                        <Select
                            value={sortMode}
                            fullWidth={true}
                            onChange={(e) => {
                                const value = e.target.value as string
                                sort(attr, value)
                            }}>
                            <MenuItem value={'ASC'}>{t('misc.asc')}</MenuItem>
                            <MenuItem value={'DESC'}>{t('misc.desc')}</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item>
                    <GridListSwitcher handleChangeMode={handleChangeMode} isLocationGrid={isLocationGrid}/>
                </Grid>
            </Grid>

            <AdsListActions grid={true}/>

            <Grid container justify={"space-between"}>
                <Grid item xs={11}>
                    <GridList cellHeight={500} cols={3} spacing={theme.spacing(2)}>
                        {ads.length > 0 && ads.map((ad, idx) =>
                            <GridListTile key={ad.id} className={classes.tile}
                                          onMouseLeave={() => shown(isShown.map((it, _idx) => _idx === idx ? false : it))}>
                                <Card
                                    elevation={0}
                                    onMouseEnter={() => shown(isShown.map((it, _idx) => _idx === idx ? true : it))}>
                                    <Checkbox name={"selected_ad_" + ad.id}
                                              color="primary"
                                              checked={selectedIds.indexOf(ad.id ?? '') > -1}
                                              onChange={() => dispatch(selectOneElement({
                                                  ctx: SearchContext.ADVERTISEMENT,
                                                  id: ad.id
                                              }))}
                                              className={classes.check}
                                    />
                                    <CardMedia
                                        onClick={() => history.push('/' + t('routes.ad.root') + '/' + ad.id)}
                                        children={
                                            <div className={classes['image-infos-container']}>
                                                <Collapse in={isShown[idx]}>
                                                    <div className={classes.zoom}>
                                                        <Zoom/>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        }
                                        className={classes.media}
                                        image={`/api/image/${ad.id}?size=MEDIUM`}
                                    />
                                    <CardContent>
                                        {ad.id} {ad.name} <br/>
                                        <DateTime date={ad.modificationDate}/>
                                    </CardContent>

                                    <CardActions className={classes.center}>
                                        <Tooltip title={t('images.export') || ""}>
                                            <IconButton aria-label={t('images.export')}
                                                        onClick={() => downloadAdvertisementImages([ad.id])}>
                                                <ExportIcon fontSize={"small"}/>
                                            </IconButton>
                                        </Tooltip>

                                        <Link
                                            to={`/${t('routes.ad.root')}/${ad.id}/edit`}
                                            component={RouterLink}>
                                            <Tooltip title={t('ads.edit') || ""}>
                                                <IconButton>
                                                    <EditIcon fontSize={"small"}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Link>

                                        <Tooltip title={(ad.enable ? t('ads.disable') : t('ads.enable')) || ""}>
                                            <IconButton
                                                disabled={ad.enable ? shouldDeactivateDisable(ad) : false}
                                                onClick={switchEnableAds(ad.id, ad.enable)}>
                                                {enableIcon(!ad.enable)}
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={t('misc.remove_or_delete') || ""}>
                                            <IconButton aria-label={t('misc.remove_or_delete')}
                                                        disabled={shouldDeactivateDelete(ad)}
                                                        onClick={handleAdDeletionConfirmation(ad.id)}>
                                                <DeleteIcon fontSize={"small"}/>
                                            </IconButton>
                                        </Tooltip>
                                    </CardActions>
                                </Card>
                            </GridListTile>
                        )}

                        {(ads.length === 0 && loaded) && (
                            <Grid item xs={12}>
                                <Typography variant="h6"
                                            style={{
                                                "textAlign": "center",
                                                "marginTop": 250
                                            }}>{t('table.no_result')}</Typography>
                            </Grid>
                        )}
                    </GridList>
                </Grid>
            </Grid>
        </>
    )
}

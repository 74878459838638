import {EMPTY, merge, Observable, of} from "rxjs";
import {Action, ActionWithPayload} from "../actions/utils.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {catchError, filter, flatMap} from "rxjs/operators";
import {EpicDependencies} from "../store";
import {AxiosStatic} from "axios";
import {fromPromise} from "rxjs/internal-compatibility";
import {notify, notifyError, notifyWarning} from "../actions/notification.actions";
import {ArticleActionName, JouveProcessStatus, PublicationActionName} from "../../types/workflow";

import {
    dispatchArticleResponseAction,
    dispatchMultipleArticlesActionResponse,
    dispatchMultiplePublicationsActionResponse,
    dispatchPublicationResponseAction,
    isDispatchArticleAction,
    isDispatchArticleResponseAction,
    isDispatchMultipleArticlesAction,
    isDispatchMultiplePublicationsAction,
    isDispatchPublicationAction,
    isDispatchPublicationResponseAction
} from "../actions/workflow.actions"
import {reloadSearch} from "../actions/common-search.actions";
import {ElementContext, SearchContext} from "../../types/common-search";
import {fetchArticle, fetchArticleSuccess} from "../actions/article/article.actions";
import {fetchBundleSuccess} from "../actions/bundle.actions";
import {ArticleStatus} from "../../types/article";
import {fetchPublicationSuccess} from "../actions/publication.actions";
import {i18n} from "i18next";

function _dispacthArticleWorkflowAction(i18n: i18n, axios: AxiosStatic, uuid: string, articleId: string, action: ArticleActionName, token: string, context?: SearchContext | ElementContext, forceOptions?: any): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/workflow/article`, {
        name: action,
        id: articleId
    }, {headers}))
        .pipe(
            flatMap(() =>
                merge(
                    of(notify(i18n.t(`workflows.success.${action}`), {variant: 'success'})),
                    isSearchContext(context) ? of(reloadSearch({ctx: context as SearchContext, forceOptions})) : EMPTY,
                    isElementContext(context) ? dispatchFetchElement(context as ElementContext, articleId) : EMPTY,
                    of(dispatchArticleResponseAction({uuid: uuid, action: action}))
                )
            ),
            catchError(err =>
                merge(
                    of(notifyError(((err.response.status === 400 || err.response.status === 403) && err.response.data.message.startsWith("WORKFLOW_")) ? err.response.data.message : err.response.status, {
                        type: 'ARTICLE', articles: err?.response?.data?.articles
                    })),
                    of(dispatchArticleResponseAction({uuid: uuid, action: action}))
                )
            )
        );
}

export function redirectOnPlacardRequestOnFontoPage(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isDispatchArticleResponseAction)
    ).subscribe((action) => {
        if (history.location.pathname.endsWith("/edit") && action.payload.action === ArticleActionName.GENERATE_ARTICLE_PLACARD)
            history.push({
                pathname: `/${i18n.t('routes.article.root')}`
            })
    });

    return EMPTY
}

function isElementContext(context?: SearchContext | ElementContext): boolean {
    return context !== undefined && context in ElementContext
}

function isSearchContext(context?: SearchContext | ElementContext): boolean {
    return context !== undefined && context in SearchContext
}

function dispatchFetchElement(context: ElementContext, id: any): Observable<any> {
    switch (context) {
        case ElementContext.ELEMENT_CONTEXT_ARTICLE:
            return of(fetchArticle(id))
        default:
            return EMPTY
    }
}

function _dispacthArticlesWorkflowAction(i18n: i18n, axios: AxiosStatic, uuid: string, articleIds: string[], action: ArticleActionName, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/workflow/articles`, {
        name: action,
        ids: articleIds
    }, {headers}))
        .pipe(
            flatMap((response) => {
                    const notif = response.status === 202 ?
                        of(notifyWarning(response.data, {type: 'ARTICLE'}))
                        : of(notify(i18n.t(`workflows.success.${action}`, {count: articleIds.length}), {variant: 'success'}))

                    return merge(
                        notif,
                        of(reloadSearch({ctx: SearchContext.ARTICLE})),
                        of(dispatchMultipleArticlesActionResponse({uuid: uuid, action: action}))
                    )
                }
            ),
            catchError(err =>
                merge(
                    of(notifyError(((err.response.status === 400 || err.response.status === 403) && err.response?.data?.message?.startsWith("WORKFLOW_")) ? err.response.data.message : err.response.status, {
                        type: 'ARTICLE', articles: err?.response?.data?.articles
                    })),
                    of(dispatchMultipleArticlesActionResponse({uuid: uuid, action: action}))
                )
            )
        )
}

function _dispacthPublicationWorkflowAction(i18n: i18n, axios: AxiosStatic, uuid: string, publicationId: number, action: PublicationActionName, token: string, context?: SearchContext, forceOptions?: any): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/workflow/publication`, {
        name: action,
        id: publicationId
    }, {headers}))
        .pipe(
            flatMap(() =>
                merge(
                    of(notify(i18n.t(`workflows.success.${action}`), {variant: 'success'})),
                    context ? of(reloadSearch({ctx: context, forceOptions: forceOptions})) : EMPTY,
                    of(dispatchPublicationResponseAction({uuid: uuid, action: action, isSuccess: true}))
                )
            ),
            catchError(err =>
                merge(
                    of(notifyError(((err.response.status === 400 || err.response.status === 403) && err.response.data.message.startsWith("WORKFLOW_")) ? err.response.data.message : err.response.status, {
                        type: 'PUBLICATION',
                        articles: err?.response?.data?.articles
                    })),
                    of(dispatchPublicationResponseAction({uuid: uuid, action: action, isSuccess: false})),
                )
            )
        );
}

function _dispacthPublicationsWorkflowAction(i18n: i18n, axios: AxiosStatic, uuid: string, publicationIds: number[], action: PublicationActionName, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/workflow/publications`, {
        name: action,
        ids: publicationIds
    }, {headers}))
        .pipe(
            flatMap((response) => {
                    const notif = response.status === 202 ?
                        of(notifyWarning(response.data, {type: 'PUBLICATION'}))
                        : of(notify(i18n.t(`workflows.success.${action}`), {variant: 'success'}))

                    return merge(
                        notif,
                        of(dispatchMultiplePublicationsActionResponse(uuid)),
                        of(reloadSearch({ctx: SearchContext.PUBLICATION}))
                    )
                }
            ),
            catchError(err =>
                merge(
                    of(notifyError((err.response.status === 400 && err.response.data.message.startsWith("WORKFLOW_")) ? err.response.data.message : err.response.status, {
                        type: 'PUBLICATION',
                        articles: err?.response?.data?.articles
                    })),
                    of(dispatchMultiplePublicationsActionResponse(uuid))
                )
            )
        );
}

const isClosePublicationAction = (action,): action is ActionWithPayload => isDispatchPublicationResponseAction(action) && action.payload.action === PublicationActionName.CLOSE_PUBLICATION && action.payload.isSuccess

export function redirectOnClosePublicationAction(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies
    action$.pipe(
        filter(isClosePublicationAction)
    ).subscribe(() => {
        history.push(`/${i18n.t('routes.publication.root')}`);
    });
    return EMPTY;
}

export function refreshPublicationOnWorkflowSuccess(action$: Observable<Action>, state$: StateObservable<State>): Observable<Action> {
    return action$.pipe(
        filter(isDispatchPublicationResponseAction),
        flatMap(({payload}) => {
            const {action} = payload;

            if (action === PublicationActionName.GENERATE_PUBLICATION_PLACARD) {
                return updatePublication(state$, 'lastRequestPlacardProcess', JouveProcessStatus.PENDING)
            }
            if (action === PublicationActionName.UNLOCK_PUBLICATION_PLACARD) {
                return updatePublication(state$, 'lastRequestPlacardProcess', null)
            }
            if (action === PublicationActionName.BAT_PUBLICATION) {
                return updatePublication(state$, 'validationDate', new Date().toISOString())
            }
            if (action === PublicationActionName.GENERATE_PUBLICATION_PRINTER_PDF) {
                return updatePublication(state$, 'lastPrinterProcessStatus', JouveProcessStatus.PENDING)
            }
            if (action === PublicationActionName.INVALIDATE_PUBLICATION_PRINTER_PDF) {
                return updatePublication(state$, 'validationDate', undefined)
            }
            return EMPTY
        }),
    );
}

export function refreshArticleOnWorkflowSuccess(action$: Observable<Action>, state$: StateObservable<State>): Observable<Action> {
    return action$.pipe(
        filter(isDispatchArticleResponseAction),
        flatMap(({payload}) => {
            const {action} = payload;
            if (action === ArticleActionName.BAP_ARTICLE) {
                return merge(updateArticleAndBundle(state$, 'status', ArticleStatus.READY))
            }
            if (action === ArticleActionName.UPDATE_ARTICLE) {
                return merge(updateArticleAndBundle(state$, 'status', ArticleStatus.IN_PROGRESS))
            }
            if (action === ArticleActionName.GENERATE_ARTICLE_PLACARD) {
                return merge(updateArticleAndBundle(state$, 'lastRequestPlacardProcessStatus', JouveProcessStatus.PENDING))
            }
            if (action === ArticleActionName.UNLOCK_ARTICLE_PLACARD) {
                return merge(updateArticleAndBundle(state$, 'lastRequestPlacardProcessStatus', undefined))
            }
            return EMPTY
        }),
    );
}

const updatePublication = (state$: StateObservable<State>, attributeName: string, value: any): Observable<Action> => {
    if (state$.value.publication.organize.current) {
        return of(fetchPublicationSuccess({
            ...state$.value.publication.organize.current,
            [attributeName]: value
        }))
    }
    return EMPTY
}

const updateArticleAndBundle = (state$: StateObservable<State>, attributeName: string, value: any) => {
    let array = [] as ActionWithPayload[];
    if (state$.value.article.infos.currentFetchedArticle) {
        array.push(
            fetchArticleSuccess({
                ...state$.value.article.infos.currentFetchedArticle,
                [attributeName]: value
            })
        )
    }
    if (state$.value.bundle.organizeBundle.current) {
        array.push(fetchBundleSuccess({
            ...state$.value.bundle.organizeBundle.current!!,
            [attributeName]: value
        }))
    }
    return array
}


export function dispacthArticleWorkflowAction(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;
    return action$.pipe(
        filter(isDispatchArticleAction),
        flatMap(({payload}) => {
            const {id, action, uuid, context, forceOptions} = payload;
            const token = state$.value.auth.token ?? "";
            return _dispacthArticleWorkflowAction(i18n, axios, uuid, id, action, token, context, forceOptions)
        }),
    );
}

export function dispacthPublicationWorkflowAction(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;
    return action$.pipe(
        filter(isDispatchPublicationAction),
        flatMap(({payload}) => {
            const {id, action, uuid, context, forceOptions} = payload;
            const token = state$.value.auth.token ?? "";
            return _dispacthPublicationWorkflowAction(i18n, axios, uuid, id, action, token, context, forceOptions)
        }),
    );
}

export function dispacthMultipleArticlesWorkflowAction(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;
    return action$.pipe(
        filter(isDispatchMultipleArticlesAction),
        flatMap(({payload}) => {
            const {ids, action, uuid} = payload;
            const token = state$.value.auth.token ?? "";
            return _dispacthArticlesWorkflowAction(i18n, axios, uuid, ids, action, token)
        }),
    );
}

export function dispacthMultiplePublicationsWorkflowAction(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;
    return action$.pipe(
        filter(isDispatchMultiplePublicationsAction),
        flatMap(({payload}) => {
            const {ids, action, uuid} = payload;
            const token = state$.value.auth.token ?? "";
            return _dispacthPublicationsWorkflowAction(i18n, axios, uuid, ids, action, token)
        }),
    );
}

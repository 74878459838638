import {DateTime} from "luxon";
import {AuthorImage} from "./image";

export enum AuthorType {
    MALE = "MALE",
    FEMALE = "FEMALE",
    ORGANISATION = "ORGANISATION",
    UNKNOWN = "UNKNOWN"
}

export enum ContactTypes {
    PROFESSIONAL = "PROFESSIONAL",
    PERSONAL = "PERSONAL",
    OTHER = "OTHER"
}

export enum PhoneTypes {
    MOBILE = "MOBILE",
    FIXE = "FIX",
    FAX = "FAX"
}

export enum ProfileType {
    TWITTER = "TWITTER",
    LINKEDIN = "LINKEDIN",
    FACEBOOK = "FACEBOOK",
    WEBSITE = "WEBSITE",
    ORCID = "ORCID",
    ISNI = "ISNI"
}

export type AuthorEmail = {
    contact: ContactTypes
    fav: boolean
    mail: string
}

export type LinkAuthorArticles = {
    title: string
    modificationDate: DateTime
    type: AuthorLinkType,
    articleId: string
}

enum AuthorLinkType {
    BUNDLE = 'BUNDLE',
    ARTICLE = 'ARTICLE'
}

export type AuthorPhoneNumber = {
    contact: ContactTypes
    fav: boolean
    type?: PhoneTypes
    countryCode: string
    number: string
}

export type AuthorAddress = {
    contact: ContactTypes
    fav: boolean
    street: string
    complement: string
    city: string
    postCode: string
    country: string
}

export type AuthorProfile = {
    type?: ProfileType
    value: string
}

// field that can be edited in author
export interface EditableAuthor {
    type: AuthorType
    firstName: string
    particle: string
    lastName: string
    functions: string[]
    notes: string
    images: AuthorImage[]
    emails: AuthorEmail[]
    addresses: AuthorAddress[]
    phoneNumbers: AuthorPhoneNumber[]
    profiles: AuthorProfile[]
}

// field generated on save
export interface PersistedAuthor extends EditableAuthor {
    id: string
    knkId?: string
    creationDate: string
    creationUser: string
    modificationDate: string
    modificationUser: string
    enable: boolean
    used?: boolean
    lastArticlesModified?: LinkAuthorArticles[]
}

export const isSaved = (author?: any): author is PersistedAuthor => author?.id !== undefined

export type CreateOrUpdateFullAuthor = {
    id: string
    type: AuthorType
    firstName: string
    lastName: string
    particle: string
    addresses: AuthorAddress[]
    phoneNumbers: AuthorPhoneNumber[]
    emails: AuthorEmail[]
    notes: string
    creationDate: DateTime
    creationUser: string
    modificationUser: string
    modificationDate: DateTime
    enable: boolean
    functions: string[]
    profiles: AuthorProfile[]
    images: UpdateAuthorImage[]
}

export type UpdateAuthorImage = {
    id: string,
    fav: boolean,
    color: string,
    framing: string,
    credits: string,
    sources: string,
    comments: string
}

export type MappedFullAuthor = {
    id: string
    type: AuthorType
    firstName: string
    lastName: string
    particle: string
    notes: string
    creationDate: string
    creationUser: string
    modificationDate: string
    modificationUser: string
    used?: boolean
    enable: boolean
    functions: string[]
    contacts: AuthorContacts[]
    images: AuthorImage[]
    profiles: AuthorProfile[]
    lastArticlesModified?: LinkAuthorArticles[]
}

export type AuthorContacts = {
    contact: string
    emails: AuthorEmail[]
    phoneNumbers: AuthorPhoneNumber[]
    addresses: AuthorAddress[]
}

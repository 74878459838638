import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import React from "react";

type Props = {
    to: string
    title: string
    icon: any
    size?: "small" | "medium",
    buttonClassName?: any,
}

export default function LinkButton({to, title, icon, size, buttonClassName}: Props) {
    return (
        <Link to={to} component={RouterLink}>
            <Tooltip title={title}>
                <IconButton className={buttonClassName} size={size ? size : "medium"}>
                    {icon}
                </IconButton>
            </Tooltip>
        </Link>
    );
}

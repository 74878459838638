import React from "react";
import {Container} from "@material-ui/core";
import {setPeriodicalId, setTitle, setType,} from "../../../../redux/actions/admin/bundle/admin-bundle.actions";
import {selectRenderer} from "../../../commons/forms/LexFormField";
import {GridSize} from "@material-ui/core/Grid";
import LexForm from "../../../commons/forms/LexForm";
import {
    currentAdminBundlePlanPeriodicalIdSelector,
    currentAdminBundlePlanTitleSelector,
    currentAdminBundlePlanTypeSelector
} from "../../../../redux/selectors/admin/bundle/admin-bundle.selectors";
import {commonStyle} from "../../../../styles/common.style";
import {BundlePlanFormMode, BundleType} from "../../../../types/bundle";
import {useSelector} from "react-redux";
import {currentPeriodicalsSelector} from "../../../../redux/selectors/periodical.selectors";

type Props = {
    mode: BundlePlanFormMode
}
const BundlePlanInfos = ({mode}: Props) => {
    const classes = commonStyle();
    const periodicals = useSelector(currentPeriodicalsSelector);

    return (
        <Container maxWidth={"xl"} className={classes.root}>
            <LexForm
                type={"admin.bundle"}
                fields={[
                    {
                        name: "title",
                        selector: currentAdminBundlePlanTitleSelector,
                        action: setTitle,
                        disabled: false,
                        required: true,
                        column: 6 as GridSize,
                        shouldNotTranslate: true
                    }, {
                        name: "periodicalId",
                        selector: currentAdminBundlePlanPeriodicalIdSelector,
                        action: setPeriodicalId,
                        renderer: selectRenderer(periodicals.map(e => {
                                return {
                                    label: `[${e.id}] ${e.label}`,
                                    value: e.id
                                }
                            }
                        )),
                        disabled: mode === BundlePlanFormMode.EDIT,
                        required: true,
                        column: 6 as GridSize,
                        shouldNotTranslate: true
                    }, {
                        name: "type",
                        selector: currentAdminBundlePlanTypeSelector,
                        action: setType,
                        renderer: selectRenderer(Object.values(BundleType).filter((type) => type !== BundleType.UNKNOWN).map(x => {
                                return {
                                    label: `enums.articles.type.${x}.main`,
                                    value: x
                                }
                            }
                        )),
                        disabled: false,
                        required: true,
                        column: 6 as GridSize
                    }
                ]}
            />
        </Container>
    );
}

export default BundlePlanInfos

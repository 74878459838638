import React from "react";
import {useTranslation} from "react-i18next";
import {TablePagination as TablePaginationMui} from '@material-ui/core';
import {PageSize} from "../../../types/common-search";

type Props = {
    totalCount: number
    page: number
    size: number
    updateChangePage: (page: number) => void
    updateChangeRowPerPage: (page: PageSize) => void
}

export default function TablePagination({page, size, totalCount, updateChangePage, updateChangeRowPerPage}: Props) {
    const {t} = useTranslation();
    const handleChangePage = (event, newPage) => {
        updateChangePage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        updateChangeRowPerPage(event.target.value)
    };
    return (
        <TablePaginationMui
            rowsPerPageOptions={[10, 20, 30, 50]}
            component="div"
            count={totalCount}
            rowsPerPage={size}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            nextIconButtonText={t('misc.next')}
            backIconButtonText={t('misc.previous')}
            labelRowsPerPage={`${t('table.rows_per_page')} `}
            labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} ${t('table.on')} ${count !== -1 ? count : `${t('table.more_than')} ${to}`}`}
            onPageChange={() => {
            }}/>
    )
}

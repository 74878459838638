import {useSelector} from "react-redux";
import DateTime from "../../commons/DateTime";
import React from "react";
import {useTranslation} from "react-i18next";
import {koToMo} from "../../../utils/image.utils";
import InformationPanel from "../../commons/InformationPanel";
import {currentAdSelector} from "../../../redux/selectors/ads.selectors";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {truncateString} from "../../../utils/article-utils";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20
    },
    title: {
        fontWeight: 500,
        fontSize: '0.9rem',
        color: 'rgba(0, 0, 0, 0.75)'
    },
    ulSecond: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        "& li": {
            marginBottom: 5
        },
    }
}));

export default () => {
    const {t} = useTranslation()
    const classes = useStyles();

    const currentAd = useSelector(currentAdSelector);

    return (
        <InformationPanel
            rows={[
                {
                    label: t('misc.modification_date'),
                    value: <DateTime date={currentAd?.modificationDate ?? ''}/>,
                    show: currentAd.modificationDate != null
                },
                {
                    label: t('misc.format'),
                    value: currentAd.meta?.contentType,
                    show: currentAd.meta?.contentType != null
                },
                {
                    label: t('misc.file_size'),
                    value: koToMo(currentAd?.meta?.weight ?? 0),
                    show: currentAd?.meta?.weight != null
                },
                {
                    label: t('misc.resolution'),
                    value: (currentAd.meta?.width ?? '') + ' x ' + (currentAd?.meta?.height ?? '')
                        + ' px' + (currentAd.meta?.xdpi ? ' - ' + currentAd.meta?.xdpi + ' dpi' : ''),
                    show: currentAd.meta?.width != null
                },
                {
                    label: t('images.dimensions'),
                    value: currentAd.formatLabel,
                    show: currentAd.formatLabel != null
                }
            ]}
        >
            <Grid container>
                <ul className={classes.ulSecond}>
                    <Typography className={classes.title}>{t('misc.appears_in')}</Typography>
                    {
                        (currentAd.lastPublicationsModified && currentAd.lastPublicationsModified.length > 0) ? currentAd.lastPublicationsModified.map(x =>
                            <li><Link
                                to={'/' + t('routes.publication.root') + '/' + x.publicationId + '/' + t('routes.publication.organize')}
                                component={RouterLink}
                                style={{color: "black"}}
                            >{x.publicationName}{x.title !== '' ? '_' + truncateString(x.title, 40) : ''}</Link>
                            </li>) : " - "
                    }
                </ul>
            </Grid>

            {
                (currentAd.lastPublicationsModified && currentAd.lastPublicationsModified.length > 0) ?
                    <Grid container className={classes.root}>
                        <Button variant={"outlined"} component={RouterLink}
                                to={`/${t('routes.publication.root')}?imageFilter=${currentAd.id}`}
                                title={t('misc.see_all') + '...'}>{t('misc.see_all') + '...'}</Button>
                    </Grid> : <></>
            }
        </InformationPanel>
    )
}

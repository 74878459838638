import {IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {PublicationSearchResult, SearchContext} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {ConfirmBatIcon} from "../../commons/icon/icons";
import {PublicationActionName} from "../../../types/workflow";
import {dispatchPublicationAction} from "../../../redux/actions/workflow.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {uuidv4} from "../../../utils/uuid";
import {processInProgress} from "../../../redux/selectors/workflow.selectors";
import {PublicationStatus} from "../../../types/publication";
import Auth from "../../Auth";
import {ADMIN, PRODUCER} from "../../../utils/roles";
import ConfirmationDialog from "../../commons/ConfirmationDialog";


type Props = {
    publication: PublicationSearchResult
}

function BATPublication({publication}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const processInProgressSelector = useSelector(processInProgress)
    const [currentProcess, setCurrentProcess] = useState<string>();
    const [dialogOpen, openDialog] = useState(false);
    const closeDialog = () => openDialog(false)
    const displayLoading = () => {
        if (currentProcess && processInProgressSelector.includes(currentProcess)) {
            return <CircularProgress color="inherit" size={15} style={{marginTop: 15}}/>
        } else {
            return <IconButton
                onClick={() => openDialog(true)}>
                <ConfirmBatIcon/>
            </IconButton>
        }
    }


    const bat = (publicationId: number) => {
        const uuid = uuidv4();

        dispatch(dispatchPublicationAction({
            id: publicationId,
            action: PublicationActionName.BAT_PUBLICATION,
            uuid,
            context: SearchContext.PUBLICATION
        }))
        setCurrentProcess(uuid)

    }

    if (publication.validationDate === null && publication.status === PublicationStatus.PUBLISHING && publication.lastPrinterPdfProcessStatus === "FINISHED") {
        return (
            <Auth requiredRoles={[ADMIN, PRODUCER]}>
                <ConfirmationDialog
                    title={t('workflows.bat_confirmation')}
                    description={''}
                    actions={[
                        {label: t('dialogs.cancel'), color: 'default'},
                        {
                            label: t('dialogs.confirm'), action: () => {
                                bat(publication.id)
                            }, variant: 'contained'
                        }
                    ]}
                    isOpened={dialogOpen}
                    onClose={closeDialog}
                />
                <Tooltip style={{paddingLeft: "0px"}}
                         title={t('workflows.bat') ?? ''}>
                    {displayLoading()}
                </Tooltip>
            </Auth>
        )
    } else {
        return (<></>)
    }

}

export default BATPublication;

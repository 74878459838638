import React, {forwardRef, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {SnackbarContent, useSnackbar} from 'notistack';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '344px !important',
        },
    },
    cardSuccess: {
        backgroundColor: 'rgb(67, 160, 71)',
        width: '100%',
    },
    cardWarning: {
        backgroundColor: 'rgb(255, 152, 0)',
        width: '100%',
    },
    cardError: {
        width: '100%',
        backgroundColor: "rgb(211, 47, 47)"
    },
    typography: {
        color: "#fff"
    },
    actionRoot: {
        padding: '8px 8px 8px 16px',
        justifyContent: 'space-between',
    },
    icons: {
        marginLeft: 'auto',
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        padding: 16,
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: 'none',
    },
    item: {
        fontSize: '.8rem'
    }
}));

/**
 *       return merge(of<Action>(...Object.keys(err.response.data).filter((key, index) => key !== 'message').map((key, index) =>
 notify(key + ' : ' + err.response.data[key], {variant: "error"})
 )))
 * @param result
 */

const ImportReportNotification = forwardRef<HTMLDivElement, { id: string | number, importResult: string[] }>(
    ({
         id,
         importResult
     }, ref) => {
        const {t} = useTranslation()
        const classes = useStyles();
        const {closeSnackbar} = useSnackbar();

        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
        }, [id, closeSnackbar]);

        return (<>
            <SnackbarContent ref={ref} className={classes.root}>
                <Card className={classes.cardError}>
                    <CardActions classes={{root: classes.actionRoot}}>
                        <Typography className={classes.typography}>
                            {t('publications.import_failed')}
                        </Typography>
                        <div className={classes.icons}>
                            <IconButton className={classes.expand} onClick={handleDismiss}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    </CardActions>
                    <Paper className={classes.collapse}>
                        <Grid container direction="column">
                            {importResult.map(it =>
                                <Grid item>
                                    <Typography className={classes.item}>{it}</Typography>
                                </Grid>)}
                        </Grid>
                    </Paper>
                </Card>
            </SnackbarContent>
        </>);
    });

export default ImportReportNotification;

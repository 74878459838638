import React from 'react'
import Grid, {GridSize} from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import DateTime from "./DateTime";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20
    },
    delete: {
        marginTop: theme.spacing(2)
    },
    title: {
        fontWeight: 500,
        fontSize: '0.9rem',
        color: 'rgba(0, 0, 0, 0.75)'
    },
    ul: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        "& li": {
            marginBottom: 5
        }
    },
    MuiDividerVertical: {
        width: 2
    },
    info: {
        marginLeft: 30
    }
}));

type InformationPanelRow = {
    label: string
    value: any
    show: boolean
    renderer?: (label, value, classes) => JSX.Element
}

type InformationPanelProps = {
    rows: InformationPanelRow[]
    hideDivider?: boolean
    width?: GridSize
    disableMargin?: boolean
    children?: JSX.Element[] | JSX.Element
}

const render = (label, value, classes) => t => renderer => renderer(t(label), value, classes)

export const dateRenderer = (label, value, classes) => <div key={label}>
    <Typography className={classes.title}>{label}</Typography>
    <li>{value ? <DateTime date={value}/> : ''}</li>
</div>

export const listRenderer = listItemRenderer => (label, values, classes) => <div key={label}>
    <Typography className={classes.title}>{label}</Typography>
    <ul className={classes.ul}>
        {values.map(value => <li>{listItemRenderer(value)}</li>)}
    </ul>
</div>

const defaultRenderer = (label, value, classes) => <div key={label}>
    <Typography className={classes.title}>{label}</Typography>
    <li>{value}</li>
</div>

export default ({rows, hideDivider, width, disableMargin, children}: InformationPanelProps) => {
    const classes = useStyles()
    const {t} = useTranslation()

    return (
        <Grid container className={disableMargin ? "" : classes.root}>
            {!hideDivider && <Divider orientation="vertical" flexItem className={classes.MuiDividerVertical}/>}
            <Grid item xs={width ? width : 8} className={disableMargin ? "" : classes.info}>
                <Grid container>
                    <ul className={classes.ul}>
                        {rows
                            .filter(it => it.show)
                            .map(row => render(row.label, row.value, classes)(t)(row.renderer ? row.renderer : defaultRenderer))}
                    </ul>
                    {children}
                </Grid>
            </Grid>
        </Grid>
    )
}

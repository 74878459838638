import {IconButton, Tooltip} from "@material-ui/core";
import {DeleteIcon, DownloadIcon} from "../../../icon";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import GlobalGroupActions from "../../authors/list/GlobalGroupActions";
import {downloadImages} from "../../../utils/common-utils";
import {deleteManyIllustration} from "../../../redux/actions/image.actions"
import {useLocation} from "react-router-dom";
import {readImageQueryParams} from "../../../utils/image.utils";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import Auth from "../../Auth";
import {ADMIN, ADMIN_IMAGES} from "../../../utils/roles";
import {notify} from "../../../redux/actions/notification.actions";
import {ImageSearchResult, SearchContext} from "../../../types/common-search";

type Props = {
    grid?: boolean
}

const ImageListActions = ({grid}: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const searchLocation = useLocation().search;
    const queryParams = readImageQueryParams(searchLocation) // todo: mieux gérer le refresh
    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);

    const [images, totalCount, selectedIds, allSelected, allPageSelected]: [ImageSearchResult[], number, string[], boolean, boolean] =
        useSelector(({search}: State) => {
            const store = search.common[SearchContext.IMAGE];
            return [store.searchResults as ImageSearchResult[], store.totalCount, store.selectedIds, store.allSelected, store.allPageSelected];
        });

    const handleImageDeletion = useCallback(() => {
        dispatch(deleteManyIllustration({
            ids: selectedIds,
            ctx: SearchContext.IMAGE,
            filters: queryParams
        }))
    }, [dispatch, queryParams, selectedIds])

    const downloadImagesAction = () => {
        const imagesToDownload = images.filter(it => selectedIds.indexOf(it.id) !== -1);
        if (allSelected || imagesToDownload.length > 20) {
            dispatch(notify(t('misc.limit_download_size_message'), {
                variant: 'error'
            }));
        } else {
            downloadImages(imagesToDownload.map(it => ({id: it.id ?? '', collection: it.collection ?? 'UNKNOWN'})))
        }
    }

    return (<>
            <ConfirmationDialog
                title={t('images.delete_confirmation', {count: selectedIds.length})}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default'},
                    {label: t('dialogs.confirm'), action: handleImageDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />
            {selectedIds.length > 0 &&
            <GlobalGroupActions grid={grid} allSelected={allSelected} totalCount={totalCount}
                                allPageSelected={allPageSelected}
                                ctx={SearchContext.IMAGE}
                                selectedCount={selectedIds.length} type={"images"}>
                <Tooltip title={t('images.export_images') || ""}>
                    <IconButton onClick={downloadImagesAction}>
                        <DownloadIcon color="inherit"/>
                    </IconButton>
                </Tooltip>

                <Auth requiredRoles={[ADMIN, ADMIN_IMAGES]}>
                    <Tooltip title={t('images.delete') ?? ''}>
                        <span>
                            <IconButton
                                disabled={selectedIds.length === 0}
                                color="inherit"
                                onClick={() => openConfirmationDialog(true)}>
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </Auth>
            </GlobalGroupActions>
            }
        </>
    )
}

export default ImageListActions

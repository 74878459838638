import DateTime from "../../commons/DateTime";
import React from "react";
import {useTranslation} from "react-i18next";
import {koToMo} from "../../../utils/image.utils";
import InformationPanel from "../../commons/InformationPanel";
import {IllustrationImage} from "../../../types/image";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {truncateString} from "../../../utils/article-utils";

type Props = {
    image: IllustrationImage
}


const useStyles = makeStyles(() => ({
    root: {
        marginTop: 20
    },
    title: {
        fontWeight: 500,
        fontSize: '0.9rem',
        color: 'rgba(0, 0, 0, 0.75)'
    },
    ulSecond: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        "& li": {
            marginBottom: 5
        },
    }
}));

const ImageRightPanel = ({image}: Props) => {
    const {t} = useTranslation()
    const classes = useStyles();
    return (
        <InformationPanel
            rows={[
                {
                    label: t('misc.modification_date'),
                    value: <DateTime date={image?.modificationDate ?? ''}/>,
                    show: image.modificationDate != null
                },
                {
                    label: t('misc.format'),
                    value: image.meta?.contentType,
                    show: image.meta?.contentType != null
                },
                {
                    label: t('misc.file_size'),
                    value: koToMo(image?.meta?.weight ?? 0),
                    show: image?.meta?.weight != null
                },
                {
                    label: t('misc.resolution'),
                    value: (image.meta?.width ?? '') + ' x ' + (image?.meta?.height ?? '')
                        + ' px' + (image.meta?.xdpi ? ' - ' + image.meta?.xdpi + ' dpi' : ''),
                    show: image.meta?.width != null
                },
                {
                    label: t('misc.is_author'),
                    value: (image?.collection === 'AUTHOR' ?? false) ? 'Image auteur' : '',
                    show: image?.collection != null
                },
            ]}
        >
            <Grid container>
                <ul className={classes.ulSecond}>
                    <Typography className={classes.title}>{t('misc.appears_in')}</Typography>
                    {
                        (image.lastArticlesModified && image.lastArticlesModified.length > 0) ? image.lastArticlesModified.map(x =>
                            <li><Link
                                to={`/${x.type === "BUNDLE" ? t('routes.bundle.root') : t('routes.article.root')}/${x.articleId}`}
                                component={RouterLink}
                                style={{color: "black"}}
                            >{x.articleId}{x.title !== '' ? '_' + truncateString(x.title, 40) : ''}</Link>
                            </li>) : " - "
                    }
                </ul>
            </Grid>

            {
                (image.lastArticlesModified && image.lastArticlesModified.length > 0) ?
                    <Grid container className={classes.root}>
                        <Button variant={"outlined"} component={RouterLink}
                                to={`/${t('routes.article.root')}?imageFilter=${image.id}`}
                                title={t('misc.see_all') + '...'}>{t('misc.see_all') + '...'}</Button>
                    </Grid> : <></>
            }
        </InformationPanel>
    )
}

export default ImageRightPanel

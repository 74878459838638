import { defaultSearch, Search } from "../models/Search";
import { createContext, useContext } from "react";
import { defaultQuery, Query } from "../models/Query";
import { Pageable } from "../models/Pageable";
import { DocumentUnit } from "../models/DocumentUnit";
import { TreeModel } from "../models/TreeModels";
import { State } from "../types";

type SearchStateType = State<Search>;

export const SearchContext = createContext<SearchStateType>([
  defaultSearch,
  () => {},
]);

type DocumentUnitStateType = State<Query<Pageable<DocumentUnit>>>;

export const DocumentUnitContext = createContext<DocumentUnitStateType>([
  defaultQuery,
  () => {},
]);

type TreeStateStype = State<Query<TreeModel>>;

export const TreeContext = createContext<TreeStateStype>([
  defaultQuery,
  () => {},
]);


export const useDirtyTreeContext = (): [
  Query<TreeModel>,
  (tree: Query<TreeModel>) => void
] => {
  const [tree, setter] = useContext(TreeContext);

  const setTree = (tree: Query<TreeModel>) => {
    setter({ ...tree, data: { ...tree.data!!, isDirty: true } });
  };

  return [tree, setTree];
};

export type TreeTypeAndId = Omit<TreeModel, "nodes">

type TreeListStateType = State<Query<TreeTypeAndId[]>>;

export const TreeListContext = createContext<TreeListStateType>([
  defaultQuery,
  () => {},
]);

export type StatusToUpdateStateType = State<Record<string, number>>;

export const StatusToUpdateContext = createContext<StatusToUpdateStateType>([
  {},
  () => {},
]);

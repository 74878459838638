import {PlanNode} from "./plan-node";
import {Periodical} from "./periodical";
import {Publication} from "./publication";
import {JouveProcessStatus} from "./workflow";
import {BundleType} from "./bundle";
import {DateTime} from "luxon";

export enum ArticleNature {
    SIMPLE = "SIMPLE",
    COMPLEXE = "COMPLEXE"
}

export const articleNature = [ArticleNature.SIMPLE, ArticleNature.COMPLEXE]

export enum ArticleFilter {
    ARTICLE = "ARTICLE",
    BUNDLE = "BUNDLE"
}

export const articleFilter = [ArticleFilter.ARTICLE, ArticleFilter.BUNDLE]

export enum ArticleType {
    BRIEF = "BRIEF",
    THREADED = "THREADED",
    INTERVIEW = "INTERVIEW",
    COMMENTARY = "COMMENTARY",
    STUDY = "STUDY",
    FOCUS = "FOCUS",
    FORMULATE = "FORMULATE",
}

export const simpleType = [ArticleType.BRIEF, ArticleType.FOCUS, ArticleType.THREADED, ArticleType.INTERVIEW, ArticleType.COMMENTARY]
export const complexType = [ArticleType.STUDY, ArticleType.FORMULATE]

export enum ArticleStatus {
    IN_PROGRESS = "IN_PROGRESS",
    READY = "READY",
    PUBLISHING = "PUBLISHING",
    PUBLISHED = "PUBLISHED",
    PLACARD_GENERATION = "PLACARD_GENERATION",
    ONLINE = "ONLINE",
    NOT_ONLINE = 'NOT_ONLINE'
}

export type ArticleAttachment =
    "CDF"
    | "CDF_FIXE"
    | "CDF_BUNDLE"
    | "CDF_BUNDLE_FIXE"
    | "BUNDLE"
    | "BUNDLE_FIXE"
    | "NONE"

export const allAttachments: ArticleAttachment[] = ["CDF", "CDF_FIXE", "CDF_BUNDLE", "CDF_BUNDLE_FIXE", "BUNDLE", "BUNDLE_FIXE", "NONE"]
export const fixedAttachments: ArticleAttachment[] = ["CDF_FIXE", "CDF_BUNDLE_FIXE", "BUNDLE_FIXE"];
export const cdfAttachments: ArticleAttachment[] = ["CDF", "CDF_FIXE"];
export const bundleAttachments: ArticleAttachment[] = ["CDF_BUNDLE", "CDF_BUNDLE_FIXE", "BUNDLE", "BUNDLE_FIXE"];

export type AvailableArticleTags = {
    nombreDePages: string[]
}

export type ArticleTags = {
    nombreDePages?: string
}

export type ArticleDialogForm = {
    id?: string
    title?: string
    bundleId?: string
    bundlePlanVersionId?: string
    planNodeId?: string
    periodicalId?: string
    publicationId?: number
    type?: ArticleType | BundleType
    isBundle: boolean,
    tags?: ArticleTags
}

export type ArticleOnlineRequest = {
    delay?: DateTime,
    articleIds: string[]
}

export type ArticleOnlinePublicationTask = {
    id: string,
	name: string,
    title: string,
    executionDate: string
}
export type Article = {
    id?: string
    title?: string
    bundleId?: string
    planNode?: PlanNode
    periodicalId: string
    publicationId?: number
    type: ArticleType
    nature: ArticleNature
    isFrontCover: boolean
    status: ArticleStatus
    creationDate?: string
    creationUser?: string
    modificationDate?: string
    modificationUser?: string
    lastAvailablePlacardProcess?: string
    lastRequestPlacardProcessStatus?: JouveProcessStatus
    lastAvailablePlacardProcessStatus?: JouveProcessStatus
    publicationOnlineDate?: string
    charVolume: number
    charDaVolume: number
    nextPublicationOnlineUsername?: string
    nextPublicationOnlineDate?: string
}

export type ArticleResponse = Article & {
    isBundle: boolean
    periodical: Periodical
    publication?: Publication
    isValid?: boolean
    isImportant: boolean
    attached: "NONE" | "CDF" | "CDF_FIXE" | "CDF_BUNDLE" | "CDF_BUNDLE_FIXE" | "BUNDLE" | "BUNDLE_FIXE"
}

export type ArticlesDestinationRequest = {
    ids: string[]
    periodicalId: string
    publicationId?: number
    bundleId?: string
    planNodeId?: string
    title?: string
}

export type ArticleDestinationRequest = {
    id: string
    periodicalId: string
    publicationId?: number
    bundleId?: string
    planNodeId?: string
    title?: string
    isBundle: boolean
    tags?: ArticleTags
}

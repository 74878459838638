import {IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {PublicationSearchResult, SearchContext} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {UnlockIcon} from "../../commons/icon/icons";
import {PublicationActionName} from "../../../types/workflow";
import {dispatchPublicationAction} from "../../../redux/actions/workflow.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {uuidv4} from "../../../utils/uuid";
import {processInProgress} from "../../../redux/selectors/workflow.selectors";
import Auth from "../../Auth";
import {ADMIN} from "../../../utils/roles";


type Props = {
    publication: PublicationSearchResult,
    context?: SearchContext,
    size?: 'small' | 'medium'
}

function UnlockPdfPublication({publication, context, size}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const processInProgressSelector = useSelector(processInProgress)
    const [currentProcess, setCurrentProcess] = useState<string>();

    const displayLoading = () => {
        if (currentProcess && processInProgressSelector.includes(currentProcess)) {
            return <CircularProgress color="inherit" size={size && size === 'small' ? 10 : 15}
                                     style={size === undefined || size === 'medium' ? {marginTop: 15} : {}}/>
        } else {
            return <IconButton
                onClick={() => {
                    unlockPdf(publication.id)
                }}>
                <UnlockIcon/>
            </IconButton>
        }

    }

    const unlockPdf = (publicationId: number) => {
        const uuid = uuidv4();

        dispatch(dispatchPublicationAction({
            id: publicationId,
            action: PublicationActionName.UNLOCK_PUBLICATION_PLACARD,
            uuid,
            context
        }))
        setCurrentProcess(uuid)

    }

    if (publication.lastRequestPlacardProcessStatus !== null) {
        return (
            <Auth requiredRoles={[ADMIN]}>
                <Tooltip
                    title={t('workflows.unlock_placard') ?? ''}>
                    {displayLoading()}
                </Tooltip>
            </Auth>
        )
    } else {
        return (<></>)
    }
}

export default UnlockPdfPublication;

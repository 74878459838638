import {AxiosStatic} from "axios";
import {merge, Observable, of} from "rxjs";
import {Action} from "../../../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, concatMap, filter, map} from "rxjs/operators";
import {
    isSaveAdminPeriodicalPlan,
    isSetAdminPeriodicalAction,
    setAdminPeriodicalPlanCollection
} from "../../../actions/admin/periodical/admin-periodical.actions";
import {StateObservable} from "redux-observable";
import {State} from "../../../reducers";
import {EpicDependencies} from "../../../store";
import {notifyError, notifySuccess} from "../../../actions/notification.actions";
import {PlanNodeCollection} from "../../../../types/plan-node";
import i18next from "i18next";

function _fetchAdminPeriodicalPlan(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/publication_plan/periodical/${id}`, {headers}))
        .pipe(
            map((response) => setAdminPeriodicalPlanCollection(response.data)),
            catchError(err => merge(
                of(notifyError(+err.response.status, {type: 'PERIODICAL'}))
                )
            )
        )
}

function _save(axios: AxiosStatic, periodicalPlan: PlanNodeCollection, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    periodicalPlan.nodes = periodicalPlan.plan;
    // @ts-ignore
    delete periodicalPlan.plan;

    return fromPromise(axios.put(`/api/publication_plan/periodical/${periodicalPlan.periodicalId}`, periodicalPlan, {headers}))
        .pipe(
            map(() => notifySuccess(i18next.t('misc.save_plan_success'))),
            catchError(err => merge(
                of(notifyError(+err.response.status, {type: 'PERIODICAL'}))
                )
            )
        )
}

export function loadAdminPlanForPeriodical(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isSetAdminPeriodicalAction),
        concatMap((action) => {
            return _fetchAdminPeriodicalPlan(axios, action.payload.id, state$.value.auth.token ?? "")
        })
    )
}

export function saveAdminPlanForPeriodical(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSaveAdminPeriodicalPlan),
        concatMap((action) => {
            return _save(axios, action.payload, state$.value.auth.token ?? "")
        })
    )
}

import React from "react";
import {useTranslation} from "react-i18next";

import {SearchContext} from "../../types/common-search";
import {useDispatch} from "react-redux";
import {addSearchQueryParam, removeSearchQueryParam} from "../../redux/actions/common-search.actions";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

type Props = {
    marbreFilter?: boolean
}

export default ({marbreFilter}: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();


    const handleChange = (value: boolean, checked: boolean) => {
        if (checked) {
            dispatch(addSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'marbre',
                value: value
            }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'marbre',
                value: value
            }))
        }
    };


    return (
        <FormGroup row>
            <FormControlLabel
                control={<Checkbox
                    onChange={(event, check) => handleChange(true, check)}
                    checked={marbreFilter}
                />}
                label={t('misc.marbre')}
            />
        </FormGroup>
    )
}

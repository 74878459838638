import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import {Periodical} from "../../../types/periodical";
import {State} from "../../../redux/reducers";
import {updatePeriodicalSelection} from "../../../redux/actions/periodical.actions";
import {useTranslation} from "react-i18next";

type Props = {
    selectedOptions?: string[],
    selectedAction?: (periodicals: string[]) => void,
    label?: string
}

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 20
    }
}))

export default ({label, selectedOptions, selectedAction}: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation()

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>, value: Periodical | Periodical[] | null) => {
        if (selectedAction) {
            if (value === null) {
                selectedAction([])
            } else if (Array.isArray(value)) {
                selectedAction(value.map(e => e.id))
            } else {
                selectedAction([value.id])
            }
        }
    };

    const handleGlobalChange = (event: React.ChangeEvent<HTMLInputElement>, value: Periodical | Periodical[] | null) => {
        if (value === null) {
            dispatch(updatePeriodicalSelection([]))
        } else if (Array.isArray(value)) {
            dispatch(updatePeriodicalSelection(value.map(x => x.id)))
        } else {
            dispatch(updatePeriodicalSelection([value.id]))
        }
    };

    const initPeriodicals = useSelector(({periodical}: State) => periodical.periodicals)

    return (
        <Autocomplete openText={t('misc.open')}
                      clearText={t('misc.clear')}
                      closeText={t('misc.close')}
                      loadingText={t('misc.loading')}
                      noOptionsText={t('misc.no-options')}
                      multiple color={"secondary"}
                      options={initPeriodicals}
                      size={"medium"}
                      className={classes.select}
                      disableCloseOnSelect
                      fullWidth={true}
                      value={initPeriodicals.filter(x => selectedOptions?.includes(x.id))}
                      onChange={(event: any, newValue: Periodical | Periodical[] | null) => (selectedAction) ? handleFilterChange(event, newValue) : handleGlobalChange(event, newValue)}
                      getOptionLabel={(option) => option.id}
                      renderOption={(option, {selected}) => (
                          <React.Fragment>
                              <Checkbox
                                  checked={selected}
                              />
                              [{option.id}] - {option.label}
                          </React.Fragment>
                      )}
                      renderInput={(params) => (
                          <TextField color={"secondary"} {...params} variant="outlined" label={label}/>
                      )}
        />
    )
};

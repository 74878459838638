import React, {useContext, useEffect, useState} from "react";
import {Grid, RadioGroup, Table} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import {Search} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import TablePagination from "../../authors/list/TablePagination";
import {PlanNode} from "../../../types/plan-node";
import {flatMapIds, moveNodeInPlanAsChild, removeChildren} from "../../commons/tree/TreeViewer";
import {AdSearchResult, ArticleSearchResult, SearchContext} from "../../../types/common-search";
import {search} from "../../../redux/actions/common-search.actions";
import {planHasId} from "../../../utils/plan-utils";
import {fetchPublicationsOfPeriodical, setDirtyAction} from "../../../redux/actions/publication.actions";
import {deleteArticle} from "../../../redux/actions/article/article.actions";
import {isDoingRequestSelector} from "../../../redux/selectors/article/article.selectors";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import {DeleteArticleDialogContext} from "../../commons/context/DeleteArticleDialogContext";
import OrganizeRowTable from "./OrganizeRowTable";
import {forcePublicationOptions} from "../../../utils/publication-utils";
import {
    setQueryForceOptions,
    setSizePageForceOptions
} from "../../../redux/actions/search/force-search-options.actions";
import {pageSizeSelector, querySelector} from "../../../redux/selectors/forceOptions.selectors";

type Props = {
    nodes: PlanNode[]
    setNodes: any
    page: number
    setPage: any
    setIsAdMode: (adMode: boolean) => void
    isAdMode: boolean
}

function PublicationOrganizeTableComponent({nodes, setNodes, page, setPage, isAdMode, setIsAdMode}: Props) {
    const dispatch = useDispatch()
    const {id} = useParams() as { id: string }
    const {t} = useTranslation()
    const [isInit, setInit] = useState(false);

    const publication = useSelector(getCurrentPublication)
    const isDoingRequest = useSelector(isDoingRequestSelector)

    const query = useSelector(querySelector)
    const size = useSelector(pageSizeSelector)

    const handleChangeMode = (newMode: string) => {
        setIsAdMode(newMode === 'ad');
        setPage(0);
        dispatch(setQueryForceOptions(undefined))
    }

    const handleUnlink = (id: string) => () => {
        setNodes(removeChildren(nodes, [id]))
    }

    const handleLink = (elemId: string, name: string, id: string, isBundle?: boolean) => {
        setNodes(moveNodeInPlanAsChild(nodes, {
            id: elemId,
            name: name,
            sub: [],
            type: isAdMode ? "ADVERTISEMENT" : "ARTICLE",
            meta: isAdMode ? undefined : {
                isBundle: isBundle
            }
        } as PlanNode, {sub: [], type: "CONTAINER", id: id}))

        dispatch(setDirtyAction(true))
    }

    const [articles, ads, totalArticles, totalAds]: [ArticleSearchResult[], AdSearchResult[], number, number] =
        useSelector(({search}: State) =>
            [search.common[SearchContext.PUBLICATION_ARTICLE].searchResults,
                search.common[SearchContext.PUBLICATION_AD].searchResults,
                search.common[SearchContext.PUBLICATION_ARTICLE].totalCount,
                search.common[SearchContext.PUBLICATION_AD].totalCount]);

    const result: (AdSearchResult | ArticleSearchResult)[] = isAdMode ? ads : articles
    const resultIds = result
        .map(res => (res as any).planNodeId)

    const linkable = flatMapIds(nodes).filter(nodeId => resultIds.indexOf(nodeId) !== -1)

    useEffect(() => {
        if (publication?.periodicalId)
            dispatch(fetchPublicationsOfPeriodical(publication.periodicalId))
    }, [publication?.periodicalId, dispatch])

    useEffect(() => {
        new Promise<void>((resolve) => {
            dispatch(setSizePageForceOptions(50));
            dispatch(setQueryForceOptions(undefined));
            resolve();
        }).then(() => setInit(true));
    }, [dispatch])

    useEffect(() => {
        if (!id || id === 'undefined' || isDoingRequest || !isInit) {
        } else {
            if (isAdMode)
                dispatch(search({
                    ctx: SearchContext.PUBLICATION_AD,
                    filters: forcePublicationOptions(isAdMode, publication, query, size, page)
                }))

            if (!isAdMode && publication)
                dispatch(search({
                    ctx: SearchContext.PUBLICATION_ARTICLE,
                    filters: forcePublicationOptions(isAdMode, publication, query, size, page)
                }))
        }
    }, [id, publication?.id, isAdMode, query, page, size, isDoingRequest, isInit, dispatch, publication])

    const {openConfirmationDialog, articleBeingDeleted, confirmationDialogOpen} = useContext(DeleteArticleDialogContext)

    const handleArticleDeletion = () => {
        new Promise<void>((resolve) => {
            dispatch(deleteArticle(articleBeingDeleted))
            resolve();
        }).then(() => openConfirmationDialog(false))
    }

    return <>
        <ConfirmationDialog
            title={t(articleBeingDeleted.isBundle ? 'bundles.delete_confirmation' : 'articles.delete_confirmation')}
            description={''}
            actions={[
                {label: t('dialogs.cancel'), color: 'default', action: () => openConfirmationDialog(false)},
                {label: t('dialogs.confirm'), action: handleArticleDeletion, variant: 'contained'}
            ]}
            isOpened={confirmationDialogOpen}
            onClose={() => openConfirmationDialog(false)}
        />

        <TableContainer component={Paper} style={{padding: 8}}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell variant={"head"} colSpan={isAdMode ? 2 : 4}>
                            <Grid container spacing={1} alignItems="flex-end">
                                <Grid item>
                                    <Search/>
                                </Grid>
                                <Grid item xs={10}>
                                    <TextField
                                        label="Rechercher"
                                        fullWidth={true}
                                        value={query}
                                        onChange={(v) =>
                                            dispatch(setQueryForceOptions(v.target.value))
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell colSpan={3}>
                            <Grid container justify={"flex-end"}>
                                <RadioGroup
                                    row
                                    value={isAdMode ? 'ad' : 'article'}
                                    onChange={v => handleChangeMode(v.target.value)}
                                >
                                    <FormControlLabel control={<Radio
                                        value="article"
                                    />} label={"Articles et Lots"}/>

                                    <FormControlLabel control={<Radio
                                        value="ad"
                                    />} label={"Publicités"}/>
                                </RadioGroup>
                            </Grid>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>{t('misc.id')}</TableCell>
                        <TableCell>{t('misc.designation')}</TableCell>
                        <TableCell>{t('articles.modified')}</TableCell>
                        {!isAdMode && <TableCell align={"center"}>{t('articles.status.name')}</TableCell>}
                        <TableCell align={"center"}>{t('articles.character_volume')}</TableCell>
                        <TableCell align={"center"}>{t('misc.actions')}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {(!result || result?.length === 0) &&
                    <TableRow><TableCell colSpan={8} align={"center"}>{t('table.no_result')}</TableCell></TableRow>}

                    {result && result.map(it => <OrganizeRowTable
                        page={'publication'}
                        publication={publication}
                        unlink={handleUnlink(it.id)}
                        handleLink={handleLink}
                        queryId={query}
                        isLinked={planHasId(nodes, it.id)}
                        linkable={linkable.indexOf((it as any).planNodeId) !== -1}
                        infos={it}
                        isAdMode={isAdMode}
                        key={it.id}/>)}
                </TableBody>
            </Table>
            {!isAdMode && <TablePagination page={page} size={size}
                                           totalCount={totalArticles}
                                           updateChangePage={(page) => {
                                               setPage(page)
                                           }}
                                           updateChangeRowPerPage={(size) => {
                                               setPage(0)
                                               dispatch(setSizePageForceOptions(size))
                                           }}/>}
            {isAdMode && <TablePagination page={page} size={size}
                                          totalCount={totalAds}
                                          updateChangePage={(page) => {
                                              setPage(page)
                                          }}
                                          updateChangeRowPerPage={(size) => {
                                              setPage(0)
                                              dispatch(setSizePageForceOptions(size))
                                          }}/>}
        </TableContainer>
    </>
}

const PublicationOrganizeTable = (props: Props) => <PublicationOrganizeTableComponent {...props}/>
export default PublicationOrganizeTable


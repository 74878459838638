import {createSelector} from "reselect";
import {State} from "../reducers";

export const adFormSelector = createSelector((state: State) => state.ads, ad => ad.form)
export const isCurrentAdDirtySelector = createSelector(adFormSelector, form => form.isDirty)
export const currentAdSelector = createSelector(adFormSelector, form => form.currentAd)
export const currentAdIdSelector = createSelector(currentAdSelector, ad => ad?.id)
export const currentAdNameSelector = createSelector(currentAdSelector, ad => ad?.name)
export const currentAdDescriptionSelector = createSelector(currentAdSelector, ad => ad?.comments)
export const currentAdDeadlineSelector = createSelector(currentAdSelector, ad => ad?.deadline)
export const currentAdFormatSelector = createSelector(currentAdSelector, ad => ad?.format)
export const currentAdColorSelector = createSelector(currentAdSelector, ad => ad?.color)
export const currentAdPeriodicalsSelector = createSelector(currentAdSelector, ad => ad?.periodicals)
export const currentAdPrioritySelector = createSelector(currentAdSelector, ad => ad?.priority)

export const currentUploadedAdSelector = createSelector(({ads}: State) => ads, ad => ad.currentUploadedImage)
export const uploadingAd = createSelector(({ads}: State) => ads, ad => ad.uploading)

const periodicalIdsSelector = ({periodical}: State) => periodical.periodicals.map(({id}) => id)
const periodicalAdsConfigSelector = (state: State) => state.ads.config

export const currentValidPeriodicalSelector = createSelector(periodicalIdsSelector, periodicalAdsConfigSelector, currentAdFormatSelector, currentAdColorSelector, (ids, config, format, color) => {

    console.log('config : ', config)
    return ids.filter(id => {
        const curr = config[id]
        if (curr) {
            return curr.taille.includes(format) && curr.couleurs.includes(color);
        } else {
            return true;
        }
    })

})

import {Action} from "../actions/utils.actions";
import {isInitFontoSessionSuccessAction} from "../actions/fonto.actions";
import {InitFontoSession} from "../../types/fonto";

export type FontoState = {
    session: InitFontoSession
}

const init: FontoState = {
    session: {
        id: "",
        userName: ""
    }
}

export default function FontoReducer(state = init, action: Action): FontoState {
    if (isInitFontoSessionSuccessAction(action)) {
        return {
            ...state,
            session: action.payload
        }
    } else {
        return state
    }
}

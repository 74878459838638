import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {ActionWithPayload} from "../../redux/actions/utils.actions";
import {State} from "../../redux/reducers";
import {isAuth} from "../Auth";

const useStyles = makeStyles(() => ({
    right: {
        paddingLeft: 50
    },
	noMargin: {
		margin: 0
	}
}));

type Props = {
    id: string
    enabled: boolean
    requiredRoles: string[]
    action: (_) => ActionWithPayload
    label?: string,
    reverse?: boolean,
	style?: 'right' | 'noMargin'
	labelPlacement?: 'top' | 'start' | 'bottom' | 'end'
};

export default ({id, enabled, requiredRoles, action, label, reverse, style = 'right', labelPlacement = 'end'}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const dispatchAction = (id: string, flag: boolean) => dispatch(action({id, flag}))
    const canChange = useSelector(({auth}: State) => isAuth(auth.roles, requiredRoles))

    return (
        <FormControlLabel className={classes[style]}
                          control={
                              <Switch
                                  disabled={!canChange}
                                  name={"toggle" + id}
                                  color="secondary"
                                  checked={enabled}
                                  onClick={() => (!canChange) ? null :
                                      dispatchAction(id, reverse ? enabled : !enabled)
                                  }
                              />
                          }
                          label={label}
						  labelPlacement={labelPlacement}
        />
    )
}

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import {useTranslation} from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {PublicationSearchFilters, PublicationSearchResult, SearchContext} from "../../../types/common-search";
import {makeSortHandler, sortDirection} from "../../../utils/table-utils";
import {tableStyle} from "../../../styles/table.style";
import Date from "../../commons/Date";
import DateTime from "../../commons/DateTime";
import PublicationTableActions from "./PublicationTableActions";
import PublicationTableStatus from "./PublicationTableStatus";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";

const mapper = {
    UPDATE: {default: "DESC", DESC: "ASC", ASC: ""},
    ID: {default: "ASC", DESC: "ASC", ASC: "DESC"},
    START: {default: "ASC", DESC: "ASC", ASC: "DESC"},
}

type LexPublicationListProps = {
    publications: PublicationSearchResult[]
    filters: PublicationSearchFilters
}

const PublicationTable = ({publications, filters}: LexPublicationListProps) => {
    const theme = useTheme()
    const classes = tableStyle(theme)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const loaded = useSelector((state: State) => state.search.common[SearchContext.PUBLICATION].status === 'LOADED')
    const sortHandler = makeSortHandler(SearchContext.PUBLICATION, mapper, filters, dispatch, updateSearchQueryParam)

    return (<>
            <TableContainer className={classes.root}>
                <Table aria-label={t('publications.list')}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "ID") !== undefined}
                                    direction={sortDirection(filters, "ID")}
                                    onClick={sortHandler("ID")}>
                                    {t('misc.id')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {t('publications.edit_type')}
                            </TableCell>

                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "START") !== undefined}
                                    direction={sortDirection(filters, "START")}
                                    onClick={sortHandler("START")}>
                                    {t('publications.publication_date')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "UPDATE") !== undefined}
                                    direction={sortDirection(filters, "UPDATE")}
                                    onClick={sortHandler("UPDATE")}>
                                    {t('publications.modified')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {t('publications.status')}
                            </TableCell>
                            <TableCell align={"center"} className={classes.cell}>
                                {t('misc.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>

                        {publications.length > 0 && publications.map(publication =>
                            <TableRow key={publication.id} className={`${classes.row}`}
                                      hover={true}>

                                <TableCell className={classes.cell}>
                                    <Link
                                        to={t('routes.publication.root') + '/' + publication.id + '/' + t('routes.publication.organize')}
                                        component={RouterLink}
                                        color="inherit"
                                    >
                                        {publication.periodicalId}_{publication.year}_{publication.number}
                                    </Link>
                                </TableCell>

                                <TableCell className={classes.cell}>
                                    {t(`enums.publications.editionType.${publication.editionType}`)}
                                </TableCell>


                                <TableCell className={classes.cell}>
                                    <Date date={publication.startDate}/>
                                </TableCell>

                                <TableCell className={classes.cell}>
                                    <DateTime date={publication.modificationDate}/>
                                </TableCell>

                                <TableCell className={classes.cell}>
                                    <PublicationTableStatus publication={publication}/>
                                </TableCell>

                                <TableCell className={classes.cell}>
                                    <PublicationTableActions publication={publication}/>
                                </TableCell>
                            </TableRow>
                        )}

                        {(publications.length === 0 && loaded) && (
                            <TableRow>
                                <TableCell colSpan={9} align={"center"}>
                                    {t('table.no_result')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default PublicationTable

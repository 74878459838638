import React from "react";
import {AppBar, Toolbar} from "@material-ui/core";
import UserMenu from "./UserMenu";
import {makeStyles} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    toolBar: {
        minHeight: '48px'
    },
    title: {
        fontWeight: "bold"
    },
    appBar: {
        // marginTop: '-4px',
        backgroundColor: "#2e2e2e"
    },
    space: {
        height: '4px'
    }
});

const LexAppBar = () => {
    const classes = useStyles();

    // todo: inutile ??
    const pendingStatus = useSelector(() => false);

    return (
        <AppBar position="sticky" className={classes.appBar}>
            {(pendingStatus) ? <LinearProgress variant={"indeterminate"}/> : <div className={classes.space}/>}
            <Toolbar className={classes.toolBar}>
                <UserMenu/>
            </Toolbar>
        </AppBar>)
}

export default LexAppBar

export const palette = {
  primary: "#e64b4f",
  borderColor: "#C3CFD9",
  headerColor: "#2E2E2E",
  attachedUDColor: "#C3CFD9",
  unattachedUDColor: "#000000",
  attachedStatusBorderCircle: "#1AAE9F",
  attachedStatusBackgroundCircle: "#D1EFEC",
  unattachedStatusBorderCircle: "#9EADBA",
  unattachedStatusBackgroundCircle: "#E3E8ED",
  lexpressLink: "#f6ca45",
  oxygenLink: "#FFFFFF",
};

export const treeColor = [
  "#4200db",
  "#1AAE9F",
  "#F7C325",
  "#ec00d4",
  "#00b9ec",
  "#ffa600",
  "#ff1e00",
  "#0000ff",
  "#000",
];

import {Grid} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {PublicationSearchResult} from "../../../types/common-search";
import {GeneratingPdfIcon, LockIcon, OnlineIcon, ScheduledIcon} from "../../commons/icon/icons";
import Tooltip from "@material-ui/core/Tooltip";
import {PublicationStatus} from "../../../types/publication";
import PdfPrinterStatusAndDownload from "../workflow/PdfPrinterStatusAndDownload";
import PlacardStatusAndDownload from "../workflow/PlacardStatusAndDownload";
import {JouveProcessStatus} from "../../../types/workflow";
import DateTime from "../../commons/DateTime";


type LexPublicationListProps = {
    publication: PublicationSearchResult
}

const PublicationTableStatus = ({publication}: LexPublicationListProps) => {
    const {t} = useTranslation()
    return (
        <Grid container spacing={1}>
            <Grid item>{t(`enums.publications.status.${publication.status}`)}</Grid>

            <PlacardStatusAndDownload id={publication.id} type={"publication"}
                                      lastAvailablePlacardProcessStatus={publication.lastAvailablePlacardProcessStatus}/>

            {(publication.lastRequestPlacardProcessStatus === JouveProcessStatus.GEN_REQUEST_PACKAGE
                || publication.lastRequestPlacardProcessStatus === JouveProcessStatus.PENDING
                || publication.lastRequestPlacardProcessStatus === JouveProcessStatus.GEN_RESULT_PACKAGE) &&
            <Grid item><Tooltip title={t('workflows.placard_in_progress') ?? ""}>
                <span><GeneratingPdfIcon/></span>
            </Tooltip></Grid>}


            {(publication.publicationOnlineDate) &&
            <Grid item>
                <Tooltip title={<DateTime date={publication.publicationOnlineDate}/>}>
                    <span><OnlineIcon htmlColor={((publication.modificationDate && publication.modificationDate > publication.publicationOnlineDate) ? "orange" : "green")}/></span>
                </Tooltip>
            </Grid>}


            {(publication.lastPrinterPdfProcessStatus === JouveProcessStatus.GEN_REQUEST_PACKAGE
                || publication.lastPrinterPdfProcessStatus === JouveProcessStatus.PENDING
                || publication.lastPrinterPdfProcessStatus === JouveProcessStatus.GEN_RESULT_PACKAGE) &&
            <Grid item><Tooltip title={t('workflows.publishing') ?? ""}>
                <span><LockIcon/></span>
            </Tooltip></Grid>}

            {(publication.validationDate !== null && publication.status === PublicationStatus.PUBLISHING) &&
            <Grid item>
                <span><LockIcon/></span></Grid>}

            {/* TODO Mis en ligne */}
            <PdfPrinterStatusAndDownload publication={publication}/>

			{
				publication.scheduledDate &&
				<Grid item>
					<Tooltip title={<>Planifié le <DateTime
                        date={publication.scheduledDate.toString()}/> par {publication.username}</>}>
						<span><ScheduledIcon/></span>
                    </Tooltip>
				</Grid>
			}

            {/* TODO archivé */}
        </Grid>
    )
}

export default PublicationTableStatus

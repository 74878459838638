import {Action} from "../actions/utils.actions";
import {SavedImage} from "../../types/image";
import {AdPriority, AdSize, Advertisement} from "../../types/ads";
import {
    isFetchAdAction,
    isFetchAdConfigSuccessAction,
    isFetchAdSizesSuccessAction,
    isFetchAdSuccessAction,
    isGenerateNewAdAction,
    isReplaceAdImageAction,
    isReplaceAdImageError,
    isReplaceAdImageSuccess,
    isSaveAdSuccessAction,
    isSetAdColorAction,
    isSetAdDeadlineAction,
    isSetAdDescriptionAction,
    isSetAdFormatAction,
    isSetAdNameAction,
    isSetAdPeriodicalsAction,
    isSetAdPriorityAction,
    isToggleAdDisabledSuccessAction,
    isUploadAdImageAction,
    isUploadAdImageErrorAction,
    isUploadAdImageSuccessAction
} from "../actions/ad.actions";

type AdState = {
    form: {
        isDirty: boolean,
        currentAd: Advertisement,
    },
    uploading: boolean,
    uploadN: number,
    lastUploadedImage?: SavedImage,
    currentUploadedImage?: SavedImage,
    sizes: AdSize[]
    config: object
}

const emptyAd = (): Advertisement => ({
    id: '',
    name: '',
    used: false,
    disabled: false,
    comments: '',
    priority: false,
    periodicals: [],
    version: 0
})

const init: AdState = {
    form: {
        isDirty: false,
        currentAd: emptyAd(),
    },
    uploading: false,
    uploadN: 0,
    sizes: [],
    config: {},
}
const reducer = (state = init, action: Action): AdState => {
    if (isUploadAdImageAction(action)) {
        return {...state, uploading: true, currentUploadedImage: undefined}
    } else if (isFetchAdAction(action)) {
        return init
    } else if (isFetchAdSizesSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form
            },
            sizes: action.payload,
        }
    } else if (isFetchAdConfigSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form
            },
            config: action.payload,
        }
    } else if (isToggleAdDisabledSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                currentAd: {
                    ...state.form.currentAd,
                    disabled: action.payload
                }
            }
        }
    } else if (isSaveAdSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                isDirty: false
            }
        }
    } else if (isReplaceAdImageAction(action)) {
        return {
            ...state,
            uploading: true,
            currentUploadedImage: undefined
        }
    } else if (isReplaceAdImageSuccess(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                currentAd: {
                    ...state.form.currentAd,
                    ...action.payload
                }
            },
            uploadN: state.uploadN + 1,
            lastUploadedImage: {
                format: action.payload.format,
                meta: action.payload.meta,
                id: action.payload.id,
                version: action.payload.version
            },
            currentUploadedImage: {
                format: action.payload.format,
                meta: action.payload.meta,
                id: action.payload.id,
                version: action.payload.version
            },
            uploading: false
        }
    } else if (isFetchAdSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                currentAd: {
                    ...state.form.currentAd,
                    ...action.payload
                }
            },
            uploadN: state.uploadN + 1,
            lastUploadedImage: {
                format: action.payload.format!,
                meta: action.payload.meta!,
                id: action.payload.id,
                version: action.payload.version
            },
            currentUploadedImage: {
                format: action.payload.format!,
                meta: action.payload.meta!,
                id: action.payload.id,
                version: action.payload.version
            },
            uploading: false
        }
    } else if (isReplaceAdImageError(action)) {
        return {
            ...state,
            currentUploadedImage: state.lastUploadedImage ? {
                ...state.lastUploadedImage
            } : undefined,
            uploading: false
        }
    } else if (isUploadAdImageSuccessAction(action)) {
        return {
            ...state,
            uploading: false,
            lastUploadedImage: action.payload,
            currentUploadedImage: action.payload,
            form: {
                ...state.form,
                currentAd: {
                    ...state.form.currentAd,
                    id: action.payload.id,
                    meta: action.payload.meta,
                    format: action.payload.format
                },
            },
            uploadN: state.uploadN + 1
        }
    } else if (isUploadAdImageErrorAction(action)) {
        return {
            ...state,
            uploading: false,
            currentUploadedImage: state.lastUploadedImage ? {
                ...state.lastUploadedImage
            } : undefined,
        }
    } else if (isFetchAdSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                currentAd: action.payload,
                isDirty: false
            }
        }
    } else if (isGenerateNewAdAction(action)) {
        return {
            ...state, form: {
                isDirty: false,
                currentAd: emptyAd()
            },
            currentUploadedImage: undefined,
            lastUploadedImage: undefined,
            uploading: false
        }
    } else if (isSetAdNameAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentAd: {
                    ...state.form.currentAd,
                    name: action.payload
                }
            }
        }
    } else if (isSetAdDescriptionAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentAd: {
                    ...state.form.currentAd,
                    comments: action.payload
                }
            }
        }
    } else if (isSetAdDeadlineAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentAd: {
                    ...state.form.currentAd,
                    deadline: action.payload
                }
            }
        }
    } else if (isSetAdFormatAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentAd: {
                    ...state.form.currentAd,
                    format: action.payload
                }
            }
        }
    } else if (isSetAdColorAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentAd: {
                    ...state.form.currentAd,
                    color: action.payload
                }
            }
        }
    } else if (isSetAdPeriodicalsAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentAd: {
                    ...state.form.currentAd,
                    periodicals: action.payload
                }
            }
        }
    } else if (isSetAdPriorityAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentAd: {
                    ...state.form.currentAd,
                    priority: action.payload === AdPriority.PRIORITY
                }
            }
        }
    } else {
        return state;
    }
}

export default reducer

import {EMPTY, Observable, of} from "rxjs";
import {Action} from "../../actions/utils.actions";
import {catchError, filter, flatMap, map, mergeMap} from "rxjs/operators";
import {
    closeNewPublicationDialog,
    createPublicationError,
    createPublicationSuccess,
    editPublicationSuccess,
    generateNewPublication,
    isCreatePublicationAction,
    isEditPublicationAction,
    isOpenEditPublicationDialogAction,
    isOpenNewPublicationDialogAction,
    setEditPublicationId
} from "../../actions/publication.actions";
import {StateObservable} from "redux-observable";
import {State} from "../../reducers";
import {EpicDependencies} from "../../store";
import {fromPromise} from "rxjs/internal-compatibility";
import {notifyError, notifySuccess} from "../../actions/notification.actions";
import i18n from "i18next";
import {AxiosStatic} from "axios";
import {PublicationDialogForm} from "../../reducers/publication/new-publication-dialog.reducer";
import {EditablePublication, EditionType} from "../../../types/publication";
import {SearchContext} from "../../../types/common-search";
import {reloadSearch} from "../../actions/common-search.actions";
import {mandatoryPublicationFieldsCompleted} from "../../../utils/publication-utils";

export function generateNewPublicationFormEpic(action$: Observable<Action>): Observable<Action> {
    return action$.pipe(
        filter(isOpenNewPublicationDialogAction),
        map(() => {
            const fav = sessionStorage.getItem('fav')?.split(',') ?? [];
            const date = new Date();
            return generateNewPublication({
                periodicalId: fav.length === 1 ? fav[0] : undefined,
                date: date.toISOString(),
                year: date.getFullYear(),
            })
        })
    )
}

export function loadEditPublicationFormEpic(action$: Observable<Action>): Observable<Action> {
    return action$.pipe(
        filter(isOpenEditPublicationDialogAction),
        map((action) => {
            return setEditPublicationId(action.payload);
        })
    )
}

function form2NewEntity(form: PublicationDialogForm): EditablePublication {
    const {
        periodicalId,
        number,
        editionType,
        article,
        image,
        text,
        ...base
    } = form
    return {
        periodicalId: periodicalId!,
        number: editionType === EditionType.STANDARD ? number.filter(str => str).join('-') : `hors-serie`.concat(number[0] && number[0] !== '' ? `_${number[0]}` : '').trim(),
        articleId: periodicalId === 'DEF' || periodicalId === 'GPL' ? article?.value : undefined,
        imageId: periodicalId === 'DEF' || periodicalId === 'GPL' ? image?.value : undefined,
        text: periodicalId === 'GPL' ? text : undefined,
        editionType,
        ...base
    }
}

function postNewPublication(axios: AxiosStatic, publication: EditablePublication, token: string): Observable<Action> {
    if (!mandatoryPublicationFieldsCompleted(publication)) {
        return EMPTY
    }
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.post(`/api/publication`, publication, {headers}))
        .pipe(
            mergeMap(() => of(
                reloadSearch({ctx: SearchContext.PUBLICATION}),
                createPublicationSuccess(),
                closeNewPublicationDialog(),
                notifySuccess(i18n.t('publications.save_success'))),
            ),
            catchError(err =>
                treatExceptions(err, publication)
            )
        )
}

function treatExceptions(err, publication): Observable<Action> {
    if (err.response.status === 409) {
        return of(
            createPublicationError(),
            // todo: https://kazupon.github.io/vue-i18n/guide/messages.html#formatting-linked-locale-messages
            // utiliser lower d'une façon ou d'une autre
            notifyError(+err.response.status, {
                idtype: `nom (${publication.periodicalId}_${publication.year}_${publication.number})`,
                type: 'L_PUBLICATION'
            })
        )
    }
    if (err.response.status === 412) {
        return of(
            createPublicationError(),
            notifyError(err.response.data.message, {
                type: 'L_PUBLICATION'
            })
        )
    }
    return of(
        createPublicationError(),
        closeNewPublicationDialog(),
        // todo: https://kazupon.github.io/vue-i18n/guide/messages.html#formatting-linked-locale-messages
        // utiliser lower d'une façon ou d'une autre
        notifyError(+err.response.status, {
            idtype: `numéro`,
            type: 'PUBLICATION'
        })
    )
}

function putEditPublication(axios: AxiosStatic, id: number, publication: EditablePublication, token: string): Observable<Action> {
    if (!mandatoryPublicationFieldsCompleted(publication)) {
        return EMPTY
    }

    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.put(`/api/publication/${id}`, publication, {headers}))
        .pipe(
            mergeMap(() => of(
                reloadSearch({ctx: SearchContext.PUBLICATION}),
                editPublicationSuccess(id),
                closeNewPublicationDialog(),
                notifySuccess(i18n.t('publications.update_success'))),
            ),
            catchError(err => treatExceptions(err, publication))
        )
}

export function createPublicationEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isCreatePublicationAction),
        flatMap(() => {
            const token = state$.value.auth.token ?? '';
            const form = state$.value.publication.dialog.form!;
            const publication = form2NewEntity(form);
            return postNewPublication(axios, publication, token)
        })
    );
}

export function editPublicationEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isEditPublicationAction),
        flatMap(() => {
            const token = state$.value.auth.token ?? '';
            const form = state$.value.publication.dialog.form!;
            const publicationId = state$.value.publication.dialog.currentEditPublicationId!;
            const publication = form2NewEntity(form);
            return putEditPublication(axios, publicationId, publication, token)
        })
    );
}


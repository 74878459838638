import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import TrueFalseFilter from "../../authors/list/TrueFalseFilter";

const EnableFilter = ({filter, addElementAction, removeElementAction, context}) => {
    const {t} = useTranslation();

    return (
        <ListItem label={t('images.state')}>
            <TrueFalseFilter ctx={context} trueLabel={t('enums.images.state.ENABLE')}
                             falseLabel={t('enums.images.state.DISABLE')}
                             removeSearchQueryAction={removeElementAction} addSearchQueryAction={addElementAction}
                             field={"enableFilter"}
                             values={filter}/>
        </ListItem>
    )
}

export default EnableFilter

import {createActionWithPayload} from "./utils.actions";
import {Proposal} from "../../types/quick-search";

export const [typeQuickSearch, isTypeQuickSearchAction] = createActionWithPayload<string>('TYPE_QUICK_SEARCH');
export const [fetchQuickSearchProposalsSuccess, isFetchQuickSearchProposalsSuccessAction] = createActionWithPayload<Proposal[]>('FETCH_QUICK_SEARCH_PROPOSAL_SUCCESS');
export const [fetchQuickSearchProposalsError, isFetchQuickSearchProposalsErrorAction] = createActionWithPayload<string>('FETCH_QUICK_SEARCH_PROPOSAL_ERROR');
export const [selectQuickSearchProposal, isSelectQuickSearchProposalAction] = createActionWithPayload<Proposal>('SELECT_QUICK_SEARCH_PROPOSAL');

export const [countQuickSearchResults, isCountQuickSearchResultsAction] = createActionWithPayload<{ type: string, query: string }>('COUNT_QUICK_SEARCH');
export const [countQuickSearchSucces, isCountQuickSearchSuccesAction] = createActionWithPayload<{ type: string, count: number }>('SUCCES_COUNT_QUICK_SEARCH');
export const [countQuickSearchError, isCountQuickSearchErrorAction] = createActionWithPayload<string>('ERROR_COUNT_QUICK_SEARCH');


import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DateRangePicker, { DateRangePickerProps } from '../../components/commons/DateRangePicker';
import React from 'react';
import { palette } from '../palette';

export const kyrTheme = createMuiTheme({
  palette: {
    primary: {
      main: palette.primary,
    },
    secondary: {
      main: palette.primary,
    },
  },
  typography: {
    fontFamily: ['"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(', '),
    fontSize: 12,
    fontWeightRegular: 300,
  },
});

export const KyrDateRangePicker = (props: DateRangePickerProps) => {

  return (
    <ThemeProvider theme={kyrTheme}>
      <DateRangePicker inverted {...props} />
    </ThemeProvider>
  )
}
import React from 'react';
import { Typography, TypographyProps } from "@material-ui/core";

export const KyrTitle = (props: TypographyProps) => {
    const { children, style, ...otherProps } = props

    return (
    <Typography variant="h6" style={{...style, fontWeight: 'bold'}} {...otherProps}>
        {children}
    </Typography>
    )
}
import { useContext } from "react";
import {
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop,
} from "react-dnd";
import { NODE } from "../DnDTypes";
import { TileContext } from "../contexts/administrateTreeContexts";
import { useDirtyTreeContext } from "../contexts/organizeTreeBibleContexts";
import {
  NodeModel,
  addNodeBetween,
  addNodeIntoTree,
  createNodeModel,
  findMaxDepth,
  findNodeDepth,
  getParentId,
  removeNode,
} from "../models/TreeModels";
import { TileProps } from "../pages/administrateTree/AddElement";
import { isNodeModel, isTile } from "../pages/organizeTree/tree/tree.utils";
import { MAX_DEPTH_LEVEL } from "../constantes";

/**
 * Custom hook for dragging a node from a tree.
 * @param node - The node or tile props to be dragged.
 * @returns An array containing the drag source and a boolean indicating whether the item is currently being dragged.
 */
export const useDragNodeFromTree = (
  node: NodeModel | TileProps
): [ConnectDragSource, boolean] => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: NODE,
      item: node,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [node]
  );

  return [drag, isDragging];
};

/**
 * Custom React hook that enables dropping a node on a node title.
 *
 * @param node - The node to drop on.
 * @returns An array containing the drop target and a boolean indicating if the drop is currently over the target.
 */
export const useDropNodeOnNodeTitle = (
  node: NodeModel
): [ConnectDropTarget, boolean] => {
  const [tree, setTree] = useDirtyTreeContext();
  const [, setTiles] = useContext(TileContext);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: NODE,
      drop: (item: TileProps | NodeModel) => {
        if (tree.data === undefined) return;

        const depthLevel = findNodeDepth(tree.data.nodes, node.id);
        if (depthLevel === MAX_DEPTH_LEVEL) return; // Pas plus de 8 sous-niveaux

        if (isTile(item)) {
            const newNode: NodeModel = createNodeModel(item.label)
            const updatedNodes = addNodeIntoTree(
              tree.data.nodes,
              node.id,
              newNode
            );
            setTiles((prev) => prev.filter((_, index) => index !== item.index));
            setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes } });
        }
        if (isNodeModel(item)) {
          // Item is NodeModel type
          const itemDepth = findMaxDepth(item)
          // On vérifie que le noeud + ses sous noeud ne dépasse pas MAX_DEPTH_LEVEL
          if(depthLevel !== null && (depthLevel + itemDepth) >= MAX_DEPTH_LEVEL) return 
          const nodesWithoutOlderOne = removeNode(tree.data.nodes, item.id);
          const nodesWithNewOne = addNodeIntoTree(
            nodesWithoutOlderOne,
            node.id,
            item
          );

          setTree({
            ...tree,
            data: { ...tree.data, nodes: nodesWithNewOne },
          });
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [node, tree]
  );

  return [drop, isOver];
};

/**
 * Custom hook that enables dropping a node between other nodes in a tree.
 *
 * @param node - The node to be dropped.
 * @param dropZoneIndex - The index of the drop zone where the node will be placed.
 * @returns An array containing the drop target and a boolean indicating if the node is being hovered over.
 */
export const useDropNodeBetween = (
  node: NodeModel | null,
  dropZoneIndex: number
): [ConnectDropTarget, boolean] => {
  const [tree, setTree] = useDirtyTreeContext();
  const [, setTiles] = useContext(TileContext);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: NODE,
      drop: (item: TileProps | NodeModel) => {
        if (tree.data === undefined) return;

        const destinationParentId = node ? getParentId(tree.data.nodes, node.id) : null;

        if (isTile(item)) {
          const newNode = createNodeModel(item.label);
          const updatedNodes = addNodeBetween(
            tree.data.nodes,
            destinationParentId,
            dropZoneIndex + 1,
            newNode
          );
          setTiles((prev) => prev.filter((_, index) => index !== item.index));

          setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes } });
        }

        if (isNodeModel(item)) {
          const sourceParentId = getParentId(tree.data.nodes, item.id);

          if (destinationParentId !== sourceParentId) {
            const nodesWithoutOlderOne = removeNode(tree.data.nodes, item.id);
            const nodesWithNewOne = addNodeBetween(
              nodesWithoutOlderOne,
              destinationParentId,
              dropZoneIndex + 1,
              item
            );
            setTree({
              ...tree,
              data: { ...tree.data, nodes: nodesWithNewOne },
            });
          } else {
            const nodesWithNewOne = addNodeBetween(
              tree.data.nodes,
              destinationParentId,
              dropZoneIndex + 1,
              item
            );
            console.log({nodesWithNewOne})
            setTree({
              ...tree,
              data: { ...tree.data, nodes: nodesWithNewOne },
            });
          }
        }
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [node, tree]
  );

  return [drop, isOver];
};

import React from "react";
import {DateTime as DateTimeLuxon, DateTimeFormatOptions, LocaleOptions} from "luxon";

type Props = {
    date: string
}

export default ({date}: Props) => {
    return (
        <>{DateTimeLuxon.fromISO(date).setZone("europe/paris").toLocaleString(DateTimeLuxon.DATE_MED as LocaleOptions & DateTimeFormatOptions)}</>
    );
}
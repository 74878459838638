/* ============================ Partie Organiser le CdF ============================ */

// Réservoir d'UD
export const TANK_UD_FONT_SIZE = 14
export const TANK_UD_HEADER_FONT_SIZE = TANK_UD_FONT_SIZE + 2
export const TANK_UD_MARGIN_Y = 6

export const TANK_FILTERS_FONT_SIZE = 15
export const TANK_ROW_GAP_SIMPLE_FILTERS = 5

export const TANK_STATUS_CIRCLE_SIZE = 20
export const TANK_STATUS_CIRCLE_BORDER_SIZE = 1

// CdF
export const TREE_DASHED_BORDER = 2
export const TREE_MARGIN_Y = 4
export const TREE_UD_FONT_SIZE = 14
export const TREE_NODE_FONT_SIZE = TREE_UD_FONT_SIZE + 1
/* ================================================================================== */



/* ============================ Partie Administrer le CdF ============================ */


/* ================================================================================== */
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import {useTranslation} from "react-i18next";
import {availableArticleTagsSelector} from "../../../redux/selectors/article/article.selectors";
import {updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {SearchContext} from "../../../types/common-search";

type Props = {
    selectedOptions?: string[],
    label?: string
}

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 20
    }
}))

export default ({label, selectedOptions}: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation()

    const handleGlobalChange = (event: React.ChangeEvent<HTMLInputElement>, value: string | string[] | null) => {
        const val = Array.isArray(value) ? value.map(x => encodeURIComponent(x)) : value ? encodeURIComponent(value) : undefined
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ARTICLE,
            field: 'numberOfPagesFilter',
            value: val
        }));
    };

    const articleTags = useSelector(availableArticleTagsSelector)
    if (!articleTags?.nombreDePages) {
        return (<></>)
    }

    const value = articleTags.nombreDePages.filter(x => {return selectedOptions?.includes(x)})

    return (
        <Autocomplete openText={t('misc.open')}
                      clearText={t('misc.clear')}
                      closeText={t('misc.close')}
                      loadingText={t('misc.loading')}
                      noOptionsText={t('misc.no-options')}
                      multiple color={"secondary"}
                      options={articleTags.nombreDePages}
                      size={"medium"}
                      className={classes.select}
                      disableCloseOnSelect
                      fullWidth={true}
                      value={value}
                      onChange={(event: any, newValue: string | string[] | null) => handleGlobalChange(event, newValue)}
                      getOptionLabel={(option) => option}
                      renderOption={(option, {selected}) => (
                          <React.Fragment>
                              <Checkbox
                                  checked={selected}
                              />
                              {option}
                          </React.Fragment>
                      )}
                      renderInput={(params) => (
                          <TextField color={"secondary"} {...params} variant="outlined" label={label}/>
                      )}
        />
    )
};

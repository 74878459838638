import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

type ConfirmationDialogAction = {
    label: string
    action?: () => void
    color?: "inherit" | "primary" | "secondary" | "default" | undefined
    variant?: "text" | "outlined" | "contained" | undefined
    textColor?: string
    disabled?: boolean
    close?: boolean
}

type ConfirmationDialogProps = {
    title: string
    description: string | React.ReactElement
    actions: ConfirmationDialogAction[]
    isOpened: boolean
    onClose: () => void
}

export default function ConfirmationDialog({title, description, actions, isOpened, onClose}: ConfirmationDialogProps) {
    return (
        <Dialog
            open={isOpened}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {
                    actions.map((action, idx) =>
                        <Button
                            disabled={action.disabled}
                            variant={action.variant || 'outlined'}
                            color={action.color || 'primary'}
                            onClick={() => {
                                if (action.action != null) action.action();
                                if (action.close)
                                    onClose();
                            }} key={idx}
                        >
                            {action.label}
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    );
}

import {AxiosResponse, AxiosStatic} from "axios";
import {merge, Observable, of} from "rxjs";
import {Action} from "../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, filter, flatMap, map} from "rxjs/operators";
import {notifyError} from "../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import {initFontoSessionError, initFontoSessionSuccess, isInitFontoSessionAction} from "../actions/fonto.actions";
import {InitFontoSession} from "../../types/fonto";


function _initFontoSession(axios: AxiosStatic, token: string): Observable<AxiosResponse<InitFontoSession>> {
    const headers = {'Authorization': `Bearer ${token}`};
    return fromPromise(axios.post<InitFontoSession>(`/api/fonto/session`, {}, {headers}));
}

export function initFontoSession(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isInitFontoSessionAction),
        flatMap(() => {
            const token = state$.value.auth.token ?? "";
            const observable = _initFontoSession(axios, token)
            return observable.pipe(
                map((response) => initFontoSessionSuccess(response.data)),
                catchError(err => merge(
                    of(initFontoSessionError()),
                    of(notifyError(+err.response.status, {type: 'ARTICLE'})))
                )
            );
        })
    )
}

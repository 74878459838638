import React, {useMemo} from "react";
import {_REFID_VALUE, _TEXT_CONTENT, bundleStructureTitleRegex} from "./BundleStructureTitleRenvoiEditField";
import {Tooltip} from "@material-ui/core";

export default function BundleStructureTitleRenvoi({value}) {
    const matched = useMemo(() => {
        const matched = value.match(bundleStructureTitleRegex)
        return matched ?? [value]
    }, [value])

    return <>
        {matched.length > 1 && <>
            <div>
                <Tooltip title={matched[_REFID_VALUE]}>
                    <span style={{
                        textDecoration: 'underline',
                        color: 'blue'
                    }}>{matched[_TEXT_CONTENT]}</span>
                </Tooltip>
            </div>
        </>}
        {matched.length <= 1 && <>{value}</>}
    </>
}
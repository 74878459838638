import React, {useEffect, useState} from "react";
import {Container, Grid} from "@material-ui/core";
import BundleOrganizeTable from "./BundleOrganizeTable";
import BundleOrganizeTree, {removeNulls} from "./BundleOrganizeTree";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {commonStyle} from "../../../styles/common.style";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import {fetchBundle, organizeBundle} from "../../../redux/actions/bundle.actions";
import {fetchPublication, resetPublication,} from "../../../redux/actions/publication.actions";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import BundleOrganizeMetadata from "./BundleOrganizeMetadata";
import useAsyncState from "../../commons/hooks/useAsyncState";
import {ArticleStatus} from "../../../types/article";
import {State} from "../../../redux/reducers";
import {ADMIN, EDITOR, MANAGER} from "../../../utils/roles";
import {isAuth} from "../../Auth";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BundleBreadcrumbs from "../commons/BundleBreadcrumbs";
import BundleOrganizeRightHeader from "./BundleOrganizeRightHeader";
import {attachedOnlySelected, pageSizeSelector, querySelector} from "../../../redux/selectors/forceOptions.selectors";
import {createHtml} from "../../../utils/common-utils";


const useStyles = makeStyles((theme) => ({
    topTitle: {
        fontWeight: 200,
        margin: theme.spacing(2, 0)
    },
    structureTitle: {
        paddingLeft: theme.spacing(6),
        fontWeight: 200,
    }
}))


export default function BundleOrganizeRoot() {
    const theme = useTheme()
    const commonClasses = commonStyle(theme)
    const classes = useStyles()


    const {t} = useTranslation()
    const {id} = useParams() as { id: string }
    const dispatch = useDispatch()

    const [page, setPage] = useState(0);

    const bundle = useSelector(currentFetchedBundleSelector)
    const bundleId = bundle?.id

    const queryId = useSelector(querySelector)
    const size = useSelector(pageSizeSelector)
    const attachedOnly = useSelector(attachedOnlySelected)

    const [nodes, setNodes] = useAsyncState([])
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        dispatch(fetchBundle(id))
        dispatch(resetPublication())
    }, [id])

    useEffect(() => {
        if (bundle && bundle.publication)
            dispatch(fetchPublication("" + bundle.publication))
    }, [bundleId])
    const roles = useSelector((state: State) => state.auth.roles)

    const editorRoles = [EDITOR, MANAGER, ADMIN]
    // TODO : la condition est-elle OK ? ça semble ambigu pour le compilo (Unexpected mix of '||' and '&&')
    const isBundlePlanReadOnly =
        !isAuth(roles, editorRoles, bundle?.periodical) || bundle?.status !== undefined
        && ([ArticleStatus.PUBLISHED, ArticleStatus.PUBLISHING].indexOf(bundle.status) !== -1);

    return <Container maxWidth={"xl"} className={commonClasses.root}>
        <Grid container>
            <Grid item xs={8}>
                <Box p={1} flexGrow={1}>
                    <Typography variant="h5" className={classes.topTitle}
                                component="h1">{bundle ? `${t('bundles.edit_bundle_title')} : ${bundle.id} - ` : ''}
                        <div dangerouslySetInnerHTML={createHtml(bundle?.title || '')}
                             style={{display: 'inline'}}/>
                    </Typography>

                    <BundleBreadcrumbs/>
                    <BundleOrganizeMetadata/>
                </Box>

                <Typography variant="h5" className={classes.topTitle}
                            component="h1">{t('bundles.articles_and_ads_table_title')}</Typography>

                <BundleOrganizeTable nodes={nodes} setNodes={setNodes} page={page} setPage={setPage}/>
            </Grid>

            <Grid item xs={4} alignItems={"flex-end"}>
                <BundleOrganizeRightHeader page={page} size={size} queryId={queryId} attachedOnly={attachedOnly}/>

                <Grid item style={{marginTop: "17%", marginRight: 10, position: "sticky", top: "80px"}}>
                    {!isBundlePlanReadOnly &&
                    <Grid container item justify={"flex-end"} direction={"row"} style={{marginBottom: 15}}>
                        <Grid item style={{flexGrow: 1}}>
                            <Typography variant="h5" className={classes.structureTitle}
                                        component="h1">{t('bundles.structure_title')}</Typography>
                        </Grid>

                        {dirty && <Button variant={"outlined"} color={"primary"} onClick={() => {
                            if (bundle?.plan) {
                                setNodes(removeNulls(bundle.plan)).then(() => {
                                })
                                setDirty(false)
                            }
                        }} style={{marginRight: 10}}>
                            {t('misc.cancel')}
                        </Button>}

                        <Button variant={"contained"} color={"primary"} onClick={() => {
                            dispatch(organizeBundle({bundle: nodes, bundleId: id}))
                            setDirty(false);
                        }}>
                            {t('bundles.save_plan')}
                        </Button>

                    </Grid>}

                    <Grid container item xs={12}>
                        <BundleOrganizeTree
                            nodes={nodes}
                            setNodes={setNodes}
                            dirty={dirty}
                            setDirty={setDirty}
                            isBundlePlanReadOnly={isBundlePlanReadOnly}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Container>
}

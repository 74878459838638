import {AxiosStatic} from "axios";
import {B64Image, IllustrationImage, SavedImage} from "../../types/image";
import {EMPTY, iif, merge, Observable, of} from "rxjs";
import {Action} from "../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, filter, flatMap, map, mergeMap} from "rxjs/operators";
import {notifyError, notifySuccess} from "../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import {
    deleteIllustrationError,
    DeleteIllustrationPayload,
    deleteIllustrationSuccess,
    deleteManyIllustrationError,
    DeleteManyIllustrationPayload,
    deleteManyIllustrationSuccess,
    fetchImageError,
    fetchImageSuccess,
    isDeleteIllustrationAction,
    isDeleteManyIllustrationAction,
    isFetchImageAction,
    isReplaceIllustrationAction,
    isSaveIllustrationAction,
    isSaveIllustrationSuccessAction,
    isSearchImagesAction,
    isToggleIllustrationDisabledAction,
    isUploadIllustrationAction,
    replaceIllustrationError,
    replaceIllustrationSuccess,
    saveIllustrationError,
    saveIllustrationSuccess,
    searchImagesError,
    searchImagesSuccess,
    toggleIllustrationDisabledError,
    toggleIllustrationDisabledSuccess,
    uploadIllustrationError,
    uploadIllustrationSuccess
} from "../actions/image.actions";
import {addImageAuthorError, addImageAuthorSuccess, isAddImageAuthorAction} from "../actions/author.actions";
import i18n from "i18next";
import {
    isReplaceAdImageAction,
    isUploadAdImageAction,
    replaceAdImageError,
    replaceAdImageSuccess,
    uploadAdImageError,
    uploadAdImageSuccess
} from "../actions/ad.actions";
import {redirect} from "../actions/router.actions";
import {reloadSearch} from "../actions/common-search.actions";

const _toggleImageDisabled = (axios: AxiosStatic, id: string, disabled: boolean, token: string): Observable<Action> => {
    const headers = {
        'Authorization': `Bearer ${token}`
    }

    return fromPromise(axios.put(`/api/illustration/${id}/status`, {disabled: disabled}, {headers}))
        .pipe(
            mergeMap(() => merge(of<Action>(
                toggleIllustrationDisabledSuccess(disabled),
                notifySuccess(i18n.t(disabled ? 'images.toggle_disable' : 'images.toggle_enable')))),
            ),
            catchError(err => merge(
                of(toggleIllustrationDisabledError()),
                of(notifyError(+err.response.status, {type: 'ILLUSTRATION'}))
            ))
        )
}

function _saveIllustration(axios: AxiosStatic, illustration: IllustrationImage, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.put(`/api/illustration/${id}`, illustration, {headers}))
        .pipe(
            mergeMap(_ => merge(of<Action>(
                saveIllustrationSuccess(),
                notifySuccess(i18n.t('images.save_success')))),
            ),
            catchError(err => merge(
                of(saveIllustrationError()),
                of(err.response.status === 404 ? notifyError('MISSING_IMAGE', {}) : notifyError(+err.response.status, {type: 'ILLUSTRATION'}))
            ))
        );
}

function _fetch(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/illustration/${id}`, {headers}))
        .pipe(
            map((result) => fetchImageSuccess(result.data)),
            catchError(err => merge(
                of(fetchImageError()),
                of(notifyError(+err.response.status, {type: 'ILLUSTRATION'}))
            ))
        )
}

function _search(axios: AxiosStatic, filters: any, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/image/search`, filters, {headers}))
        .pipe(
            map((response) => searchImagesSuccess(response.data.result as IllustrationImage[])),
            catchError(err => merge(
                of(searchImagesError()),
                of(notifyError(+err.response.status, {type: 'ILLUSTRATION'}))
            ))
        );
}

function _delete(axios: AxiosStatic, {
    id,
    doRedirect,
    ctx,
    filters
}: DeleteIllustrationPayload, route, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.delete(`/api/illustration/${id}`, {headers}))
        .pipe(
            mergeMap(_ => merge(of<Action>(
                deleteIllustrationSuccess(),
                notifySuccess(i18n.t('images.delete_success'))
                ),
                iif(() => doRedirect, of(redirect(route))),
                iif(() => !!ctx, of(reloadSearch({ctx: ctx!, forceOptions: filters})))
            )),
            catchError(err => merge(
                of(deleteIllustrationError()),
                of(notifyError(+err.response.status, {type: 'ILLUSTRATION'}))
            ))
        );
}

const _replaceImage = (actionOnSuccess: any, actionOnFailure: any, collection: string) => (axios: AxiosStatic, image: B64Image, id: string, token: string): Observable<Action> => {
    const headers = {'Authorization': `Bearer ${token}`};
    return fromPromise(axios.put<SavedImage>(`/api/image/${collection}/${id}`, image, {headers}))
        .pipe(
            map((response) => actionOnSuccess(response.data)),
            catchError(err => merge(
                of(actionOnFailure(err.data)),
                of(notifyError(err.response.data.message, {imageName: image.name, type: collection})))
            )
        );
}

const _uploadImage = (actionOnSuccess: any, actionOnFailure: any, collection: string) => (axios: AxiosStatic, image: B64Image, token: string): Observable<Action> => {
    const headers = {'Authorization': `Bearer ${token}`};
    return fromPromise(axios.post<SavedImage>(`/api/image/${collection}`, image, {headers}))
        .pipe(
            map((response) => actionOnSuccess(response.data)),
            catchError(err => merge(
                of(actionOnFailure(err.data)),
                of(notifyError(err.response.data.message, {imageName: image.name, type: collection})))
            )
        );
}

function _deleteMany(axios: AxiosStatic, {
    ids,
    ctx,
    filters
}: DeleteManyIllustrationPayload, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.post(`/api/illustration/delete`, ids, {headers}))
        .pipe(
            mergeMap(_ => merge(of<Action>(
                deleteManyIllustrationSuccess(),
                notifySuccess(i18n.t('images.delete_success', {count: ids.length ?? 1}))),
                iif(() => !!ctx, of(reloadSearch({ctx: ctx!, forceOptions: filters})))
            )),
            catchError(err => merge(
                of(deleteManyIllustrationError()),
                of(notifyError(+err.response.status, {type: 'ILLUSTRATION'}))
            ))
        );
}

const illustrationUpload = _uploadImage(uploadIllustrationSuccess, uploadIllustrationError, 'ILLUSTRATION')
const authorUpload = _uploadImage(addImageAuthorSuccess, addImageAuthorError, 'AUTHOR')
const adUpload = _uploadImage(uploadAdImageSuccess, uploadAdImageError, 'ADVERTISEMENT')

const illustrationReplace = _replaceImage(replaceIllustrationSuccess, replaceIllustrationError, 'ILLUSTRATION')
const adReplace = _replaceImage(replaceAdImageSuccess, replaceAdImageError, 'ADVERTISEMENT')

export function toggleIllustrationDisabledEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isToggleIllustrationDisabledAction),
        flatMap((action) => _toggleImageDisabled(axios,
            action.payload.id,
            action.payload.flag,
            state$.value.auth.token ?? "")
        )
    )
}

export function fetchImageEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchImageAction),
        flatMap((action) => _fetch(axios,
            action.payload,
            state$.value.auth.token ?? "")
        )
    )
}

export function redirectOnSaveImage(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history} = dependencies;

    action$.pipe(
        filter(isSaveIllustrationSuccessAction)
    ).subscribe(() => {
        history.push({
            pathname: `/${i18n.t('routes.image.root')}`
        })
    })
    return EMPTY
}

export function saveIllustrationEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;

    return action$.pipe(
        filter(isSaveIllustrationAction),
        flatMap(() => {
                const currentUploadedImageId = state$.value.image.currentUploadedImage?.id
                const currentImageId = state$.value.image.form.currentIllustration.id
                const imageId = currentUploadedImageId ? currentUploadedImageId : currentImageId

                return _saveIllustration(axios,
                    state$.value.image.form.currentIllustration,
                    imageId,
                    state$.value.auth.token ?? "")
            }
        )
    )
}

export function deleteManyIllustrationEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isDeleteManyIllustrationAction),
        flatMap((action) => {
            const token = state$.value.auth.token ?? "";
            return _deleteMany(axios, action.payload, token)
        })
    )
}

export function deleteIllustrationEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;
    return action$.pipe(
        filter(isDeleteIllustrationAction),
        flatMap((action) => {
            const token = state$.value.auth.token ?? "";
            return _delete(axios, action.payload, `/${i18n.t('routes.image.viewRoot')}`, token)
        })
    )
}

export function searchImagesEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSearchImagesAction),
        flatMap(() => {
            const token = state$.value.auth.token ?? "";
            return _search(axios, {collectionFilter: ['ILLUSTRATION']}, token)
        })
    )
}

export function replaceIllustrationEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isReplaceIllustrationAction),
        flatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return illustrationReplace(axios, payload.image, payload.id, token)
        })
    );
}

export function replaceAdImageEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isReplaceAdImageAction),
        flatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return adReplace(axios, payload.image, payload.id, token)
        })
    );
}

export function uploadImageEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isUploadIllustrationAction),
        flatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return illustrationUpload(axios, payload, token)
        })
    );
}

export function uploadAuthorImageEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isAddImageAuthorAction),
        flatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return authorUpload(axios, payload, token)
        })
    );
}

export function uploadAdImageEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isUploadAdImageAction),
        flatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return adUpload(axios, payload, token)
        })
    );
}

import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import LexForm from "../../commons/forms/LexForm";

import {autocompleteSelectRenderer, datePickerRenderer, selectRenderer} from "../../commons/forms/LexFormField";
import React, {useCallback, useEffect} from "react";
import {
    getCurrentPublication,
    getPublicationFormArticleOnCover,
    getPublicationFormEditionType,
    getPublicationFormEndDate,
    getPublicationFormImageOnCover,
    getPublicationFormNumber,
    getPublicationFormPeriodicalId,
    getPublicationFormStartDate,
    getPublicationFormTextOnCover,
    getPublicationFormTitle,
    getPublicationFormYear,
    getPublicationId,
    openDialog as isPublicationOpen
} from "../../../redux/selectors/publication.selectors";
import {
    closeNewPublicationDialog,
    createPublication,
    editPublication,
    fetchPublication,
    setPublicationFormArticleOnCover,
    setPublicationFormEditionType,
    setPublicationFormEndDate,
    setPublicationFormImageOnCover,
    setPublicationFormNumber,
    setPublicationFormPeriodicalId,
    setPublicationFormStartDate,
    setPublicationFormTextOnCover,
    setPublicationFormTitle,
    setPublicationFormYear
} from "../../../redux/actions/publication.actions";
import {EditionType} from "../../../types/publication";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {publicationRenderer, yearRenderer} from "./PublicationRenderer";
import {initPeriodicalsLimitedByRoles} from "../../../redux/selectors/periodical.selectors";
import {SearchContext} from "../../../types/common-search";
import {search} from "../../../redux/actions/common-search.actions";
import {State} from "../../../redux/reducers";
import {useForm} from "react-hook-form";
import {cleanHtml} from "../../../utils/common-utils";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(0)
    },
    button: {
        marginTop: theme.spacing(2)
    }
}));

export default function CreatePublicationDialog() {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = useSelector(isPublicationOpen)

    const handleClose = () => {
        dispatch(closeNewPublicationDialog());
    };

    // Edit
    const publicationId = useSelector(getPublicationId);
    const publication = useSelector(getCurrentPublication);
    useEffect(() => {
        if (publicationId !== undefined) {
            dispatch(fetchPublication(`${publicationId}`));
        }
    }, [dispatch, publicationId]);

    const handleArticleSearch = useCallback((e: any | undefined = undefined) => {
        dispatch(search({
                ctx: SearchContext.PUBLICATION_ARTICLE,
                filters: {
                    publicationFilter: publication ? [publication.periodicalId + '_' + publication.year + '_' + publication.number] : undefined,
                    attachedFilterBis: ["CDF_FIXE"],
                    query: e !== undefined ? e.target.value : ""
                }
            })
        )
    }, [dispatch, publication])

    const handleImageSearch = useCallback((e: any | undefined = undefined) => {
        dispatch(search({
                ctx: SearchContext.IMAGE,
                filters: {query: e !== undefined ? e.target.value : "", collectionFilter: ["ILLUSTRATION"]}
            })
        )
    }, [dispatch])

    useEffect(() => {
        if (publication !== undefined) {
            handleArticleSearch();
            handleImageSearch();
        }
    }, [handleArticleSearch, handleImageSearch, publication]);

    const isEdit = publicationId !== undefined;
    const initPeriodicals = useSelector(initPeriodicalsLimitedByRoles)
    const selectedPeriodicalId = useSelector(getPublicationFormPeriodicalId)
    const editionType = useSelector(getPublicationFormEditionType)

    const articles = useSelector(({search}: State) => search.common[SearchContext.PUBLICATION_ARTICLE].searchResults);
    const images = useSelector(({search}: State) => search.common[SearchContext.IMAGE].searchResults);


    const {handleSubmit} = useForm<FormData>({
        mode: 'onBlur',
        shouldFocusError: true,
        criteriaMode: "all"
    });

    const onSubmit = handleSubmit(() => {
        if (publicationId !== undefined) {
            dispatch(editPublication(publicationId))
        } else {
            dispatch(createPublication())
        }
    });

    const cancelAction = () => {
        handleClose();
    }
    if (!isEdit || (isEdit && selectedPeriodicalId && editionType)) {
        return (
            <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
                <Grid container className={classes.root}>
                    <Grid container className={classes.root} spacing={1}>
                        <Typography variant="h5"
                                    component="h1">{t(isEdit ? 'publications.edit_publication' : 'publications.add')}</Typography>
                        <LexForm
                            type={"publications"}
                            cancelAction={cancelAction}
                            onSubmit={onSubmit}
                            fields={[
                                {
                                    name: "edition_type",
                                    required: true,
                                    selector: () => editionType,
                                    action: setPublicationFormEditionType,
                                    shouldNotTranslate: true,
                                    renderer: selectRenderer([{
                                        value: EditionType.SPECIAL_EDITION,
                                        label: "Hors-Série"
                                    }, {
                                        value: EditionType.STANDARD,
                                        label: "Classique"
                                    }])
                                },
                                {
                                    name: "numero",
                                    required: !editionType || editionType === EditionType.STANDARD,
                                    action: setPublicationFormNumber,
                                    selector: getPublicationFormNumber,
                                    renderer: publicationRenderer(editionType)
                                },
                                {
                                    name: "parution",
                                    required: true,
                                    disabled: isEdit,
                                    selector: getPublicationFormPeriodicalId,
                                    action: setPublicationFormPeriodicalId,
                                    shouldNotTranslate: true,
                                    renderer: selectRenderer(initPeriodicals.map(e => {
                                        return {
                                            label: `[${e.id}] ${e.label}`,
                                            value: e.id
                                        }
                                    })),
                                }, {
                                    name: "title",
                                    required: false,
                                    selector: getPublicationFormTitle,
                                    action: setPublicationFormTitle
                                }, {
                                    name: "year",
                                    required: true,
                                    action: setPublicationFormYear,
                                    selector: getPublicationFormYear,
                                    renderer: yearRenderer
                                }, {
                                    name: "startDate",
                                    required: true,
                                    action: setPublicationFormStartDate,
                                    selector: getPublicationFormStartDate,
                                    renderer: datePickerRenderer
                                }, {
                                    name: "endDate",
                                    action: setPublicationFormEndDate,
                                    selector: getPublicationFormEndDate,
                                    renderer: datePickerRenderer
                                }, ((isEdit && (selectedPeriodicalId === 'DEF'
                                    || selectedPeriodicalId === 'GPL')) ? {
                                    name: "articleId",
                                    action: setPublicationFormArticleOnCover,
                                    selector: getPublicationFormArticleOnCover,
                                    searchHandler: handleArticleSearch,
                                    renderer: autocompleteSelectRenderer(articles.map(e => {
                                        return {
                                            label: cleanHtml(e.title),
                                            value: e.id,
                                        }
                                    })),
                                    shouldNotTranslate: true
                                } : undefined), ((isEdit && (selectedPeriodicalId === 'DEF'
                                    || selectedPeriodicalId === 'GPL')) ? {
                                    name: "imageId",
                                    action: setPublicationFormImageOnCover,
                                    selector: getPublicationFormImageOnCover,
                                    searchHandler: handleImageSearch,
                                    renderer: autocompleteSelectRenderer(images.map(e => {
                                        return {
                                            label: e.name,
                                            value: e.id,
                                        }
                                    })),
                                    shouldNotTranslate: true
                                } : undefined), (isEdit && selectedPeriodicalId === 'GPL' ? {
                                    name: "text",
                                    action: setPublicationFormTextOnCover,
                                    selector: getPublicationFormTextOnCover,
                                    shouldNotTranslate: true
                                } : undefined)
                            ]}
                        />

                    </Grid>
                </Grid>
            </Dialog>
        );
    } else {
        return (<></>)
    }
};

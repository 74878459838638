import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "../../commons/ListItem";
import {
    addSearchQueryParam,
    removeSearchQueryParam,
    resetSearchQueryParams,
    updateSearchQueryParam
} from "../../../redux/actions/common-search.actions"
import {makeStyles} from "@material-ui/core/styles";
import DateRangePicker from "../../commons/DateRangePicker";
import PublishedIn from "../../commons/PublishedIn";
import {ImageSearchFilters, SearchContext} from "../../../types/common-search";
import RevueIn from "./RevueIn";
import Button from "@material-ui/core/Button";
import {ClearIcon} from "../../../icon";
import DimensionFilter from "./DimensionFilter";
import ColorFilter from "../../images/list/ColorFilter";
import EnableFilter from "../../images/list/EnableFilter";
import PriorityFilter from "./PriorityFilter";

type Props = {
    filters: ImageSearchFilters
}

const useStyles = makeStyles(() => ({
    paper: {
        borderBottom: "none"
    }
}))

export default ({filters}: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const minDate = "2000-01-01"
    const maxDate = "2025-01-01"

    return (
        <>
            <Button onClick={() => dispatch(resetSearchQueryParams(SearchContext.ADVERTISEMENT))}
                    startIcon={<ClearIcon/>}>Réinitialiser</Button>

            <Paper variant="outlined" square className={classes.paper}>
                <List component="div" disablePadding>
                    <ListItem label={t('misc.edit_date')}>
                        <DateRangePicker
                            end={filters.modificationDateFilter?.end}
                            start={filters.modificationDateFilter?.start}
                            onDatesChange={(values) => dispatch(updateSearchQueryParam({
                                ctx: SearchContext.ADVERTISEMENT,
                                field: "modificationDateFilter",
                                value: values
                            }))}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </ListItem>

                    <RevueIn publicationPeriodicalFilter={filters.periodicalFilter}/>

                    <PublishedIn
                        publicationPeriodicalFilter={filters.publicationPeriodicalFilter ?? []}
                        ctx={SearchContext.ADVERTISEMENT}
                    />

                    <PriorityFilter values={filters.priorityFilter}/>

                    <DimensionFilter formatFilter={filters.formatFilter} selectedAction={updateSearchQueryParam}/>

                    <ColorFilter removeElementAction={removeSearchQueryParam} addElementAction={addSearchQueryParam}
                                 characteristicFilter={filters.characteristicFilter}/>

                    <ListItem label={t('images.deadline')}>
                        <DateRangePicker
                            end={filters.endDateFilter?.end}
                            start={filters.endDateFilter?.start}
                            onDatesChange={(values) => dispatch(updateSearchQueryParam({
                                ctx: SearchContext.ADVERTISEMENT,
                                field: "endDateFilter",
                                value: values
                            }))}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </ListItem>

                    <EnableFilter removeElementAction={removeSearchQueryParam} addElementAction={addSearchQueryParam}
                                  filter={filters.enableFilter} context={SearchContext.ADVERTISEMENT}/>
                </List>
            </Paper>
        </>
    )
}

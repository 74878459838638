import {Grid, IconButton, Link} from "@material-ui/core";
import React from "react";
import {ArticleStatus, cdfAttachments, fixedAttachments} from "../../../types/article";
import Tooltip from "@material-ui/core/Tooltip";
import {
    ArchiveIcon,
    AttachedPlanIcon,
    BundleIcon,
    FrontCoverIcon,
    GeneratingPdfIcon,
    LockIcon,
    OnlineIcon, OnlineTempIcon,
    ReadyStatusIcon
} from "../../commons/icon/icons";
import {useTranslation} from "react-i18next";
import DateTime from "../../commons/DateTime";
import {BundleResponse} from "../../../types/bundle";
import {JouveProcessStatus} from "../../../types/workflow";
import PlacardStatusAndDownload from "../../publications/workflow/PlacardStatusAndDownload";
import {Link as RouterLink} from "react-router-dom";

type  Props = {
    bundle: BundleResponse,
    justify?: "center" | "flex-start" | "flex-end" | "space-between" | "space-around" | "space-evenly" | undefined
}

function BundleStatusList({bundle, justify}: Props) {
    const {t} = useTranslation()

    return (
        <Grid container spacing={1} justify={justify ?? "center"}>
            <Grid item>
                <BundleIcon/>
            </Grid>

            {bundle.isFrontCover &&
            <Grid item>
                <Tooltip title={t('misc.front_cover') ?? ''}>
                    <span><FrontCoverIcon/></span>
                </Tooltip>
            </Grid>}

            {/* rattaché à un CDF */}
            {(cdfAttachments.includes(bundle.attached) && fixedAttachments.includes(bundle.attached)) &&
            <Grid item>
                <Link
                    to={`/${t('routes.publication.root')}/${bundle.publication}/${t('routes.publication.organize')}`}
                    component={RouterLink}>
                    <Tooltip title={t('bundles.attached_plan_detailed', {publicationName: bundle.publication}) ?? ""}>
                        <IconButton size="small">
                            <AttachedPlanIcon/>
                        </IconButton>
                    </Tooltip>
                </Link>
            </Grid>}

            {(bundle.lastRequestPlacardProcessStatus === JouveProcessStatus.GEN_REQUEST_PACKAGE || bundle.lastRequestPlacardProcessStatus === JouveProcessStatus.PENDING
                || bundle.lastRequestPlacardProcessStatus === JouveProcessStatus.GEN_RESULT_PACKAGE) &&
            <Grid item>
                <Tooltip title={t('workflows.placard_in_progress') ?? ""}>
                    <span><GeneratingPdfIcon/></span>
                </Tooltip>
            </Grid>}

            {(bundle.publicationOnlineDate) &&
            <Grid item>
                <Tooltip title={<>Poussé en ligne le <DateTime date={bundle.publicationOnlineDate}/></>}>
                    <span><OnlineIcon
                        htmlColor={((bundle.modificationDate && bundle.modificationDate > bundle.publicationOnlineDate) ? "orange" : "green")}/></span>
                </Tooltip>
            </Grid>}

            {(bundle.nextPublicationOnlineDate) && <>
                <Grid item>
                    <Tooltip title={<>Planifié le <DateTime
                        date={bundle.nextPublicationOnlineDate}/> par {bundle.nextPublicationOnlineUsername}</>}>
                        <span><OnlineTempIcon/></span>
                    </Tooltip>
                </Grid>
                {bundle.status != ArticleStatus.PUBLISHED}
                <Grid item>
                    <span><ArchiveIcon/></span>
                </Grid></>
            }

            {bundle && <Grid item><PlacardStatusAndDownload type={"article"} id={bundle.id!!}
                                                            lastAvailablePlacardProcessStatus={bundle.lastAvailablePlacardProcessStatus}/></Grid>}

            {bundle.status === ArticleStatus.READY &&
            <Grid item>
                <Tooltip title={t('articles.ready') ?? ""}>
                    <span><ReadyStatusIcon/></span>
                </Tooltip>
            </Grid>}

            {(bundle.status === ArticleStatus.PUBLISHING) &&
            <Grid item>
                <Tooltip title={t('workflows.publishing') ?? ""}>
                    <span><LockIcon/></span>
                </Tooltip>
            </Grid>}

            {(bundle.status === ArticleStatus.PUBLISHED) &&
            <Grid item>
                <Tooltip title={t('workflows.archived') ?? ""}>
                    <span><ArchiveIcon/></span>
                </Tooltip>
            </Grid>}
        </Grid>
    )
}

export default BundleStatusList


import {AxiosStatic} from "axios";
import {merge, Observable, of} from "rxjs";
import {Action} from "../../../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, concatMap, filter, mergeMap} from "rxjs/operators";
import {notify, notifySuccess} from "../../../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../../../reducers";
import {EpicDependencies} from "../../../store";
import {isUploadReferentialAction} from "../../../actions/admin/referential/referential.actions";
import {ReferentialResource} from "../../../../components/admin/referential/ReferentialAdminRoot";

function _uploadReferential(axios: AxiosStatic, files: FileList, type: ReferentialResource, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'
    };

    const data = new FormData()
    data.append("file", files[0])

    return fromPromise(axios.post(`/api/admin/referential/${type}`, data, {headers}))
        .pipe(
            mergeMap(() => merge(of<Action>(
                notifySuccess("L'import s'est terminé avec succès"),
            ))),
            catchError(err => {
                    return of(
                        notify(err.response.data.hasOwnProperty("message")
                            ? err.response.data.message
                            : err.response.data, {variant: 'error'})
                    )
                }
            ))
}

export function uploadReferentialEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isUploadReferentialAction),
        concatMap((action) => _uploadReferential(axios, action.payload.file, action.payload.type, state$.value.auth.token ?? ""))
    )
}

import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Checkbox,
    Grid,
    GridList,
    GridListTile,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Tooltip
} from "@material-ui/core";
import React, {useState} from "react";
import {ImageQuality} from "../../../types/image";
import {useTranslation} from "react-i18next";
import {DeleteIcon, EditIcon, ExportIcon, Zoom} from "../../../icon";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import {deleteIllustration} from "../../../redux/actions/image.actions"
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import {selectOneElement, updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {createHtml, downloadIllustrationImages} from "../../../utils/common-utils";
import ImageListActions from "./ImageListActions";
import Collapse from "@material-ui/core/Collapse";
import {Link as RouterLink, useHistory, useLocation} from "react-router-dom";
import DateTime from "../../commons/DateTime";
import {getSortFirstAttr, getSortFirstOrder, readImageQueryParams} from "../../../utils/image.utils";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {gridStyle} from "../../../styles/grid.style";
import {commonStyle} from "../../../styles/common.style";
import GridListSwitcher from "../../commons/GridListSwitcher";
import {SearchContext} from "../../../types/common-search";

const sortValues = [
    "ID",
    "NAME",
    "UPDATE"
]

const ImageGrid = () => {
    const gridCss = gridStyle()
    const commonCss = commonStyle()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const [selectedIds, loaded]: [string[], boolean] = useSelector((state: State) => {
        const store = state.search.common[SearchContext.IMAGE];
        return [store.selectedIds, store.status === 'LOADED'];
    });

    const images = useSelector(({search}: State) => search.common[SearchContext.IMAGE].searchResults)

    const [isShown, shown] = useState(images.map((_) => false))
    if (isShown.length !== images.length) {
        shown(images.map(() => false))
    }

    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);

    const [imageBeingDeleted, changeImageBeingDeletedId] = useState("");
    const handleImageDeletionConfirmation = (imageId: string) => () => {
        changeImageBeingDeletedId(imageId)
        openConfirmationDialog(true)
    }

    const searchLocation = useLocation().search;
    const isLocationGrid = searchLocation.includes("&grid") || searchLocation.includes("?grid")
    const queryParams = readImageQueryParams(searchLocation)
    const sortMode = getSortFirstOrder(queryParams)
    const attr = getSortFirstAttr(queryParams)

    const sort = (attribute: string, order: ("ASC" | "DESC" | undefined) | string) => dispatch(updateSearchQueryParam({
        ctx: SearchContext.IMAGE,
        field: "sort",
        value: [{attr: attribute, order: order}]
    }))

    const handleChangeMode = () => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.IMAGE,
            field: "grid",
            value: !isLocationGrid
        }))
    };

    const handleImageDeletion = () => {
        dispatch(deleteIllustration({
            id: imageBeingDeleted,
            doRedirect: false,
            ctx: SearchContext.IMAGE,
            filters: queryParams
        }))
    }

    return (
        <>
            <ConfirmationDialog
                title={t('images.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default'},
                    {label: t('dialogs.confirm'), action: handleImageDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            <Grid item container xs={12} justify={"space-between"}>
                <Grid container item xs={5} spacing={1}>
                    <Grid item xs={8}>
                        <InputLabel>{t('misc.sort_by')}</InputLabel>
                        <Select
                            value={sortValues.indexOf(attr)}
                            fullWidth={true}
                            onChange={(e) => {
                                const i = e.target.value as number
                                sort(sortValues[i], sortMode)
                            }}>
                            <MenuItem value={0}>{t('misc.id')}</MenuItem>
                            <MenuItem value={1}>{t('misc.name')}</MenuItem>
                            <MenuItem value={2}>{t('misc.modification_date')}</MenuItem>
                        </Select>
                    </Grid>
                    <Grid container item xs={4}>
                        <InputLabel>{t('misc.sort_order')}</InputLabel>

                        <Select
                            value={sortMode}
                            fullWidth={true}
                            onChange={(e) => {
                                const value = e.target.value as string
                                sort(attr, value)
                            }}>
                            <MenuItem value={'ASC'}>{t('misc.asc')}</MenuItem>
                            <MenuItem value={'DESC'}>{t('misc.desc')}</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Grid item>
                    <GridListSwitcher handleChangeMode={handleChangeMode} isLocationGrid={isLocationGrid}/>
                </Grid>
            </Grid>

            <Grid container justify={"space-between"}>

                <Grid item xs={11}>
                    <ImageListActions grid={true}/>

                    <GridList cellHeight={500} cols={3}>
                        {images.length > 0 && images.map((image, idx) =>
                            <GridListTile key={image.id} className={gridCss.tile}
                                          onMouseLeave={() => shown(isShown.map((it, _idx) => _idx === idx ? false : it))}
                            >
                                <Card
                                    elevation={0}
                                    onMouseEnter={() => shown(isShown.map((it, _idx) => _idx === idx ? true : it))}>
                                    <Checkbox name={"selected_image_" + image.id}
                                              color="primary"
                                              checked={selectedIds.indexOf(image.id) > -1}
                                              onChange={() => dispatch(selectOneElement({
                                                  ctx: SearchContext.IMAGE,
                                                  id: image.id
                                              }))}
                                              className={gridCss.check}/>
                                    <CardMedia
                                        onClick={() => history.push('/' + t('routes.image.root') + '/' + image.id)}
                                        children={
                                            <div className={gridCss['image-infos-container']}>
                                                <Collapse in={isShown[idx]}>
                                                    <div className={gridCss["image-details"]}>
                                                        <div className={gridCss.details}>
                                                            {t('authors.sources')} : <div
                                                            dangerouslySetInnerHTML={createHtml(image.sources)}/>
                                                        </div>
                                                        <div className={gridCss.details}>
                                                            {t('authors.credits')} : <div
                                                            dangerouslySetInnerHTML={createHtml(image.credits)}/>
                                                        </div>

                                                        {image.quality === ImageQuality.BAD &&
                                                        <div className={`${commonCss.badQuality} ${gridCss.details}`}>
                                                            {t('enums.images.quality.BAD.main')}
                                                        </div>
                                                        }
                                                    </div>

                                                    <div className={gridCss.zoom}>
                                                        <Zoom/>
                                                    </div>
                                                </Collapse>
                                            </div>
                                        }
                                        className={gridCss.media}
                                        image={`/api/image/${image.id}?size=MEDIUM`}
                                    />


                                    <CardContent>
                                        {image.id} {image.name} <br/>
                                        <DateTime date={image.modificationDate ?? ''}/>
                                    </CardContent>

                                    <CardActions className={gridCss.center}>
                                        <Tooltip title={t('images.export') || ""}>
                                            <IconButton aria-label={t('images.export')}
                                                        onClick={() => downloadIllustrationImages([image.id])}>
                                                <ExportIcon fontSize={"small"}/>
                                            </IconButton>
                                        </Tooltip>

                                        {!image.authorId &&
                                        <Link
                                            to={`/${t('routes.image.root')}/${image.id}/edit`}
                                            component={RouterLink}>
                                            <Tooltip title={t('images.edit') || ""}>
                                                <IconButton>
                                                    <EditIcon fontSize={"small"}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Link>}

                                        {image.authorId &&
                                        <Link
                                            to={`/${t('routes.author.root')}/${image.authorId}/edit`}
                                            component={RouterLink}>
                                            <Tooltip title={t('authors.edit') || ""}>
                                                <IconButton>
                                                    <EditIcon fontSize={"small"}/>
                                                </IconButton>
                                            </Tooltip>
                                        </Link>}

                                        <Tooltip title={t('misc.remove_or_delete') || ""}>
                                            <IconButton
                                                aria-label={t('misc.remove_or_delete')}
                                                onClick={handleImageDeletionConfirmation(image.id)}>
                                                <DeleteIcon fontSize={"small"}/>
                                            </IconButton>
                                        </Tooltip>
                                    </CardActions>
                                </Card>
                            </GridListTile>
                        )}

                        {(images.length === 0 && loaded) && (
                            <Grid item xs={12}>
                                <Typography variant="h6"
                                            style={{
                                                "textAlign": "center",
                                                "marginTop": 250
                                            }}>{t('table.no_result')}</Typography>
                            </Grid>
                        )}
                    </GridList>
                </Grid>
            </Grid>
        </>
    )
}

export default ImageGrid

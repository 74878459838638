import React from "react";
import FormLabel from '@material-ui/core/FormLabel'
import {AuthorEmail} from "../../../types/author";
import Grid from "@material-ui/core/Grid";
import {FavIcon} from '../../../icon';
import {createStyles, makeStyles} from "@material-ui/core/styles";

type Props = {
    emails: AuthorEmail[]
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: 30
        },
        divider: {
            marginTop: 30,
            height: 1,
            marginBottom: 20
        },
        favorite: {
            color: "#ffbe00"
        },
        fav: {
            maxWidth: '18px',
            marginRight: theme.spacing(1)
        }
    })
);


export default ({emails}: Props) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={2}>
                <FormLabel color={"secondary"}>
                    Email(s)
                </FormLabel>
            </Grid>
            <Grid item xs={10}>
                {emails.length > 0 &&
                emails.map((email, index) => {
                    return (<Grid container key={index}>
                            <Grid item xs={1} className={classes.fav}>
                                {email.fav && (<FavIcon fontSize={"small"} className={classes.favorite}/>)}
                            </Grid>
                            <Grid item xs={11}>
                                {email.mail}
                            </Grid>
                        </Grid>
                    )
                })
                }
            </Grid>
        </Grid>
    )
}
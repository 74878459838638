import {Action} from "../actions/utils.actions";
import {isCloseSnackbarAction, isEnqueueSnackbarAction, isRemoveSnackbarAction} from "../actions/notification.actions";
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";

type NotificationReducer = {
    key: SnackbarKey
    message: SnackbarMessage
    dismissed?: boolean
    options?: OptionsObject
    closeable: boolean
}

const reducer = (notifications: NotificationReducer[] = [], action: Action): NotificationReducer[] => {
    if (isEnqueueSnackbarAction(action)) {
        const {key, notification} = action.payload;
        return [
            ...notifications,
            {
                key,
                ...notification
            }
        ];
    } else if (isCloseSnackbarAction(action)) {
        const key = action.payload;
        const dismissAll = key === undefined;
        return notifications.map(notification => (
            (dismissAll || notification.key === key)
                ? {...notification, dismissed: true}
                : {...notification}
        ));
    } else if (isRemoveSnackbarAction(action)) {
        const key = action.payload;
        return notifications.map(notification =>
            (notification.key === key) ?
                {
                    ...notification,
                    dismissed: true
                } : notification
        );
    } else {
        return notifications;
    }
}

export default reducer

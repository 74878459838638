import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch} from "react-redux";

const CheckboxForFilters = ({ctx, label, value, filters, field, addElementAction, removeElementAction}) => {
    const dispatch = useDispatch();

    const handleChange = (value: string, checked: boolean) => {
        if (checked) {
            dispatch(addElementAction({ctx, field: field, value: value}))
        } else {
            dispatch(removeElementAction({ctx, field: field, value: value}))
        }
    }

    return (
        <FormGroup row>
            <FormControlLabel
                control={<Checkbox
                    onChange={(event, check) => handleChange(value, check)}
                    checked={filters.some((e) => e === value)}
                />}
                label={label}
            />
        </FormGroup>
    )
}

export default CheckboxForFilters

import {EMPTY, merge, Observable, of} from "rxjs";
import {Action} from "../actions/utils.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import {catchError, debounceTime, filter, flatMap, map} from "rxjs/operators";
import {AuthorSearchFilters, ImageSearchFilters} from "../../types/common-search";
import {EntityProposal, ProposalType} from "../../types/quick-search";

import {
    countQuickSearchError,
    countQuickSearchSucces,
    fetchQuickSearchProposalsError,
    fetchQuickSearchProposalsSuccess,
    isCountQuickSearchResultsAction,
    isSelectQuickSearchProposalAction,
    isTypeQuickSearchAction
} from "../actions/quick-search.actions";
import {AxiosStatic} from "axios";
import {fromPromise} from "rxjs/internal-compatibility";
import {notifyError} from "../actions/notification.actions";
import {getCollectionName} from "../../utils/image.utils";
import {isAuth} from "../../components/Auth";
import {ADMIN, EDITOR, MANAGER} from "../../utils/roles";

function searchRequest(axios: AxiosStatic, query: string, type: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    let filters;
    let url;
    switch (type) {
        case "author":
            url = `/api/author/search`;
            filters = {
                query: query
            } as AuthorSearchFilters;
            break
        case "image":
        case "ad":
            url = `/api/image/search`
            filters = {
                query: query, collectionFilter: getCollectionName(type)
            } as ImageSearchFilters;
            break
    }

    return fromPromise(axios.post<any>(url, filters, {headers: headers}))
        .pipe(
            map((response) => {
                    return countQuickSearchSucces({type, count: response.data.resultCount})
                },
                catchError((err) => {
                        return merge(
                            of(countQuickSearchError(type)),
                            of(notifyError(+err.response.status, {type: type}))
                        )
                    }
                )
            )
        );
}

export function fetchCountQuickSearch(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isCountQuickSearchResultsAction),
        flatMap(({payload}) => {
            const token = state$.value.auth.token ?? "";
            return searchRequest(axios, payload.query, payload.type, token)
        }),
    );
}

function fetchProposals(axios: AxiosStatic, token: string, query: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get<EntityProposal[]>(`/api/search`,
        {
            params: {query},
            headers
        }))
        .pipe(
            map((response) => fetchQuickSearchProposalsSuccess(response.data)),
            catchError(err => merge(
                of(fetchQuickSearchProposalsError(err.data)),
                of(notifyError(+err.response.status, {type: 'Auteur'}))
            ))
        );
}

export function fetchQuickSearchProposalsOnType(action$: Observable<Action>, state$: StateObservable<State>, {axios}: EpicDependencies): Observable<Action> {
    return action$.pipe(
        filter(isTypeQuickSearchAction),
        debounceTime(1),
        flatMap(({payload}) => {
            if (payload.trim()) {
                const token = state$.value.auth.token ?? "";
                return fetchProposals(axios, token, payload);
            } else {
                return of(fetchQuickSearchProposalsSuccess([]))
            }
        })
    );
}

export function redirectOnQuickSearchProposalSelected(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {history, i18n} = dependencies;
    action$.pipe(
        filter(isSelectQuickSearchProposalAction)
    ).subscribe(({payload}) => {
        if (payload.type === ProposalType.AUTHOR) {
            history.push({
                pathname: `/${i18n.t('routes.author.root')}/${payload.id}`
            })
        } else if (payload.type === ProposalType.IMAGE && payload.authorId) {
            history.push({
                pathname: `/${i18n.t('routes.author.root')}/${payload.authorId}`
            })
        } else if (payload.type === ProposalType.IMAGE) {
            history.push({
                pathname: `/${i18n.t('routes.image.root')}/${payload.id}`
            })
        } else if (payload.type === ProposalType.AD) {
            history.push({
                pathname: `/${i18n.t('routes.ad.root')}/${payload.id}`
            })
        } else if (payload.type === ProposalType.PUBLICATION) {
            if (isAuth(state$.value.auth.roles, [ADMIN, EDITOR, MANAGER], payload.label.split(" ")[0])) {
                history.push({
                    pathname: `/${i18n.t('routes.publication.root')}/${payload.id}/${i18n.t('routes.publication.organize')}`
                })
            } else {
                history.push(`/${i18n.t('routes.publication.root')}?query=${encodeURIComponent(payload.label)}`)
            }

        } else if (payload.type === ProposalType.ARTICLE) {
            history.push({
                pathname: `/${i18n.t('routes.article.root')}/${payload.id}`
            })
        } else if (payload.type === ProposalType.BUNDLE) {
            history.push({
                pathname: `/${i18n.t('routes.bundle.root')}/${payload.id}`
            })
        } else if (payload.type === ProposalType.QUERY) {
            if (payload.site === ProposalType.AUTHOR) {
                history.push(`/${i18n.t('routes.author.root')}?query=${encodeURIComponent(payload.query)}`)
            } else if (payload.site === ProposalType.IMAGE) {
                history.push(`/${i18n.t('routes.image.root')}?query=${encodeURIComponent(payload.query)}`)
            } else if (payload.site === ProposalType.AD) {
                history.push(`/${i18n.t('routes.ad.root')}?query=${encodeURIComponent(payload.query)}`)
            } else if (payload.site === ProposalType.ARTICLE) {
                history.push(`/${i18n.t('routes.article.root')}?query=${encodeURIComponent(payload.query)}`)
            } else if (payload.site === ProposalType.PUBLICATION) {
                history.push(`/${i18n.t('routes.publication.root')}?query=${encodeURIComponent(payload.query)}`)
            }
        }
    });
    return EMPTY
}

import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import {addSearchQueryParam, removeSearchQueryParam} from "../../../redux/actions/common-search.actions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch} from "react-redux";
import {SearchContext} from "../../../types/common-search";

export default ({attachedFilter}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleChange = (value: string, checked: boolean) => {
        if (checked) {
            dispatch(addSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'attachedFilter',
                value: value
            }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'attachedFilter',
                value: value
            }))
        }
    }

    return (
        <ListItem label={t('articles.attached.name')}>
            {["CDF", "BUNDLE", "NONE"].map((attachment, index) => <FormGroup row key={'attachment_' + index}>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange(attachment, check)}
                        checked={attachedFilter.some((e) => e === attachment)}
                    />}
                    label={t('articles.attached.' + attachment)}
                />
            </FormGroup>)}
        </ListItem>
    )
}

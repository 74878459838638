import {Action} from "../../../actions/utils.actions";
import {
    isExportAuthors,
    isExportAuthorsError,
    isExportAuthorsSuccess,
    isExportPublishedElements,
    isExportPublishedElementsError,
    isExportPublishedElementsSuccess,
    isExportReferences,
    isExportReferencesError,
    isExportReferencesSuccess,
    isInitExportStatus
} from "../../../actions/admin/export/admin-export.actions";


export type AdminExportState = {
    authorsStatus: 'NONE' | 'PENDING' | 'LOADED' | 'ERROR',
    referencesStatus: 'NONE' | 'PENDING' | 'LOADED' | 'ERROR',
    publishedStatus: 'NONE' | 'PENDING' | 'LOADED' | 'ERROR',
    lastFileName?: string
}

const init: AdminExportState = {
    authorsStatus: 'NONE',
    referencesStatus: 'NONE',
    publishedStatus: 'NONE'
}

export default function AdminExportReducer(state = init, action: Action): AdminExportState {
    if (isExportAuthors(action)) {
        return {
            ...state,
            authorsStatus: 'PENDING',
            lastFileName: undefined
        }
    } else if (isExportAuthorsSuccess(action)) {
        return {
            ...state,
            authorsStatus: 'LOADED',
            lastFileName: action.payload.filename
        }
    } else if (isExportAuthorsError(action)) {
        return {
            ...state,
            authorsStatus: 'ERROR',
            lastFileName: undefined
        }
    } else if (isExportReferences(action)) {
        return {
            ...state,
            referencesStatus: 'PENDING',
            lastFileName: undefined
        }
    } else if (isExportReferencesSuccess(action)) {
        return {
            ...state,
            referencesStatus: 'LOADED',
            lastFileName: action.payload.filename
        }
    } else if (isExportReferencesError(action)) {
        return {
            ...state,
            referencesStatus: 'ERROR',
            lastFileName: undefined
        }
    } else if (isExportPublishedElements(action)) {
        return {
            ...state,
            publishedStatus: 'ERROR',
            lastFileName: undefined
        }
    } else if (isExportPublishedElementsSuccess(action)) {
        return {
            ...state,
            publishedStatus: 'LOADED',
            lastFileName: ''
        }
    } else if (isExportPublishedElementsError(action)) {
        return {
            ...state,
            publishedStatus: 'ERROR',
            lastFileName: undefined
        }
    } else if (isInitExportStatus(action)) {
        return {
            ...state,
            referencesStatus: 'NONE',
            authorsStatus: 'NONE',
            publishedStatus: 'NONE'
        }
    }

    return state;
}
import {
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop,
} from "react-dnd";
import { UD } from "../DnDTypes";
import {
  StatusToUpdateContext,
  useDirtyTreeContext
} from "../contexts/organizeTreeBibleContexts";
import { DocumentUnit } from "../models/DocumentUnit";
import {
  DocumentUnitWithPointer,
  NodeModel,
  addDocumentUnitBetween,
  addDocumentUnitIntoTree,
  removeDocumentUnitInTree
} from "../models/TreeModels";
import {
  isDocumentUnit,
  isDocumentUnitWithPointer
} from "../pages/organizeTree/tree/tree.utils";
import { useContext } from "react";


export const useDragUDFromTree = (
  doc: DocumentUnitWithPointer
): [ConnectDragSource, boolean] => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: UD,
      item: doc,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [doc]
  );

  return [drag, isDragging];
};

/**
 * Custom hook that enables dropping a document unit on a node title.
 * 
 * @param node - The node model on which the document unit is dropped.
 * @returns An array containing the drop target and a boolean indicating if the drop is currently over the target.
 */
export const useDropUDOnNodeTitle = (
  node: NodeModel
): [ConnectDropTarget, boolean] => {
  const [tree, setTree] = useDirtyTreeContext()
  
  const [, setStatusToUpdate] = useContext(StatusToUpdateContext)


  const [{ isOver }, drop] = useDrop(() => ({
    accept: UD,
    drop: (item: DocumentUnit | DocumentUnitWithPointer) => {
      if (tree.data === undefined) return;
      let updatedNodes: NodeModel[]
      if (isDocumentUnit(item)) {
        updatedNodes = addDocumentUnitIntoTree(tree.data.nodes, node.id, {
          documentId: item.id,
          title: item.title,
          nodeId: node.id,
          type: item.type,
          alert: false,
        });
        setStatusToUpdate(prevStatus => ({
          ...prevStatus,
          [item.id]: (prevStatus[item.id] || 0) + 1
        }));
      }

      if (isDocumentUnitWithPointer(item)) {
        updatedNodes = addDocumentUnitIntoTree(tree.data.nodes, node.id, {
          pointerId: item.pointerId,
          documentId: item.documentId,
          title: item.title,
          nodeId: node.id,
          type: item.type,
          alert: item.alert,
        });
        if (item.nodeId !== node.id) {
          updatedNodes = removeDocumentUnitInTree(
            updatedNodes,
            item.nodeId,
            item.documentId
          );
        }
      }
      setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes!! } });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return [drop, isOver];
};

/**
 * Custom hook that enables dropping a document unit between nodes.
 * 
 * @param node - The node model where the document unit will be dropped.
 * @param dropZoneIndex - The index of the drop zone where the document unit will be placed.
 * @returns A tuple containing the drop target and a boolean indicating if the drop is currently over the target.
 */
export const useDropUDBetween = (
  node: NodeModel,
  dropZoneIndex: number
): [ConnectDropTarget, boolean] => {
  const [tree, setTree] = useDirtyTreeContext()
  const [, setStatusToUpdate] = useContext(StatusToUpdateContext)
  const [{ isOver }, drop] = useDrop(() => ({
    accept: UD,
    drop: (item: DocumentUnitWithPointer | DocumentUnit) => {
      if (tree.data === undefined) return;
      let updatedNodes: NodeModel[]
      if (isDocumentUnitWithPointer(item)) {
        updatedNodes = addDocumentUnitBetween(
          tree.data.nodes,
          node.id,
          dropZoneIndex,
          { documentId: item.documentId, title: item.title, nodeId: node.id, type: item.type, alert: item.alert }
        );

        if (item.nodeId !== node.id) {
          updatedNodes = removeDocumentUnitInTree(
            updatedNodes,
            item.nodeId,
            item.documentId
          );
        }
      }

      if (isDocumentUnit(item)) {
        updatedNodes = addDocumentUnitBetween(
          tree.data.nodes,
          node.id,
          dropZoneIndex,
          { documentId: item.id, title: item.title, nodeId: node.id, type: item.type, alert: false }
        );
        setStatusToUpdate(prevStatus => ({
          ...prevStatus,
          [item.id]: (prevStatus[item.id] || 0) + 1
        }));
      }

      setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes!! } });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return [drop, isOver];
};

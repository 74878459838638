import React from 'react'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {currentImageSelector, currentUploadedImageSelector} from "../../../redux/selectors/image.selectors";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
}));

type Props = {
    form?: boolean
}

const ImageBreadcrumbs = ({form}: Props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const currentUploadedImage = useSelector(currentUploadedImageSelector)
    const currentImage = useSelector(currentImageSelector)
    const image = currentUploadedImage ? currentUploadedImage : currentImage

    const action = image.id !== '' ? 'misc.edit' : 'images.new_image'

    return (
        <Breadcrumbs aria-label="breadcrumb" separator="›" className={classes.root}>
            <Link color="inherit" to={'/' + t('routes.image.root')} component={RouterLink}>
                {t('app.sections.images')}
            </Link>

            {image.id !== '' && <Link color="inherit"
                                      to={'/' + t('routes.image.root') + '/' + image.id}
                                      component={RouterLink}>
                {image.id} {image['name'] ?? ''}
            </Link>}

            {form && <Typography color="textPrimary">{t(action)}</Typography>}
        </Breadcrumbs>)
}

export default ImageBreadcrumbs

import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {Action, isActionWithPayload} from "../actions/utils.actions";

const loggerMiddleware: Middleware = (_: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
    const specificName = (action as any).specificName

    if (isActionWithPayload(action)) {
        console.log('action', action.type + (specificName ? ' - ' + specificName : ''), action.payload);
    } else {
        console.log('action', action.type + (specificName ? ' - ' + specificName : ''));
    }
    return next(action);
};

export default loggerMiddleware;

import ConfirmationDialog from "../commons/ConfirmationDialog";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IconButton, Tooltip} from "@material-ui/core";
import axios, {AxiosResponse} from "axios";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../redux/reducers";
import {notify} from "../../redux/actions/notification.actions";
import {uploadArticle} from "../../redux/actions/article/article.actions";
import {useTranslation} from "react-i18next";
import {ImportIcon} from "../../icon";
import StyledFab from "../commons/StyledFab";
import {articleImportStatusSelector} from "../../redux/selectors/article/article.selectors";

type ImportArticleProps = {
    fab?: boolean
}

type ImportState = "non-initialized" | "importable" | "unimportable"

export default function ImportArticle({fab}: ImportArticleProps) {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const token = useSelector(({auth}: State) => auth.token)

    const articleImportStatus = useSelector(articleImportStatusSelector)

    const inputFile = useRef(null);
    const [files, setFiles] = useState(null as FileList | null)
    const [openImportDialog, setOpenImportDialog] = useState(false)
    const [importMode, setImportMode] = useState("")
    const [importInfos, setImportInfos] = useState({
        publication: '',
        periodical: '',
        articleId: '',
        articleTitle: ''
    })
    const [askImportState, setAskImportState] = useState("non-initialized" as ImportState)
    const [nonimportableReason, setNonimportableReason] = useState("")

    useEffect(() => {
        if (articleImportStatus === 'ERROR' || articleImportStatus === 'DONE') {
            setOpenImportDialog(false)
        }
    }, [articleImportStatus])

    const handleFileUpload = useCallback(() => {
        if (files) {
            const notification = notify('Import en cours', {variant: 'info', persist: true}, false)
            dispatch(notification)
            dispatch(uploadArticle({files, notificationKey: notification.payload.key}))
        }
    }, [files])

    const askMode = useCallback((files: FileList) => {
        const data = new FormData()
        data.append("file", files[0])


        axios.post("/api/article/ask-import", data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((result: AxiosResponse<{
            mode: string
            metadata: {
                id: string
                title: string
            }
            periodical: string
            publication: string
        }>) => {
            setAskImportState("importable")
            setImportMode(result.data.mode)
            setImportInfos({
                articleId: result.data.metadata.id,
                articleTitle: result.data.metadata.title,
                periodical: result.data.periodical,
                publication: result.data.publication
            })
        })
            .catch((err) => {
                setNonimportableReason(err.response.data)
                setAskImportState("unimportable")
            })
    }, [token])

    return <>
        <ConfirmationDialog
            title={"Import article"}
            description={
                <>
                    {askImportState === "importable" && <div>
                        <span style={{color: importMode === "MODIFICATION" ? "red" : undefined}}>
                            {t(`articles.import-confirmation.${importMode}`, importInfos)}
                        </span>
                        <br/>
                        {t('articles.import-confirmation.continue')}
                    </div>}
                    {askImportState === "non-initialized" && <div>
                        {t('articles.import-confirmation.non-initialized')}
                    </div>}
                    {askImportState === "unimportable" &&
                    <div>
                        <span style={{color: "red"}}>
                            {t('articles.import-confirmation.unimportable')}<br/>
                        </span>
                        {nonimportableReason}
                    </div>}
                </>
            }
            actions={[
                {label: t('dialogs.cancel'), color: 'default', action: () => setOpenImportDialog(false)},
                {
                    label: t('dialogs.confirm'),
                    disabled: askImportState !== "importable" || articleImportStatus === 'IN_PROGRESS',
                    action: () => handleFileUpload(),
                    variant: 'contained'
                }
            ]}
            isOpened={openImportDialog}
            onClose={() => {
                setNonimportableReason("")
                setAskImportState("non-initialized" as ImportState)
                setOpenImportDialog(false)
            }}
        />

        {fab && <StyledFab size="small" aria-label="CdF"
                           onClick={() => {
                               (document.getElementById('file-input-import') as any).value = '';
                               if (inputFile && inputFile.current) {
                                   (inputFile?.current as any).click();
                               }
                           }}>

            <Tooltip title={t('articles.import') ?? ""}>
                <ImportIcon style={{transform: 'rotate(180deg)'}}/>
            </Tooltip>
        </StyledFab>}

        {!fab && <Tooltip title={t('articles.import') ?? ""}>
            <IconButton onClick={() => {
                if (inputFile && inputFile.current) {
                    (inputFile?.current as any).click();
                }
            }} color={"inherit"}>
                <ImportIcon color={"inherit"}/>
            </IconButton>
        </Tooltip>}

        <input
            id='file-input-import'
            key={"import-article-click"}
            style={{display: 'none'}}
            accept={'.xml, .docx'}
            ref={inputFile}
            onInput={(e: any) => {
                if (e.target.files === null) return;

                setFiles(e.target.files)
                askMode(e.target.files)
                setOpenImportDialog(true)
            }}
            type="file"
        />
    </>
}

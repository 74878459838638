import { ConnectDragPreview, ConnectDragSource, useDrag } from "react-dnd";
import { UD } from "../../../DnDTypes";
import { DocumentUnit } from "../../../models/DocumentUnit";

export const useDragElementFromTank = (
  doc: DocumentUnit
): [ConnectDragSource, boolean, ConnectDragPreview] => {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: UD,
    item: doc,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return [drag, isDragging, preview];
};

export const ADMIN = 'ADMIN'
export const ADMIN_ADS = 'ADMIN_ADS'
export const ADMIN_AUTHORS = 'ADMIN_AUTHORS'
export const ADMIN_IMAGES = 'ADMIN_IMAGES'
export const ADMIN_ARTICLES = 'ADMIN_ARTICLES'

export const ONLINE = 'ONLINE'
export const ONLINE_BJS = 'ONLINE_BJS'
export const ONLINE_DEF = 'ONLINE_DEF'
export const ONLINE_GDP = 'ONLINE_GDP'

export const EDITOR = 'EDITOR'
export const EDITOR_BJS = 'EDITOR_BJS'
export const EDITOR_DEF = 'EDITOR_DEF'
export const EDITOR_GDP = 'EDITOR_GDP'

export const KYRIELLE = 'KYRIELLE'

export const MANAGER = 'MANAGER'
export const MANAGER_BJS = 'MANAGER_BJS'
export const MANAGER_DEF = 'MANAGER_DEF'
export const MANAGER_GDP = 'MANAGER_GDP'

export const PRODUCER = 'PRODUCER'
export const PRODUCER_BJS = 'PRODUCER_BJS'
export const PRODUCER_DEF = 'PRODUCER_DEF'
export const PRODUCER_GDP = 'PRODUCER_GDP'

export const VIEWER = 'VIEWER'

export const onlineRoles = [ADMIN, ONLINE]

import {Action} from "../actions/utils.actions";
import {
    isFetchPeriodicalsAction,
    isFetchPeriodicalsErrorAction,
    isFetchPeriodicalsSuccessAction,
    isUpdatePeriodicalSelectionAction
} from "../actions/periodical.actions";
import {Periodical} from "../../types/periodical";


type PeriodicalState = {
    periodicals: Periodical[],
    selected?: string[],
    status: string
}

const init: PeriodicalState = {
    periodicals: [],
    selected: [],
    status: "NONE"
}

const reducer = (state = init, action: Action): PeriodicalState => {
    if (isFetchPeriodicalsSuccessAction(action)) {
        return {
            selected: action.payload.filter(x => state.selected?.includes(x.id)).map(x => x.id),
            periodicals: action.payload,
            status: "LOADED"
        }
    } else if (isFetchPeriodicalsAction(action)) {
        return {
            ...state, status: "PENDING"
        }
    } else if (isFetchPeriodicalsErrorAction(action)) {
        return {
            ...state, status: "ERROR"
        }
    } else if (isUpdatePeriodicalSelectionAction(action)) {
        return {
            ...state,
            selected: action.payload
        }
    } else {
        return state
    }
}

export default reducer

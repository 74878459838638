export interface Pageable<T> {
  content: T[]
  size: number
  number: number
  totalElements: number
  totalPages: number
}

export const DEFAULT_PAGINATION = [50, 40, 30, 20, 10]
export const DEFAULT_SIZE = 50
export const DEFAULT_PAGE = 0

export const defaultPageable: Pageable<unknown> = {
  content: [],
  size: DEFAULT_SIZE,
  number: DEFAULT_PAGE,
  totalElements: -1,
  totalPages: -1
}
export const setPageNumber = <T>(updatedPageNumber: number) => (pageable?: Pageable<T>) => pageable ? ({
  ...pageable,
  number: updatedPageNumber
}) as Pageable<T> : defaultPageable

export const setPageSize = <T>(updatedPageSize: number) => (pageable?: Pageable<T>) => pageable ? ({
  ...pageable,
  size: updatedPageSize
}) as Pageable<T> : defaultPageable

export const setPageSizeFromString = <T> (updatedPageSize: string) => (pageable?: Pageable<T>) => pageable ? ({
  ...pageable,
  size: Number(updatedPageSize)
}) as Pageable<T> : defaultPageable
import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import {addSearchQueryParam, removeSearchQueryParam} from "../../../redux/actions/common-search.actions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useDispatch} from "react-redux";
import {ArticleFilter, articleFilter, ArticleNature, articleNature} from "../../../types/article";
import MemoCheckbox from "../../commons/MemoCheckbox";
import {SearchContext} from "../../../types/common-search";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {Divider} from "@material-ui/core";

type Props = {
    natureFilter: string[]
}

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 10,
        marginLeft: 0
    },
    divider: {
        width: '100%',
        height: 2
    }
}))

export default ({natureFilter}: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleChange = (value: ArticleNature | ArticleFilter) => (event, check) => {
        if (check) {
            dispatch(addSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'natureFilter',
                value: value
            }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'natureFilter',
                value: value
            }))
        }
    }

    return (
        <ListItem label={t('articles.nature')}>
            <>
                {articleNature.map((x, index) => (
                        <Grid item key={'nature_' + index}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<MemoCheckbox
                                        onChange={handleChange(x)}
                                        checked={natureFilter.some((e) => e === x)}
                                    />}
                                    label={t('enums.articles.nature.' + x + '.main')}
                                />
                            </FormGroup>
                        </Grid>
                    )
                )}
                <Divider className={classes.divider}/>

                {articleFilter.map((x, index) => (
                        <Grid item key={'nature_' + index}>
                            <FormGroup row>
                                <FormControlLabel
                                    control={<MemoCheckbox
                                        onChange={handleChange(x)}
                                        checked={natureFilter.some((e) => e === x)}
                                    />}
                                    label={t('enums.articles.nature.' + x + '.main')}
                                />
                            </FormGroup>
                        </Grid>
                    )
                )}
            </>
        </ListItem>
    )
}

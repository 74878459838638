import React from "react";
import {useDispatch} from "react-redux";
import {addSearchQueryParam, removeSearchQueryParam} from "../../../redux/actions/common-search.actions";
import Checkbox from '@material-ui/core/Checkbox';
import {useTranslation} from "react-i18next";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from "../../commons/ListItem";
import {SearchContext} from "../../../types/common-search";

type Props = {
    values?: boolean[]
}

export default ({values}: Props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleChange = (value: boolean, checked: boolean) => {
        if (checked) {
            dispatch(addSearchQueryParam({
                ctx: SearchContext.ADVERTISEMENT,
                field: 'priorityFilter',
                value: value
            }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.ADVERTISEMENT,
                field: 'priorityFilter',
                value: value
            }))
        }
    };

    return (
        <ListItem label={t('images.priority')}>
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange(true, check)}
                        checked={(values && values.some((e) => e))}
                    />}
                    label={t('misc.boolean.yes')}
                />
            </FormGroup>

            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange(false, check)}
                        checked={(values && values.some((e) => !e))}
                    />}
                    label={t('misc.boolean.no')}
                />
            </FormGroup>
        </ListItem>
    )
};

import React, {ChangeEvent, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {PublicationSearchFilters, SearchContext} from "../../types/common-search";
import {loadAutoCompletePublication} from "../../redux/actions/article/article-filters.actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {updateSearchQueryParam} from "../../redux/actions/common-search.actions";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import {EntityProposal} from "../../types/quick-search";
import {makeStyles} from "@material-ui/core/styles";
import {State} from "../../redux/reducers";

type Props = {
    publicationIdFilter?: string[],
    periodicalFilter?: string[]
}

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 20
    }
}))


export default ({publicationIdFilter, periodicalFilter}: Props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const [inputText, setInputText] = React.useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: EntityProposal[]) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ARTICLE,
            field: `publicationIdFilter`,
            value: value.map(e => e.id)
        }))
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option: EntityProposal) => {
            return option.label;
        },
    });

    useEffect(() => {
        dispatch(loadAutoCompletePublication({
            periodicalIdFilter: periodicalFilter,
            query: ''
        } as PublicationSearchFilters))
    }, [dispatch])

    const [options, selectedOptions] = useSelector(({article}: State) => {
        const options = article.filters.availablePublications
        const selectedOptions = publicationIdFilter ? options ? options.filter(x => publicationIdFilter!!.indexOf(x.id) > -1) : [] ?? [] : [];
        return [options, selectedOptions];
    }, shallowEqual);


    const statusPending = options === undefined

    return (
        <FormControl fullWidth={true} className={classes.select}>
            <Autocomplete openText={t('misc.open')}
                          clearText={t('misc.clear')}
                          closeText={t('misc.close')}
                          loadingText={t('misc.loading')}
                          noOptionsText={t('misc.no-options')}
                          color={"secondary"}
                          multiple
                          open={open}
                          onOpen={() => {
                              dispatch(loadAutoCompletePublication({
                                  periodicalIdFilter: periodicalFilter,
                                  query: ''
                              } as PublicationSearchFilters))
                              setOpen(true);
                          }}
                          onClose={() => {
                              setOpen(false);
                          }}
                          onChange={(event: any, newValue: EntityProposal[]) => handleChange(event, newValue)}
                          options={options ?? []}
                          getOptionLabel={option => option.label}

                          filterOptions={filterOptions}

                          value={selectedOptions}
                          inputValue={inputText}
                          onInputChange={
                              (event: ChangeEvent<{}>, value) => {
                                  if (event !== null) {
                                      setInputText(value);
                                      dispatch(loadAutoCompletePublication({
                                          periodicalIdFilter: periodicalFilter,
                                          query: value
                                      } as PublicationSearchFilters))
                                  }
                              }
                          }
                          disableCloseOnSelect
                          loading={statusPending}
                          renderOption={(option, {selected}) => (
                              <React.Fragment>
                                  <Checkbox
                                      checked={selected}
                                  />
                                  {option.label}
                              </React.Fragment>
                          )}
                          renderInput={(params) => {
                              return (<TextField
                                  {...params}
                                  variant="outlined"
                                  color={"secondary"}
                                  label={t('articles.published_in_publication')}
                                  InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                          <React.Fragment>
                                              {statusPending ?
                                                  <CircularProgress color="inherit" size={20}/> : null}
                                              {params.InputProps.endAdornment}
                                          </React.Fragment>
                                      ),
                                  }}
                              />)
                          }}
            />
        </FormControl>
    )
}

import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteAuthor,
    fetchAuthor,
    generateNewAuthor,
    saveAuthor,
    toggleAuthorEnabled,
    updateStatusAuthor
} from "../../../redux/actions/author.actions";
import { pushBack } from "../../../redux/actions/router.actions";
import AuthorBreadcrumbs from "../commons/AuthorBreadcrumbs";
import Grid from "@material-ui/core/Grid";
import AuthorNotes from "./AuthorFormNotes";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import { useForm } from "react-hook-form";
import AuthorIdentificationForm from "./AuthorFormIdentification";
import { useTranslation } from "react-i18next";
import FunctionAuthorContentForm from "./AuthorFormFunction";
import ProfileAuthorContentForm from "./AuthorFormProfile";
import ContactsPanels from "./contact/ContactsPanels";
import RightPanel from "../commons/RightPanel";
import ImagesEditPanel from "./image/ImagesForm";
import { State } from "../../../redux/reducers";
import AuthorUtils from "../../../utils/author-utils";
import { PersistedAuthor } from "../../../types/author";
import RouteLeavingGuard from "../../RouteLeavingGuard/RouteLeavingGuard";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import { ADMIN, ADMIN_AUTHORS } from "../../../utils/roles";
import Toggler from "../../commons/Toggler";
import { authorStatusSelector } from "../../../redux/selectors/author.selectors";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 50px 0'
    },
    button: {
        marginTop: theme.spacing(2)
    },
    delete: {
        paddingLeft: 50,
        marginTop: theme.spacing(2)
    },
    right: {
        paddingLeft: 50
    },
    paper: {
        padding: 15,
        marginBottom: 20
    },
    header: {
        marginBottom: 20
    }
}))

export default function AuthorForm() {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const classes = useStyles()
    const { id } = useParams() as { id: string }
    const location = useLocation()

    useEffect(() => {
        dispatch(updateStatusAuthor('PENDING'))

        if (id) {
            dispatch(fetchAuthor({ id, edit: true }))
        } else {
            dispatch(generateNewAuthor())
        }
    }, [dispatch, id]);

    const [isReady, setIsReady] = useState(false)

    const status = useSelector(authorStatusSelector)

    useEffect(() => {
        setIsReady(status === 'LOADED')
    }, [dispatch, status])

    const { register, unregister, setValue, handleSubmit, errors, control } = useForm<FormData>({
        mode: 'onBlur',
        shouldFocusError: true,
        criteriaMode: "all",
    });

    const isDirty = useSelector(({ author }: State) => author.form.isDirty)
    const onSubmit = handleSubmit(() => {
        dispatch(saveAuthor());
    });

    const onAuthorDeletion = (id: string) => {
        dispatch(deleteAuthor({ id, refresh: false }));
        history.push('/auteur');
    }

    const handleUnload = (ev) => {
        if (isDirty && location.pathname.indexOf("edit") !== -1) {
            ev.preventDefault();
            return ev.returnValue = 'Êtes vous sûr de vouloir quitter cette page ? Des modifications en cours pourraient être perdues';
        }
    }

    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload)
        }
    })

    const [dialogOpen, openDialog] = useState(false);
    const closeDialog = () => openDialog(false)

    const author = useSelector(({ author }: State) => AuthorUtils.createMappedFullAuthor(author.current as PersistedAuthor))
    const isAuthorEnabled = author.enable

    return (
        <div className={classes.root}>
            <RouteLeavingGuard
                when={isDirty}
                shouldBlockNavigation={() => true}
                navigate={(location) => history.push(location)}
            />

            <Grid container className={classes.header}>
                <Grid item xs={9}>
                    <Typography variant="h4">
                        {t(`authors.${id ? 'edit' : 'create'}`)}
                    </Typography>
                    <AuthorBreadcrumbs form={true} />
                </Grid>
            </Grid>
            {isReady && <form onSubmit={onSubmit}>
                <Grid container spacing={8}>
                    <Grid item xs={9}>
                        <ImagesEditPanel register={register} errors={errors} control={control} />
                        <Grid container
                            justify={"flex-end"} className={classes.button} spacing={1}>
                            <Grid item>
                                <Button variant="outlined" onClick={() => dispatch(pushBack())}>
                                    {t('misc.cancel')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" type={"submit"}>
                                    {t('misc.save')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {id && <Grid item xs={3}>
                        <RightPanel />
                    </Grid>}
                </Grid>
            </form>
            }
        </div>
    );
}

import React from "react";
import Box from "@material-ui/core/Box";
import Alert from '@material-ui/lab/Alert';
import {useTranslation} from "react-i18next";


export default () => {
    const {t} = useTranslation();

    return (<div style={{width: '100%'}}>
        <Box display="flex" justifyContent="center" m={1} p={1}>
            <Alert severity="error">{t('errors.global')}</Alert>
        </Box>
    </div>)
}
import React, { Dispatch, SetStateAction } from 'react';
import {
  DocumentTypeEnum
} from '../../../models/Search';

import { LexPressAdvancedFilters } from "./LexPressAdvancedFilters";
import { OxygenAdvancedFilters } from "./OxygenAdvancedFilters";
import { GdaAdvancedFilters } from './GdaAvancedFilters';
import { OuvrageAdvancedFilters } from './OuvrageAdvancedFilters';

export const rowGap = 10

export const AdvancedFilters = ({ source }: { source?: DocumentTypeEnum }) => {

  if (source === DocumentTypeEnum.LEXPRESS) return (
    <LexPressAdvancedFilters />
  )

  if (source === DocumentTypeEnum.OXYGEN) return (
    <OxygenAdvancedFilters />
  )

  if (source === DocumentTypeEnum.GDA) return (
    <GdaAdvancedFilters />
  )

  return (
    <OuvrageAdvancedFilters />
  )
}
import { Box, Typography } from "@material-ui/core"
import React, { Children, useContext, useState } from "react"
import { Column, Row } from "../../components/FlexBox"
import { KyrButton } from "../../components/KyrButton"
import { KyrDivider } from "../../components/KyrDivider"
import { KyrCancelTreeUpdatePopUp } from "../../components/KyrPopUp"
import { TreeContext } from "../../contexts/organizeTreeBibleContexts"
import { ChildrenProps } from "../../types"
import { AddElement } from "./AddElement"
import { TreeStructure } from "./TreeStructure"
import { SaveType, useGetTree, useHandleSaveTree } from "../../hooks/treeHooks"
import { KyrTitle } from "../../components/KyrTitle"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { ArrowBackIos } from "@material-ui/icons"
import { useSetFaviconAndTabeLabel, useSetRootHeightTo100vh } from "../../hooks/UIhooks"
import { palette } from "../../palette"

const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)

  return (
    <Column id="administrate-tree-container" grow={1} style={{ padding: '2em', overflow: 'auto' }} rowGap={40}>
      <Column id="title-save-cancel-buttons" >
        {childrenList[0]}
      </Column>
      <Row grow={1} style={{ justifyContent: 'space-between', overflow: 'auto' }}>
        <Column id="add-elements" style={{ width: '45%' }}>
          {childrenList[1]}
        </Column>
        <Column id="tree-structure" style={{ width: '50%', height: '100%' }}>
          {childrenList[2]}
        </Column>
      </Row>
    </Column>
  )
}

const HeaderLayout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)


  return (
    <>
      <Row id="title" justifyContent="space-between">
        {childrenList[0]}
        {childrenList[1]}
      </Row>
      <KyrDivider />
      <Box id="buttons" style={{ display: 'flex', justifyContent: 'flex-end', columnGap: 7 }}>
        {childrenList[2]}
        {childrenList[3]}
      </Box>
    </>
  )
}

const Header = ({ showPopUp }: { showPopUp: () => void }) => {
  const [tree] = useContext(TreeContext)
  const {t} = useTranslation()
  const {id} = useParams<{ id?: string }>();

  const handleRefreshTress = useGetTree()
  const handleSaveTree = useHandleSaveTree()

  const handleSave = async () => {
    if(!tree.data) return
    await handleSaveTree(SaveType.ADMIN)
    await handleRefreshTress(tree.data.id.toString())
  }

  return (
    <HeaderLayout>
      <KyrTitle>
        {t('kyrielle.administrate.title', {cdfAdminTitle: tree.data? t(`kyrielle.administrate.cdfTitle.${tree.data.type}`) : '...'})}
      </KyrTitle>
      <KyrTitle style={{ display: 'flex', alignItems: 'center'}}> 
        <ArrowBackIos style={{ color: palette.primary }} /> 
        <Link style={{ color: palette.primary, textDecoration: 'none' }} to={`/kyrielle/organizeTree/${id}`}>Retour sur l’organisation des chemins de fer</Link>
      </KyrTitle>
      <KyrButton
        onClick={showPopUp}
        disabled={!tree.data?.isDirty}
        kyrType="secondary">
        {t('kyrielle.button.cancel')}
      </KyrButton>
      <KyrButton onClick={handleSave} kyrType="primary">{t('kyrielle.button.save')}</KyrButton>
    </HeaderLayout>
  )
}

export const AdministrateTree = () => {
  useSetRootHeightTo100vh()

  const [show, setShow] = useState(false)
  const showPopUp = () => setShow(true)
  return (
    <>
      <KyrCancelTreeUpdatePopUp showState={[show, setShow]} />
      <Layout>
        <Header showPopUp={showPopUp} />
        <AddElement />
        <TreeStructure />
      </Layout>
    </>
  )
}
import {Grid} from "@material-ui/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {PublicationSearchResult, SearchContext} from "../../../types/common-search";
import LinkButton from "../../commons/button/LinkButton";
import BATPublication from "../workflow/BATPublication";
import PrinterPdfPublication from "../workflow/PrinterPdfPublication";
import CancelPrinterPdfPublication from "../workflow/CancelPrinterPdfPublication";
import {PublicationStatus} from "../../../types/publication";
import UnlockPdfPublication from "../workflow/UnlockPdfPublication";
import Auth from "../../Auth";
import {ADMIN, EDITOR, MANAGER, ONLINE, PRODUCER} from "../../../utils/roles";
import EditPublication from "../workflow/EditPublication";
import {OrganizeBundleIcon} from "../../commons/icon/icons";
import { GeneratePlacardButton } from "../../GeneratePlacardButton";


type LexPublicationListProps = {
    publication: PublicationSearchResult
}

const PublicationTableActions = ({publication}: LexPublicationListProps) => {
    const {t} = useTranslation()
    return (<Grid container item xs={12} justify={"center"}>
            <Auth requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER, ONLINE]}
                  periodicalRestriction={publication.periodicalId}>

                {/* edit parution */}
                <EditPublication publication={publication}/>

                {/* organise publication */}
                {publication.status !== PublicationStatus.PUBLISHED &&
                <Auth requiredRoles={[ADMIN, EDITOR, MANAGER]} periodicalRestriction={publication.periodicalId}>
                    <Grid item>
                        <LinkButton
                            to={`/${t('routes.publication.root')}/${publication.id}/${t('routes.publication.organize')}`}
                            title={t('publications.edit_plan_title') ?? ''} icon={<OrganizeBundleIcon/>}/>
                    </Grid>
                </Auth>
                }

                <GeneratePlacardButton pdfPublication={{publication}} />

                <UnlockPdfPublication publication={publication} context={SearchContext.PUBLICATION}/>

                <PrinterPdfPublication publication={publication}/>

                <CancelPrinterPdfPublication publication={publication}/>

                <BATPublication publication={publication}/>
            </Auth>
        </Grid>
    )
}

export default PublicationTableActions

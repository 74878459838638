import { useEffect } from "react";

/**
 * A custom React Hook that sets the height of the root HTML element to 100vh.
 * This hook uses the useEffect hook to apply the height when the component mounts,
 * and cleans up by resetting the height when the component unmounts.
 */
export const useSetRootHeightTo100vh = () => {
  useEffect(() => {
    // Select the root HTML element
    const rootElement: HTMLDivElement | null = document.querySelector("#root");

    // If the root element exists, set its height to 100vh
    if (rootElement) {
      rootElement.style.height = "100vh";
      rootElement.style.display = "flex";
      rootElement.style.flexDirection = "column";
    }

    // Cleanup function to reset the height of the root element when the component unmounts
    return () => {
      if (rootElement) {
        rootElement.style.height = "";
        rootElement.style.display = "";
        rootElement.style.flexDirection = "";
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount
};


/**
 * Custom hook to set the favicon of the page.
 */
export const useSetFaviconAndTabeLabel = () => {
  useEffect(() => {
    const link: HTMLLinkElement | null | undefined = document.querySelector('head')?.querySelector("link[rel~='icon']")
    const title: HTMLTitleElement | null | undefined = document.querySelector('title')

    if(title) {
      title.innerText = 'Kyrielle'
    }

    if(link) {
      link.href = "/kyr-favicon.ico" 
    }

    return () => {
      if(link) {
        link.href = "/favicon.ico" 
      }

      if(title) {
        title.innerText = 'Lex-Press'
      }
    }
  }, [])
}
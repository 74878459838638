import { Dirty } from "../types";
import { v4 as uuidv4 } from 'uuid';
import { DocumentTypeEnum } from "./Search";

export enum TreeType {
  ACTES_CLAUSES = "ACTES_CLAUSES",
  ETUDES_SOCIETES = "ETUDES_SOCIETES",
  ETUDES_BOURSE = "ETUDES_BOURSE",
  FORMULES_SOCIALES = "FORMULES_SOCIALES",
}

export interface DocumentUnitWithPointer {
  pointerId?: number;
  nodeId?: string;
  documentId: string;
  title: string;
  type: DocumentTypeEnum;
  alert: boolean;
}

export interface NodeModel {
  id: string;
  name: string;
  documentUnits: DocumentUnitWithPointer[];
  sub: NodeModel[];
}

export const createNodeModel = (name: string): NodeModel => {
  const id = uuidv4()
  return ({
    id,
    name,
    documentUnits: [],
    sub: []
  })
} 

export interface TreeModel extends Dirty {
  id: number;
  type: TreeType;
  nodes: NodeModel[];
}

/* ------------------ UD OPERATIONS ------------------ */

/**
 * Checks if a node or any of its sub-nodes have document units.
 * @param node - The node to check.
 * @returns `true` if the node or any of its sub-nodes have document units, `false` otherwise.
 */
export const hasDocumentUnits = (node: NodeModel): boolean => {
  if (node.documentUnits && node.documentUnits.length > 0) {
      return true;
  }

  for (let subNode of node.sub) {
      if (hasDocumentUnits(subNode)) {
          return true;
      }
  }

  return false;
}

/**
 * Adds a document unit into the tree structure at the last postion in the (sub)node.
 *
 * @param nodes - The array of NodeModel objects representing the tree nodes.
 * @param nodeTargetId - The ID of the target node where the document unit should be added.
 * @param element - The DocumentUnitWithPointer object to be added into the tree.
 * @returns The updated array of NodeModel objects.
 */
export const addDocumentUnitIntoTree = (
  nodes: NodeModel[],
  nodeTargetId: string,
  element: DocumentUnitWithPointer
) => {
  for (const node of nodes) {
    if (node.id === nodeTargetId) {
      node.documentUnits = [
        ...node.documentUnits.filter(
          (e) => e.documentId !== element.documentId
        ),
        element
      ];
    } else {
      addDocumentUnitIntoTree(node.sub, nodeTargetId, element);
    }
  }

  return nodes;
};

/**
 * Removes a document unit from the tree structure.
 * @param nodes - The array of NodeModel objects representing the tree structure.
 * @param nodeTargetId - The ID of the target node to remove the document unit from.
 * @param elementId - The ID of the document unit to remove.
 * @returns The updated array of NodeModel objects.
 */
export const removeDocumentUnitInTree = (
  nodes: NodeModel[],
  nodeTargetId?: string,
  elementId?: string
) => {
  for (const node of nodes) {
    if (node.id === nodeTargetId) {
      node.documentUnits = node.documentUnits.filter(
        (du) => du.documentId !== elementId
      );
    }
    removeDocumentUnitInTree(node.sub, nodeTargetId, elementId);
  }

  return nodes;
};

export const updateMultipleAlert = (nodes: NodeModel[], nodeTargetId: string, alert: boolean): NodeModel[] => {
  return nodes.map(node => {
    if (node.id === nodeTargetId) {
        return {
            ...node,
            documentUnits: node.documentUnits.map(documentUnit => {
                return {...documentUnit, alert: alert};
            }),
        }   
    } else {
        return {
            ...node,
            sub: updateMultipleAlert(node.sub, nodeTargetId, alert)
        };
    }
});
};

export const updateAlert = (nodes: NodeModel[], nodeTargetId?: string,  elementId?: string): NodeModel[] => {
  return nodes.map(node => {
      if (node.id === nodeTargetId) {
          return {
              ...node,
              documentUnits: node.documentUnits.map(documentUnit => {
                  if (documentUnit.documentId === elementId) {
                      return {
                          ...documentUnit,
                          alert: !documentUnit.alert
                      };
                  }
                  return documentUnit;
              }),
          }   
      } else {
          return {
              ...node,
              sub: updateAlert(node.sub, nodeTargetId, elementId)
          };
      }
  });
}

/**
 * Adds a document unit between nodes in a tree structure.
 * 
 * @param nodes - The array of node models representing the tree structure.
 * @param nodeTargetId - The ID of the node where the document unit should be added.
 * @param dropZoneIndex - The index of the drop zone where the document unit should be added.
 * @param documentUnit - The document unit to be added.
 * @returns The updated array of node models.
 */
export const addDocumentUnitBetween = (
  nodes: NodeModel[],
  nodeTargetId: string,
  dropZoneIndex: number,
  documentUnit: DocumentUnitWithPointer
) => {
  for (const node of nodes) {
    if (node.id === nodeTargetId) {
      const destinationIndex = dropZoneIndex + 1
      const firstPart = node.documentUnits.slice(0, destinationIndex)
      const secondPart = node.documentUnits.slice(destinationIndex, node.documentUnits.length)
      node.documentUnits = [
        ...firstPart.filter(e => e.documentId !== documentUnit.documentId),
        documentUnit,
        ...secondPart.filter(e => e.documentId !== documentUnit.documentId)
      ]
    } else {
      addDocumentUnitBetween(node.sub, nodeTargetId, dropZoneIndex, documentUnit);
    }
  }

  return nodes;
};


/* ----------------- NODE OPERATIONS ----------------- */

/**
 * Checks if a node has sub-nodes.
 * @param node - The node to check.
 * @returns A boolean indicating whether the node has sub-nodes.
 */
export const hasSubNodes = (node: NodeModel): boolean => {
  return node.sub && node.sub.length > 0;
}

/**
 * Adds a node into a tree structure at the last position in the (sub)node.
 *
 * @param nodes - The array of nodes representing the tree.
 * @param nodeTargetId - The ID of the node where the new node should be added.
 * @param nodeToAdd - The node to be added into the tree.
 * @returns The updated array of nodes with the new node added.
 */
export const addNodeIntoTree = (
  nodes: NodeModel[],
  nodeTargetId: string,
  nodeToAdd: NodeModel
): NodeModel[] => {
  return nodes.map(node => {
    if (node.id === nodeTargetId) {
      return {
        ...node,
        sub: [...node.sub, nodeToAdd]
      };
    } else {
      return {
        ...node,
        sub: addNodeIntoTree(node.sub, nodeTargetId, nodeToAdd)
      };
    }
  });
}

/**
 * Removes a node from an array of nodes recursively.
 * @param nodes - The array of nodes.
 * @param nodeId - The ID of the node to remove.
 * @returns The updated array of nodes after removing the specified node.
 */
export const removeNode = (nodes: NodeModel[], nodeId: string): NodeModel[] => {
  return nodes.reduce((result: NodeModel[], node: NodeModel) => {
      if (node.id !== nodeId) {
          result.push({
              ...node,
              sub: removeNode(node.sub, nodeId)
          });
      }
      return result;
  }, [])
}

/**
 * Adds a node between existing nodes in a tree.
 *
 * @param nodes - The array of node models representing the tree.
 * @param parentNodeId - The ID of the parent node where the new node should be added. Use `null` for top-level nodes.
 * @param dropZoneIndex - The index of the drop zone where the new node should be inserted.
 * @param node - The node model to be added.
 * @returns The updated array of node models with the new node inserted at the specified position.
 */
export function addNodeBetween(
  nodes: NodeModel[], 
  parentNodeId: string | null, 
  dropZoneIndex: number, 
  node: NodeModel
): NodeModel[] {

  if(parentNodeId === null) {
    return [
      ...nodes.slice(0, dropZoneIndex).filter(n => n.id !== node.id),
      node,
      ...nodes.slice(dropZoneIndex).filter(n => n.id !== node.id)
    ]
  }


  return nodes.map((currentNode) => {
    if (currentNode.id === parentNodeId) {
      // Créer un nouveau tableau 'sub' avec le nouveau nœud inséré à l'index spécifié
      const newSub = [
        ...currentNode.sub.slice(0, dropZoneIndex).filter(n => n.id !== node.id),
        node,
        ...currentNode.sub.slice(dropZoneIndex).filter(n => n.id !== node.id)
      ];
      // Retourner un nouvel objet pour le nœud courant avec le nouveau tableau 'sub'
      return { ...currentNode, sub: newSub };
    } else if (currentNode.sub.length > 0) {
      // Si le nœud courant a des sous-nœuds, récursivement chercher le nœud parent dans les sous-nœuds
      return { ...currentNode, sub: addNodeBetween(currentNode.sub, parentNodeId, dropZoneIndex, node) };
    }
    return currentNode;
  });
}

/**
 * Returns the parent ID of a given node ID in a tree structure.
 * If the node ID is not found or has no parent, returns null.
 *
 * @param nodes - The array of node models representing the tree structure.
 * @param nodeId - The ID of the node for which to find the parent.
 * @returns The parent ID of the node, or null if not found or has no parent.
 */
export const getParentId = (nodes: NodeModel[], nodeId: string): string | null => {
  for (const node of nodes) {
    if (node.sub.some(subNode => subNode.id === nodeId)) {
      return node.id;
    }
    const parent = getParentId(node.sub, nodeId);
    if (parent) {
      return parent;
    }
  }
  return null;
}

/**
 * Changes the name of a node in the tree.
 * @param nodes - The array of node models representing the tree.
 * @param nodeId - The ID of the node to be changed.
 * @param newName - The new name for the node.
 * @returns The updated array of node models.
 */
export const changeNodeName = (nodes: NodeModel[], nodeId: string, newName: string): NodeModel[] => {
  return nodes.map(node => {
      if (node.id === nodeId) {
          return {
              ...node,
              name: newName,
              sub: changeNodeName(node.sub, nodeId, newName)
          };
      } else {
          return {
              ...node,
              sub: changeNodeName(node.sub, nodeId, newName)
          };
      }
  });
}

/**
 * Finds the depth of a node in a tree structure.
 * @param nodes - The array of node models representing the tree.
 * @param nodeId - The ID of the node to find the depth for.
 * @param currentDepth - The current depth of the recursion (default: 0).
 * @returns The depth of the node if found, or null if the node is not found.
 */
export const findNodeDepth = (nodes: NodeModel[], nodeId: string, currentDepth: number = 0): number | null => {
  for (const node of nodes) {
      if (node.id === nodeId) {
          return currentDepth;
      }

      const foundDepth = findNodeDepth(node.sub, nodeId, currentDepth + 1);
      if (foundDepth !== null) {
          return foundDepth;
      }
  }

  return null;
}


/**
 * Finds the maximum depth of a node in a tree.
 * @param node - The node to start the search from.
 * @param currentDepth - The current depth of the node (default: 0).
 * @returns The maximum depth of the node.
 */
export const findMaxDepth = (node: NodeModel, currentDepth: number = 0): number => {
  let maxDepth = currentDepth;
  for (const subNode of node.sub) {
      const subNodeDepth = findMaxDepth(subNode, currentDepth + 1);
      if (subNodeDepth > maxDepth) {
          maxDepth = subNodeDepth;
      }
  }
  return maxDepth;
}
import {AxiosStatic} from "axios";
import {merge, Observable, of} from "rxjs";
import {Action} from "../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, filter, flatMap, map} from "rxjs/operators";
import {notifyError} from "../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import {fetchPeriodicalsError, fetchPeriodicalsSuccess, isFetchPeriodicalsAction} from "../actions/periodical.actions";

function _fetch(axios: AxiosStatic, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/periodical`, {headers}))
        .pipe(
            map((result) => fetchPeriodicalsSuccess(result.data)),
            catchError(err => merge(
                of(fetchPeriodicalsError()),
                of(notifyError(+err.response.status, {}))
            ))
        )
}

export function fetchPeriodicalEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchPeriodicalsAction),
        flatMap(() => _fetch(axios,
            state$.value.auth.token ?? "")
        )
    )
}

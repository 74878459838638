import React from 'react';
import './Access.css';
import {useSelector} from "react-redux";
import {State} from "../../redux/reducers";
import {useTranslation} from "react-i18next";
import {ADMIN} from "../../utils/roles";

const hasAccess = (roles: string[], role: string, resource: string) => {
    if (roles.includes(ADMIN)) {
        return true
    } else {
        return roles.includes(`${role}_${resource}`)
    }
}

type RowProps = {
    title: string
}
const Row = ({title}: RowProps) => {
    const {t} = useTranslation();
    const roles = useSelector(({auth}: State) => auth?.roles ?? []);
    return (<tr>
        <td>{title}</td>
        <td>{hasAccess(roles, "MANAGER", title) ? t("misc.boolean.yes").toLocaleUpperCase() : t("misc.boolean.no").toLocaleUpperCase()}</td>
        <td>{hasAccess(roles, "EDITOR", title) ? t("misc.boolean.yes").toLocaleUpperCase() : t("misc.boolean.no").toLocaleUpperCase()}</td>
        <td>{hasAccess(roles, "PRODUCER", title) ? t("misc.boolean.yes").toLocaleUpperCase() : t("misc.boolean.no").toLocaleUpperCase()}</td>
        <td>{hasAccess(roles, "ONLINE", title) ? t("misc.boolean.yes").toLocaleUpperCase() : t("misc.boolean.no").toLocaleUpperCase()}</td>
    </tr>)
}

const Access = () => {
    const {t} = useTranslation();

    const rows = ['BJS', 'DEF', 'GDP'].map((title) => (<Row key={title} title={title}/>))
    const roles = useSelector(({auth}: State) => auth?.roles ?? []);

    return (<div className="access">
        <table>
            <thead>
            <tr>
                <th></th>
                <th>{t('app.sections.authors')}</th>
                <th>{t('app.sections.images')}</th>
                <th>{t('app.sections.ads')}</th>
                <th>{t('app.sections.online')}</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>{t('roles.admin')}</td>
                <td>{hasAccess(roles, "ADMIN", "AUTHORS") ? t("misc.boolean.yes").toLocaleUpperCase() : t("misc.boolean.no").toLocaleUpperCase()}</td>
                <td>{hasAccess(roles, "ADMIN", "IMAGES") ? t("misc.boolean.yes").toLocaleUpperCase() : t("misc.boolean.no").toLocaleUpperCase()}</td>
                <td>{hasAccess(roles, "ADMIN", "ADS") ? t("misc.boolean.yes").toLocaleUpperCase() : t("misc.boolean.no").toLocaleUpperCase()}</td>
                <td>{hasAccess(roles, "ADMIN", "ONLINE") ? t("misc.boolean.yes").toLocaleUpperCase() : t("misc.boolean.no").toLocaleUpperCase()}</td>
            </tr>
            </tbody>
        </table>
        <table>
            <thead>
            <tr>
                <th>{t('misc.entities.periodical', {context: "plural"})}</th>
                <th>{t('roles.manager')}</th>
                <th>{t('roles.editor')}</th>
                <th>{t('roles.producer')}</th>
                <th>{t('roles.online')}</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>
    </div>)
}

export default Access

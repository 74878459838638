import React from "react";
import {convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import {makeStyles} from "@material-ui/core/styles";
import {Controller} from "react-hook-form";
import sanitizeHtml from 'sanitize-html';

type Props = {
    state: EditorState,
    setState: any
    onChange: (value: string) => void,
    withBold?: boolean,
    control?: any,
    required?: boolean,
    name: string
}


const useStyles = makeStyles(() => ({
    wrapperClass: {
        width: "100%",
        border: 'solid 1px rgba(0, 0, 0, 0.42)',
        borderRadius: 3
    },
    editorClass: {
        marginLeft: 10
    },
    toolbarClass: {
        border: "none",
        marginBottom: 0
    }
}))


export default function Wysiwyg2({state, setState, onChange, withBold, control, required, name}: Props) {
    const classes = useStyles();

    const editor = (data?: any) => {
        return <Editor editorState={state}
                       handlePastedText={() => false}
                       placeholder="Définir votre texte ici ..."
                       onEditorStateChange={(editorState) => {
                           setState(editorState)
                           const html = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/[\n\r]/g, '')
                           let clean = sanitizeHtml(html, {
                               allowedTags: withBold ? ['em', 'sup', 'sub', 'strong', 'p'] : ['em', 'sup', 'sub', 'p']
                           }).replaceAll(/<p>/g, '')
                               .replaceAll(/<\/p>/g, '')
                               .replaceAll(/[\n]/g, ' ')
                               .replaceAll(/[ +]/g, ' ')
                               .trim()

                           onChange(clean)
                           if (data)
                               data.onChange(clean)
                       }}
                       toolbarOnFocus={false}
                       wrapperClassName={classes.wrapperClass}
                       editorClassName={classes.editorClass}
                       toolbarClassName={classes.toolbarClass}
                       toolbar={{
                           options: ['inline'],
                           inline: {
                               options: withBold ? ['bold', 'italic', 'superscript', 'subscript'] : ['italic', 'superscript', 'subscript']
                           }
                       }}
        />
    }

    if (control) {
        return (
            <>
                <Controller
                    render={data => editor(data)}
                    name={name}
                    control={control}
                    rules={
                        {
                            required: required,
                            maxLength: 500
                        }
                    }
                    defaultValue={state}
                />

            </>
        )
    } else {
        return (
            editor(undefined)
        )
    }
}

import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {AuthorSearchFilters, SearchContext} from "../../../types/common-search";
import AsyncAutoCompleteCityCountry from "./AsyncAutoCompleteCityCountry";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "../../commons/ListItem";
import {ClearIcon} from "../../../icon"
import {makeStyles} from "@material-ui/core/styles";
import DateRangePicker from "../../commons/DateRangePicker";
import PublishedIn from "../../commons/PublishedIn";
import TrueFalseFilter from "./TrueFalseFilter";
import {
    addSearchQueryParam,
    removeSearchQueryParam,
    resetSearchQueryParams,
    updateSearchQueryParam
} from "../../../redux/actions/common-search.actions";

type Props = {
    filters: AuthorSearchFilters
}


const useStyles = makeStyles(() => ({
    paper: {
        borderBottom: "none"
    }
}))

export default ({filters}: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    const minDate = "2000-01-01"
    const maxDate = "2025-01-01"

    return (
        <>
            <Button onClick={() => dispatch(resetSearchQueryParams(SearchContext.AUTHOR))}
                    startIcon={<ClearIcon/>}>Réinitialiser</Button>

            <Paper variant="outlined" square className={classes.paper}>
                <List component="div" disablePadding>
                    <ListItem label={t('misc.publish_date')}>
                        <DateRangePicker
                            end={filters.publicationDateFilter?.end}
                            start={filters.publicationDateFilter?.start}
                            onDatesChange={(values) => dispatch(updateSearchQueryParam({
                                ctx: SearchContext.AUTHOR,
                                field: "publicationDateFilter",
                                value: values
                            }))}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </ListItem>

                    <PublishedIn
                        publicationPeriodicalFilter={filters.publicationPeriodicalFilter ?? []}
                        ctx={SearchContext.AUTHOR}
                    />

                    <ListItem label={t('authors.country')}>
                        <AsyncAutoCompleteCityCountry label={t('authors.country')}
                                                      field={"country"}
                                                      selectedOptions={filters.countryFilter}/>
                    </ListItem>


                    <ListItem label={t('authors.cities')}>
                        <AsyncAutoCompleteCityCountry label={t('authors.cities')}
                                                      field={"city"}
                                                      selectedOptions={filters.cityFilter}/>
                    </ListItem>

                    <ListItem label={t('app.sections.images')}>
                        <TrueFalseFilter ctx={SearchContext.AUTHOR} values={filters.withImageFilter}
                                         field={"withImageFilter"}
                                         addSearchQueryAction={addSearchQueryParam}
                                         removeSearchQueryAction={removeSearchQueryParam} trueLabel={t('misc.with')}
                                         falseLabel={t('misc.without')}/>
                    </ListItem>
                </List>
            </Paper>
        </>
    )
}

export enum PointerType {
    ARTICLE,
    ILLUSTRATION,
    ADVERTISEMENT,
    CONTAINER
}

export type PlanNode = {
    id: string
    name?: string
    type: "CONTAINER" | "ARTICLE" | "ILLUSTRATION" | "ADVERTISEMENT"
    sub: PlanNode[],
    planNodeId?: string,
    meta?: ArticleNodeMeta
}

export type ArticleNodeMeta = {
    isBundle: boolean
}

export type PlanNodeCollection = {
    plan: PlanNode[]
    creationDate: string
    creationUserId: string
    periodicalId?: string
    publicationId?: number
    nodes?: PlanNode[]
}

export function flatMapNodesFiltered(nodes: PlanNode[], filter: (node: PlanNode) => boolean): PlanNode[] {
    return nodes
        .flatMap(it => [it, ...flatMapNodesFiltered(it.sub, filter)])
        .filter(it => filter(it))
}

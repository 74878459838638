import React from "react";
import {ArticleSearchFilters, SearchContext} from "../../../types/common-search";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {ClearIcon} from "../../../icon";
import ListItem from "../../commons/ListItem";
import {resetSearchQueryParams, updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import DateRangePicker from "../../commons/DateRangePicker";
import {maxDate, minDate} from "../../../utils/common-utils";
import ParutionFilter from "../../commons/ParutionFilter";
import StatusArticleFilter from "./StatusArticleFilter";
import AttachedArticleFilter from "./AttachedArticleFilter";
import PeriodicalAutoComplete from "../../authors/list/PeriodicalAutoComplete";
import TypeArticleFilter from "./TypeArticleFilter";
import NatureArticleFilter from "./NatureArticleFilter";
import AsyncAuthorsAutoComplete from "./AsyncAuthorsAutoComplete";
import AsyncPlanNodesAutoComplete from "./AsyncPlanNodesAutoComplete";
import {Divider} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Link as RouterLink} from "react-router-dom";
import {buildArticleQueryParams} from "../../../utils/article-utils";
import MarbreFilter from "../../commons/MarbreFilter";
import NumberOfPagesAutoComplete from "../commons/NumberOfPagesAutoComplete";

type Props = {
    filters: ArticleSearchFilters
}

const useStyles = makeStyles(() => ({
    paper: {
        borderBottom: "none"
    },
    init: {
        marginBottom: 10
    }
}))

export default function ArticleFilters({filters}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const updatePeriodic = (value: string[]) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ARTICLE,
            field: 'periodicalFilter',
            value
        }));
    }

    return (
        <>
            <Grid container alignItems="center" className={classes.init}>
                <Button onClick={() => dispatch(resetSearchQueryParams(SearchContext.ARTICLE))}
                        startIcon={<ClearIcon/>}>Réinitialiser</Button>

                {(filters.imageFilter && filters.imageFilter.length > 0) && <><Divider orientation="vertical" flexItem/>


                    <Button variant={"text"} component={RouterLink}
                            to={`/${t('routes.article.root')}?${buildArticleQueryParams({
                                ...filters,
                                imageFilter: undefined
                            })}`}
                            startIcon={<ClearIcon/>}>{filters.imageFilter.join(", ")}
                    </Button>
                </>}
            </Grid>


            <Paper variant="outlined" square className={classes.paper}>
                <List component="div" disablePadding>
                    <ListItem label={t('misc.entities.periodical')}>
                        <PeriodicalAutoComplete selectedOptions={filters.periodicalFilter}
                                                selectedAction={updatePeriodic} label={t('misc.entities.periodical')}/>
                    </ListItem>

                    <List component="div" disablePadding>
                        <ListItem label={t('articles.publication')}>
                            <ParutionFilter publicationIdFilter={filters.publicationIdFilter}
                                            periodicalFilter={filters.periodicalFilter}/>

                            <MarbreFilter marbreFilter={filters.marbre}/>
                        </ListItem>
                    </List>
                    <StatusArticleFilter statusFilter={filters.statusFilter}/>
                    <AttachedArticleFilter attachedFilter={filters.attachedFilter}/>
                    <NatureArticleFilter natureFilter={filters.natureFilter ?? []}/>

                    <ListItem label={t('publications.publication_date')}>
                        <DateRangePicker
                            end={filters.publicationDateFilter?.end}
                            start={filters.publicationDateFilter?.start}
                            onDatesChange={(values) => dispatch(updateSearchQueryParam({
                                ctx: SearchContext.ARTICLE,
                                field: "publicationDateFilter",
                                value: values
                            }))}
                            minDate={minDate}
                            maxDate={maxDate}
                        />

                    </ListItem>

                    <TypeArticleFilter typeFilter={filters.typeFilter ?? []}/>
                    <ListItem label={t('misc.plan_node')}>
                        <AsyncPlanNodesAutoComplete label={t('misc.plan_node')}
                                                    planNodeFilter={filters.planNodeFilter}/>
                    </ListItem>
                    <ListItem label={t('quickSearch.author')}>
                        <AsyncAuthorsAutoComplete label={t('quickSearch.author')}
                                                  authorsFilter={filters.authorFilter}/>
                    </ListItem>

                    <ListItem label={t('images.tags')}>
                        <NumberOfPagesAutoComplete label={t('articles.form.tags.label')}
                                                  selectedOptions={filters.numberOfPagesFilter}/>
                    </ListItem>

                </List>
            </Paper>
        </>
    )
}

import React, {PropsWithChildren} from "react";
import {ClickAwayListener, Grid, Grow, IconButton, Paper, Popper, Size, Tooltip} from "@material-ui/core";
import {ShowMoreIcon} from "./icon/icons";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    popper: {
        zIndex: 1
    }
}))

type Props = {
    size?: Size
} & PropsWithChildren<any>

export default function MoreActionsButton({children, size}: Props) {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const classes = useStyles()

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);
    const handleClose = () => setOpen(false);

    const showSelf = Array.isArray(children) ? children.some(it => it !== false) : children !== false
    return <>
        {showSelf && <>
            <Tooltip title={t('misc.more_actions') ?? "Plus d'actions"}>
                <IconButton onClick={handleToggle} ref={anchorRef} size={size}
                            style={size === "small" ? {padding: "10px 5px"} : undefined}>
                    <span><ShowMoreIcon/></span>
                </IconButton>
            </Tooltip>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                    className={classes.popper}>
                {({TransitionProps, placement}) => (
                    <Grow {...TransitionProps}
                          style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <Grid container direction={"column"} spacing={1} style={{padding: 4}}>
                                    {children}
                                </Grid>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>}
    </>
}

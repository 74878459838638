import React, {useCallback, useEffect} from "react";
import RouteLeavingGuard from "../../../RouteLeavingGuard/RouteLeavingGuard";
import PageHeader from "../../../commons/PageHeader";
import {ADMIN} from "../../../../utils/roles";
import {Grid} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {commonStyle} from "../../../../styles/common.style";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import BundleInfos from "./BundleInfos";
import BundleStructure from "./BundleStructure";
import {
    adminCustomBundlePlanReset,
    fetchAdminCustomBundle
} from '../../../../redux/actions/admin/bundle/admin-custom-bundle.actions';
import {currentAdminCustomBundlePlanDirtySelector} from "../../../../redux/selectors/admin/bundle/admin-custom-bundle.selectors";

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: "3%"
    }
}));

const BundleEditRoot = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {t} = useTranslation()
    const {id} = useParams() as { id: string }

    const dirty = useSelector(currentAdminCustomBundlePlanDirtySelector)

    const classes = commonStyle()
    const customClasses = useStyles()

    useEffect(() => {
        if (id) {
            dispatch(fetchAdminCustomBundle(id))
        }
    }, [dispatch, id]);

    const resetBundlePlan = useCallback(() => {
        dispatch(adminCustomBundlePlanReset());
    }, [dispatch])

    useEffect(() => {
        const unListener = history.listen(() => {
            resetBundlePlan();
            unListener();
        });
    }, [history, resetBundlePlan]);

    return (
        <Container maxWidth={"xl"} className={classes.root}>
            <RouteLeavingGuard
                when={dirty}
                shouldBlockNavigation={() => true}
                navigate={(location) => {
                    history.push(location);
                }}
            />

            <PageHeader
                label={t('admin.custom-bundle.edit.title', {bundleId: id})}
                requiredRoles={[ADMIN]}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} className={customClasses.root}>
                    <BundleInfos/>
                </Grid>

                <Grid item xs={12}>
                    <BundleStructure/>
                </Grid>
            </Grid>

        </Container>
    );
}

export default BundleEditRoot;

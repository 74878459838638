import React, { PropsWithChildren } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { isSaved, LinkAuthorArticles, PersistedAuthor } from "../../../types/author";
import DateTime from "../../commons/DateTime";
import { useSelector } from "react-redux";
import { State } from "../../../redux/reducers";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { truncateString } from "../../../utils/article-utils";


type Color = "inherit" | "initial" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error" | undefined
const Title = ({ children: text, color }: PropsWithChildren<{ color?: Color }>) => {
    const classes = useStyles()

    return (
        <Typography color={color} className={`${classes.title} ${!color ? classes.titleColor : undefined}`}>
            {text}
        </Typography>
    )
}

const IsEnable = ({ enable }: { enable?: boolean }) => (
    <Title color={enable ? undefined : 'primary'}>
        {enable ? 'Activé' : 'Désactivé'}
    </Title>
)

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20
    },
    delete: {
        marginTop: theme.spacing(2)
    },
    title: {
        fontWeight: 500,
        fontSize: '0.9rem',
    },
    titleColor: {
        color: 'rgba(0, 0, 0, 0.75)'
    },
    ulFirst: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        "& li": {
            marginBottom: 5
        },
        "& li:nth-child(2n)": {
            marginBottom: 10
        }
    },
    ulSecond: {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        "& li": {
            marginBottom: 5
        },
    },
    MuiDividerVertical: {
        width: 2
    },
    info: {
        marginLeft: 30
    }
}));

export default () => {
    const classes = useStyles();
    const { t } = useTranslation();

    const currentAuthor = useSelector(({ author }: State) => {
        return author.current && isSaved(author.current)
            ? author.current
            : ({
                id: '',
                knkId: '',
                enable: false,
                creationDate: '',
                creationUser: '',
                modificationDate: '',
                modificationUser: '',
                lastArticlesModified: [] as LinkAuthorArticles[]
            });
    });

    const {
        id,
        knkId,
        enable,
        creationDate,
        creationUser,
        modificationDate,
        modificationUser,
        lastArticlesModified,
    } = currentAuthor

    return (
        <Grid container className={classes.root}>
            <Divider orientation="vertical" flexItem className={classes.MuiDividerVertical} />
            <Grid item xs={8} className={classes.info}>
                <Grid container>
                    <ul className={classes.ulFirst}>
                        <li><IsEnable enable={enable} /></li>
                        <Title>Identifiants</Title>
                        <li>{id}</li>
                        <li>{knkId}</li>
                        <Title>Créé le</Title>
                        <li>{creationDate ? <DateTime date={creationDate} onlyDate/> : '-'}</li>
                        <Title>Dernière modification le</Title>
                        <li>{modificationDate ? <DateTime date={modificationDate} /> : '-'}</li>
                    </ul>
                    <Grid container>
                        <ul className={classes.ulSecond}>
                            <Typography className={classes.title}>{t('misc.appears_in')}</Typography>
                            {
                                (lastArticlesModified && lastArticlesModified.length > 0) ? lastArticlesModified.map(x =>
                                    <li><Link
                                        to={`/${x.type === "BUNDLE" ? t('routes.bundle.root') : t('routes.article.root')}/${x.articleId}`}
                                        component={RouterLink}
                                        style={{ color: "black" }}
                                    >{x.articleId}{x.title !== '' ? '_' + truncateString(x.title, 40) : ''}</Link>
                                    </li>) : ' - '
                            }
                        </ul>
                    </Grid>

                    {(lastArticlesModified && lastArticlesModified.length > 0) &&
                        <Grid container className={classes.root}>
                            <Button variant={"outlined"} component={RouterLink}
                                to={`/${t('routes.article.root')}?authorFilter=${id}`}
                                title={t('misc.see_all') + '...'}>{t('misc.see_all') + '...'}</Button>
                        </Grid>}
                </Grid>
            </Grid>
        </Grid>
    )
}

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {currentAdminCustomBundlePlanSelector} from "../../../../redux/selectors/admin/bundle/admin-custom-bundle.selectors";
import useAsyncState from "../../../commons/hooks/useAsyncState";
import {PlanNode} from "../../../../types/plan-node";
import {useTranslation} from "react-i18next";
import {
    saveAdminCustomBundle,
    setDirty,
    setPlan
} from "../../../../redux/actions/admin/bundle/admin-custom-bundle.actions";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TreeViewer from "../../../commons/tree/TreeViewer";
import {useHistory} from "react-router-dom";

const removeNulls = (plan: PlanNode[]) => plan.map(node => node.sub === null ? {...node, sub: []} : {
    ...node,
    sub: removeNulls(node.sub)
})

const useStyles = makeStyles(() => ({
    tree: {
        backgroundColor: "rgba(0,0,0,0.1)",
        borderRadius: "5px",
        minHeight: "50vh"
    },
    topTitle: {
        fontWeight: 200
    },
}));

const BundleStructure = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [nodes, setNodes] = useAsyncState([] as PlanNode[])
    const [isLoaded, setIsLoaded] = useAsyncState(false)
    const customClasses = useStyles();

    const customBundlePlan = useSelector(currentAdminCustomBundlePlanSelector);

    useEffect(() => {
        if (customBundlePlan?.plan && !isLoaded) {
            setNodes(removeNulls(customBundlePlan.plan)).then(() => setIsLoaded(true));
        }
    }, [customBundlePlan, isLoaded, setIsLoaded, setNodes]);

    useEffect(() => {
        if (isLoaded) {
            dispatch(setPlan(nodes));
        }
    }, [dispatch, isLoaded, nodes]);

    const cancel = () => {
        new Promise<void>((resolve) => {
            dispatch(setDirty(true));
            resolve();
        }).then(() => history.push(`/`));
    }

    const save = () => {
        if (customBundlePlan) {
            dispatch(saveAdminCustomBundle(customBundlePlan));
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={customClasses.topTitle} variant={"h5"}
                                component={"h1"}>{t('admin.custom-bundle.structure.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <hr/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container item justify={"flex-end"} direction={"row"} spacing={2}>
                        <Grid item>
                            <Button variant="outlined" color="primary" onClick={cancel}>
                                {t('misc.cancel')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={"contained"} color={"primary"} onClick={save}>
                                {t('misc.save')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TreeViewer
                        setDirty={setDirty}
                        expandAll={true}
                        detachLabel={''}
                        maxDepth={5}
                        preventSiblings={true}
                        edit={true}
                        canAddContainerNodes={true}
                        nodes={nodes}
                        setNodes={setNodes}
                        displayDirection={"row"}
                        treeStyle={{height: '55vh', overflowY: 'auto'}}
                        treeAdderInputLabel={"Titre"}
                        treeAdderButtonLabel={"Ajouter"}
                        customClasses={customClasses}
                        isBundleStructureEdition={true}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default BundleStructure;

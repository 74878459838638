import React, {useEffect, useMemo, useState} from 'react';
import {Button, Container, Grid, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {commonStyle} from "../../../styles/common.style";
import Auth from "../../Auth";
import {ADMIN, MANAGER} from "../../../utils/roles";
import PageHeader from "../../commons/PageHeader";
import {useTranslation} from "react-i18next";
import PeriodicalSingleSelect from "../../commons/PeriodicalSingleSelect";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import DateRangePicker from "../../commons/DateRangePicker";
import {maxDate, minDate} from "../../../utils/common-utils";
import {useDispatch, useSelector} from "react-redux";
import {
    exportAuthors,
    exportPublishedElements,
    exportReferences,
    initExportStatus
} from "../../../redux/actions/admin/export/admin-export.actions"
import FormHelperText from "@material-ui/core/FormHelperText";
import {
    currentAdminExportAuthorStatus,
    currentAdminExportPublishedElementStatus,
    currentAdminExportReferentialStatus
} from "../../../redux/selectors/admin/export/admin-export.selectors"
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import {green, red} from '@material-ui/core/colors';
import {ExportIcon} from "../../../icon";

const useStyles = makeStyles(() => ({
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonError: {
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    divider: {
        marginTop: 15,
        marginBottom: 15
    }
}))


const GlobalExportRoot = () => {
    const commonClasses = commonStyle();
    const classes = useStyles();
    const {t} = useTranslation();

    const authorStatus = useSelector(currentAdminExportAuthorStatus);
    const referentialStatus = useSelector(currentAdminExportReferentialStatus);
    const publishedStatus = useSelector(currentAdminExportPublishedElementStatus);

    const [periodicalId, setPeriodicalId] = useState<string>()
    const [startDate, setStartDate] = useState<string>()
    const [endDate, setEndDate] = useState<string>()

    const buttonClassname = useMemo(() => clsx({
        [classes.buttonSuccess]: false, //status === 'LOADED'
        [classes.buttonError]: false //status === 'ERROR'
    }), [])

    const [errors, setErrors] = useState<string[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(initExportStatus())
    }, [dispatch])

    const verifyFormDatas = () => {
        let error: string[] = [];
        if (periodicalId === undefined) {
            error.push(
                "periodicalId")
        }
        if (startDate === undefined) {
            error.push(
                "startDate")
        }
        if (endDate === undefined) {
            error.push(
                "endDate")
        }

        if (startDate && endDate) {
            const diffTime = Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays > 60) {
                error.push(
                    "between_dates")
            }
        }

        setErrors(error)
        return error
    }

    const exportAction = (action: any) => {
        if (verifyFormDatas().length === 0)
            dispatch(action({
                periodicalId: periodicalId!,
                startDate: startDate!,
                endDate: endDate!
            }))
    }


    return (
        <Auth requiredRoles={[ADMIN, MANAGER]}>
            <Container maxWidth={"lg"} className={commonClasses.root}>
                <PageHeader label={t('admin.export.title')} requiredRoles={[ADMIN]}/>
                <Grid container spacing={4}>
                    <Grid item>
                        <PeriodicalSingleSelect selectedOption={periodicalId}
                                                selectedAction={(e) => {
                                                    setErrors(errors.filter((x) => x !== "periodicalId"))
                                                    setPeriodicalId(e)
                                                }}
                                                label={t('admin.periodical.form.periodicalId.label') + " *"}
                                                error={errors.includes('periodicalId')}/>

                        <Grid container spacing={2} style={{marginTop: 20}}>
                            <Grid item>
                                <Typography variant="subtitle2" style={{marginTop: 20}}>
                                    {t('admin.export.form.dates')} *
                                </Typography>
                            </Grid>
                            <Grid item>
                                <DateRangePicker
                                    start={startDate}
                                    end={endDate}
                                    onDatesChange={(values) => {
                                        setStartDate(values.start)
                                        setEndDate(values.end)
                                        if (values.start) {
                                            setErrors(errors.filter((x) => x !== "startDate"))
                                        }
                                        if (values.end) {
                                            setErrors(errors.filter((x) => x !== "endDate"))
                                        }
                                    }}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />
                                {errors.includes('startDate') && <FormHelperText error={true}>
                                    La date de début est obligatoire</FormHelperText>}
                                {errors.includes('endDate') && <FormHelperText error={true}>
                                    La date de fin est obligatoire</FormHelperText>}
                                {errors.includes('between_dates') && <FormHelperText error={true}>
                                    La différence entre les dates doit être de 60 jours maximums</FormHelperText>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {t('admin.export.specific_author')}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="outlined" disabled={authorStatus === "PENDING"}
                                                className={buttonClassname} startIcon={<ExportIcon/>}
                                                onClick={() =>
                                                    exportAction(exportAuthors)
                                                }>
                                            {t('misc.export')} {authorStatus === "PENDING" &&
                                        <CircularProgress size={24} className={classes.buttonProgress}/>}
                                        </Button>
                                    </TableCell>
                                </TableRow>

                                <Auth requiredRoles={["ADMIN"]}>
                                    <TableRow>
                                        <TableCell>

                                            {t('admin.export.article_with_invalid_reference')}
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="outlined" disabled={referentialStatus === "PENDING"}
                                                    className={buttonClassname}
                                                    startIcon={<ExportIcon/>}
                                                    onClick={() =>
                                                        exportAction(exportReferences)
                                                    }>
                                                {t('misc.export')} {referentialStatus === "PENDING" &&
                                            <CircularProgress size={24} className={classes.buttonProgress}/>}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>

                                            {t('admin.export.published_elements')}
                                        </TableCell>
                                        <TableCell>
                                            <Button variant="outlined" disabled={publishedStatus === "PENDING"}
                                                    className={buttonClassname}
                                                    startIcon={<ExportIcon/>}
                                                    onClick={
                                                        () => exportAction(exportPublishedElements)
                                                    }>
                                                {t('misc.export')} {publishedStatus === "PENDING" &&
                                            <CircularProgress size={24} className={classes.buttonProgress}/>}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </Auth>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Container>
        </Auth>
    );
};

export default GlobalExportRoot;

import {createActionWithoutPayload, createActionWithPayload} from "./utils.actions";
import {Filters, PublicationSearchResult} from "../../types/common-search";
import {EditionType, Publication, PublicationStatus} from "../../types/publication";
import {PlanNode} from "../../types/plan-node";
import {IllustrationImage} from "../../types/image";
import {SelectOption} from "../../components/commons/forms/LexFormField";
import {Article} from "../../types/article";

export const [savePublication, isSavePublicationAction] = createActionWithoutPayload('SAVE_PUBLICATION');
export const [savePublicationSuccess, isSavePublicationSuccessAction] = createActionWithoutPayload('SAVE_PUBLICATION_SUCCESS');
export const [savePublicationError, isSavePublicationErrorAction] = createActionWithoutPayload('SAVE_PUBLICATION_ERROR');

export const [savePublicationPlan, isSavePublicationPlanAction] = createActionWithPayload<{ plan: PlanNode[], publicationId: string, filters?: Filters }>('SAVE_PUBLICATION_PLAN');
export const [savePublicationPlanSuccess, isSavePublicationPlanSuccessAction] = createActionWithoutPayload('SAVE_PUBLICATION_PLAN_SUCCESS');
export const [savePublicationPlanError, isSavePublicationPlanErrorAction] = createActionWithoutPayload('SAVE_PUBLICATION_PLAN_ERROR');

export const [fetchPublication, isFetchPublicationAction] = createActionWithPayload<string>('FETCH_PUBLICATION');
export const [fetchPublicationsOfPeriodical, isFetchPublicationsOfPeriodicalAction] = createActionWithPayload<string>('FETCH_PUBLICATIONS_OF_PERIODICAL');
export const [resetPublication, isResetPublicationAction] = createActionWithoutPayload('RESET_PUBLICATION');
export const [fetchPublicationSuccess, isFetchPublicationSuccessAction] = createActionWithPayload<Publication>('FETCH_PUBLICATION_SUCCESS');
export const [fetchImageOfPublication, isFetchImageOfPublicationAction] = createActionWithPayload<IllustrationImage>('FETCH_IMAGE_OF_PUBLICATION');
export const [fetchArticleOfPublication, isFetchArticleOfPublicationAction] = createActionWithPayload<Article>('FETCH_ARTICLE_OF_PUBLICATION');
export const [fetchPublicationError, isFetchPublicationErrorAction] = createActionWithoutPayload('FETCH_PUBLICATION_ERROR');

export const [setDirtyAction, isSetDirtyAction] = createActionWithPayload<boolean>('SET_DIRTY_ORGANIZE');


export const [fetchPublicationPlan, isFetchPublicationPlanAction] = createActionWithPayload<string>('FETCH_PUBLICATION_PLAN');
export const [fetchPublicationPlanSuccess, isFetchPublicationPlanSuccessAction] = createActionWithPayload<PlanNode[]>('FETCH_PUBLICATION_PLAN_SUCCESS');
export const [fetchPublicationPlanError, isFetchPublicationPlanErrorAction] = createActionWithoutPayload('FETCH_PUBLICATION_PLAN_ERROR');

export const [searchPublications, isSearchPublicationsAction] = createActionWithPayload<any>('SEARCH_PUBLICATIONS');
export const [searchPublicationsSuccess, isSearchPublicationsSuccessAction] = createActionWithPayload<PublicationSearchResult[]>('SEARCH_PUBLICATIONS_SUCCESS');
export const [searchPublicationsError, isSearchPublicationsErrorAction] = createActionWithoutPayload('SEARCH_PUBLICATIONS_ERROR');

export const [deletePublication, isDeletePublicationAction] = createActionWithPayload<string>('DELETE_PUBLICATION');
export const [deletePublicationSuccess, isDeletePublicationSuccessAction] = createActionWithoutPayload('DELETE_PUBLICATION_SUCCESS');
export const [deletePublicationError, isDeletePublicationErrorAction] = createActionWithoutPayload('DELETE_PUBLICATION_ERROR');

export const [deleteManyPublications, isDeleteManyPublicationsAction] = createActionWithPayload<string[]>('DELETE_MANY_PUBLICATIONS');
export const [deleteManyPublicationsSuccess, isDeleteManyPublicationsSuccessAction] = createActionWithoutPayload('DELETE_MANY_PUBLICATIONS_SUCCESS');
export const [deleteManyPublicationsError, isDeleteManyPublicationsErrorAction] = createActionWithoutPayload('DELETE_MANY_PUBLICATIONS_ERROR');

export const [selectPublications, isSelectPublications] = createActionWithPayload<string[]>('PUBLICATIONS_SET_SELECTION');
export const [addPublicationSelection, isPublicationdPublicationSelection] = createActionWithPayload<string[]>('PUBLICATIONS_PUBLICATIOND_SELECTION');
export const [removePublicationSelection, isRemovePublicationSelection] = createActionWithPayload<string[]>('PUBLICATIONS_REMOVE_SELECTION');
export const [clearPublicationsSelection, isClearPublicationsSelection] = createActionWithoutPayload('CLEAR_PUBLICATIONS_SELECTION');

export const [openNewPublicationDialog, isOpenNewPublicationDialogAction] = createActionWithoutPayload('OPEN_NEW_PUBLICATION_DIALOG');
export const [closeNewPublicationDialog, isCloseNewPublicationDialogAction] = createActionWithoutPayload('CLOSE_NEW_PUBLICATION_DIALOG');
export const [generateNewPublication, isGenerateNewPublicationAction] = createActionWithPayload<{ periodicalId?: string, date: string, year: number }>('GENERATE_NEW_PUBLICATION');

// EDIT
export const [openEditPublicationDialog, isOpenEditPublicationDialogAction] = createActionWithPayload<number>('OPEN_EDIT_PUBLICATION_DIALOG');
export const [setEditPublicationId, isSetEditPublicationId] = createActionWithPayload<number>('SET_EDIT_PUBLICATION_ID');

export const [setPublicationFormPeriodicalId, isSetPublicationFormPeriodicalId] = createActionWithPayload<string>('SET_PUBLICATION_PERIODICAL_ID')
export const [setPublicationFormTitle, isSetPublicationFormTitle] = createActionWithPayload<number>('SET_PUBLICATION_TITLE')
export const [setPublicationFormYear, isSetPublicationFormYear] = createActionWithPayload<number>('SET_PUBLICATION_YEAR')
export const [setPublicationFormNumber, isSetPublicationFormNumber] = createActionWithPayload<string>('SET_PUBLICATION_NUMBER')
export const [setPublicationFormEditionType, isSetPublicationFormEditionType] = createActionWithPayload<EditionType>('SET_PUBLICATION_EDITION_TYPE')
export const [setPublicationFormStatus, isSetPublicationFormStatus] = createActionWithPayload<PublicationStatus>('SET_PUBLICATION_STATUS')
export const [setPublicationFormStartDate, isSetPublicationFormStartDate] = createActionWithPayload<string>('SET_PUBLICATION_START_DATE')
export const [setPublicationFormEndDate, isSetPublicationFormEndDate] = createActionWithPayload<string>('SET_PUBLICATION_END_DATE')

// EDIT
export const [setPublicationFormArticleOnCover, isSetPublicationFormArticleOnCover] = createActionWithPayload<string>('SET_PUBLICATION_ARTICLE_ON_COVER')
export const [setPublicationFormImageOnCover, isSetPublicationFormImageOnCover] = createActionWithPayload<SelectOption>('SET_PUBLICATION_IMAGE_ON_COVER')
export const [setPublicationFormTextOnCover, isSetPublicationFormTextOnCover] = createActionWithPayload<string>('SET_PUBLICATION_TEXT_ON_COVER')


export const [createPublication, isCreatePublicationAction] = createActionWithoutPayload('CREATE_PUBLICATION')
export const [createPublicationSuccess, isCreatePublicationSuccessAction] = createActionWithoutPayload('CREATE_PUBLICATION_SUCCESS');
export const [createPublicationError, isCreatePublicationErrorAction] = createActionWithoutPayload('CREATE_PUBLICATION_ERROR');

export const [editPublication, isEditPublicationAction] = createActionWithPayload<number>('EDIT_PUBLICATION')
export const [editPublicationSuccess, isEditPublicationSuccessAction] = createActionWithPayload<number>('EDIT_PUBLICATION_SUCCESS');
export const [editPublicationError, isEditPublicationErrorAction] = createActionWithPayload<number>('EDIT_PUBLICATION_ERROR');

export const [uploadPublications, isUploadPublicationsAction] = createActionWithPayload<FileList>('UPLOAD_PUBLICATIONS')

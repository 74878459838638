import React, {useEffect, useMemo} from 'react';
import {Container, Grid} from "@material-ui/core";
import {commonStyle} from "../../../../styles/common.style";
import Auth from "../../../Auth";
import {ADMIN} from "../../../../utils/roles";
import PublicationPlanAdministrateStructure from "./PublicationPlanAdministrateStructure";
import PageHeader from "../../../commons/PageHeader";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {currentAdminSelectedPublicationAdminDirtySelector,} from "../../../../redux/selectors/admin/periodical/admin-publication.selectors";
import RouteLeavingGuard from "../../../RouteLeavingGuard/RouteLeavingGuard";
import {useHistory, useParams} from "react-router-dom";
import {getCurrentPublication} from "../../../../redux/selectors/publication.selectors";
import {fetchPublication} from "../../../../redux/actions/publication.actions";

const PublicationPlanAdministrateRoot = () => {
    const commonClasses = commonStyle();
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const publication = useSelector(getCurrentPublication);
    const {id: publicationId} = useParams() as unknown as { id: number }

    const isDirty = useSelector(currentAdminSelectedPublicationAdminDirtySelector);

    useEffect(() => {
        if (!publication || publication?.id !== publicationId) {
            dispatch(fetchPublication('' + publicationId))
        }
    }, [publicationId])

    const publicationName = useMemo(() =>
        publication ? `${publication.periodicalId}_${publication.year}_${publication.number}` : '', [publication])

    return (
        <Auth requiredRoles={[ADMIN]}>
            <RouteLeavingGuard
                when={isDirty}
                shouldBlockNavigation={() => true}
                navigate={(location) => history.push(location)}
            />
            <Container maxWidth={"xl"} className={commonClasses.root}>
                <PageHeader label={t('admin.administrate_publication_plan.title', {publicationName})}
                            requiredRoles={[ADMIN]}/>
                <Grid container spacing={2} direction={"column"}>
                    <Grid item>
                        <PublicationPlanAdministrateStructure/>
                    </Grid>
                </Grid>
            </Container>
        </Auth>
    );
};

export default PublicationPlanAdministrateRoot;

import React, { CSSProperties, Children, useContext } from "react"
import { KyrDateRangePicker } from "../../../components/KyrDateRangePicker"
import { useHandlePlanNodeChange, useHandleSearchChange } from "../../../hooks/searchHooks"
import { SearchContext } from "../../../contexts/organizeTreeBibleContexts"
import { DocumentNatureEnum, displayDocumentNatureEnum, setDocumentNatureFromString, setPeriodicalFilter, setPlanNodeFilter, setPublicationDateFilterFromString } from "../../../models/Search"
import { KyrTextField } from "../../../components/KyrTextField"
import { KyrCheckbox } from "../../../components/KyrCheckBox"
import { Periodical } from "../../../../types/periodical"
import { Autocomplete } from "@material-ui/lab"
import { useFetchDataOnMount } from "../../../hooks/useFetchDataOnMount"
import { Box, Chip, CircularProgress, FormControlLabel, Tooltip } from "@material-ui/core"
import { PublicationPlan } from "../../../models/publicationPlan"
import { Pageable } from "../../../models/Pageable"
import { filterBoxPadding } from "./Header"
import { ChildrenProps } from "../../../types"
import { rowGap } from "./AdvancedFIlters"
import { TANK_FILTERS_FONT_SIZE } from "../../../elementsSize"

export const NatureCheckBoxes = ({ natures }: { natures?: DocumentNatureEnum[] }) => {
  const [searchState] = useContext(SearchContext)

  const handleChange = useHandleSearchChange()

  const values = natures === undefined ? Object.values(DocumentNatureEnum) : natures

  return (
    <>
      {values.map((value) => (
        <FormControlLabel
          key={value}
          control={
            <KyrCheckbox
              checked={searchState.nature.includes(value as DocumentNatureEnum)}
              onChange={({ target: { checked } }) => {
                const updatedSearch = setDocumentNatureFromString(checked, value)(searchState)
                handleChange(updatedSearch)
              }}
              value={value}
            />
          }
          label={<span style={{fontSize: TANK_FILTERS_FONT_SIZE}}>{displayDocumentNatureEnum(value as DocumentNatureEnum)}</span>}
        />
      ))}
    </>
  )
}

const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)

  return (
    <Box id="advanced-filters" style={{ display: 'flex', flexDirection: 'column', rowGap }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', columnGap: 20, ...filterBoxPadding }}>
        <Box style={{width: '50%'}}>
          {childrenList[0]}
        </Box>
        <Box style={{width: '50%'}}>
          {childrenList[1]}
        </Box>
      </Box>
      <Box style={{ display: 'flex', justifyContent: 'space-between', ...filterBoxPadding }}>
        {childrenList[2]}
        {childrenList[3]}
      </Box>
    </Box>
  )
}

const PeridicalsId = [
  'AJU',
  'BJB',
  'BJE',
  'BJS',
  'BJT',
  'DAA',
  'DAS',
  'DBA',
  'DCO',
  'DDC',
  'DED',
  'DEF',
  'DFF',
  'DFP',
  'DIU',
  'DPI',
  'GDA',
  'GPL',
  'LPA',
  'RDC',
  'RDP',
  'RGA',
] as const

export type PeriodicalId = typeof PeridicalsId[number]

const buildParameters = (
  query: string,
  includePeriodical?: PeriodicalId,
  excludePeriodical?: PeriodicalId,
) => ({ page: 0, size: 20, query, includePeriodical, excludePeriodical })

export const PlanNodeAutoComplete = ({ style, includePeriodical, excludePeriodical }:
  { style?: CSSProperties, includePeriodical?: PeriodicalId, excludePeriodical?: PeriodicalId }) => {
  const [searchState] = useContext(SearchContext)
  const [rubriques, setRubriques] = useFetchDataOnMount<Pageable<PublicationPlan>>({
    url: '/api/publication_plan/search',
    method: 'post',
    body: buildParameters('', includePeriodical, excludePeriodical)
  })

  const handleChange = useHandleSearchChange()
  const handlePlanNodeChange = useHandlePlanNodeChange(setRubriques)

  const displayLabel = (plan: PublicationPlan) => {
    if (!plan.parentName || plan.parentName?.trim().length === 0) {
      return `[${plan.periodicalId}] ${plan.name}`
    }

    return `[${plan.periodicalId}] ${plan.parentName} (${plan.name})`
  }
  return (
    <Autocomplete<PublicationPlan, true>
      style={style}
      limitTags={1}
      options={(rubriques.data as any)?.result ?? []}
      onChange={(_, value) => {
        handleChange(setPlanNodeFilter(value.map(e => e.id))(searchState))
      }}
      onInputChange={(_, inputTextValue) => {
        handlePlanNodeChange(buildParameters(inputTextValue, includePeriodical), true)
      }}
      multiple
      getOptionLabel={(option) => option.name}
      renderOption={(option, { selected }) => (
        <>
          <KyrCheckbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {displayLabel(option)}
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip title={option.name} key={index}>
            <Chip label={option.name} {...getTagProps({ index })} />
          </Tooltip>
        ))
      }
      filterOptions={() => (rubriques.data as any)?.result ?? []}
      renderInput={(params) => (
        <KyrTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {rubriques.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label="Rubrique"
        />
      )}
    />
  )
}

const PeriodicalAutoComplete = () => {
  const [searchState] = useContext(SearchContext)
  const [periodical] = useFetchDataOnMount<Periodical[]>({
    url: '/api/periodical',
    method: 'get',
  })

  const handleChange = useHandleSearchChange()

  return (
    <Autocomplete<Periodical, true>
      style={{ flexGrow: 1 }}
      limitTags={1}
      options={periodical.data ?? []}
      onChange={(_, value) => {
        handleChange(setPeriodicalFilter(value.map(e => e.id))(searchState))
      }}
      multiple
      getOptionLabel={(option) => option.id}
      renderOption={(option, { selected }) => (
        <>
          <KyrCheckbox
            style={{ marginRight: 8}}
            checked={selected}
          />
          [{option.id}] {option.label}
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip title={ `[${option.id}] ${option.label}`} key={index}>
            <Chip label={option.id} {...getTagProps({ index })} />
          </Tooltip>
        ))
      }
      renderInput={(params) => (
        <KyrTextField
          {...params}
          label="Revue"
        />
      )}
    />
  )
}

const PublicationDateFilter = () => {
  const minDate = "2000-01-01"
  const maxDate = "2030-01-01"

  const handleChange = useHandleSearchChange()
  const [searchState] = useContext(SearchContext)

  return (
    <KyrDateRangePicker
      style={{ width: '70%', marginBottom: 20, color: 'purple' }}
      maxDate={maxDate}
      minDate={minDate}
      start={searchState.publicationDateFilter?.start?.toString()}
      end={searchState.publicationDateFilter?.end?.toString()}
      onDatesChange={(value) => {
        handleChange(setPublicationDateFilterFromString(value)(searchState))
      }}
    />
  )
}

export const LexPressAdvancedFilters = () => {

  return (
    <Layout>
      <PeriodicalAutoComplete />
      <PlanNodeAutoComplete excludePeriodical="GDA" style={{ flexGrow: 1 }} />
      <PublicationDateFilter />
      <NatureCheckBoxes natures={[DocumentNatureEnum.LOT]}/>
    </Layout>
  )
}
import {Action} from "../actions/utils.actions";
import {
    isFetchImageSuccessAction,
    isGenerateNewImageAction,
    isRemoveImageTagAction,
    isReplaceIllustrationAction,
    isReplaceIllustrationErrorAction,
    isReplaceIllustrationSuccessAction,
    isSaveIllustrationSuccessAction,
    isSetImageColorAction,
    isSetImageCreditsAction,
    isSetImageDescriptionAction,
    isSetImageFormatAction,
    isSetImageNameAction,
    isSetImageQualityAction,
    isSetImageSourcesAction,
    isSetImageTagsAction,
    isToggleIllustrationDisabledSuccessAction,
    isUploadIllustrationAction,
    isUploadIllustrationErrorAction,
    isUploadIllustrationSuccessAction
} from "../actions/image.actions";
import {IllustrationImage, SavedImage} from "../../types/image";

type ImageState = {
    currentSelection: string[],
    form: {
        isDirty: boolean,
        currentIllustration: IllustrationImage
    },
    uploading: boolean,
    uploadN: number,
    currentUploadedImage?: SavedImage
    lastUploadedImage?: SavedImage
}

const emptyIllustration = (): IllustrationImage => ({
    id: '',
    name: '',
    comments: '',
    credits: '',
    sources: '',
    tags: [],
    enable: true,
    disabled: false,
    version: 0
})

const init: ImageState = {
    currentSelection: [],
    form: {
        isDirty: false,
        currentIllustration: emptyIllustration(),
    },
    uploading: false,
    uploadN: 0
}

const reducer = (state = init, action: Action): ImageState => {
    if (isUploadIllustrationAction(action)) {
        return {...state, uploading: true}
    } else if (isToggleIllustrationDisabledSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    disabled: action.payload,
                    enable: !action.payload
                }
            }
        }
    } else if (isSaveIllustrationSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                isDirty: false
            }
        }
    } else if (isReplaceIllustrationAction(action)) {
        return {
            ...state,
            uploading: true,
            currentUploadedImage: undefined
        }
    } else if (isReplaceIllustrationSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    id: action.payload.id,
                    meta: action.payload.meta,
                    format: action.payload.format
                }
            },
            uploadN: state.uploadN + 1,
            lastUploadedImage: {
                format: action.payload.format,
                meta: action.payload.meta,
                id: action.payload.id,
                version: action.payload.version
            },
            currentUploadedImage: {
                format: action.payload.format,
                meta: action.payload.meta,
                id: action.payload.id,
                version: action.payload.version
            },
            uploading: false
        }
    } else if (isUploadIllustrationSuccessAction(action)) {
        return {
            ...state,
            uploading: false,
            currentUploadedImage: action.payload,
            lastUploadedImage: action.payload,
            form: {
                ...state.form,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    id: action.payload.id,
                    meta: action.payload.meta,
                    format: action.payload.format
                }
            },
            uploadN: state.uploadN + 1
        }
    } else if (isReplaceIllustrationErrorAction(action)) {
        return {
            ...state,
            currentUploadedImage: state.lastUploadedImage ? {
                ...state.lastUploadedImage
            } : undefined,
            uploading: false
        }
    } else if (isUploadIllustrationErrorAction(action)) {
        return {
            ...state, uploading: false, currentUploadedImage: state.lastUploadedImage ? {
                ...state.lastUploadedImage
            } : undefined
        }
    } else if (isFetchImageSuccessAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                isDirty: false,
                currentIllustration: action.payload
            }
        }
    } else if (isGenerateNewImageAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: false,
                currentIllustration: emptyIllustration()
            },
            currentUploadedImage: undefined,
            lastUploadedImage: undefined,
            uploading: false
        }
    } else if (isSetImageNameAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    name: action.payload
                }
            }
        }
    } else if (isSetImageFormatAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    format: action.payload
                }
            }
        }
    } else if (isSetImageColorAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    color: action.payload
                }
            }
        }
    } else if (isSetImageQualityAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    quality: action.payload
                }
            }
        }
    } else if (isSetImageCreditsAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    credits: action.payload
                }
            }
        }
    } else if (isSetImageSourcesAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    sources: action.payload
                }
            }
        }
    } else if (isSetImageDescriptionAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    comments: action.payload
                }
            }
        }
    } else if (isSetImageTagsAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    tags: action.payload
                }
            }
        }
    } else if (isRemoveImageTagAction(action)) {
        return {
            ...state, form: {
                ...state.form,
                isDirty: true,
                currentIllustration: {
                    ...state.form.currentIllustration,
                    tags: state.form.currentIllustration.tags.filter((_, idx) => idx !== action.payload)
                }
            }
        }
    } else {
        return state;
    }
}

export default reducer

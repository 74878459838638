import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import CheckboxForFilters from "./CheckboxForFilters";
import {SearchContext} from "../../../types/common-search";

const ColorFilter = ({characteristicFilter, addElementAction, removeElementAction}) => {
    const {t} = useTranslation();

    return (
        <ListItem label={t('images.color')}>
            <CheckboxForFilters ctx={SearchContext.ADVERTISEMENT} addElementAction={addElementAction}
                                removeElementAction={removeElementAction}
                                field={"characteristicFilter"} filters={characteristicFilter}
                                label={t('enums.images.characteristics.COLOR')}
                                value={"COLOR"}/>

            <CheckboxForFilters ctx={SearchContext.ADVERTISEMENT} addElementAction={addElementAction}
                                removeElementAction={removeElementAction}
                                field={"characteristicFilter"} filters={characteristicFilter}
                                label={t('enums.images.characteristics.BLACK_AND_WHITE')}
                                value={"BLACK_AND_WHITE"}/>

            <CheckboxForFilters ctx={SearchContext.ADVERTISEMENT} addElementAction={addElementAction}
                                removeElementAction={removeElementAction}
                                field={"characteristicFilter"} filters={characteristicFilter}
                                label={t('enums.images.characteristics.N_AND_ONE_COLOR')}
                                value={"N_AND_ONE_COLOR"}/>
        </ListItem>
    )
}

export default ColorFilter

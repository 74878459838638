import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import {addSearchQueryParam, removeSearchQueryParam} from "../../../redux/actions/common-search.actions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {useDispatch} from "react-redux";
import {complexType, simpleType} from "../../../types/article";
import MemoCheckbox from "../../commons/MemoCheckbox";
import {SearchContext} from "../../../types/common-search";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";

type Props = {
    typeFilter: string[]
}

const useStyles = makeStyles(() => ({
    root: {
        marginTop: 10,
        marginLeft: 0
    }
}))


export default ({typeFilter}: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();


    const handleChange = (value: string) => (event, check) => {
        if (check) {
            dispatch(addSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'typeFilter',
                value: value
            }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.ARTICLE,
                field: 'typeFilter',
                value: value
            }))
        }
    }

    return (
        <ListItem label={t('articles.article_type')}>
            <Grid container spacing={1} className={classes.root}>
                {//(natureFilter.length === 0 || natureFilter.includes(ArticleNature.SIMPLE)) &&
                    simpleType.map((x, index) => (
                            <Grid item key={'type_' + index}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<MemoCheckbox
                                            onChange={handleChange(x)}
                                            checked={typeFilter.some((e) => e === x)}
                                        />}
                                        label={t('enums.articles.type.' + x + '.main')}
                                    />
                                </FormGroup>
                            </Grid>
                        )
                    )
                }
                {//(natureFilter.length === 0 || natureFilter.includes(ArticleNature.COMPLEXE)) &&
                    complexType.map((x, index) => (
                            <Grid item key={'complex_type_' + index}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<MemoCheckbox
                                            onChange={handleChange(x)}
                                            checked={typeFilter.some((e) => e === x)}
                                        />}
                                        label={t('enums.articles.type.' + x + '.main')}
                                    />
                                </FormGroup>
                            </Grid>
                        )
                    )
                }
            </Grid>
        </ListItem>
    )
}

import {Action} from "../../../actions/utils.actions";
import {
    isDeleteAdminBundlePlanSuccess,
    isFetchAdminBundlePlansSuccess,
    isFetchAdminBundlePlanSuccess,
    isResetAdminBundlePlan,
    isSaveAdminBundlePlanSuccess,
    isSetAdminBundlePlanFormMode,
    isSetAdminBundlePlansSearchContext,
    isSetDirty,
    isSetPeriodicalId,
    isSetPlan,
    isSetTitle,
    isSetType
} from "../../../actions/admin/bundle/admin-bundle.actions";
import {
    BundlePlanFormMode,
    BundlePlanResponse,
    BundlePlanSearchContext,
    BundlePlanSearchItem,
    BundlePlanSearchResponse
} from "../../../../types/bundle";
import {PlanNode} from "../../../../types/plan-node";

const setAllPlanNodeTypeToContainer = (plan: PlanNode[]) => {
    return plan.map((planNode: PlanNode) => {
        if (planNode.sub) {
            planNode.sub = setAllPlanNodeTypeToContainer(planNode.sub)
        }

        return {...planNode, type: 'CONTAINER'} as PlanNode
    });
}

export type AdminBundleState = {
    adminBundlePlansSearchContext: BundlePlanSearchContext
    adminBundleSearchResult?: BundlePlanSearchResponse
    adminBundlePlans?: BundlePlanSearchItem[]
    adminBundlePlan?: BundlePlanResponse
    adminBundlePlanFormMode?: BundlePlanFormMode
    dirty: boolean
}

const init: AdminBundleState = {
    adminBundlePlansSearchContext: {size: 10, page: 0, periodicalId: []},
    adminBundlePlans: undefined,
    adminBundlePlan: undefined,
    adminBundlePlanFormMode: undefined,
    dirty: false
}

export default function AdminBundleReducer(state = init, action: Action): AdminBundleState {
    if (isFetchAdminBundlePlansSuccess(action)) {
        return {
            ...state,
            dirty: false,
            adminBundlePlans: action.payload.result,
            adminBundleSearchResult: action.payload,
        }
    } else if (isSetAdminBundlePlanFormMode(action)) {
        return {
            ...state,
            adminBundlePlanFormMode: action.payload
        }
    } else if (isSetAdminBundlePlansSearchContext(action)) {
        return {
            ...state,
            adminBundlePlansSearchContext: action.payload,
        }
    } else if (isResetAdminBundlePlan(action)) {
        return {
            ...state,
            adminBundlePlan: undefined,
        }
    } else if (isDeleteAdminBundlePlanSuccess(action)) {
        let filteredBundlePlans = state.adminBundlePlans;
        if (state.adminBundlePlans) {
            filteredBundlePlans = state.adminBundlePlans?.filter((bundlePlan: BundlePlanSearchItem) => bundlePlan.id !== action.payload)
        }
        return {
            ...state,
            adminBundlePlans: filteredBundlePlans,
        }
    } else if (isSaveAdminBundlePlanSuccess(action)) {
        if (state.adminBundlePlanFormMode === BundlePlanFormMode.CREATE) {
            // Payload is a BundlePlanResponse
            const searchItem = {id: action.payload.bundlePlanId, label: action.payload.name} as BundlePlanSearchItem
            state.adminBundlePlans?.push(searchItem);
        }
        return {
            ...state,
            dirty: false
        }
    } else if (isFetchAdminBundlePlanSuccess(action)) {
        return {
            ...state,
            adminBundlePlan: {
                ...action.payload,
                plan: setAllPlanNodeTypeToContainer(action.payload.plan)
            },
        }
    } else if (isSetTitle(action)) {
        return {
            ...state,
            dirty: true,
            adminBundlePlan: {
                ...state.adminBundlePlan!,
                name: action.payload
            },
        }
    } else if (isSetType(action)) {
        return {
            ...state,
            dirty: true,
            adminBundlePlan: {
                ...state.adminBundlePlan!,
                type: action.payload
            },
        }
    } else if (isSetPeriodicalId(action)) {
        return {
            ...state,
            dirty: true,
            adminBundlePlan: {
                ...state.adminBundlePlan!,
                periodicalId: action.payload
            },
        }
    } else if (isSetPlan(action)) {
        return {
            ...state,
            dirty: state.adminBundlePlanFormMode === BundlePlanFormMode.CREATE ? action.payload.length > 0 : true,
            adminBundlePlan: {
                ...state.adminBundlePlan!,
                plan: action.payload
            },
        }
    } else if (isSetDirty(action)) {
        return {
            ...state,
            dirty: action.payload,
        }
    }

    return state;
}

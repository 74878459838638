import {Filters} from "../types/common-search";

export const sortDirection = (filters: Filters, attribute: string) => {
    if (filters && filters.sort) {
        const index = filters.sort.findIndex((x) => x.attr === attribute);
        if (index > -1) {
            return filters.sort[index].order === 'DESC' ? 'desc' : 'asc'
        }
    }
    return undefined;
}

export const makeSortHandler = (ctx, mapper, filters, dispatch, updateSearchQueryParamAction) => attribute => () => {
    let direction = mapper[attribute].default;
    if (filters && filters.sort) {
        const sortElement = filters.sort.find((x) => x.attr === attribute);
        if (sortElement && sortElement.order) {
            direction = mapper[attribute][sortElement.order];
        }
        if (direction !== "") {
            dispatch(updateSearchQueryParamAction({
                ctx,
                field: "sort",
                value: [{attr: attribute, order: direction}]
            }))
        } else {
            dispatch(updateSearchQueryParamAction({
                ctx,
                field: "sort",
                value: []
            }))
        }
    }
}

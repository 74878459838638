import React from "react";
import {useSelector} from "react-redux";
import {commonStyle} from "../../../../styles/common.style";
import {Container} from "@material-ui/core";
import LexForm from "../../../commons/forms/LexForm";
import {
    currentAdminCustomBundlePlanPeriodicalIdSelector,
    currentAdminCustomBundlePlanTitleSelector,
    currentAdminCustomBundlePlanTypeSelector
} from "../../../../redux/selectors/admin/bundle/admin-custom-bundle.selectors";
import {setPeriodicalId, setTitle, setType} from "../../../../redux/actions/admin/bundle/admin-custom-bundle.actions";
import {GridSize} from "@material-ui/core/Grid";
import {selectRenderer} from "../../../commons/forms/LexFormField";
import {BundleType} from "../../../../types/bundle";
import {currentPeriodicalsSelector} from "../../../../redux/selectors/periodical.selectors";

const BundleInfos = () => {
    const classes = commonStyle();

    const periodicals = useSelector(currentPeriodicalsSelector);

    return (
        <Container maxWidth={"xl"} className={classes.root}>
            <LexForm
                type={"admin.custom-bundle"}
                fields={[
                    {
                        name: "title",
                        selector: currentAdminCustomBundlePlanTitleSelector,
                        action: setTitle,
                        disabled: true,
                        required: false,
                        column: 6 as GridSize,
                        shouldNotTranslate: true
                    }, {
                        name: "periodicalId",
                        selector: currentAdminCustomBundlePlanPeriodicalIdSelector,
                        action: setPeriodicalId,
                        renderer: selectRenderer(periodicals.map(e => {
                                return {
                                    label: `[${e.id}] ${e.label}`,
                                    value: e.id
                                }
                            }
                        )),
                        disabled: true,
                        required: false,
                        column: 6 as GridSize,
                        shouldNotTranslate: true
                    }, {
                        name: "type",
                        selector: currentAdminCustomBundlePlanTypeSelector,
                        action: setType,
                        renderer: selectRenderer(Object.values(BundleType).filter((type) => type !== BundleType.UNKNOWN).map(x => {
                                return {
                                    label: `enums.articles.type.${x}.main`,
                                    value: x
                                }
                            }
                        )),
                        disabled: false,
                        required: true,
                        column: 6 as GridSize
                    }
                ]}
            />
        </Container>
    );
}

export default BundleInfos;

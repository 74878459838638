import React from "react";
import {fade, makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {Link as RouterLink, useHistory, useLocation} from 'react-router-dom';

import {AdminIcon, AdvertisementIcon, ArticleIcon, AuthorIcon, ImageIcon, PublicationIcon} from "../../icon";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../redux/actions/auth.actions";
import {Link, Tooltip} from "@material-ui/core";
import AppBarSearch from "./AppBarSearch";
import {ADMIN, MANAGER, KYRIELLE} from "../../utils/roles";
import Auth, {isAuth} from "../Auth";
import {State} from "../../redux/reducers";
import {HelpIcon} from "../commons/icon/icons";

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block"
        }
    },
    logo: {
        maxWidth: "100%",
        maxHeight: "100%",
    },
    logoContainer: {
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        "&:hover": {
            cursor: "pointer"
        }
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25)
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto"
        }
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    inputRoot: {
        color: "inherit"
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch"
        }
    },
    inputDark: {
        color: "white",
        "& .MuiInputBase-root": {
            color: "white",

            "& label.Mui-focused": {
                color: "white"
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: "white"
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderColor: "white"
                },
                "&:hover fieldset": {
                    borderColor: "white"
                },
                "&.Mui-focused fieldset": {
                    borderColor: "white"
                }
            }
        },
        "& .MuiInputAdornment-root": {
            color: "white"
        }
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex"
        }
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    fastNav: {
        display: "flex",
        "& .MuiIconButton-root": {
            color: "#FFFFFF"
        }
    },

    tabButton: {
        fontSize: theme.typography.pxToRem(10),
        fontWeight: 600,
        minHeight: 48,
        color: "white",
        minWidth: 80,
        padding: "0.1rem 0.5rem",
        "& .MuiTab-wrapper > *:first-child": {
            margin: 0
        }
    },
    divider: {
        color: "#000"
    }
}));

const routesValues = (isAdmin: boolean) => {
    return isAdmin ?
        {
            admin: 0,
            auteur: 1,
            image: 2,
            pub: 3,
            parution: 4,
            article: 5,
            lot: 5
        } : {
            auteur: 0,
            image: 1,
            pub: 2,
            parution: 3,
            article: 4,
            lot: 4
        }
}

export default function Header() {
    const history = useHistory();

    const dispatch = useDispatch();

    const classes = useStyles();
    const {t} = useTranslation();

    const [anchorProfileEl, setAnchorProfileEl] = React.useState(null);
    const [anchorAdminEl, setAnchorAdminEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isProfileMenuOpen = Boolean(anchorProfileEl);
    const isAdminMenuOpen = Boolean(anchorAdminEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const keycloakAuthServerUrl = useSelector(({auth}: State) => auth?.keycloakBaseUrl ?? "");
    const keycloakRealm = useSelector(({auth}: State) => auth?.realm ?? "");

    const handleProfileMenuOpen = event => {
        setAnchorProfileEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleAdminMenuOpen = event => {
        setAnchorAdminEl(event.currentTarget);
    };

    const handleAdminMenuClose = () => {
        setAnchorAdminEl(null);
    };

    const handleMenuClose = () => {
        setAnchorProfileEl(null);
        handleMobileMenuClose();
        handleAdminMenuClose();
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        dispatch(logout())
    }

    const handleMenuLinkClick = (linkTo: string) => () => {
        history.push(linkTo);
        handleMenuClose();
    }

    const profileMenuId = "primary-search-account-menu";
    const renderProfileMenu = (
        <Menu
            anchorEl={anchorProfileEl}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            id={profileMenuId}
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={isProfileMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleLogout}>{t('app.bar.logout')}</MenuItem>
        </Menu>
    );

    const adminMenuId = "primary-search-admin-menu";
    const renderAdminMenu = (
        <Auth requiredRoles={[ADMIN, MANAGER, KYRIELLE]}>
            <Menu
                anchorEl={anchorAdminEl}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
                id={adminMenuId}
                keepMounted
                transformOrigin={{vertical: "top", horizontal: "right"}}
                open={isAdminMenuOpen}
                onClose={handleMenuClose}
            >
                <Auth requiredRoles={[ADMIN]}>
                    <MenuItem
                        onClick={handleMenuLinkClick(`/${t("routes.admin.root")}/${t('routes.admin.periodical.root')}/${t('routes.admin.periodical.organize')}`)}
                    >
                        {t('admin.menu.periodical')}
                    </MenuItem>
                    <MenuItem
                        onClick={handleMenuLinkClick(`/${t("routes.admin.root")}/${t('routes.admin.bundle.root')}`)}
                    >
                        {t('admin.menu.bundle')}
                    </MenuItem>

                    <MenuItem
                        onClick={handleMenuLinkClick(`/${t('routes.admin.root')}/${t('routes.admin.referential.root')}`)}
                    >
                        {t('admin.menu.referential')}
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuClose()}>
                        <Link component={RouterLink} variant={"inherit"} color={"inherit"} underline={"none"}
                              to={{pathname: `${keycloakAuthServerUrl}/admin/${keycloakRealm}/console/#/realms/${keycloakRealm}/users`}}
                              target="_blank">
                            {t('admin.menu.users')}
                        </Link>
                    </MenuItem>
                </Auth>
                <Auth requiredRoles={[ADMIN, MANAGER]}>
                <MenuItem
                    onClick={handleMenuLinkClick(`/${t("routes.admin.root")}/${t('routes.admin.export.root')}`)}
                >
                    {t('admin.menu.export')}
                </MenuItem>
                </Auth>
                <Auth requiredRoles={[ADMIN]}>
                    <MenuItem
                        onClick={handleMenuLinkClick(`/${t('routes.admin.root')}/${t('routes.admin.administrate-push-online')}`)}
                    >
                        {t('admin.menu.push-online')}
                    </MenuItem>
                </Auth>
                <Auth requiredRoles={[KYRIELLE]}>
                    <MenuItem
                        onClick={handleMenuLinkClick(`/${t('routes.kyrielle.root')}/${t('routes.kyrielle.mainPage')}`)}
                    >
                        {t('admin.menu.kyrielle')}
                    </MenuItem>
                </Auth>
            </Menu>
        </Auth>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: "top", horizontal: "right"}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon/>
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle/>
                </IconButton>
                <p>Profil</p>
            </MenuItem>
        </Menu>
    );

    const location = useLocation()
    const roles = useSelector((state: State) => state.auth.roles)
    return (
        <div style={{width: '100%'}}>
            <Box display="flex" p={1}>
                <Box width="100%">
                    <Grid justify={"space-between"} container>
                        <Grid className={classes.logoContainer} xs={1} item>
                            <img
                                onClick={handleMenuLinkClick(`/`)}
                                className={classes.logo}
                                src={
                                    "/assets/logo.png"
                                }
                                alt="Lex-press logo"
                            />
                        </Grid>
                        <Grid item xs={11}>
                            <Tabs value={routesValues(isAuth(roles, [ADMIN, MANAGER]))[location.pathname.split('/')[1]]}
                                  className={classes.fastNav}>
                                {isAuth(roles, [ADMIN, MANAGER, KYRIELLE]) &&
                                <Tab
                                    icon={<AdminIcon/>}
                                    label="Admin"
                                    aria-controls={adminMenuId}
                                    onClick={handleAdminMenuOpen}
                                    className={classes.tabButton}
                                />}

                                <Tab icon={<AuthorIcon/>}
                                     label="Auteurs"
                                     onClick={handleMenuLinkClick(`/${t('routes.author.root')}`)}
                                     className={classes.tabButton}
                                />
                                <Tab
                                    icon={<ImageIcon/>}
                                    label="Images"
                                    onClick={handleMenuLinkClick(`/${t('routes.image.root')}`)}
                                    className={classes.tabButton}
                                />
                                <Tab
                                    icon={<AdvertisementIcon/>}
                                    label="Publicités"
                                    onClick={handleMenuLinkClick(`/${t('routes.ad.root')}`)}
                                    className={classes.tabButton}
                                />
                                <Tab
                                    icon={<PublicationIcon/>}
                                    label="Parutions"
                                    onClick={handleMenuLinkClick(`/${t('routes.publication.root')}`)}
                                    className={classes.tabButton}
                                />
                                <Tab
                                    icon={<ArticleIcon/>}
                                    label="Articles"
                                    onClick={handleMenuLinkClick(`/${t('routes.article.root')}`)}
                                    className={classes.tabButton}
                                />
                            </Tabs>
                        </Grid>
                    </Grid>
                </Box>

                <AppBarSearch/>

                <div className={classes.sectionDesktop}>
                    <Link to={{pathname: "https://aide.lex-press.lextenso.fr/"}}
                          target="_blank" component={RouterLink}>
                        <Tooltip title={"Aide"}>
                            <IconButton style={{marginTop: 2}}
                                        edge="end"
                            >
                                <HelpIcon/>
                            </IconButton>
                        </Tooltip>
                    </Link>
                </div>

                <div className={classes.sectionDesktop}>
                    <Tooltip title={"Profil"}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={profileMenuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.sectionMobile}>
                    <IconButton
                        aria-label="show more"
                        aria-controls={mobileMenuId}
                        aria-haspopup="true"
                        onClick={handleMobileMenuOpen}
                        color="inherit"
                    >
                        <MoreIcon/>
                    </IconButton>
                </div>
                {renderMobileMenu}
                {renderProfileMenu}
                {renderAdminMenu}
            </Box>
        </div>
    );
}

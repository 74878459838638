import {Grid} from "@material-ui/core";
import React, {useCallback, useMemo} from "react";
import {
    ArticleResponse,
    ArticleStatus,
    bundleAttachments,
    cdfAttachments,
    fixedAttachments
} from "../../../types/article";
import Tooltip from "@material-ui/core/Tooltip";
import {
    ArchiveIcon,
    AttachedBundleIcon,
    AttachedPlanIcon,
    ComplexeIcon,
    FrontCoverIcon,
    GeneratingPdfIcon,
    InvalidDocIcon,
    LockIcon,
    OnlineIcon, OnlineTempIcon,
    ReadyStatusIcon
} from "../../commons/icon/icons";
import {useTranslation} from "react-i18next";
import DateTime from "../../commons/DateTime";
import PlacardStatusAndDownload from "../../publications/workflow/PlacardStatusAndDownload";
import {ArticleSearchResult} from "../../../types/common-search";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {JouveProcessStatus} from "../../../types/workflow";
import {GridSize} from "@material-ui/core/Grid";
import Auth from "../../Auth";
import {ADMIN, EDITOR, MANAGER, PRODUCER, VIEWER} from "../../../utils/roles";
import {useSelector} from "react-redux"
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors"

type  Props = {
    article: ArticleSearchResult | ArticleResponse,
    justify?: "center" | "flex-start" | "flex-end" | "space-between" | "space-around" | "space-evenly" | undefined
    page?: string
}

const useStyles = makeStyles(() => ({
    clickable: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
}))


const pageGridXsMapping = {
    'article-view': 0 as GridSize
}
const mapPageToGridXs = (page?: string) => page ? pageGridXsMapping[page] : 2 as GridSize

function ArticleStatusList({article, justify, page}: Props) {
    const {t} = useTranslation()
    const history = useHistory();
    const classes = useStyles();

    let [pubId, pubName] = useMemo(() => {
        if (article.periodicalId) {
            return [article.publicationId, article.publication]
        } else {
            const castArticle = article as ArticleResponse
            const pub = castArticle.publication
			article.publication = pub
            return [pub ? pub.id : -1, pub ? castArticle.periodical.id + "_" + pub.year + "_" + pub.number : ""]
        }
    }, [article])

    const navToCdf = useCallback(() => {
        history.push(t(`/${t('routes.publication.root')}/${pubId}/${t('routes.publication.organize')}`));
    }, [pubId])

    const navToBundle = useCallback(() => {
        history.push(t(`/${t('routes.bundle.root')}/${article.bundleId}/${t('routes.bundle.organize')}`));
    }, [article])

	const publication = useSelector(getCurrentPublication)

    return (
        <Grid container alignItems="center" justify={justify ?? "flex-start"} spacing={1}>
            {/* TODO circuit complexe */}

            {/* rattaché à un CDF */}
            {(cdfAttachments.includes(article.attached) && fixedAttachments.includes(article.attached)) &&
            <Grid item>
                <Tooltip title={t('bundles.attached_plan_detailed', {publicationName: pubName}) ?? ""}>
                    <span className={classes.clickable} onClick={navToCdf}><AttachedPlanIcon/></span>
                </Tooltip>
            </Grid>}

            {/* rattaché à un lot */}
            {(bundleAttachments.includes(article.attached) && fixedAttachments.includes(article.attached)) &&
            <Grid item>
                <Tooltip title={t('articles.attached_bundle_detailed', {bundleId: article.bundleId}) ?? ""}>
                    <span className={classes.clickable} onClick={navToBundle}><AttachedBundleIcon/></span>
                </Tooltip>
            </Grid>}

            {page === "article-view" && (article.nature && article.nature === 'COMPLEXE') &&
            <Grid item>
                <span><ComplexeIcon/></span>
            </Grid>}


            {!article.isValid &&
            <Grid item>
                <Tooltip title={t('articles.invalid') ?? ""}>
                    <span><InvalidDocIcon/></span>
                </Tooltip>
            </Grid>}

            {(article.lastRequestPlacardProcessStatus === JouveProcessStatus.GEN_REQUEST_PACKAGE
                || article.lastRequestPlacardProcessStatus === JouveProcessStatus.PENDING
                || article.lastRequestPlacardProcessStatus === JouveProcessStatus.GEN_RESULT_PACKAGE) &&
            <Grid item>
                <Tooltip title={t('workflows.placard_in_progress') ?? ""}>
                    <span><GeneratingPdfIcon/></span>
                </Tooltip>
            </Grid>}

            {article &&
            article.lastAvailablePlacardProcessStatus &&

            <Grid item style={{marginTop: -7}}>
                <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR, VIEWER]}>
                    <PlacardStatusAndDownload type={"article"} id={article.id!!} size={"small"}
                                              lastAvailablePlacardProcessStatus={article.lastAvailablePlacardProcessStatus}/>
                </Auth>
            </Grid>}

            {(article.publicationOnlineDate) &&
            <Grid item>
                <Tooltip title={<>Poussé en ligne le <DateTime date={article.publicationOnlineDate}/></>}>
                    <span><OnlineIcon
                        htmlColor={((article.modificationDate && article.modificationDate > article.publicationOnlineDate) ? "orange" : "green")}/></span>
                </Tooltip>
            </Grid>}

            {(article.nextPublicationOnlineDate) && <>
                <Grid item>
                    <Tooltip title={<>Planifié le <DateTime
                        date={article.nextPublicationOnlineDate}/> par {article.nextPublicationOnlineUsername}</>}>
                        <span><OnlineTempIcon/></span>
                    </Tooltip>
                </Grid>
                {article.status !== ArticleStatus.PUBLISHED &&
                <Grid item>
                    <span><ArchiveIcon/></span>
                </Grid>
                }
            </>
            }

            {article.status === ArticleStatus.READY &&
            <Grid item>
                <Tooltip title={t('articles.ready') ?? ""}>
                    <span><ReadyStatusIcon/></span>
                </Tooltip>
            </Grid>}

            {(article.status === ArticleStatus.PUBLISHING) &&
            <Grid item>
                <Tooltip title={t('workflows.publishing') ?? ""}>
                    <span><LockIcon/></span>
                </Tooltip>
            </Grid>}

            {(article.status === ArticleStatus.PUBLISHED) &&
            <Grid item>
                <Tooltip title={t('workflows.archived') ?? ""}>
                    <span><ArchiveIcon/></span>
                </Tooltip>
            </Grid>}


            {article.isFrontCover &&
            <Grid item>
                <Tooltip title={t('articles.front_cover') ?? ''}>
                    <span><FrontCoverIcon/></span>
                </Tooltip>
            </Grid>}

            {/* TODO Mis en ligne */}

            {/* TODO archivé */}
        </Grid>
    )
}

export default ArticleStatusList


import PeriodicalAutoComplete from "../../authors/list/PeriodicalAutoComplete";
import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import {updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {SearchContext} from "../../../types/common-search";
import {useDispatch} from "react-redux";

export default ({publicationPeriodicalFilter}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const updatePeriodic = (value: string[]) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.ADVERTISEMENT,
            field: 'periodicalFilter',
            value
        }));
    }

    return (
        <ListItem label={t('ads.affected_periodicals')}>
            <PeriodicalAutoComplete label={t('ads.periodicals')}
                                    selectedOptions={publicationPeriodicalFilter}
                                    selectedAction={updatePeriodic}/>
        </ListItem>
    )
}

import { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { palette } from "../../palette";
import { TANK_UD_MARGIN_Y } from "../../elementsSize";

export const height = "10%";
export const borderWith = "2px";
export const marginBottom = "7px";
export const boxPadding = "15px";

export const box: CSSProperties = {
  borderWidth: borderWith,
  borderColor: palette.borderColor,
  borderStyle: "solid",
  padding: 15,
  overflow: "auto",
  height: "90%",
};

export const useTableStyles = makeStyles({
  unitDocumentListStyleTable: {
    "& thead th": {
      position: "sticky",
      top: 0,
      zIndex: 1000,
      backgroundColor: "#fff",
      borderBottom: "none",
      borderRight: "none",
      padding: 8,
    },
    "& tbody td": {
      borderBottom: "none",
      borderRight: "none",
      paddingTop: TANK_UD_MARGIN_Y,
      paddingBottom: TANK_UD_MARGIN_Y,
      paddingRight: 8,
      paddingLeft: 8,
    },
    "& tbody tr:last-child td": {
      borderBottom: "none",
    },
  },
  unitDocumentListStyleRow: {
    height: "0px",
  },
});

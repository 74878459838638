import {createActionWithPayload} from "./utils.actions";
import {Filters, SearchContext, SearchResults} from "../../types/common-search";

export const [initDefaultPeriodicalQueryParam, isInitDefaultPeriodicalQueryParamAction] = createActionWithPayload<SearchContext>('INIT_PERIODICAL_QUERY_PARAM');
export const [initDefaultQueryParam, isInitDefaultQueryParamAction] = createActionWithPayload<{ ctx: SearchContext, field: string, value: any }>('INIT_DEFAULT_QUERY_PARAM');

export const [updateSearchQueryParam, isUpdateSearchQueryParamAction] = createActionWithPayload<{ ctx: SearchContext, field: string, value: any }>('UPDATE_SEARCH_QUERY_PARAM');
export const [addSearchQueryParam, isAddSearchQueryParamAction] = createActionWithPayload<{ ctx: SearchContext, field: string, value: any }>('ADD_SEARCH_QUERY_PARAM');
export const [removeSearchQueryParam, isRemoveSearchQueryParamAction] = createActionWithPayload<{ ctx: SearchContext, field: string, value: any }>('REMOVE_SEARCH_QUERY_PARAM');
export const [resetSearchQueryParams, isResetSearchQueryParamsAction] = createActionWithPayload<SearchContext>('RESET_SEARCH_QUERY_PARAMS');

export const [reloadSearch, isReloadSearchAction] = createActionWithPayload<{ ctx: SearchContext, forceOptions?: Filters }>('RELOAD_SEARCH');

export const [search, isSearchAction] = createActionWithPayload<{ ctx: SearchContext, filters: Filters }>('SEARCH');
export const [searchSuccess, isSearchSuccessAction] = createActionWithPayload<{ ctx: SearchContext, results: SearchResults }>('SEARCH_SUCCESS');
export const [searchError, isSearchErrorAction] = createActionWithPayload<SearchContext>('SEARCH_ERROR');

export const [selectAll, isSelectAllAction] = createActionWithPayload<{ ctx: SearchContext, all: boolean }>('SELECT_ALL');
export const [selectAllPage, isSelectAllPageAction] = createActionWithPayload<{ ctx: SearchContext, allPages: boolean }>('SELECT_ALL_PAGE');
export const [selectOneElement, iSelectOneElementAction] = createActionWithPayload<{ ctx: SearchContext, id: string }>('SELECT_AN_ELEMENT');

export const [updateSpecificElementInSearchList, isUpdateSpecificElementInSearchListAction] =
    createActionWithPayload<{ ctx: SearchContext, id: string, field: string, value: string }>('UPDATE_SPECIFIC_ELEMENT_IN_SEARCH_RESULT');

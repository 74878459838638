import Keycloak from "keycloak-js";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {Action} from "../actions/utils.actions";
import {AuthPayload, User} from "../../types/auth";
import {
    authError,
    authSuccess,
    isLogoutAction,
    logout,
    refreshError,
    refreshSuccess,
    userInfo,
    setKeycloakData
} from "../actions/auth.actions";
import {fetchPeriodicals} from "../actions/periodical.actions";
import {fetchAvailableTags} from "../actions/article/article-dialog.actions"
type KeycloakUser = {
    preferred_username: string
    sub: string
    name?: string
    given_name?: string
    family_name?: string
    email?: string
}

export const keycloakMiddleware: Middleware = (api: MiddlewareAPI) => {
    const keycloak = Keycloak('/api/config/keycloak.json');

    keycloak.onAuthSuccess = () => {
        const payload: AuthPayload = {
            token: keycloak.token,
            roles: keycloak.resourceAccess?.[keycloak.clientId!!].roles ?? []
        };
        api.dispatch(authSuccess(payload));
        keycloak.loadUserInfo()
            .then(() => {
                const {
                    preferred_username: username,
                    given_name: firstName,
                    family_name: lastName,
                    email
                } = keycloak.userInfo as KeycloakUser;
                const user: User = {
                    username,
                    firstName,
                    lastName,
                    email
                };
                api.dispatch(userInfo(user));
                api.dispatch(fetchPeriodicals());
                api.dispatch(fetchAvailableTags());
            })
    };
    keycloak.onAuthError = () => {
        api.dispatch(authError());
    };
    keycloak.onAuthRefreshSuccess = () => {
        const payload: AuthPayload = {
            token: keycloak.token,
            roles: keycloak.resourceAccess?.[keycloak.clientId!!].roles ?? []
        };
        api.dispatch(refreshSuccess(payload))
    };
    keycloak.onAuthRefreshError = () => {
        api.dispatch(refreshError());
    };
    keycloak.onTokenExpired = () => {
        console.log("KEYCLOAK_TOKEN_REFRESH")
        keycloak.updateToken(5);
    };
    keycloak.onAuthLogout = () => {
        api.dispatch(logout());
    };
    keycloak.onReady = (authenticated) => {
        api.dispatch(setKeycloakData({url: keycloak.authServerUrl, realm: keycloak.realm}))
        if (!authenticated) {
            keycloak.login({
                locale: 'fr-FR fr en'
            }).then(() => console.log("login"));
        }
    };
    keycloak.init({});

    return (next: Dispatch) => (action: Action) => {
        if (isLogoutAction(action)) {
            keycloak.logout({})
        }
        return next(action)
    }
};

export default keycloakMiddleware;

import {applyMiddleware, createStore, compose} from "redux";
import reducer, {State} from "./reducers";
import loggerMiddleware from "./middlewares/logger";
import keycloakMiddleware from "./middlewares/keycloak";
import {createEpicMiddleware} from "redux-observable";
import epic from "./epics";
import {Action} from "./actions/utils.actions";
import axios, {AxiosStatic} from "axios";
import {createBrowserHistory, History} from "history";
import i18next from '../i18n';
import {i18n} from "i18next";
import token_attributesMiddleware from "./middlewares/token_attributes.middleware";

export type EpicDependencies = {
    axios: AxiosStatic,
    history: History,
    i18n: i18n
}

export const history = createBrowserHistory();

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose

const store = () => {
    const epicMiddleware = createEpicMiddleware<Action, Action, State, EpicDependencies>({
        dependencies: {
            axios,
            history,
            i18n: i18next
        }
    });
    const store = createStore(
        reducer,
        composeEnhancers(applyMiddleware(keycloakMiddleware, epicMiddleware, token_attributesMiddleware, loggerMiddleware))
    );
    epicMiddleware.run(epic)
    return store;
}

export default store

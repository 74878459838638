import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SnackbarKey, useSnackbar} from 'notistack';
import {removeSnackbar} from '../../redux/actions/notification.actions';
import {State} from "../../redux/reducers";
import {Button} from "@material-ui/core";

let displayed: SnackbarKey[] = [];

// copied from https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example
const Notifier = () => {

    const dispatch = useDispatch();
    const notifications = useSelector(({notification}: State) => notification);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const action = key => (
        <>
            <Button onClick={() => closeSnackbar(key)} style={{color: 'white'}}>
                Fermer
            </Button>
        </>
    );

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };
    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    useEffect(() => {
        notifications.forEach(({
                                   key, message, options = {},
                                   dismissed = false, closeable
                               }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }
            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose!(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
                action: closeable ? action : undefined,
                persist: closeable || options?.persist
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return (<></>);
};

export default Notifier;

import React, { Children, useContext } from "react"
import { ChildrenProps } from "../../../types"
import Box from "@material-ui/core/Box"
import { KyrTextField } from "../../../components/KyrTextField"
import { SearchContext } from "../../../contexts/organizeTreeBibleContexts"
import { useHandleSearchChange } from "../../../hooks/searchHooks"
import { DocumentNatureEnum, setEan, setSearchText } from "../../../models/Search"
import { NatureCheckBoxes } from "./LexPressAdvancedFilters"

const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)

  return (
    <Box style={{ display: 'flex', columnGap: 20 }}>
      {childrenList[0]}
      {childrenList[1]}
    </Box>
  )
}

export const OuvrageAdvancedFilters = () => {

  const [searchState] = useContext(SearchContext)
  const handleChange = useHandleSearchChange()

  return (
    <Layout>
      <NatureCheckBoxes natures={[DocumentNatureEnum.CLAUSE, DocumentNatureEnum.MODELE]} />
      <KyrTextField
        label="EAN"
        value={searchState.ean}
        onChange={({ target: { value } }) => handleChange(setEan(value)(searchState), undefined, true)}
      />
    </Layout>
  )
}
import React from "react";
import PushOnlineArticleTable from "./PushOnlineArticleTable";
import {ADMIN} from "../../../utils/roles";
import Auth from "../../Auth";
import {Container} from "@material-ui/core";
import {commonStyle} from "../../../styles/common.style";
import PageHeader from "../../commons/PageHeader";
import {useTranslation} from "react-i18next";

export default function PushOnlineConfigurationAdminRoot() {
    const commonClasses = commonStyle();
    const {t} = useTranslation()

    return (
        <Auth requiredRoles={[ADMIN]}>
            <Container maxWidth={"xl"} className={commonClasses.root}>
                <PageHeader label={t('admin.push-online.title')}
                            requiredRoles={[ADMIN]}/>

                <PushOnlineArticleTable/>
            </Container>
        </Auth>
    )
}

import { Divider } from "@material-ui/core";
import React, { Children } from 'react';

import { Column } from "../../../components/FlexBox";
import { ChildrenProps } from "../../../types";
import { Content } from './Content';
import { Header } from "./Header";
import { Pagination } from './Pagination';

const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)
  return (
    <Column grow={1} style={{ overflow: 'auto' }} >
      <Column>
        {childrenList[0]}
      </Column>
      <Column>
        {childrenList[1]}
      </Column>
      <Column grow={1} style={{ overflow: 'auto' }}>
        {childrenList[2]}
      </Column>
      <Column justifyContent="flex-end">
        {childrenList[3]}
      </Column>
    </Column>
  )
}

export const UDTank = () => {
  return (
    <Layout>
      <Header />
      <Divider style={{ marginTop: 15, marginBottom: 15 }} />
      <Content />
      <Pagination />
    </Layout>
  )
}

import { makeStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import React, {forwardRef} from "react";
import { palette } from "../palette";

const useStyles = makeStyles({
  purpleBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: palette.borderColor,
      },
      '&:hover fieldset': {
        borderColor: palette.primary,
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary,
      },
    },
  },
});


export const KyrTextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const classes = useStyles()

  return (
    <TextField
      {...props}
      className={classes.purpleBorder}
      variant="outlined"
      inputRef={ref}
    />
  )
})
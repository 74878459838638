import React from "react";
import {Grid} from "@material-ui/core";
import {useSelector} from "react-redux";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {DateTime as DateTimeLuxon} from "luxon";
import DateTime from "../../commons/DateTime";

const metadataContainer = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '5px'
    }
}))

export default function PublicationOrganizeMetadata() {
    const publication = useSelector(getCurrentPublication)
    const {t} = useTranslation()
    const classes = metadataContainer();

    return <Grid container direction={"row"} justify={"flex-start"} xs={12} className={classes.root}>
        {publication && <>
            <Grid item xs={3}>
                <Typography>{t(`publications.form.parution.label`)} : <b>{publication.periodicalId}</b></Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>{t(`publications.form.numero.label`)} : <b>{publication.number}</b></Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>{t(`publications.form.year.label`)} : <b>{publication.year}</b></Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>{t(`publications.form.edition_type.label`)} : <b>{t(`enums.publications.editionType.${publication?.editionType}`)}</b></Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>{t(`publications.form.startDate.label`)} : <b><DateTime format={DateTimeLuxon.DATE_MED}
                                                                                    date={publication.startDate}/></b></Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>{t(`publications.form.endDate.label`)} : {publication?.endDate &&
                <b><DateTime format={DateTimeLuxon.DATE_MED} date={publication.endDate}/></b>}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography>{t(`publications.status`)} : <b>{t(`enums.publications.status.${publication.status}`)}</b></Typography>
            </Grid>
        </>}
    </Grid>
}

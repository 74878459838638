import {Action} from "../../actions/utils.actions";
import {EditionType} from "../../../types/publication";
import {
    isCloseNewPublicationDialogAction,
    isFetchArticleOfPublicationAction,
    isFetchImageOfPublicationAction,
    isFetchPublicationSuccessAction,
    isGenerateNewPublicationAction,
    isOpenEditPublicationDialogAction,
    isOpenNewPublicationDialogAction,
    isSetEditPublicationId,
    isSetPublicationFormArticleOnCover,
    isSetPublicationFormEditionType,
    isSetPublicationFormEndDate,
    isSetPublicationFormImageOnCover,
    isSetPublicationFormNumber,
    isSetPublicationFormPeriodicalId,
    isSetPublicationFormStartDate,
    isSetPublicationFormTextOnCover,
    isSetPublicationFormTitle,
    isSetPublicationFormYear
} from "../../actions/publication.actions";
import {SelectOption} from "../../../components/commons/forms/LexFormField";

export type PublicationDialogForm = {
    periodicalId?: string
    editionType: EditionType
    year: number
    number: string[]
    title?: string
    startDate: string
    endDate?: string

    // Edit
    article?: SelectOption
    image?: SelectOption
    text?: string
}

type PublicationDialogState = {
    open: boolean
    form?: PublicationDialogForm
    currentEditPublicationId?: number
}

const init: PublicationDialogState = {
    open: false
};

function updateForm(state: PublicationDialogState, field: string, value: any): PublicationDialogState {
    return {
        ...state,
        form: state.form ? {
            ...state.form,
            [field]: value,
        } : undefined
    }
}

const reducer = (state = init, action: Action): PublicationDialogState => {
    if (isOpenNewPublicationDialogAction(action)) {
        // open after epic send GenerateNewPublicationAction
        return state;
    } else if (isCloseNewPublicationDialogAction(action)) {
        return {
            open: false
        }
    } else if (isOpenEditPublicationDialogAction(action)) {
        return state;
    } else if (isSetEditPublicationId(action)) {
        return {
            ...state,
            open: true,
            currentEditPublicationId: action.payload
        };
    } else if (isFetchPublicationSuccessAction(action)) {
        return {
            ...state,
            form: {
                periodicalId: action.payload.periodicalId,
                editionType: action.payload.editionType,
                year: action.payload.year,
                number: action.payload.editionType === EditionType.SPECIAL_EDITION ? [action.payload.number.substring(11, 14)] : [action.payload.number],
                title: action.payload.title,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
                text: action.payload.text
            }
        };
    } else if (isFetchImageOfPublicationAction(action)) {
        return {
            ...state,
            form: state.form ? {
                ...state.form,
                image: {value: action.payload.id, label: action.payload.name}
            } : undefined
        };
    } else if (isFetchArticleOfPublicationAction(action)) {
        return {
            ...state,
            form: state.form ? {
                ...state.form,
                article: {value: action.payload.id, label: action.payload.title ?? ""}
            } : undefined
        };
    } else if (isGenerateNewPublicationAction(action)) {
        const {periodicalId, date, year} = action.payload
        return {
            open: true,
            form: {
                periodicalId,
                editionType: EditionType.STANDARD,
                year,
                number: [''],
                title: '',
                startDate: date,
            }
        }
    } else if (isSetPublicationFormTitle(action)) {
        return updateForm(state, 'title', action.payload);
    } else if (isSetPublicationFormPeriodicalId(action)) {
        return updateForm(state, 'periodicalId', action.payload);
    } else if (isSetPublicationFormYear(action)) {
        return updateForm(state, 'year', action.payload);
    } else if (isSetPublicationFormNumber(action)) {
        return updateForm(state, 'number', [action.payload]);
    } else if (isSetPublicationFormEditionType(action)) {
        return {
            ...state,
            form: state.form ? {
                ...state.form,
                editionType: action.payload,
                number: []
            } : undefined
        }
    } else if (isSetPublicationFormStartDate(action)) {
        return updateForm(state, 'startDate', action.payload);
    } else if (isSetPublicationFormEndDate(action)) {
        return updateForm(state, 'endDate', action.payload);
    } else if (isSetPublicationFormArticleOnCover(action)) {
        return updateForm(state, 'article', action.payload);
    } else if (isSetPublicationFormImageOnCover(action)) {
        return updateForm(state, 'image', action.payload);
    } else if (isSetPublicationFormTextOnCover(action)) {
        return updateForm(state, 'text', action.payload);
    } else {
        return state
    }
}

export default reducer

import React, { Children, useContext } from "react"
import { ChildrenProps } from "../../types"
import { IconButton, Typography } from "@material-ui/core"
import { KyrButton } from "../../components/KyrButton"
import { KyrTextField } from "../../components/KyrTextField"
import { Row, Column } from "../../components/FlexBox"
import { TileContext } from "../../contexts/administrateTreeContexts"
import { palette } from "../../palette"
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { boxStyle } from "./admistrateTree.common"
import { useDragNodeFromTree } from "../../hooks/nodeDnDhooks"
import { KyrSubTitle } from "../../components/KyrSubTitle"
import { useTextMaxLength } from "../../hooks/useTextMaxLength"
import { MAX_LENGTH_TILE } from "../../constantes"
import { useTranslation } from "react-i18next"

const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)
  return (
    <Column id="add-elements-container" grow={1} rowGap={25} style={{ overflow: 'auto' }}>
      {childrenList[0]}
      <Row id="btn-input-test" colGap={25}>
        {childrenList[1]}
        {childrenList[2]}
      </Row>
      {childrenList[3]}
      <Column id="shadow-elements-container" grow={1} style={{ ...boxStyle }}>
        <Row id="elements-container" style={{ padding: 30, display: 'flex', columnGap: 20, rowGap: 20, flexWrap: 'wrap' }}>
          {childrenList[4]}
        </Row>
      </Column>
    </Column>
  )
}

export interface TileProps {
  label: string
  index: number
}

const Tile = ({ label, index }: TileProps) => {
  const [tile, setTile] = useContext(TileContext)
  const [drag, isDragging] = useDragNodeFromTree({ label, index })
  const handleDelete = () => {
    setTile(tile.filter((_, i) => i !== index))
  }

  return (
    <Row ref={drag} style={{
      borderRadius: 5,
      display: 'flex',
      border: 'solid',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderColor: '#C2BFF8',
      backgroundColor: 'white',
      padding: '5px 15px',
      maxWidth: '100%',
      opacity: isDragging ? 0 : 1,
      transition: 'opacity 0.5s ease-out',
    }}>
      <Typography
        style={{ color: palette.primary, fontWeight: 500, overflowWrap: 'anywhere' }}>
        {label}
      </Typography>
      <IconButton
        style={{ marginLeft: 10, marginBottom: 2 }}
        onClick={handleDelete}
        size="small">
        <DeleteForeverIcon style={{ color: '#BB0000' }} />
      </IconButton>
    </Row>
  )
}

const TitlesList = () => {
  const [tiles] = useContext(TileContext)
  return (
    <>
      {tiles.map((tile, index) => (
        <Tile label={tile} index={index} />
      ))}
    </>

  )
}

export const AddElement = () => {
  const [tile, setTile] = useContext(TileContext)
  const [text, setText] = useTextMaxLength(MAX_LENGTH_TILE)
  const {t} = useTranslation()

  const handleAddElement = () => {
    if (text.trim().length === 0) return
    setTile([...tile, text.trim()])
    setText('')
  }

  return (
    <Layout>
      <KyrSubTitle>{t('kyrielle.administrate.createElementTitle')}</KyrSubTitle>
      <KyrTextField
        onKeyPress={({ key }) => { if (key === 'Enter') handleAddElement() }}
        placeholder={t('kyrielle.administrate.placeholderText')}
        style={{ flexGrow: 1 }}
        size="small"
        value={text}
        onChange={({ target: { value } }) => setText(value)}
      />
      <KyrButton onClick={handleAddElement} kyrType="primary">{t('kyrielle.button.create')}</KyrButton>
      <KyrSubTitle>{t('kyrielle.administrate.dragableElementTitle')}</KyrSubTitle>
      <TitlesList />
    </Layout>
  )
}

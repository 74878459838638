export enum ProposalType {
    QUERY = 'QUERY',
    AUTHOR = 'AUTHOR',
    IMAGE = 'IMAGE',
    AD = 'AD',
    PUBLICATION = 'PUBLICATION',
    ARTICLE = 'ARTICLE',
    BUNDLE = 'BUNDLE'
}

export type Proposal = EntityProposal | QueryProposal

export type EntityProposal = {
    id: string
    type: ProposalType.AUTHOR | ProposalType.PUBLICATION | ProposalType.ARTICLE | ProposalType.BUNDLE | ProposalType.IMAGE | ProposalType.AD
    label: string
    authorId?: string
    complete?: string[]
}

export type QueryProposal = {
    type: ProposalType.QUERY
    site: ProposalType.AUTHOR | ProposalType.ARTICLE | ProposalType.BUNDLE | ProposalType.IMAGE | ProposalType.AD | ProposalType.PUBLICATION
    query: string
}

export const isQueryProposal = (proposal: Proposal): proposal is QueryProposal => proposal.type === ProposalType.QUERY

import { Box, Chip, CircularProgress, Tooltip } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import React, { CSSProperties, Children, useContext } from "react"
import { EntityProposal } from "../../../../types/quick-search"
import { KyrCheckbox } from "../../../components/KyrCheckBox"
import { KyrTextField } from "../../../components/KyrTextField"
import { SearchContext } from "../../../contexts/organizeTreeBibleContexts"
import { useHandlePublicationChange, useHandleSearchChange } from "../../../hooks/searchHooks"
import { useFetchDataOnMount } from "../../../hooks/useFetchDataOnMount"
import { DocumentNatureEnum, setPublicationFilter } from "../../../models/Search"
import { ChildrenProps } from "../../../types"
import { NatureCheckBoxes } from "./LexPressAdvancedFilters"


const buildParameters = (
    query: string,
) => ({ page: 0, size: 20, query, periodicalIdFilter: ['GDA'] })


const PublicationAutoComplete = ({ style }: { style?: CSSProperties }) => {
    const [searchState] = useContext(SearchContext)
    const [publications, setPublications] = useFetchDataOnMount<EntityProposal[]>({
        url: '/api/search/autocomplete/publication',
        method: 'post',
        body: buildParameters('')
    })

    const handleChange = useHandleSearchChange()
    const handlePublicationChange = useHandlePublicationChange(setPublications)

    const displayLabel = (publication: EntityProposal) => {
        return publication.label
    }

    return (
        <Autocomplete<EntityProposal, true>
            style={style}
            limitTags={1}
            options={publications.data ?? []}
            onChange={(_, value) => {
                handleChange(setPublicationFilter(value.map(e => Number(e.id)))(searchState))
            }}
            onInputChange={(_, inputTextValue) => {
                handlePublicationChange(buildParameters(inputTextValue), true)
            }}
            multiple
            getOptionLabel={(option) => option.label}
            renderOption={(option, { selected }) => (
                <>
                    <KyrCheckbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {displayLabel(option)}
                </>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Tooltip title={option.label} key={index}>
                        <Chip label={option.label} {...getTagProps({ index })} />
                    </Tooltip>
                ))
            }
            renderInput={(params) => (
                <KyrTextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {publications.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    label="Parution"
                />
            )}
        />
    )
}

const Layout = ({ children }: ChildrenProps) => {
    const childrenList = Children.toArray(children)

    return (
        <Box style={{ display: 'flex', columnGap: 20 }}>
            {childrenList[0]}
            {childrenList[1]}
        </Box>

    )
}

export const GdaAdvancedFilters = () => {

    return (
        <Layout>
            <PublicationAutoComplete style={{ flexGrow: 1 }} />
            <NatureCheckBoxes natures={[DocumentNatureEnum.LOT]}/>
        </Layout>
    )
}
import { RadioGroup } from "@material-ui/core";
import React, { CSSProperties, Children, Dispatch, SetStateAction, useContext } from 'react';
import { KyrButton } from "../../../components/KyrButton";
import { KyrCheckbox } from "../../../components/KyrCheckBox";
import { KyrRadio } from "../../../components/KyrRadio";
import { KyrTextField } from "../../../components/KyrTextField";
import {
  DocumentStatusEnum,
  DocumentTypeEnum,
  defaultSearch,
  displayDocumentStatusEnum,
  displayDocumentTypeEnum,
  resetFilter,
  setSearchText,
  setStatusFromString,
  setTypeFromString
} from '../../../models/Search';

import { KyrFormControlLabel } from "../../../components/KyrFormControlLabel";
import { SearchContext } from "../../../contexts/organizeTreeBibleContexts";
import { useHandleSearchChange } from "../../../hooks/searchHooks";
import { ChildrenProps } from "../../../types";
import { filterBoxPadding } from "./Header";
import { Column, Row } from "../../../components/FlexBox";
import { TANK_FILTERS_FONT_SIZE, TANK_ROW_GAP_SIMPLE_FILTERS } from "../../../elementsSize";

const SimpleFilterLayout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)
  const statusedWidth = 70

  return (
    <Row style={filterBoxPadding} alignItems="center" colGap="5%">
      <Row id="search-text-input" alignItems="center">
        {childrenList[0]}
      </Row>
      <Column grow={1} rowGap={TANK_ROW_GAP_SIMPLE_FILTERS}>
          <Row id="radio-sources">
            {childrenList[1]}
          </Row>
          <Row>
            <Row grow={1} >
              <Row id="statuses"  width={`${statusedWidth}%`} colGap="20%">
                {childrenList[2]}
              </Row>
              <Row id="more-filters" width={`${100 - statusedWidth}%`} justifyContent="flex-end">
                {childrenList[3]}
              </Row>
            </Row>
          </Row>
      </Column>
    </Row>
  )
}

const Sources = () => {
  const groupStyle: CSSProperties = { display: 'flex', columnGap: "10%", alignItems: 'center', flexGrow: 1 }
  const [searchState] = useContext(SearchContext)

  const handleChange = useHandleSearchChange()

  return (
    <RadioGroup row style={groupStyle} value={searchState?.type} onChange={({ target: { value } }) => {
      const updatedSearch = resetFilter(setTypeFromString(value)(defaultSearch)) 
      handleChange(updatedSearch)
    }}>
      {Object.values(DocumentTypeEnum).map(value => (
        <KyrFormControlLabel
          key={value}
          value={value}
          style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}
          control={<KyrRadio style={{paddingTop: 0, paddingBottom: 0}} />}
          label={<span style={{fontSize: TANK_FILTERS_FONT_SIZE}}>{displayDocumentTypeEnum(value as DocumentTypeEnum)}</span>}
        />
      ))}
    </RadioGroup>
  )
}

const Statuses = () => {
  const [searchState] = useContext(SearchContext)

  const handleChange = useHandleSearchChange()

  return (
    <>
      {Object.values(DocumentStatusEnum).map((value) => (
        <KyrFormControlLabel
          key={value}
          control={
            <KyrCheckbox
              style={{paddingTop: 0, paddingBottom: 0}}
              checked={searchState.status.includes(value as DocumentStatusEnum)}
              onChange={({ target: { checked } }) => {
                const updatedSearch = setStatusFromString(checked, value)(searchState)
                handleChange(updatedSearch)
              }}
              value={value}
            />
          }
          label={<span style={{fontSize: TANK_FILTERS_FONT_SIZE}}>{displayDocumentStatusEnum(value as DocumentStatusEnum)}</span>}
        />
      ))}
    </>
  )
}

export const SimpleFilters = ({ moreFilterIsOpen, setMoreFilterIsOpen }: {
  moreFilterIsOpen: boolean,
  setMoreFilterIsOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [searchState] = useContext(SearchContext)
  const handleChange = useHandleSearchChange()
  const handleMoreFilter = () => {
    setMoreFilterIsOpen((prev) => !prev)
  }

  const buttonText = moreFilterIsOpen ? 'moins de filtres ⌃' : 'plus de filtres ⌄'

  return (
    <SimpleFilterLayout>
      <KyrTextField
        size="small"
        label="Rechercher"
        value={searchState.searchText}
        onChange={({ target: { value } }) => handleChange(setSearchText(value)(searchState), undefined, true)}
      />
      <Sources />
      <Statuses />
      <KyrButton size="small" style={{padding: 0, fontWeight: 'unset', fontSize: TANK_FILTERS_FONT_SIZE}} onClick={handleMoreFilter} kyrType="text">{buttonText}</KyrButton>
    </SimpleFilterLayout>
  )
}
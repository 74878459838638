import {AxiosStatic} from "axios";
import {merge, Observable, of} from "rxjs";
import {Action} from "../../../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, concatMap, filter, map, mergeMap} from "rxjs/operators";
import {
    fetchAdminCustomBundleError,
    fetchAdminCustomBundleSuccess,
    isFetchAdminCustomBundle,
    isSaveAdminCustomBundle,
    saveAdminCustomBundleError,
    saveAdminCustomBundleSuccess
} from "../../../actions/admin/bundle/admin-custom-bundle.actions";
import {notifyError, notifySuccess} from "../../../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../../../reducers";
import {EpicDependencies} from "../../../store";
import {CustomBundlePlan} from "../../../../types/bundle";
import i18next from "i18next";

function _fetch(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.get(`/api/bundle/${id}/bundle_plan`, {headers}))
        .pipe(
            map((result) => fetchAdminCustomBundleSuccess({...result.data, id: id} as CustomBundlePlan)),
            catchError(err => merge(
                of(fetchAdminCustomBundleError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE_PLAN'}))
            ))
        )
}

function _update(axios: AxiosStatic, customBundlePlan: CustomBundlePlan, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.put(`/api/bundle/${customBundlePlan.id}/bundle_plan`, customBundlePlan, {headers}))
        .pipe(
            mergeMap(() => of(saveAdminCustomBundleSuccess(customBundlePlan), notifySuccess(i18next.t('misc.save_plan_success')))),
            catchError(err => merge(
                of(saveAdminCustomBundleError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE_PLAN'}))
            ))
        )
}

export function saveAdminCustomBundlePlan(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSaveAdminCustomBundle),
        concatMap((action) => {
            return _update(axios, action.payload, state$.value.auth.token ?? "")
        })
    )
}

export function loadAdminCustomBundlePlan(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchAdminCustomBundle),
        concatMap((action) => {
            return _fetch(axios, action.payload, state$.value.auth.token ?? "")
        })
    )
}

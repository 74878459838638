import {Action} from "../../actions/utils.actions";
import {BundleResponse} from "../../../types/bundle";
import {isFetchBundleErrorAction, isFetchBundleSuccessAction, isResetBundleAction} from "../../actions/bundle.actions";

type OrganizeBundleState = {
    current?: BundleResponse
}

const reducer = (state = {}, action: Action): OrganizeBundleState => {
    if (isFetchBundleSuccessAction(action)) {
        return {
            ...state,
            current: action.payload
        }
    } else if (isFetchBundleErrorAction(action)) {
        return {
            ...state,
            current: undefined
        }
    } else if (isResetBundleAction(action)) {
        return {
            ...state,
            current: undefined
        }
    }

    return state
}

export default reducer

import {Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {FormFieldProps} from "../../commons/forms/LexFormField";
import {EditionType} from "../../../types/publication";
import MaskedInput from 'react-text-mask';
import Input from "@material-ui/core/Input";

const handleSetState = (setState, resetIfBlank) => (v) => {
    if (v.target.value.length > 3) {
        setState(v.target.value.substr(0, 3))
    } else if (v.target.value.length === 0 && resetIfBlank) {
        resetIfBlank()
        setState(v.target.value)
    } else {
        setState(v.target.value)
    }
}

const StandardNumberRenderer = ({name, disabled, register, errors, action, required, update, value, dispatch}:
                                    FormFieldProps & { update, value, dispatch }) => {
    const [firstNumber, setFirstNumber] = useState(value ? (value.split('-')[0] ?? "") : "")
    const [secondNumber, setSecondNumber] = useState(value ? (value.split('-')[1] ?? "") : "")

    useEffect(() => {
        update(action, dispatch)({target: {value: [firstNumber, secondNumber].filter(it => it !== "").join('-')}})
    }, [action, dispatch, update, firstNumber, secondNumber])

    return <Grid container direction={"row"} spacing={1}>
        <Grid item>
            <Input
                name={name}
                inputComponent={PublicationNumberMaskCustom}
                fullWidth={true}
                disabled={disabled}
                color={"secondary"}
                error={errors[name] !== undefined}
                inputRef={register({required: required})}
                defaultValue={firstNumber ?? ''}
                onChange={handleSetState(setFirstNumber, () => {
                    setSecondNumber("")
                })}
            />
        </Grid> <Grid item style={{marginTop: 5}}> – </Grid>
        <Grid item>
            <Input
                name={name}
                inputComponent={PublicationNumberMaskCustom}
                fullWidth={true}
                disabled={firstNumber === ""}
                color={"secondary"}
                error={!(firstNumber === "") && errors[name] !== undefined}
                defaultValue={secondNumber ?? ''}
                onChange={handleSetState(setSecondNumber, null)}
            />
        </Grid>
    </Grid>
}

export const publicationRenderer = (type?: EditionType) => (props: FormFieldProps, update, value, dispatch, t, classes) => {
    if (!type) return <></>
    return type === EditionType.STANDARD ?
        <StandardNumberRenderer {...{
            ...props,
            update,
            value,
            dispatch,
            t,
            classes
        }} /> : specialNumberRenderer(props, update, value, dispatch)
}


function PublicationNumberMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/]}
            showMask={true}
            guide={false}
        />
    );
}


function YearMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
            showMask={true}
            guide={false}
        />
    );
}

export const specialNumberRenderer = ({
                                          type,
                                          name,
                                          disabled,
                                          valueSelector,
                                          register,
                                          errors,
                                          action,
                                          required
                                      }: FormFieldProps, update, value, dispatch) => {
    return <Input
        name={name}
        inputComponent={PublicationNumberMaskCustom}
        color={"secondary"}
        error={errors[name] !== undefined}
        inputRef={register({required: required})}
        defaultValue={value ?? ''}
        onChange={update(action, dispatch)}
    />
}


export const yearRenderer = ({
                                 type,
                                 name,
                                 disabled,
                                 valueSelector,
                                 register,
                                 errors,
                                 action,
                                 required
                             }: FormFieldProps, update, value, dispatch) => {
    return <Input
        name={name}
        inputComponent={YearMaskCustom}
        disabled={disabled}
        color={"secondary"}
        error={errors[name] !== undefined}
        inputRef={register({required: required, maxLength: 4, minLength: 4})}
        defaultValue={value ?? ''}
        onChange={update(action, dispatch)}
    />
}

import { Box, Typography } from "@material-ui/core"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React, { CSSProperties, useContext } from "react"
import { DocumentUnitContext, SearchContext, StatusToUpdateContext } from "../../../contexts/organizeTreeBibleContexts"
import { DocumentUnit } from "../../../models/DocumentUnit"
import { palette } from "../../../palette"
import { flexRowCenter } from "../../../styles"
import { useTableStyles } from "../styles"
import { useDragElementFromTank } from "./udTank.dnd"
import { KyrHandleQuery } from "../../../components/KyrHandleQuery"
import { Row } from "../../../components/FlexBox"
import { TANK_STATUS_CIRCLE_BORDER_SIZE, TANK_STATUS_CIRCLE_SIZE, TANK_UD_FONT_SIZE, TANK_UD_HEADER_FONT_SIZE } from "../../../elementsSize"
import DOMPurify from 'dompurify';

const TabRow = ({ doc, idAndTitleAlign }: { doc: DocumentUnit, idAndTitleAlign: "inherit" | "left" | "center" | "right" | "justify" }) => {
  const classes = useTableStyles()
  const [statusToUpdate] = useContext(StatusToUpdateContext)

  const getDocumentCount = () => doc.countInTree + (statusToUpdate[doc.id] || 0)


  const [drag] = useDragElementFromTank(doc)

  const isAttached = (doc: DocumentUnit) => getDocumentCount() > 0
  const color = (doc: DocumentUnit) => isAttached(doc) ? 'grey' : 'black'
  type StatusStyleType = (doc: DocumentUnit) => CSSProperties
  const statusStyle: StatusStyleType = (doc: DocumentUnit) => ({
    ...flexRowCenter,
    border: 'solid',
    borderWidth: TANK_STATUS_CIRCLE_BORDER_SIZE,
    borderRadius: '100%',
    paddingRight: TANK_STATUS_CIRCLE_BORDER_SIZE / 2,
    paddingTop: TANK_STATUS_CIRCLE_BORDER_SIZE / 2,
    width: TANK_STATUS_CIRCLE_SIZE,
    height: TANK_STATUS_CIRCLE_SIZE,
    backgroundColor: isAttached(doc) ? palette.attachedStatusBackgroundCircle : palette.unattachedStatusBackgroundCircle,
    borderColor: isAttached(doc) ? palette.attachedStatusBorderCircle : palette.unattachedStatusBorderCircle,
  })

  return (
    <TableRow innerRef={drag} style={{ cursor: 'move' }} key={doc.id} className={classes.unitDocumentListStyleRow} >
      <TableCell style={{ color: color(doc), border: 'none', fontSize: TANK_UD_FONT_SIZE, whiteSpace: 'nowrap' }} align={idAndTitleAlign}>{doc.id}</TableCell>
      <TableCell style={{ color: color(doc), border: 'none', fontSize: TANK_UD_FONT_SIZE }} align={idAndTitleAlign}>
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(doc.title) }} />
      </TableCell>
      <TableCell style={{ ...flexRowCenter, border: 'none', fontSize: TANK_UD_FONT_SIZE }}>
        <Box style={statusStyle(doc)}>
          <div>
            {getDocumentCount() === 0 ? '' : getDocumentCount()}
          </div>
        </Box>
      </TableCell>
    </TableRow>
  )
}

const DocumentsUnitRows = ({ list, idAndTitleAlign }: {
  list?: DocumentUnit[],
  idAndTitleAlign: "inherit" | "left" | "center" | "right" | "justify"
}) => {

  return (
    <>
      {list?.map((doc) => (
        <TabRow key={doc.id} doc={doc} idAndTitleAlign={idAndTitleAlign} />
      ))}
    </>
  )
}


export const Content = () => {
  const [documentUnitState, setDocumentUnitState] = useContext(DocumentUnitContext)
  const noResult = documentUnitState.data?.totalElements === 0

  const [searchState] = useContext(SearchContext)
  const noSelectedType = searchState.type === undefined
  const classes = useTableStyles()

  if (noSelectedType) {
    return (
      <Row center grow={1}>
        <Typography>Veuillez sélectionner une source</Typography>
      </Row>
    )
  }

  if (noResult) {
    return (
      <Row center grow={1}>
        <Typography>Aucune UD correspondante trouvée</Typography>
      </Row>
    )
  }


  const align = 'left'
  const headStyle: CSSProperties = {
    fontWeight: 'bold',
    fontSize: TANK_UD_HEADER_FONT_SIZE,
    border: 'none'
  }

  return (
    <KyrHandleQuery
      errorMessage="KYRIELLE_UD_LOAD"
      query={documentUnitState}
      setQuery={setDocumentUnitState}
    >
      <TableContainer id="ud-table">
        <Table className={classes.unitDocumentListStyleTable} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={headStyle} align={align}>ID</TableCell>
              <TableCell style={headStyle} align={align}>Titre</TableCell>
              <TableCell style={headStyle} align="center">Statut</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <DocumentsUnitRows idAndTitleAlign={align} list={documentUnitState?.data?.content} />
          </TableBody>
        </Table>
      </TableContainer>
    </KyrHandleQuery>
  )
}


import React from 'react';
import Auth from "./Auth";
import AppBar from "./appbar";
import Root from "../routes/Root";
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import Notifier from "./commons/Notifier";
import ArticleDialog from "./articles/create/ArticleDialog";
import {useSelector} from "react-redux";
import {openDialog as isPublicationOpen} from "../redux/selectors/publication.selectors";
import {openDialog as isArticleOpen} from "../redux/selectors/article/article.selectors";
import CreatePublicationDialog from "./publications/create/CreatePublicationDialog";
import withStyles from "@material-ui/core/styles/withStyles";
import {useLocation} from "react-router-dom";

const App = () => {
    const publicationDialogOpen = useSelector(isPublicationOpen)
    const articleDialogOpen = useSelector(isArticleOpen)

    const GlobalCss = withStyles({
        '@global': {
            '.MuiPickersToolbar-toolbar': {
                background: 'white',
                height: 'auto'
            },
            '.MuiPickersToolbarText-toolbarBtnSelected': {
                color: 'black',
                fontSize: 12

            },
            '.MuiPickersToolbarText-toolbarTxt': {
                color: 'black'
            },
            '.MuiPickersToolbarText-toolbarTxt.MuiTypography-h4': {
                fontSize: 12
            }
        }
    })(() => null);

    const {pathname} = useLocation()
    return <>
        <GlobalCss/>
        <MuiPickersUtilsProvider utils={LuxonUtils} locale={"fr"}>
            <Notifier/>
            <Auth>
                {!pathname.includes('kyrielle') && <AppBar/>}
                <Root/>
                <>
                    {publicationDialogOpen && <CreatePublicationDialog/>}
                    {articleDialogOpen && <ArticleDialog/>}
                </>
            </Auth>
        </MuiPickersUtilsProvider>
    </>
}

export default App;

import {ParameterType} from "./query-param.utils";
import axios from "axios";

export const downloadImages = (images: { id: string, collection: string }[]) => {
    images.forEach(({id, collection}, index) => {
        setTimeout(() => {
            const a = document.createElement('a')
            a.setAttribute('href', `${window.location.origin}/api/image/${id}?download`);
            a.setAttribute('download', '');
            a.setAttribute('target', '_blank');
            a.click()
        }, index * 500)
    })
}

export const downloadAllAuthorsCSV = () => {
    const a = document.createElement("a")
    a.setAttribute('download', 'auteurs.csv');
    a.setAttribute('target', '_blank');
    a.click()

    axios({
        url: `${window.location.origin}/api/admin/export/authors-csv?all=true`, //your url
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'auteurs.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}

export const downloadAuthorsCSV = (authorIds: string[]) => {
    const a = document.createElement("a")
    a.setAttribute('download', 'auteurs.csv');
    a.setAttribute('target', '_blank');
    a.click()

    axios({
        url: `${window.location.origin}/api/admin/export/authors-csv?ids=${authorIds.join(",")}`, //your url
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'auteurs.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
    });
}

export const createHtml = (html: string) => {
    return {__html: html};
}

const htmlUnescapes = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': '\''
};

const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?39);/g;

export const unescapeHtml = (html: string): string =>
  html.replace(reEscapedHtml, (entity) => (htmlUnescapes[entity] || '\''))

export const cleanHtml = (html?: string) : string => {
    if(!html)
        return ""
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export const downloadArticle = (fileName: string, content: string) => {
    const a = document.createElement('a')
    a.setAttribute('href', 'data:text/xml;charset=utf-8,' + encodeURIComponent(content));
    a.setAttribute('download', fileName);
    a.setAttribute('target', '_blank');
    a.click()
}
export const minDate = '2000-01-01';
export const maxDate = '2025-01-01';

export const baseParams = {
    page: {
        type: ParameterType.NUMBER,
        default: 0
    },
    size: {
        type: ParameterType.NUMBER,
        default: 20
    },
    query: {
        type: ParameterType.STRING
    },
    sort: {
        type: ParameterType.SORT,
        default: [{attr: 'UPDATE', order: 'DESC'}]
    },
}

const _download = () => (files: string[]) => {
    files.forEach((fileId, index) => {
        setTimeout(function () {
            const a = document.createElement('a');
            a.setAttribute('href', window.location.origin + "/api/image/" + fileId + "?download");
            a.setAttribute('download', '');
            a.setAttribute('target', '_blank');
            a.click();
        }, index * 500);
    })
};

export const downloadAuthorImages = _download()
export const downloadAdvertisementImages = _download()
export const downloadIllustrationImages = _download()

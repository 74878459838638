import React from "react";
import {useSelector} from "react-redux";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {
    authorIdSelector,
    authorIsSavedSelector,
    fullNameStaticSelector
} from "../../../redux/selectors/author.selectors";

type Props = {
    form?: boolean
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2)
    },
}));

export default ({form}: Props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const authorId = useSelector(authorIdSelector)
    const isAuthorSaved = useSelector(authorIsSavedSelector)

    const fullNameStatic = useSelector(fullNameStaticSelector)

    const action = isAuthorSaved ? 'misc.edit' : 'authors.new';

    return (
        <Breadcrumbs aria-label="breadcrumb" separator="›" className={classes.root}>
            <Link color="inherit" to={'/' + t('routes.author.root')} component={RouterLink}>
                {t('app.sections.authors')}
            </Link>

            {isAuthorSaved && <Link color="inherit"
                                    to={'/' + t('routes.author.root') + '/' + authorId}
                                    component={RouterLink}>
                {fullNameStatic}
            </Link>}


            {form && <Typography color="textPrimary">{t(action)}</Typography>}
        </Breadcrumbs>)
}

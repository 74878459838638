import {
    AuthorAddress,
    AuthorContacts,
    AuthorEmail,
    AuthorPhoneNumber,
    AuthorType,
    ContactTypes,
    CreateOrUpdateFullAuthor,
    MappedFullAuthor,
    PersistedAuthor
} from "../types/author";

import {DateTime} from "luxon";
import {AuthorSearchFilters, PublicationSearchFilters} from "../types/common-search";
import {notify} from "../redux/actions/notification.actions";
import {Dispatch} from "redux";
import {TFunction} from "i18next";
import {buildQueryParams, ParameterType, readQueryParams} from "./query-param.utils";
import { baseParams, unescapeHtml } from './common-utils'

export const isEmailEmpty = ({mail}: AuthorEmail): boolean => Boolean(mail);
export const isPhoneNumberEmpty = ({number}: AuthorPhoneNumber): boolean => Boolean(number);
export const isAddressEmpty = ({street, complement, postCode, city, country}): boolean => {
    return street || complement || postCode || city || country !== 'FRANCE'
}

type AuthorCopy = {
    id: string
    firstName?: string
    particle?: string
    lastName?: string
    functions?: string[]
}

export const copyToWord = (dispatch: Dispatch, t: TFunction, author: AuthorCopy) => {
    const text = [
        author.id,
        author.firstName,
        author.particle,
        author.lastName,
        ...(author?.functions ?? [])
    ].filter(str => str).join('\r\n');

    navigator.clipboard.writeText(unescapeHtml(text));
    dispatch(notify(t('authors.copyToWord'), {
        variant: 'success'
    }));
}

const params = {
    ...baseParams,
    query: {
        type: ParameterType.STRING
    },
    publicationPeriodicalFilter: {
        type: ParameterType.TOKEN_ARRAY
    },
    publicationDateFilter: {
        type: ParameterType.DATE_RANGE
    },
    cityFilter: {
        type: ParameterType.STRING_ARRAY
    },
    countryFilter: {
        type: ParameterType.STRING_ARRAY
    },
    withImageFilter: {
        type: ParameterType.BOOLEAN_ARRAY
    },
}

export function readAuthorQueryParams(search: string): AuthorSearchFilters {
    return readQueryParams(params, search);
}

export const buildAuthorQueryParams = (filters: PublicationSearchFilters): string => {
    return buildQueryParams(params, filters);
}

export default class AuthorUtils {

    static notYetImplemented = (dispatch: any) => {
        dispatch(notify("Cette fonctionnalité n'est pas implémentée dans ce lot.", {
            variant: 'warning'
        }));
    };

    static extractedContacts(payload: PersistedAuthor): AuthorContacts[] {
        return Object.values(ContactTypes).map(type => AuthorUtils.extractedContactsByType(type, payload))
    }

    static extractedContactsByType(type: string, payload: PersistedAuthor): AuthorContacts {
        return {
            contact: type,
            addresses: payload.addresses ? payload.addresses.filter(addresse => addresse.contact === type.toUpperCase()) : [],
            emails: payload.emails ? payload.emails.filter(email => email.contact === type.toUpperCase()) : [],
            phoneNumbers: payload.phoneNumbers ? payload.phoneNumbers.filter(phone => phone.contact === type.toUpperCase()) : []
        } as AuthorContacts;
    }

    static createFullAuthorFromFormData(data: any): CreateOrUpdateFullAuthor {
        const author = {} as CreateOrUpdateFullAuthor;

        author.emails = [];
        if (data.emails) {
            Object.entries(data.emails).forEach(entry => {
                const type = entry[0] as string;
                const emails = entry[1] as AuthorEmail[];
                emails.forEach((email) => {
                    author.emails.push({contact: type as ContactTypes, fav: email.fav, mail: email.mail})
                })
            })
        }

        author.modificationDate = DateTime.local()
        author.modificationUser = "TODO"

        author.phoneNumbers = [];
        if (data.phoneNumbers) {
            Object.entries(data.phoneNumbers).forEach(entry => {
                const type = entry[0] as string;
                const phones = entry[1] as AuthorPhoneNumber[];
                phones.forEach((phone) => {
                    author.phoneNumbers.push({
                        contact: type as ContactTypes,
                        fav: phone.fav,
                        countryCode: phone.countryCode,
                        number: phone.number,
                        type: phone.type
                    })
                })
            })
        }

        author.addresses = [];
        if (data.addresses) {
            Object.entries(data.addresses).forEach(entry => {
                const type = entry[0] as string;
                const addresses = entry[1] as AuthorAddress[];
                addresses.forEach((addresse) => {
                    author.addresses.push({
                        contact: type as ContactTypes,
                        fav: addresse.fav,
                        postCode: addresse.postCode,
                        country: addresse.country,
                        complement: addresse.complement,
                        city: addresse.city,
                        street: addresse.street
                    })
                })
            })
        }

        author.id = data.id;
        author.notes = data.notes;
        author.lastName = data.lastName;
        author.type = data.type;
        author.functions = data.functions || [];
        author.firstName = (data.type === AuthorType.ORGANISATION) ? "" : data.firstName

        author.particle = data.particle;
        author.profiles = data.profiles;

        author.images = [];

        return author;
    }

    static createMappedFullAuthor(author: PersistedAuthor): MappedFullAuthor {
        console.log("author", author)
        return {
            creationDate: author?.creationDate || "",
            creationUser: author?.creationUser || "",
            modificationDate: author?.modificationDate || "",
            modificationUser: author?.modificationUser || "",
            enable: author?.enable || false,
            firstName: author?.firstName || "",
            lastName: author?.lastName || "",
            particle: author?.particle || "",
            functions: author?.functions || [],
            type: author?.type || AuthorType.UNKNOWN,
            id: author?.id || "",
            notes: author?.notes || "",
            contacts: author != null ? AuthorUtils.extractedContacts(author) : [],
            images: author?.images || [],
            profiles: author?.profiles || [],
            lastArticlesModified: author?.lastArticlesModified,
            used: author?.used
        } as MappedFullAuthor;
    }

    static haveContactContent(contacts, contactType) {
        if (contacts) {
            let contact = contacts.find(c => c.contact === contactType);
            if (contact) {
                return contact.emails.length > 0 || contact.phoneNumbers.length > 0 || contact.addresses.length > 0;
            }
        }
        return false;
    }

    static haveContent(contact) {
        return contact && (contact.emails.length > 0 || contact.phoneNumbers.length > 0 || contact.addresses.length > 0);
    }
}

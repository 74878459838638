import React from "react";
import {useTranslation} from "react-i18next";
import {ArticleStatus} from "../../../types/article";
import {ADMIN, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {EditIcon} from "../../commons/icon/icons";
import Auth from "../../Auth";
import LinkButton from "../../commons/button/LinkButton";
import {BundleResponse} from "../../../types/bundle";

type Props = {
    bundle: BundleResponse,
    size?: 'small' | 'medium',
    className?: any,
}

function EditBundle({bundle, size, className}: Props) {
    const {t} = useTranslation()

    const linkButton = <LinkButton size={size}
                                   buttonClassName={className}
                                   to={`/${t('routes.bundle.root')}/${bundle.id}/${t('routes.article.edit')}`}
                                   title={t('bundles.edit')}
                                   icon={<EditIcon/>}/>
    if (bundle.status === ArticleStatus.IN_PROGRESS || bundle.status === ArticleStatus.READY) {
        return (
            <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR]}>
                {linkButton}
            </Auth>
        )
    } else if (bundle.status === ArticleStatus.PUBLISHED) {
        return (<Auth requiredRoles={[ADMIN]}>
            {linkButton}
        </Auth>)
    } else {
        return (<></>)
    }
}

export default EditBundle;
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import MoreIcon from "@material-ui/icons/MoreVert";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import Popover from '@material-ui/core/Popover';
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";
import {ListItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

type HamburttonLink = {
    route: string;
    label: string;
    display?: boolean
}

type HamburttonAction = {
    action: () => void;
    label: string;
    display?: boolean
}

export type HamburttonProps = {
    listButtonAction?: (HamburttonLink | HamburttonAction)[]
    buttonAction?: (HamburttonLink | HamburttonAction)
    actions?: (HamburttonLink | HamburttonAction)[]
    display?: boolean
}

const handleAction = (handleClose, action) => () => {
    handleClose()
    action()
}

const isLink = (hamburtton: HamburttonLink | HamburttonAction) => hamburtton.hasOwnProperty('route')

const mapHamburttonToComponent = (hamburtton: HamburttonLink | HamburttonAction) =>
    isLink(hamburtton) ? hamburttonLink(hamburtton as HamburttonLink) : hamburttonAction(hamburtton as HamburttonAction)


const hamburttonLink = (hamLink: HamburttonLink) => {
    if (hamLink.display === undefined || hamLink.display) {
        return (<Button variant="outlined" color="primary">
            <Link color="inherit"
                  to={hamLink.route}
                  component={RouterLink}>
                {hamLink.label}
            </Link>
        </Button>)
    }
}


const hamburttonAction = (hamAction: HamburttonAction) => {
    if (hamAction.display === undefined || hamAction.display) {
        return (<Button variant="outlined" color="primary" onClick={hamAction.action}>
            {hamAction.label}
        </Button>)
    }
}


const linkMenuItem = (action: HamburttonLink, handleClose, key) => {
    if (action.display === undefined || action.display) {
        return (<ListItem>
            <Link color="inherit" onClick={handleClose} key={key}
                  to={action.route}
                  component={RouterLink}>
                {action.label}
            </Link>
        </ListItem>)
    }
}


const actionMenuItem = (action: HamburttonAction, handleClose, key) => {
    if (action.display === undefined || action.display) {
        return (<ListItem>
            <Button fullWidth color="inherit" onClick={handleAction(handleClose, action.action)} key={key}>
                {action.label}
            </Button>
        </ListItem>)
    }
}


const useStyles = makeStyles(() => ({
    popper: {
        zIndex: 1
    }
}))

/**
 * Le Hamburtton est un bouton pouvant posséder une liste de "sous-boutons" dans un menu hamburger.
 *
 * Exemple d'utilisation:
 <Hamburtton
 buttonAction={{label: t('authors.create'), route: '/auteur/creer'}}
 actions={[
        {label: 'Alerte !', action: () => alert('alert !')}
    ]}/>
 */
export default (props: HamburttonProps) => {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const classes = useStyles()

    const handleToggle = (e) => {
        e.preventDefault();
        setOpen((prevOpen) => !prevOpen);
    };
    const handleClose = () => setOpen(false);

    const haveLeftButton = (props.display === undefined || props.display) && props.buttonAction

    return (<>
        <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button">
            {props.listButtonAction && props.listButtonAction.map(e => mapHamburttonToComponent(e))}
            {haveLeftButton && mapHamburttonToComponent(props.buttonAction!!)}
            {(props.actions && props.actions.length > 0) &&
            <Tooltip title={t('misc.moreActions') ?? ''}>
                <Button
                    color="primary"
                    size="small"
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={e => handleToggle(e)}>
                    <MoreIcon/>
                </Button>
            </Tooltip>
            }
        </ButtonGroup>
        {(props.actions && props.actions.length > 0) &&
        <Popover open={open} anchorEl={anchorRef.current} role={undefined} disablePortal
                 className={classes.popper} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                 }}>
            <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                    {
                        (props.actions ?? []).map((action, idx) => {
                            if (action.hasOwnProperty('route'))
                                return linkMenuItem(action as HamburttonLink, handleClose, idx)
                            else return actionMenuItem(action as HamburttonAction, handleClose, idx)
                        })
                    }
                </MenuList>
            </ClickAwayListener>
        </Popover>
        }
    </>)
}

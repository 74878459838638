import React from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {ImageSearchFilters, SearchContext} from "../../../types/common-search";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "../../commons/ListItem";
import {
    addSearchQueryParam,
    removeSearchQueryParam,
    resetSearchQueryParams,
    updateSearchQueryParam
} from "../../../redux/actions/common-search.actions"
import {makeStyles} from "@material-ui/core/styles";
import PublishedIn from "../../commons/PublishedIn";
import DateRangePicker from "../../commons/DateRangePicker";
import CollectionFilter from "./CollectionFilter";
import TypeFileFilter from "./TypeFileFilter";
import CharacteristicFilter from "./CharacteristicFilter";
import Button from "@material-ui/core/Button";
import {ClearIcon} from "../../../icon";
import EnableFilter from "./EnableFilter";
import {maxDate, minDate} from "../../../utils/common-utils";

type Props = {
    filters: ImageSearchFilters
}

const useStyles = makeStyles(() => ({
    paper: {
        borderBottom: "none"
    }
}))

const ImageFilters = ({filters}: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <>
            <Button onClick={() => dispatch(resetSearchQueryParams(SearchContext.IMAGE))}
                    startIcon={<ClearIcon/>}>{t('misc.reset')}</Button>

            <Paper variant="outlined" square className={classes.paper}>
                <List component="div" disablePadding>
                    <ListItem label={t('misc.edit_date')}>
                        <DateRangePicker
                            end={filters.modificationDateFilter?.end}
                            start={filters.modificationDateFilter?.start}
                            onDatesChange={(values) => dispatch(updateSearchQueryParam({
                                ctx: SearchContext.IMAGE,
                                field: "modificationDateFilter",
                                value: values
                            }))}
                            minDate={minDate}
                            maxDate={maxDate}
                        />
                    </ListItem>

                    <PublishedIn
                        publicationPeriodicalFilter={filters.publicationPeriodicalFilter ?? []}
                        ctx={SearchContext.IMAGE}
                    />

                    <TypeFileFilter mimeTypeFilter={filters.mimeTypeFilter}/>

                    <CharacteristicFilter selectedAction={updateSearchQueryParam}
                                          characteristicFilter={filters.characteristicFilter}/>

                    <ListItem label={t('images.collection.name')}>
                        <CollectionFilter values={filters.collectionFilter ?? []}/>
                    </ListItem>

                    <EnableFilter removeElementAction={removeSearchQueryParam} addElementAction={addSearchQueryParam}
                                  filter={filters.enableFilter} context={SearchContext.IMAGE}/>
                </List>
            </Paper>
        </>
    )
}

export default ImageFilters

import React from "react";
import {useDispatch} from "react-redux";
import {addSearchQueryParam, removeSearchQueryParam} from "../../../redux/actions/common-search.actions";
import Checkbox from '@material-ui/core/Checkbox';
import {useTranslation} from "react-i18next";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {SearchContext} from "../../../types/common-search";

type Props = {
    values: string[]
}

const CollectionFilter = ({values}: Props) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleChange = (value: string, checked: boolean) => {
        if (checked) {
            if (!values.includes(value))
                dispatch(addSearchQueryParam({
                    ctx: SearchContext.IMAGE,
                    field: 'collectionFilter',
                    value: value
                }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.IMAGE,
                field: 'collectionFilter',
                value: value
            }))
        }
    };
    return (
        <>
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange("AUTHOR", check)}
                        checked={values && values.some((e) => e === "AUTHOR")}
                    />}
                    label={t('images.collection.author')}
                />
            </FormGroup>

            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange("ILLUSTRATION", check)}
                        checked={values && values.some((e) => e === "ILLUSTRATION")}
                    />}
                    label={t('images.collection.illustration')}
                />
            </FormGroup>
        </>
    )
};

export default CollectionFilter

import axios, {AxiosStatic} from "axios";
import {EMPTY, merge, Observable, of} from "rxjs";
import {Action} from "../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, filter, flatMap, map, mergeMap} from "rxjs/operators";
import {notify, notifyError, notifySuccess} from "../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../reducers";
import {EpicDependencies} from "../store";
import {
    fetchArticleOfPublication,
    fetchImageOfPublication,
    fetchPublication,
    fetchPublicationError,
    fetchPublicationPlanError,
    fetchPublicationPlanSuccess,
    fetchPublicationSuccess,
    isFetchPublicationAction,
    isFetchPublicationPlanAction,
    isSavePublicationPlanAction,
    isUploadPublicationsAction,
    savePublicationPlanError,
    savePublicationPlanSuccess
} from "../actions/publication.actions";
import {PlanNode} from "../../types/plan-node";
import i18next, {i18n} from "i18next";
import {search} from "../actions/common-search.actions";
import {Filters, SearchContext} from "../../types/common-search";
import React from "react";
import ImportPublicationReportNotification from "../../components/commons/ImportPublicationReportNotification";
import {DateTime} from "luxon"


function _savePlan(axios: AxiosStatic, {
    plan,
    publicationId,
    filters
}: { plan: PlanNode[], publicationId: string, filters?: Filters }, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    let actions = [savePublicationPlanSuccess(), fetchPublication(publicationId),
        notifySuccess(i18next.t('misc.save_plan_success'))]

    if (filters)
        actions = [...actions, search({
            ctx: SearchContext.PUBLICATION_ARTICLE,
            filters: filters
        })]

    return fromPromise(axios.put(`/api/publication/${publicationId}/organize`, plan, {headers}))
        .pipe(
            mergeMap(() => of(
                ...actions
            )),
            catchError(err => merge(
                of(savePublicationPlanError()),
                of(notifyError(+err.response.status, {type: 'PUBLICATION'}))
            ))
        )
}

function _fetchPlan(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/publication_plan/publication/${id}`, {headers}))
        .pipe(
            map((result) => fetchPublicationPlanSuccess(result.data)),
            catchError(err => merge(
                of(fetchPublicationPlanError()),
                of(notifyError(+err.response.status, {type: 'PUBLICATION'}))
            ))
        )
}

function _fetch(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/publication/${id}`, {headers}))
        .pipe(
            mergeMap((result) =>
                merge(
                    of(fetchPublicationSuccess(result.data)),
                    result.data.imageId ? _fetchImage(axios, result.data.imageId, token) : EMPTY,
                    result.data.articleId ? _fetchArticle(axios, result.data.articleId, token) : EMPTY
                )
            ),
            catchError(err => merge(
                of(fetchPublicationError()),
                of(notifyError(+err.response.status, {type: 'PUBLICATION'}))
            ))
        )
}


function _fetchImage(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/illustration/${id}`, {headers}))
        .pipe(
            map((result) => fetchImageOfPublication(result.data))
        )
}

function _fetchArticle(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };
    return fromPromise(axios.get(`/api/articleBase/${id}`, {headers}))
        .pipe(
            map((result) => fetchArticleOfPublication(result.data))
        )
}

export function savePublicationPlanEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSavePublicationPlanAction),
        flatMap((action) => _savePlan(axios,
            action.payload,
            state$.value.auth.token ?? "")
        )
    )
}

export function fetchPublicationPlanEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchPublicationPlanAction),
        flatMap((action) => _fetchPlan(axios,
            action.payload,
            state$.value.auth.token ?? "")
        )
    )
}

export function fetchPublicationEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchPublicationAction),
        flatMap((action) => _fetch(axios,
            action.payload,
            state$.value.auth.token ?? "")
        )
    )
}

function _uploadPublications(axios: AxiosStatic, i18n: i18n, files: FileList, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data'
    };

    const data = new FormData()
    data.append("file", files[0])

    return fromPromise(axios.post(`/api/publication/import`, data, {headers}))
        .pipe(
            map(() =>
                notify(i18n.t('publications.import_success'), {variant: "success"})
            ),
            catchError(err => {
                    return merge(of<Action>(
                        notify(
                            "",
                            {
                                persist: true,
                                content: (key) => (
                                    <ImportPublicationReportNotification id={key} importResult={
                                        Object.keys(err.response.data).filter((key) => key !== 'message')
                                            .map((key) => key + ' : ' + err.response.data[key])
                                    }/>
                                )
                            })
                    ))
                }
            ))
}

export function uploadPublicationsEpic(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios, i18n} = dependencies;
    return action$.pipe(
        filter(isUploadPublicationsAction),
        mergeMap((action) => merge(
            of(notify("Import en cours", {variant: "info"})),
            _uploadPublications(axios, i18n, action.payload, state$.value.auth.token ?? "")))
    )
}

export function closePublicationEpic(id: number, token: string, date?: DateTime) {
    return axios.post("/api/publication/schedule", {id, date: date?.toISO({includeOffset: false})}, {headers: {
			Authorization: `Bearer ${token}`
	}})
}

import React from "react";
import {useTranslation} from "react-i18next";
import ListItem from "../../commons/ListItem";
import {addSearchQueryParam, removeSearchQueryParam} from "../../../redux/actions/common-search.actions";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch} from "react-redux";
import {SearchContext} from "../../../types/common-search";

const TypeFileFilter = ({mimeTypeFilter}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const handleChange = (value: string, checked: boolean) => {
        if (checked) {
            dispatch(addSearchQueryParam({
                ctx: SearchContext.IMAGE,
                field: 'mimeTypeFilter',
                value: value
            }))
        } else {
            dispatch(removeSearchQueryParam({
                ctx: SearchContext.IMAGE,
                field: 'mimeTypeFilter',
                value: value
            }))
        }
    }

    return (
        <ListItem label={t('images.file_type.name')}>
            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange("JPG", check)}
                        checked={mimeTypeFilter.some((e) => e === "JPG")}
                    />}
                    label={t('images.file_type.jpg')}
                />
            </FormGroup>

            <FormGroup row>
                <FormControlLabel
                    control={<Checkbox
                        onChange={(event, check) => handleChange("PNG", check)}
                        checked={mimeTypeFilter.some((e) => e === "PNG")}
                    />}
                    label={t('images.file_type.png')}
                />
            </FormGroup>
        </ListItem>
    )
}

export default TypeFileFilter

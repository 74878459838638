import React from 'react'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {commonStyle} from "../../../styles/common.style";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {State} from "../../../redux/reducers";
import {Typography} from "@material-ui/core";

export default function PublicationsBreadcrumbs() {
    const {t} = useTranslation();
    const classes = commonStyle();

    const publication = useSelector(getCurrentPublication)
    const publicationName = `${publication?.periodicalId}_${publication?.year}_${publication?.number}`

    const periodical = useSelector(({periodical}: State) => periodical.periodicals).find(periodical => periodical.id === publication?.periodicalId)

    const periodicalLink = <Link key={0} color="inherit"
                                 to={'/' + t('routes.publication.root') + '?periodicalIdFilter=' + periodical?.id}
                                 component={RouterLink}>
        {periodical?.label}
    </Link>
    const publicationLink = publication ? [
        <Link key={1} color="inherit"
              to={'/' + t('routes.publication.root') + '/' + publication.id + '/' + t('routes.publication.organize')}
              component={RouterLink}>
            {publicationName}
        </Link>
    ] : [];

    const organizeLink = (<Typography key={2}>
        Organiser le chemin de fer
    </Typography>);

    return <Breadcrumbs aria-label="breadcrumb" separator="›" className={classes.root} style={{marginLeft: 10}}>
        {[periodicalLink, [...publicationLink], organizeLink]}
    </Breadcrumbs>
}
import React from 'react'
import {useTranslation} from "react-i18next";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {commonStyle} from "../../../styles/common.style";
import {ArticleResponse} from "../../../types/article";
import Typography from "@material-ui/core/Typography";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {cleanHtml, createHtml} from "../../../utils/common-utils";

type Props = {
    article: ArticleResponse
}

export default function ArticleViewBreadcrumbs({article}: Props) {
    const {t} = useTranslation();
    const classes = commonStyle();

    return <>
        <Breadcrumbs aria-label="breadcrumb" separator="›" className={classes.root}>
            <Typography color="textPrimary">
                {article.periodical.id}
            </Typography>

            {article.publication &&
            <Link color="inherit"
                  to={'/' + t('routes.publication.root') + '/' + article.publication.id + '/' + t('routes.publication.organize')}
                  component={RouterLink}>
                {article.publication.periodicalId}_{article.publication.year}_{article.publication.number}
            </Link>}

            <Typography color="textPrimary">
                {article.id} {cleanHtml(article.title)}
            </Typography>
        </Breadcrumbs>
    </>
}

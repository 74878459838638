import React, { CSSProperties, useContext } from "react"
import { DocumentUnitContext, SearchContext } from "../../../contexts/organizeTreeBibleContexts"
import { useHandleSearchChange } from "../../../hooks/searchHooks"
import {
    DEFAULT_PAGE,
    DEFAULT_PAGINATION, 
    DEFAULT_SIZE, 
    setPageNumber, 
    setPageSizeFromString
} from "../../../models/Pageable"
import TablePagination from '@material-ui/core/TablePagination';

export const Pagination = () => {
    const [searchState] = useContext(SearchContext)
    const [documentUnitState] = useContext(DocumentUnitContext)
    const handleChange = useHandleSearchChange()
    return (
        <TablePagination
            component="div"
            labelRowsPerPage="Lignes par page : "
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
            style={{ display: 'flex', justifyContent: 'flex-end', borderBottom: 'none' }}
            rowsPerPageOptions={DEFAULT_PAGINATION}
            count={documentUnitState?.data?.totalElements ?? 0}
            rowsPerPage={documentUnitState?.data?.size ?? DEFAULT_SIZE}
            page={documentUnitState?.data?.number ?? DEFAULT_PAGE}
            onRowsPerPageChange={({ target: { value } }) => handleChange(searchState, setPageSizeFromString(value)(documentUnitState?.data))}
            onPageChange={(_, page) => handleChange(searchState, setPageNumber(page)(documentUnitState?.data))} />
    )
}
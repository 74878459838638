import React, {useEffect, useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from "@material-ui/core/Grid";
import {ImageColor, ImageFraming} from "../../../../types/image";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import {State} from "../../../../redux/reducers";
import {Field, updateFieldInput} from "../../../../redux/actions/author.actions";
import {koToMo} from "../../../../utils/image.utils";
import {Divider, Select as MuiSelect, Typography} from "@material-ui/core";
import DateTime from "../../../commons/DateTime";
import Wysiwyg2 from "../../../articles/create/Wysiwyg2";
import {ContentState, EditorState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import {Controller} from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    root: {},
    label: {
        lineHeight: 3
    },
    modalImageBody: {
        display: "flex",
        flexDirection: "row"
    },
    modalImageBodyListItem: {
        listStyle: "none",
        padding: 0,
        listStyleType: "none"
    },
    modalImageLabel: {
        fontWeight: "bold"
    },
    section1: {
        marginBottom: theme.spacing(1)
    },
    section2: {
        marginTop: theme.spacing(2)
    }
}));

type Props = {
    index: number
    register: any
    errors: any
    control: any
}

const ImagesFormItemForm = ({index, register, errors, control}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const image = useSelector(({author}: State) => author.current!.images[index]);
    const {id, fav, format, color, framing, sources, comments, credits, meta} = image;
    const [creditsState, setCreditsState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(credits).contentBlocks)))
    const [sourcesState, setSourcesState] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(sources).contentBlocks)))

    useEffect(() => {
        setCreditsState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(credits).contentBlocks)))
        setSourcesState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(sources).contentBlocks)))
        // eslint-disable-next-line
    }, [index])

    const [colorState, setColorState] = useState<ImageColor | undefined | null>(color)
    const [framingState, setFramingState] = useState<ImageFraming | undefined | null>(framing)

    useEffect(() => {
        setColorState(color)
    }, [color])

    useEffect(() => {
        setFramingState(framing)
    }, [framing])

    const name = id + '.' + meta.contentType.toLowerCase();

    const update = (input) => (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value;
        dispatch(updateFieldInput({field: Field.IMAGE, index, input, value}))
    }

    const updateSource = (input: string, text: string) => {
        dispatch(updateFieldInput({
            field: Field.IMAGE,
            index,
            input,
            value: text
        }));
    }

    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.section1}>
                <Input type="hidden" inputRef={register()} name={`images[${index}].fav`} value={fav}/>
                <Input type="hidden" inputRef={register()} name={`images[${index}].format`} value={format}/>
                <Grid item xs={4}>
                    <InputLabel className={classes.label}>{t('misc.name')}</InputLabel>
                </Grid>
                {name && <>
                    <Grid item xs={6}>
                        <TextField fullWidth={true}
                                   disabled={true}
                                   inputRef={register({required: false})}
                                   value={name}
                                   name={`images[${index}].name`}/>
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel className={classes.label}>{t('authors.id')}</InputLabel>
                    </Grid>
                </>}
                <Grid item xs={6}>
                    <TextField fullWidth={true}
                               disabled={true}
                               value={id}
                               inputRef={register()}
                               name={`images[${index}].id`}/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel className={classes.label}>{t('misc.format')}</InputLabel>
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth={true}
                               value={t(`enums.images.format.${format}.main`)}
                               name={`images[${index}].format_label`}/>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel className={classes.label}>{t('images.color')} *</InputLabel>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                        {colorState !== null &&
                        <Controller
                            render={() =>
                                <MuiSelect
                                    value={color ?? ''}//data ? data.value ?? '' : []
                                    name={name}
                                    fullWidth={true}
                                    error={errors[`images[${index}].color`] !== undefined}
                                    onChange={update('color')}>
                                    {Object.values(ImageColor).map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {t(`enums.images.color.${type}.main`)}
                                        </MenuItem>
                                    ))}
                                </MuiSelect>
                            }
                            name={`images[${index}].color`}
                            control={control}
                            defaultValue={color ?? ''}
                        />
                        }
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <InputLabel htmlFor="type" className={classes.label}>{t('images.type')} *</InputLabel>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                        {framingState !== null &&
                        <Controller
                            render={() =>
                                <MuiSelect
                                    value={framing ?? ''}//data ? data.value ?? '' : []
                                    name={name}
                                    fullWidth={true}
                                    error={errors[`images[${index}].framing`] !== undefined}
                                    onChange={update('framing')}>
                                    {Object.values(ImageFraming).map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {t(`enums.images.framing.${type}.main`)}
                                        </MenuItem>
                                    ))}
                                </MuiSelect>
                            }
                            name={`images[${index}].framing`}
                            control={control}
                            defaultValue={framing ?? ''}
                        />
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <InputLabel className={classes.label} color={"secondary"}>
                        {t('authors.credits')}
                    </InputLabel>
                </Grid>

                <Grid item xs={6} style={{marginTop: 10}}>
                    <Wysiwyg2 name={"credits"} required={false}
                              control={control} state={creditsState} setState={setCreditsState}
                              onChange={(text) => updateSource('credits', text)}
                              withBold={false}/>
                </Grid>

                <Grid item xs={4}>
                    <InputLabel className={classes.label} color={"secondary"}>
                        {t('authors.sources')}
                    </InputLabel>
                </Grid>

                <Grid item xs={6} style={{marginTop: 15}}>
                    <Wysiwyg2 name={"sources"} required={false} setState={setSourcesState}
                              control={control} state={sourcesState} onChange={(text) => updateSource('sources', text)}
                              withBold={false}/>
                </Grid>

                <Grid item xs={4} style={{marginTop: 10}}>
                    <InputLabel htmlFor="my-input" className={classes.label}>{t('misc.comments')}</InputLabel>
                </Grid>

                <Grid item xs={6} style={{marginTop: 10}}>
                    <TextField color={"secondary"}
                               name={`images[${index}].comment`}
                               inputRef={register({required: false})}
                               multiline
                               fullWidth={true}
                               value={comments}
                               onChange={update('comments')}/>
                </Grid>
            </Grid>

            <Divider variant="middle"/>

            <Grid container className={classes.section2}>
                {
                    // todo: refactor pour éviter la duplication
                    // dupliqué aussi dans ImageAuthor.tsx
                }
                <Grid item xs={12}>
                    <Typography variant={"h6"}>Informations</Typography>
                </Grid>

                <Grid item xs={6} container direction={"column"} spacing={1}>
                    {[
                        [t("images.type_file"), image.meta.contentType],
                        [t("misc.resolution"), `${image.meta.width} x ${image.meta.height} px`],
                    ]
                        .map(([label, value]) =>
                            <Grid item key={"" + label}>
                                <div className={classes.modalImageLabel}>
                                    {label}
                                </div>
                                <div>
                                    {value}
                                </div>
                            </Grid>)
                    }
                </Grid>
                <Grid item xs={6} container direction={"column"} spacing={1}>
                    {[

                        [t("misc.modification_date"), <DateTime date={image.modificationDate}/>],
                        [t("misc.file_size"), koToMo(image.meta.weight)],
                    ]
                        .map(([label, value]) =>
                            <Grid item key={"" + label}>
                                <div className={classes.modalImageLabel}>
                                    {label}
                                </div>
                                <div>
                                    {value}
                                </div>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    )

}

export default ImagesFormItemForm;

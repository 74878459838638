import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    currentAdminSelectedPeriodicalDirtySelector,
    currentAdminSelectedPeriodicalSelector
} from "../../../../redux/selectors/admin/periodical/admin-publication.selectors";
import {currentPeriodicalsSelector} from "../../../../redux/selectors/periodical.selectors";
import {setAdminPeriodical} from "../../../../redux/actions/admin/periodical/admin-periodical.actions";
import {Grid} from "@material-ui/core";
import LexForm from "../../../commons/forms/LexForm";
import {selectRenderer} from "../../../commons/forms/LexFormField";
import {GridSize} from "@material-ui/core/Grid";
import ConfirmationDialog from "../../../commons/ConfirmationDialog";
import {useTranslation} from "react-i18next";
import {Periodical} from "../../../../types/periodical";

const PeriodicalOrganizeSelect = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [modalVisible, setModalVisible] = useState(false);
    const [selectChangeEvent, setSelectChangeEvent] = useState<React.FormEvent<HTMLInputElement> | undefined>(undefined);

    const periodicals = useSelector(currentPeriodicalsSelector);
    const currentPeriodical = useSelector(currentAdminSelectedPeriodicalSelector);
    const isDirty = useSelector(currentAdminSelectedPeriodicalDirtySelector);

    const onSelectChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSelectChangeEvent(e);
        if (isDirty && currentPeriodical !== undefined) {
            setModalVisible(true);

            return true;
        }

        return false;
    }

    const onSelectChangeConfirmed = () => {
        if (selectChangeEvent) {
            // We forward the event that we blocked previously
            const periodical = (selectChangeEvent.target as HTMLInputElement).value as unknown as Periodical;
            dispatch(setAdminPeriodical(periodical))
        }
    }

    return (
        <Grid xs={12}>
            <ConfirmationDialog
                title={t('authors.edit_cancel_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default'},
                    {label: t('dialogs.confirm'), action: onSelectChangeConfirmed}
                ]}
                isOpened={modalVisible}
                onClose={() => setModalVisible(false)}
            />

            <LexForm
                type={"admin.periodical"}
                fields={[
                    {
                        name: "periodicalId",
                        selector: currentAdminSelectedPeriodicalSelector,
                        action: setAdminPeriodical,
                        renderer: selectRenderer(periodicals.map(e => {
                            return {
                                label: `[${e.id}] ${e.label}`,
                                value: e,
                                keyValue: `opt ${e.id}`
                            }
                        }), [], onSelectChange),
                        disabled: false,
                        required: true,
                        column: 4 as GridSize,
                        shouldNotTranslate: true
                    }
                ]}
            />
        </Grid>
    );
}

export default PeriodicalOrganizeSelect;

import React, { useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteAuthor, fetchAuthor, toggleAuthorEnabled } from "../../../redux/actions/author.actions";
import { State } from "../../../redux/reducers";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import AuthorUtils, { copyToWord } from "../../../utils/author-utils";
import { AuthorContacts, AuthorType, MappedFullAuthor, PersistedAuthor } from "../../../types/author";
import { useTranslation } from "react-i18next";
import AuthorBreadcrumbs from "../commons/AuthorBreadcrumbs";
import AuthorNotes from "./AuthorNotes";
import RightPanelEditAuthor from "../commons/RightPanel";
import ContactAuthorPanel from "../commons/ContactAuthorPanel";
import FunctionAuthorContent from "./FunctionAuthorContent";
import ProfileAuthorContent from "./ProfileAuthorContent";
import Error from "../../commons/Error";
import Loader from "../../commons/Loader";
import Hamburtton from "../../commons/Hamburtton";
import ImagesAuthorPanel from "../commons/ImagesAuthorPanel";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import Auth from "../../Auth";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyIcon } from "../../../icon";
import IconButton from "@material-ui/core/IconButton";
import { ADMIN, ADMIN_AUTHORS } from "../../../utils/roles";
import Toggler from "../../commons/Toggler";
import { viewPageStyle } from "../../../styles/view-page.style";
import { AuthorStateStatus } from "../../../redux/reducers/author.reducer";
import { downloadAuthorsCSV } from "../../../utils/common-utils";

export default function ShowAuthorView() {
    const history = useHistory()
    const classes = viewPageStyle()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const { id } = useParams() as { id: string }

    useEffect(() => {
        dispatch(fetchAuthor({ id }))
    }, [dispatch, id]);

    const [status, author]: [AuthorStateStatus, MappedFullAuthor] = useSelector(({ author }: State) => [author.status, AuthorUtils.createMappedFullAuthor(author.current as PersistedAuthor)])

    const [dialogOpen, openDialog] = useState(false);
    const closeDialog = () => openDialog(false)

    const onAuthorDeletion = (id: string) => {
        dispatch(deleteAuthor({ id, refresh: false }));
        history.push('/auteur');
    }

    const handleCopy = () => {
        copyToWord(dispatch, t, author);
    };

    const shouldShowFunctions = author.functions.length > 0
    const shouldShowImages = author.images.length > 0
    const shouldShowProfiles = author.profiles.length > 0


    const commonActions = [ { label: t("misc.export"), action: () => downloadAuthorsCSV([id]) } ]

    if (status === 'LOADED' && author) {
        return (
            <div className={classes.viewRoot}>
                <Grid container className={classes.header}>
                    <Grid item xs={9}>
                        <Typography variant="h4">
                            <span>{author.type === AuthorType.ORGANISATION ? author.lastName : t('misc.gender.' + (author.type === AuthorType.MALE ? 'male' : 'female') + '.long') + " " + author.firstName + " " + (author.particle ? author.particle + " " : "") + author.lastName}</span>
                            <IconButton onClick={handleCopy}>
                                <Tooltip title={t('misc.copy_to_word') || ""}>
                                    <CopyIcon />
                                </Tooltip>
                            </IconButton>
                        </Typography>
                        <AuthorBreadcrumbs />
                    </Grid>

                    <Grid item xs={3}>
                        <Auth requiredRoles={[ADMIN, ADMIN_AUTHORS]}>
                            <Grid container className={classes.delete}>
                                <Hamburtton
                                    buttonAction={{
                                        label: t('authors.edit'),
                                        route: `/${t('routes.author.root')}/${author.id}/${t('routes.author.edit')}`
                                    }}
                                    actions={commonActions}
                                />
                            </Grid>
                        </Auth>
                    </Grid>
                </Grid>

                <Grid container spacing={8}>
                    <Grid item xs={9}>
                        {/* <AuthorNotes notes={author.notes} /> */}
                        {shouldShowImages && <ImagesAuthorPanel />}
                        {author.contacts.flatMap((c: AuthorContacts) =>
                            AuthorUtils.haveContent(c) && <ContactAuthorPanel contact={c} key={c.contact} />)}
                        {shouldShowFunctions && <FunctionAuthorContent functions={author.functions} />}
                        {shouldShowProfiles && <ProfileAuthorContent profiles={author.profiles} />}
                    </Grid>

                    <Grid item xs={3}>
                        <RightPanelEditAuthor />
                    </Grid>
                </Grid>
            </div>)
    } else if (status === 'ERROR') {
        return (<Error />)
    } else {
        return (<Loader />)
    }
}

import { IconButton, Tooltip } from "@material-ui/core"
import { GeneratePdfIcon } from "./commons/icon/icons"
import React from "react"
import { useDispatch } from "react-redux"
import { uuidv4 } from "../utils/uuid"
import { dispatchArticleAction } from "../redux/actions/workflow.actions"
import { ArticleActionName } from "../types/workflow"
import { useTranslation } from "react-i18next"
import { FontoPdfArticleProps } from "./GeneratePlacardButton"


export const FontoPdfArticle = ({id}: FontoPdfArticleProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()


  const generatePDF = (articleId: string) => {
    const uuid = uuidv4();

    dispatch(dispatchArticleAction({
      id: articleId,
      action: ArticleActionName.GENERATE_ARTICLE_PLACARD,
      uuid
    }))
  }

  return (
    <Tooltip title={t('workflows.generate_placard') ?? ''}>
      <IconButton color={"inherit"}
        onClick={() => {
          generatePDF(id)
        }}>
        <GeneratePdfIcon color={"inherit"} />
      </IconButton>
    </Tooltip>
  )
}
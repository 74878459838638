import { Divider } from "@material-ui/core";
import React, { CSSProperties, useContext, useState } from 'react';

import { SearchContext } from "../../../contexts/organizeTreeBibleContexts";
import { SimpleFilters } from "./SimpleFilters";
import { AdvancedFilters } from "./AdvancedFIlters";
import { KyrDivider } from "../../../components/KyrDivider";

export const paddingHorizontal = 10
export const filterBoxPadding: CSSProperties = { paddingLeft: paddingHorizontal, paddingRight: paddingHorizontal }

export const Header = () => {
  const [searchState] = useContext(SearchContext)
  const [moreFilterIsOpen, setMoreFilterIsOpen] = useState(false)

  return (
    <>
      <SimpleFilters moreFilterIsOpen={moreFilterIsOpen} setMoreFilterIsOpen={setMoreFilterIsOpen} />
      {moreFilterIsOpen && (
        <>
          <KyrDivider />
          <AdvancedFilters source={searchState?.type} />
        </>
      )}
    </>
  )
}

import {DateTime} from "luxon";

export enum ImageFormat {
    THUMBNAIL = 'IMG_THUMBNAIL',
    LARGE = 'IMG_LARGE'
}

export enum ImageFraming {
    PORTRAIT = 'PORTRAIT',
    SKETCH = 'SKETCH',
    WIDE_PLAN = 'WIDE_PLAN'
}

export enum ImageQuality {
    OK = 'OK',
    BAD = 'BAD'
}

export enum ImageColor {
    COLOR = 'COLOR',
    N_AND_ONE_COLOR = 'N_AND_ONE_COLOR',
    BLACK_AND_WHITE = 'BLACK_AND_WHITE'
}

export type B64Image = {
    name?: string
    data: string
}

export type DeleteOrDisabledImage = {
    disabled: string[],
    deleted: string[]
}

export type SavedImage = {
    id: string
    format: ImageFormat
    meta: MetadataImage,
    version: number
}

export type MetadataImage = {
    contentType: string,
    weight: number,
    width: number,
    height: number,
    xdpi: number,
    ydpi: number
}

export type AuthorImage = {
    id: string,
    fav: boolean,
    meta: MetadataImage,
    used: boolean,
    disabled: boolean,
    format: ImageFormat,
    color?: ImageColor,
    framing?: ImageFraming,
    credits: string,
    sources: string,
    comments: string,
    uploadDate: string,
    modificationDate: string
}

export type LinkImage = {
    title: string,
    modificationDate: DateTime,
    articleId: string
    type: string
}

export type IllustrationImage = {
    id: string,
    name: string,
    comments: string,
    format?: ImageFormat,
    color?: ImageColor,
    tags: string[],
    quality?: ImageQuality,
    credits: string,
    sources: string,
    enable: boolean
    disabled: boolean // todo : refacto pour n'en garder qu'un
    collection?: string,
    uploadDate?: string,
    modificationDate?: string,
    meta?: {
        contentType: string,
        weight: number,
        width: number,
        height: number,
        xdpi: number,
        ydpi: number
    },
    authorId?: string,
    characteristics?: string[],
    lastArticlesModified?: LinkImage[],
    _hash?: string,
    version: number
}

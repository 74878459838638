import {makeStyles} from "@material-ui/core/styles";
import {lighten} from "@material-ui/core";

export const gridStyle = makeStyles((theme) => ({
    tile: {
        maxWidth: 380
    },
    media: {
        height: 240,
        cursor: "pointer"
    },
    'image-infos-container': {
        display: "flex",
        width: '100 %',
        height: '100%',
        flexDirection: "column",
        justifyContent: "flex-end"
    },
    'image-details': {
        'background-color': "rgba(210,210,210,.5)",
        'font-size': "small",
        border: 0,
        width: "100%",
        color: "black",
    },
    title: {
        fontWeight: 500,
        fontSize: '0.9rem',
        color: 'rgba(0, 0, 0, 0.75)'
    },
    imageInfoDiv: {
        overflowWrap: "break-word"
    },
    check: {
        position: "absolute",
        top: 0,
        left: 0
    },
    actionGroup: {
        background: lighten(theme.palette.secondary.main, 0.85)
    },
    details: {
        "padding": "5px"
    },
    zoom: {
        position: "absolute",
        top: 215,
        right: 5
    },
    center: {
        display: "flex",
        justifyContent: "center"
    }
}));

import {Action} from "../../actions/utils.actions";
import {
    isFetchPublicationPlanSuccessAction,
    isFetchPublicationSuccessAction,
    isResetPublicationAction,
    isSavePublicationPlanSuccessAction,
    isSetDirtyAction
} from "../../actions/publication.actions";
import {Publication} from "../../../types/publication";
import {PlanNode} from "../../../types/plan-node";

type OrganizePublicationState = {
    current?: Publication
    currentPlan?: PlanNode[]
    isDirty: boolean
}

const init = {
    isDirty: false
}

const reducer = (state = init, action: Action): OrganizePublicationState => {
    if (isFetchPublicationSuccessAction(action)) {
        return {
            ...state,
            isDirty: false,
            current: action.payload
        }
    } else if (isFetchPublicationPlanSuccessAction(action)) {
        return {
            ...state,
            isDirty: false,
            currentPlan: action.payload
        }
    } else if (isResetPublicationAction(action)) {
        return {
            isDirty: false,
            currentPlan: undefined,
            current: undefined
        }
    } else if (isSavePublicationPlanSuccessAction(action)) {
        return {
            ...state,
            isDirty: false
        }
    } else if (isSetDirtyAction(action)) {
        return {
            ...state,
            isDirty: action.payload
        }
    }
    else {
        return state
    }
}

export default reducer

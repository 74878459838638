import {ActionWithPayload, createActionWithoutPayload, createActionWithPayload} from "./utils.actions";
import {PlanNode} from "../../types/plan-node";
import {Bundle, BundlePlanResponse, BundleResponse} from "../../types/bundle";
import {Filters, SearchContext} from "../../types/common-search";

export const [fetchBundlePlansOfPeriodical, isFetchBundlePlansOfPeriodicalAction] = createActionWithPayload<string>('FETCH_BUNDLE_PLANS_PERIODICAL');
export const [fetchBundlePlansOfPeriodicalSuccess, isFetchBundlePlansOfPeriodicalSuccessAction] = createActionWithPayload<BundlePlanResponse[]>('FETCH_BUNDLE_PLANS_PERIODICAL_SUCCESS');
export const [fetchBundlePlansOfPeriodicalError, isFetchBundlePlansOfPeriodicalErrorAction] = createActionWithoutPayload('FETCH_BUNDLE_PLANS_PERIODICAL_ERROR');

export const [resetBundle, isResetBundleAction] = createActionWithoutPayload('RESET_BUNDLE');

export const [fetchBundle, isFetchBundleAction] = createActionWithPayload<string>('FETCH_BUNDLE');
export const [fetchBundleSuccess, isFetchBundleSuccessAction] = createActionWithPayload<BundleResponse>('FETCH_BUNDLE_SUCCESS');
export const [fetchBundleError, isFetchBundleErrorAction] = createActionWithoutPayload('FETCH_BUNDLE_ERROR');

export const [organizeBundle, isOrganizeBundle] = createActionWithPayload<{ bundle: PlanNode[], bundleId: string }>('ORGANIZE_BUNDLE');
export const [organizeBundleSuccess, isOrganizeBundleSuccessAction] = createActionWithoutPayload('ORGANIZE_BUNDLE_SUCCESS');
export const [organizeBundleError, isOrganizeBundleErrorAction] = createActionWithoutPayload('ORGANIZE_BUNDLE_ERROR');

export const [createBundle, isCreateBundleAction] = createActionWithPayload<{ action?: ActionWithPayload, context?: SearchContext, forceOptions?: Filters }>('CREATE_BUNDLE');
export const [createBundleSuccess, isCreateBundleSuccessAction] = createActionWithPayload<{ action?: ActionWithPayload, bundle: Bundle }>('CREATE_BUNDLE_SUCCESS');
export const [createBundleError, isCreateBundleErrorAction] = createActionWithoutPayload('CREATE_BUNDLE_ERROR');

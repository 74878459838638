import React from "react";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import Tooltip from "@material-ui/core/Tooltip";
import ViewListIcon from "@material-ui/icons/ViewList";
import {ViewGridIcon} from "../../icon";
import {useTranslation} from "react-i18next";

type SwitcherProps = {
    isLocationGrid: boolean
    handleChangeMode: any
}

export default function Switcher({isLocationGrid, handleChangeMode}: SwitcherProps) {
    const {t} = useTranslation()

    return <span>
            <ToggleButtonGroup
                size={"small"}
                value={isLocationGrid}
                onChange={handleChangeMode}
                exclusive>

                <ToggleButton value={false}>
                   <Tooltip title={t('misc.list_view') ?? ''}>
                        <ViewListIcon/>
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value={true}>
                    <Tooltip title={t('misc.grid_view') ?? ''}>
                        <ViewGridIcon/>
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>
        </span>
}

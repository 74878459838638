import Dropzone, {FileRejection} from "react-dropzone";
import {replaceIllustration, uploadIllustration, uploadIllustrationError} from "../../../redux/actions/image.actions";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {B64Image} from "../../../types/image";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PlusIcon} from "../../../icon";
import {Button} from "@material-ui/core";
import {addImageAuthor, addImageAuthorError, replaceImageAuthor} from "../../../redux/actions/author.actions";
import {replaceAdImage, uploadAdImage, uploadAdImageError} from "../../../redux/actions/ad.actions"
import {notifyError} from "../../../redux/actions/notification.actions";
import {uploadingAd} from "../../../redux/selectors/ads.selectors";
import {uploadingImage} from "../../../redux/selectors/image.selectors";
import {uploadingAuthorImage} from "../../../redux/selectors/author.selectors";

const useStyles = makeStyles((theme) => ({
    root: {},
    image: {
        width: "138px",
        height: "138px",
        cursor: "pointer",
        border: "rgba(0, 0, 0, .325) 1px solid",
        backgroundColor: "transparent",
        color: theme.palette.text.secondary,
        '&:hover, &:focus, &:active, &:visited, &:link': {
            backgroundColor: theme.palette.secondary.light,
            border: "rgba(0, 0, 0, .325) 1px solid"
        }
    },
    error: {
        borderColor: 'red',
        color: 'red'
    }
}));

function displayImageStatus(uploading: boolean) {
    if (uploading) {
        return (<CircularProgress/>)
    } else {
        return (<PlusIcon fontSize={"large"}/>
        )
    }
}

export type ImageUploadProps = {
    collection: 'ILLUSTRATION' | 'AUTHOR' | 'ADVERTISEMENT',
    imageId: string | undefined,
    error?: boolean
}

const mapCollectionToAction = {
    ILLUSTRATION: {
        action: uploadIllustration,
        replace: replaceIllustration,
        error: uploadIllustrationError,
        uploading: uploadingImage
    },
    AUTHOR: {
        action: addImageAuthor,
        replace: replaceImageAuthor,
        error: addImageAuthorError,
        uploading: uploadingAuthorImage
    }, // todo image replace author
    ADVERTISEMENT: {action: uploadAdImage, replace: replaceAdImage, error: uploadAdImageError, uploading: uploadingAd}
}

export default ({collection, imageId, error}: ImageUploadProps) => {
    const {action, replace, uploading} = mapCollectionToAction[collection]
    const {t} = useTranslation();

    const classes = useStyles();
    const dispatch = useDispatch();

    function getBase64(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            if (imageId) {
                dispatch(replace({image: {data: reader.result} as B64Image, id: imageId}))
            } else {
                dispatch(action({data: reader.result} as B64Image))
            }
        }
    }

    const uploadingValue = useSelector(uploading)

    if (uploadingValue) {
        return <Grid item>
            <Avatar variant="square"
                    className={classes.image}>
                {displayImageStatus(uploadingValue)}
            </Avatar>
        </Grid>
    } else {
        return <Grid item>
            <Dropzone maxSize={10000000} accept={["image/jpeg", "image/png"]}
                      onDropRejected={
                          (error: FileRejection[]) => {
                              dispatch(mapCollectionToAction[collection].error(error[0].errors[0].code));
                              dispatch(notifyError(error[0].errors[0].code, {type: collection}));
                          }
                      }
                      onDropAccepted={
                          files => {
                              files.forEach(file => getBase64(file))
                          }
                      }>
                {({getRootProps, getInputProps}) => (<>
                    {imageId && <section>
                        <div>
                            <input {...getInputProps()} />
                            <Grid justify="flex-end" container>
                                <Button  {...getRootProps()} variant="outlined" color="primary">Remplacer
                                    l'image</Button>
                            </Grid>
                        </div>
                    </section>}
                    {!imageId && <section>
                        <div>
                            <input {...getInputProps()} />
                            <Tooltip title={t('images.add', {context: collection}) ?? ''}>
                                <Avatar variant="square"
                                        className={[classes.image, error ? classes.error : ''].join(' ')} {...getRootProps()}>
                                    {displayImageStatus(uploadingValue)}
                                </Avatar>
                            </Tooltip>
                        </div>
                    </section>}
                </>)}
            </Dropzone>
        </Grid>
    }
}

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusToUpdateContext, TreeContext, useDirtyTreeContext } from '../contexts/organizeTreeBibleContexts';
import { useGetTree } from '../hooks/treeHooks';
import { useQuery } from '../hooks/useFetchDataOnMount';
import { useHandleQuery } from '../hooks/useHandleQuery';
import { removeNode } from '../models/TreeModels';
import { State } from '../types';
import { KyrButton } from './KyrButton';

interface Props {
  openState: State<boolean>
  customHandleConfirm: () => void,
  title?: string
  message: string
  confirmButtonText: string
  cancelButtonText: string
}

export const KyrPopUp = ({
  openState: [open, setOpen],
  customHandleConfirm,
  message,
  title,
  cancelButtonText,
  confirmButtonText
}: Props
) => {

  const handleCancel = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    customHandleConfirm()
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <KyrButton onClick={handleCancel} type="button" kyrType="primary">
            {cancelButtonText}
          </KyrButton>
          <KyrButton onClick={handleConfirm} type="button" kyrType="secondary">
            {confirmButtonText}
          </KyrButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const KyrCancelTreeUpdatePopUp = ({ showState: [show, setShow] }: { showState: State<boolean> }) => {
  const [tree] = useContext(TreeContext)
  const handleRefreshtree = useGetTree()
  const [, setStatusToUpdate] = useContext(StatusToUpdateContext)

  const handleConfirm = () => {
    if (tree.data?.id) {
      handleRefreshtree(tree.data.id.toString())
      setStatusToUpdate({})
    }
  }

  return (
    show ? <KyrPopUp
      openState={[show, setShow]}
      customHandleConfirm={handleConfirm}
      cancelButtonText="Revenir au CdF"
      confirmButtonText="Abandonner"
      message="Êtes-vous sur de vouloir abandonner les modifications apportées ?"
    /> : <></>
  )
}
export const KyrPublishTreePopUp = ({ showState: [show, setShow], delivery }: { showState: State<boolean>, delivery: boolean }) => {
  const [tree] = useContext(TreeContext)
  const { t } = useTranslation();
  const [query, publishResult, setPublishResult] = useQuery<undefined>({})

  useHandleQuery({
    errorMessage: delivery ? "KYRIELLE_DELIVERY" : "KYRIELLE_PUBLISH",
    succesMessage: 'kyrielle.publish.successMessage',
    query: publishResult,
    setQuery: setPublishResult
  })

  const handleConfirm = () => {
    query({
      url: `/api/kyrielle/tree/${tree.data?.id}/publish`,
      queryParams: { delivery },
      method: 'get',
    })
  }

  return (
    show ? <KyrPopUp
      openState={[show, setShow]}
      customHandleConfirm={handleConfirm}
      cancelButtonText={t("kyrielle.button.cancel")}
      confirmButtonText={t("kyrielle.button.publish")}
      message={delivery ? t("kyrielle.publish.publishAndDeliveryMessage") : t("kyrielle.publish.publishMessage")}
    /> : <></>
  )
}

type CustomPopUpProps = { showState: State<boolean> }
type RemovePopUpProps = CustomPopUpProps & { nodeId: string }
export const KyrRemoveNodePopUp = ({
  showState: [show, setShow],
  nodeId
}: RemovePopUpProps) => {

  const [tree, setTree] = useDirtyTreeContext()
  const {t} = useTranslation()

  const hanleConfirmDelete = () => {
    if (!tree.data) return
    const updatedNodes = removeNode(tree.data.nodes, nodeId)
    setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes } })
  }

  return (
    show ? <KyrPopUp
      openState={[show, setShow]}
      customHandleConfirm={hanleConfirmDelete}
      cancelButtonText={t('kyrielle.button.cancel')}
      confirmButtonText="Supprimer"
      message="Êtes-vous sûr de vouloir supprimer ce niveau ?"
    /> : <></>
  )
}

export const KyrLeavingPagePopUp = ({ showState: [show, setShow] }: CustomPopUpProps) => {
  const [tree, setTree] = useContext(TreeContext)
  const {t} = useTranslation()

  const handleConfirm = () => {
    if (!tree.data) return
    setShow(false);
    setTree({ ...tree, data: { ...tree.data, isDirty: false } });
  }

  return (
    show ? <KyrPopUp
      openState={[show, setShow]}
      customHandleConfirm={handleConfirm}
      cancelButtonText={t('kyrielle.button.cancel')}
      confirmButtonText="Confirmer"
      message="Êtes-vous sûr de vouloir quitter sans enregistrer les modifications ?"
    /> : <></>
  )
}

type KyrChangeTreeTypePopUpProps = CustomPopUpProps & {
  handleConfirm: () => void,
}
export const KyrChangeTreeTypePopUp = ({
  showState: [show, setShow],
  handleConfirm,
}: KyrChangeTreeTypePopUpProps) => {
  const {t} = useTranslation()

  return (
    show ? <KyrPopUp
      openState={[show, setShow]}
      customHandleConfirm={handleConfirm}
      cancelButtonText={t('kyrielle.button.cancel')}
      confirmButtonText="Confirmer"
      message="Êtes-vous sûr de vouloir changer de CDF sans enregistrer les modifications ?"
    /> : <></>
  )
}
import React, {useCallback} from "react";
import {Button, Container, Grid, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {commonStyle} from "../../../styles/common.style";
import {useTheme} from "@material-ui/core/styles";
import PageHeader from "../../commons/PageHeader";
import {ADMIN} from "../../../utils/roles";
import {ExportIcon, ImportIcon} from "../../../icon";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {uploadReferential} from "../../../redux/actions/admin/referential/referential.actions";
import {State} from "../../../redux/reducers";

export type ReferentialResource =
    "push-online"
    | "pdc"
    | "emetteurs"
    | "regex"
    | "tailles-pubs"
    | "types-texte"
    | "article-tags"

type ReferentialTableRowProps = {
    type: ReferentialResource
    download: () => void
}

export const downloadUrlResult = (result, filename) => {
    const data = filename.endsWith("json")
        ? ("data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(result)))
        : window.URL.createObjectURL(new Blob([result], {type: 'text/plain'}))

    const dlAnchorElem = document.getElementById('downloadFile');

    if (dlAnchorElem !== null) {
        dlAnchorElem.setAttribute("href", data);
        dlAnchorElem.setAttribute("download", filename);
        dlAnchorElem.click();
    }
}

function ReferentialTableRow({type, download}: ReferentialTableRowProps) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    return <>
        <TableRow>
            <TableCell>{t(`admin.referential.labels.${type}`)}</TableCell>

            <TableCell>
                <Button
                    onClick={download}
                    startIcon={<ExportIcon/>}
                >
                    {t('misc.export')}
                </Button>
            </TableCell>

            <TableCell>
                <input id={type + "upload"} type="file" style={{display: 'none'}} onChange={(e) => {
                    if (e.target.files !== null) {
                        dispatch(uploadReferential({file: e.target.files, type}))
                    }
                }}/>

                <Button
                    startIcon={<ImportIcon/>}
                    onClick={() => document.getElementById(type + "upload")?.click()}
                >
                    {t('misc.import')}
                </Button>
            </TableCell>
        </TableRow>
    </>
}

const mapReferentialResourceTypeToExt = (type: ReferentialResource) => {
    switch (type) {
        case "pdc":
            return "json"
        case "emetteurs":
            return "xml"
        case "regex":
            return "xlsx"
        case "tailles-pubs":
            return "json"
        case "types-texte":
            return "json"
        case "article-tags":
            return "json"
        case "push-online":
            return "json"
    }
}


function ReferentialAdminRoot() {
    const theme = useTheme()
    const classes = commonStyle(theme)
    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')

    const download = useCallback((type: ReferentialResource) => () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/admin/referential/${type}`, type === "regex" ? {...headers, responseType: 'blob'} : {headers})
            .then(result => {
                downloadUrlResult(
                    result.data,
                    `${type}-export.${mapReferentialResourceTypeToExt(type)}`
                )
            })
    }, [token])

    const jouveReportDownload = useCallback(() => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/admin/jouve`, {headers})
            .then(result => {
                downloadUrlResult(result.data, "jouve-report.json")
            })
    }, [token])

    // const temporaryAuthorImport = useCallback((files: FileList) => {
    //     const headers = {
    //         'Authorization': `Bearer ${token}`,
    //         'content-type': 'multipart/form-data'
    //     };
    //
    //     const data = new FormData()
    //     data.append("csv", files[0])
    //
    //     axios.post("/api/admin/export/authors-csv", data, {headers})
    //         .then(() => console.log('ok'))
    // }, [token])

    return <Container maxWidth={"xl"} className={classes.root}>
        <Grid container item xs={12}>
            <PageHeader label={"Import/Export Référentiels"} requiredRoles={[ADMIN]}/>
        </Grid>

        <Grid container item xs={12} alignContent={"center"} justify={"center"} direction={"column"} spacing={2}>
            <a id="downloadFile" style={{display: 'none'}}/>
            <Grid container item xs={8}>
                <Table>
                    <TableBody>
                        <ReferentialTableRow type={"pdc"} download={download("pdc")}/>
                        <ReferentialTableRow type={"emetteurs"} download={download("emetteurs")}/>
                        <ReferentialTableRow type={"tailles-pubs"} download={download("tailles-pubs")}/>
                        <ReferentialTableRow type={"regex"} download={download("regex")}/>
                        <ReferentialTableRow type={"types-texte"} download={download("types-texte")}/>
                        <ReferentialTableRow type={"article-tags"} download={download("article-tags")}/>
                        <ReferentialTableRow type={"push-online"} download={download("push-online")}/>
                        <TableRow>
                            <TableCell>
                                Rapport des processus Jouve
                            </TableCell>

                            <TableCell colSpan={2}>
                                <Button
                                    startIcon={<ExportIcon/>}
                                    onClick={jouveReportDownload}
                                >
                                    Exporter
                                </Button>
                            </TableCell>
                        </TableRow>

                        {/*<TableRow>*/}
                        {/*    <TableCell>*/}
                        {/*        Import auteurs CSV*/}
                        {/*    </TableCell>*/}

                        {/*    <TableCell/>*/}

                        {/*    <TableCell>*/}
                        {/*        <>*/}
                        {/*            <input id={"upload-authors-csv"} type="file" style={{display: 'none'}}*/}
                        {/*                   onChange={(e) => {*/}
                        {/*                       console.log('on change')*/}
                        {/*                       if (e.target.files !== null) {*/}
                        {/*                           console.log("not null")*/}
                        {/*                           temporaryAuthorImport(e.target.files)*/}
                        {/*                       }*/}
                        {/*                   }}/>*/}

                        {/*            <Button*/}
                        {/*                startIcon={<ImportIcon/>}*/}
                        {/*                onClick={() => document.getElementById("upload-authors-csv")?.click()}>*/}
                        {/*                Import*/}
                        {/*            </Button>*/}
                        {/*        </>*/}
                        {/*    </TableCell>*/}
                        {/*</TableRow>*/}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    </Container>
}

export default ReferentialAdminRoot

import {createActionWithoutPayload, createActionWithPayload} from "../../utils.actions";

export const [initExportStatus, isInitExportStatus] = createActionWithoutPayload('ADMIN_EXPORT_INIT_STATUS')

export const [exportPublishedElements, isExportPublishedElements] = createActionWithPayload<{ periodicalId: string, startDate: string, endDate: string }>('ADMIN_EXPORT_PUBLISHED_ELEMENTS')
export const [exportPublishedElementsSucces, isExportPublishedElementsSuccess] = createActionWithoutPayload('ADMIN_EXPORT_PUBLISHED_ELEMENTS_SUCCESS')
export const [exportPublishedElementsError, isExportPublishedElementsError] = createActionWithPayload<string>('ADMIN_EXPORT_PUBLISHED_ELEMENTS_ERROR')

export const [exportAuthors, isExportAuthors] = createActionWithPayload<{ periodicalId: string, startDate: string, endDate: string }>('ADMIN_EXPORT_AUTHORS')
export const [exportAuthorsSucces, isExportAuthorsSuccess] = createActionWithPayload<{ filename: string, blob: string }>('ADMIN_EXPORT_AUTHORS_SUCCESS')
export const [exportAuthorsError, isExportAuthorsError] = createActionWithPayload<string>('ADMIN_EXPORT_AUTHORS_ERROR')

export const [exportReferences, isExportReferences] = createActionWithPayload<{ periodicalId: string, startDate: string, endDate: string }>('ADMIN_EXPORT_REFERENCES')
export const [exportReferencesSucces, isExportReferencesSuccess] = createActionWithPayload<{ filename: string, blob: string }>('ADMIN_EXPORT_REFERENCES_SUCCESS')
export const [exportReferencesError, isExportReferencesError] = createActionWithPayload<string>('ADMIN_EXPORT_REFERENCES_ERROR')

import {createActionWithoutPayload, createActionWithPayload} from "./utils.actions";
import {B64Image, IllustrationImage, ImageColor, ImageFormat, ImageQuality, SavedImage} from "../../types/image";
import {Filters, SearchContext} from "../../types/common-search";

export const [saveIllustration, isSaveIllustrationAction] = createActionWithoutPayload('SAVE_ILLUSTRATION');
export const [saveIllustrationSuccess, isSaveIllustrationSuccessAction] = createActionWithoutPayload('SAVE_ILLUSTRATION_SUCCESS');
export const [saveIllustrationError, isSaveIllustrationErrorAction] = createActionWithoutPayload('SAVE_ILLUSTRATION_ERROR');

export const [fetchImage, isFetchImageAction] = createActionWithPayload<string>('FETCH_IMAGES');
export const [fetchImageSuccess, isFetchImageSuccessAction] = createActionWithPayload<IllustrationImage>('FETCH_IMAGES_SUCCESS');
export const [fetchImageError, isFetchImageErrorAction] = createActionWithoutPayload('FETCH_IMAGES_ERROR');

export const [searchImages, isSearchImagesAction] = createActionWithoutPayload('SEARCH_IMAGES');
export const [searchImagesSuccess, isSearchImagesSuccessAction] = createActionWithPayload<IllustrationImage[]>('SEARCH_IMAGES_SUCCESS');
export const [searchImagesError, isSearchImagesErrorAction] = createActionWithoutPayload('SEARCH_IMAGES_ERROR');

export const [uploadIllustration, isUploadIllustrationAction] = createActionWithPayload<B64Image>('UPLOAD_ILLUSTRATION');
export const [uploadIllustrationSuccess, isUploadIllustrationSuccessAction] = createActionWithPayload<SavedImage>('UPLOAD_ILLUSTRATION_SUCCESS');
export const [uploadIllustrationError, isUploadIllustrationErrorAction] = createActionWithPayload<string>('UPLOAD_ILLUSTRATION_ERROR');

export const [replaceIllustration, isReplaceIllustrationAction] = createActionWithPayload<{ image: B64Image, id: string }>('REPLACE_ILLUSTRATION');
export const [replaceIllustrationSuccess, isReplaceIllustrationSuccessAction] = createActionWithPayload<SavedImage>('REPLACE_ILLUSTRATION_SUCCESS');
export const [replaceIllustrationError, isReplaceIllustrationErrorAction] = createActionWithPayload<string>('REPLACE_ILLUSTRATION_ERROR');

export type DeleteIllustrationPayload = { id: string, doRedirect: boolean, filters?: Filters, ctx?: SearchContext }
export const [deleteIllustration, isDeleteIllustrationAction] = createActionWithPayload<DeleteIllustrationPayload>('DELETE_ILLUSTRATION');
export const [deleteIllustrationSuccess, isDeleteIllustrationSuccessAction] = createActionWithoutPayload('DELETE_ILLUSTRATION_SUCCESS');
export const [deleteIllustrationError, isDeleteIllustrationErrorAction] = createActionWithoutPayload('DELETE_ILLUSTRATION_ERROR');

export type DeleteManyIllustrationPayload = { ids: string[], filters?: Filters, ctx?: SearchContext }
export const [deleteManyIllustration, isDeleteManyIllustrationAction] = createActionWithPayload<DeleteManyIllustrationPayload>('DELETE_MANY_ILLUSTRATION');
export const [deleteManyIllustrationSuccess, isDeleteManyIllustrationSuccessAction] = createActionWithoutPayload('DELETE_MANY_ILLUSTRATION_SUCCESS');
export const [deleteManyIllustrationError, isDeleteManyIllustrationErrorAction] = createActionWithoutPayload('DELETE_MANY_ILLUSTRATION_ERROR');

export const [selectImages, isSelectImages] = createActionWithPayload<string[]>('IMAGES_SET_SELECTION');
export const [addImageSelection, isAddImageSelection] = createActionWithPayload<string[]>('IMAGES_ADD_SELECTION');
export const [removeImageSelection, isRemoveImageSelection] = createActionWithPayload<string[]>('IMAGES_REMOVE_SELECTION');
export const [clearImagesSelection, isClearImagesSelection] = createActionWithoutPayload('CLEAR_IMAGES_SELECTION');

export const [generateNewImage, isGenerateNewImageAction] = createActionWithoutPayload('GENERATE_NEW_IMAGE');

export const [setGridImageSortMode, isSetGridImageSortModeAction] = createActionWithPayload<string>('IMAGE_GRID_SORT_MODE');

// image edit
export const [setImageName, isSetImageNameAction] = createActionWithPayload<string>('SET_IMAGE_NAME')
export const [setImageDescription, isSetImageDescriptionAction] = createActionWithPayload<string>('SET_IMAGE_DESCRIPTION')
export const [setImageFormat, isSetImageFormatAction] = createActionWithPayload<ImageFormat>('SET_IMAGE_FORMAT')
export const [setImageColor, isSetImageColorAction] = createActionWithPayload<ImageColor>('SET_IMAGE_COLOR')
export const [setImageQuality, isSetImageQualityAction] = createActionWithPayload<ImageQuality>('SET_IMAGE_QUALITY')
export const [setImageCredits, isSetImageCreditsAction] = createActionWithPayload<string>('SET_IMAGE_CREDITS')
export const [setImageSources, isSetImageSourcesAction] = createActionWithPayload<string>('SET_IMAGE_SOURCES')
export const [setImageTags, isSetImageTagsAction] = createActionWithPayload<string[]>('SET_IMAGE_TAGS')
export const [removeImageTag, isRemoveImageTagAction] = createActionWithPayload<number>('REMOVE_IMAGE_TAGS')

export const [toggleIllustrationDisabled, isToggleIllustrationDisabledAction] = createActionWithPayload<{ id: string, flag: boolean }>('TOGGLE_ILLUSTRATION_DISABLED')
export const [toggleIllustrationDisabledSuccess, isToggleIllustrationDisabledSuccessAction] = createActionWithPayload<boolean>('TOGGLE_ILLUSTRATION_DISABLED_SUCCESS')
export const [toggleIllustrationDisabledError, isToggleIllustrationDisabledErrorAction] = createActionWithoutPayload('TOGGLE_ILLUSTRATION_DISABLED_ERROR')

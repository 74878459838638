import React, {useEffect} from "react";
import {DateTime} from "luxon";

type Props = {
    date: string
}

export default ({date}: Props) => {
    const [counter, setCounter] = React.useState((DateTime.fromISO(date ?? "", {zone: "europe/paris"}).toMillis() - DateTime.now().setZone("europe/paris").toMillis())/1000);

    const secondsToDhms = (seconds) => {
        if(seconds <= 0) {
            return "Maintenant"
        }
        seconds = Number(seconds);
        const d = Math.floor(seconds / (3600*24));
        const h = Math.floor(seconds % (3600*24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);

        const dDisplay = d > 0 ? d + (d == 1 ? " jour, " : " jours, ") : "";
        const hDisplay = h > 0 ? h + (h == 1 ? " heure, " : " heures, ") : "";
        const mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        const sDisplay = s > 0 ? s + (s == 1 ? " seconde" : " secondes") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    }

    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer as NodeJS.Timeout);
    }, [counter]);

    return (
        <>{secondsToDhms(counter)}</>
    );
}

import { IconButton } from "@material-ui/core"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import EditIcon from '@material-ui/icons/Edit'
import React, { CSSProperties, MouseEventHandler, useEffect, useRef, useState } from "react"
import { Row } from "../../components/FlexBox"
import { KyrRemoveNodePopUp } from "../../components/KyrPopUp"
import { KyrTextField } from "../../components/KyrTextField"
import { useDirtyTreeContext } from "../../contexts/organizeTreeBibleContexts"
import { useDragNodeFromTree, useDropNodeBetween, useDropNodeOnNodeTitle } from "../../hooks/nodeDnDhooks"
import { NodeModel, changeNodeName, hasDocumentUnits, hasSubNodes } from "../../models/TreeModels"
import { palette, treeColor } from "../../palette"
import { hoverLabelStyle } from "../../utils/DnD.utils"
import { StyledTreeItem } from "../organizeTree/tree/OrganizeNodeList"


type KyrAdminDropZoneProps = { dropZoneIndex: number, node: NodeModel | null }
const KyrAdminDropZone = ({ dropZoneIndex, node }: KyrAdminDropZoneProps) => {
  const [drop, isOver] = useDropNodeBetween(node, dropZoneIndex)

  return (
    <div
      id={`drop-zone-n${dropZoneIndex}`}
      ref={drop}
      style={{ height: 20, ...hoverLabelStyle(isOver, 40) }}
    />
  )
}

interface NodeComponentProps {
  offset: number
  node: NodeModel
  color?: string
  uppercase?: boolean
  dropZoneIndex: number
}

const NodeComponent = ({ offset, node, color, uppercase, dropZoneIndex }: NodeComponentProps) => {
  const [drag, isDragging] = useDragNodeFromTree(node)
  const [drop, isOver] = useDropNodeOnNodeTitle(node)
  const [show, setShow] = useState(false)
  const [onEdit, setOnEdit] = useState(false)
  const [tree, setTree] = useDirtyTreeContext()

  const pStyle: CSSProperties = {
    color,
    fontWeight: uppercase ? 400 : 'inherit',
    textTransform: uppercase ? 'uppercase' : 'none'
  }


  const handleDelete = () => {
    setShow(true)
  }

  const inputId = `input-${node.id}`

  const handleOnEdit: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    setOnEdit(true)
  }

  const inputRef = useRef<HTMLInputElement>(null)

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (inputRef.current == null || !tree.data) return
      const { current: { value } } = inputRef
      const updatedNodes = changeNodeName(tree.data?.nodes, node.id, value)
      setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes } })
      setOnEdit(false)
    }
  }

  useEffect(() => {
    if (onEdit) {
      document.getElementById(inputId)?.focus()
    }
  }, [onEdit])

  const label = (
    <Row justifyContent="space-between" ref={drop} style={hoverLabelStyle(isOver, 50)} >
      {!onEdit && <p style={{ ...pStyle }}>{node.name}</p>}
      {onEdit && (
        <KyrTextField
          onClick={(e) => e.stopPropagation()}
          style={{flexGrow: 1}}
          ref={inputRef}
          id={inputId}
          size="small"
          defaultValue={node.name}
          onKeyPress={onKeyPress}
        />
      )}
      <Row justifyContent="flex-end">
        <IconButton
          style={{ marginLeft: 10, marginBottom: 2 }}
          onClick={handleDelete}
          size="small"
        >
          {!(hasSubNodes(node) || hasDocumentUnits(node)) && (
            <DeleteForeverIcon style={{ color: palette.primary }} />
          )}
        </IconButton>
        <IconButton
          style={{ marginLeft: 10, marginBottom: 2 }}
          onClick={handleOnEdit}
          size="small"
        >
          <EditIcon style={{ color: palette.primary }} />
        </IconButton>
      </Row>
    </Row>
  )


  return (
    <>
      {dropZoneIndex === 0 && <KyrAdminDropZone node={node} dropZoneIndex={-1} />}
      <KyrRemoveNodePopUp showState={[show, setShow]} nodeId={node.id} />
      <StyledTreeItem
        style={{ display: isDragging ? 'none' : 'flex', flexDirection: 'column' }}
        ref={drag}
        nodeId={node.id}
        label={label}
      >
        {node.sub.length > 0 && node.sub.map((n, i) => (
          <NodeComponent dropZoneIndex={i} offset={offset + 20} node={n} />
        ))}
      </StyledTreeItem>
      <KyrAdminDropZone node={node} dropZoneIndex={dropZoneIndex} />
    </>
  )
}

interface NodeListProps {
  nodes: NodeModel[]
  colorful: boolean
}

export const AdminNodeList = ({ nodes, colorful }: NodeListProps) => (
  <>
    {nodes.length > 0 ? (
      nodes.map((n, i) => (
        <NodeComponent
          uppercase
          dropZoneIndex={i}
          color={colorful ? treeColor[i % treeColor.length] : 'black'}
          offset={0}
          node={n}
        />
      ))
    ) : (
      <KyrAdminDropZone dropZoneIndex={0} node={null} />
    )}
  </>
)

import {createSelector} from "reselect";
import {State} from "../../../reducers";

const adminBundleState = (state: State) => state.adminBundle;
// LIST
export const currentAdminBundlePlansSelector = createSelector(adminBundleState, state => state.adminBundlePlans);
export const currentAdminBundlePlansSearchResultSelector = createSelector(adminBundleState, state => state.adminBundleSearchResult);
export const currentAdminBundlePlansSearchContextSelector = createSelector(adminBundleState, state => state.adminBundlePlansSearchContext);

// FORM
export const currentAdminBundlePlanFormModeSelector = createSelector(adminBundleState, state => state.adminBundlePlanFormMode);
export const currentAdminBundlePlanDirtySelector = createSelector(adminBundleState, state => state.dirty);

// EDIT
export const currentAdminBundlePlanSelector = createSelector(adminBundleState, state => state.adminBundlePlan);
export const currentAdminBundlePlanPlanSelector = createSelector(adminBundleState, state => state.adminBundlePlan?.plan);
export const currentAdminBundlePlanTitleSelector = createSelector(adminBundleState, state => state.adminBundlePlan?.name);
export const currentAdminBundlePlanTypeSelector = createSelector(adminBundleState, state => state.adminBundlePlan?.type);
export const currentAdminBundlePlanPeriodicalIdSelector = createSelector(adminBundleState, state => state.adminBundlePlan?.periodicalId);


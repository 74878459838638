import {AxiosStatic} from "axios";
import {merge, Observable, of} from "rxjs";
import {Action} from "../../../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, concatMap, filter, map, mergeMap} from "rxjs/operators";
import {
    deleteAdminBundlePlanError,
    deleteAdminBundlePlanSuccess,
    fetchAdminBundlePlanError,
    fetchAdminBundlePlansError,
    fetchAdminBundlePlansSuccess,
    fetchAdminBundlePlanSuccess,
    isDeleteAdminBundlePlan,
    isFetchAdminBundlePlan,
    isFetchAdminBundlePlans,
    isSaveAdminBundlePlan,
    saveAdminBundlePlanError,
    saveAdminBundlePlanSuccess
} from "../../../actions/admin/bundle/admin-bundle.actions";
import {notifyError, notifySuccess} from "../../../actions/notification.actions";
import {StateObservable} from "redux-observable";
import {State} from "../../../reducers";
import {EpicDependencies} from "../../../store";
import {BundlePlanFormMode, BundlePlanResponse, BundlePlanSearchContext} from "../../../../types/bundle";
import i18next from "i18next";

function _fetchList(axios: AxiosStatic, searchContext: BundlePlanSearchContext, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    let listUrl = `/api/bundle_plan?page=${searchContext.page}&size=${searchContext.size}`;
    if (searchContext.periodicalId.length !== 0) {
        listUrl += `&periodicalIds=${searchContext.periodicalId}`;
    }

    return fromPromise(axios.get(listUrl, {headers}))
        .pipe(
            map((response) => fetchAdminBundlePlansSuccess(response.data)),
            catchError(err => merge(
                of(fetchAdminBundlePlansError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE_PLAN'}))
                )
            )
        )
}

function _fetch(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.get(`/api/bundle_plan/${id}`, {headers}))
        .pipe(
            map((result) => fetchAdminBundlePlanSuccess(result.data as BundlePlanResponse)),
            catchError(err => merge(
                of(fetchAdminBundlePlanError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE_PLAN'}))
            ))
        )
}

function _create(axios: AxiosStatic, bundlePlan: BundlePlanResponse, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    // To ensure that even in duplication, we do not send IDs to API
    // @ts-ignore
    delete bundlePlan.bundlePlanId;
    // @ts-ignore
    delete bundlePlan.bundlePlanVersionId;

    bundlePlan.enable = true;

    return fromPromise(axios.post(`/api/bundle_plan`, bundlePlan, {headers}))
        .pipe(
            mergeMap((result) => of(saveAdminBundlePlanSuccess(result.data as BundlePlanResponse), notifySuccess(i18next.t('misc.save_plan_success')))),
            catchError(err => merge(
                of(saveAdminBundlePlanError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE_PLAN'}))
            ))
        )
}

function _update(axios: AxiosStatic, bundlePlan: BundlePlanResponse, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    // @ts-ignore
    delete bundlePlan.bundlePlanVersionId;

    return fromPromise(axios.put(`/api/bundle_plan/${bundlePlan.bundlePlanId}`, bundlePlan, {headers}))
        .pipe(
            mergeMap((result) => of(saveAdminBundlePlanSuccess(result.data as BundlePlanResponse), notifySuccess(i18next.t('misc.save_plan_success')))),
            catchError(err => merge(
                of(saveAdminBundlePlanError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE_PLAN'}))
            ))
        )
}

function _delete(axios: AxiosStatic, id: string, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.delete(`/api/bundle_plan/${id}`, {headers}))
        .pipe(
            mergeMap(() => of(deleteAdminBundlePlanSuccess(id), notifySuccess(i18next.t('admin.bundle.list.delete_plan_success')))),
            catchError(err => merge(
                of(deleteAdminBundlePlanError()),
                of(notifyError(+err.response.status, {type: 'BUNDLE_PLAN'}))
            ))
        )
}

export function saveAdminBundlePlan(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSaveAdminBundlePlan),
        concatMap((action) => {
            switch (state$.value.adminBundle.adminBundlePlanFormMode) {
                case BundlePlanFormMode.EDIT:
                    return _update(axios, action.payload, state$.value.auth.token ?? "")

                case BundlePlanFormMode.CREATE:
                case BundlePlanFormMode.DUPLICATE:
                    return _create(axios, action.payload, state$.value.auth.token ?? "")
                default:
                    return _fetch(axios, action.payload.bundlePlanId, state$.value.auth.token ?? "")
            }
        })
    )
}

export function deleteAdminBundlePlan(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isDeleteAdminBundlePlan),
        concatMap((action) => {
            return _delete(axios, action.payload, state$.value.auth.token ?? "")
        })
    )
}

export function loadAdminBundlePlans(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchAdminBundlePlans),
        concatMap((action) => {
            return _fetchList(axios, action.payload, state$.value.auth.token ?? "")
        })
    )
}

export function loadAdminBundlePlan(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isFetchAdminBundlePlan),
        concatMap((action) => {
            return _fetch(axios, action.payload, state$.value.auth.token ?? "")
        })
    )
}

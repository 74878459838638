import {IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Filters, SearchContext} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {UnlockIcon} from "../../commons/icon/icons";
import {ArticleActionName} from "../../../types/workflow";
import {dispatchArticleAction} from "../../../redux/actions/workflow.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {uuidv4} from "../../../utils/uuid";
import {processInProgress} from "../../../redux/selectors/workflow.selectors";
import Auth from "../../Auth";
import {ADMIN} from "../../../utils/roles";


type Props = {
    documentId: string,
    context?: SearchContext,
    size?: 'small' | 'medium',
    forceOptions?: Filters,
    buttonClassName?: any
}

function UnlockPdfArticle({documentId, context, size, forceOptions, buttonClassName}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const processInProgressSelector = useSelector(processInProgress)
    const [currentProcess, setCurrentProcess] = useState<string>();

    const displayLoading = () => {
        if (currentProcess && processInProgressSelector.includes(currentProcess)) {
            return <CircularProgress color="inherit" size={size && size == 'small' ? 10 : 15}
                                     style={size === undefined || size === 'medium' ? {marginTop: 15} : {}}/>
        } else {
            return <IconButton
                size={size}
                className={buttonClassName}
                onClick={() => {
                    unlockPdf(documentId)
                }}>
                <UnlockIcon/>
            </IconButton>
        }

    }

    const unlockPdf = (articleId: string) => {
        const uuid = uuidv4();

        dispatch(dispatchArticleAction({
            id: articleId,
            action: ArticleActionName.UNLOCK_ARTICLE_PLACARD,
            uuid,
            context,
            forceOptions
        }))
        setCurrentProcess(uuid)

    }

    return (
        <Auth requiredRoles={[ADMIN]}>
            <Tooltip
                title={t('workflows.unlock_placard') ?? ''}>
                {displayLoading()}
            </Tooltip>
        </Auth>
    )
}

export default UnlockPdfArticle;

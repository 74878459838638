import {createActionWithPayload} from "./utils.actions";
import {ArticleActionName, PublicationActionName} from "../../types/workflow";
import {ElementContext, SearchContext} from "../../types/common-search";

//single
export const [dispatchArticleAction, isDispatchArticleAction] = createActionWithPayload<{ id: string, action: ArticleActionName, uuid: string, context?: SearchContext | ElementContext, forceOptions?: any }>('DISPATCH_ARTICLE_ACTION_WORKFLOW');
export const [dispatchArticleResponseAction, isDispatchArticleResponseAction] = createActionWithPayload<{ uuid: string, action: string }>('DISPATCH_ARTICLE_ACTION_WORKFLOW_RESPONSE');
export const [dispatchPublicationAction, isDispatchPublicationAction] = createActionWithPayload<{ id: number, action: PublicationActionName, uuid: string, context?: SearchContext, forceOptions?: any }>('DISPATCH_PUBLICATION_ACTION_WORKFLOW');
export const [dispatchPublicationResponseAction, isDispatchPublicationResponseAction] = createActionWithPayload<{ uuid: string, action: string, isSuccess: boolean }>('DISPATCH_PUBLICATION_ACTION_WORKFLOW_RESPONSE');

//multiple
export const [dispatchMultipleArticlesAction, isDispatchMultipleArticlesAction] = createActionWithPayload<{ ids: string[], action: ArticleActionName, uuid: string }>('DISPATCH_MULTIPLE_ARTICLES_ACTION_WORKFLOW');
export const [dispatchMultiplePublicationsAction, isDispatchMultiplePublicationsAction] = createActionWithPayload<{ ids: number[], action: PublicationActionName, uuid: string }>('DISPATCH_MULTIPLE_PUBLICATIONS_ACTION_WORKFLOW');
export const [dispatchMultipleArticlesActionResponse, isDispatchMultipleArticlesResponseAction] = createActionWithPayload<{ uuid: string, action: string }>('DISPATCH_MULTIPLE_PUBLICATIONS_ACTION_WORKFLOW_RESPONSE');
export const [dispatchMultiplePublicationsActionResponse, isDispatchMultiplePublicationsResponseAction] = createActionWithPayload<string>('DISPATCH_MULTIPLE_PUBLICATIONS_ACTION_WORKFLOW_RESPONSE');

import React from 'react'
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {commonStyle} from "../../../styles/common.style";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import {getCurrentPublication} from "../../../redux/selectors/publication.selectors";
import {State} from "../../../redux/reducers";
import {Typography} from "@material-ui/core";
import {createHtml} from "../../../utils/common-utils";

export default () => {
    const {t} = useTranslation();
    const classes = commonStyle();

    const bundle = useSelector(currentFetchedBundleSelector)
    const publication = useSelector(getCurrentPublication)
    const publicationName = `${publication?.periodicalId}_${publication?.year}_${publication?.number}`

    const periodical = useSelector(({periodical}: State) => periodical.periodicals).find(periodical => periodical.id === bundle?.periodical)

    return <Breadcrumbs aria-label="breadcrumb" separator="›" className={classes.root}>
        <Link key={0} color="inherit"
              to={'/' + t('routes.publication.root') + '?periodicalIdFilter=' + periodical?.id}
              component={RouterLink}>
            {periodical?.label}
        </Link>

        {publication && <Link key={1} color="inherit"
                              to={'/' + t('routes.publication.root') + '/' + publication.id + '/' + t('routes.publication.organize')}
                              component={RouterLink}>
            {publicationName}
        </Link>}

        <Typography key={2}>
            {t('bundles.organize')} : {bundle?.id} - {<div dangerouslySetInnerHTML={createHtml(bundle?.title || '')}
                                                           style={{display: 'inline'}}/>}
        </Typography>
    </Breadcrumbs>
}

import {makeStyles} from "@material-ui/core/styles";

export const viewPageStyle = makeStyles(theme => ({
    viewRoot: {
        margin: '20px 50px 0'
    },
    formRoot: {
        marginTop: '20px'
    },
    delete: {
        paddingLeft: 50,
        marginTop: theme.spacing(2)
    },
    header: {
        marginBottom: 20
    }
}))

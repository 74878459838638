import Grid from "@material-ui/core/Grid";
import { DateTime } from "luxon";
import React, { CSSProperties } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconButton } from "@material-ui/core";
import { ClearIcon } from "../../icon";

export type DateRangePickerProps = {
    onDatesChange: (value: { start?: string, end?: string }) => void
    start?: string
    end?: string
    minDate: string
    maxDate: string
    style?: CSSProperties
    inverted?: boolean
}

type CustomDatePickerProps = {
    label: string
    date: DateTime | null
    minDate: string,
    maxDate: string,
    handle: (value: DateTime | null) => void
}

const CustomDatePicker = ({
    label,
    date,
    minDate,
    maxDate,
    handle
}: CustomDatePickerProps) => (
    <Grid item xs={6}>
        <KeyboardDatePicker
            autoOk
            label={label}
            variant="inline"
            InputAdornmentProps={{ position: "end" }}
            value={date ?? null}
            onChange={handle}
            format="dd/MM/yyyy"
            minDate={minDate ?? DateTime.fromISO("2000-01-01")}
            maxDate={maxDate ?? DateTime.fromISO("2025-01-01")}
            showTodayButton={true}
            TextFieldComponent={props => <TextField {...props} color={"secondary"} />}
        />
        {date &&
            <IconButton style={{
                marginLeft: -70,
                marginTop: 8
            }}
                onClick={() => {
                    handle(null)
                }}>
                <ClearIcon />
            </IconButton>
        }
    </Grid>
)

type CommonAfterBeforeProps = {
    minDate: string,
    maxDate: string,
    handle: (attr: string) => (value: DateTime | null) => void
}

type AfterProps = CommonAfterBeforeProps & { afterDate: DateTime | null }

const After = ({
    afterDate,
    minDate,
    maxDate,
    handle
}: AfterProps
) => {
    const { t } = useTranslation();

    return (
        <CustomDatePicker
            label={t('misc.after')}
            date={afterDate}
            minDate={minDate}
            maxDate={maxDate}
            handle={handle('start')}
        />
    )
}


type BeforeProps = CommonAfterBeforeProps & { beforeDate: DateTime | null }

const Before = ({
    beforeDate,
    minDate,
    maxDate,
    handle
}: BeforeProps) => {
    const { t } = useTranslation();

    return (
        <CustomDatePicker
            label={t('misc.before')}
            date={beforeDate}
            minDate={minDate}
            maxDate={maxDate}
            handle={handle('end')}
        />
    )
}

export default ({ onDatesChange, start, end, minDate, maxDate, style, inverted }: DateRangePickerProps) => {
    const { t } = useTranslation();

    const handle = (attr: string) => (value: DateTime | null) => {
        const date = value?.isValid ? value.toISODate() : undefined;
        onDatesChange({
            start,
            end,
            [attr]: date
        })
    }

    const afterDate = start ? DateTime.fromISO(start) : null;
    const beforeDate = end ? DateTime.fromISO(end) : null;


    return (
        <Grid container style={{ marginTop: 5, ...style }}>
            <Grid container spacing={1}>
                {!inverted ? (
                    <>
                        <After afterDate={afterDate} handle={handle} maxDate={maxDate} minDate={minDate} />
                        <Before beforeDate={beforeDate} handle={handle} maxDate={maxDate} minDate={minDate}/>
                    </>
                ) : (
                    <>
                        <Before beforeDate={beforeDate} handle={handle} maxDate={maxDate} minDate={minDate}/>
                        <After afterDate={afterDate} handle={handle} maxDate={maxDate} minDate={minDate} />
                    </>
                )}
            </Grid>
        </Grid>
    )
}

import React from "react";
import PhoneUtils from "../../../utils/phone-utils";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {PhoneTypes} from "../../../types/author";

type Props = {
    phone: {
        countryCode: string,
        number: string,
        type?: PhoneTypes
    }
}


const useStyles = makeStyles((theme) =>
    createStyles({
        bulletElem: {
            fontSize: '1.8em',
            verticalAlign: 'middle',

            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        flexCenter: {
            display: 'flex',
            alignItems: 'center'
        }
    }),
);


export default ({phone: {countryCode, number, type}}: Props) => {
    const flag = PhoneUtils.countryToFlag(countryCode);
    const country = PhoneUtils.countryToValue(countryCode);
    const classes = useStyles();
    const {t} = useTranslation();

    let i = 0;
    const digits = number.replace(/\D/g, '')
    const formatted: string = country?.mask?.replace(/\d/g, () => `${digits[i++] ?? ''}`) ?? digits;

    return (
        <div className={classes.flexCenter}>
            +{countryCode}&nbsp;{formatted}
            {type != null && <span className={classes.bulletElem}>•</span>}
            {type != null && <span>{t(`authors.phone_type.${type}`)}</span>}
        </div>
    )
};

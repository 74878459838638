import React, {Dispatch, SetStateAction, useState} from "react";

/**
 * Is used the same way as to useState but
 * extends the usage to add a .then() callback
 * to the state being actually updated
 *
 * Example :
 *
 * const [nodes, setNodes] = useAsyncState([] as PlanNode[]);
 * setNodes([]).then(() => console.log('Nodes are set');
 *
 * @param initialValue
 */
function useAsyncState<S>(initialValue: S): [[S, Dispatch<SetStateAction<S>>][0], ((x: any) => Promise<unknown>)] {
    const [value, setValue] = useState(initialValue);

    const setterAsPromise = x =>
        new Promise(resolve => {
            setValue(x);
            resolve(x);
        });

    return [value, setterAsPromise];
}

export default useAsyncState;

import {createActionWithoutPayload, createActionWithPayload} from "./utils.actions";
import {ContactTypes, PersistedAuthor} from "../../types/author";
import {B64Image, DeleteOrDisabledImage, SavedImage} from "../../types/image";

// Fetch author
export const [fetchAuthor, isFetchAuthorAction] = createActionWithPayload<{ id: string, edit?: boolean }>('REST_FETCH_AUTHOR');
export const [fetchAuthorSuccess, isFetchAuthorSuccessAction] = createActionWithPayload<{ author: PersistedAuthor, edit: boolean }>('REST_FETCH_AUTHOR_SUCCESS');
export const [fetchAuthorError, isFetchAuthorErrorAction] = createActionWithPayload<string>('REST_FETCH_AUTHOR_ERROR');

// Save author
export const [generateNewAuthor, isGenerateNewAuthorAction] = createActionWithoutPayload('GENERATE_NEW_AUTHOR');
export const [updateStatusAuthor, isUpdateStatusAuthorAction] = createActionWithPayload<'NONE' | 'PENDING' | 'LOADED' | 'ERROR'>('UPDATE_STATUS_AUTHOR');

export const [saveAuthor, isSaveAuthorAction] = createActionWithoutPayload('REST_SAVE_AUTHOR');
export const [saveAuthorSuccess, isSaveAuthorSuccessAction] = createActionWithPayload<{ author: PersistedAuthor }>('REST_SAVE_AUTHOR_SUCCESS');
export const [saveAuthorError, isSaveAuthorErrorAction] = createActionWithPayload<string>('REST_SAVE_AUTHOR_ERROR');

// Delete author
export const [deleteAuthor, isDeleteAuthorAction] = createActionWithPayload<{ id: string, refresh: boolean }>('DELETE_AUTHOR');
export const [deleteAuthorSuccess, isDeleteAuthorSuccessAction] = createActionWithPayload<{ id: string, refresh: boolean }>('DELETE_AUTHOR_SUCCESS');

// Form Actions
export enum Field {
    'IMAGE' = 'images',
    'EMAIL' = 'emails',
    'PHONE_NUMBER' = 'phoneNumbers',
    'ADDRESS' = 'addresses',
    'FUNCTION' = 'functions',
    'FONTO_FUNCTION' = 'fontoFunctions',
    'PROFILE' = 'profiles',
}

export const [updateInput, isUpdateInputAction] = createActionWithPayload<{ input: string, value }>('UPDATE_INPUT_AUTHOR');
export const [setFavField, isSetFavFieldAction] = createActionWithPayload<{ field: Field, index: number }>('SET_FAV_AUTHOR');
export const [updateFieldInput, isUpdateFieldInputAction] = createActionWithPayload<{ field: Field, index: number, input?: string, value }>('UPDATE_FIELD_INPUT_AUTHOR');
export const [removeField, isRemoveFieldAction] = createActionWithPayload<{ field: Field, index: number }>('REMOVE_FIELD_AUTHOR');
export const [removeMultipleImages, isRemoveMultipleImages] = createActionWithPayload<{ indexes: number[] }>('REMOVE_MULTIPLE_IMAGES_AUTHOR');
export const [removeMultipleImagesSuccess, isRemoveMultipleImagesSuccess] = createActionWithPayload<DeleteOrDisabledImage>('REMOVE_MULTIPLE_IMAGES_AUTHOR_SUCCESS');
export const [addNewField, isAddNewFieldAction] = createActionWithPayload<{ field: Field, contact?: ContactTypes }>('ADD_NEW_FIELD_AUTHOR');

export const [addImageAuthor, isAddImageAuthorAction] = createActionWithPayload<B64Image>('ADD_IMAGE_AUTHOR');
export const [addImageAuthorSuccess, isAddImageAuthorSuccessAction] = createActionWithPayload<SavedImage>('ADD_IMAGE_AUTHOR_SUCCESS');
export const [addImageAuthorError, isAddImageAuthorErrorAction] = createActionWithPayload<string>('ADD_IMAGE_AUTHOR_ERROR');

export const [reactivateImageAuthor, isReactivateImageAuthorAction] = createActionWithPayload<string>('REACTIVATE_IMAGE_AUTHOR');
export const [reactivateImageAuthorSuccess, isReactivateImageAuthorSuccessAction] = createActionWithPayload<string>('REACTIVATE_IMAGE_AUTHOR_SUCCESS');

export const [replaceImageAuthor, isReplaceImageAuthorAction] = createActionWithPayload<{ image: B64Image, id: string }>('REPLACE_IMAGE_AUTHOR');

export const [toggleAuthorEnabled, isToggleAuthorEnabledAction] = createActionWithPayload<{ id: string, flag: boolean }>('TOGGLE_AUTHOR_ENABLED');
export const [toggleAuthorEnabledSuccess, isToggleAuthorEnabledSuccessAction] = createActionWithPayload<boolean>('TOGGLE_AUTHOR_ENABLED_SUCCESS');
export const [toggleAuthorEnabledError, isToggleAuthorEnabledErrorAction] = createActionWithPayload<number>('TOGGLE_AUTHOR_ENABLED_ERROR');

// form view actions

export const [selectImages, isSelectImagesAction] = createActionWithPayload<number[]>('SELECT_IMAGES');
export const [currentEditImagePanel, isCurrentEditImagePanelAction] = createActionWithPayload<string | null>('CURRENT_EDIT_IMAGE_PANEL');

export const [addNewContactType, isAddNewContactTypeAction] = createActionWithPayload<ContactTypes>('ADD_AUTHOR_CONTACT_TYPE');
export const [toggleContactPanel, isToggleContactPanelAction] = createActionWithPayload<ContactTypes>('TOGGLE_CONTACT_PANEL');


import {
    isAuthErrorAction,
    isAuthSuccessAction,
    isLogoutAction,
    isRefreshErrorAction,
    isRefreshSuccessAction,
    isUserInfoAction,
    isSetKeycloakDataAction
} from "../actions/auth.actions";
import {Action} from "../actions/utils.actions";
import {User} from "../../types/auth";

type AuthState = {
    authenticated: boolean
    user?: User
    token?: string
    roles: string[]
    keycloakBaseUrl?: string,
    realm?: string
}

const init: AuthState = {
    authenticated: false,
    roles: [],
};

const reducer = (state = init, action: Action): AuthState => {
    if (isAuthSuccessAction(action)) {
        const {payload: {token, roles}} = action;
        return {...state, authenticated: true, token, roles};
    } else if (isSetKeycloakDataAction(action)) {
        return {
            ...state,
            keycloakBaseUrl: action.payload.url,
            realm: action.payload.realm
        }
    } else if (isAuthErrorAction(action)) {
        return init;
    } else if (isUserInfoAction(action)) {
        const {payload: user} = action;
        return {...state, user};
    } else if (isRefreshSuccessAction(action)) {
        const {payload: {token, roles}} = action;
        return {...state, token, roles};
    } else if (isRefreshErrorAction(action)) {
        return init;
    } else if (isLogoutAction(action)) {
        return init;
    } else {
        return state;
    }
}

export default reducer

import { makeStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import React from "react";
import { palette } from "../palette";

const useStyles = makeStyles({
  root: {
    color: palette.borderColor,
    '&$checked': {
      color: palette.primary,
    },
  },
  checked: {},
});

export const KyrCheckbox = (props: CheckboxProps) => {
  const classes = useStyles();
  return <Checkbox classes={{ root: classes.root, checked: classes.checked }} {...props} />;
}

import React from "react"
import PdfArticle from "./articles/actions/PdfArticle"
import PdfBundle from "./bundle/actions/PdfBundle"
import PdfPublication from "./publications/workflow/PdfPublication"
import { ArticleSearchResult, ElementContext, Filters, PublicationSearchResult, SearchContext } from "../types/common-search"
import { ArticleResponse } from "../types/article"
import { BundleResponse } from "../types/bundle"
import { FontoPdfArticle } from "./FontoPdfArticle"
import { disabledPeriodical } from "../constantes"


export type PdfArticleProps = {
  article?: ArticleSearchResult | ArticleResponse
  ids?: string[]
  context?: SearchContext | ElementContext
  forceOptions?: Filters
  size?: 'small' | 'medium'
  buttonClassName?: any
  isButton?: boolean
  color?: 'primary' | 'secondary' | 'inherit'
}

export type PdfBundleProps = {
  bundle?: BundleResponse,
  ids?: string[],
  context?: SearchContext | ElementContext,
  forceOptions?: Filters,
  size?: 'small' | 'medium',
  buttonClassName?: any
}

export type PdfPublicationProps = {
  publication: PublicationSearchResult
}

export type FontoPdfArticleProps = {
  id: string
  periodicalId: string
}

type Props = {
  pdfArticle?: PdfArticleProps
  pdfBundle?: PdfBundleProps
  pdfPublication?: PdfPublicationProps
  fontoPdfArticle?: FontoPdfArticleProps
}



export const GeneratePlacardButton = ({ fontoPdfArticle, pdfArticle, pdfBundle, pdfPublication }: Props) => {
  const definedPropsCount = [fontoPdfArticle, pdfArticle, pdfBundle, pdfPublication].reduce((prev, curr) => curr !== undefined ? prev + 1 : prev, 0)
  if (definedPropsCount !== 1) {
    throw new Error('Une et une seule props doit renseignée')
  }


  //@ts-ignore
  const periodical = [fontoPdfArticle?.periodicalId, pdfArticle?.article?.periodical?.id, pdfArticle?.article?.periodicalId, pdfBundle?.bundle?.periodical, pdfPublication?.publication.periodicalId].filter(e => e !== undefined)[0]
  if(disabledPeriodical.includes(periodical) || periodical === undefined) {
    return <></>
  }

  if (pdfArticle) {
    return (
      <PdfArticle
        article={pdfArticle.article}
        ids={pdfArticle.ids}
        context={pdfArticle.context}
        forceOptions={pdfArticle.forceOptions}
        size={pdfArticle.size}
        buttonClassName={pdfArticle.buttonClassName}
        isButton={pdfArticle.isButton}
        color={pdfArticle.color}
      />
    )
  }

  if (fontoPdfArticle) {
    return (
      <FontoPdfArticle
        id={fontoPdfArticle.id}
        periodicalId={fontoPdfArticle.periodicalId}
      />
    )
  }

  if (pdfBundle) {
    return (
      <PdfBundle
        bundle={pdfBundle.bundle}
        ids={pdfBundle.ids}
        context={pdfBundle.context}
        forceOptions={pdfBundle.forceOptions}
        size={pdfBundle.size}
        buttonClassName={pdfBundle.buttonClassName}
      />
    )
  }

  if (pdfPublication) {
    return (
      <PdfPublication
        publication={pdfPublication.publication}
      />
    )
  }

  return <></>

}
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import {makeStyles} from '@material-ui/core/styles';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {State} from "../../../redux/reducers";
import Fav from "../../commons/Fav";
import {CopyIcon, DeleteIcon, DownloadIcon, EditIcon, Zoom} from "../../../icon";
import {Button, Paper} from "@material-ui/core";
import DateTime from "../../commons/DateTime";
import {koToMo} from "../../../utils/image.utils";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import {notify} from "../../../redux/actions/notification.actions";
import CloseIcon from '@material-ui/icons/Close';
import {createHtml} from "../../../utils/common-utils";
import {EnableIcon} from "../../commons/icon/icons";
import {reactivateImageAuthor} from "../../../redux/actions/author.actions"

type Props = {
    index: number
    selected: boolean
    select: () => void
    edit?: () => void
    setFav?: () => void
    remove?: () => void
    error?: boolean,
    displayDisableImage?: boolean
}

const useStyles = makeStyles((theme) => ({
    root: {
        border: "1px rgba(255, 0, 0, .4) solid",
        backgroundColor: "rgba(255, 0, 0, .2)",
        marginRight: 5
    },
    avatar: {
        width: "200px",
        height: "140px",
        cursor: "pointer"
    },
    image: {
        maxWidth: "200px",
        marginTop: 60
    },
    'image-details': {
        'background-color': "#d2d2d2",
        'font-size': "small",
        border: 0,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        "max-height": "70px",
        color: "black"
    },
    modalImageBody: {
        display: "flex",
        flexDirection: "row"
    },
    modalImageBodyListItem: {
        listStyle: "none",
        padding: 0,
        listStyleType: "none"
    },
    modalImageLabel: {
        fontWeight: "bold"
    },
    imageDetailsRightPanel: {
        padding: "20px",
        backgroundColor: 'white',
        width: "320px"
    },
    check: {
        position: "absolute",
        top: 0,
        left: 0
    },
    details: {
        "padding": "5px"
    },
    error: {
        border: "2px solid " + theme.palette.error.main,
    },
    zoom: {
        position: "absolute",
        right: 0,
        top: 0
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalImage: {
        maxWidth: 'calc(100vw  - 300px)',
        maxHeight: '100vh'
    },
    MuiButtonBase: {
        root: {
            padding: "auto"
        }
    },
    imageName: {
        paddingTop: theme.spacing(1),
        justifyContent: "center"
    },
    favorite: {
        color: "#ffbe00"
    },
    title: {
        fontWeight: 500,
        fontSize: '0.9rem',
        color: 'rgba(0, 0, 0, 0.75)'
    },
    imageNameSmall: {
        fontSize: '0.8rem',
        wordBreak: "break-word",
        maxWidth: 200,
        marginTop: 5
    },
    nameLabel: {marginTop: -10}
}));


const ImageAuthor = ({index, selected, select, edit, setFav, remove, error, displayDisableImage}: Props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch()

    const [isShown, setIsShown] = useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);


    const image = useSelector(
        ({author}: State) => author.current!.images[index],
        shallowEqual);
    const name = `${image.id}.${image.meta.contentType.toLowerCase()}`;
    const className = error ? `${classes.avatar} ${classes.error}` : classes.avatar;
    const downloadSrc = `/api/image/${image.id}?download`;
    const src = `/api/image/${image.id}?size=MEDIUM`;

    function handleCopy(name: string) {
        navigator.clipboard.writeText(name);
        dispatch(notify(t('authors.copyImageToWord'), {
            variant: 'success'
        }));
    }

    const activateImage = () => {
        dispatch(reactivateImageAuthor(image.id))
    }

    if (!displayDisableImage && image.disabled) {
        return (<></>)
    }
    return (
        <Grid item className={error ? classes.root : ''}>
            <Modal className={classes.modal}
                   open={openModal}
                   onClose={handleCloseModal}
                   closeAfterTransition
                   BackdropComponent={Backdrop}
                   BackdropProps={{timeout: 500}}>
                <>
                    <Fade in={openModal}>
                        <div className={classes.modalImageBody}>
                            <img className={classes.modalImage} src={src} alt={image.id}/>
                            <Paper className={classes.imageDetailsRightPanel} variant={"outlined"} square>
                                <Grid container direction={"column"} spacing={1}>
                                    <Grid container item key={"name"}
                                          xs={12} direction={"row"} justify={"space-between"}
                                          alignItems={"center"} className={classes.nameLabel}>
                                        <Grid item>
                                            <div className={classes.modalImageLabel}>
                                                <Typography className={classes.title}>{t("misc.name")}</Typography>
                                            </div>
                                        </Grid>

                                        <Grid item>
                                            <Button startIcon={<CloseIcon/>} size={"small"} onClick={handleCloseModal}>
                                                {t('misc.close')}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid item className={classes.nameLabel}>

                                        {name}
                                    </Grid>

                                    {[
                                        [t("misc.id"), image.id],
                                        [t("misc.format"), t(`enums.images.format.${image.format}.main`)],
                                        [t("images.type_framing"), t(`enums.images.framing.${image.framing}.main`)],
                                        [t("authors.credits"), image.credits === "" ? <span>-</span> :
                                            <div dangerouslySetInnerHTML={createHtml(image.credits)}/>],
                                        [t("authors.sources"), image.sources === "" ? <span>-</span> :
                                            <div dangerouslySetInnerHTML={createHtml(image.sources)}/>],
                                        [t("images.comments"), image.comments],
                                        [t("images.type_file"), image.meta.contentType],
                                        [t("misc.resolution"), `${image.meta.width} x ${image.meta.height} px${image.meta.xdpi ? ' - ' + image.meta.xdpi + ' dpi' : ''}`],
                                        [t("misc.file_size"), koToMo(image.meta.weight)],
                                        [t("misc.modification_date"), <DateTime date={image.modificationDate}/>]
                                    ]
                                        .map(([label, value]) =>
                                            <Grid item key={"" + label}>
                                                <div className={classes.modalImageLabel}>
                                                    <Typography className={classes.title}>{label}</Typography>
                                                </div>
                                                <div>
                                                    {value}
                                                </div>
                                            </Grid>)
                                    }
                                </Grid>
                            </Paper>
                        </div>
                    </Fade>
                </>
            </Modal>

            <Avatar variant="square"
                    className={className}
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                    onClick={edit !== undefined ? edit : handleOpenModal}
            >
                <Checkbox name={"selected_image_" + image.id}
                          color="primary"
                          checked={selected}
                          className={classes.check}
                          onChange={select}/>

                <Tooltip title={t('images.zoom') ?? ''}>
                    <IconButton className={classes.zoom} color={"default"} onClick={handleOpenModal}>
                        <Zoom/>
                    </IconButton>
                </Tooltip>

                <img
                    className={classes.image}
                    src={src} style={image.disabled ? {opacity: 0.5} : {}}
                    alt={image.id}/>

                <Collapse className={classes["image-details"]} in={isShown}>
                    <div className={classes.details}>{t('authors.sources')} : <div
                        dangerouslySetInnerHTML={createHtml(image.sources)}/></div>
                    <div className={classes.details}>{t('authors.credits')} : <div
                        dangerouslySetInnerHTML={createHtml(image.credits)}/></div>
                </Collapse>
            </Avatar>

            <Tooltip title={<span>{t("misc.copy")}</span>} style={{cursor: 'pointer'}}>
                <Grid container onClick={() => handleCopy(name ?? '')} className={classes.imageNameSmall}
                      justify={"center"}>
                    {name}
                    <CopyIcon style={{marginLeft: 5}}/>
                </Grid>
            </Tooltip>

            <Grid container justify={"center"} className={"action-buttons"}>
                <Grid item xs={2}>
                    <Fav isFav={image.fav} onClick={setFav}/>
                </Grid>

                {edit && <Grid item xs={2}>
                    <IconButton aria-label={t('misc.select')}
                                onClick={edit}>
                        <EditIcon fontSize={"small"}/>
                    </IconButton>
                </Grid>}

                <Grid item xs={2}>
                    <Tooltip title={t('images.export') ?? ''}>
                        <a href={downloadSrc} download={`${image.id}.jpg`}>
                            <IconButton aria-label={t('misc.download')}>
                                <DownloadIcon fontSize={"small"}/>
                            </IconButton>
                        </a>
                    </Tooltip>
                </Grid>

                {remove && <Grid item xs={2}>
                    <IconButton aria-label={t('misc.delete')}
                                onClick={remove}>
                        <DeleteIcon fontSize={"small"}/>
                    </IconButton>
                </Grid>}

                {edit && image.disabled && <Grid item xs={2}>
                    <Tooltip title={t('misc.activated') ?? ''}>
                        <IconButton aria-label={t('misc.activated')}
                                    onClick={activateImage}>
                            <EnableIcon fontSize={"small"}/>
                        </IconButton>
                    </Tooltip>
                </Grid>}
            </Grid>
        </Grid>
    )
}

export default ImageAuthor;

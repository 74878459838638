import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme} from "@material-ui/core";
import React, {useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";
import {Link as RouterLink, useLocation} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {DeleteIcon, DownloadIcon, EditIcon, ViewIcon} from "../../../icon";
import Auth from "../../Auth";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import DateTime from "../../commons/DateTime";
import LexImage from "../../commons/LexImage";
import ImageListActions from "./ImageListActions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {selectAllPage, selectOneElement, updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {deleteIllustration} from "../../../redux/actions/image.actions";
import {ADMIN, ADMIN_IMAGES} from "../../../utils/roles";
import {ImageSearchFilters, ImageSearchResult, SearchContext} from "../../../types/common-search";
import {downloadImages} from "../../../utils/common-utils";
import {readImageQueryParams} from "../../../utils/image.utils";
import {makeSortHandler, sortDirection} from "../../../utils/table-utils";
import {tableStyle} from "../../../styles/table.style";
import GridListSwitcher from "../../commons/GridListSwitcher";
import {DisableIcon, EnableIcon} from "../../commons/icon/icons";

type LexImageListProps = {
    images: ImageSearchResult[]
    filters: ImageSearchFilters
}

const mapper = {
    UPDATE: {default: "DESC", DESC: "ASC", ASC: ""},
    DEADLINE: {default: "ASC", DESC: "", ASC: "DESC"},
    ID: {default: "ASC", DESC: "", ASC: "DESC"},
    NAME: {default: "ASC", DESC: "", ASC: "DESC"}
}

const ImageTable = ({images, filters}: LexImageListProps) => {
    const theme = useTheme()
    const classes = tableStyle(theme)
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const searchLocation = useLocation().search;
    const isLocationGrid = searchLocation.includes("&grid") || searchLocation.includes("?grid")
    const queryParams = readImageQueryParams(searchLocation)

    const [selectedIds, allPageSelected, loaded]: [string[], boolean, boolean] = useSelector((state: State) => {
        const store = state.search.common[SearchContext.IMAGE];
        return [store.selectedIds, store.allPageSelected, store.status === 'LOADED'];
    });
    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);
    const [imageBeingDeleted, changeImageBeingDeletedId] = useState("");

    const handleImageDeletionConfirmation = (imageId: string) => () => {
        changeImageBeingDeletedId(imageId)
        openConfirmationDialog(true)
    }

    const handleChangeMode = () => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.IMAGE,
            field: "grid",
            value: !isLocationGrid
        }))
    };

    const handleImageDeletion = () => {
        dispatch(deleteIllustration({
            id: imageBeingDeleted,
            doRedirect: false,
            ctx: SearchContext.IMAGE,
            filters: queryParams
        }))
    }

    const downloadImageAction = (image: ImageSearchResult) => () => {
        downloadImages([image].map(it => ({id: it?.id ?? '', collection: it?.collection ?? 'UNKNOWN'})))
    }

    const sortHandler = makeSortHandler(SearchContext.IMAGE, mapper, filters, dispatch, updateSearchQueryParam)

    return (<>
            <ConfirmationDialog
                title={t('images.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default'},
                    {label: t('dialogs.confirm'), action: handleImageDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            <TableContainer>
                <div style={{width: "100%", display: "flex", justifyContent: "flex-end"}}>
                    <span>
                        <GridListSwitcher handleChangeMode={handleChangeMode} isLocationGrid={isLocationGrid}/>
                    </span>
                </div>

                <Table aria-label={"Liste des images"}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" className={classes.cell}>
                                <Checkbox
                                    checked={allPageSelected}
                                    onChange={(event, check) => {
                                        dispatch(selectAllPage({ctx: SearchContext.IMAGE, allPages: check}))
                                    }}
                                />
                            </TableCell>

                            <TableCell className={classes.cell}>Image</TableCell>
                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "ID") !== undefined}
                                    direction={sortDirection(filters, "ID")}
                                    onClick={sortHandler("ID")}>
                                    {t('misc.id')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "NAME") !== undefined}
                                    direction={sortDirection(filters, "NAME")}
                                    onClick={sortHandler("NAME")}>
                                    Nom
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>Caractéristiques</TableCell>
                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "UPDATE") !== undefined}
                                    direction={sortDirection(filters, "UPDATE")}
                                    onClick={sortHandler("UPDATE")}>
                                    Modifiée le
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>{t('images.state')}</TableCell>
                            <TableCell align={"center"} className={classes.cell}>{t('misc.actions')}</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(selectedIds && selectedIds.length > 0) &&
                        <TableRow key={"selectedRow"} className={classes.actionGroup}>
                            <TableCell colSpan={9} className={classes.cell}>
                                <ImageListActions/>
                            </TableCell>
                        </TableRow>}

                        {images.length > 0 && images.map(image =>
                            <TableRow key={image.id} className={`${classes.row}`} hover={true}>
                                <TableCell padding="checkbox" className={classes.cell}>
                                    <Checkbox
                                        checked={selectedIds.indexOf(image.id) > -1}
                                        onChange={() => dispatch(selectOneElement({
                                            ctx: SearchContext.IMAGE,
                                            id: image.id
                                        }))}/>
                                </TableCell>

                                <TableCell className={classes.cell}>
                                    <LexImage image={image}
                                              size={"THUMBNAIL"}
                                              maxWidth={65}
                                              disabled={!image.enable}
                                    />
                                </TableCell>

                                <TableCell className={classes.cell}>{image.id}</TableCell>

                                <TableCell className={classes.cell}>{image.name}</TableCell>

                                <TableCell className={classes.cell}>
                                    <ul>
                                        {image.characteristics?.map((it, idx) => <li
                                            key={image.id + "char" + idx}>{t(`enums.images.characteristics.${it}`)}</li>)}
                                    </ul>
                                </TableCell>

                                <TableCell className={classes.cell}>
                                    {image.modificationDate && <DateTime date={image.modificationDate}/>}
                                    {!image.modificationDate && <span>{t('misc.not_provided')}</span>}
                                </TableCell>

                                <TableCell className={classes.cell}>
                                    {image.enable ? <EnableIcon/> :
                                        <DisableIcon/>}
                                </TableCell>

                                <TableCell align={"right"} className={classes.cell}>
                                    {!image.authorId &&
                                    <Link
                                        to={`/${t('routes.image.root')}/${image.id}`}
                                        component={RouterLink}>
                                        <Tooltip title={`${t('images.see')}` || ""}>
                                            <IconButton className={classes.iconButton}>
                                                <ViewIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Link>}

                                    {image.authorId &&
                                    <Link
                                        to={`/${t('routes.author.root')}/${image.authorId}`}
                                        component={RouterLink}>
                                        <Tooltip title={`${t('authors.see_author')}` || ""}>
                                            <IconButton className={classes.iconButton}>
                                                <ViewIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </Link>}

                                    <Auth requiredRoles={[ADMIN, ADMIN_IMAGES]}>
                                        <>
                                            {!image.authorId &&
                                            <Link
                                                to={`/${t('routes.image.root')}/${image.id}/edit`}
                                                component={RouterLink}>
                                                <Tooltip title={t('misc.edit') || ""}>
                                                    <IconButton className={classes.iconButton}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>}

                                            {image.authorId &&
                                            <Link
                                                to={`/${t('routes.author.root')}/${image.authorId}/edit`}
                                                component={RouterLink}>
                                                <Tooltip title={t('misc.edit') || ""}>
                                                    <IconButton className={classes.iconButton}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Link>}

                                            {image.collection !== 'AUTHOR' && <>
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={handleImageDeletionConfirmation(image.id ?? '')}>
                                                    <Tooltip title={t('misc.delete') || ""}>
                                                        <DeleteIcon/>
                                                    </Tooltip>
                                                </IconButton>
                                            </>}
                                        </>
                                    </Auth>

                                    <IconButton className={classes.iconButton} onClick={downloadImageAction(image)}>
                                        <Tooltip title={t('images.export') || ""}>
                                            <DownloadIcon/>
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}

                        {(images.length === 0 && loaded) && (
                            <TableRow>
                                <TableCell colSpan={9} align={"center"}>
                                    {t('table.no_result')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ImageTable

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {TreeView} from "@material-ui/lab";
import React, {PropsWithChildren, useEffect, useRef, useState} from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import Container from "@material-ui/core/Container";
import theme from "../../../theme";
import {PlanNode} from "../../../types/plan-node";
import {fade, makeStyles} from "@material-ui/core/styles";
import {Grid, TextField} from "@material-ui/core";
import TreeAdder from "./TreeAdder";
import Typography from "@material-ui/core/Typography";
import TreePopover from "./TreePopover";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {BundleIcon, DetachedPlanIcon} from "../icon/icons";
import {TrashCan} from "mdi-material-ui";
import {Edit} from "@material-ui/icons";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {createHtml} from "../../../utils/common-utils";
import BundleStructureTitleRenvoiEditField from "../../admin/bundle/edit/BundleStructureTitleRenvoiEditField";
import BundleStructureTitleRenvoi from "../../admin/bundle/edit/BundleStructureTitleRenvoi";
import ImageIcon from "@material-ui/icons/Image";
import DescriptionIcon from "@material-ui/icons/Description";

let globalTreeDropData: PlanNode | null = null
export const setGlobalTreeDropData = (node: PlanNode) => globalTreeDropData = node

const useStyle = makeStyles((theme) => ({
    treeItem: {
        border: '1px solid transparent',
    },
    treeItemSelected: {
        backgroundColor: 'green'
    },
    treeItemOveredBeforeOrAfter: {
        border: 'none',
        borderBottom: '1px solid',
        borderColor: theme.palette.secondary.light
    },
    treeItemOvered: {
        border: '1px solid',
        borderRadius: '5px',
        borderColor: theme.palette.secondary.light
    },
    treeContainer: {
        marginBottom: '15px'
    },
    treeItemArticle: {
        fontSize: '.85rem',
        marginLeft: -8
    }
}))

type Props = {
    parent?: PlanNode
    hasDropZoneAfter?: boolean
    node: PlanNode
    depth: number
    maxDepth: number
    moveAsChild: any
    moveAsChildAfter: any
    moveAsChildBefore: any
    setAnchorEl: any
    setDragInfo: any
    edit: boolean
    preventSiblings: boolean
    unlink: (id: string) => void
    enableDrag?: boolean
    updateNodeName?: (key: string, name: string) => void
    mayDeleteAnyContainer: boolean
    isBundleStructureEdition: boolean
}

const detachLabelOfNode = (node: PlanNode) => {
    if (node.type.toString() === "ARTICLE") {
        // if (node.isBundle) {
        //     return "bundles.unlink"
        // } else {
        return "articles.unlink"
        // }
    } else if (node.type.toString() === "ILLUSTRATION") {
        return "illustrations.unlink"
    } else if (node.type.toString() === "ADVERTISEMENT") {
        return "ads.unlink"
    }
}

// Object = article / ad
const nodeAcceptsObjectNode = (node: any, otherNode: PlanNode, isPlan: boolean): boolean => {
    if (!node) return true
    else {
        if (node.planNodeId !== null && otherNode.planNodeId !== node.id && isPlan && otherNode.type.toString() === "ARTICLE") return false
        if (node && node.type.toString() !== "CONTAINER") return false
        if (isPlan && !node) return false

        else if (node) {
            if (isPlan) return !node.sub.some(node => node.type.toString() === "CONTAINER")
            else return true
        }

        return true
    }
}

const nodeAcceptsNode = (node: any, isPlan: boolean, edit: boolean): boolean => {
    if (node && node.type.toString() !== "CONTAINER") return false
    return edit;
}

const totalNodeDepth = (node: PlanNode, currentDepth: number): number => node.sub ?
    node.sub.map(_node => totalNodeDepth(_node, currentDepth + 1))
        .reduce((previousValue, currentValue) => previousValue > currentValue ? previousValue : currentValue, currentDepth)
    : currentDepth

const isDepthOk = (node: PlanNode, currentDepth: number, maxDepth: number) => totalNodeDepth(node, currentDepth) < maxDepth

const StyledTreeItem = withStyles((theme) => ({
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
        marginBottom: 8
    },
}))((props: PropsWithChildren<any>) => <TreeItem {...props} />);

const shouldAcceptNode = (node, preventSiblings, edit) => {
    if (!globalTreeDropData || (node && globalTreeDropData.id === node.id)) return false;

    const nonContainerAcceptsNodes = globalTreeDropData.type.toString() !== "CONTAINER"
        && nodeAcceptsObjectNode(node, globalTreeDropData, preventSiblings)

    const containerAcceptsObjectNode = globalTreeDropData.type.toString() === "CONTAINER"
        && nodeAcceptsNode(node, preventSiblings, edit)

    return nonContainerAcceptsNodes || containerAcceptsObjectNode
}

export const NodeTreeItem = ({
                                 enableDrag = true,
                                 hasDropZoneAfter = true,
                                 parent,
                                 preventSiblings,
                                 edit,
                                 node,
                                 depth,
                                 maxDepth,
                                 moveAsChild,
                                 moveAsChildAfter,
                                 moveAsChildBefore,
                                 setAnchorEl,
                                 setDragInfo,
                                 unlink,
                                 updateNodeName,
                                 mayDeleteAnyContainer,
                                 isBundleStructureEdition
                             }: Props) => {
    const [isBeforeHovered, setBeforeHovered] = useState(false)
    const [isNameHovered, setNameHovered] = useState(false)
    const [isAfterHovered, setAfterHovered] = useState(false)
    const [renvoiOk, setRenvoiOk] = useState(!isBundleStructureEdition)
    const [nameOk, setNameOk] = useState(!isBundleStructureEdition)

    const [isEditing, setEditing] = useState(false)
    const [nodeName, setNodeName] = useState(node.name)
    const {t} = useTranslation()
    const classes = useStyle(theme)

    const onUpdateNodeName = () => {
        setEditing(false);
        if (updateNodeName) {
            updateNodeName(node.id, nodeName ?? "");
        }
    }

    const editNameInputRef = useRef<HTMLInputElement>(null);
    const openNodeNameEdit = () => {
        setEditing(true)
    }
    const closeNodeNameEdit = () => {
        setEditing(false)
        setNodeName(node.name);
    }
    useEffect(() => {
        if (editNameInputRef.current) {
            editNameInputRef.current.focus();
        }
    }, [isEditing])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onUpdateNodeName();
        }

        if (event.keyCode === 27) {
            setEditing(false);
        }
    }

    const hasDeleteAction = (node) => (edit
        && (node.sub.length === 0
            || (mayDeleteAnyContainer && node.type.toString() === "CONTAINER")));

    const displayArticleIcon = (node: PlanNode) => {
        if (node.type.toString() === 'ARTICLE') {
            if (node.meta?.isBundle) {
                return <BundleIcon fontSize={"small"}/>
            } else {
                return <DescriptionIcon fontSize={"small"}/>
            }
        }
    }

    return <>
        <Grid item>
            <StyledTreeItem
                ex
                key={node.id}
                draggable={enableDrag && (edit || node.type.toString() !== "CONTAINER")}

                onDragStart={e => {
                    e.stopPropagation();
                    setGlobalTreeDropData(node);
                }}

                onDragOver={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!globalTreeDropData) return;
                    setAnchorEl(e.target)

                    if (isDepthOk(globalTreeDropData, depth, maxDepth) && shouldAcceptNode(node, preventSiblings, edit)) {
                        setNameHovered(true)
                        setDragInfo("as child|>" + node.name)
                    } else {
                        setNameHovered(false)
                        setDragInfo("invalid|>" + node.name)
                    }
                }}

                onDragLeave={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    setTimeout(() => {
                        setNameHovered(false)
                    }, 50)
                }}

                onDrop={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    //clean
                    setNameHovered(false)
                    setAnchorEl(null)

                    if (!globalTreeDropData) return;

                    if (isDepthOk(globalTreeDropData, depth, maxDepth) && shouldAcceptNode(node, preventSiblings, edit)) {
                        moveAsChild(globalTreeDropData, node)
                    }
                }}

                collapseIcon={<ExpandMoreIcon style={{
                    position: 'relative',
                    top: 0
                }}/>}
                expandIcon={<ChevronRightIcon style={{
                    position: 'relative',
                    top: 0
                }}/>}

                nodeId={node.id}
                label={
                    <>
                        <Grid item
                              className={classes.treeItem + " " + (isBeforeHovered ? classes.treeItemOveredBeforeOrAfter : "")}
                              style={{
                                  height: 15,
                                  margin: 0,
                              }}

                              onDragOver={e => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  if (!globalTreeDropData) return;
                                  setAnchorEl(e.currentTarget)

                                  //depth - 1 car on teste avec '<', mais un "cousin" peut être à une profondeur depth == maxDepth
                                  if (isDepthOk(globalTreeDropData, depth - 1, maxDepth) && shouldAcceptNode(parent, preventSiblings, edit)) {
                                      setBeforeHovered(true)
                                      setDragInfo("as sibling|>" + node.name)
                                  } else {
                                      setBeforeHovered(false)
                                      setDragInfo("invalid|>" + node.name)
                                  }
                              }}

                              onDragLeave={e => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  setBeforeHovered(false)
                              }}

                              onDrop={e => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  // clean
                                  setBeforeHovered(false)
                                  setAnchorEl(null)

                                  if (!globalTreeDropData) return;

                                  if (isDepthOk(globalTreeDropData, depth, maxDepth) && shouldAcceptNode(parent, preventSiblings, edit)) {
                                      moveAsChildBefore(globalTreeDropData, node)
                                  }
                              }}>
                        </Grid>
                        {<>
                            <Typography component="div" className={
                                classes.treeItem + " " +
                                (isNameHovered ? classes.treeItemOvered : '') + " " +
                                (node.type.toString() !== 'CONTAINER' ? classes.treeItemArticle : (!hasDropZoneAfter ? classes.treeContainer : ''))
                            } style={{position: 'relative'}}>
                                <Grid container direction="row" wrap="nowrap">
                                    {node.type.toString() !== 'CONTAINER' &&
                                    <Grid item alignContent={"center"} style={{marginRight: 5}}>
                                        {displayArticleIcon(node)}
                                        {node.type.toString() === 'ADVERTISEMENT' && <ImageIcon fontSize={"small"}/>}
                                    </Grid>}

                                    {node.type.toString() === 'CONTAINER' &&
                                    <Grid container alignItems="center" direction="row" justify="space-between"
                                          wrap="nowrap">
                                        <Grid item
                                              xs={12}
                                              className={"max-2-lines-text"}
                                              style={{fontWeight: 'bold'}}>
                                            {isEditing && <>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item xs={11}>
                                                        {!isBundleStructureEdition && <TextField
                                                            fullWidth
                                                            value={nodeName}
                                                            inputRef={editNameInputRef}
                                                            onKeyDown={handleKeyDown}
                                                            onChange={(e) => {
                                                                setNodeName(e.target.value)
                                                            }}
                                                        />}
                                                        {isBundleStructureEdition &&
                                                        <BundleStructureTitleRenvoiEditField
                                                            fieldValue={nodeName}
                                                            setFieldValue={setNodeName}
                                                            setRenvoiOk={setRenvoiOk}
                                                            setNameOk={setNameOk}
                                                        />}
                                                    </Grid>
                                                </Grid>
                                            </>}
                                            {!isEditing &&
                                            <Grid item container>
                                                <BundleStructureTitleRenvoi value={node.name}/>
                                            </Grid>}
                                        </Grid>

                                        {edit && <Grid container item xs={4} direction="row"
                                                       justify={isEditing ? "space-between" : "flex-end"} wrap="nowrap">
                                            {isEditing &&
                                            <Grid item>
                                                <Grid container direction="row" justify="flex-start" wrap="nowrap">
                                                    <Grid item>
                                                        <Tooltip title={`${t('misc.save')}`}>
                                                            <IconButton size="small" onClick={onUpdateNodeName}>
                                                                <CheckCircleIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title={`${t('misc.cancel')}`}>
                                                            <IconButton size="small" onClick={closeNodeNameEdit}>
                                                                <CancelIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>}

                                            <Grid item>
                                                <Grid container direction="row" justify="flex-end" wrap="nowrap">
                                                    {hasDeleteAction(node) &&
                                                    <Grid item>
                                                        <IconButton size="small" onClick={() => unlink(node.id)}>
                                                            <Tooltip title={`${t('misc.delete')}`}>
                                                                <TrashCan/>
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid>}
                                                    {!isEditing &&
                                                    <Grid item>
                                                        <IconButton size="small" onClick={() => openNodeNameEdit()}>
                                                            <Tooltip title={`${t('misc.edit')}`}>
                                                                <Edit/>
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid>}
                                                    {isEditing &&
                                                    <Grid item>
                                                        <IconButton size="small" onClick={closeNodeNameEdit}>
                                                            <Tooltip title={`${t('misc.cancel')}`}>
                                                                <CancelIcon/>
                                                            </Tooltip>
                                                        </IconButton>
                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                        </Grid>}
                                    </Grid>}

                                    {node.type.toString() !== 'CONTAINER' &&
                                    <Grid container item xs={11} alignItems={"center"}>
                                        <Grid item xs={11}
                                              className={"max-2-lines-text"}>{node.id + ' - '}{node.type.toString() === 'ARTICLE' ?
                                            <div dangerouslySetInnerHTML={createHtml(node.name || '')}
                                                 style={{display: 'inline'}}/> : node.name}</Grid>

                                        <Grid item xs={1}>
                                            <Tooltip title={t(detachLabelOfNode(node) ?? "") ?? "Détacher"}>
                                                <IconButton style={{padding: 5}} onClick={() => unlink(node.id)}>
                                                    <DetachedPlanIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>}
                                </Grid>
                            </Typography> </>}
                        {<>
                            {(hasDropZoneAfter && node.sub.length === 0) &&
                            <Grid item
                                  className={classes.treeItem + " " + (isAfterHovered ? classes.treeItemOveredBeforeOrAfter : "")}
                                  style={{
                                      height: 15,
                                      margin: 0,
                                  }}

                                  onDragOver={e => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      if (!globalTreeDropData) return;
                                      setAnchorEl(e.currentTarget)

                                      if (isDepthOk(globalTreeDropData, depth, maxDepth) && shouldAcceptNode(parent, preventSiblings, edit)) {
                                          setAfterHovered(true)
                                          setDragInfo("as sibling|>" + node.name)
                                      } else {
                                          setAfterHovered(false)
                                          setDragInfo("invalid|>" + node.name)
                                      }
                                  }}

                                  onDragLeave={e => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      setAfterHovered(false)
                                  }}

                                  onDrop={e => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      // clean
                                      setAfterHovered(false)
                                      setAnchorEl(null)

                                      if (!globalTreeDropData) return;

                                      if (isDepthOk(globalTreeDropData, depth, maxDepth) && shouldAcceptNode(parent, preventSiblings, edit)) {
                                          moveAsChildAfter(globalTreeDropData, node)
                                      }
                                  }}>
                            </Grid>}
                        </>}
                    </>}
            >
                {node.sub &&
                node.sub.map((subNode, index) => <NodeTreeItem
                    hasDropZoneAfter={index === (node.sub.length - 1)}
                    unlink={unlink}
                    parent={node}
                    edit={edit}
                    preventSiblings={preventSiblings}
                    maxDepth={maxDepth}
                    depth={depth + 1}
                    key={subNode.id}
                    node={subNode}
                    moveAsChild={moveAsChild}
                    moveAsChildAfter={moveAsChildAfter}
                    moveAsChildBefore={moveAsChildBefore}
                    setAnchorEl={setAnchorEl}
                    setDragInfo={setDragInfo}
                    mayDeleteAnyContainer={mayDeleteAnyContainer}
                    updateNodeName={updateNodeName}
                    isBundleStructureEdition={isBundleStructureEdition}
                />)
                }
            </StyledTreeItem>
            {(node.sub && node.sub.length > 0 && hasDropZoneAfter) &&
            <Grid item
                  className={classes.treeItem + " " + (isAfterHovered ? classes.treeItemOveredBeforeOrAfter : "")}
                  style={{
                      height: 15,
                      margin: 0
                  }}

                  onDragOver={e => {
                      e.preventDefault();
                      e.stopPropagation();

                      if (!globalTreeDropData) return;
                      setAnchorEl(e.currentTarget)

                      if (isDepthOk(globalTreeDropData, depth, maxDepth) && shouldAcceptNode(parent, preventSiblings, edit)) {
                          setAfterHovered(true)
                          setDragInfo("as sibling|>" + node.name)
                      } else {
                          setAfterHovered(false)
                          setDragInfo("invalid|>" + node.name)
                      }
                  }}

                  onDragLeave={e => {
                      e.preventDefault();
                      e.stopPropagation();

                      setAfterHovered(false)
                  }}

                  onDrop={e => {
                      e.preventDefault();
                      e.stopPropagation();

                      // clean
                      setAfterHovered(false)
                      setAnchorEl(null)

                      if (!globalTreeDropData) return;

                      if (isDepthOk(globalTreeDropData, depth, maxDepth) && shouldAcceptNode(parent, preventSiblings, edit)) {
                          moveAsChildAfter(globalTreeDropData, node)
                      }
                  }}>
            </Grid>}
        </Grid>
    </>
}

type PlanViewerProps = {
    setDirty: (dirty: boolean) => void
    readonly?: boolean
    maxDepth: number
    preventSiblings: boolean
    edit: boolean
    nodes: PlanNode[]
    setNodes: any
    canAddContainerNodes: boolean
    detachLabel: string
    displayDirection?: "column" | "column-reverse" | "row" | "row-reverse"
    treeStyle?: any
    treeAdderInputLabel?: string
    treeAdderButtonLabel?: string
    customClasses?: any
    mayDeleteAnyContainer?: boolean
    expandAll?: boolean
    isBundleStructureEdition: boolean
}

const isNodeEmpty = (node: PlanNode) =>
    node.sub.every(subnode => subnode.type === "CONTAINER") &&
    node.sub.every(subnode => isNodeEmpty(subnode))

export function flatMapIds(nodes: PlanNode[]): string[] {
    return nodes
        .flatMap(it => [it.id, ...flatMapIds(it.sub)])
}

function flatMapIdsAndRemoveEmpties(nodes: PlanNode[]): string[] {
    return nodes
        .filter(it => !isNodeEmpty(it))
        .flatMap(it => [it.id, ...flatMapIdsAndRemoveEmpties(it.sub)])
}

const treeClasses = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        padding: 0
    },
    tree: {
        backgroundColor: "rgba(0,0,0,0.1)",
        padding: theme.spacing(1),
        borderRadius: "5px",
        minHeight: "75vh"
    },
    treeInDropZone: {
        backgroundColor: "rgba(0,0,0,0.1)",
        padding: theme.spacing(1),
        borderRadius: "5px",
        border: "1px solid rgba(0,0,0,0.8)",
        minHeight: "75vh"
    },
    treeContainer: {
        width: "100%"
    }
}));

export default function TreeViewer({
                                       setDirty,
                                       readonly = false,
                                       maxDepth,
                                       preventSiblings,
                                       edit,
                                       canAddContainerNodes,
                                       nodes,
                                       setNodes,
                                       displayDirection,
                                       treeStyle,
                                       treeAdderInputLabel,
                                       treeAdderButtonLabel,
                                       customClasses = {},
                                       mayDeleteAnyContainer,
                                       expandAll,
                                       isBundleStructureEdition
                                   }: PlanViewerProps) {
    const classes = treeClasses(theme);

    const [expanded, setExpandedNodes] = useState(expandAll ? flatMapIds(nodes) : flatMapIdsAndRemoveEmpties(nodes))
    const [anchorEl, _setAnchorEl] = useState(null)
    const [dragInfo, _setDragInfo] = useState("")

    const [inDropZone, setInDropZone] = useState(false)
    const [isTreeFlat, setIsTreeFlat] = useState(false)

    const setAnchorEl = useRef(_.debounce(v => _setAnchorEl(v), 25)).current;
    const setDragInfo = useRef(_.debounce(v => _setDragInfo(v), 25)).current;

    displayDirection = displayDirection ?? "column";

    useEffect(() => {
        setIsTreeFlat((nodes.filter((node) => node.type === 'CONTAINER').length === 0) && !readonly);
    }, [nodes])

    const moveAsChild = (src: PlanNode, dest: PlanNode) => {
        if (!readonly) {
            if (expanded.indexOf(dest.id) === -1) setExpandedNodes([...expanded, dest.id])
            setNodes(moveNodeInPlanAsChild(nodes, src, dest))
            setInDropZone(false);
            setDirty(true)
        }
    }

    const moveAsChildAfter = (src: PlanNode, dest: PlanNode) => {
        if (!readonly) {
            setNodes(moveNodeInPlanAsChildAfter(nodes, src, dest))
            setInDropZone(false);
            setDirty(true)
        }
    }

    const moveAsChildBefore = (src: PlanNode, dest: PlanNode) => {
        if (!readonly) {
            setNodes(moveNodeInPlanAsChildBefore(nodes, src, dest))
            setInDropZone(false);
            setDirty(true)
        }
    }

    const addNode = (node: PlanNode) => {
        if (!readonly && !treeContainsNode(nodes, node)) {
            setNodes([node, ...nodes])
            setInDropZone(false);
            setDirty(true)
        }
    }

    const handleToggle = (event, nodeIds) => {
        setExpandedNodes(nodeIds)
    };

    const unlink = (id: string) => {
        setNodes(removeChildren(nodes, [id]))
        setDirty(true)
    }

    const updateNodeName = (key: string, name: string) => {
        setNodes(updateChild(nodes, key, name))
        setDirty(true)
    }

    return <>
        <TreePopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} dragInfo={dragInfo}/>

        <Container maxWidth={"xl"} className={customClasses.root ?? classes.root}>
            <Grid container spacing={2} alignItems={"flex-start"} direction={displayDirection}>
                {edit && canAddContainerNodes && <>
                    <Grid item xs={displayDirection === "row" ? 6 : 12}>
                        <TreeAdder
                            inputLabel={treeAdderInputLabel}
                            buttonLabel={treeAdderButtonLabel}
                            addNode={addNode}
                            isBundleStructureEdition={isBundleStructureEdition}
                        />
                        {(displayDirection === "column") && <hr/>}
                    </Grid>
                </>}

                <Grid item xs={displayDirection === "row" ? 6 : 12} className={classes.treeContainer}
                      style={treeStyle ?? {}}>
                    <TreeView
                        onMouseLeave={() => setAnchorEl(null)}
                        onDrop={(e) => {
                            if (isTreeFlat && globalTreeDropData && inDropZone) {
                                e.stopPropagation();
                                e.preventDefault();
                                if (!treeContainsNode(nodes, globalTreeDropData)) {
                                    addNode(globalTreeDropData);
                                }
                                setInDropZone(false);
                            }
                        }}
                        onDragOver={(e) => {
                            if (isTreeFlat) {
                                e.stopPropagation();
                                e.preventDefault();
                                if (globalTreeDropData && !inDropZone) {
                                    setInDropZone(true);
                                }
                            }
                        }}
                        onDragExit={(e) => {
                            if (isTreeFlat) {
                                e.stopPropagation();
                                e.preventDefault();
                                if (globalTreeDropData && inDropZone) {
                                    setInDropZone(false);
                                }
                            }
                        }}
                        expanded={expanded}
                        selected={""}
                        className={inDropZone ? classes.treeInDropZone : classes.tree}
                        onNodeToggle={handleToggle}>
                        {
                            nodes.map((node, index) => <NodeTreeItem
                                enableDrag={!readonly}
                                hasDropZoneAfter={index === (nodes.length - 1)}
                                unlink={unlink}
                                parent={undefined}
                                preventSiblings={preventSiblings}
                                edit={edit}
                                key={node.id}
                                node={node}
                                depth={0}
                                maxDepth={maxDepth}
                                moveAsChild={moveAsChild}
                                moveAsChildAfter={moveAsChildAfter}
                                moveAsChildBefore={moveAsChildBefore}
                                setAnchorEl={setAnchorEl}
                                setDragInfo={setDragInfo}
                                updateNodeName={updateNodeName}
                                mayDeleteAnyContainer={mayDeleteAnyContainer ?? false}
                                isBundleStructureEdition={isBundleStructureEdition}
                            />)
                        }
                    </TreeView>
                </Grid>
            </Grid>
        </Container>
    </>
}

export const removeChildren = (nodes: PlanNode[], ids: string[]) => nodes
    .filter(node => ids.indexOf(node.id) === -1)
    .map(node => ({...node, sub: removeChildren(node.sub, ids)}))

const updateChild = (nodes: PlanNode[], id: string, name: string) =>
    nodes.map(
        node => node.id === id
            ? {...node, name: name}
            : {
                ...node,
                sub: updateChild(node.sub, id, name)
            }
    )

const treeContainsNode = (nodes: PlanNode[], node: PlanNode): boolean => {
    return nodes.some((value: PlanNode) => value.sub ? (treeContainsNode(value.sub, node) || value.id === node.id) : value.id === node.id)
}

const maybeAppendNode = (nodes: PlanNode[], id: string, nodeToAdd: PlanNode) => nodes.map(
    node => node.id === id
        ? {...node, sub: [...node.sub, nodeToAdd]}
        : {
            ...node,
            sub: maybeAppendNode(node.sub, id, nodeToAdd)
        }
)

export const moveNodeInPlanAsChild = (nodes: PlanNode[], src: PlanNode, dest: PlanNode) => {
    if (src.id === dest.id) return nodes
    return maybeAppendNode(removeChildren(nodes, [src.id]), dest.id, src)
}

const maybeAppendNodeAfter = (nodes: PlanNode[], id: string, nodeToAdd: PlanNode) => nodes.flatMap(
    node => node.id === id
        ? [node, nodeToAdd]
        : {
            ...node,
            sub: maybeAppendNodeAfter(node.sub, id, nodeToAdd)
        }
)

const maybeAppendNodeBefore = (nodes: PlanNode[], id: string, nodeToAdd: PlanNode) => nodes.flatMap(
    node => node.id === id
        ? [nodeToAdd, node]
        : {
            ...node,
            sub: maybeAppendNodeBefore(node.sub, id, nodeToAdd)
        }
)

export const moveNodeInPlanAsChildAfter = (nodes: PlanNode[], src: PlanNode, dest: PlanNode) => {
    if (src.id === dest.id) return nodes
    return maybeAppendNodeAfter(removeChildren(nodes, [src.id]), dest.id, src)
}

export const moveNodeInPlanAsChildBefore = (nodes: PlanNode[], src: PlanNode, dest: PlanNode) => {
    if (src.id === dest.id) return nodes
    return maybeAppendNodeBefore(removeChildren(nodes, [src.id]), dest.id, src)
}

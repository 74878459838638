import React, {useEffect, useState} from "react";
import TreeViewer from "../../commons/tree/TreeViewer";
import {Container} from "@material-ui/core";
import {useSelector} from "react-redux";
import {PlanNode} from "../../../types/plan-node";
import {currentFetchedBundleSelector} from "../../../redux/selectors/bundle.selectors";
import {useTranslation} from "react-i18next";
import RouteLeavingGuard from "../../RouteLeavingGuard/RouteLeavingGuard";
import {useHistory, useLocation} from "react-router-dom";

export const removeNulls = (plan: PlanNode[]) => plan.map(node => node.sub === null ? {...node, sub: []} : {
    ...node,
    sub: removeNulls(node.sub)
})

type Props = {
    nodes: PlanNode[]
    setNodes: any
    dirty: boolean
    setDirty: any
    isBundlePlanReadOnly: boolean
}

export default function BundleOrganizeTree({nodes, setNodes, dirty, setDirty, isBundlePlanReadOnly}: Props) {
    const bundle = useSelector(currentFetchedBundleSelector)
    const [loaded, setLoaded] = useState(false)
    const {t} = useTranslation()
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (bundle?.plan) {
            setNodes(removeNulls(bundle.plan)).then(() => setLoaded(true))
        }
    }, [bundle?.id, bundle?.plan])

    const handleUnload = (ev) => {
        if (dirty && location.pathname.indexOf("edit") !== -1) {
            ev.preventDefault();
            return ev.returnValue = 'Souhaitez-vous vraiment annuler la saisie en cours ?';
        }
    }
    window.addEventListener("beforeunload", handleUnload);

    return <Container style={{marginTop: "1%", maxHeight: '85vh', minHeight: '50vh', overflowY: 'auto'}}>
        <RouteLeavingGuard
            when={dirty}
            shouldBlockNavigation={() => true}
            navigate={(location) => history.push(location)}
            title={t(`bundles.confirm_dialog_title`)}
            description={t(`bundles.confirm_dialog_description`)}
        />
        {loaded && <TreeViewer
            setDirty={setDirty}
            expandAll={true}
            readonly={isBundlePlanReadOnly}
            detachLabel={t('articles.unlink')}
            maxDepth={5}
            preventSiblings={false}
            edit={false}
            canAddContainerNodes={false}
            nodes={nodes}
            setNodes={setNodes}
            isBundleStructureEdition={true}
        />}
    </Container>
}

import React from 'react';
import {Container, Grid} from "@material-ui/core";
import {commonStyle} from "../../../../styles/common.style";
import Auth from "../../../Auth";
import {ADMIN} from "../../../../utils/roles";
import PeriodicalOrganizeSelect from "./PeriodicalOrganizeSelect";
import PeriodicalOrganizeStructure from "./PeriodicalOrganizeStructure";
import PageHeader from "../../../commons/PageHeader";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {currentAdminSelectedPeriodicalDirtySelector,} from "../../../../redux/selectors/admin/periodical/admin-publication.selectors";
import RouteLeavingGuard from "../../../RouteLeavingGuard/RouteLeavingGuard";
import {useHistory} from "react-router-dom";
import {resetAdminPeriodical} from "../../../../redux/actions/admin/periodical/admin-periodical.actions";

const PeriodicalOrganizeRoot = () => {
    const commonClasses = commonStyle();
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const isDirty = useSelector(currentAdminSelectedPeriodicalDirtySelector);

    const resetPeriodical = () => {
        dispatch(resetAdminPeriodical());
    }

    history.listen(() => {
        resetPeriodical();
    })

    return (
        <Auth requiredRoles={[ADMIN]}>
            <RouteLeavingGuard
                when={isDirty}
                shouldBlockNavigation={() => true}
                navigate={(location) => history.push(location)}
            />
            <Container maxWidth={"xl"} className={commonClasses.root}>
                <PageHeader label={t('admin.periodical.title')} requiredRoles={[ADMIN]}/>
                <Grid container spacing={2} direction={"column"}>
                    <Grid item>
                        <PeriodicalOrganizeSelect/>
                    </Grid>
                    <Grid item>
                        <PeriodicalOrganizeStructure/>
                    </Grid>
                </Grid>
            </Container>
        </Auth>
    );
};

export default PeriodicalOrganizeRoot;

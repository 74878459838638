import React, {useEffect} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import AuthorFilters from "./AuthorFilters";
import AuthorTable from "./AuthorTable";
import Error from "../../commons/Error";
import {useLocation} from "react-router-dom";
import {readAuthorQueryParams} from "../../../utils/author-utils";
import {State} from "../../../redux/reducers";
import QuickResults from "../../commons/QuickResults";
import {useTranslation} from "react-i18next";
import PageHeader from "../../commons/PageHeader";
import {ADMIN, ADMIN_AUTHORS} from "../../../utils/roles";
import {commonStyle} from "../../../styles/common.style";
import {search} from "../../../redux/actions/common-search.actions";
import {AuthorSearchResult, SearchContext} from "../../../types/common-search";
import {SearchStatus} from "../../../redux/reducers/search/common-search.reducer";

export default () => {
    const {t} = useTranslation();
    const classes = commonStyle()
    const dispatch = useDispatch();

    const searchLocation = useLocation().search;

    const filters = readAuthorQueryParams(searchLocation);

    useEffect(() => {
        const filters = readAuthorQueryParams(searchLocation);
        dispatch(search({
            ctx: SearchContext.AUTHOR,
            filters
        }))
    }, [dispatch, searchLocation])

    const [totalCount, results, selectedAuthors, allSelected, allPageSelected, status]: [number, AuthorSearchResult[], string[], boolean, boolean, SearchStatus] =
        useSelector(({search}: State) => {
            const store = search.common[SearchContext.AUTHOR]
            return [store.totalCount, store.searchResults as AuthorSearchResult[], store.selectedIds, store.allSelected, store.allPageSelected, store.status]
        }, shallowEqual)

    if (status !== 'ERROR') {
        return (
            <Container maxWidth={"xl"} className={classes.root}>
                <PageHeader
                    label={filters.query ? "" : "Liste des auteurs"}
                    
                    requiredRoles={[ADMIN, ADMIN_AUTHORS]}
                >
                    {filters.query ?
                        <QuickResults currentPage={"author"} countResult={totalCount}
                                      query={filters.query!!}/> : <></>}
                </PageHeader>

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <AuthorFilters filters={filters}/>
                    </Grid>

                    <Grid item xs={9}>
                        {(results) &&
                        <AuthorTable rows={results} allSelected={allSelected} allPageSelected={allPageSelected}
                                     filters={filters}
                                     selectedAuthors={selectedAuthors}
                                     totalCount={totalCount}/>}
                    </Grid>
                </Grid>
            </Container>)
    } else {
        return (<Error/>)
    }
}

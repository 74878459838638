import {Button, Grid} from "@material-ui/core";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ArticleSearchResult} from "../../../types/common-search";
import {DownloadIcon} from "../../commons/icon/icons";
import Auth from "../../Auth";
import {ADMIN, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {State} from "../../../redux/reducers";
import axios, {AxiosResponse} from "axios";
import {downloadArticle} from "../../../utils/common-utils";
import {notifyError} from "../../../redux/actions/notification.actions";


type Props = {
    article: ArticleSearchResult,
}

function DownloadXmlArticle({article}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')
    const downloadXml = (id: string, isBundle: boolean) => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/${isBundle ? "bundle" : "article"}/xml/${id}`, {headers})
            .then((r: AxiosResponse) => downloadArticle(`${id}.xml`, r.data as string))
            .catch((err: any) => {
                dispatch(notifyError(+err.response.status, {type: isBundle ? 'BUNDLE' : 'ARTICLE'}))
            })
    }


    return (
        <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR]} periodicalRestriction={article.periodicalId}>
            <Grid item>
                <Button
                    className={'lex-left-button'}
                    fullWidth
                    startIcon={<DownloadIcon/>}
                    size={"small"}
                    onClick={() => downloadXml(article.id, article.isBundle)}>
                    {t('misc.download')}
                </Button>
            </Grid>
        </Auth>
    )
}

export default DownloadXmlArticle;
import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {Action} from "../actions/utils.actions";
import {isAuthSuccessAction} from "../actions/auth.actions";
import {isUpdateSearchQueryParamAction} from "../actions/common-search.actions";

const fields = ['periodicalFilter', 'periodicalIdFilter'];

const token_attributesMiddleware: Middleware = (_: MiddlewareAPI) => (next: Dispatch) => (action: Action) => {
    if (isAuthSuccessAction(action) && (sessionStorage.getItem('fav') === null || sessionStorage.getItem('statut_fav') === null)) {
        const token = action.payload.token!;
        const data = JSON.parse(atob(token.split('.')[1]));

        if (sessionStorage.getItem('fav') === null && !!(data?.data?.fav?.split(','))) {
            const fav = data?.data?.fav?.split(',');
            sessionStorage.setItem('fav', fav);
        }

        if (sessionStorage.getItem('statut_fav') === null) {
            const statut_fav = data?.data?.statut_fav !== undefined ? 'true' : 'false'
            sessionStorage.setItem('statut_fav', statut_fav);
        }

    }
    if (isUpdateSearchQueryParamAction(action) && fields.includes(action.payload.field)) {
        sessionStorage.setItem('fav', action.payload.value);
    }

    if (isUpdateSearchQueryParamAction(action) && 'statusFilter' === action.payload.field) {
        sessionStorage.setItem('statut_fav', action.payload.value);
    }
    return next(action);
};

export default token_attributesMiddleware;

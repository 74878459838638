import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import {SwitchBaseProps} from "@material-ui/core/internal/SwitchBase";

const LexCheckbox = ({onChange, checked}: { onChange: SwitchBaseProps['onChange'], checked: boolean }) => {
    return <Checkbox
        checked={checked}
        onChange={onChange}/>
}

const MemoCheckbox = React.memo(
    LexCheckbox,
    (
        prev,
        next) => prev.checked === next.checked)

export default MemoCheckbox

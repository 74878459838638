import {Location} from 'history'
import React, {useEffect, useState} from 'react'
import {Prompt} from 'react-router-dom'
import ConfirmationDialog from "../commons/ConfirmationDialog";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {ActionWithPayload} from "../../redux/actions/utils.actions";

interface Props {
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    title?: string;
    description?: string
    actionOnOk?: ActionWithPayload
    navigateOnCancel?: boolean
}

// https://medium.com/@michaelchan_13570/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
const RouteLeavingGuard = ({
                               when,
                               navigate,
                               shouldBlockNavigation,
                               title,
                               description,
                               actionOnOk, navigateOnCancel
                           }: Props) => {

    const {t} = useTranslation()
    const dispatch = useDispatch();
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
        if (actionOnOk) dispatch(actionOnOk);
    };

    const handleCancelNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, navigate]);

    return (
        <>
            <Prompt when={when} message={handleBlockedNavigation}/>
            <ConfirmationDialog
                title={title ?? t('authors.edit_cancel_confirmation')}
                description={description ?? ''}
                actions={[
                    {
                        label: t('dialogs.cancel'),
                        color: 'default',
                        action: navigateOnCancel ? handleCancelNavigationClick : undefined
                    },
                    {label: t('dialogs.confirm'), action: handleConfirmNavigationClick}//
                ]}
                isOpened={modalVisible}
                onClose={closeModal}
            />
        </>
    );
};
export default RouteLeavingGuard;

import {State} from "../../../reducers";
import {createSelector} from "reselect";

const adminCustomBundleState = (state: State) => state.adminCustomBundle;

// EDIT
export const currentAdminCustomBundlePlanSelector = createSelector(adminCustomBundleState, state => state.adminCustomBundlePlan);

export const currentAdminCustomBundlePlanDirtySelector = createSelector(adminCustomBundleState, state => state.dirty);

export const currentAdminCustomBundlePlanTitleSelector = createSelector(adminCustomBundleState, state => state.adminCustomBundlePlan?.title);
export const currentAdminCustomBundlePlanPeriodicalIdSelector = createSelector(adminCustomBundleState, state => state.adminCustomBundlePlan?.periodicalId);
export const currentAdminCustomBundlePlanTypeSelector = createSelector(adminCustomBundleState, state => state.adminCustomBundlePlan?.type);
export const currentAdminCustomBundlePlanPlanSelector = createSelector(adminCustomBundleState, state => state.adminCustomBundlePlan?.plan);

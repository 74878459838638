import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Auth from "../Auth";
import Hamburtton, {HamburttonProps} from "./Hamburtton";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 20
    },
    button: {
        marginTop: theme.spacing(2)
    },
    delete: {
        paddingLeft: 50,
        marginTop: theme.spacing(2)
    },
    right: {
        paddingLeft: 50
    },
    paper: {
        padding: 15,
        marginBottom: 20
    },
    header: {
        width: "100%"
    },
    topTitle: {
        fontWeight: 200,
        margin: theme.spacing(2, 0)
    },
    box: {
        padding: 0
    },
    rightButton: {
        maxWidth: 400
    }
}))

type PageHeaderProps = {
    label?: string,
    children?: JSX.Element[] | JSX.Element,
    rightPanelChildren?: JSX.Element[] | JSX.Element
    hamburttonProps?: HamburttonProps,
    requiredRoles: string[]
}

export default ({label, children, hamburttonProps, requiredRoles, rightPanelChildren}: PageHeaderProps) => {
    const classes = useStyles()

    return (
        <div className={classes.header}>
            <Box display="flex" p={1} className={classes.box}>
                <Box p={1} flexGrow={1} className={classes.box}>
                    {label && <Typography variant="h5" component="h1" className={classes.topTitle}>{label}</Typography>}
                    {children}
                </Box>

                <Auth requiredRoles={requiredRoles}>
                    <Box p={1}>
                        {hamburttonProps && <Hamburtton
                            {...hamburttonProps}
                        />}
                        {rightPanelChildren}
                    </Box>
                </Auth>
            </Box>
        </div>
    )
}

import noImage from "../../images/no-image.jpg";
import React from "react";
import {Advertisement} from "../../types/ads";
import {makeStyles} from "@material-ui/core/styles";
import {AdSearchResult, CommonImageResult} from "../../types/common-search";
import {IllustrationImage} from "../../types/image";

const useStyles = makeStyles(() => ({
    imageDisabled: {
        filter: 'grayscale(75%)'
    }
}))

type LexImageT =
    AdSearchResult
    | IllustrationImage
    | Advertisement
    | CommonImageResult
    | { id: string, collection: string, version: number }

type Props = {
    image: LexImageT
    maxWidth?: number | string
    maxHeight?: number | string
    size?: 'MEDIUM' | 'THUMBNAIL'
    className?: string | undefined
    disabled?: boolean,
    forceRefresh?: boolean
}

export default ({image, maxWidth, maxHeight, className, disabled, size, forceRefresh}: Props) => {
    const classes = useStyles()

    const buildParams = () => {
        const versionParam = `?version=${image?.version ?? 0}`
        const sizeParam = size ? `&size=${size}` : ''
        const refreshParam = forceRefresh ? `&refesh=${Date.now()}` : ''

        return `${versionParam}${sizeParam}${refreshParam}`
    }

    if (image?.id && image?.collection) {
        return (<img src={`/api/image/${image.id}${buildParams()}`}
                     className={className + (disabled ? (' ' + classes.imageDisabled) : '')}
                     alt={image.id} style={{maxWidth: maxWidth, maxHeight: maxHeight}}/>)
    } else {
        return (<img src={noImage}
                     alt={""} style={{maxWidth: maxWidth, maxHeight: maxHeight}}/>)
    }
}

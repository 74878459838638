import {State} from "../reducers";
import {createSelector} from "reselect";

const newPublicationDialogState = (state: State) => state.publication.dialog

export const openDialog = createSelector(newPublicationDialogState, state => state.open)

export const getPublicationFormPeriodicalId = createSelector(newPublicationDialogState, state => state.form?.periodicalId)
export const getPublicationFormYear = createSelector(newPublicationDialogState, state => state.form?.year)
export const getPublicationFormNumber = createSelector(newPublicationDialogState, state => state.form?.number[0])
export const getPublicationFormEditionType = createSelector(newPublicationDialogState, state => state.form?.editionType)
export const getPublicationFormStartDate = createSelector(newPublicationDialogState, state => state.form?.startDate)
export const getPublicationFormEndDate = createSelector(newPublicationDialogState, state => state.form?.endDate)
export const getPublicationFormArticleOnCover = createSelector(newPublicationDialogState, state => state.form?.article)
export const getPublicationFormImageOnCover = createSelector(newPublicationDialogState, state => state.form?.image)
export const getPublicationFormTextOnCover = createSelector(newPublicationDialogState, state => state.form?.text)
export const getPublicationFormTitle = createSelector(newPublicationDialogState, state => state.form?.title)

// EDIT
export const getPublicationId = createSelector(newPublicationDialogState, state => state.currentEditPublicationId)

const OrganizeState = (state: State) => state.publication.organize;
export const getCurrentPublication = createSelector(OrganizeState, state => state.current)
export const getCurrentPublicationPlan = createSelector(OrganizeState, state => state.currentPlan)

import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import RouteLeavingGuard from "../../RouteLeavingGuard/RouteLeavingGuard";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {useForm} from "react-hook-form";
import LexForm from "../../commons/forms/LexForm";
import ImageUpload from "../../commons/forms/ImageUpload";
import LexImage from "../../commons/LexImage";
import {ImageColor} from "../../../types/image";
import {datePickerRenderer, selectRenderer, tagsRenderer, textRenderer} from "../../commons/forms/LexFormField";
import {Grid, Typography} from "@material-ui/core";
import {
    deleteAd,
    fetchAd,
    fetchAdConfig,
    fetchAdSizes,
    generateNewAd,
    saveAd,
    setAdColor,
    setAdDeadline,
    setAdDescription,
    setAdFormat,
    setAdName,
    setAdPeriodicals,
    setAdPriority,
    toggleAdDisabled
} from "../../../redux/actions/ad.actions";

import {
    currentAdColorSelector,
    currentAdDeadlineSelector,
    currentAdDescriptionSelector,
    currentAdFormatSelector,
    currentAdIdSelector,
    currentAdNameSelector,
    currentAdPeriodicalsSelector,
    currentAdSelector,
    currentUploadedAdSelector,
    currentValidPeriodicalSelector,
    isCurrentAdDirtySelector,
    uploadingAd
} from "../../../redux/selectors/ads.selectors";
import AdsBreadcrumbs from "../commons/AdsBreadcrumbs";
import {State} from "../../../redux/reducers";
import AdsRightPanel from "./AdsRightPanel";
import {ADMIN, ADMIN_ADS} from "../../../utils/roles";
import Toggler from "../../commons/Toggler";
import {AdPriority} from "../../../types/ads";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 10px 0'
    },
    delete: {
        paddingLeft: 50,
        marginTop: theme.spacing(2)
    },
    header: {
        marginBottom: 20
    }
}))

export default function AdsForm() {
    const classes = useStyles()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const [dialogOpen, openDialog] = useState(false);
    const closeDialog = () => openDialog(false);

    const {id} = useParams() as { id: string }

    const isDirty = useSelector(isCurrentAdDirtySelector)
    const size = useSelector(currentAdFormatSelector)

    const currentAdImage = useSelector(currentAdSelector)
    const uploadedAd = useSelector(currentUploadedAdSelector)
    const currentAdImageId = currentAdImage.id
    const adSizes = useSelector(({ads}: State) => ads.sizes)

    const validValues = useSelector(currentValidPeriodicalSelector);

    const initPeriodicals = useSelector(({periodical}: State) => periodical.periodicals)

    const [loaded, setLoaded] = useState(false)
    const [sendOneTime, setSendOneTime] = useState(false)

    const uploading = useSelector(uploadingAd)

    useEffect(() => {
        dispatch(fetchAdSizes())
        dispatch(fetchAdConfig())
    }, [dispatch])

    useEffect(() => {
        if (id) {
            dispatch(fetchAd(id))
        } else {
            dispatch(generateNewAd())
        }
        setLoaded(true)
    }, [dispatch, id]);

    useEffect(() => {
        //#degeu ! force re-render form
        setLoaded(false)
        setLoaded(true)
    }, [size]);

    const {handleSubmit} = useForm<FormData>({
        mode: 'onBlur',
        shouldFocusError: true,
        criteriaMode: "all",
    });

    const onSubmit = handleSubmit(() => {
        setSendOneTime(true)
        if (currentAdImage && uploadedAd)
            dispatch(saveAd())
    });

    const onAdDeletion = (id: string) => () => {
        dispatch(deleteAd({id}))
        history.push(`/${t('routes.ad.root')}`)
    }

    const handleUnload = (ev) => {
        if (isDirty && location.pathname.indexOf("edit") !== -1) {
            ev.preventDefault();
            return ev.returnValue = 'Êtes vous sûr de vouloir quitter cette page ? Des modifications en cours pourraient être perdues';
        }
    }

    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload)
        }
    })

    return (
        <div className={classes.root}>
            <ConfirmationDialog
                title={t('ads.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default', action: closeDialog},
                    {label: t('dialogs.confirm'), action: onAdDeletion(id), variant: 'contained'}
                ]}
                isOpened={dialogOpen}
                onClose={closeDialog}
            />

            <RouteLeavingGuard
                when={isDirty}
                shouldBlockNavigation={() => true}
                navigate={(location) => history.push(location)}
            />

            <Grid container className={classes.header} spacing={2}>
                <Grid item xs={9}>
                    <Typography variant="h4">
                        {t(`ads.${id ? 'edit' : 'create'}`)}
                    </Typography>
                    <AdsBreadcrumbs form={true}/>
                </Grid>
                {id && <Grid item xs={3}>
                    <span>
                        {t("ads.disabled")}
                        <Toggler
                            id={id}
                            enabled={!currentAdImage.disabled}
                            requiredRoles={[ADMIN, ADMIN_ADS]}
                            action={toggleAdDisabled}
                            reverse={true}
                        />
                        {t("ads.enabled")}
                    </span>

                    <Grid container className={classes.delete}>
                        <Button variant="outlined" color="primary" onClick={() => openDialog(true)}>
                            {t('ads.delete')}
                        </Button>
                    </Grid>
                </Grid>}
            </Grid>

            <Grid container spacing={8} style={{width: '100%'}}>
                <Grid item xs={5}>
                    {(uploadedAd && !uploading) &&
                    <LexImage image={{id: uploadedAd.id, collection: 'ADVERTISEMENT', version: uploadedAd.version}}
                              disabled={currentAdImage.disabled} maxWidth={450} size={"MEDIUM"} forceRefresh={true}/>}
                    <ImageUpload collection={'ADVERTISEMENT'} imageId={currentAdImageId}
                                 error={!uploadedAd && sendOneTime}/>

                    <Grid container className={classes.root}>
                        {((!id || currentAdImage?.id) && loaded) && <LexForm
                            type={"ads"}
                            onSubmit={onSubmit}
                            fields={[{
                                name: "id",
                                selector: currentAdIdSelector,
                                disabled: true
                            }, {
                                name: "name",
                                selector: currentAdNameSelector,
                                action: setAdName,
                                required: true,
                            }, {
                                name: "deadline",
                                selector: currentAdDeadlineSelector,
                                action: setAdDeadline,
                                renderer: datePickerRenderer,
                            }, {
                                name: "size",
                                selector: currentAdFormatSelector,
                                action: setAdFormat,
                                renderer: selectRenderer(adSizes.map(it => ({value: it.key, label: it.label}))),
                                disabled: true
                            }, {
                                name: "color",
                                selector: currentAdColorSelector,
                                action: setAdColor,
                                renderer: selectRenderer([{
                                    label: "enums.images.color.COLOR.main",
                                    value: ImageColor.COLOR
                                }, {
                                    label: "enums.images.color.N_AND_ONE_COLOR.main",
                                    value: ImageColor.N_AND_ONE_COLOR
                                }, {
                                    label: "enums.images.color.BLACK_AND_WHITE.main",
                                    value: ImageColor.BLACK_AND_WHITE
                                }]),
                                required: true
                            }, {
                                name: "description",
                                selector: currentAdDescriptionSelector,
                                action: setAdDescription,
                                renderer: textRenderer(true)
                            }, {
                                name: "periodicals",
                                selector: currentAdPeriodicalsSelector,
                                action: setAdPeriodicals,
                                renderer: tagsRenderer(initPeriodicals.map(it => ({
                                    label: it.label,
                                    value: it.id
                                })), 1, undefined, undefined, validValues),
                                required: true,
                                validate: () => false,
                            }, {
                                name: "priority",
                                selector: (state: State) => state.ads.form.currentAd.priority ? AdPriority.PRIORITY : AdPriority.NON_PRIORITY,
                                action: setAdPriority,
                                renderer: selectRenderer([{
                                    label: "Oui",
                                    value: AdPriority.PRIORITY
                                }, {
                                    label: "Non",
                                    value: AdPriority.NON_PRIORITY
                                }])
                            }
                            ]}
                        />}
                    </Grid>
                </Grid>

                <Grid item xs={3}>
                    <AdsRightPanel/>
                </Grid>
            </Grid>
        </div>
    );
}

import {PlanNode} from "../../../../types/plan-node";
import {CustomBundlePlan} from "../../../../types/bundle";
import {Action} from "../../../actions/utils.actions";
import {
    isAdminCustomBundlePlanReset,
    isFetchAdminCustomBundleSuccess,
    isSaveAdminCustomBundleSuccess,
    isSetDirty,
    isSetPeriodicalId,
    isSetPlan,
    isSetTitle,
    isSetType
} from '../../../actions/admin/bundle/admin-custom-bundle.actions';

const setAllPlanNodeTypeToContainer = (plan: PlanNode[]) => {
    return plan.map((planNode: PlanNode) => {
        if (planNode.sub) {
            planNode.sub = setAllPlanNodeTypeToContainer(planNode.sub)
        }

        return {...planNode, type: 'CONTAINER'} as PlanNode
    });
}

export type AdminCustomBundleState = {
    adminCustomBundlePlan?: CustomBundlePlan
    dirty: boolean
}

const init: AdminCustomBundleState = {
    adminCustomBundlePlan: undefined,
    dirty: false
}

export default function AdminCustomBundleReducer(state = init, action: Action): AdminCustomBundleState {
    if (isFetchAdminCustomBundleSuccess(action)) {
        return {
            ...state,
            adminCustomBundlePlan: {
                ...action.payload,
                plan: setAllPlanNodeTypeToContainer(action.payload.plan)
            }
        }
    } else if (isSetDirty(action)) {
        return {
            ...state,
            dirty: action.payload
        }
    } else if (isAdminCustomBundlePlanReset(action)) {
        return init;
    } else if (isSetTitle(action)) {
        return {
            ...state,
            dirty: true,
            adminCustomBundlePlan: {
                ...state.adminCustomBundlePlan!,
                title: action.payload
            }
        }
    } else if (isSetPeriodicalId(action)) {
        return {
            ...state,
            dirty: true,
            adminCustomBundlePlan: {
                ...state.adminCustomBundlePlan!,
                periodicalId: action.payload
            }
        }
    } else if (isSetPlan(action)) {
        return {
            ...state,
            dirty: true,
            adminCustomBundlePlan: {
                ...state.adminCustomBundlePlan!,
                plan: setAllPlanNodeTypeToContainer(action.payload)
            }
        }
    } else if (isSetType(action)) {
        return {
            ...state,
            dirty: true,
            adminCustomBundlePlan: {
                ...state.adminCustomBundlePlan!,
                type: action.payload
            }
        }
    } else if (isSaveAdminCustomBundleSuccess(action)) {
        return {
            ...state,
            dirty: false,
            adminCustomBundlePlan: {
                ...action.payload,
                plan: setAllPlanNodeTypeToContainer(action.payload.plan)
            }
        }
    }
    return state;
}

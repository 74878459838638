import React from "react";
import {FavIcon, FavIconLight} from "../../icon";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles} from "@material-ui/core/styles";

type Props = {
    isFav: boolean
    onClick?: () => void
}

const useStyles = makeStyles(() => ({
    favorite: {
        color: "#ffbe00"
    }
}));

const Fav = ({isFav, onClick}: Props) => {
    const classes = useStyles();
    const icon = isFav
        ? <FavIcon className={classes.favorite}/>
        : <FavIconLight/>
    return (
        <IconButton disabled={isFav || onClick === undefined} onClick={onClick}>
            {icon}
        </IconButton>
    )
}

export default Fav;

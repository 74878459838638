import {Action} from "../../../actions/utils.actions";
import {
    isResetAdminPeriodicalAction,
    isSaveAdminPeriodicalPlan,
    isSetAdminPeriodicalAction,
    isSetAdminPeriodicalPlanAction,
    isSetAdminPeriodicalPlanCollectionAction,
    isSetDirty
} from "../../../actions/admin/periodical/admin-periodical.actions";
import {Periodical} from "../../../../types/periodical";
import {PlanNode, PlanNodeCollection} from "../../../../types/plan-node";

const setAllPlanNodeTypeToContainer = (plan: PlanNode[]) => {
    return plan.map((planNode: PlanNode) => {
        if (planNode.sub) {
            planNode.sub = setAllPlanNodeTypeToContainer(planNode.sub)
        }

        return {...planNode, type: 'CONTAINER'} as PlanNode
    });
}

export type AdminPeriodicalState = {
    dirty: boolean,
    currentSelectedAdminPeriodical?: Periodical,
    currentSelectedAdminPeriodicalPlan?: PlanNodeCollection
}

const init: AdminPeriodicalState = {
    dirty: false,
    currentSelectedAdminPeriodical: undefined,
    currentSelectedAdminPeriodicalPlan: undefined
}

export default function AdminPeriodicalReducer(state = init, action: Action): AdminPeriodicalState {
    if (isSetAdminPeriodicalAction(action)) {
        return {
            ...state,
            dirty: false,
            currentSelectedAdminPeriodical: action.payload,
        }
    } else if (isSetAdminPeriodicalPlanCollectionAction(action)) {
        return {
            ...state,
            currentSelectedAdminPeriodicalPlan: {
                ...action.payload,
                plan: setAllPlanNodeTypeToContainer(action.payload.plan)
            },
        }
    } else if (isSetAdminPeriodicalPlanAction(action)) {
        let planCollection = state.currentSelectedAdminPeriodicalPlan;
        if (planCollection) {
            planCollection.plan = setAllPlanNodeTypeToContainer(action.payload)
        }

        return {
            ...state,
            dirty: true,
            currentSelectedAdminPeriodicalPlan: planCollection,
        }
    } else if (isSaveAdminPeriodicalPlan(action)) {
        return {
            ...state,
            dirty: false
        }
    } else if (isSetDirty(action)) {
        return {
            ...state,
            dirty: action.payload
        }
    } else if (isResetAdminPeriodicalAction(action)) {
        return init;
    }

    return state;
}

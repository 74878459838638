import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import LexImage from "../../commons/LexImage";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Auth from "../../Auth";
import Hamburtton from "../../commons/Hamburtton";
import Loader from "../../commons/Loader";
import TitleTypo from "../../commons/TitleTypo";
import DateTime from "../../commons/DateTime";
import AdsBreadcrumbs from "../commons/AdsBreadcrumbs";
import AdsRightPanel from "../edit/AdsRightPanel";
import {deleteAd, fetchAd, fetchAdSizes, toggleAdDisabled} from "../../../redux/actions/ad.actions";
import {currentAdSelector} from "../../../redux/selectors/ads.selectors";
import {State} from "../../../redux/reducers";
import {ADMIN, ADMIN_ADS} from "../../../utils/roles";
import Toggler from "../../commons/Toggler";
import {downloadAdvertisementImages} from "../../../utils/common-utils";
import {viewPageStyle} from "../../../styles/view-page.style";

export function AdsView() {
    const {id} = useParams() as { id: string }
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const classes = viewPageStyle()
    const history = useHistory()

    useEffect(() => {
        dispatch(fetchAd(id))
        dispatch(fetchAdSizes())
    }, [dispatch, id])

    const [dialogOpen, openDialog] = useState(false);
    const closeDialog = () => openDialog(false)

    const onAdDeletion = (id: string) => () => {
        dispatch(deleteAd({id}))
        history.push(`/${t('routes.ad.root')}`)
    }

    const ad = useSelector(currentAdSelector)
    const adSizes = useSelector(({ads}: State) => ads.sizes)

    if (ad) {
        return (
            <div className={classes.viewRoot}>
                <ConfirmationDialog
                    title={t('ads.delete_confirmation')}
                    description={''}
                    actions={[
                        {label: t('dialogs.cancel'), color: 'default', action: closeDialog},
                        {label: t('dialogs.confirm'), action: onAdDeletion(ad?.id ?? ''), variant: 'contained'}
                    ]}
                    isOpened={dialogOpen}
                    onClose={closeDialog}
                />

                <Grid container className={classes.header}>
                    <Grid item xs={9}>
                        <Typography variant="h4">
                            <span>{ad.id} {ad.name}</span>
                        </Typography>
                        <AdsBreadcrumbs/>
                    </Grid>

                    <Grid item xs={3}>
                        <span>
                            {t("ads.disabled")}
                            <Toggler
                                id={id}
                                enabled={!ad.disabled}
                                requiredRoles={[ADMIN, ADMIN_ADS]}
                                action={toggleAdDisabled}
                                style='noMargin'
                                reverse={true}
                            />
                            {t("ads.enabled")}
                        </span>

                        <Auth requiredRoles={[ADMIN, ADMIN_ADS]}>
                            <Grid container className={classes.delete}>
                                <Hamburtton
                                    buttonAction={{
                                        label: t('ads.edit'),
                                        route: `/${t('routes.ad.root')}/${ad.id}/${t('routes.ad.edit')}`
                                    }}
                                    actions={[
                                        {label: t("misc.export"), action: () => downloadAdvertisementImages([ad.id])},
                                        {label: t("misc.delete"), action: () => openDialog(true)}
                                    ]}
                                />
                            </Grid>
                        </Auth>
                    </Grid>
                </Grid>

                <Grid container spacing={8}>
                    <Grid item xs={9}>
                        <LexImage image={{...ad, collection: 'ADVERTISEMENT'}} maxHeight={"calc(100vh - 124px)"}
                                  maxWidth={"100%"} size={"MEDIUM"}/>

                        <Grid container className={classes.viewRoot}>
                            {
                                [
                                    ["misc.id", ad.id, true],
                                    ["misc.name", ad.name, true],
                                    ["images.deadline",
                                        <DateTime date={ad?.deadline?.toString() ?? ''}/>, ad.deadline],
                                    ["images.size", adSizes.find(size => size.key === ad?.format)?.label ?? "Taille non reconnue", true],
                                    ["images.color", t(`enums.images.color.${ad?.color}.main`), true],
                                    ["ads.periodicals", ad?.periodicals.join(', '), true],
                                    ["ads.priority", ad?.priority ? "Oui" : "Non", true],
                                    ["ads.description", ad?.comments ?? "", ad.comments]
                                ].map((v, idx) => <Grid container item xs={12} key={v[0] + '_' + idx}>
                                        {v[2] && <>
                                            <Grid item xs={2}>
                                                <TitleTypo label={t("" + v[0])}/>
                                            </Grid>
                                            <Grid item xs={10} component={"pre"}>
                                                {v[1]}
                                            </Grid>
                                        </>}
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>

                    <Grid item xs={3}>
                        <AdsRightPanel/>
                    </Grid>
                </Grid>
            </div>)
    } else {
        return (<Loader/>)
    }
}

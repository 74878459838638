import React from "react";
import {AuthorAddress, AuthorEmail, AuthorPhoneNumber} from "../../../types/author";
import Grid from "@material-ui/core/Grid";
import EmailAuthorContent from "./EmailAuthorContent";
import AddresseAuthorContent from "./AddresseAuthorContent";
import PhonesAuthorContent from "./PhonesAuthorContent";

type Props = {
    emails: AuthorEmail[],
    phoneNumbers: AuthorPhoneNumber[],
    addresses: AuthorAddress[]
}


export default ({emails, phoneNumbers, addresses}: Props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                {emails.length > 0 && <EmailAuthorContent emails={emails}/>}
                {addresses.length > 0 && <AddresseAuthorContent addresses={addresses}/>}
                {phoneNumbers.length > 0 && <PhonesAuthorContent phoneNumbers={phoneNumbers}/>}
            </Grid>
        </Grid>)
}

import PeriodicalAutoComplete from "../authors/list/PeriodicalAutoComplete";
import ListItem from "./ListItem";
import React from "react";
import {useTranslation} from "react-i18next";
import {updateSearchQueryParam} from "../../redux/actions/common-search.actions";
import {SearchContext} from "../../types/common-search";
import {useDispatch} from "react-redux";

type Props = {
    publicationPeriodicalFilter: string[]
    ctx: SearchContext
}

export default ({publicationPeriodicalFilter, ctx}: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const updatePeriodic = (value: string[]) => {
        dispatch(updateSearchQueryParam({
            ctx: ctx,
            field: 'publicationPeriodicalFilter',
            value
        }));
    }

    return (
        <ListItem label={t('authors.published_in')}>
            <PeriodicalAutoComplete label={t('authors.published_in')}
                                    selectedOptions={publicationPeriodicalFilter} selectedAction={updatePeriodic}/>
        </ListItem>
    )
}

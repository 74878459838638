import {createActionWithoutPayload, createActionWithPayload} from "../utils.actions";
import {ArticleResponse} from "../../../types/article";
import {PartialArticleSearchResultToggleCover} from "../../epics/articles.epics";
import {SnackbarKey} from "notistack";
import {DateTime} from "luxon";
import {SearchContext} from "../../../types/common-search";

export const [toggleArticleCover, isToggleArticleCoverAction] = createActionWithPayload<{ article: PartialArticleSearchResultToggleCover, flag: boolean }>('TOGGLE_ARTICLE_COVER');
export const [toggleArticleSuccess, isToggleArticleSuccessAction] = createActionWithPayload<boolean>('TOGGLE_ARTICLE_SUCCESS')
export const [toggleArticleError, isToggleArticleErrorAction] = createActionWithoutPayload('TOGGLE_ARTICLE_ERROR')

export const [deleteArticle, isDeleteArticleAction] = createActionWithPayload<{ id: string, isBundle: boolean, redirectUrl?: string }>('DELETE_ARTICLE')
export const [deleteArticleSuccess, isDeleteArticleSuccessAction] = createActionWithoutPayload('DELETE_ARTICLE_SUCCESS')
export const [deleteArticleError, isDeleteArticleErrorAction] = createActionWithoutPayload('DELETE_ARTICLE_ERROR')
export const [deleteArticles, isDeleteArticlesAction] = createActionWithPayload<string[]>('DELETE_ARTICLES')
export const [deleteArticlesSuccess, isDeleteArticlesSuccessAction] = createActionWithoutPayload('DELETE_ARTICLES_SUCCESS')
export const [deleteArticlesError, isDeleteArticlesErrorAction] = createActionWithoutPayload('DELETE_ARTICLES_ERROR')

export const [fetchArticle, isFetchArticleAction] = createActionWithPayload<string>('FETCH_ARTICLE')
export const [fetchArticleSuccess, isFetchArticleSuccessAction] = createActionWithPayload<ArticleResponse>('FETCH_ARTICLE_SUCCESS')
export const [fetchArticleError, isFetchArticleErrorAction] = createActionWithoutPayload('FETCH_ARTICLE_ERROR')

export const [uploadArticle, isUploadArticleAction] = createActionWithPayload<{ files: FileList, notificationKey: SnackbarKey }>('UPLOAD_ARTICLE')
export const [uploadArticleTerminated, isUploadArticleTerminatedAction] = createActionWithPayload<boolean>('UPLOAD_ARTICLE_TERMINATED')

export const [pushArticlesOnlineAction, isPushArticlesOnlineAction] = createActionWithPayload<{ ids: string[], delay?: DateTime, context?: SearchContext, forceOptions?: any }>('PUSH_ARTICLES_ONLINE');
export const [pushArticlesOnlineResponseAction, isPushArticlesOnlineResponseAction] = createActionWithPayload<string[] | undefined>('PUSH_ARTICLES_ONLINE_RESPONSE');

import React from "react";
import { AuthorAddress } from "../../../types/author";
import Grid from "@material-ui/core/Grid";
import { FavIcon } from "../../../icon";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { useTranslation } from "react-i18next";

type Props = {
    addresses: AuthorAddress[]
}
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: 30
        },
        divider: {
            marginTop: 30,
            height: 1,
            marginBottom: 20
        },
        favorite: {
            color: "#ffbe00"
        },
        fav: {
            maxWidth: '18px',
            marginRight: theme.spacing(1)
        }
    }),
);

export default ({ addresses }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    // Marge entre chaque blocs d'adresses sauf pour la dernière 
    const interLine = (index: number) => ({ marginBottom: index !== (addresses.length - 1) ? 10 : 0 })

    return (
        <Grid container className={classes.root}>
            <Grid item xs={2}>
                <FormLabel color={"secondary"}>
                    {t('authors.addresses')}
                </FormLabel>
            </Grid>
            <Grid item xs={10}>
                {
                    addresses.map((addresse, index) => (
                        <div key={index} style={interLine(index)}>
                            <Grid container>
                                <Grid item xs={1} className={classes.fav}>
                                    {addresse.fav && (<FavIcon fontSize={"small"} className={classes.favorite} />)}
                                </Grid>
                                <Grid item xs={11}>
                                    {addresse.street} {addresse.street && <br />}
                                    {addresse.complement}{addresse.complement && <br />}
                                    {addresse.postCode} {addresse.city}{(addresse.postCode || addresse.city) && <br />}
                                    {addresse.country}
                                </Grid>
                            </Grid>
                        </div>
                    ))}
            </Grid>
        </Grid>
    )
}
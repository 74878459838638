import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Filters, SearchContext} from "../../../types/common-search";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import {
    pushArticlesOnlineAction,
    pushArticlesOnlineResponseAction
} from "../../../redux/actions/article/article.actions";
import {DateTimePicker} from "@material-ui/pickers";
import {DateTime, Duration} from "luxon";
import Grid from "@material-ui/core/Grid";
import {
    articleIdsToPushOnlineSelector
} from "../../../redux/selectors/article/article.selectors";


type Props = {
    ids: string[],
    context?: SearchContext,
    forceOptions?: Filters
}

function PushOnlineArticleDialog({ids, context, forceOptions}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const closeDialog = () => {
        dispatch(pushArticlesOnlineResponseAction(undefined))
        setDisplayDateTimePicker(false)
    }

    const articleIdsToPushOnline = useSelector(articleIdsToPushOnlineSelector) || []

    const [displayDateTimePicker, setDisplayDateTimePicker] = useState(false);
    const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now().plus(Duration.fromObject({minutes: 1})));
    const pushOnlineArticles = (delay?: DateTime) => {
        if (ids && ids.length > 0) {
            dispatch(pushArticlesOnlineAction({
                ids: ids,
                context: context,
                delay: delay,
                forceOptions: forceOptions
            }))
        }
    }

    const handleDateChange = (value) => setSelectedDate(value)

    return (
        <ConfirmationDialog
            title={t('workflows.online_confirmation')}
            description={(displayDateTimePicker
                ? <Grid container direction="row"
                        justifyContent={"center"}> <DateTimePicker
                    inputVariant="outlined"
                    value={selectedDate}
                    onChange={handleDateChange}
                /></Grid> : <Grid container>Veuillez choisir quand pousser en ligne</Grid>)}
            actions={[
                {
                    label: t('push-online.now'), action: () => {
                        pushOnlineArticles()
                    }, variant: 'contained'
                },
                {
                    label: t('push-online.push-later'), action: () => {
                        if (displayDateTimePicker) {
                            pushOnlineArticles(selectedDate)

                        }
                        setDisplayDateTimePicker(true)
                    }, variant: 'contained', close: false
                },
                {label: t('dialogs.cancel'), color: 'default', action: () => closeDialog()}
			]}
			isOpened={
				(ids.length > 1 && articleIdsToPushOnline.length > 1)
				|| (articleIdsToPushOnline[0] === ids[0] && articleIdsToPushOnline.length === 1 && ids.length === 1)
			}
			onClose={closeDialog}
		/>
    )
}

export default PushOnlineArticleDialog;

import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {AuthorContacts} from "../../../types/author";
import Paper from "@material-ui/core/Paper";
import ContactAuthorContentForm from "../show/ContactAuthorContentForm";
import List from "@material-ui/core/List";
import ListItem from "../../commons/ListItem";


type Props = {
    contact: AuthorContacts,
    register?: any,
    errors?: any
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: 20,
        borderBottom: 'none'
    },
    error: {
        border: "1px solid " + theme.palette.error.main,
    },
    content: {
        marginTop: theme.spacing(3),
        width: '100%'
    }
}))

export default ({contact, register, errors}: Props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    let errorClassName = classes.paper;
    if (errors &&
        ((errors.emails && errors.emails[contact.contact])
            || (errors.addresses && errors.addresses[contact.contact])
            || (errors.phone && errors.phone[contact.contact]))) {
        errorClassName += " " + classes.error;
    }
    return (
        <Paper variant="outlined" square className={errorClassName}>
            <List component="div" disablePadding>
                <ListItem label={t('authors.contact_types.' + contact.contact)} bold={true}>
                    <div className={classes.content}>
                        {register ?
                            <div/>
                            :
                            <ContactAuthorContentForm addresses={contact.addresses}
                                                      emails={contact.emails} phoneNumbers={contact.phoneNumbers}/>}
                    </div>
                </ListItem>
            </List>
        </Paper>
    )

}

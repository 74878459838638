import {createActionWithPayload} from "./utils.actions";
import {OptionsObject, SnackbarKey, SnackbarMessage} from "notistack";
import i18n from 'i18next';
import {uuidv4} from "../../utils/uuid";

export const [enqueueSnackbar, isEnqueueSnackbarAction] = createActionWithPayload<{
    key: SnackbarKey, notification:
        { message: SnackbarMessage, options: OptionsObject, closeable: boolean }
}>('ENQUEUE_SNACKBAR');

export const notify = (message: SnackbarMessage, options?: OptionsObject, closeable: boolean = false) => {
    const key = uuidv4();

    return enqueueSnackbar({
        key,
        notification: {
            message: message,
            options: {
                ...options,
                key
            },
            closeable,
        }
    })
}

export const notifyWarning = (errorCode: number | string, templateInfos: {}, closeable: boolean = false) =>
    notify(i18n.t(`errors.${errorCode}`, templateInfos), {variant: "warning"}, closeable)

export const notifyError = (errorCode: number | string, templateInfos: {}, closeable: boolean = false) =>
    notify(i18n.t(`errors.${errorCode}`, templateInfos), {variant: "error"}, closeable)

export const notifySuccess = (message, closeable: boolean = false) => notify(message, {variant: "success"}, closeable)

export const [closeSnackbar, isCloseSnackbarAction] = createActionWithPayload<SnackbarKey | undefined>('CLOSE_SNACKBAR');

export const [removeSnackbar, isRemoveSnackbarAction] = createActionWithPayload<SnackbarKey>('REMOVE_SNACKBAR')

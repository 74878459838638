import {Action as ReduxAction} from "redux";

export type SearchSpecificName = 'author' | 'ad' | 'image' | 'publication' | 'article'
    | 'publicationArticle' | 'publicationAd'


export interface Action extends ReduxAction<string> {
}

export interface ActionWithPayload<T = any> extends Action {
    payload: T
}

export const isActionWithPayload = (action: Action): action is ActionWithPayload => (action as { payload?: any }).payload !== undefined;

export const createActionWithoutPayload = (type: string): [() => Action, (action: Action) => boolean] => {
    return [
        (): Action => ({type}),
        (action: Action): boolean => type === action.type
    ]
};

export const createActionWithPayload = <T>(type: string): [(payload: T) => ActionWithPayload<T>, (action: Action) => action is ActionWithPayload<T>] => {
    return [
        (payload: T): ActionWithPayload<T> => ({type, payload}),
        (action: Action): action is ActionWithPayload<T> => type === action.type
    ]
};

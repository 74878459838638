import React, {useEffect, useState} from 'react';
import ListItemCore from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ExpandLessIcon, ExpandMoreIcon} from "../../icon";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    collapse: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    panelTitle: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        background: "rgba(0,0,0,0.03)"
    },
    container: {
        marginBottom: theme.spacing(2)
    }
}));

type Props = {
    defaultOpen?: boolean
    open?: boolean
    onToggle?: () => void
    children: JSX.Element[] | JSX.Element
    label: string | JSX.Element
    bold?: boolean
}

const ListItem: React.FunctionComponent<Props> = ({children, defaultOpen, open, onToggle, label, bold}) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const [controlled] = useState(open !== undefined);

    const [expended, setExpended] = useState(open ?? defaultOpen ?? true);

    useEffect(() => {
        if (controlled) {
            setExpended(open ?? true);
        }
    }, [controlled, open]);

    const handleClick = controlled ? (() => {
        onToggle?.();
    }) : (() => {
        setExpended(!expended);
    })

    return (<>
        <ListItemCore button onClick={() => handleClick()} className={classes.panelTitle}>
            {!bold &&
            <ListItemText primary={label}/>
            }

            {bold && <ListItemText>
                <Typography style={{
                    fontWeight: 400,
                    fontSize: '0.9rem',
                    color: 'rgba(0, 0, 0, 0.75)'
                }}>{label}</Typography>
            </ListItemText>
            }
            {expended ? <Tooltip title={t('misc.expendLess') ?? ''}><ExpandLessIcon/></Tooltip> :
                <Tooltip title={t('misc.expendMore') ?? ''}><ExpandMoreIcon/></Tooltip>}
        </ListItemCore>
        <Collapse in={expended} timeout="auto" unmountOnExit className={classes.collapse}>
            <Grid container className={classes.container}>
                {children}
            </Grid>
        </Collapse>
    </>);
};

export default ListItem;

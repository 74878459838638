import {IconButton, Tooltip} from "@material-ui/core";
import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ArticleSearchResult} from "../../../types/common-search";
import Auth from "../../Auth";
import {ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import {DeleteIcon} from "../../../icon";
import {deleteArticle, deleteArticles} from "../../../redux/actions/article/article.actions";
import {isDoingRequestSelector} from "../../../redux/selectors/article/article.selectors";
import {fixedAttachments} from "../../../types/article";


type Props = {
    selectedIds: string[],
    allSelected: boolean,
    searchResults: ArticleSearchResult[]
}

function RemoveMultipleArticle({selectedIds, allSelected, searchResults}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);
    const isDeleting = useSelector(isDoingRequestSelector)

    const handleArticleDeletion = () => {
        new Promise<void>((resolve) => {
            dispatch(deleteArticles(selectedIds))
            resolve();
        }).then(() => openConfirmationDialog(false))
    }

    const anySelectedIsRattached = useMemo(() => searchResults
        .filter(article => selectedIds.indexOf(article.id) !== -1)
        .some(article => fixedAttachments.includes(article.attached)), [selectedIds])

    const isRemoveDisabled = selectedIds.length === 0 || anySelectedIsRattached || allSelected

    return (
        <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR]}>
            <ConfirmationDialog
                title={t('articles.delete_confirmation', {count: selectedIds.length})}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default', action: () => openConfirmationDialog(false)},
                    {label: t('dialogs.confirm'), action: handleArticleDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, EDITOR, PRODUCER, MANAGER]}>
                <Tooltip title={t(isRemoveDisabled ? 'articles.delete_disabled_attached' :
                    (allSelected ? 'articles.delete_disabled_all_selected' : 'articles.delete_multiple')) ?? ''}>
                        <span>
                            <IconButton
                                color="inherit"
                                disabled={isRemoveDisabled || isDeleting}
                                onClick={() => openConfirmationDialog(true)}>
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                </Tooltip>
            </Auth>

        </Auth>
    )
}

export default RemoveMultipleArticle;

import {CSSProperties} from "react";

export const flexColumn: CSSProperties = {
  display: 'flex',
  flexDirection: 'column'
}

export const growFlex: CSSProperties = {
  display: "flex",
  flexGrow: 1
}

export const flexRowCenter: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}
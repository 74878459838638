import {Action} from "../../actions/utils.actions";
import {
    isAutoCompleteErrorPlanNodesAction,
    isAutoCompletePlanNodesAction,
    isAutoCompletePlanNodesSuccesAction
} from "../../actions/search/autcocomplete-plan-nodes.actions";
import {autoCompleteStatus} from "../../../types/common-search";

export type KeyValue = {
    key: string,
    value: string
}

export type AutoCompletePlanNodesState = {
    results: KeyValue[],
    status: autoCompleteStatus
}

const initAutoComplete: AutoCompletePlanNodesState = {
    results: [],
    status: "NONE"
}

const reducer = (state = initAutoComplete, action: Action): AutoCompletePlanNodesState => {
    if (isAutoCompletePlanNodesAction(action)) {
        return {
            status: 'PENDING',
            results: []
        }
    } else if (isAutoCompleteErrorPlanNodesAction(action)) {
        return {
            status: 'ERROR',
            results: []
        }
    } else if (isAutoCompletePlanNodesSuccesAction(action)) {
        return {
            status: 'LOADED',
            results: action.payload
        }
    } else {
        return state;
    }
}

export default reducer

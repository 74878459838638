import {AxiosStatic} from "axios";
import {merge, Observable, of} from "rxjs";
import {Action} from "../../../actions/utils.actions";
import {fromPromise} from "rxjs/internal-compatibility";
import {catchError, concatMap, filter, map} from "rxjs/operators";
import {isSaveAdminPublicationPlan} from "../../../actions/admin/publication/admin-publication.actions";
import {StateObservable} from "redux-observable";
import {State} from "../../../reducers";
import {EpicDependencies} from "../../../store";
import {notifyError, notifySuccess} from "../../../actions/notification.actions";
import {PlanNodeCollection} from "../../../../types/plan-node";
import i18next from "i18next";

export type AdminPublicationPlanCollection = Pick<PlanNodeCollection, "publicationId" | "nodes">

function _save(axios: AxiosStatic, publicationPlan: AdminPublicationPlanCollection, token: string): Observable<Action> {
    const headers = {
        'Authorization': `Bearer ${token}`
    };

    return fromPromise(axios.put(`/api/publication_plan/publication/${publicationPlan.publicationId}`, publicationPlan, {headers}))
        .pipe(
            map(() => notifySuccess(i18next.t('misc.save_plan_success'))),
            catchError(err => merge(
                of(notifyError(+err.response.status, {type: 'PUBLICATION'}))
                )
            )
        )
}

export function saveAdminPlanForPublication(action$: Observable<Action>, state$: StateObservable<State>, dependencies: EpicDependencies): Observable<Action> {
    const {axios} = dependencies;
    return action$.pipe(
        filter(isSaveAdminPublicationPlan),
        concatMap((action) => {
            return _save(axios, action.payload, state$.value.auth.token ?? "")
        })
    )
}

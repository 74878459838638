import {createActionWithoutPayload, createActionWithPayload} from "../../utils.actions";
import {BundleType, CustomBundlePlan} from "../../../../types/bundle";
import {PlanNode} from "../../../../types/plan-node";

/**
 * CUSTOM BUNDLE
 */

// EDIT
export const [fetchAdminCustomBundle, isFetchAdminCustomBundle] = createActionWithPayload<string>('ADMIN_FETCH_CUSTOM_BUNDLE')
export const [fetchAdminCustomBundleSuccess, isFetchAdminCustomBundleSuccess] = createActionWithPayload<CustomBundlePlan>('ADMIN_FETCH_CUSTOM_BUNDLE_SUCCESS')
export const [fetchAdminCustomBundleError, isFetchAdminCustomBundleError] = createActionWithoutPayload('ADMIN_FETCH_CUSTOM_BUNDLE_ERROR')

export const [adminCustomBundlePlanReset, isAdminCustomBundlePlanReset] = createActionWithoutPayload('ADMIN_CUSTOM_BUNDLE_RESET')

export const [setTitle, isSetTitle] = createActionWithPayload<string>('ADMIN_CUSTOM_BUNDLE_SET_TITLE')
export const [setPeriodicalId, isSetPeriodicalId] = createActionWithPayload<string>('ADMIN_CUSTOM_BUNDLE_SET_PERIODICAL_ID')
export const [setType, isSetType] = createActionWithPayload<BundleType>('ADMIN_CUSTOM_BUNDLE_SET_TYPE')
export const [setPlan, isSetPlan] = createActionWithPayload<PlanNode[]>('ADMIN_CUSTOM_BUNDLE_SET_PLAN')
export const [setDirty, isSetDirty] = createActionWithPayload<boolean>('ADMIN_CUSTOM_BUNDLE_SET_DIRTY')

export const [saveAdminCustomBundle, isSaveAdminCustomBundle] = createActionWithPayload<CustomBundlePlan>('ADMIN_SAVE_CUSTOM_BUNDLE')
export const [saveAdminCustomBundleSuccess, isSaveAdminCustomBundleSuccess] = createActionWithPayload<CustomBundlePlan>('ADMIN_SAVE_CUSTOM_BUNDLE_SUCCESS')
export const [saveAdminCustomBundleError, isSaveAdminCustomBundleError] = createActionWithoutPayload('ADMIN_SAVE_CUSTOM_BUNDLE_ERROR')

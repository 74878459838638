import React, {createContext, PropsWithChildren, useState} from "react";

export const DeleteArticleDialogContext = createContext({
    articleBeingDeleted: {id: "", isBundle: false},
    setArticleBeingDeleted: (_article: { id: string, isBundle: boolean }) => {
    },
    confirmationDialogOpen: false,
    openConfirmationDialog: (_flag: boolean) => {
    }
})

export default function DeleteArticleDialogProvider({children}: PropsWithChildren<any>) {
    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);
    const [articleBeingDeleted, setArticleBeingDeleted] = useState({id: "", isBundle: false})

    return <DeleteArticleDialogContext.Provider value={{
        confirmationDialogOpen, openConfirmationDialog,
        articleBeingDeleted, setArticleBeingDeleted
    }}>
        {children}
    </DeleteArticleDialogContext.Provider>
}

import React from "react";
import {AuthorProfile} from "../../../types/author";
import Grid from "@material-ui/core/Grid";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import TitleTypo from "../../commons/TitleTypo";
import {useTranslation} from "react-i18next";

type Props = {
    profiles: AuthorProfile[]
}
const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        list: {
            marginTop: 0,
            paddingLeft: theme.spacing(2)
        },
        bulletElem: {
            fontSize: '1.8em',
            verticalAlign: 'middle',

            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    }),
);


export default ({profiles}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Grid container className={classes.root}>
            <Grid item xs={2}>
                <TitleTypo label={t("authors.editPanel.profiles")}/>
            </Grid>

            <Grid item xs={10}>
                {profiles.length > 0 && (<ul className={classes.list}>
                    {profiles.map((profile) =>
                        <li>{t(`authors.profile_types.${profile.type}`)} : {profile.value}</li>
                    )}
                </ul>)}
            </Grid>
        </Grid>
    )
}

import React from "react";
import {useTranslation} from "react-i18next";
import AutoCompleteFilter from "./AutoCompleteFilter";
import {SearchContext} from "../../../types/common-search";

const CharacteristicFilter = ({characteristicFilter, selectedAction}) => {
    const {t} = useTranslation();

    const char = [
        {
            label: t('enums.images.characteristics.THUMBNAIL'),
            value: 'THUMBNAIL'
        },
        {
            label: t('enums.images.characteristics.LARGE'),
            value: 'LARGE'
        }, {
            label: t('enums.images.characteristics.COLOR'),
            value: 'COLOR'
        }, {
            label: t('enums.images.characteristics.BLACK_AND_WHITE'),
            value: 'BLACK_AND_WHITE'
        }, {
            label: t('enums.images.characteristics.PORTRAIT'),
            value: 'PORTRAIT'
        }, {
            label: t('enums.images.characteristics.SKETCH'),
            value: 'SKETCH'
        },
        {
            label: t('enums.images.characteristics.WIDE_PLAN'),
            value: 'WIDE_PLAN'
        }, {
            label: t('enums.images.characteristics.BAD_QUALITY'),
            value: 'BAD_QUALITY'
        }
    ]

    return (
        <AutoCompleteFilter ctx={SearchContext.IMAGE} selectedAction={selectedAction} filters={characteristicFilter}
                            options={char}
                            label={t('images.characteristics')}
                            filterName={"characteristicFilter"}/>
    )
}

export default CharacteristicFilter

import {IconButton, Tooltip} from "@material-ui/core";
import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {DownloadIcon} from "../../commons/icon/icons";
import Auth from "../../Auth";
import {ADMIN, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {State} from "../../../redux/reducers";
import axios, {AxiosResponse} from "axios";
import {downloadArticle} from "../../../utils/common-utils";


type Props = {
    ids: { type: string, id: string }[]
    size?: "small" | "medium"
}

function DownloadMultipleArticleXml({ids, size}: Props) {
    const {t} = useTranslation()
    const [inProgress, setInProgress] = React.useState(false);

    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')
    const downloadXml = () => {
        if (inProgress) {
            return;
        }
        setInProgress(true)
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        ids.forEach(({type, id}, index) => {
            setTimeout(() => {
                axios.get(`/api/${type}/xml/${id}`, {headers})
                    .then((r: AxiosResponse) => downloadArticle(`${id}.xml`, r.data as string))
            }, index * 500)
        })
        setInProgress(false)
    }

    if (inProgress) {
        return (<></>)
    } else {
        return (
            <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR]}>
                <Tooltip title={t('articles.download') ?? 'Télécharger'}>
                    <span>
                        <IconButton
                            size={size}
                            color="inherit"
                            onClick={() => downloadXml()}>
                            <DownloadIcon/>
                        </IconButton>
                    </span>
                </Tooltip>
            </Auth>
        )
    }

}

export default DownloadMultipleArticleXml;

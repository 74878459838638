import { DocumentUnit } from "../../../models/DocumentUnit";
import {
  DocumentUnitWithPointer,
  NodeModel,
  TreeModel,
} from "../../../models/TreeModels";
import { TileProps } from "../../administrateTree/AddElement";

export const isDocumentUnit = (
  item: DocumentUnit | DocumentUnitWithPointer
): item is DocumentUnit => "id" in item;

export const isDocumentUnitWithPointer = (
  item: DocumentUnit | DocumentUnitWithPointer
): item is DocumentUnitWithPointer => "documentId" in item;

export const isTile = (
  item: TileProps | NodeModel
): item is TileProps => 'label' in item && 'index' in item

export const isNodeModel = (
  item: TileProps | NodeModel
): item is NodeModel => 'id' in item && 'name' in item && 'documentUnits' in item && 'sub' in item 



export const addNodeIdIntoDocumentUnit = (tree: TreeModel): TreeModel => {
  const recursive = (nodes: NodeModel[]): NodeModel[] => {
    return nodes.map((node) => ({
      ...node,
      documentUnits: node.documentUnits.map((du) => ({
        ...du,
        nodeId: node.id,
      })),
      sub: recursive(node.sub),
    }));
  };

  return {
    ...tree,
    nodes: recursive(tree.nodes),
  };
};

export const getNodesId = (node: NodeModel): string[] => [
  node.id,
  ...node.sub.flatMap(getNodesId),
];
import React, { CSSProperties, FC, LegacyRef, useContext } from "react"
import { NodeModel, updateMultipleAlert } from "../../models/TreeModels"
import { hoverLabelStyle } from "../../utils/DnD.utils"
import { useDropUDOnNodeTitle } from "../../hooks/documentUnitDnDHooks"
import { Checkbox } from "@material-ui/core"
import { Row } from "../../components/FlexBox"
import { TreeContext } from "../../contexts/organizeTreeBibleContexts"
import { TREE_MARGIN_Y, TREE_NODE_FONT_SIZE, TREE_UD_FONT_SIZE } from "../../elementsSize"
import boutonAlerteNiveau from '../../logo/bouton_alerte_niveau.png'
import DOMPurify from "dompurify"

type UDLabelProps = { documentId: string; title: string; innerRef?: LegacyRef<HTMLParagraphElement> }
export const UDLabel = ({ documentId, title, innerRef }: UDLabelProps) => {

  return (
    <p ref={innerRef} style={{ color: '#2C88D9', fontSize: TREE_UD_FONT_SIZE, marginTop: TREE_MARGIN_Y, marginBottom: TREE_MARGIN_Y }}>
      <span style={{ fontWeight: 'bold', marginRight: '1em', textTransform: 'uppercase' }}>
        {documentId}
      </span>
      <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(title)}}/>
    </p>
  )
}

type NodeLabelProps = { node: NodeModel, color?: string, uppercase: boolean }
export const NodeLabel: FC<NodeLabelProps> = ({ node, color, uppercase }) => {
  const pStyle: CSSProperties = {
    color,
    fontWeight: uppercase ? 400 : 'inherit',
    textTransform: uppercase ? 'uppercase' : 'none'
  }
  const [tree, setTree] = useContext(TreeContext)

  const [drop, isOver] = useDropUDOnNodeTitle(node)

  const onlySomeAreChecked = node.documentUnits.some(d => d.alert) && !node.documentUnits.every(d => d.alert)

  const handleUpdateMultipleAlert = () => {
    if (tree.data?.nodes === undefined) return
    const updatedNodes = onlySomeAreChecked || !node.documentUnits.some(d => d.alert)
      ? updateMultipleAlert(tree.data?.nodes, node.id, true)
      : updateMultipleAlert(tree.data?.nodes, node.id, false);
    setTree({ ...tree, data: { ...tree.data, nodes: updatedNodes } })
  }

  return (
    <Row justifyContent="space-between">
      <p style={{ ...pStyle, ...hoverLabelStyle(isOver), flexGrow: 1, fontSize: TREE_NODE_FONT_SIZE, marginTop: TREE_MARGIN_Y, marginBottom: TREE_MARGIN_Y }} ref={drop}>{node.name}</p>
      {node.documentUnits.length > 0 && (
        <Checkbox
          style={{paddingTop: TREE_MARGIN_Y, paddingBottom: TREE_MARGIN_Y}}
          checked={node.documentUnits.some(d => d.alert)}
          // indeterminate={onlySomeAreChecked}
          checkedIcon={<img src={boutonAlerteNiveau} height="20" width="20" />}
          onChange={handleUpdateMultipleAlert}
          onClick={(e) => e.stopPropagation()}
        />
      )}
    </Row>
  )
}
import {useEffect, useRef} from 'react';

type CallbackSignature = () => any

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback: CallbackSignature, delay: number) {
    const savedCallback = useRef<CallbackSignature>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (savedCallback.current !== undefined) {
                savedCallback.current()
            }
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
import React, {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import {useTheme} from "@material-ui/core/styles";
import PageHeader from "../commons/PageHeader";
import {
    initDefaultPeriodicalQueryParam,
    search,
    updateSearchQueryParam
} from "../../redux/actions/common-search.actions";
import {openNewPublicationDialog, uploadPublications} from "../../redux/actions/publication.actions"
import {ADMIN, ADMIN_IMAGES, MANAGER} from "../../utils/roles";
import TablePagination from "../authors/list/TablePagination";
import QuickResults from "../commons/QuickResults";
import PublicationsFilters from "./list/PublicationsFilters";
import {readPublicationQueryParams} from "../../utils/publication-utils";
import PublicationsTable from "./list/PublicationsTable";
import {commonStyle} from "../../styles/common.style";
import {State} from "../../redux/reducers";
import {PublicationSearchResult, SearchContext} from "../../types/common-search";
import {isAuth} from "../Auth";

export default function PublicationsRoot() {
    const {t} = useTranslation()
    const theme = useTheme()
    const classes = commonStyle(theme)
    const dispatch = useDispatch()
    const roles = useSelector(({auth}: State) => auth?.roles ?? []);

    const searchLocation = useLocation().search;
    const filters = readPublicationQueryParams(searchLocation);

    useEffect(() => {
        dispatch(initDefaultPeriodicalQueryParam(SearchContext.PUBLICATION))
    }, [dispatch]);

    useEffect(() => {
        const queryFilters = readPublicationQueryParams(searchLocation);
        dispatch(search({
            ctx: SearchContext.PUBLICATION,
            filters: queryFilters
        }))
    }, [dispatch, searchLocation])

    const [publications, totalCount]: [PublicationSearchResult[], number] = useSelector((state: State) => {
        const search = state.search.common[SearchContext.PUBLICATION];
        return [
            search.searchResults as PublicationSearchResult[],
            search.totalCount
        ];
    });

    const updateChangePage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.PUBLICATION,
            field: 'page',
            value
        }));
    }

    const updateChangeRowPerPage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.PUBLICATION,
            field: 'size',
            value
        }));
    }

    return (
        <>
            <Container maxWidth={"xl"} className={classes.root}>
                <input id={"upload_parutions"} type="file" style={{display: 'none'}}
                       onChange={(e) => {
                           if (e.target.files !== null) {
                               dispatch(uploadPublications(e.target.files))
                           }
                       }}/>

                <PageHeader
                    label={filters.query ? "" : t('publications.list')}
                    hamburttonProps={{
                        buttonAction: {
                            label: t('publications.add'),
                            action: () => {
                                dispatch(openNewPublicationDialog())
                            }
                        },
                        actions: [
                            {
                                label: t("publications.import"),
                                action: () => document.getElementById("upload_parutions")!!.click(),
                                display: isAuth(roles, [ADMIN, MANAGER])
                            }
                        ]
                    }}
                    requiredRoles={[ADMIN, ADMIN_IMAGES, MANAGER]}
                >
                    {filters.query ?
                        <QuickResults currentPage={"parution"} countResult={totalCount}
                                      query={filters.query!!}/> : <></>}
                </PageHeader>

                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <PublicationsFilters filters={filters}/>
                    </Grid>

                    <Grid item xs={9}>
                        <Grid item xs={12}>
                            <PublicationsTable publications={publications} filters={filters}/>

                            <TablePagination page={filters.page || 0}
                                             size={filters.size || 0}
                                             totalCount={totalCount}
                                             updateChangePage={updateChangePage}
                                             updateChangeRowPerPage={updateChangeRowPerPage}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}

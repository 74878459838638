import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import RouteLeavingGuard from "../../RouteLeavingGuard/RouteLeavingGuard";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    deleteIllustration,
    fetchImage,
    generateNewImage,
    saveIllustration,
    setImageColor,
    setImageCredits,
    setImageDescription,
    setImageFormat,
    setImageName,
    setImageQuality,
    setImageSources,
    setImageTags,
    toggleIllustrationDisabled
} from "../../../redux/actions/image.actions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
    currentImageColorSelector,
    currentImageCreditsSelector,
    currentImageDescriptionSelector,
    currentImageFormatSelector,
    currentImageIdSelector,
    currentImageNameSelector,
    currentImageQualitySelector,
    currentImageSelector,
    currentImageSourcesSelector,
    currentImageTagsSelector,
    isCurrentImageDirtySelector,
    uploadingImage
} from "../../../redux/selectors/image.selectors";
import ImageBreadcrumbs from "../commons/ImageBreadcrumbs";
import LexForm from "../../commons/forms/LexForm";
import ImageUpload from "../../commons/forms/ImageUpload";
import LexImage from "../../commons/LexImage";
import {ImageColor, ImageFormat, ImageQuality} from "../../../types/image";
import {checkboxRenderer, selectRenderer, tagsRenderer, textRenderer} from "../../commons/forms/LexFormField";
import ImageRightPanel from "./ImageRightPanel";
import {ADMIN, ADMIN_IMAGES} from "../../../utils/roles";
import Toggler from "../../commons/Toggler";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 10px 0'
    },
    containerRoot: {
        margin: '20px 50px 0'
    },
    button: {
        marginTop: theme.spacing(2)
    },
    delete: {
        paddingLeft: 50,
        marginTop: theme.spacing(2)
    },
    right: {
        paddingLeft: 50
    },
    paper: {
        padding: 15,
        marginBottom: 20
    },
    name: {
        marginTop: theme.spacing(1)
    },
    header: {
        marginBottom: 20
    }
}))

const ImageForm = () => {
    const classes = useStyles()
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const [dialogOpen, openDialog] = useState(false);
    const closeDialog = () => openDialog(false)

    const {id} = useParams() as { id: string }

    const isDirty = useSelector(isCurrentImageDirtySelector)
    const currentImage = useSelector(currentImageSelector)
    const currentImageId = currentImage?.id
    const uploading = useSelector(uploadingImage)

    useEffect(() => {
        if (id) {
            dispatch(fetchImage(id))
        } else {
            dispatch(generateNewImage())
        }
    }, [dispatch, id]);

    const onSubmit = () => dispatch(saveIllustration());

    const onImageDeletion = (id: string) => () => {
        dispatch(deleteIllustration({id, doRedirect: true}))
    }

    const handleUnload = (ev) => {
        if (isDirty && location.pathname.indexOf("edit") !== -1) {
            ev.preventDefault();
            return ev.returnValue = 'Êtes vous sûr de vouloir quitter cette page ? Des modifications en cours pourraient être perdues';
        }
    }

    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload)
        }
    })

    return (
        <div className={classes.containerRoot}>
            <ConfirmationDialog
                title={t('images.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default'},
                    {label: t('dialogs.confirm'), action: onImageDeletion(id), variant: 'contained'}
                ]}
                isOpened={dialogOpen}
                onClose={closeDialog}
            />

            <RouteLeavingGuard
                when={isDirty}
                shouldBlockNavigation={() => true}
                navigate={(location) => history.push(location)}
            />

            <Grid container className={classes.header} spacing={2}>
                <Grid item xs={9}>
                    <Typography variant="h4">
                        {t(`images.${id ? 'edit' : 'create'}`)}
                    </Typography>
                    <ImageBreadcrumbs form={true}/>
                </Grid>

                {id && <Grid item xs={3}>
                    <span>
                        {t("images.disabled")}
                        <Toggler
                            id={id}
                            enabled={!currentImage.disabled}
                            requiredRoles={[ADMIN, ADMIN_IMAGES]}
                            action={toggleIllustrationDisabled}
                            style='noMargin'
                            reverse={true}
                        />
                        {t("images.enabled")}
                    </span>

                    {currentImage.enable && <Grid container className={classes.delete}>
                        <Button variant="outlined" color="primary" onClick={() => openDialog(true)}>
                            {t('images.delete')}
                        </Button>
                    </Grid>}
                </Grid>}
            </Grid>

            <Grid container spacing={8} style={{width: '100%'}}>
                <Grid item xs={5}>
                    {(currentImageId && !uploading) &&
                    <LexImage size={"MEDIUM"}
                              image={{...currentImage, collection: 'ILLUSTRATION'}}
                              maxWidth={450} forceRefresh={true}
                              disabled={currentImage.disabled}/>}
                    <ImageUpload collection={'ILLUSTRATION'} imageId={currentImageId}/>

                    <Grid container className={classes.root}>
                        {(!id || currentImage?.id) && <LexForm
                            type={"images"}
                            onSubmit={onSubmit}
                            fields={[{
                                name: "id",
                                selector: currentImageIdSelector,
                                disabled: true
                            }, {
                                name: "name",
                                selector: currentImageNameSelector,
                                action: setImageName,
                                required: true
                            }, {
                                name: "size",
                                selector: currentImageFormatSelector,
                                action: setImageFormat,
                                renderer: selectRenderer([{
                                    label: "enums.images.format.LARGE.main",
                                    value: ImageFormat.LARGE
                                }, {
                                    label: "enums.images.format.THUMBNAIL.main",
                                    value: ImageFormat.THUMBNAIL
                                }]),
                                disabled: true
                            }, {
                                name: "color",
                                selector: currentImageColorSelector,
                                action: setImageColor,
                                renderer: selectRenderer([{
                                    label: "enums.images.color.COLOR.main",
                                    value: ImageColor.COLOR
                                }, {
                                    label: "enums.images.color.BLACK_AND_WHITE.main",
                                    value: ImageColor.BLACK_AND_WHITE
                                }]),
                                required: true
                            }, {
                                name: "tags",
                                selector: currentImageTagsSelector,
                                action: setImageTags,
                                renderer: tagsRenderer([
                                    "Culture", "Dessin", "Événement pro.", "Individu", "Infographie", "Logo", "Ouvrage", "Image de couverture"
                                ].map(it => ({label: it, value: it})), 0)
                            }, {
                                name: "quality",
                                selector: currentImageQualitySelector,
                                action: setImageQuality,
                                renderer: checkboxRenderer(ImageQuality.BAD, ImageQuality.OK),
                                dontWrap: true
                            }, {
                                name: "credits",
                                selector: currentImageCreditsSelector,
                                action: setImageCredits,
                                renderer: textRenderer(true),
                                required: false
                            }, {
                                name: "sources",
                                selector: currentImageSourcesSelector,
                                action: setImageSources,
                                renderer: textRenderer(true),
                                required: false
                            }, {
                                name: "description",
                                selector: currentImageDescriptionSelector,
                                action: setImageDescription
                            },
                            ]}
                        />}
                    </Grid>
                </Grid>

                <Grid item xs={3}>
                    <ImageRightPanel image={currentImage}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default ImageForm

import {createSelector} from "reselect";
import {State} from "../reducers";
import {AuthorType, isSaved, PersistedAuthor} from "../../types/author";

export const currentAuthorSelector = createSelector((state: State) => state.author.current, author => author as PersistedAuthor)
export const authorStatusSelector = createSelector(({author}: State) => author, author => author.status)

export const authorIdSelector = createSelector(currentAuthorSelector, author => author?.id ?? '')
export const authorIsSavedSelector = createSelector(currentAuthorSelector, author => isSaved(author))
export const authorFirstNameSelector = createSelector(currentAuthorSelector, author => author?.firstName ?? '')
export const authorLastNameSelector = createSelector((state: State) => state.author.current, author => author?.lastName ?? '')
export const authorParticleSelector = createSelector(currentAuthorSelector, author => author?.particle ?? '')
export const authorTypeSelector = createSelector(currentAuthorSelector, author => author?.type ?? AuthorType.MALE)
export const authorFunctionsSelector = createSelector(currentAuthorSelector, author => author?.functions ?? [])
export const authorEmailsSelector = createSelector(currentAuthorSelector, author => author?.emails ?? [])
export const authorPhoneNumbersSelector = createSelector(currentAuthorSelector, author => author?.phoneNumbers ?? [])
export const authorPhoneAdressesSelector = createSelector(currentAuthorSelector, author => author?.addresses ?? [])
export const authorNotesSelector = createSelector(currentAuthorSelector, author => author?.notes ?? [])
export const uploadingAuthorImage = createSelector(({author}: State) => author, author => author.uploading)

export const fullNameStaticSelector = createSelector((state: State) => {
    const {firstName, particle, lastName} = state.author;
    return [firstName, particle, lastName]
        .filter(str => str)
        .join(' ')
}, fullName => fullName as string)

const _currentAuthorImageFieldSelector = (index: number, field: string) => (({author}: State) => author.current!.images[index][field])
export const currentAuthorImageSelector = (index: number) => (({author}: State) => author.current!.images[index])
export const currentAuthorImageIdSelector = (index: number) => _currentAuthorImageFieldSelector(index, 'id')
export const currentAuthorImageFavSelector = (index: number) => _currentAuthorImageFieldSelector(index, 'fav')
export const currentAuthorImageFormatSelector = (index: number) => _currentAuthorImageFieldSelector(index, 'format')
export const currentAuthorImageColorSelector = (index: number) => _currentAuthorImageFieldSelector(index, 'color')
export const currentAuthorImageFramingSelector = (index: number) => _currentAuthorImageFieldSelector(index, 'framing')
export const currentAuthorImageSourcesSelector = (index: number) => _currentAuthorImageFieldSelector(index, 'sources')
export const currentAuthorImageCommentsSelector = (index: number) => _currentAuthorImageFieldSelector(index, 'comments')
export const currentAuthorImageCreditsSelector = (index: number) => _currentAuthorImageFieldSelector(index, 'credits')

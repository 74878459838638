import {AuthorType, ContactTypes, EditableAuthor, isSaved, PersistedAuthor} from "../../types/author";
import {AuthorImage} from "../../types/image";
import {Action} from "../actions/utils.actions";
import {
    Field,
    isAddImageAuthorAction,
    isAddImageAuthorErrorAction,
    isAddImageAuthorSuccessAction,
    isAddNewContactTypeAction,
    isAddNewFieldAction,
    isCurrentEditImagePanelAction,
    isDeleteAuthorAction,
    isFetchAuthorAction,
    isFetchAuthorErrorAction,
    isFetchAuthorSuccessAction,
    isGenerateNewAuthorAction,
    isReactivateImageAuthorSuccessAction,
    isRemoveFieldAction,
    isRemoveMultipleImagesSuccess,
    isSaveAuthorErrorAction,
    isSaveAuthorSuccessAction,
    isSelectImagesAction,
    isSetFavFieldAction,
    isToggleAuthorEnabledAction,
    isToggleAuthorEnabledSuccessAction,
    isToggleContactPanelAction,
    isUpdateFieldInputAction,
    isUpdateInputAction,
    isUpdateStatusAuthorAction
} from "../actions/author.actions";
import {isAddressEmpty, isEmailEmpty, isPhoneNumberEmpty} from "../../utils/author-utils";

type ContactPanel = {
    open: boolean
}

export type AuthorStateStatus = 'NONE' | 'PENDING' | 'LOADED' | 'ERROR'

type AuthorState = {
    uploading: boolean
    status: AuthorStateStatus
    current?: EditableAuthor | PersistedAuthor
    firstName?: string
    particle?: string
    lastName?: string
    form: {
        currentEditingImage: string | null
        selectedImages: number[]
        contactsPanel: {
            [ContactTypes.PROFESSIONAL]?: ContactPanel,
            [ContactTypes.PERSONAL]?: ContactPanel,
            [ContactTypes.OTHER]?: ContactPanel
        },
        isDirty: boolean
    }
}

const init: AuthorState = {
    uploading: false,
    status: 'NONE',
    current: undefined,
    form: {
        currentEditingImage: null,
        selectedImages: [],
        contactsPanel: {},
        isDirty: false
    }
};

const initContactPanels = (author: EditableAuthor) => {

    const emails = author.emails
        ?.filter(isEmailEmpty)
        ?.map(({contact}) => contact) ?? [];

    const phoneNumbers = author.phoneNumbers
        ?.filter(isPhoneNumberEmpty)
        ?.map(({contact}) => contact) ?? [];

    const addresses = author.addresses
        ?.filter(isAddressEmpty)
        ?.map(({contact}) => contact) ?? [];

    const existing = [ContactTypes.PROFESSIONAL, ContactTypes.PERSONAL, ContactTypes.OTHER]
        .filter(type => emails.includes(type) || phoneNumbers.includes(type) || addresses.includes(type));

    return existing.reduce((pre, cur, i) => {
        return {
            ...pre,
            [cur]: {
                open: i === 0
            }
        };
    }, {});

};

const contacts = [ContactTypes.PROFESSIONAL, ContactTypes.PERSONAL, ContactTypes.OTHER];
const DefaultFieldMapping = {
    [Field.EMAIL]: (contact: ContactTypes) => ({contact, fav: false, mail: ''}),
    [Field.PHONE_NUMBER]: (contact: ContactTypes) => ({contact, fav: false, countryCode: 'FR', number: ''}),
    [Field.ADDRESS]: (contact: ContactTypes) => ({
        contact,
        fav: false,
        street: '',
        complement: '',
        postCode: '',
        city: '',
        country: 'FRANCE'
    }),
    [Field.FUNCTION]: () => '',
    [Field.FONTO_FUNCTION]: () => '',
    [Field.PROFILE]: () => ({value: ''}),
};

const genNewAuthor = (): EditableAuthor => {
    return {
        type: AuthorType.MALE,
        particle: '',
        firstName: '',
        lastName: '',
        notes: '',
        images: [],
        functions: [DefaultFieldMapping[Field.FUNCTION]()],
        emails: contacts.map(contact => DefaultFieldMapping[Field.EMAIL](contact)),
        phoneNumbers: contacts.map(contact => DefaultFieldMapping[Field.PHONE_NUMBER](contact)),
        addresses: contacts.map(contact => DefaultFieldMapping[Field.ADDRESS](contact)),
        profiles: [DefaultFieldMapping[Field.PROFILE]()]
    }
}

const generateDefaultFields = <T extends EditableAuthor>(author: T): T => {

    const images = author.images.map(image => {
        return image.sources.length > 1 ? image
            : {...image, sources: ''}
    });

    const emails = contacts
        .filter(contact => !author.emails.map(({contact}) => contact).includes(contact))
        .map(contact => DefaultFieldMapping[Field.EMAIL](contact));

    const phoneNumbers = contacts
        .filter(contact => !author.phoneNumbers.map(({contact}) => contact).includes(contact))
        .map(contact => DefaultFieldMapping[Field.PHONE_NUMBER](contact));

    const addresses = contacts
        .filter(contact => !author.addresses.map(({contact}) => contact).includes(contact))
        .map(contact => DefaultFieldMapping[Field.ADDRESS](contact));

    return {
        ...author,
        images,
        emails: [...author.emails, ...emails],
        phoneNumbers: [...author.phoneNumbers, ...phoneNumbers],
        addresses: [...author.addresses, ...addresses],
        functions: author.functions.length ? author.functions : [DefaultFieldMapping[Field.FUNCTION]()],
        profiles: author.profiles.length ? author.profiles : [DefaultFieldMapping[Field.PROFILE]()]
    };
}

const setFav = <T extends EditableAuthor>(author: T, field: Field, index: number): T => {
    const former: any[] = author[field];
    const array = former.map((field, idx) => ({
        ...field,
        fav: idx === index
    }));
    return {
        ...author,
        [field]: array
    };
};

const update = (curr, input, value) => input ? {...curr, [input]: value} : value;
const updateField = <T extends EditableAuthor>(author: T, field: Field, index: number, input: string | undefined, value: any): T => {
    const former: any[] = author[field];
    const array = former.map((field, idx) => index === idx ? update(field, input, value) : field);
    return {
        ...author,
        [field]: array
    };
};

export const removeField = <T extends EditableAuthor>(author: T, field: Field, index: number): T => {
    // @ts-ignore
    const curr: ContactTypes | undefined = author[field][index]?.contact;
    const head = author[field].slice(0, index);
    const tail = author[field].slice(index + 1);
    // @ts-ignore
    const create = field !== Field.IMAGE && author[field].filter(({contact}, idx) => {
        return contact === curr && idx !== index;
    }).length === 0;
    const array = [...head, ...tail, ...(create ? [DefaultFieldMapping[field](curr)] : [])];
    return {
        ...author,
        [field]: array
    };
};

export const removeAndDisableMultipleImages = <T extends EditableAuthor>(author: T, deletedImagesIds: string[], disabledImageIds: string[]): T => {
    return {
        ...author,
        images: author.images.filter((image) => deletedImagesIds.indexOf(image.id) === -1).map((image) => {
            if (disabledImageIds.indexOf(image.id) > -1) {
                return {
                    ...image,
                    disabled: true
                }
            } else {
                return image
            }
        })
    }
};

export const enableImage = <T extends EditableAuthor>(author: T, imageId: string): T => {
    return {
        ...author,
        images: author.images.map((image) => {
            if (imageId === image.id) {
                return {
                    ...image,
                    disabled: false
                }
            } else {
                return image
            }
        })
    }
};

const addField = <T extends EditableAuthor>(author: T, field: Field, contact?: ContactTypes): T => {
    const tail = DefaultFieldMapping[field](contact);
    return {
        ...author,
        [field]: [...author[field], tail]
    };
};

const reducer = (state = init, action: Action): AuthorState => {
    if (isGenerateNewAuthorAction(action)) {
        return {
            ...state,
            status: 'LOADED',
            current: genNewAuthor(),
            form: {
                currentEditingImage: null,
                selectedImages: [],
                contactsPanel: {},
                isDirty: false
            }
        };
    } else if (isFetchAuthorAction(action)) {
        const keep = isFetchAuthorAction(action) && state.current && isSaved(state.current) && action.payload.id === state.current.id;
        const edit = isFetchAuthorSuccessAction(action) && action.payload.edit;
        return keep ? {
            ...state,
            status: 'PENDING',
            current: edit && state.current ? generateDefaultFields(state.current) : state.current,
            form: {
                currentEditingImage: null,
                selectedImages: [],
                contactsPanel: {},
                isDirty: false
            }
        } : {
            ...state,
            status: 'PENDING',
            current: undefined,
            firstName: undefined,
            particle: undefined,
            lastName: undefined,
            form: {
                currentEditingImage: null,
                selectedImages: [],
                contactsPanel: {},
                isDirty: false
            }
        }
    } else if (isFetchAuthorSuccessAction(action) || isSaveAuthorSuccessAction(action)) {
        const edit = isFetchAuthorSuccessAction(action) && action.payload.edit;
        const {firstName, particle, lastName} = action.payload.author;
        return {
            ...state,
            status: 'LOADED',
            current: edit ? generateDefaultFields(action.payload.author) : action.payload.author,
            firstName,
            particle,
            lastName,
            form: {
                currentEditingImage: null,
                selectedImages: [],
                contactsPanel: initContactPanels(action.payload.author),
                isDirty: false
            }
        }
    } else if (isUpdateStatusAuthorAction(action)) {
        return {
            ...state,
            status: action.payload
        }
    } else if (isDeleteAuthorAction(action)) {
        return state
    } else if (isToggleAuthorEnabledAction(action)) {
        return state
    } else if (isToggleAuthorEnabledSuccessAction(action)) {
        return {
            ...state,
            current: {
                ...(state.current!),
                enable: action.payload.valueOf()
            }
        }
    } else if (isFetchAuthorErrorAction(action) || isSaveAuthorErrorAction(action)) {
        return {...state, current: undefined, status: 'ERROR'}
    } else if (isUpdateInputAction(action)) {
        const {input, value} = action.payload
        return {
            ...state,
            current: {
                ...(state.current!),
                [input]: value
            },
            form: {
                ...state.form,
                isDirty: true
            }
        };
    } else if (isSetFavFieldAction(action)) {
        const {field, index} = action.payload;
        return {
            ...state,
            current: setFav(state.current!, field, index),
            form: {
                ...state.form,
                isDirty: true
            }
        };
    } else if (isUpdateFieldInputAction(action)) {
        const {field, index, input, value} = action.payload;
        return {
            ...state,
            current: updateField(state.current!, field, index, input, value),
            form: {
                ...state.form,
                isDirty: true
            }
        };
    } else if (isRemoveFieldAction(action)) {
        const {field, index} = action.payload;
        let current = removeField(state.current!, field, index);
        if (field === Field.FUNCTION) {
            current = removeField(current, Field.FONTO_FUNCTION, index)
        }
        return {
            ...state,
            current,
            form: {
                ...state.form,
                isDirty: true
            }
        };
    } else if (isRemoveMultipleImagesSuccess(action)) {
        const {disabled, deleted} = action.payload;
        return {
            ...state,
            current: removeAndDisableMultipleImages(state.current!, deleted, disabled),
            form: {
                ...state.form,
                selectedImages: [],
                isDirty: true
            }
        };
    } else if (isReactivateImageAuthorSuccessAction(action)) {
        return {
            ...state,
            current: enableImage(state.current!, action.payload),
            form: {
                ...state.form,
                selectedImages: [],
                isDirty: true
            }
        }
    } else if (isAddNewFieldAction(action)) {
        const {field, contact} = action.payload;
        return {
            ...state,
            current: addField(state.current!, field, contact),
            form: {
                ...state.form,
                isDirty: true
            }
        };
    } else if (isAddImageAuthorAction(action)) {
        return {
            ...state,
            uploading: true
        }
    } else if (isAddImageAuthorSuccessAction(action)) {
        const {id, meta, format} = action.payload;
        const image: AuthorImage = {
            id,
            fav: false,
            used: false,
            disabled: false,
            format,
            credits: '',
            sources: '',
            comments: '',
            modificationDate: '',
            uploadDate: '',
            meta
        }
        const current = {
            ...(state.current!),
            images: [...(state.current?.images ?? []), image],
        }
        return {
            ...state,
            uploading: false,
            form: {
                ...state.form,
                currentEditingImage: id,
                isDirty: true
            },
            current
        }
    } else if (isAddImageAuthorErrorAction(action)) {
        return {
            ...state,
            uploading: false
        }
    }
    if (isCurrentEditImagePanelAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                currentEditingImage: action.payload,
                isDirty: true
            },
        }
    }
    if (isAddNewContactTypeAction(action)) {
        const type = action.payload
        return {
            ...state,
            form: {
                ...state.form,
                contactsPanel: {
                    ...state.form.contactsPanel,
                    [type]: {
                        open: true
                    }
                },
                isDirty: true
            },
        }
    }
    if (isToggleContactPanelAction(action)) {
        const type = action.payload
        return {
            ...state,
            form: {
                ...state.form,
                contactsPanel: {
                    ...state.form.contactsPanel,
                    [type]: {
                        open: !state.form.contactsPanel[type]!.open
                    }
                },
                isDirty: true
            },
        }
    }
    if (isSelectImagesAction(action)) {
        return {
            ...state,
            form: {
                ...state.form,
                selectedImages: [...action.payload]
            }
        }
    } else {
        return state
    }
}

export default reducer

import { useHandleSearchChange, useThematicsChange, useTypeActeChange } from "../../../hooks/searchHooks"
import { SearchContext } from "../../../contexts/organizeTreeBibleContexts"
import { OxygenCategoryEnum, OxygenTypeEnum,  displayOxygenCategoryEnum, displayOxygenTypeEnum, setOxygenCategoryFromString, setOxygenThematics, setOxygenTypeActe, setOxygenTypeFromString } from "../../../models/Search"
import { Chip, CircularProgress, RadioGroup, Tooltip, makeStyles } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import React, { Children, useContext, useState } from "react"
import { Column, Row } from "../../../components/FlexBox"
import { KyrCheckbox } from "../../../components/KyrCheckBox"
import { KyrFormControlLabel } from "../../../components/KyrFormControlLabel"
import { KyrRadio } from "../../../components/KyrRadio"
import { KyrTextField } from "../../../components/KyrTextField"
import { useFetchDataOnMount } from "../../../hooks/useFetchDataOnMount"
import { ChildrenProps } from "../../../types"
import { rowGap } from "./AdvancedFIlters"

const OxygenTypes = () => {
  const [searchState] = useContext(SearchContext)

  const handleChange = useHandleSearchChange()

  return (
    <RadioGroup style={{flexGrow: 0.5, justifyContent: 'space-between'}} row value={searchState?.oxygenType} onChange={({ target: { value } }) => {
      const updatedSearch = setOxygenTypeFromString(value)(searchState)
      handleChange(updatedSearch)
    }}>
      {Object.values(OxygenTypeEnum).map(value => (
        <KyrFormControlLabel
          key={value}
          value={value}
          control={<KyrRadio />}
          label={displayOxygenTypeEnum(value)}
        />
      ))}
    </RadioGroup>
  )
}

const OxygenCategory = () => {
  const [searchState] = useContext(SearchContext)

  const handleChange = useHandleSearchChange()

  return (
    <>
      {Object.values(OxygenCategoryEnum).map((value) => (
        <KyrFormControlLabel
          key={value}
          control={
            <KyrCheckbox
              checked={searchState.oxygenCategory.includes(value)}
              onChange={({ target: { checked } }) => {
                const updatedSearch = setOxygenCategoryFromString(checked, value)(searchState)
                handleChange(updatedSearch)
              }}
              value={value}
            />
          }
          label={displayOxygenCategoryEnum(value)}
        />
      ))}
    </>
  )
}

const ThematicsAutoComplete = () => {
  const [searchState] = useContext(SearchContext)
  const [thematics, setThematics] = useFetchDataOnMount<string[]>({
    url: '/api/kyrielle/oxygen/thematics',
    method: 'post',
    body: ""
  })

  const {tag} = makeStyles({
    tag: {
      maxWidth: '25%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  })()
  

  const handleChange = useHandleSearchChange()
  const handleThematicsChange = useThematicsChange(setThematics)

  const [open, setOpen] = useState(false)
  return (
    <Autocomplete<string, true>
      classes={{
        tag
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      style={{ flexGrow: 1, maxWidth: '100%' }}
      limitTags={3}
      options={thematics.data ?? []}
      onChange={(_, value) => {
        handleChange(setOxygenThematics(value)(searchState))
      }}
      onInputChange={(_, inputTextValue) => {
        handleThematicsChange(inputTextValue, true)
      }}
      disableListWrap
      multiple
      getOptionLabel={(option) => option}
      renderOption={(option, { selected }) => (
        <>
          <KyrCheckbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip title={option} key={index}>
            <Chip label={option} {...getTagProps({ index })} />
          </Tooltip>
        ))
      }
      renderInput={(params) => (
        <KyrTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {thematics.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label="Thématique"
        />
      )}
    />
  )
}

const TypeActeAutoComplete = () => {
  const [searchState] = useContext(SearchContext)
  const [typeActe, setTypeActe] = useFetchDataOnMount<string[]>({
    url: '/api/kyrielle/oxygen/type-acte',
    method: 'post',
    body: ""
  })


  const handleChange = useHandleSearchChange()
  const handleTypeActeChange = useTypeActeChange(setTypeActe)
  const {tag} = makeStyles({
    tag: {
      maxWidth: '70%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  })()

  return (
    <Autocomplete<string, true>
      classes={{tag}}
      style={{ flexGrow: 1 }}
      limitTags={1}
      options={typeActe.data ?? []}
      onChange={(_, value) => {
        handleChange(setOxygenTypeActe(value)(searchState))
      }}
      onInputChange={(_, inputTextValue) => {
        handleTypeActeChange(inputTextValue, true)
      }}
      disableListWrap
      multiple
      getOptionLabel={(option) => option}
      renderOption={(option, { selected }) => (
        <>
          <KyrCheckbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip title={option} key={index}>
            <Chip label={option} {...getTagProps({ index })} />
          </Tooltip>
        ))
      }
      renderInput={(params) => (
        <KyrTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {typeActe.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label="Type acte"
        />
      )}
    />
  )
}

const Layout = ({ children }: ChildrenProps) => {
  const childrenList = Children.toArray(children)

  return (
    <Column rowGap={rowGap}>
      <Row alignItems="center" justifyContent="space-between" colGap="2%">
        <Column grow={0.1}>
          <Row justifyContent="space-between">{childrenList[0]}</Row>
          <Row justifyContent="space-between">{childrenList[1]}</Row>
        </Column>
        <Column grow={0.9}>
          {childrenList[2]}
        </Column>
      </Row>
      <Row alignItems="center">
        {childrenList[3]}
      </Row>
    </Column>
  )
}



export const OxygenAdvancedFilters = () => {

  return (
    <Layout>
        <OxygenTypes />
        <OxygenCategory />
        <TypeActeAutoComplete />
        <ThematicsAutoComplete />
    </Layout>
  )
}
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { AuthorSearchFilters, AuthorSearchResult, SearchContext } from "../../../types/common-search";
import { CopyIcon, DeleteIcon, EditIcon, ViewIcon } from "../../../icon";
import { selectAllPage, selectOneElement, updateSearchQueryParam } from "../../../redux/actions/common-search.actions";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from 'react-router-dom'
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import PhoneNumber from "../commons/PhoneNumber";
import DateTime from "../../commons/DateTime";
import AuthorGroupActions from "./AuthorGroupActions";
import Auth from "../../Auth";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import { deleteAuthor, deleteAuthorSuccess } from "../../../redux/actions/author.actions";
import { copyToWord } from "../../../utils/author-utils";
import LexImage from "../../commons/LexImage";
import { ADMIN, ADMIN_AUTHORS } from "../../../utils/roles";
import TablePagination from "./TablePagination";
import { State } from "../../../redux/reducers";
import { makeSortHandler, sortDirection } from "../../../utils/table-utils";
import { tableStyle } from "../../../styles/table.style";

type Props = {
    rows: AuthorSearchResult[],
    totalCount: number,
    filters: AuthorSearchFilters,
    selectedAuthors: string[],
    allPageSelected: boolean,
    allSelected: boolean
}

const mapper = {
    UPDATE: { default: "ASC", DESC: "", ASC: "DESC" },
    ID: { default: "ASC", DESC: "", ASC: "DESC" },
    NAME: { default: "ASC", DESC: "", ASC: "DESC", }
}

export default ({ rows, filters, selectedAuthors, allSelected, allPageSelected, totalCount }: Props) => {
    const theme = useTheme()
    const classes = tableStyle(theme);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // todo : inutile ??
    const loaded = useSelector(({ }: State) => false)

    const [dialogOpen, openDialog] = useState(false);
    const [authorDeletionId, changeAuthorDeletionId] = useState("");
    const closeDialog = () => openDialog(false)

    const onDeleteAuthorClick = (id: string) => {
        changeAuthorDeletionId(id);
        openDialog(true);
    }

    const sortHandler = makeSortHandler(SearchContext.AUTHOR, mapper, filters, dispatch, updateSearchQueryParam)
    const deleteAuthorAction = () => dispatch(deleteAuthor({ id: authorDeletionId, refresh: false }))

    const handleCopy = (author: AuthorSearchResult) => () => {
        copyToWord(dispatch, t, author);
    };

    const updateChangePage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.AUTHOR,
            field: 'page',
            value
        }));
    }

    const createHtml = (title: string) => {
        return { __html: title };
    }

    const updateChangeRowPerPage = (value: number) => {
        dispatch(updateSearchQueryParam({
            ctx: SearchContext.AUTHOR,
            field: 'size',
            value
        }));
    }

    return (
        <>
            <ConfirmationDialog
                title={t('authors.delete_confirmation')}
                description={''}
                actions={[
                    { label: t('dialogs.cancel'), color: 'default', close: true },
                    { label: t('dialogs.confirm'), action: deleteAuthorAction, variant: 'contained', close: true }
                ]}
                isOpened={dialogOpen}
                onClose={closeDialog}
            />

            <TableContainer className={classes.root}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" className={classes.cell}>
                                <Checkbox
                                    checked={allPageSelected}
                                    onChange={(event, check) => {
                                        dispatch(selectAllPage({ ctx: SearchContext.AUTHOR, allPages: check }))
                                    }}
                                />
                            </TableCell>
                            <TableCell align={"center"} className={classes.cell}>{t('authors.photo')}</TableCell>
                            <TableCell key={"ID"}
                                align={"center"} className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "ID") !== undefined}
                                    direction={sortDirection(filters, "ID")}
                                    onClick={sortHandler("ID")}>
                                    {t('misc.id')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}
                                key={"NAME"}
                                align={"center"}>
                                <TableSortLabel
                                    active={sortDirection(filters, "NAME") !== undefined}
                                    direction={sortDirection(filters, "NAME")}
                                    onClick={sortHandler("NAME")}>
                                    {t('authors.last_name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={"center"} className={classes.cell}>{t('authors.phone')}</TableCell>
                            <TableCell align={"center"} className={classes.cell}>{t('authors.email')}</TableCell>
                            <TableCell align={"center"} className={classes.cell}>{t('authors.functions')}</TableCell>
                            <TableCell key={"UPDATE"}
                                align={"center"} className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "UPDATE") !== undefined}
                                    direction={sortDirection(filters, "UPDATE")}
                                    onClick={sortHandler("UPDATE")}>
                                    {t('authors.updated')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={"center"} className={classes.cell}>{t('misc.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(selectedAuthors && selectedAuthors.length > 0) &&
                            <TableRow key={"selectedRow"} className={classes.actionGroup}>
                                <TableCell colSpan={9} className={classes.cell}>
                                    <AuthorGroupActions idSelected={selectedAuthors} totalCount={totalCount}
                                        allSelected={allSelected} allPageSelected={allPageSelected}
                                        authors={rows} />
                                </TableCell>
                            </TableRow>}

                        {rows.length > 0 && rows.map((row, index) => (
                            <TableRow key={row.id} className={`${classes.row}`}
                                hover={true}
                            >
                                <TableCell padding="checkbox" className={classes.cell}>
                                    <Checkbox
                                        checked={selectedAuthors.indexOf(row.id) > -1}
                                        onChange={() => dispatch(selectOneElement({
                                            ctx: SearchContext.AUTHOR,
                                            id: row.id
                                        }))}
                                    />
                                </TableCell>
                                <TableCell align={"center"} className={classes.cell}>
                                    <LexImage image={{ id: row.imageAuthorId ?? '', collection: 'AUTHOR', version: row.version }}
                                        maxWidth={65} size={"THUMBNAIL"} />
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {row.id}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                    {row.lastName}{row.particle ? ` (${row.particle})` : ''}{row.lastName !== '' ? ',' : ''} {row.firstName}
                                </TableCell>
                                <TableCell className={classes.cell} align={"center"}>{row.phoneNumberFav ? (
                                    <PhoneNumber phone={row.phoneNumberFav} />) : "-"}
                                </TableCell>
                                <TableCell align={"center"}
                                    className={classes.cell}>
                                    {row.emailFav ? row.emailFav : '-'}
                                </TableCell>

                                <TableCell className={classes.cell}>
                                    <ul>
                                        {row.functions?.map((f, index_function) =>
                                            <li key={index + "_functions" + index_function}
                                                dangerouslySetInnerHTML={createHtml(f)} />)}
                                    </ul>
                                </TableCell>
                                <TableCell align={"center"} className={classes.cell}><DateTime
                                    date={row.modificationDate} /></TableCell>
                                <TableCell className={classes.cell} align={"center"}>
                                    <Link
                                        to={`/${t('routes.author.root')}/${row.id}`}
                                        component={RouterLink}>
                                        <Tooltip title={t('authors.see_author') || ""}>
                                            <IconButton className={classes.iconButton}>
                                                <ViewIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link>
                                    <Auth requiredRoles={[ADMIN, ADMIN_AUTHORS]}>
                                        <Link
                                            to={`/${t('routes.author.root')}/${row.id}/${t('routes.author.edit')}`}
                                            component={RouterLink}>
                                            <Tooltip title={t('authors.edit') || ""}>
                                                <IconButton className={classes.iconButton}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                    </Auth>

                                    <IconButton className={classes.iconButton} onClick={handleCopy(row)}>
                                        <Tooltip title={t('misc.copy_to_word') || ""}>
                                            <CopyIcon />
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}

                        {(rows.length === 0 && loaded) && (
                            <TableRow>
                                <TableCell colSpan={9} align={"center"}>
                                    {t('table.no_result')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination page={filters.page || 0}
                size={filters.size || 0}
                totalCount={totalCount}
                updateChangePage={updateChangePage}
                updateChangeRowPerPage={updateChangeRowPerPage} />

        </>
    )
}

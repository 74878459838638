import React, {ChangeEvent} from "react";
import TextField from "@material-ui/core/TextField";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {autoComplete} from "../../../redux/actions/search/search-author.actions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import {State} from "../../../redux/reducers";
import {updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import {SearchContext} from "../../../types/common-search";
import {useTranslation} from "react-i18next";

type Props = {
    label?: string,
    field: 'city' | 'country',
    selectedOptions?: string[]
}

const useStyles = makeStyles(() => ({
    select: {
        marginTop: 20
    }
}))

export default ({label, field, selectedOptions}: Props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {t} = useTranslation()

    const [open, setOpen] = React.useState(false);
    const [inputText, setInputText] = React.useState("");

    const status = useSelector(({autoCompleteReducer}: State) => autoCompleteReducer.autocompleteCountryCity.autoComplete[field].status, shallowEqual) as string;
    const options = useSelector(({autoCompleteReducer}: State) => {
        const options = autoCompleteReducer.autocompleteCountryCity.autoComplete[field].options as string[];
        return selectedOptions?.length ? [...selectedOptions ?? [], ...options.filter(option => !selectedOptions?.includes(option))]
            .sort((a, b) => a.localeCompare(b)) : options;
    }, shallowEqual);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string | string[] | null) => {
        if (value === null) {
            dispatch(updateSearchQueryParam({
                ctx: SearchContext.AUTHOR,
                field: `${field}Filter`, value: []
            }))
        } else if (typeof value === 'string') {
            dispatch(updateSearchQueryParam({
                ctx: SearchContext.AUTHOR,
                field: `${field}Filter`, value: [value]
            }))
        } else {
            dispatch(updateSearchQueryParam({
                ctx: SearchContext.AUTHOR,
                field: `${field}Filter`, value
            }))
        }
    };

    return (
        <FormControl className={classes.select} fullWidth={true}>
            <Autocomplete openText={t('misc.open')}
                          clearText={t('misc.clear')}
                          closeText={t('misc.close')}
                          loadingText={t('misc.loading')}
                          noOptionsText={t('misc.no-options')}
                          color={"secondary"}
                          multiple
                          open={open}
                          onOpen={() => {
                              dispatch(autoComplete({attribute: field, query: ''}))
                              setOpen(true);
                          }}
                          onClose={() => {
                              setOpen(false);
                          }}
                          onChange={(event: any, newValue: string | string[] | null) => handleChange(event, newValue)}
                          options={options}
                          value={selectedOptions ?? []}
                          inputValue={inputText}
                          onInputChange={
                              (event: ChangeEvent<{}>, value) => {
                                  setInputText(value);
                                  if (value) {
                                      dispatch(autoComplete({
                                          attribute: field,
                                          query: value
                                      }))
                                  }
                              }
                          }
                          disableCloseOnSelect
                          loading={status === 'PENDING'}
                          renderOption={(option, {selected}) => (
                              <React.Fragment>
                                  <Checkbox
                                      checked={selected}
                                  />
                                  {option}
                              </React.Fragment>
                          )}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  variant="outlined"
                                  color={"secondary"}
                                  label={label}
                                  InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                          <React.Fragment>
                                              {status === 'PENDING' ?
                                                  <CircularProgress color="inherit" size={20}/> : null}
                                              {params.InputProps.endAdornment}
                                          </React.Fragment>
                                      ),
                                  }}
                              />
                          )}
            />
        </FormControl>
    )
};

import {combineReducers} from "redux";

import dialog from "./article-dialog.reducer";
import infos from "./article.reducer";
import filters from "./article-filters.reducer"

export default combineReducers({
    dialog,
    infos,
    filters
});

import React, {useState} from "react";
import {tableStyle} from "../../../../styles/table.style";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {useDispatch, useSelector} from "react-redux";
import {currentAdminBundlePlansSelector} from "../../../../redux/selectors/admin/bundle/admin-bundle.selectors";
import {currentPeriodicalsSelector} from "../../../../redux/selectors/periodical.selectors";
import BundlePlanActionsList from "../commun/BundlePlanActionsList";
import ConfirmationDialog from "../../../commons/ConfirmationDialog";
import {deleteAdminBundlePlan} from "../../../../redux/actions/admin/bundle/admin-bundle.actions";

const BundlePlanListTable = () => {
    const theme = useTheme();
    const classes = tableStyle(theme);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [confirmationDialogOpen, openConfirmationDialog] = useState(false);
    const [deletingId, setDeletingId] = useState('');
    const bundlePlans = useSelector(currentAdminBundlePlansSelector) ?? [];
    const periodicals = useSelector(currentPeriodicalsSelector) ?? [];

    const getPeriodicalForId = (id: string) => periodicals.filter((periodical) => periodical.id === id)[0] ?? undefined;

    const handleDeleteBundlePlan = () => {
        if (deletingId !== '') {
            dispatch(deleteAdminBundlePlan(deletingId));
            handleCloseDialog();
        }
    }

    const handleCloseDialog = () => {
        openConfirmationDialog(false);
        setDeletingId('');
    }

    return (
        <>
            <ConfirmationDialog
                title={t('admin.bundle.list.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default', action: handleCloseDialog},
                    {label: t('dialogs.confirm'), action: handleDeleteBundlePlan, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={handleCloseDialog}
            />

            <TableContainer className={classes.root} style={{overflowX: "unset"}}>
                <Table aria-label={t('admin.bundle.list.title')}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                <TableSortLabel>
                                    {t('misc.name')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TableSortLabel>
                                    {t('misc.type')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TableSortLabel>
                                    {t('misc.entities.periodical')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align={"center"} className={classes.actionCell}>
                                {t('misc.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bundlePlans.length > 0 && bundlePlans.map(bundlePlan =>
                            <TableRow key={bundlePlan.id} className={`${classes.row}`}
                                      hover={true}>
                                <TableCell className={classes.cell} width={"55%"}>
                                    {bundlePlan.label}
                                </TableCell>
                                <TableCell className={classes.cell} width={"20%"}>
                                    {t(`enums.articles.type.${bundlePlan.type}.main`)}
                                </TableCell>
                                <TableCell className={classes.cell} width={"20%"}>
                                    {getPeriodicalForId(bundlePlan.periodicalId)?.label}
                                </TableCell>
                                <TableCell align={"center"} className={classes.actionCell} width={"5%"}>
                                    <BundlePlanActionsList
                                        openConfirmationDialog={openConfirmationDialog}
                                        bundlePlan={bundlePlan}
                                        setDeletingId={setDeletingId}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                        {bundlePlans.length === 0 && <TableRow>
                            <TableCell colSpan={4} align={"center"}>
                                {t('table.no_result')}
                            </TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default BundlePlanListTable

import React, {useEffect, useMemo} from 'react'
import Grid, {GridSize} from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {pushBack} from "../../../redux/actions/router.actions";
import {useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import LexFormField from "./LexFormField";
import {ActionWithPayload} from "../../../redux/actions/utils.actions";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 50px 0'
    },
    button: {
        marginTop: theme.spacing(2)
    },
    delete: {
        paddingLeft: 50,
        marginTop: theme.spacing(2)
    },
    right: {
        paddingLeft: 50
    },
    paper: {
        padding: 15,
        marginBottom: 20
    },
    name: {
        marginTop: theme.spacing(1)
    },
    header: {
        marginBottom: 20
    },
    form: {
        width: "100%"
    }
}))

export type LexFormField = {
    name: string,
    selector: any,
    required?: boolean,
    validate?: () => boolean,
    action?: (_: any) => ActionWithPayload,
    renderer?: any,
    disabled?: boolean,
    dontWrap?: boolean,
    titleColumn?: number,
    column?: GridSize,
    shouldNotTranslate?: boolean
    searchHandler?: any
    resetFields?: string[],
    parentJsx?: JSX.Element[] | JSX.Element
}

export type LexFormProps = {
    type: "authors" | "images" | "ads" | "articles" | "publications" | "bundles" | "admin.periodical" | "admin.bundle" | "admin.custom-bundle",
    onSubmit?: any,
    cancelAction?: any,
    columns?: 3 | 4 | 6,
    fields: (LexFormField | undefined | string)[],
    children?: JSX.Element[] | JSX.Element
}

const lexField = (field, type, register, errors, setValue, control, getValues, reset) =>
    <LexFormField
        disabled={field.disabled}
        key={field.name}
        type={type}
        name={field.name}
        required={field.required ?? false}
        validate={field.validate}
        valueSelector={field.selector}
        register={register}
        errors={errors}
        setValue={setValue}
        action={field.action}
        renderer={field.renderer}
        control={control}
        dontWrap={field.dontWrap}
        getValues={getValues}
        titleColumn={field.titleColumn}
        translate={!field.shouldNotTranslate}
        searchHandler={field.searchHandler}
        reset={reset}
        parentJsx={field.parentJsx}
    />

export default ({type, onSubmit, fields, children, cancelAction}: LexFormProps) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {control, handleSubmit, register, errors, setValue, getValues, unregister, reset} = useForm<FormData>({
        mode: 'onBlur',
        shouldFocusError: true,
        criteriaMode: "all"
    });

    const onFormSubmit = handleSubmit(() => {
        return onSubmit ? onSubmit() : undefined
    });

    useEffect(() => {
        fields.forEach(field => {
            if (field && typeof field === "string") {
                unregister(field)
            }
        })
    }, [fields])

    const shownFields: LexFormField[] = useMemo(() => {
        return fields.filter(field => field && typeof field === "object") as LexFormField[]
    }, [fields])

    return (
        <form onSubmit={onFormSubmit} className={classes.form}>
            <Grid container spacing={3} className={classes.name}>
                {shownFields.map(field => {
                        if (field?.name === 'blockRenderer') {
                            return field.renderer
                        }if (field?.column) {
                            return <Grid item xs={field.column ? field.column : 12} key={"field" + field.name}>
                                <Grid container justify={"space-between"}>
                                    {lexField(field, type, register, errors, setValue, control, getValues(), reset)}

                                </Grid>
                            </Grid>
                        } else if (field) {
                            return <Grid container justify={"space-between"}>
                                {lexField(field, type, register, errors, setValue, control, getValues(), reset)}
                            </Grid>
                        }
                    }
                )}
            </Grid>
            {children}
            {(onSubmit && !children) && <Grid container
                                              justify={"flex-end"} className={classes.button} spacing={1}>
                <Grid item>
                    <Button variant="outlined" onClick={() => {
                        if (cancelAction) {
                            cancelAction()
                        } else {
                            dispatch(pushBack())
                        }
                    }}>
                        {t('misc.cancel')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" type={"submit"}>
                        {t('misc.save')}
                    </Button>
                </Grid>
            </Grid>}
        </form>
    )
}

import React, {useCallback} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {ExportIcon, ImageIcon, SendIcon} from "../../../icon";
import {useDispatch} from "react-redux";
import {notify} from "../../../redux/actions/notification.actions";
import {AuthorSearchResult, SearchContext} from "../../../types/common-search";
import AuthorUtils from "../../../utils/author-utils";
import GlobalGroupActions from "./GlobalGroupActions";
import {downloadAllAuthorsCSV, downloadAuthorsCSV, downloadImages} from "../../../utils/common-utils";

const useStyles = makeStyles(() => ({
    root: {},
    allElement: {
        textTransform: "uppercase",
        cursor: "pointer",
        marginLeft: 5
    },
    verySmall: {
        fontSize: "1.0rem"
    },
    allSelected: {
        paddingTop: 10,
        paddingLeft: 10
    }
}))

type Props = {
    idSelected: string[],
    totalCount: number,
    allSelected: boolean,
    allPageSelected: boolean,
    authors: AuthorSearchResult[]
}

export default function AuthorGroupActions({idSelected, totalCount, allSelected, allPageSelected, authors}: Props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const exportImages = useCallback(() => {
        const imagesToDownload = idSelected.flatMap((authorId,) => {
            const author = authors.find((e) => e.id === authorId);
            if (author && author.imageAuthorId) {
                return author.imageAuthorId;
            }
            return null
        }).filter(it => it !== null);

        if (allSelected || imagesToDownload.length > 20) {
            dispatch(notify(t('misc.limit_download_size_message'), {
                variant: 'error'
            }));
        } else {
            downloadImages(imagesToDownload.map(it => ({id: it!!, collection: 'AUTHOR'})))
        }
    }, [allSelected, authors, dispatch, idSelected, t])

    const exportAuthorsCSV = useCallback(() => {
        if (allSelected) {
            downloadAllAuthorsCSV()
        } else {
            downloadAuthorsCSV(idSelected)
        }
    }, [idSelected, allSelected])

    return (
        <GlobalGroupActions ctx={SearchContext.AUTHOR} allSelected={allSelected} totalCount={totalCount}
                            allPageSelected={allPageSelected} selectedCount={idSelected.length} type={"authors"}>
            <Tooltip title={t('authors.export') || ""}>
                <IconButton onClick={exportAuthorsCSV}>
                    <ExportIcon className={classes.verySmall} color="inherit"/>
                </IconButton>
            </Tooltip>
            <Tooltip title={t('authors.export_images') || ""}>
                <IconButton onClick={() => exportImages()}>
                    <ImageIcon className={classes.verySmall} color="inherit"/>
                </IconButton>
            </Tooltip>
        </GlobalGroupActions>
    )
}

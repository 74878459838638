import {Action} from "../../actions/utils.actions";
import {ArticleDialogForm, AvailableArticleTags, ArticleType} from "../../../types/article";
import {
    isGenerateNewArticleAction,
    isSetArticleDialogModeAction,
    isSetAvailableNodesResponseAction,
    isSetAvailablePeriodicalBundlesResponseAction,
    isSetAvailablePublicationBundlesResponseAction,
    isSetAvailablePublicationsResponseAction,
    isSetBundleId,
    isSetBundlePlanVersionId,
    isSetPeriodicalIdAction,
    isSetPlanNodeId,
    isSetPublicationIdAction,
    isSetTitle,
    isSetTypeAction,
    isSetAvailableTagsAction,
    isSetNumberOfPages
} from "../../actions/article/article-dialog.actions";

import {isFetchBundlePlansOfPeriodicalSuccessAction} from "../../actions/bundle.actions"
import {BundlePlanResponse, SmallBundleResponse} from "../../../types/bundle";
import {PlanNode} from "../../../types/plan-node";
import {Publication} from "../../../types/publication";
import {SearchContext} from "../../../types/common-search";
import {isUploadArticleAction, isUploadArticleTerminatedAction} from "../../actions/article/article.actions";

export enum ArticleDialogMode {
    CLOSE = 'CLOSE',
    CREATE = 'CREATE',
    MOVE = 'MOVE',
    MOVE_MULTIPLE = 'MOVE_MULTIPLE',
    COPY = 'COPY',
}

export type ArticleDialogState = {
    mode: ArticleDialogMode
    sourceId?: string
    articleForm?: ArticleDialogForm
    context?: SearchContext
    publications?: Publication[]
    topics: {
        bundle: PlanNode[],
        periodical: PlanNode[],
        publication: PlanNode[]
    }
    periodicalBundles?: SmallBundleResponse[]
    publicationBundles?: SmallBundleResponse[]
    bundlePlanVersion: BundlePlanResponse[]
    articleTags?: AvailableArticleTags,
    articleImportStatus?: 'IN_PROGRESS' | 'ERROR' | 'DONE'
}

const init: ArticleDialogState = {
    mode: ArticleDialogMode.CLOSE,
    publications: undefined,
    topics: {
        bundle: [],
        periodical: [],
        publication: []
    },
    periodicalBundles: undefined,
    publicationBundles: undefined,
    bundlePlanVersion: []
}

export default function ArticleDialogReducer(state = init, action: Action): ArticleDialogState {
    if (isFetchBundlePlansOfPeriodicalSuccessAction(action)) {
        return {
            ...state,
            bundlePlanVersion: action.payload
        }
    } else if (isSetArticleDialogModeAction(action)) {
        if (action.payload.mode === ArticleDialogMode.CLOSE) {
            return {
                ...init,
                articleTags: state.articleTags
            };
        } else {
            return {
                ...state,
                mode: action.payload.mode,
                sourceId: action.payload.source,
                articleForm: action.payload.default ? {
                    ...state.articleForm,
                    ...action.payload.default,
                    title: action.payload.default.title ?? state.articleForm?.title ?? ''
                } : {
                    periodicalId: '',
                    title: '',
                    type: undefined,
                    isBundle: false,
                    tags: undefined
                }
            }
        }
    } else if (isGenerateNewArticleAction(action)) {
        return {
            ...state,
            articleForm: state.articleForm ? {
                ...state.articleForm,
                periodicalId: action.payload.periodicalId
            } : undefined
        }
    } else if (isUploadArticleAction(action)) {
        return {
            ...state,
            articleImportStatus: 'IN_PROGRESS'
        }
    } else if (isUploadArticleTerminatedAction(action)) {
        return {
            ...state,
            articleImportStatus: action.payload ? 'DONE' : 'ERROR'
        }
    } else if (isSetAvailablePublicationsResponseAction(action)) {
        return {
            ...state,
            publications: action.payload
        }
    } else if (isSetAvailableNodesResponseAction(action)) {
        return {
            ...state,
            topics: {
                ...state.topics,
                [action.payload.type]: action.payload.plan
            }
        }
    } else if (isSetAvailablePeriodicalBundlesResponseAction(action)) {
        return {
            ...state,
            periodicalBundles: action.payload,
        }
    } else if (isSetAvailablePublicationBundlesResponseAction(action)) {
        return {
            ...state,
            publicationBundles: action.payload,
        }
    } else if (isSetAvailableTagsAction(action)) {
        return {
            ...state,
            articleTags: action.payload,
        }
    } else if (isSetPeriodicalIdAction(action)) {
        return {
            ...state,
            articleForm: {
                ...state.articleForm!,
                periodicalId: action.payload,
                publicationId: undefined,
                bundleId: undefined,
                planNodeId: undefined,
            }
        }
    } else if (isSetPublicationIdAction(action)) {
        return {
            ...state,
            articleForm: {
                ...state.articleForm!,
                publicationId: action.payload,
                bundleId: undefined,
                planNodeId: undefined,
            }
        }
    } else if (isSetBundleId(action)) {
        return {
            ...state,
            articleForm: {
                ...state.articleForm!,
                bundleId: action.payload,
            }
        }
    } else if (isSetTypeAction(action)) {
        return {
            ...state,
            articleForm: {
                ...state.articleForm!,
                type: action.payload,
                title: action.payload === ArticleType.BRIEF ? '' : state.articleForm?.title ?? ''
            }
        }
    } else if (isSetPlanNodeId(action)) {
        return {
            ...state,
            articleForm: {
                ...state.articleForm!,
                planNodeId: action.payload,
            }
        }
    } else if (isSetTitle(action)) {
        return {
            ...state,
            articleForm: {
                ...state.articleForm!,
                title: action.payload,
            }
        }
    }else if (isSetNumberOfPages(action)) {
        return {
            ...state,
            articleForm: {
                ...state.articleForm!,
                tags: {
                    ...state.articleForm?.tags,
                    nombreDePages : action.payload
                },
            }
        }
    } else if (isSetBundlePlanVersionId(action)) {
        return {
            ...state,
            articleForm: {
                ...state.articleForm!,
                bundlePlanVersionId: action.payload,
                type: state.bundlePlanVersion.find(x => x.bundlePlanVersionId === action.payload)?.type
            }
        }
    } else {
        return state
    }
}

import {Action} from "../../actions/utils.actions";
import {
    isAutoCompleteAction,
    isAutoCompleteErrorAction,
    isAutoCompleteSuccesAction
} from "../../actions/search/search-author.actions";
import {AutoCompleteResults} from "../../../types/common-search";


export type AutoCompleteState = {
    autoComplete: AutoCompleteResults
}

const initAutoComplete: AutoCompleteResults = {
    city: {
        status: "NONE",
        options: []
    },
    country: {
        status: "NONE",
        options: []
    },
}

const init: AutoCompleteState = {
    autoComplete: initAutoComplete
};

const reducer = (state = init, action: Action): AutoCompleteState => {
    if (isAutoCompleteAction(action)) {
        return {
            ...state,
            autoComplete: {
                ...state.autoComplete,
                [action.payload.attribute]: {
                    status: 'PENDING',
                    options: []
                }
            }
        }
    } else if (isAutoCompleteErrorAction(action)) {
        return {
            ...state,
            autoComplete: {
                ...state.autoComplete,
                [action.payload]: {
                    status: 'ERROR',
                    options: []
                }
            }
        }
    } else if (isAutoCompleteSuccesAction(action)) {
        return {
            ...state,
            autoComplete: {
                ...state.autoComplete,
                [action.payload.attribute]: {
                    status: 'LOADED',
                    options: action.payload.results
                }
            }
        }
    } else {
        return state;
    }
}

export default reducer
